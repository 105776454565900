import { env, localStorage } from '@fiverr-private/futile';

const KEYS = {
    BROWSER_HISTORY: 'browse-history',
    FIVERR_CHOICE_MODALITIES_TOOLTIP: 'search_fiverr_choice_modalities_tooltip',
    PROFESSION_FILTER_IMAGE_INDEX: 'profession_filter_image_index',
    PROFESSION_FILTER_RELATED_QUERY: 'profession_filter_related_query',
    PROFESSION_FILTER_IMAGE_MAPPER: 'profession_filter_image_mapper',
};

const ssrStorageMock = {
    [KEYS.BROWSER_HISTORY]: [],
    [KEYS.FIVERR_CHOICE_MODALITIES_TOOLTIP]: true,
    [KEYS.PROFESSION_FILTER_IMAGE_INDEX]: null,
    [KEYS.PROFESSION_FILTER_RELATED_QUERY]: '',
    [KEYS.PROFESSION_FILTER_IMAGE_MAPPER]: {},
};

const defaultStorage = {
    [KEYS.BROWSER_HISTORY]: [],
    [KEYS.FIVERR_CHOICE_MODALITIES_TOOLTIP]: false,
    [KEYS.PROFESSION_FILTER_IMAGE_INDEX]: null,
    [KEYS.PROFESSION_FILTER_RELATED_QUERY]: '',
    [KEYS.PROFESSION_FILTER_IMAGE_MAPPER]: {},
};

const GIGS_REGEX = /gigs\|\|\|show\|\|\|([\d]+)/;

const get = (key) => {
    if (!env.browser) {
        return ssrStorageMock[key];
    }

    const originalValue = localStorage.get(key);
    const defaultValue = defaultStorage[key];
    return originalValue || defaultValue;
};

const set = (key, value) => {
    if (env.browser) {
        return localStorage.set(key, value);
    }
};

const getRecentlyViewedGigs = () =>
    get(KEYS.BROWSER_HISTORY).reduce((acc, data) => {
        const match = data.match(GIGS_REGEX);
        if (match) {
            acc.push(match[1]);
        }
        return acc;
    }, []);

const removeAllRecentlyViewedGigs = () => {
    const updatedHistory = get(KEYS.BROWSER_HISTORY).filter((entry) => !entry.match(GIGS_REGEX));

    return set(KEYS.BROWSER_HISTORY, updatedHistory);
};

const removeSingleRecentlyViewedGig = (gigId) => {
    const gigIdString = gigId.toString();
    const updatedHistory = get(KEYS.BROWSER_HISTORY).filter((entry) => {
        const match = entry.match(GIGS_REGEX);
        return !(match && match[1] === gigIdString);
    });

    return set(KEYS.BROWSER_HISTORY, updatedHistory);
};

const storePageView = (entrySuffix) => {
    const filteredBrowseHistory = get(KEYS.BROWSER_HISTORY).filter((entry) => !entry.includes(entrySuffix));

    const newEntry = Date.now() + entrySuffix;
    filteredBrowseHistory.push(newEntry);

    return set(KEYS.BROWSER_HISTORY, filteredBrowseHistory);
};

export const localStorageService = {
    get,
    set,
    getRecentlyViewedGigs,
    removeAllRecentlyViewedGigs,
    removeSingleRecentlyViewedGig,
    storePageView,
    KEYS,
};
