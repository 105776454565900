import { merge } from 'lodash';
import { bigQuery } from '@fiverr-private/obs';
import { getContext } from '@fiverr-private/fiverr_context';
import { EVENTS_MAPPING } from '@fiverr-private/listing_lib';
import { FLOWS } from '../../../utils/constants';

export const reportImpBigQuery = (flow, promotionEncouragementStatus) => {
    const basePayLoad = getBasePayLoad(flow, promotionEncouragementStatus);
    const impPayLoad = {
        type: EVENTS_MAPPING.VIEW_PROMOTE_BANNER.biquery,
        group: 'user_impressions',
        action: {
            type: 'impression',
        },
    };
    const payLoad = merge(impPayLoad, basePayLoad);
    bigQuery.send(payLoad);
};

export const reportClickBigQuery = (flow, promotionEncouragementStatus) => {
    const basePayLoad = getBasePayLoad(flow, promotionEncouragementStatus);
    const clickPayLoad = {
        type: EVENTS_MAPPING.CLICKED_ON_PROMOTEֹֹֹ_BANNERֹ.biquery,
        group: 'user_actions',
        action: {
            type: 'click',
        },
    };
    const payLoad = merge(clickPayLoad, basePayLoad);
    bigQuery.send(payLoad);
};

const getPageName = (flow) => {
    let pageName = '';
    switch (flow) {
        case FLOWS.CATEGORY:
        case FLOWS.BUSINESS_CATEGORIES:
            pageName = 'subcategory_listings';
            break;
        case FLOWS.SEARCH:
        case FLOWS.BUSINESS_SEARCH:
            pageName = 'search_listings';
            break;
        default:
            pageName = '';
    }

    return pageName;
};

const getBasePayLoad = (flow, promotionEncouragementStatus) => {
    const { userId, pageCtxId } = getContext();
    const pageName = getPageName(flow);
    const eventPayload = {
        feature: {
            name: 'promoted_gigs',
        },
        page: {
            name: pageName,
            ctx_id: pageCtxId,
            component: {
                name: promotionEncouragementStatus,
            },
        },
        user: {
            id: userId,
        },
    };

    return eventPayload;
};
