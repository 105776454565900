import React, { useContext } from 'react';
import { inRange } from 'lodash';
import { useImpressionItem } from '@fiverr-private/impressionable';
import { BannerWrapper } from '@fiverr-private/gig_listings';
import { initializeLogoMakerBanner } from '@fiverr-private/logo_maker_marketplace';
import { metricsService } from '@fiverr-private/listing_lib';
import { FLOWS_ENRICHMENT, MEDIA_GRID_LG, MEDIA_GRID_MD } from '../../utils/constants';
import { ListingsImpressionContext } from '../../../../shared/context/ListingsImpressionContainer/ListingsImpressionContainer';
import { LOGO_MAKER_BANNER_ROW } from './listings/constants';

const LogoMakerBanner = ({ showListViewCards, ...props }) => {
    const { onImpression } = useContext(ListingsImpressionContext);

    const [ref] = useImpressionItem({
        sendImpressionToBQ: false,
        intersectionThreshold: 0.01,
        enrichment: {
            name: FLOWS_ENRICHMENT.LOGO_MAKER_BANNER,
        },
        onImpression,
    });

    const mdMediaQueryTop = Number.parseInt(MEDIA_GRID_LG, 10),
        mdMediaQueryBottom = Number.parseInt(MEDIA_GRID_MD, 10);
    const isWindowInMDMediaQuery = inRange(
        typeof window !== 'undefined' && window.innerWidth,
        mdMediaQueryBottom,
        mdMediaQueryTop
    );

    const cardViewBannerRow = isWindowInMDMediaQuery ? LOGO_MAKER_BANNER_ROW.TABLET : LOGO_MAKER_BANNER_ROW.DESKTOP;

    const bannerRow = showListViewCards ? LOGO_MAKER_BANNER_ROW.MOBILE : cardViewBannerRow;

    const logoMakerBanner = initializeLogoMakerBanner({ bannerRow, ...props });

    if (!logoMakerBanner) {
        return null;
    }

    metricsService.count('mpi.logo_maker_banner.shown');

    return BannerWrapper(<div ref={ref}>{logoMakerBanner}</div>)({ bannerRow, className: 'logo-maker-banner' });
};

export default LogoMakerBanner;
