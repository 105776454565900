import { isEmpty } from 'lodash';
import { buildListingPageUrl } from '@fiverr-private/listing_lib';
import type { FlowName, ListingAttributes } from '@fiverr-private/listing_types';
import { GeneralFilterOption } from '../../side_filters/types';
import { ProfessionFiltersProps } from '../../side_filters/ExposedFilters/types';
import { SOURCE } from '../constants';

export const buildOptionUrl = ({
    option,
    professionFilter,
    listingAttributes,
    flowName,
}: {
    option: GeneralFilterOption;
    professionFilter: ProfessionFiltersProps['professionFilter'];
    listingAttributes: ListingAttributes;
    flowName: FlowName;
}) => {
    if (isEmpty(professionFilter)) {
        return undefined;
    }

    const url = buildListingPageUrl(
        window,
        {
            ...listingAttributes,
            activeFilters: { ...listingAttributes.activeFilters, [professionFilter.id]: [option.id] },
        },
        flowName,
        SOURCE
    );

    return url;
};
