import React from 'react';
import { Icon } from '@fiverr-private/fit';
import { I18n, translate } from '@fiverr-private/i18n-react';
import { r_globe } from '@fiverr-private/fit/icons';
import { HIGHLIGHTS_DATA_KEYS } from '../../../shared/constants';
import { highlightsDataType } from '../types';

const SellerSpeaks = ({ highlightsData = {} }) => {
    const sellerLanguageCode = highlightsData[HIGHLIGHTS_DATA_KEYS.SELLER_LANGUAGE_CODE];

    if (!sellerLanguageCode) {
        return null;
    }

    const language = translate(`languages.codes.${sellerLanguageCode}`);

    return (
        <>
            <Icon className="seller-highlight-icon">{r_globe}</Icon>
            <I18n k={'listings.highlights.seller_speaks'} params={{ language }} />
        </>
    );
};

SellerSpeaks.propTypes = {
    highlightsData: highlightsDataType,
};

export default SellerSpeaks;
