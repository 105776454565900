const { TILE_SIZES } = require('../visual_filters/constants');

const getVisualFiltersConfig = () => [
    {
        id: '156',
        filters: [
            {
                id: 'instrument',
                options: ['guitar', 'drums', 'piano', 'bass', 'keyboards', 'violin', 'saxophone', 'trumpet'],
            },
        ],
    },
    {
        id: '2619',
        filters: [
            {
                id: 'platform',
                options: ['wordpress', 'wix', 'webflow', 'shopify', 'woocommerce'],
            },
        ],
    },
    {
        id: '340',
        filters: [
            {
                id: 'illustration_type',
                tileSize: TILE_SIZES.LARGE,
                options: ['portrait', 'caricature', 'pet_portrait'],
            },
        ],
    },
    {
        id: '154',
        filters: [
            {
                id: 'merch_type',
                options: [
                    't_shirts',
                    'clothing_apparel',
                    'mugs',
                    'hoodies',
                    'stickers',
                    'socks',
                    'hats',
                    'accessories',
                    'jerseys_sportswear',
                ],
            },
        ],
    },
    {
        id: '512',
        filters: [
            {
                id: 'game_name',
                options: [
                    'valorant',
                    'minecraft',
                    'league_of_legends',
                    'fortnite',
                    'apex_legends',
                    'roblox',
                    'call_of_duty',
                    'counter_strike',
                    'rocket_league',
                ],
            },
        ],
    },
    {
        id: '2358',
        filters: [
            {
                id: 'software',
                options: ['streamlabs_obs', 'obs_studio', 'streamelements', 'twitch_app'],
            },
        ],
    },
    {
        id: '2357',
        filters: [
            {
                id: 'software',
                options: ['streamlabs_obs', 'obs_studio', 'streamelements', 'twitch_app'],
            },
        ],
    },
    {
        id: '2240',
        filters: [
            {
                id: 'language',
                options: ['english', 'german', 'spanish', 'italian', 'french'],
            },
            {
                id: 'book_type',
                options: ['fiction', 'non_fiction'],
            },
            {
                id: 'genre',
                options: [
                    'self_help_wellness',
                    'children_s_books',
                    'business_management',
                    'science_fiction_fantasy',
                    'romance',
                ],
            },
        ],
    },
    {
        id: '2442',
        filters: [
            {
                id: 'explainer_type',
                options: ['illustration', 'infographics', 'text_animation'],
            },
        ],
    },
    {
        id: '2443',
        filters: [
            {
                id: 'explainer_type',
                options: ['illustration', 'infographics', 'text_animation'],
            },
        ],
    },
    {
        id: '2441',
        filters: [
            {
                id: 'explainer_type',
                options: ['illustration', 'infographics', 'text_animation'],
            },
        ],
    },
    {
        id: '2341',
        filters: [
            {
                id: 'video_type',
                options: ['animated', 'live_action'],
            },
        ],
    },
    {
        id: '2333',
        filters: [
            {
                id: 'video_type',
                options: ['animated', 'live_action'],
            },
        ],
    },
    {
        id: '2330',
        filters: [
            {
                id: 'video_type',
                options: ['animated', 'live_action'],
            },
        ],
    },
    {
        id: '2328',
        filters: [
            {
                id: 'video_type',
                options: ['animated', 'live_action'],
            },
        ],
    },
    {
        id: '2329',
        filters: [
            {
                id: 'video_type',
                options: ['animated', 'live_action'],
            },
        ],
    },
    {
        id: '2331',
        filters: [
            {
                id: 'video_type',
                options: ['animated', 'live_action'],
            },
        ],
    },
    {
        id: '2332',
        filters: [
            {
                id: 'video_type',
                options: ['animated', 'live_action'],
            },
        ],
    },
    {
        id: '2489',
        filters: [
            {
                id: 'video_type',
                options: ['animated', 'live_action'],
            },
        ],
    },
    {
        id: '158',
        filters: [
            {
                id: 'gender',
                options: ['female', 'male', 'non_binary'],
            },
        ],
    },
    {
        id: '329',
        filters: [
            {
                id: 'platform',
                options: [
                    'facebook',
                    'generic',
                    'instagram',
                    'youtube',
                    'tiktok',
                    'snapchat',
                    'pinterest',
                    'linkedin',
                    'twitter',
                ],
            },
        ],
    },
    {
        id: '1029',
        filters: [
            {
                id: 'platform',
                options: ['wix', 'squarespace', 'godaddy', 'sitebuilder', 'webflow'],
            },
        ],
    },
    {
        id: '1006',
        filters: [
            {
                id: 'platform',
                options: ['wix', 'squarespace', 'godaddy', 'sitebuilder', 'webflow'],
            },
        ],
    },
    {
        id: '1005',
        filters: [
            {
                id: 'platform',
                options: ['wix', 'squarespace', 'godaddy', 'sitebuilder', 'webflow'],
            },
        ],
    },
    {
        id: '1004',
        filters: [
            {
                id: 'platform',
                options: ['wix', 'squarespace', 'godaddy', 'sitebuilder', 'webflow'],
            },
        ],
    },
    {
        id: '1003',
        filters: [
            {
                id: 'platform',
                options: ['wix', 'squarespace', 'godaddy', 'sitebuilder', 'webflow'],
            },
        ],
    },
    {
        id: '1002',
        filters: [
            {
                id: 'platform',
                options: ['wix', 'squarespace', 'godaddy', 'sitebuilder', 'webflow'],
            },
            {
                id: 'spcecialization',
                options: ['blog_magazine', 'corporate', 'education', 'portfolio', 'entertainment', 'wedding'],
            },
        ],
    },
    {
        id: '1001',
        filters: [
            {
                id: 'platform',
                options: ['wix', 'squarespace', 'godaddy', 'sitebuilder', 'webflow'],
            },
        ],
    },
    {
        id: '1000',
        filters: [
            {
                id: 'spcecialization',
                options: ['blog_magazine', 'corporate', 'education', 'portfolio', 'entertainment', 'wedding'],
            },
            {
                id: 'platform',
                options: ['wix', 'squarespace', 'godaddy', 'sitebuilder', 'webflow'],
            },
        ],
    },
    {
        id: '1007',
        filters: [
            {
                id: 'platform',
                options: ['shopify', 'woocommerce', 'magento', 'prestashop', 'opencart', 'bigcommerce'],
            },
        ],
    },
    {
        id: '1008',
        filters: [
            {
                id: 'platform',
                options: ['shopify', 'woocommerce', 'magento', 'prestashop', 'opencart', 'bigcommerce'],
            },
        ],
    },
    {
        id: '1009',
        filters: [
            {
                id: 'platform',
                options: ['shopify', 'woocommerce', 'magento', 'prestashop', 'opencart', 'bigcommerce'],
            },
        ],
    },
    {
        id: '1010',
        filters: [
            {
                id: 'platform',
                options: ['shopify', 'woocommerce', 'magento', 'prestashop', 'opencart', 'bigcommerce'],
            },
        ],
    },
    {
        id: '1011',
        filters: [
            {
                id: 'platform',
                options: ['shopify', 'woocommerce', 'magento', 'prestashop', 'opencart', 'bigcommerce'],
            },
        ],
    },
    {
        id: '1012',
        filters: [
            {
                id: 'platform',
                options: ['shopify', 'woocommerce', 'magento', 'prestashop', 'opencart', 'bigcommerce'],
            },
        ],
    },
    {
        id: '1013',
        filters: [
            {
                id: 'platform',
                options: ['shopify', 'woocommerce', 'magento', 'prestashop', 'opencart', 'bigcommerce'],
            },
        ],
    },
    {
        id: '2060',
        filters: [
            {
                id: 'platform_type',
                options: ['facebook', 'instagram', 'linkedin', 'twitter', 'youtube'],
            },
        ],
    },
    {
        id: '2061',
        filters: [
            {
                id: 'platform_type',
                options: ['facebook', 'instagram', 'linkedin', 'twitter', 'youtube'],
            },
        ],
    },
    {
        id: '2062',
        filters: [
            {
                id: 'platform_type',
                options: ['facebook', 'instagram', 'linkedin', 'twitter', 'youtube'],
            },
        ],
    },
    {
        id: '2063',
        filters: [
            {
                id: 'platform_type',
                options: ['facebook', 'instagram', 'linkedin', 'twitter', 'youtube'],
            },
        ],
    },
    {
        id: '2065',
        filters: [
            {
                id: 'platform_type',
                options: ['facebook', 'instagram', 'linkedin', 'twitter', 'youtube'],
            },
        ],
    },
    {
        id: '386',
        filters: [
            {
                id: 'podcast_cover_style',
                options: ['photographic', 'illustrative', 'typographic'],
            },
        ],
    },
    {
        id: '50',
        filters: [
            {
                id: 'illustration_style',
                tileSize: TILE_SIZES.LARGE,
                options: ['for_children', 'realistic_drawing', 'comic_cartoon', 'manga', 'flat', 'line_art', 'sketch'],
            },
            {
                id: 'artistic_technique',
                tileSize: TILE_SIZES.LARGE,
                options: ['freehand_digital', 'vector_art', 'mixed_media', 'pen_ink', 'watercolor', 'pencil'],
            },
        ],
    },
    {
        id: '392',
        filters: [
            {
                id: 'genre',
                options: ['pop', 'hip_hop', 'rap', 'r_b', 'edm'],
            },
        ],
    },
    {
        id: '249',
        filters: [
            {
                id: 'type',
                options: ['weddings_events', 'family_travel', 'corporate', 'sports_games', 'elearning'],
            },
        ],
    },
    {
        id: '158',
        filters: [
            {
                id: 'gender',
                options: ['female', 'male', 'non_binary'],
            },
        ],
    },
    {
        id: '416',
        filters: [
            {
                id: 'development_technology',
                options: ['python', 'c', 'javascript', 'java', 'html', 'css'],
            },
        ],
    },
    {
        id: '2416',
        filters: [
            {
                id: 'gender_and_group',
                options: ['women', 'men', 'unisex', 'kids'],
            },
        ],
    },
    {
        id: '2414',
        filters: [
            {
                id: 'item_type',
                options: ['clothing', 'shoes', 'accessories', 'bags', 'full_outfit'],
            },
        ],
    },
    {
        id: '2412',
        filters: [
            {
                id: 'item_type',
                options: ['clothing', 'bags', 'shoes', 'accessories'],
            },
        ],
    },
    {
        id: '384',
        filters: [
            {
                id: 'language',
                options: ['english', 'spanish', 'french', 'german', 'arabic'],
            },
        ],
    },
    {
        id: '163',
        filters: [
            {
                id: 'language',
                options: ['english', 'spanish', 'french', 'german', 'italian', 'dutch'],
            },
            {
                id: 'topic',
                options: [
                    'internet_technology',
                    'health_medical',
                    'lifestyle',
                    'business_finance_law',
                    'beauty_fashion',
                    'marketing',
                ],
            },
        ],
    },
    {
        id: '54',
        filters: [
            {
                id: 'purpose',
                options: [
                    'marketing',
                    'investor_pitch_fundraising',
                    'business_proposal',
                    'education_training',
                    'sales',
                    'conference',
                ],
            },
        ],
    },
    {
        id: '49',
        filters: [
            {
                id: 'style',
                tileSize: TILE_SIZES.LARGE,
                options: [
                    'flat_minimalist',
                    'hand_drawn',
                    'retro',
                    'mascot_cartoon',
                    '3d',
                    'lettering',
                    'geometric',
                    'signature',
                    'watercolor_feminine',
                ],
            },
        ],
    },
    {
        id: '371',
        filters: [
            {
                id: 'asset_type',
                options: ['emotes_badges', 'overlays_screen_packs', 'panels', 'thumbnails', 'banners', 'alerts'],
            },
        ],
    },
    {
        id: '99',
        filters: [
            {
                id: 'service_type',
                options: [
                    'ads_social_media_videos',
                    'youtube_videos',
                    'corporate_videos',
                    'gaming_videos',
                    'family_videos',
                    'music_videos',
                    'event_videos',
                    'explainer_videos',
                    'showreels',
                    'fiction_films',
                    'movie_trailers',
                    'vr_360_video',
                ],
            },
        ],
    },
    {
        id: '16',
        filters: [
            {
                id: 'language',
                options: ['english', 'german', 'french', 'arabic', 'italian', 'spanish'],
            },
            {
                id: 'gender',
                options: ['female', 'male'],
            },
        ],
    },
];

module.exports = {
    getVisualFiltersConfig,
};
