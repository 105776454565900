import React from 'react';
import { LazyComponent } from '@fiverr-private/orca';
import { logger } from '@fiverr-private/obs';

const lazyImport = () => import(/* webpackChunkName: 'FiProEntryBanner' */ './FiProEntryBanner');

const FiProEntryBannerResolver = () => (
    <LazyComponent lazyImport={lazyImport} onError={(err) => logger.error('Failed to load FiProEntryBanner', err)} />
);

export { FiProEntryBannerResolver as FiProEntryBanner };
