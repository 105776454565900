import React from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import { Text } from '@fiverr-private/typography';
import { InitialProps } from '@fiverr-private/listing_types';

interface NoResultsProProps {
    displayData: InitialProps['displayData'];
}

export const NoResultsPro = ({ displayData }: NoResultsProProps) => {
    const { modifiedQueryString, query } = displayData?.searchComponentData || {};

    const displayQuery = modifiedQueryString || query;
    const emptyResultsCopyKey = `listing_results.empty_results.pro.${displayQuery ? 'query' : 'category'}`;

    return (
        <Text size="b_sm" color="bodySecondary">
            <I18n k={emptyResultsCopyKey} />
        </Text>
    );
};
