import { getContext } from '@fiverr-private/fiverr_context';
import { DEFAULT_FIVERR_LOCALE, SUPPORTED_FORMATTING_LOCALES_KEYS } from '@fiverr-private/localization';

const normalizeLocale = (locale) => (locale === 'es' ? SUPPORTED_FORMATTING_LOCALES_KEYS.ES_ES : locale);

export const getVideoSourceByLocale = ({ sources }) => {
    const { locale } = getContext();
    const normalizedLocale = normalizeLocale(locale);

    return sources[normalizedLocale] || sources[DEFAULT_FIVERR_LOCALE];
};
