import React from 'react';
import { Stack } from '@fiverr-private/layout_components';
import { Avatar, AvatarImage } from '@fiverr-private/avatar';
import { Icon } from '@fiverr-private/fit';
import { l_download } from '@fiverr-private/fit/icons';
import { Text } from '@fiverr-private/typography';
import { translate } from '@fiverr-private/i18n-react';
import FiNeoStill from '../../../assets/gifs/FiNeoStill.gif';
import { assistantTriggerStyles, ctaStyles, iconStyles } from './AssistantTrigger.ve.css';

interface AssistantTriggerProps {
    onClick: () => void;
}

export const AssistantTrigger = ({ onClick }: AssistantTriggerProps) => {
    const ctaText = translate('search_perseus.assistant.trigger.cta');

    return (
        <Stack
            className={assistantTriggerStyles}
            paddingY="2"
            paddingX="1.5"
            alignItems="center"
            direction="row"
            position="fixed"
            top="144px"
            backgroundColor="white"
            borderBottomRightRadius="lg"
            borderTopRightRadius="lg"
            onClick={onClick}
            cursor="pointer"
            gap="0"
        >
            <Avatar username="Neo" size="sm">
                <AvatarImage alt="trigger assistant icon" src={FiNeoStill} />
            </Avatar>
            <Text className={ctaStyles} fontWeight="semibold" color="inherit" size="b_md">
                {ctaText}
            </Text>
            <Icon className={iconStyles} color="grey_800" size={16}>
                {l_download}
            </Icon>
        </Stack>
    );
};
