import React from 'react';
import { Container, Divider, Wrap } from '@fiverr-private/layout_components';
import { translate } from '@fiverr-private/i18n-react';
import { Typography } from '@fiverr-private/typography';

export const OrDivider = () => (
    <Wrap paddingY="3" gap="2" position="relative">
        <Divider marginX="0" width="100%" />
        <Container
            position="relative"
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            lineHeight="b_lg"
        >
            <Wrap position="absolute" paddingX="2" backgroundColor="white" top="-18px">
                <Typography size="b_sm" color="grey_700" fontWeight="bold" transform="uppercase">
                    {translate('search_perseus.general.or')}
                </Typography>
            </Wrap>
        </Container>
    </Wrap>
);
