import React, { useEffect, useState } from 'react';
import { Container, Stack, Wrap } from '@fiverr-private/layout_components';
import { getContext } from '@fiverr-private/fiverr_context';
import { I18n } from '@fiverr-private/i18n-react';
import { localStorage } from '@fiverr-private/futile/lib/storage/localStorage';
import { HireHourlyL2Icon, TimesIcon } from '@fiverr-private/visuals';
import { Text, Typography } from '@fiverr-private/typography';
import { CloseButton, IconButton } from '@fiverr-private/button';
import { useAppContext } from '../../../context/listingsContext';
import { HowItWorksButton, HowItWorksInlineButton } from './HowItWorksButton';
import { reportBqBannerImpressionEvent, reportBqDismissClickEvent } from './HourlyRate.events';
import { UpgradeButton } from './UpgradeButton';
import { useUpgradeClick } from './hooks/useUpgradeClick';

const T_PREFIX = 'search_perseus.business_listings.banners';

const getLsKey = () => `HourlyRateTopBanner:closed:${getContext().userId}`;
const bannerWasClosed = () => !!localStorage.get(getLsKey());
const setBannerWasClosed = () => localStorage.set(getLsKey(), 'true');
export const resetLsBannerWasClosed = () => localStorage.remove(getLsKey());

export interface HourlyRateTopBannerProps {}

export const HourlyRateTopBanner: React.FC<HourlyRateTopBannerProps> = () => {
    const { flowName } = useAppContext();
    const [isClosed, setClosed] = useState(bannerWasClosed());
    const { onUpgradeClick, isLoading } = useUpgradeClick();

    useEffect(() => {
        reportBqBannerImpressionEvent({ flowName });
    }, [flowName]);

    if (bannerWasClosed() || isClosed) {
        return null;
    }

    const onCloseClick = (e: React.SyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setClosed(true);
        setBannerWasClosed();
        reportBqDismissClickEvent({ flowName });
    };

    return (
        <Stack
            width="100%"
            borderWidth="sm"
            borderColor="border"
            borderRadius="xl"
            paddingTop="4"
            backgroundColor="white"
            paddingBottom={{ default: '6', lg: '4' }}
            paddingX="6"
            position="relative"
            cursor="pointer"
            onClick={onUpgradeClick}
        >
            <Container
                hidden={{ default: false, lg: true }}
                position="absolute"
                cursor="pointer"
                margin="1"
                top={4}
                right={4}
                zIndex="1"
            >
                <CloseButton size="xs" onClick={onCloseClick} />
            </Container>
            <Wrap
                justifyContent="spaceBetween"
                gap={{ default: '3', md: '4' }}
                width="100%"
                wrap="nowrap"
                alignItems={{ default: 'flexStart', lg: 'center' }}
                direction={{ default: 'column', sm: 'row' }}
            >
                <Stack color="grey_1200">
                    <Container width={64}>
                        <HireHourlyL2Icon size="lg" />
                    </Container>
                </Stack>
                <Stack
                    gap="3"
                    justifyContent="spaceBetween"
                    direction={{ default: 'column', lg: 'row' }}
                    flex="auto"
                    color="grey_1200"
                >
                    <Wrap direction="column" gap={{ default: '1', md: '1.5' }} paddingRight={{ default: '4', lg: '0' }}>
                        <Container>
                            <Typography size="b_md" color="inherit" fontWeight="bold">
                                <I18n k={`${T_PREFIX}.hourly_banner.title`} />
                            </Typography>
                        </Container>
                        <Container display="block">
                            <Text size="b_md" color="inherit" as="span">
                                <I18n k={`${T_PREFIX}.hourly_banner.description`} />
                            </Text>
                            <HowItWorksInlineButton
                                marginLeft="2"
                                hidden={{ default: true, lg: false }}
                                style={{ verticalAlign: 'top' }}
                            />
                        </Container>
                    </Wrap>
                    <Wrap
                        justifyContent={{ md: 'flexStart' }}
                        alignItems={{ default: 'flexEnd', lg: 'center' }}
                        width={{ default: 'auto', sm: 'fit-content' }}
                        flex="none"
                    >
                        <HowItWorksButton
                            size="sm"
                            hidden={{ default: false, lg: true }}
                            style={{ flex: 'auto' }}
                            fullWidth
                        />
                        <UpgradeButton size="sm" style={{ width: 'max-content', flex: 'auto' }} isLoading={isLoading} />
                        <Container hidden={{ default: true, lg: false }} cursor="pointer" margin="1" zIndex="1">
                            <IconButton
                                aria-label="Close"
                                size="sm"
                                intent="secondary"
                                variant="ghost"
                                onClick={onCloseClick}
                            >
                                <TimesIcon />
                            </IconButton>
                        </Container>
                    </Wrap>
                </Stack>
            </Wrap>
        </Stack>
    );
};
