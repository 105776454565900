import React from 'react';
import { findKey } from 'lodash';
import classnames from 'classnames';
import { Tag } from '@fiverr-private/fit';
import { HIGHLIGHTS_COMPONENT_MAPPER, HIGHLIGHTS_COMPONENT_CLASSNAME_MAP } from './constsnts';
import propTypes from './types';

import './index.scss';

/**
 * A component that shows the seller highlight if exists.
 */
const SellerHighlight = ({ highlights, highlightsData }) => {
    const highlightId = findKey(highlights, (highlight) => highlight);
    const Component = HIGHLIGHTS_COMPONENT_MAPPER[highlightId];
    const highlightsWrapperClassnames = 'm-t-16 listings-seller-highlights';
    if (!Component) {
        return <div className={classnames(highlightsWrapperClassnames, 'no-highlight')} />;
    }

    return (
        <div className={highlightsWrapperClassnames}>
            <Tag
                size={Tag.SIZES.SMALL}
                color={Tag.COLORS.LIGHT_GREY}
                className={classnames('seller-highlight-tag', HIGHLIGHTS_COMPONENT_CLASSNAME_MAP[highlightId])}
            >
                <Component highlightsData={highlightsData} />
            </Tag>
        </div>
    );
};

SellerHighlight.propTypes = propTypes;

export default SellerHighlight;
