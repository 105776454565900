import { useEffect, useState } from 'react';
import { isEmpty, isNil } from 'lodash';
import { logger } from '@fiverr-private/obs';
import { getContext } from '@fiverr-private/fiverr_context';
import { localStorageService } from '@fiverr-private/listing_lib';
import { fetchPersonalizationContentByFunnel } from '../../utils/fetchPersonalizedContent';
import { FUNNEL_NAME_MAPPER } from './constants';

const usePersonalizationData = ({ flow, fetchParams = {}, shouldRun = false }) => {
    const [personalizedContent, setPersonalizedContent] = useState({});
    const [isLoadingPersonalization, setIsLoadingPersonalization] = useState(false);

    const shouldFetch = () => {
        if (!isNil(shouldRun)) {
            return shouldRun;
        }

        return true;
    };

    useEffect(() => {
        const getPersonalizationContent = async () => {
            try {
                const shouldFetchResult = shouldFetch();

                if (!shouldFetchResult) {
                    return;
                }

                setIsLoadingPersonalization(true);

                const { userId } = getContext();
                const funnelName = FUNNEL_NAME_MAPPER[flow];
                const data = await fetchPersonalizationContentByFunnel(funnelName, {
                    ...(userId && { browse_history: localStorageService.getRecentlyViewedGigs() }),
                    ...fetchParams,
                });

                !isEmpty(data) && setPersonalizedContent(data);
            } catch (error) {
                logger.error(error, { description: 'Failed to fetch personalized content by funnel' });
            }

            setIsLoadingPersonalization(false);
        };

        getPersonalizationContent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { personalizedContent, isLoadingPersonalization, setIsLoadingPersonalization };
};

export default usePersonalizationData;
