import { buildListingPageUrl } from '@fiverr-private/listing_lib';
import { PaginationProps } from './types';

const MAX_INDEX = 20;
const FULL_INDEX_SIZE = 10;
const HALF_INDEX_SIZE = FULL_INDEX_SIZE / 2;
const MIN_PAGE_TO_SHOW_FIRST_PAGE_BUTTON = 3;
const SOURCE_PAGINATION = 'pagination';

const calcPagesIndexes = (page: number, numberOfPages: number) => {
    let startIndex = 1,
        endIndex = FULL_INDEX_SIZE;

    if (numberOfPages < FULL_INDEX_SIZE) {
        return { startIndex, endIndex: numberOfPages };
    }

    // End of the pagination
    if (page + HALF_INDEX_SIZE - 1 >= numberOfPages) {
        startIndex = numberOfPages - FULL_INDEX_SIZE + 1;
        endIndex = numberOfPages;
    } else if (page - HALF_INDEX_SIZE > 1 && page + HALF_INDEX_SIZE - 1 < numberOfPages) {
        // Middle of the pagination
        startIndex = page - HALF_INDEX_SIZE;
        endIndex = page + HALF_INDEX_SIZE - 1;
    }

    return { startIndex, endIndex };
};

const calcPagesRange = (page: number, numberOfPages: number) => {
    const { startIndex, endIndex } = calcPagesIndexes(page, numberOfPages);

    const pagesNum = endIndex - startIndex + 1;
    return Array(...Array(pagesNum)).map((_, i) => i + startIndex);
};

export const getPaginationConfig = ({ pagination, listingAttributes, flowName }: PaginationProps) => {
    const { page: currentPage, page_size: pageSize, total: amountOfResults, offset } = pagination;
    const totalPages = Math.ceil(amountOfResults / pageSize);
    const numberOfPages = Math.min(MAX_INDEX, totalPages);

    const previousPageButtonRelevant = currentPage > 1;
    const nextPageButtonRelevant = currentPage < numberOfPages;
    const firstPageButtonRelevant = currentPage >= MIN_PAGE_TO_SHOW_FIRST_PAGE_BUTTON;

    const previousPageUrl = buildListingPageUrl(
        window,
        { ...listingAttributes, page: currentPage - 1, offset },
        flowName,
        SOURCE_PAGINATION
    );

    const nextPageUrl = buildListingPageUrl(
        window,
        { ...listingAttributes, page: currentPage + 1, offset },
        flowName,
        SOURCE_PAGINATION
    );
    const firstPageUrl = buildListingPageUrl(
        window,
        { ...listingAttributes, page: 1, offset },
        flowName,
        SOURCE_PAGINATION
    );

    const pagesRange = calcPagesRange(currentPage, numberOfPages);
    const pages = pagesRange.map((pageNumber) => ({
        pageNumber,
        url: buildListingPageUrl(
            window,
            { ...listingAttributes, page: pageNumber, offset },
            flowName,
            SOURCE_PAGINATION
        ),
        isCurrentPage: pageNumber === currentPage,
    }));

    return {
        previousPageButtonRelevant,
        nextPageButtonRelevant,
        firstPageButtonRelevant,
        previousPageUrl,
        nextPageUrl,
        firstPageUrl,
        pages,
    };
};
