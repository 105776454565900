const BANNER_URL =
    'https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto/v1/attachments/generic_asset/asset/482337d38d6e7d33efc8c3a0f867b701-1669287659270/banner.png';

export const BANNER_WRAPPER_STYLE = {
    '--mobile-background-img': `url(${BANNER_URL})`,
};

export const BI_EVENTS = {
    BANNER_IS_SHOWN: 'trust_banner_shown',
    BANNER_IMPRESSION: 'banner_impression',
    BANNER_NAME: 'trust_banner',
    ELEMENT_TYPE: 'banner',
};
