import React, { useEffect } from 'react';
import { TryHourlyRatesBanner } from '@fiverr-private/business_entry_point';
import { LISTINGS_PAGE } from '../../../../../../../constants';
import { useAppContext } from '../../../../../../context/listingsContext';
import { reportBqClickEvent, reportBqImpressionEvent } from './HourlyRates.events';
import { shouldShowBannerCondition, onMigrationSuccess, getProCatalogLinkWithHourlyFilter } from './HourlyRates.utils';

interface HourlyRateBudgetBannerProps {
    className?: string;
    maxWidth?: React.ComponentProps<typeof TryHourlyRatesBanner>['maxWidth'];
    closeMenu(): void;
}

export const HourlyRateBudgetBanner: React.FC<HourlyRateBudgetBannerProps> = ({
    className = '',
    closeMenu = () => null,
    maxWidth = 290,
}) => {
    const { flowName, rollouts } = useAppContext();
    const shouldShow = shouldShowBannerCondition({ rollouts });

    const onClick = () => {
        reportBqClickEvent({ flowName });
        closeMenu();
    };

    const onTryHourlyRatesRedirect = () => {
        window.open(getProCatalogLinkWithHourlyFilter({ source: 'hourly_budget_filter' }), '_self');
    };

    useEffect(() => {
        if (shouldShow) {
            reportBqImpressionEvent({ flowName });
        }
    }, [shouldShow, flowName]);

    if (!shouldShow) {
        return null;
    }

    return (
        <TryHourlyRatesBanner
            className={className}
            source={LISTINGS_PAGE}
            maxWidth={maxWidth}
            registrationSource="hourly_budget_filter"
            migrationBiEnrichment={{ pageElementName: 'hourly_budget' }}
            onClick={onClick}
            onMigrationSuccess={onMigrationSuccess}
            onTryHourlyRatesRedirect={onTryHourlyRatesRedirect}
            style={{ fontWeight: 'initial' }}
        />
    );
};
