import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { I18n } from '@fiverr-private/i18n-react';
import { Icon } from '@fiverr-private/fit';
import { s_check_circle } from '@fiverr-private/fit/icons';
import { white } from '@fiverr-private/sass/helpers/index';
import localStorage from '../../../utils/localStorage';
import { TOOLTIP_MAPPER } from '../../constants';

import './index.scss';

class DeliveredAssetTag extends Component {
    constructor(props) {
        super(props);

        !localStorage.get(localStorage.KEYS.DELIVERY_ASSET) && props.toggleTooltip(TOOLTIP_MAPPER.DELIVERY_ASSET, true);

        this.onMouseOut = this.onMouseOut.bind(this);
    }

    onMouseOut() {
        const { toggleTooltip } = this.props;

        localStorage.get(localStorage.KEYS.DELIVERY_ASSET) && toggleTooltip(TOOLTIP_MAPPER.DELIVERY_ASSET, false);
    }

    render() {
        const { toggleTooltip } = this.props;
        const shrink = localStorage.get(localStorage.KEYS.DELIVERY_ASSET);

        return (
            <div
                className={classnames('delivered-asset-tag', { shrink })}
                onMouseOver={() => toggleTooltip(TOOLTIP_MAPPER.DELIVERY_ASSET, true)}
                onMouseOut={this.onMouseOut}
            >
                <Icon size={14} color={white}>
                    {s_check_circle}
                </Icon>
                <span className={classnames({ hidden: shrink })}>
                    <I18n k="gig_listings.gig_cards.delivered_asset.tag" />
                </span>
            </div>
        );
    }
}

DeliveredAssetTag.propTypes = {
    toggleTooltip: PropTypes.func,
};

export default DeliveredAssetTag;
