import { useEffect, useContext } from 'react';
import { OrchestratorMachineContext, OrchestratorMachineDispatchContext } from '../contexts';
import { RESPONSE_TIMEOUT, TIMED_ORCHESTRATOR_STATES } from '../constants';

export const useHandleOrchestratorTimeouts = () => {
    const { activeState } = useContext(OrchestratorMachineContext);
    const dispatch = useContext(OrchestratorMachineDispatchContext);

    useEffect(() => {
        let timer: NodeJS.Timeout;

        if (TIMED_ORCHESTRATOR_STATES.includes(activeState)) {
            timer = setTimeout(() => {
                dispatch({ event: 'TIMEOUT' });
            }, RESPONSE_TIMEOUT);
        }

        return () => {
            clearTimeout(timer);
        };
    }, [activeState, dispatch]);
};
