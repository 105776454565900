import React from 'react';
import { Container, Section, Stack } from '@fiverr-private/layout_components';
import { Heading, Text, Typography } from '@fiverr-private/typography';
import { Button } from '@fiverr-private/button';
import { I18n } from '@fiverr-private/i18n-react';
import pathfinder from '@fiverr-private/pathfinder';
import { ClientInitialProps } from '@fiverr-private/listing_types';
import { ROLLOUTS } from '@fiverr-private/listing_experiments';
import { EmptySearchResultsIcon } from './components/EmptySearchResultsIcon';
import { GenerateBrief } from './components/GenerateBrief';
import { OrDivider } from './components/OrDivider';

interface EmptySearchResultsProps {
    rollouts: ClientInitialProps['rollouts'];
    currency: ClientInitialProps['currency'];
}

export const EmptySearchResults = ({ currency, rollouts }: EmptySearchResultsProps) => {
    const createBriefLink = pathfinder('create_brief_and_match_page', undefined, {
        query: {
            source: 'search_not_found',
        },
    });

    const shouldShowBriefMatch = rollouts[ROLLOUTS.MUSTANGS_SHOW_BRIEF_AND_MATCH];
    const shouldShowDynamicMatching = rollouts[ROLLOUTS.MUSTANGS_MARKETPLACE_DYNAMIC_MATCHING];

    return (
        <Section>
            <Stack paddingY="8" alignItems="center" direction="column" gap="10">
                <Stack alignItems="center" direction="column" gap="10">
                    {!shouldShowDynamicMatching && (
                        <Container width={{ default: '158px', sm: '201px' }}>
                            <EmptySearchResultsIcon />
                        </Container>
                    )}

                    <Stack alignItems="center" direction="column" gap="6">
                        <Stack alignItems="center" direction="column" gap="2">
                            <Heading width={{ default: '100%', sm: '326px' }} as="h5" textAlign="center">
                                <I18n k="listing_results.empty_results.empty_search_results.title" />
                            </Heading>
                            <Text width={{ default: '100%', sm: '380px' }} color="bodySecondary" textAlign="center">
                                <I18n k="listing_results.empty_results.empty_search_results.sub_title" />
                            </Text>
                        </Stack>
                        {shouldShowBriefMatch && (
                            <Button href={createBriefLink} target="_self">
                                <I18n k="listing_results.empty_results.empty_search_results.button" />
                            </Button>
                        )}
                    </Stack>
                </Stack>

                {shouldShowDynamicMatching && (
                    <>
                        <OrDivider />
                        <Typography fontSize="b_md" color="grey_1200">
                            <I18n k="listing_results.empty_results.empty_search_results.dynamic_matching.header" />
                        </Typography>
                        <GenerateBrief currency={currency} />
                    </>
                )}
            </Stack>
        </Section>
    );
};
