import React, { useContext, useState } from 'react';
import { isEmpty } from 'lodash';
import { array, arrayOf, func, string, shape, any } from 'prop-types';
import { URI } from '@fiverr-private/futile';
import { logger } from '@fiverr-private/obs';
import { AppContext } from '../../../../../context/listingsContext';
import { trackLeafCategoryFilter } from '../../../leafCategoryTile/utils';
import Header from '../shared/header';
import Footer from '../shared/footer';
import List from '../shared/list';

const ScListModal = ({ close, items, initialValue = '', urlParams = [] }) => {
    const [selected, setSelected] = useState(initialValue);
    const { displayQuery } = useContext(AppContext);

    const onSelect = (item) => {
        setSelected(item);
    };

    const onApply = () => {
        const selectedSc = items.find((sc) => sc.id === selected);

        if (selectedSc) {
            const { alias, id } = selectedSc;
            trackLeafCategoryFilter(alias, id);

            if (isEmpty(urlParams)) {
                window.location.href = selectedSc.url;
            } else {
                const uri = new URI(selectedSc.url);

                urlParams.forEach(({ key, value }) => {
                    try {
                        uri.addParams({ [key]: value });
                    } catch (e) {
                        logger.error(`Failed to add url param in sc_list_modal. param:[${key}=${value}]`);
                    }
                });

                window.location.href = uri.href;
            }
        }

        close();
    };

    return (
        <>
            <Header
                close={close}
                params={{ query: displayQuery }}
                titleKey="search_perseus.subcategory_tiles.view_all.title"
            />
            <List items={items} selected={selected} onSelect={onSelect} />
            <Footer setSelected={setSelected} onApply={onApply} className="hide-shadow hide-clear-all" />
        </>
    );
};

ScListModal.propTypes = {
    close: func,
    items: array,
    initialValue: string,
    urlParams: arrayOf(shape({ key: string, value: any })),
};

export default ScListModal;
