import { queryParamToString } from '../queryParamToString/queryParamToString';

export const queryParamToBoolean = (queryParam: unknown): boolean | undefined => {
    if (!queryParam) {
        return;
    }

    const queryParamString = queryParamToString(queryParam);

    if (queryParamString === 'true') {
        return true;
    }

    if (queryParamString === 'false') {
        return false;
    }
};
