import React from 'react';
import { getContext } from '@fiverr-private/fiverr_context';
import { I18n } from '@fiverr-private/i18n-react';
import { Button } from '@fiverr-private/button';
import { useUpgradeClick } from './hooks/useUpgradeClick';

const T_PREFIX = 'search_perseus.business_listings.banners';

interface UpgradeButtonProps extends Partial<React.ComponentProps<typeof Button>> {}

export const UpgradeButton: React.FC<UpgradeButtonProps & { isLoading?: boolean }> = ({
    isLoading = false,
    ...buttonProps
}) => {
    const { userId } = getContext();
    const { onUpgradeClick, isLoading: isLoadingClick } = useUpgradeClick();

    return (
        <Button onClick={onUpgradeClick} loading={isLoading || isLoadingClick} {...buttonProps}>
            {userId ? (
                <I18n k={`${T_PREFIX}.hourly_banner.upgrade_for_free`} />
            ) : (
                <I18n k={`${T_PREFIX}.hourly_banner.explore_hourly_rates`} />
            )}
        </Button>
    );
};
