import { GoSearcherRoute, SearchPerseusConfig } from '@fiverr-private/listing_types';
import { validateCategoryIds } from '../../util';
import { GigFlow } from './GigFlow';

export class GigCategoriesFlow extends GigFlow {
    timeout?: number;
    metricRouteKey: string;
    servicePath: string;

    route: GoSearcherRoute = 'categories';
    validateRequest = validateCategoryIds;
    fetchRelatedSearchTerms = () => Promise.resolve([]);

    constructor(serviceConfigs: SearchPerseusConfig) {
        super('gigCategoriesFlow', serviceConfigs);

        const { search_service: searchService } = serviceConfigs;

        const { timeout, name, url } = searchService;

        if (timeout?.[this.route]) {
            this.timeout = timeout[this.route];
        }

        this.metricRouteKey = `${name}.${this.route}`;
        this.servicePath = `${url}/v5/${this.route}`;
    }
}
