import React from 'react';
import { get } from 'lodash';
import { Icon } from '@fiverr-private/fit';
import { s_calendar } from '@fiverr-private/icons';
import { getContext } from '@fiverr-private/fiverr_context';
import { I18n } from '@fiverr-private/i18n-react';
import { dateTimeFormat, DATETIME_ELEMENT_STYLES } from '@fiverr-private/localization';
import { formatDate } from '@fiverr-private/futile/lib/datetime';
import { orange_1100 } from '@fiverr-private/sass/helpers';
import { HIGHLIGHTS_DATA_KEYS } from '../../../shared/constants';
import { highlightsDataType } from '../types';

const OnVacation = ({ highlightsData } = {}) => {
    const endDate = get(highlightsData, `${HIGHLIGHTS_DATA_KEYS.VACATION_END_DATE}.endsAt`);
    const dateInMs = new Date(endDate * 1000); /** sellers phoenix holds the time in seconds we need to convert to ms */
    const { locale } = getContext();
    const date = dateTimeFormat(dateInMs, {
        formattingLocale: locale,
        year: DATETIME_ELEMENT_STYLES.NUMERIC,
        month: DATETIME_ELEMENT_STYLES.SHORT,
        day: DATETIME_ELEMENT_STYLES.NUMERIC,
        fallbackValue: formatDate(dateInMs, { locale }),
    });

    return (
        <>
            <Icon className={'seller-highlight-icon'} color={orange_1100}>
                {s_calendar}
            </Icon>
            <I18n k={'listings.highlights.on_vacation'} params={{ date }} />
        </>
    );
};

OnVacation.propTypes = {
    highlightsData: highlightsDataType,
};

export default OnVacation;
