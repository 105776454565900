import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import './index.scss';

const isIntersectionObserverExists = typeof IntersectionObserver !== 'undefined';

const StickyComponent = ({ children }) => {
    const [isSticky, setIsSticky] = useState(false);
    const containerDiv = useRef(null);

    const onElementSticky = ([entry]) => {
        const isIntersectingFromTop = entry.intersectionRatio < 1 && entry.intersectionRect.y === 0;

        if (isIntersectingFromTop) {
            setIsSticky(true);
        } else {
            setIsSticky(false);
        }
    };

    const observer = useRef(
        isIntersectionObserverExists && new IntersectionObserver(onElementSticky, { threshold: 1 })
    );

    useEffect(() => {
        isIntersectionObserverExists && observer.current.observe(containerDiv.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={classNames('sticky-hoc', { 'box-shadow-z4': isSticky })} ref={containerDiv}>
            <div className="sticky-wrapper">{children}</div>
        </div>
    );
};

export default StickyComponent;
