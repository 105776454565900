import React from 'react';
import { getContext } from '@fiverr-private/fiverr_context';
import { Tag } from '@fiverr-private/data_display';
import { getTranslation, isNonEmptyString } from './utils';
import propTypes from './types';

/**
 * A component that shows one of expertise by a given type.
 */
const ExpertiseTag = ({ type, id, parentId, translatedName }) => {
    const { locale } = getContext();
    const translation = getTranslation({ type, id, parentId, translatedName, locale });

    return (
        isNonEmptyString(translation) && (
            <Tag size="sm" variant="outline">
                <div className="expertise-text-wrapper">{translation}</div>
            </Tag>
        )
    );
};

ExpertiseTag.propTypes = propTypes;

export default ExpertiseTag;
