import { ALLOCATIONS, EXPERIMENTS } from '@fiverr-private/listing_experiments';

function getSharedExperimentConditions(isBusiness, isTouch) {
    return !isTouch && !isBusiness;
}

function isInCaterpillarsToggleRemovalTestWithProRemoved(abTests, isBusiness, isTouch) {
    return (
        getSharedExperimentConditions(isBusiness, isTouch) &&
        (Number(abTests[EXPERIMENTS.CAT_ONLINE_PRO_TOGGLE_REMOVAL]) === Number(ALLOCATIONS.TEST_B) ||
            Number(abTests[EXPERIMENTS.CAT_ONLINE_PRO_TOGGLE_REMOVAL]) === Number(ALLOCATIONS.TEST_D))
    );
}

export { isInCaterpillarsToggleRemovalTestWithProRemoved };
