import { useEffect } from 'react';
import { getContext } from '@fiverr-private/fiverr_context';
import { bqEvent } from '../../../../shared/services/BIEvents/utils';
import { BI_PAGE_NAMES } from '../../../../shared/services/BIEvents/constants';
import { TAG_ENTITIES, COMPONENT_NAMES } from '../../components/generic_listings/TagFilters/constants';
import { ELEMENT_TYPES, EVENT_GROUP, EVENT_TYPE, ACTION_TYPE } from './constants';

const checkVisualFilters = (visualFilters) => {
    const { visualFiltersList = [] } = visualFilters;
    return !!visualFiltersList.find(({ isMetadataIncluded }) => !isMetadataIncluded);
};

export const useReportEligibility = ({ visualFilters = {}, filterTagsProps = {}, isSearch, shownExposedFilter }) => {
    const { pageCtxId, queryParameters: { ref_ctx_id: refCtxId } = {} } = getContext();

    useEffect(() => {
        let elementType;
        let componentName;
        const isEligibileForVisualFilters = isSearch
            ? checkVisualFilters(visualFilters)
            : filterTagsProps.entity === TAG_ENTITIES.VISUAL_FILTERS;

        if (isEligibileForVisualFilters) {
            elementType = ELEMENT_TYPES.METADATA_FILTER;
            componentName = COMPONENT_NAMES.VISUAL_FILTERS;
        }

        if (filterTagsProps.entity === TAG_ENTITIES.SLC_FILTERS) {
            elementType = ELEMENT_TYPES.SLC_FILTER;
        }

        if (filterTagsProps.entity === TAG_ENTITIES.LEAF_CATEGORIES) {
            elementType = ELEMENT_TYPES.LEAF_CATEGORIES_FILTER;
        }

        // shownExposedFilter's type is ExposedFilter from listing_types package
        switch (shownExposedFilter) {
            case 'VISUAL_FILTERS':
                elementType = ELEMENT_TYPES.VISUAL_FILTERS;
                componentName = COMPONENT_NAMES.METADATA;

                break;
            case 'METADATA_FILTERS':
                elementType = ELEMENT_TYPES.EXPOSED_METADATA_FILTERS;
                componentName = COMPONENT_NAMES.METADATA;

                break;
            case 'EXPLICIT_LANGUAGE_PAIR':
                elementType = ELEMENT_TYPES.LANGUAGE_PAIR;

                break;
            case 'LEAF_CATEGORIES':
                elementType = ELEMENT_TYPES.LEAF_CATEGORIES_FILTER;

                break;
            case 'NESTED_SUB_CATEGORIES':
                elementType = ELEMENT_TYPES.SLC_FILTER;

                break;

            case 'PROFESSION_FILTERS':
                elementType = ELEMENT_TYPES.PROFESSION_FILTERS;

                break;

            default:
        }

        if (!elementType) {
            return;
        }

        bqEvent({
            type: EVENT_TYPE,
            group: EVENT_GROUP,
            actionType: ACTION_TYPE,
            page: {
                name: BI_PAGE_NAMES.LISTINGS,
                ctx_id: pageCtxId,
                element: {
                    type: elementType,
                },
                ...(componentName && {
                    component: {
                        name: componentName,
                    },
                }),
                ...(refCtxId && {
                    referrer: {
                        ctx_id: refCtxId,
                    },
                }),
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
