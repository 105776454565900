import { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { assetType, attachmentType, filteredDeliveryAttachmentType } from '../../../shared/types';
import { ASSET_TYPES } from '../../../shared/constants';
import { combineAssets } from '../../AssetsCarousel/utils';
import { ASSET_IMG_TRANSFORMATION, ATTACHMENT_IMG_TRANSFORMATION, SOURCE_TYPES } from './constants';

export const useGigServiceCardLightbox = ({ assets = [], attachments = [], filteredDeliveryAttachments = [] }) => {
    const [isPreviewOpened, setIsPreviewOpened] = useState(false);
    const [sourceInitialIndex, setSourceInitialIndex] = useState(0);

    const openPreview = useCallback(() => {
        setIsPreviewOpened(true);
    }, []);

    const closePreview = useCallback(() => setIsPreviewOpened(false), []);

    const combinedAssets = useMemo(
        () => combineAssets({ assets, attachments, filteredDeliveryAttachments }),
        [assets, attachments, filteredDeliveryAttachments]
    );

    const getAssetSrc = useCallback(
        (asset) => {
            // TODO: We don't want built-in img transformations - this should be autofixed after migrating to new WIP api
            if (asset.type === ASSET_TYPES.IMAGE) {
                return asset.cloudImgMainGig?.replace(
                    new RegExp([ASSET_IMG_TRANSFORMATION, ATTACHMENT_IMG_TRANSFORMATION].join('|')),
                    ''
                );
            }
            if (asset.type === ASSET_TYPES.VIDEO || asset.type === ASSET_TYPES.AUDIO) {
                return asset.attachmentId
                    ? combinedAssets.attachments.find(({ id }) => id === asset.attachmentId)?.streamUrl
                    : asset.streamUrl;
            }
        },
        [combinedAssets]
    );

    const sources = useMemo(
        () =>
            combinedAssets.assets.map((asset) => ({
                src: getAssetSrc(asset),
                type: SOURCE_TYPES[asset.type],
            })),
        [combinedAssets, getAssetSrc]
    );

    return {
        sources,
        sourceInitialIndex,
        setSourceInitialIndex,
        isPreviewOpened,
        openPreview,
        closePreview,
    };
};

useGigServiceCardLightbox.propTypes = {
    assets: PropTypes.arrayOf(assetType),
    attachments: PropTypes.arrayOf(attachmentType),
    filteredDeliveryAttachments: PropTypes.arrayOf(filteredDeliveryAttachmentType),
};
