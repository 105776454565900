const RECOMMENDATIONS_ROUTE = 'recommendations/listings';
const API_ROUTE = 'api/v1';
const V2_API_ROUTE = 'api/v2';
const SEARCH_ROUTE = 'search/listings';
const SEARCH_FILTERS = {
    delivery_time: 'filtered_duration',
    package_includes: 'filtered_factors',
    gig_price_range: 'filtered_price',
};

const EMPTY_RESPONSE = {
    gigs: [],
};

const STATUS = {
    OK: 200,
    BAD_REQUEST: 400,
    INTERNAL_SERVER_ERROR: 500,
    PARTIAL: 207,
};

const TIMEOUT_POLICY = ['HIGH', 'MEDIUM', 'LOW'];

const TIER_SELECTION = {
    RECOMMENDED: 'recommended',
};

const DEFAULT_TIMEOUT_POLICY = 'MEDIUM';
const TRANSLATION_LAYERS_FETCH_TIMEOUT = 200;
const SELLERS_DATA_FETCH_TIMEOUT = 150;
const ERR_TIMEOUT = 'request-timeout';

const ERRORS = {
    LISTINGS_PHOENIX_URL_MISSING: 'listingsPhoenixUrl is required string value parameter',
    PACKAGES_MISS_MATCH_GIGS: 'PackageIds array length must match GigIds array length',
    UGC_TRANSLATION_VALIDATIONS: 'Failed in UGC translation validations',
    MACHINE_TRANSLATION_FAILED: 'Machine translation service failed to translate',
};

const RATING_ALGO_TYPE = 1;

module.exports = {
    RECOMMENDATIONS_ROUTE,
    API_ROUTE,
    V2_API_ROUTE,
    SEARCH_ROUTE,
    SEARCH_FILTERS,
    ERRORS,
    EMPTY_RESPONSE,
    STATUS,
    TIMEOUT_POLICY,
    DEFAULT_TIMEOUT_POLICY,
    TRANSLATION_LAYERS_FETCH_TIMEOUT,
    SELLERS_DATA_FETCH_TIMEOUT,
    TIER_SELECTION,
    RATING_ALGO_TYPE,
    ERR_TIMEOUT,
};
