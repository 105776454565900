import { merge } from 'lodash';
import { bigQuery } from '@fiverr-private/obs';
import { getContext } from '@fiverr-private/fiverr_context';
import { BI_CONSTS } from '@fiverr-private/listing_lib';
import { getBqEventSource, getEventPayload } from '../biUtils';
import {
    BI_ACTION_TYPES,
    BI_EVENT_GROUPS,
    BI_EVENTS_SUB_GROUPS,
} from '../../../../../shared/services/BIEvents/constants';
import { MAX_ITEMS_TO_SHOW_NO_RESULTS } from '../../../constants';
import { bannerTypeToEvent } from './util';

const FEATURE_NAME = 'fiverr_pro';

export const getImpressionEventPayload = (isExpertListings, resultsCount, hasActiveFilters, position, bannerType) => {
    const basePayLoad = getEventPayload({
        type: bannerTypeToEvent[bannerType],
        group: BI_EVENT_GROUPS.USER_IMPRESSION,
        actionType: BI_ACTION_TYPES.IMPRESSION,
        isExpertListings,
    });
    const impressionEventPayload = {
        item_context: {
            position,
        },
        gig_impression: {
            listings: {
                filters: hasActiveFilters,
            },
        },
    };
    const sharedPayload = getSharedPayload(resultsCount, isExpertListings);

    return merge(basePayLoad, impressionEventPayload, sharedPayload);
};

export const getSharedPayload = (resultsCount, isExpertListings) => {
    const { pageCtxId, userId, userGuid } = getContext();

    return {
        sub_group: BI_EVENTS_SUB_GROUPS.FIVERR_PRO,
        feature: {
            name: FEATURE_NAME,
        },
        user: {
            id: userId,
        },
        uid: userGuid,
        page: {
            ctx_id: pageCtxId,
            component: {
                num_of_elements: resultsCount,
                type: getPageComponentType(resultsCount),
            },
        },
        event_source: getBqEventSource(isExpertListings),
        out_of_session: false,
    };
};
const getPageComponentType = (resultsCount) => {
    if (resultsCount === 0) {
        return 'no_results';
    } else if (resultsCount <= MAX_ITEMS_TO_SHOW_NO_RESULTS) {
        return 'low_supply';
    }
    return 'regular';
};

export const reportBigQueryBannerClick = (type, isExpertListings, resultsCount, hasActiveFilters, position) => {
    const basePayLoad = getEventPayload({
        type,
        group: BI_EVENT_GROUPS.USER_ACTION,
        actionType: BI_ACTION_TYPES.CLICK,
        isExpertListings,
    });
    const sharedPayload = getSharedPayload(resultsCount, isExpertListings);
    const buttonClickEventPayload = {
        page: {
            element: {
                type: BI_CONSTS.BUTTON,
            },
            component: {
                position,
            },
        },
        listings: {
            filters: hasActiveFilters,
        },
    };

    const payload = merge(basePayLoad, buttonClickEventPayload, sharedPayload);
    bigQuery.send(payload);
};
