import { isObject } from 'lodash';
import { FILTER } from '../constants';

export const transformServiceOfferingsToBooleans = (change = {}) => {
    const serviceOfferingsId = FILTER.SERVICE_OFFERINGS.ID;

    if (isObject(change) && serviceOfferingsId in change) {
        const { service_offerings: serviceOfferings = [] } = change;

        const filtersWithServiceOfferingBooleans = {
            ...change,
            subscription: [],
            offer_consultation: [],
        };
        delete filtersWithServiceOfferingBooleans[serviceOfferingsId];

        serviceOfferings.forEach((key) => filtersWithServiceOfferingBooleans[key].push('true'));

        return filtersWithServiceOfferingBooleans;
    }

    return change;
};
