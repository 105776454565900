import React from 'react';
import { isEmpty } from 'lodash';
import TagFilter from './TagFilter';
import TranslationFilter from './Translation';

import styles from './index.module.scss';

const comboBoxFilterIds = ['language', 'country'];

const shouldDisplayTranslationFilter = (languageFilter) => {
    if (isEmpty(languageFilter)) {
        return false;
    }

    const { fromOptions = [], toOptions = [] } = languageFilter.options ?? {};

    return !(isEmpty(fromOptions) || isEmpty(toOptions));
};

const VisualFilters = ({
    visualFilters = {},
    translationFilter = {},
    activeFilters,
    filters,
    subCategory,
    applyFilters,
}) => {
    const { visualFiltersList = [] } = visualFilters;

    const displayTranslationFilter = shouldDisplayTranslationFilter(translationFilter);
    const displayVisualFiltersList = !isEmpty(visualFiltersList);

    if (!displayTranslationFilter && !displayVisualFiltersList) {
        return null;
    }

    return (
        <div className={styles.visualFiltersContainer}>
            {displayTranslationFilter && <TranslationFilter filters={filters} applyFilters={applyFilters} />}
            {displayVisualFiltersList &&
                visualFiltersList.map((filter) => (
                    <TagFilter
                        key={filter.id}
                        filter={filter}
                        allFilters={filters}
                        activeFilters={activeFilters}
                        subCategoryId={subCategory.id}
                        applyFilters={applyFilters}
                        withComboBox={comboBoxFilterIds.includes(filter.id)}
                    />
                ))}
        </div>
    );
};

export default VisualFilters;
