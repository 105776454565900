import React from 'react';
import { object } from 'prop-types';
import { useImpressionItem } from '@fiverr-private/impressionable';
import { getContext } from '@fiverr-private/fiverr_context';
import NscTile from '../tiles/nsc_tile';
import TilesCarousel from '../tiles_carousel';
import { BI_ELEMENTS_NAMES, BI_EVENTS_TYPES, ELEMENT_TYPE } from '../../generic_listings/TagFilters/constants';
import { BI_EVENT_GROUPS } from '../../../../../shared/services/BIEvents/constants';
import { buildEventEnrichment } from '../../generic_listings/TagFilters/utils';
import { MAX_TILES_TO_SHOW, MOBILE_SLIDE_PADDING, SLIDE_PADDING } from '../tiles_carousel/constants';

import './index.scss';

const NscTilesCarousel = (props) => {
    const { isTouch } = getContext();
    const enrichment = buildEventEnrichment({
        type: BI_EVENTS_TYPES.IMPRESSION,
        group: BI_EVENT_GROUPS.USER_IMPRESSION,
        elementName: BI_ELEMENTS_NAMES.LEAF_CATEGORY,
        elementType: ELEMENT_TYPE.VISUAL,
    });
    const [ref] = useImpressionItem({ enrichment });

    return (
        <div ref={ref} className="nsc-carousel">
            <div className="nsc-carousel-wrapper">
                <TilesCarousel
                    {...props}
                    TileComponent={NscTile}
                    tilePadding={isTouch ? MOBILE_SLIDE_PADDING : SLIDE_PADDING}
                    maxTilesToShow={MAX_TILES_TO_SHOW}
                />
            </div>
        </div>
    );
};

NscTilesCarousel.propTypes = {
    props: object,
};

export default NscTilesCarousel;
