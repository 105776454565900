import React from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import { InfoCircleSolidIcon } from '@fiverr-private/visuals';

import styles from './NoOptions.module.scss';

const NoOptions = () => (
    <div className={styles.container}>
        <InfoCircleSolidIcon color="grey_800" />
        <span>
            <I18n k="search_perseus.filter.no_results" />
        </span>
    </div>
);

export default NoOptions;
