import React, { useContext, useEffect, useState } from 'react';
import classnames from 'classnames';
import { I18n } from '@fiverr-private/i18n-react';
import { Button } from '@fiverr-private/button';
import { getContext } from '@fiverr-private/fiverr_context';
import { useImpressionItem } from '@fiverr-private/impressionable';
import { ArrowRightIcon } from '@fiverr-private/icons';
import { theme } from '@fiverr-private/theme/';
import pathfinder from '@fiverr-private/pathfinder';
import { tracker, EXPERT_BANNER_TYPE } from '@fiverr-private/listing_lib';
import { PATHFINDER } from '../../../config/filters';
import { getWindow } from '../../../../../shared/utils';
import { AppContext } from '../../context/listingsContext';
import { getUserTeamData } from '../../../service/getUserTeamData';
import { reportBriefClickedToBigQuery } from '../BusinessPageLayout/ItemsContainer/NoResults/bqReports';
import { getBqEventSource } from '../biUtils';
import { getImpressionEventPayload, reportBigQueryBannerClick } from './bqReports';

import styles from './index.module.scss';

export const BannerMode = {
    NO_RESULTS_PAGE: 'no results page',
    PRO_LISTINGS: 'pro-listings',
    FULL_CATALOG: 'full-catalog',
};

export const BI_EVENT_TYPES = {
    PROJECT_PARTNER_BANNER_IS_SHOWN: 'project_partner_banner_is_shown',
    BRIEF_AND_MATCH_BANNER_IS_SHOWN: 'brief_and_match_banner_is_shown',
    AI_SERVICE_BANNER_IS_SHOWN: 'ai_service_banner_is_shown',
    CLICKED_ON_FIVERR_PRO_FIND_PROJECT_PARTNER: 'clicked_on_fiverr_pro_find_project_partner',
    CLICKED_ON_FIVERR_PRO_BRIEF_AND_MATCH: 'clicked_on_fiverr_pro_brief_and_match',
    CLICK_ON_AI_SERVICE_BANNER: 'clicked_on_fiverr_pro_ai_service',
};

export const bannerTypeToEvent = {
    [EXPERT_BANNER_TYPE.BRIEF]: BI_EVENT_TYPES.BRIEF_AND_MATCH_BANNER_IS_SHOWN,
    [EXPERT_BANNER_TYPE.PROJECT_PARTNERS]: BI_EVENT_TYPES.PROJECT_PARTNER_BANNER_IS_SHOWN,
    [EXPERT_BANNER_TYPE.AI_CONSULTATION]: BI_EVENT_TYPES.AI_SERVICE_BANNER_IS_SHOWN,
};

export const T_PREFIX = 'search_perseus.business_listings.banners';

export const Text = ({ titleKey, descriptionKey }) => (
    <div className={classnames(styles.textWrapper, 'm-l-20')}>
        <p className={classnames('text-display-6', 'm-b-4', styles.title)}>
            <I18n k={titleKey} />
        </p>
        <p className={classnames('tbody-6', styles.description)}>
            <I18n k={descriptionKey} />
        </p>
    </div>
);

export const IconWrapper = ({ children }) => (
    <div className={classnames(styles.iconWrapper, 'flex flex-items-center')}>{children}</div>
);

export const mixPanelBasePayload = (role, isExpertListings) => ({
    'User role': role,
    source: isExpertListings ? 'pro listing' : 'full catalog',
});

export const useFetchUserData = () => {
    const [userData, setUserData] = useState(null);
    useEffect(() => {
        if (!getContext().userId) {
            setUserData({});
            return;
        }

        getUserTeamData(getContext().userId)
            .then((data) => setUserData(data))
            .catch(() => setUserData({}));
    }, []);
    return userData;
};
export const BannerContainer = ({
    onClick,
    resultsCount,
    hasActiveFilters,
    position,
    bannerType,
    bannerMode,
    children,
    disableStyles,
}) => {
    const { isExpertListings } = useContext(AppContext);
    const userData = useFetchUserData();
    const [mainContainerRef] = useImpressionItem({
        enrichment: getImpressionEventPayload(isExpertListings, resultsCount, hasActiveFilters, position, bannerType),
        onImpression: () => {
            tracker.trackBannerImpression(bannerType, mixPanelBasePayload(userData?.role, isExpertListings));
        },
    });

    return (
        <div
            ref={mainContainerRef}
            onClick={onClick}
            className={
                disableStyles
                    ? ''
                    : classnames('flex flex-row flex-items-center flex-justify-between p-24', styles.container, {
                          'm-t-12 m-b-12': bannerMode === BannerMode.PRO_LISTINGS,
                          [styles.fullCatalogContainer]: bannerMode === BannerMode.FULL_CATALOG,
                          [styles.noResultsContainer]: bannerMode === BannerMode.NO_RESULTS_PAGE,
                      })
            }
        >
            {children}
        </div>
    );
};

export const RightElement = ({ ctaKey, bannerMode }) => {
    if (bannerMode === BannerMode.NO_RESULTS_PAGE) {
        return (
            <div className={styles.rightElement}>
                <ArrowRightIcon color={theme.colors.grey_1200} />
            </div>
        );
    }

    return (
        <>
            <div className={classnames(styles.rightIcon, styles.rightElement)}>
                <ArrowRightIcon color={theme.colors.grey_1200} />
            </div>
            <Button
                className={classnames(styles.rightButton, styles.rightElement)}
                size="sm"
                intent="primary"
                variant="filled"
            >
                <I18n k={ctaKey} />
            </Button>
        </>
    );
};

export const openBrief = ({
    role = 'member',
    isExpertListings,
    resultsCount,
    hasActiveFilters,
    position = 0,
    isBanner = true,
    buttonText,
}) => {
    tracker.trackBriefBannerClick(mixPanelBasePayload(role, isExpertListings));
    if (isBanner) {
        reportBigQueryBannerClick(
            BI_EVENT_TYPES.CLICKED_ON_FIVERR_PRO_BRIEF_AND_MATCH,
            isExpertListings,
            resultsCount,
            hasActiveFilters,
            position
        );
    } else {
        reportBriefClickedToBigQuery(isExpertListings, buttonText, resultsCount, hasActiveFilters);
    }

    const briefLink = pathfinder(PATHFINDER.CREATE_BRIEF_AND_MATCH_PAGE, null, {
        query: {
            source: getBqEventSource(isExpertListings),
        },
    });
    getWindow().open(briefLink, '_self');
};
