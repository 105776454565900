import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { string } from 'prop-types';
import { Icon } from '@fiverr-private/fit';
import { logger } from '@fiverr-private/obs';
import { I18n, translate } from '@fiverr-private/i18n-react';
import TooltipWrapper from '../../../shared/components/TooltipWrapper';
import './index.scss';

const getTooltipContent = (countryName) => {
    if (countryName) {
        return (
            <I18n
                k="gig_listings.gig_cards.seller_location"
                params={{ countryName }}
                templates={{
                    label: (text) => <strong>{text}</strong>,
                }}
            />
        );
    }

    return '';
};

const SellerCountryFlag = ({ country, className = '' }) => {
    const [flag, setFlag] = useState(null);
    const countryExists = country && typeof country === 'string';
    const countryName = countryExists ? translate(`countries.${country.toUpperCase()}`) : null;
    const tooltipContent = getTooltipContent(countryName);

    useEffect(() => {
        const importFlag = async () => {
            if (countryExists) {
                try {
                    const { [country.toLowerCase()]: importedFlag } = await import(
                        /* webpackChunkName: 'flags' */ '@fiverr-private/fit/icons/flags'
                    );

                    if (importedFlag) {
                        setFlag(importedFlag);
                    }
                } catch (error) {
                    error.description = `Failed to load the the seller country flag: ${country}`;
                    logger.warn(error);
                }
            }
        };

        importFlag();
    }, [country, countryExists]);

    return flag ? (
        <TooltipWrapper
            className="seller-language-tooltip"
            shouldRenderTooltip
            appendToBody={false}
            content={tooltipContent}
        >
            <div className={classNames('seller-flag', className)}>
                <Icon>{flag}</Icon>
            </div>
        </TooltipWrapper>
    ) : null;
};

SellerCountryFlag.propTypes = {
    country: string,
    className: string,
};

export default SellerCountryFlag;
