import { uniqBy } from 'lodash';
import { getContext } from '@fiverr-private/fiverr_context';
import { URI } from '@fiverr-private/futile';
import { raiseErrorToast } from '../../../../shared/utils/raiseToast';
import { maxios } from '../../../../shared/utils/request';
import { ROUTE_KEYS } from '../../../../shared/utils/request/constants';
import { EXPERT_LISTINGS_PAGINATION_ROUTE, EXPERT_LISTINGS_PAGES_LIMIT } from './constants';

export const onLoadMore = async ({ setHasMore, setCardItems, cardItems = [], paginationParams = {} }) => {
    try {
        const { queryParameters = {}, abTests = {} } = getContext();
        const page = paginationParams.page + 1;
        const pagination = { ...paginationParams, page };

        const url = new URI(EXPERT_LISTINGS_PAGINATION_ROUTE);
        url.addParams({ ...queryParameters, abTests: JSON.stringify(abTests) });

        let paginationData = {};

        if (page <= EXPERT_LISTINGS_PAGES_LIMIT) {
            const { data = {} } = await maxios.post(
                url,
                { pagination },
                { routeKey: ROUTE_KEYS.EXPERT_LISTINGS_PAGINATION }
            );
            const exceededPageLimit = page === EXPERT_LISTINGS_PAGES_LIMIT;

            paginationData = {
                ...data,
                hasMore: !exceededPageLimit && data.hasMore,
            };
        }

        const { offset = paginationParams.offset, hasMore = false, items = [] } = paginationData;

        setHasMore(hasMore);
        const newCardItems = uniqBy([...cardItems, ...items], 'seller.username');
        setCardItems(newCardItems);

        const newPaginationParams = {
            offset,
            page,
        };

        return newPaginationParams;
    } catch (error) {
        const errorKey = 'search_perseus.experts.errors.show_more_experts';
        raiseErrorToast(errorKey);
    }
};
