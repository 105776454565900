import { currencyFormat } from '@fiverr-private/localization';
import { CurrencyConverter } from '@fiverr-private/futile';

/**
 * Returns formatted currency according to the user selected currency.
 * For example if the user currency is `₪` and the amount is `10` then
 * the output would be `₪30`.
 */
export const convertFromUSD = (amount, currencyObj = {}, asNumber = false) => {
    const currency = { ...currencyObj, forceRound: true, forceRoundFromAmount: 0 };
    const currencyConverter = new CurrencyConverter(currency);
    const convertedAmount = amount * currency.rate;
    const fallbackValue = currencyConverter.convert(amount, { commaSeparate: true });

    return currencyFormat(convertedAmount, currency.name, {
        fallbackValue,
        noFractionDigits: currency.forceRound,
        asNumber,
    });
};
