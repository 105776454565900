import React, { useContext } from 'react';
import classNames from 'classnames';
import { getContext } from '@fiverr-private/fiverr_context';
import { I18n } from '@fiverr-private/i18n-react';
import { RelatedSearchTypes } from '../../../types';
import { AppContext } from '../../context/listingsContext';
import SearchTags from './SearchTags';

import './index.scss';

const RelatedSearch = () => {
    const { relatedSearches = [] } = useContext(AppContext);

    const { isTouch } = getContext();
    const hasSearchTags = relatedSearches.length !== 0;

    return (
        <header className="related-search">
            <nav className={classNames('layout-row', { 'scrollable-related-links': isTouch })}>
                {hasSearchTags && (
                    <>
                        {!isTouch && (
                            <b>
                                <I18n k="search_perseus.related_search.suggested" />
                            </b>
                        )}
                        <SearchTags relatedTerms={relatedSearches} />
                    </>
                )}
            </nav>
        </header>
    );
};

RelatedSearch.propTypes = RelatedSearchTypes;

export default RelatedSearch;
