import React, { Component } from 'react';
import request from '../../service/request';

const withRequestContext = (WrappedComponent) => {
    class WithRequestContext extends Component {
        constructor(props) {
            super(props);

            const { requestContext } = props;

            request.initialize(requestContext);
        }

        render() {
            return <WrappedComponent {...this.props} />;
        }
    }

    return WithRequestContext;
};

export default withRequestContext;
