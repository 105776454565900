import threeDimentional from './images/three_dimentional.svg';
import flat_minimalist from './images/flat_minimalist.svg';
import hand_drawn from './images/hand_drawn.svg';
import mascot_cartoon from './images/mascot_cartoon.svg';
import retro from './images/retro.svg';
import signature from './images/signature.svg';
import watercolor_feminine from './images/watercolor_feminine.svg';
import thumbnails from './images/thumbnails.svg';
import panels from './images/panels.svg';
import overlays_screen_packs from './images/overlays_screen_packs.svg';
import emotes_badges from './images/emotes_badges.svg';
import banners from './images/banners.svg';
import alerts from './images/alerts.svg';
import ads_social_media_videos from './images/ads_social_media_videos.svg';
import corporate_videos from './images/corporate_videos.svg';
import elearning from './images/elearning.svg';
import event_videos from './images/event_videos.svg';
import explainer_videos from './images/explainer_videos.svg';
import family_videos from './images/family_videos.svg';
import gaming_videos from './images/gaming_videos.svg';
import movie_trailers from './images/movie_trailers.svg';
import music_videos from './images/music_videos.svg';
import showreels from './images/showreels.svg';
import fiction_films from './images/fiction_films.svg';
import vr_360_video from './images/vr_360_video.svg';
import view_all from './images/view_all.svg';
import male from './images/male.svg';
import female from './images/female.svg';
import arabic from './images/arabic.svg';
import english from './images/english.svg';
import french from './images/french.svg';
import german from './images/german.svg';
import italian from './images/italian.svg';
import spanish from './images/spanish.svg';
import dutch from './images/dutch.svg';
import beauty_fashion from './images/beauty_fashion.svg';
import business_finance_law from './images/business_finance_law.svg';
import health_medical from './images/health_medical.svg';
import internet_technology from './images/internet_technology.svg';
import lifestyle from './images/lifestyle.svg';
import marketing from './images/marketing.svg';
import facebook from './images/facebook.svg';
import twitter from './images/twitter.svg';
import etsy from './images/etsy.svg';
import instagram from './images/instagram.svg';
import linkedin from './images/linkedin.svg';
import youtube from './images/youtube.svg';
import investor_pitch_fundraising from './images/investor_pitch_fundraising.svg';
import business_proposal from './images/business_proposal.svg';
import education_training from './images/education_training.svg';
import sales from './images/sales.svg';
import conference from './images/conference.svg';
import shopify from './images/shopify.svg';
import amazon from './images/amazon.svg';
import ebay from './images/ebay.svg';
import woocommerce from './images/woocommerce.svg';
import wix from './images/wix.svg';
import wordpress from './images/wordpress.svg';
import magento from './images/magento.svg';
import opencart from './images/opencart.svg';
import bigcommerce from './images/bigcommerce.svg';
import prestashop from './images/prestashop.svg';
import squarespace from './images/squarespace.svg';
import godaddy from './images/godaddy.svg';
import sitebuilder from './images/sitebuilder.svg';
import webflow from './images/webflow.svg';
import blog from './images/blog.svg';
import business from './images/business.svg';
import education from './images/education.svg';
import portfolio from './images/portfolio.svg';
import entertainment from './images/entertainment.svg';
import wedding from './images/wedding.svg';
import sports_games from './images/sport_games.svg';
import twoD from './images/twoD.svg';
import threeD from './images/threeD.svg';
import guitar from './images/guitar.svg';
import drums from './images/drums.svg';
import bass from './images/bass.svg';
import piano from './images/piano.svg';
import keyboards from './images/keyboards.svg';
import pop from './images/pop.svg';
import hip_hop from './images/hip_hop.svg';
import rap from './images/rap.svg';
import r_b from './images/r_b.svg';
import edm from './images/edm.svg';
import illustrative from './images/illustrative.svg';
import photographic from './images/photographic.svg';
import typographic from './images/typographic.svg';
import digital from './images/digital-pattern.svg';
import hand_drawn_pattern from './images/hand_drawn-pattern.svg';
import for_children from './images/for_children.svg';
import realistic_drawing from './images/realistic_drawing.png';
import comic_cartoon from './images/comic_cartoon.png';
import manga from './images/manga.png';
import flat from './images/flat.png';
import line_art from './images/line_art.png';
import sketch from './images/sketch.png';
import mysql from './images/mysql.svg';
import ms_sql from './images/ms_sql.svg';
import access from './images/access.svg';
import oracle from './images/oracle.svg';
import sqlite from './images/sqlite.svg';
import postgres from './images/postgres.svg';
import mongo from './images/mongo.svg';
import teradata from './images/teradata.svg';
import freehand_digital from './images/freehand_digital.png';
import pen_ink from './images/pen_ink.png';
import vector_art from './images/vector_art.png';
import mixed_media from './images/mixed_media.png';
import watercolor from './images/watercolor.png';
import pencil from './images/pencil.png';
import fiction from './images/fiction.svg';
import non_fiction from './images/non_fiction.svg';
import self_help_wellness from './images/self_help_wellness.svg';
import children_s_books from './images/children_books.svg';
import science_fiction_fantasy from './images/science_fiction_fantasy.svg';
import romance from './images/romance.svg';
import google from './images/google.svg';
import bing from './images/bing.svg';
import excel from './images/excel.svg';
import word from './images/word.svg';
import power_bi from './images/power_bi.svg';
import google_data_studio from './images/google_data_studio.svg';
import arcgis from './images/arcgis.svg';
import qgis from './images/qgis.svg';
import google_earth_pro from './images/google_earth_pro.svg';
import tableau from './images/tableau.svg';
import d3_js from './images/dThree.svg';
import google_sheets from './images/google_sheets.svg';
import google_analytics from './images/google_analytics.svg';
import python from './images/python.svg';
import r from './images/r.svg';
import spss from './images/spss.svg';
import house from './images/house.svg';
import trap from './images/trap.svg';
import lofi from './images/lofi.svg';
import dancehall from './images/dancehall.svg';
import future_bass from './images/future_bass.svg';
import drill from './images/drill.svg';
import male_singer from './images/male_singer.svg';
import female_singer from './images/female_singer.svg';
import non_binary from './images/non_binary.svg';
import streamlabs_obs from './images/streamlabs_obs.svg';
import obs_studio from './images/obs_studio.svg';
import streamelements from './images/streamelements.svg';
import twitch_app from './images/twitch_app.svg';
import ios_android_dual from './images/ios_android_dual.svg';
import android_app from './images/android_app.svg';
import iphone_ipad_app from './images/iphone_ipad_app.svg';
import mobile_web from './images/mobile_web.svg';
import windows_mobile_app from './images/windows_mobile_app.svg';
import html_css from './images/html_css.svg';
import javascript from './images/javascript.svg';
import php from './images/php.svg';
import asp_net from './images/asp_net.svg';
import java from './images/java.svg';
import c from './images/c.svg';
import c_sharp from './images/c_sharp.svg';
import html from './images/html.svg';
import css from './images/css.svg';
import unisex from './images/unisex.svg';
import clothing from './images/clothing.svg';
import shoes from './images/shoes.svg';
import accessories from './images/accessories.svg';
import bags from './images/bags.svg';
import modular_systems_booth from './images/modular_systems_booth.svg';
import custom_made_booth from './images/custom_made_booth.svg';
import t_shirts from './images/t_shirts.svg';
import clothing_apparel from './images/clothing_apparel.svg';
import mugs from './images/mugs.svg';
import hats from './images/hats.svg';
import building from './images/building.svg';
import building_complex from './images/building_complex.svg';
import object from './images/object.svg';
import apartment from './images/apartment.svg';
import room from './images/room.svg';
import neighborhood from './images/neighborhood.svg';
import city from './images/city.svg';
import portrait from './images/portrait.svg';
import pet_portrait from './images/pet_portrait.svg';
import caricature from './images/caricature.svg';
import seller_provides_location from './images/white_background.svg';
import client_provides_location from './images/indoor_lifestyle.svg';
import screencasting from './images/outdoor_lifestyle.svg';
import three_hundred_sixty from './images/three_hundred_sixty.svg';
import black_background from './images/black_background.svg';
import animated from './images/animated.svg';
import live_action from './images/live_action.svg';
import generic from './images/generic.svg';
import tiktok from './images/tiktok.svg';
import snapchat from './images/snapchat.svg';
import pinterest from './images/pinterest.svg';
import illustration from './images/Illustration.svg';
import infographics from './images/infographics.svg';
import text_animation from './images/text_animation.svg';
import women from './images/women.svg';
import men from './images/men.svg';
import watercolor_pattern from './images/watercolor_pattern.svg';
import collage_pattern from './images/collage_pattern.svg';
import realistic_pattern from './images/realistic_pattern.svg';
import cartoon_pattern from './images/cartoon_pattern.svg';
import abstract_pattern from './images/abstract_pattern.svg';
import geometric from './images/geometric.svg';
import lettering from './images/lettering.svg';
import youtube_videos from './images/youtube_videos.svg';
import full_outfit from './images/full_outfit.svg';
import kids from './images/kids.svg';
import valorant from './images/valorant.svg';
import minecraft from './images/minecraft.svg';
import league_of_legends from './images/league_of_legends.svg';
import fortnite from './images/fortnite.svg';
import apex_legends from './images/apex_legends.svg';
import roblox from './images/roblox.svg';
import call_of_duty from './images/call_of_duty.svg';
import counter_strike from './images/counter_strike.svg';
import rocket_league from './images/rocket_league.svg';
import hoodies from './images/hoodies.svg';
import stickers from './images/stickers.svg';
import socks from './images/socks.svg';
import jerseys_sportswear from './images/jerseys_sportswear.svg';
import violin from './images/violin.svg';
import saxophone from './images/saxophone.svg';
import trumpet from './images/trumpet.svg';

export default {
    515: {
        wordpress,
        wix,
        webflow,
        shopify,
        woocommerce,
    },
    512: {
        valorant,
        minecraft,
        league_of_legends,
        fortnite,
        apex_legends,
        roblox,
        call_of_duty,
        counter_strike,
        rocket_league,
    },
    335: {
        english,
        german,
        spanish,
        french,
        italian,
    },
    228: {
        illustration,
        infographics,
        text_animation,
    },
    329: {
        facebook,
        generic,
        instagram,
        youtube,
        tiktok,
        snapchat,
        pinterest,
        linkedin,
        twitter,
    },
    234: {
        animated,
        live_action,
    },
    332: {
        seller_provides_location,
        client_provides_location,
        screencasting,
        black_background,
        360: three_hundred_sixty,
    },
    340: {
        portrait,
        pet_portrait,
        caricature,
    },
    150: {
        building,
        building_complex,
        object,
        apartment,
        neighborhood,
        room,
        city,
    },
    154: {
        t_shirts,
        clothing_apparel,
        mugs,
        accessories,
        hats,
        hoodies,
        stickers,
        socks,
        jerseys_sportswear,
    },
    419: {
        modular_systems_booth,
        custom_made_booth,
    },
    441: {
        women,
        men,
        unisex,
        clothing,
        shoes,
        accessories,
        bags,
        full_outfit,
        kids,
    },
    416: {
        python,
        c,
        javascript,
        java,
        html,
        css,
    },
    140: {
        html_css,
        javascript,
        python,
        php,
        asp_net,
        java,
        c: c_sharp,
    },
    141: {
        ios_android_dual,
        android_app,
        iphone_ipad_app,
        mobile_web,
        windows_mobile_app,
    },
    425: {
        streamlabs_obs,
        obs_studio,
        streamelements,
        twitch_app,
    },
    158: {
        male: male_singer,
        female: female_singer,
        non_binary,
    },
    432: {
        hip_hop,
        pop,
        r_b,
        edm,
        house,
        trap,
        lofi,
        dancehall,
        rap,
        future_bass,
        drill,
    },
    454: {
        excel,
        word,
        power_bi,
        google_data_studio,
        arcgis,
        qgis,
        google_earth_pro,
        tableau,
        d3_js,
        google_sheets,
        spss,
        r,
        google_analytics,
        python,
    },
    453: {
        excel,
        word,
        power_bi,
        google_data_studio,
        arcgis,
        qgis,
        google_earth_pro,
        tableau,
        d3_js,
        google_sheets,
        spss,
        r,
        google_analytics,
        python,
    },
    49: {
        '3d': threeDimentional,
        flat_minimalist,
        hand_drawn,
        mascot_cartoon,
        signature,
        retro,
        watercolor_feminine,
        geometric,
        lettering,
        view_all,
    },
    371: {
        thumbnails,
        panels,
        overlays_screen_packs,
        emotes_badges,
        banners,
        alerts,
        view_all,
    },
    99: {
        ads_social_media_videos,
        corporate_videos,
        elearning,
        event_videos,
        explainer_videos,
        family_videos,
        gaming_videos,
        movie_trailers,
        music_videos,
        fiction_films,
        showreels,
        vr_360_video,
        youtube_videos,
        view_all,
    },
    163: {
        beauty_fashion,
        business_finance_law,
        health_medical,
        internet_technology,
        lifestyle,
        marketing,
        arabic,
        english,
        french,
        german,
        italian,
        spanish,
        dutch,
        view_all,
    },
    16: {
        arabic,
        english,
        french,
        german,
        italian,
        spanish,
        dutch,
        female,
        male,
        view_all,
    },
    67: {
        facebook,
        instagram,
        linkedin,
        twitter,
        youtube,
        view_all,
    },
    407: {
        facebook,
        instagram,
        linkedin,
        youtube,
        view_all,
    },
    54: {
        marketing,
        investor_pitch_fundraising,
        business_proposal,
        education_training,
        sales,
        conference,
        view_all,
    },
    325: {
        shopify,
        amazon,
        ebay,
        etsy,
        woocommerce,
        view_all,
    },
    139: {
        wix,
        magento,
        opencart,
        squarespace,
        prestashop,
        bigcommerce,
        godaddy,
        sitebuilder,
        webflow,
        shopify,
        amazon,
        ebay,
        woocommerce,
        view_all,
    },
    91: {
        blog,
        business,
        education,
        portfolio,
        entertainment,
        wedding,
        wix,
        magento,
        opencart,
        squarespace,
        prestashop,
        bigcommerce,
        godaddy,
        sitebuilder,
        webflow,
        shopify,
        amazon,
        ebay,
        woocommerce,
        view_all,
    },
    138: {
        blog_magazine: blog,
        corporate: business,
        education,
        portfolio,
        entertainment,
        wedding,
        wix,
        magento,
        opencart,
        squarespace,
        prestashop,
        bigcommerce,
        godaddy,
        sitebuilder,
        webflow,
        shopify,
        amazon,
        ebay,
        woocommerce,
        view_all,
    },
    334: {
        english,
        german,
        french,
        italian,
        spanish,
        view_all,
    },
    249: {
        weddings_events: wedding,
        family_travel: family_videos,
        corporate: corporate_videos,
        sports_games,
        elearning,
        view_all,
    },
    384: {
        english,
        spanish,
        french,
        german,
        arabic,
        view_all,
    },
    235: {
        '2d': twoD,
        '3d': threeD,
        view_all,
    },
    156: {
        guitar,
        drums,
        bass,
        piano,
        keyboards,
        violin,
        saxophone,
        trumpet,
        view_all,
    },
    392: {
        pop,
        hip_hop,
        rap,
        r_b,
        edm,
        view_all,
    },
    51: {
        illustrative,
        photographic,
        typographic,
        view_all,
    },
    420: {
        abstract: abstract_pattern,
        digital,
        hand_drawn: hand_drawn_pattern,
        cartoon: cartoon_pattern,
        realistic: realistic_pattern,
        seasonal: watercolor_pattern,
        collage: collage_pattern,
        view_all,
    },
    50: {
        for_children,
        realistic_drawing,
        comic_cartoon,
        manga,
        flat,
        line_art,
        sketch,
        freehand_digital,
        vector_art,
        pen_ink,
        mixed_media,
        watercolor,
        pencil,
        view_all,
    },
    386: {
        photographic,
        illustrative,
        typographic,
        view_all,
    },
    87: {
        mysql,
        ms_sql,
        access,
        oracle,
        sqlite,
        postgres,
        mongo,
        teradata,
        view_all,
    },
    430: {
        fiction,
        non_fiction,
    },
    114: {
        english,
        german,
        spanish,
        french,
    },
    354: {
        english,
        german,
        spanish,
        italian,
        french,
        fiction,
        non_fiction,
        self_help_wellness,
        children_s_books,
        business_management: business,
        science_fiction_fantasy,
        romance,
    },
    435: {
        english,
        spanish,
        german,
        italian,
    },
    200: {
        google,
        bing,
        view_all,
    },
};
