import { string } from 'prop-types';

export default {
    /**
     * A unique key for expertise's translation
     */
    id: string,
    /**
     * An id which is one level above the id (if exists)
     */
    parentId: string,
    /**
     * The type of the expertise
     */
    type: string,
    /**
     * The translated name of the expertise
     */
    translatedName: string,
};
