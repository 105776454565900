import React from 'react';
import PropTypes from 'prop-types';
import { useExpertCardContext } from '../../../contexts';
import { SaveExpertButton } from './SaveExpertButton';
import ContactButton from './ContactButton';
import SeeProfileButton from './SeeProfileButton';
import CustomButton from './CustomButton';

import './index.scss';

const CardActions = ({ source }) => {
    const { displayOptions, headerCustomButtons = [] } = useExpertCardContext();
    const { showHeaderSaveExpert = true, showHeaderContact = true, showHeaderSeeProfile = true } = displayOptions;

    return (
        <div className="listings-action-menu-container flex" onClick={(e) => e.stopPropagation()}>
            <div className="responsive-buttons">
                {showHeaderSaveExpert && <SaveExpertButton source={source} />}
                {showHeaderContact && <ContactButton source={source} />}
            </div>
            {showHeaderSeeProfile && <SeeProfileButton source={source} />}
            {headerCustomButtons.map(({ label, onClick, isCta, elementName, loading, disabled, variant, icon }) => (
                <CustomButton
                    key={label}
                    label={label}
                    onClick={onClick}
                    isCta={isCta}
                    source={source}
                    elementName={elementName}
                    size="md"
                    loading={loading}
                    disabled={disabled}
                    variant={variant}
                    icon={icon}
                />
            ))}
        </div>
    );
};

CardActions.propTypes = {
    source: PropTypes.string,
};

export default CardActions;
