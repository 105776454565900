import { oneOf, shape, string } from 'prop-types';
import { SUPPORTED_FORMATTING_LOCALES_KEYS } from '@fiverr-private/localization';

export const GENERAL_VIDEO_MODAL_NAME = 'general-video-modal';

export const VIDEO_PROP_TYPES = {
    video: shape({
        id: string,
        name: string,
        sources: shape({
            [oneOf(Object.keys(SUPPORTED_FORMATTING_LOCALES_KEYS))]: string,
        }),
    }),
    sourceInfo: shape({
        videoName: string,
        mixpanel: shape({
            pageName: string,
        }),
        bigQuery: shape({
            pageName: string,
        }),
    }),
};
