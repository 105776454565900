import { BI_CONSTS } from './biConsts';

export const BI_CLICK_DATA = {
    type: BI_CONSTS.CLICKED_ON_FILTER_GROUP,
    group: BI_CONSTS.USER_ACTIONS,
    sub_group: BI_CONSTS.WEBSITE_ACTIONS,
    action: {
        type: BI_CONSTS.CLICK,
    },
    out_of_session: false,
    domain: {
        name: BI_CONSTS.BUYERS,
    },
    publisher: {
        domain: BI_CONSTS.BUYERS,
        sub_domain: BI_CONSTS.CATERPILLARS,
    },
};
