import { RequestContext } from '@fiverr-private/perseus';
import { isActive } from '@fiverr-private/rollout_js';
import { InitialProps } from '@fiverr-private/listing_types';
import { EXPERIMENT_TYPE } from '@fiverr-private/experiments_package';
import { EXPERIMENTS, ROLLOUTS } from '@fiverr-private/listing_experiments';

const isEligibleForAllocation = () => {
    const {
        experience: { isBusiness },
        initialProps,
    } = RequestContext;

    const { experimentsToAllocate = {}, shouldShowExpressDelivery } = initialProps as InitialProps;

    return (
        !isBusiness &&
        !shouldShowExpressDelivery &&
        !!experimentsToAllocate &&
        !!experimentsToAllocate[EXPERIMENTS.AGENCIES_IN_LISTINGS_GD]
    );
};

const inRollout = async () => {
    const { userGuid } = RequestContext;

    return await isActive(ROLLOUTS.FALCONS_AGENCIES_IN_LISTINGS_GD, userGuid);
};

export const agenciesInListingsGD = {
    id: EXPERIMENTS.AGENCIES_IN_LISTINGS_GD,
    experimentType: EXPERIMENT_TYPE.GUEST,
    numOfGroups: 2,
    shouldAllocate: isEligibleForAllocation,
    inRollout,
};
