import { FILTER, SELECTORS } from '../constants';
import { isBrowser } from '../util';
import { setCookie, expireCookie } from './cookies';

const stickyFilter = FILTER.SELLER_LOCATION;
const stickyValue = 'IL';

const setStickyFilterCookie = () => {
    const sec = 1 / (60 * 60 * 24);
    const secondsToSaveInCookies = 120;
    setCookie({
        name: stickyFilter.COOKIE_NAME,
        value: stickyValue,
        expirationDays: secondsToSaveInCookies * sec,
    });
};

const isStickyFilterActive = (activeFilters) => {
    const activeFilteredValue = activeFilters[stickyFilter.ID];
    if (!activeFilteredValue) {
        return false;
    }

    if (SELECTORS.isMulti(stickyFilter.ID) && activeFilteredValue.includes?.(stickyValue)) {
        return true;
    }

    return activeFilteredValue === stickyValue;
};

export const deregisterStickyFilter = () => {
    if (!isBrowser()) {
        return;
    }

    expireCookie(stickyFilter.COOKIE_NAME);
    window.removeEventListener('beforeunload', setStickyFilterCookie);
};

export const registerStickyFilter = (activeFilters) => {
    if (!isBrowser()) {
        return;
    }

    if (isStickyFilterActive(activeFilters)) {
        deregisterStickyFilter();
        window.addEventListener('beforeunload', setStickyFilterCookie);
    }
};

export const onRemoveStickyFilter = ({ id, value }) => {
    if (stickyFilter.ID === id && value === stickyValue) {
        deregisterStickyFilter();
    }
};
