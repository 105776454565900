import { trackEvent } from '@fiverr-private/gtm';

const SEARCH_PERSEUS_GTM_EVENTS = {
    BRAZE_EVENT: 'braze_event',
    SEARCH_PERSEUS_LOAD: 'search_perseus_page_load',
};

interface BrazeEventParams {
    brazeEventName: string;
    brazeEventProperties: Record<string, unknown>;
}

export const trackBrazeEvent = (params: BrazeEventParams) => trackEvent(SEARCH_PERSEUS_GTM_EVENTS.BRAZE_EVENT, params);

interface TrackSearchPerseusPageLoadParams {
    categorySlug: string;
    subCategorySlug: string;
    perseus_name: string;
    app_base_path: string;
    flow: string;
    controller: string;
    action: string;
    nestedSubCategorySlug: string;
}

export const trackSearchPerseusPageLoad = (params: Partial<TrackSearchPerseusPageLoadParams>) =>
    trackEvent(SEARCH_PERSEUS_GTM_EVENTS.SEARCH_PERSEUS_LOAD, params);
