import { RequestContext } from '@fiverr-private/perseus';
import { isActive } from '@fiverr-private/rollout_js';
import { InitialProps } from '@fiverr-private/listing_types';
import { EXPERIMENT_TYPE } from '@fiverr-private/experiments_package';
import { EXPERIMENTS, ROLLOUTS } from '@fiverr-private/listing_experiments';

const inRollout = (): Promise<boolean> => isActive(ROLLOUTS.PROMOTED_VIDEO_STRIP, RequestContext.userGuid);

const isEligibleForAllocation = (): boolean => {
    const { initialProps, isMobile } = RequestContext;
    const { experimentsToAllocate } = initialProps as InitialProps;

    return !isMobile && !!experimentsToAllocate && !!experimentsToAllocate[EXPERIMENTS.PROMOTED_VIDEO_STRIP];
};

export default {
    id: EXPERIMENTS.PROMOTED_VIDEO_STRIP,
    experimentType: EXPERIMENT_TYPE.GUEST,
    numOfGroups: 2,
    shouldAllocate: isEligibleForAllocation,
    inRollout,
};
