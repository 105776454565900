import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@fiverr-private/fit';
import { commaSeparateNumber } from '@fiverr-private/futile';
import { shouldRenderFilterOption } from '../../../filter/utils';

const ControlledCheckbox = ({ id, value, name, options, onChange }) => {
    const { alias, count } = options[0];
    const isChecked = !!value;

    const onChangeHandler = ({ target }) => {
        onChange(id, target.checked ? true.toString() : undefined);
    };

    return shouldRenderFilterOption(count) ? (
        <div data-testid="single-filter-checkbox" className="single-filter box-shadow-z1">
            <Checkbox name={name} id={id} checked={isChecked} value={isChecked} onChange={onChangeHandler}>
                {alias}
            </Checkbox>
            {count && <span>({commaSeparateNumber(count)})</span>}
        </div>
    ) : null;
};

ControlledCheckbox.propTypes = {
    id: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    name: PropTypes.string,
    options: PropTypes.array,
    onChange: PropTypes.func,
};

ControlledCheckbox.defaultProps = {
    options: [],
    onChange: () => true,
};

export default ControlledCheckbox;
