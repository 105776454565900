import { AxiosResponse } from 'axios';
import { UseMutateAsyncFunction } from 'react-query';
import { MutableRefObject } from 'react';
import { buildListingPageUrl } from '@fiverr-private/listing_lib';
import { FlowName, InitialProps, ListingAttributes } from '@fiverr-private/listing_types';
import { OrchestratorMachineDispatchedAction } from '../../../types';

const handleNavigation = (listingAttributes: ListingAttributes, flow: FlowName) => {
    const url = buildListingPageUrl(window, listingAttributes, flow);

    window.history.pushState({}, '', url);
};

export const handleListingAttributesMessage = async (
    dispatch: (value: OrchestratorMachineDispatchedAction) => void,
    setLoadingState: (isLoading: boolean) => void,
    listingAttributes: ListingAttributes,
    flowName: FlowName,
    _fetchListing: UseMutateAsyncFunction<AxiosResponse<InitialProps, any>, unknown, ListingAttributes, unknown>,
    _handleNewListingResult: (listings: InitialProps) => void,
    isCurrentListingCreatedByAssistant?: MutableRefObject<boolean>,
    currentListingIndex?: MutableRefObject<number>
) => {
    dispatch({ type: 'setListingAttributesPayload', payload: listingAttributes });
    dispatch({ event: 'LISTING_ATTRIBUTES_MESSAGE_RECEIVED' });

    setLoadingState(true);

    try {
        const { data } = await _fetchListing(listingAttributes);
        delete data.assistantData; // TODO: Potentially remove this form the AJAX request alltogether.

        if (isCurrentListingCreatedByAssistant) {
            isCurrentListingCreatedByAssistant.current = true;
        }

        if (currentListingIndex) {
            currentListingIndex.current++;
        }

        _handleNewListingResult(data);

        // TODO: For now we are getting the flow from the context. later on we will get it from the result.
        handleNavigation(listingAttributes, flowName);
    } catch (error) {
        dispatch({ event: 'TIMEOUT' });
    }

    setLoadingState(false);
};
