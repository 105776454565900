import React, { Fragment, useContext, useMemo, useState, useCallback, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import throttle from 'lodash/throttle';
import classNames from 'classnames';
import { I18nProvider } from '@fiverr-private/i18n-react';
import { Container, Stack } from '@fiverr-private/layout_components';
import { BusinessEntryPoint } from '@fiverr-private/business_entry_point';
import { LISTING_SOURCES } from '@fiverr-private/logo_maker_marketplace';
import { EmptySearchResults, NoResultsClearFilters, ProFilteredListingsTitle } from '@fiverr-private/listing_results';
import { Pagination } from '@fiverr-private/listing_pagination';
import { useListingsOrchestrator } from '@fiverr-private/listings_orchestrator';
import { LazyComponent } from '@fiverr-private/orca';
import { useIsClient } from '@fiverr-private/hooks';
import { SearchHeader } from '@fiverr-private/listings_header';
import { removeEmptyEntries } from '@fiverr-private/listing_lib';
import { DEFAULT_LOCALE } from '../../../../shared/constants';
import { SearchPropTypes } from '../../types/index';
import LogoMakerBanner from '../common/logoMakerBanner';
import { DynamicGigListings, MobileFilters, RecommendationsCarousels, SortBy } from '../common';
import { NumberOfResults } from '../common/NumberOfResults/NumberOfResults';
import { TopBarFilters } from '../common/floating_topbar/TopBarFilters';
import StickyComponent from '../hoc/stickyComponent';
import { FiProEntryBanner } from '../common/banners/FipProEntryBanner';
import MachineTranslationButtonWrapper from '../common/machine_translation_button_wrapper';
import { MINIMUM_TILES_LENGTH } from '../common/tiles_carousel/constants';
import LeafCategoriesCarousel from '../common/LeafCategoriesCarousel';
import TopFiltersCarousel from '../common/top_filters_carousel';
import RepeatedBuyersRecommendations from '../common/recommendations/RepeatedBuyers';
import FtbFriendlyRecommendations from '../common/recommendations/FtbFriendly';
import { ExpressDeliveryBanner } from '../common/recommendations/ExpressDelivery';
import { SellersWhoSpeakRecommendations } from '../common/recommendations/SellersWhoSpeak';
import { isSupportedFiverrLanguage } from '../common/recommendations/SellersWhoSpeak/utils';
import { AppContext } from '../context/listingsContext';
import { getLeafCategoryTilesData } from '../common/LeafCategoriesCarousel/utils';
import { LISTINGS_PAGE, LISTINGS_TYPES, MAX_NON_HEAD_QUERY_LENGTH, TRACKING_NAMES } from '../../constants';
import ExplicitLanguagePair from '../common/ExplicitLanguagePair';
import { shouldShowExplicitLanguageFilter } from '../common/ExplicitLanguagePair/utils';
import { getFilterTagsProps } from '../generic_listings/TagFilters/utils';
import PromoteBanner from '../common/promoteBanner';
import LogoMakerFork from '../common/LogoMakerFork';
import { useReportEligibility } from '../../utils/reportEligibility';
import { FLOWS } from '../../utils/constants';
import {
    useCountFlowView,
    usePersonalizationData,
    usePromotedVideoStripListingRowObserver,
    useAllocateAgenciesInListingsGDRowObserver,
    useAllocateStarlingsBoostingNewGigsObserver,
} from '../../hooks';
import PricingFactorDropdown from '../common/floating_topbar/menus/content/pricing_factor_dropdown';
import { ActiveFilters } from '../common/ActiveFilters/ActiveFilters';
import { ExposedFilters } from '../common/side_filters/ExposedFilters/ExposedFilters';
import Modalities from '../common/modalities/Modalities';
import { pushParamToUrlHistory } from '../common/util';
import { ListingsImpressionContainer } from '../../../../shared/context/ListingsImpressionContainer/ListingsImpressionContainer';
import { sendBigQueryReport } from '../../../../shared/utils/sendBigQueryReport/sendBigQueryReport';
import ACTION_TYPES from '../context/listingsContextReducerActionTypes';
import { useGigModalitiesConfig } from '../common/modalities/useGigModalitiesConfig/useGigModalitiesConfig';
import { HourlyRateBannerResolverWithWrapper } from '../common/banners/HourlyRateBanners';
import { checkStringWordsLessThan } from './related_search/utils';
import SearchResultsCounter from './search_results_counter';
import RelatedSearch from './related_search';
import { prepareAssistantConversationProps } from './utils/prepareAssistantConversationProps';
import { AssistantTrigger } from './assistant_trigger/AssistantTrigger';
import {
    assistantContainerStyles,
    assistantFullHeightContainerStyles,
    openAssistantContainerStyles,
    openFiltersAssistantContainerOpenStyles,
} from './Search.ve.css';
import { getHeaderHeight } from './utils/getHeaderHeight';

const AssistantContainer = () =>
    import(/* webpackChunkName: 'listings/assistant' */ '../assistant_container/AssistantContainer');

const Search = (props) => {
    const [isExternalFiltersFixed, setIsExternalFiltersFixed] = useState(false);
    const [showLMFlow, setShowLMFlow] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const {
        isTouch,
        listings: [listings],
        dominateSubCategoryId,
        appFilters,
        activeFilters = {},
        dominantLeafCategory,
        significantLeafCategories = [],
        source,
        displayQuery,
        logoMakerConfig = {},
        shouldShowHourlyRateBanners,
        shouldShowExpressDelivery,
        shouldShowProEntryBanner,
        shouldShowPromoteBanner,
        assumedLanguage,
        inFetchPersonalizationContentRollout,
        relatedSearches,
        flow,
        inOwlsSideFiltersTest,
        isLoadingListings,
        rollouts,
        currentUser,
        assistantData,
        dispatch,
        listingAttributes,
        pagination,
        displayData,
        currency,
    } = useContext(AppContext);
    const { shouldShowAssistant, enable: enableAssistant } = assistantData;
    const [isAssistantOpen, setIsAssistantOpen] = useState(enableAssistant && shouldShowAssistant);
    const [assistantFullHeight, setAssistantFullHeight] = useState();
    const isClient = useIsClient();

    const { onReceiveChat, onMessageSend, onReceiveMessage, onReceiveListingAttributes } = useListingsOrchestrator({
        flowName: 'gigSearchFlow',
        setLoadingState: (isLoading) => {
            dispatch({ type: ACTION_TYPES.SET_IS_LOADING_LISTINGS, payload: isLoading });
        },
        handleNewListingResult: (res) => {
            dispatch({ type: ACTION_TYPES.SET_LISTINGS, payload: removeEmptyEntries(res) });
        },
    });

    const { gigs: modalitiesGigs } = useGigModalitiesConfig();

    const { sorting, topBarSorting, loggedIn, flowName } = props;

    const {
        gigs,
        choiceModalities,
        recommendations,
        sellersWhoSpeak,
        showListViewCards,
        context: { categoryIds, sessionLocale, sourceComponent, sourcePage },
        isHeadQuery,
    } = listings;

    const {
        selectedFilters,
        filters,
        visualFilters,
        subCategory = {},
        selectedFiltersHierarchy,
        shownExposedFilter,
    } = appFilters;
    const { children: leafCategories = [] } = subCategory;
    const noGigs = !gigs.length > 0;
    const isEmptySearch = isEmpty(activeFilters) && noGigs;
    const hasFilters = filters?.length > 0;
    const selectedSorting = topBarSorting.find((sort) => sort.selected) || topBarSorting[0];
    const leafCategoryTiles = getLeafCategoryTilesData(significantLeafCategories, leafCategories);
    const hasMinimumScTiles = leafCategoryTiles.length >= MINIMUM_TILES_LENGTH;
    const shouldShowPagination = !noGigs;
    const shouldShowModalities = !isEmpty(choiceModalities) && !isEmpty(choiceModalities.buckets);
    const shouldShowRepeatedBuyers = !isEmpty(recommendations) && !isEmpty(recommendations.repeatedBuyers);
    const shouldShowSellersWhoSpeak = !isEmpty(sellersWhoSpeak) && !!isSupportedFiverrLanguage(assumedLanguage);
    const shouldShowFtbFriendly = !isEmpty(recommendations) && !isEmpty(recommendations.ftbFriendly);
    const shouldShowExposedFilters = inOwlsSideFiltersTest && !isTouch;
    const shouldShowNumberOfResults = !inOwlsSideFiltersTest;
    const shouldShowSorter = !inOwlsSideFiltersTest;
    const shouldShowAssistantTrigger = shouldShowAssistant && !isAssistantOpen;
    const {
        searchComponentData: { modifiedQueryString, nonTranslatedQuery, searchInsteadUrl, reasonForModification },
    } = displayData;
    const userIsInSearchResultArea = modifiedQueryString || nonTranslatedQuery;

    let selectedScName = subCategory ? subCategory.alias : '';

    if (subCategory && !subCategory.selected) {
        const [item] = leafCategories.filter((item) => item.selected);
        selectedScName = item ? item.alias : selectedScName;
    }

    const displayTranslationButton = sessionLocale !== DEFAULT_LOCALE;
    const listingsType = LISTINGS_TYPES.SEARCH;

    const logoMakerProps = {
        source,
        showListViewCards,
        page: TRACKING_NAMES.SEARCH_PAGE,
        listingsType,
        query: displayQuery,
        sourcePage,
        sourceComponent,
        logoMakerConfig,
    };

    const filterTagsProps = useMemo(
        () =>
            getFilterTagsProps({
                isSearch: true,
                displayQuery,
                dominateSubCategoryId,
                significantLeafCategories,
                appFilters,
                categoryIds,
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const showAssistant = !!currentUser && shouldShowAssistant;
    const showAssistantFiltersTopBar = shouldShowAssistant && isAssistantOpen && !isTouch && hasFilters;
    const shouldRelatedSearchDisplayed =
        isHeadQuery || checkStringWordsLessThan(displayQuery, MAX_NON_HEAD_QUERY_LENGTH);
    const shouldShowVisualFilters = visualFilters?.shouldDisplayVisualFilters;
    const shouldShowScCarousel =
        !shouldShowVisualFilters && !shouldShowModalities && hasMinimumScTiles && !dominateSubCategoryId;
    const shouldShowRelatedSearch =
        shouldRelatedSearchDisplayed &&
        !shouldShowScCarousel &&
        !shouldShowVisualFilters &&
        sessionLocale === DEFAULT_LOCALE &&
        !isEmpty(relatedSearches);
    const shouldShowLogoMakerBanner = (() => {
        const { banner: { showBannerInSearch } = {} } = logoMakerConfig || {};

        return showBannerInSearch && !shouldShowExpressDelivery;
    })();

    const { personalizedContent } = usePersonalizationData({
        flow,
        shouldRun: inFetchPersonalizationContentRollout && loggedIn,
    });

    useReportEligibility({ visualFilters, filterTagsProps, isSearch: true, shownExposedFilter });
    useCountFlowView(FLOWS.SEARCH);
    const promotedVideoStripListingRowObserver = usePromotedVideoStripListingRowObserver();
    const agenciesInListingsGDRowObserver = useAllocateAgenciesInListingsGDRowObserver();
    const starlingsBoostingNewGigsObserver = useAllocateStarlingsBoostingNewGigsObserver();

    const banners = [];
    shouldShowHourlyRateBanners && banners.push(<HourlyRateBannerResolverWithWrapper key="HourlyRateBannerResolver" />);
    shouldShowLogoMakerBanner && banners.push(<LogoMakerBanner {...logoMakerProps} />);
    shouldShowExpressDelivery && banners.push(<ExpressDeliveryBanner />);

    const assistantConversationProps = useMemo(() => {
        if (showAssistant) {
            return prepareAssistantConversationProps({
                rollouts,
                currentUser,
                assistantData,
                onReceiveChat,
                onMessageSend,
                onReceiveMessage,
                onReceiveListingAttributes,
                listingAttributes,
                gigs,
                dominantLeafCategory,
                significantLeafCategories,
                setIsAssistantOpen,
                recommendations,
                modalitiesGigs,
            });
        }
    }, [
        showAssistant,
        rollouts,
        currentUser,
        assistantData,
        onReceiveChat,
        onMessageSend,
        onReceiveMessage,
        onReceiveListingAttributes,
        gigs,
        listingAttributes,
        dominantLeafCategory,
        significantLeafCategories,
        recommendations,
        modalitiesGigs,
    ]);

    const commonTopBarFiltersProps = useMemo(
        () => ({
            filters,
            selectedFilters,
            activeFilters,
            selectedFiltersHierarchy,
            subCategory,
            noGigs,
            fromSearchFlow: true,
            isDrawerOpen,
            setIsDrawerOpen,
            topBarSorting,
            selectedSorting,
            numOfResult: pagination?.total,
        }),
        [
            filters,
            selectedFilters,
            activeFilters,
            selectedFiltersHierarchy,
            subCategory,
            noGigs,
            pagination?.total,
            isDrawerOpen,
            topBarSorting,
            selectedSorting,
        ]
    );

    // FIXME: should be assistant conversation wrapper
    if (showAssistant && isClient) {
        pushParamToUrlHistory('chat_id', assistantConversationProps.chat.id);
        pushParamToUrlHistory('stream_id', assistantConversationProps.chat.realtimeChannelName);
    }

    const onAssistantTriggerClick = useCallback(() => {
        setIsAssistantOpen((prev) => !prev);
    }, []);

    const assistantOpenPaddingY = '6';
    const headerHeight = getHeaderHeight({ isClient });

    useEffect(() => {
        sendBigQueryReport('listing_search_page');
    }, []);

    useEffect(() => {
        const onScrollThrottled = throttle(() => {
            setAssistantFullHeight(document.documentElement.scrollTop > headerHeight);
        }, 16);

        if (showAssistant) {
            setAssistantFullHeight(document.documentElement.scrollTop > headerHeight);
            window.addEventListener('scroll', onScrollThrottled);
        }

        return () => {
            window.removeEventListener('scroll', onScrollThrottled);
        };
    }, [showAssistant, headerHeight]);

    const listingKey = useMemo(() => JSON.stringify(listingAttributes), [listingAttributes]);

    return (
        <Stack
            position="relative"
            marginX="auto"
            gap={isAssistantOpen ? '3' : '0'}
            paddingY={isAssistantOpen ? assistantOpenPaddingY : undefined}
            maxWidth={isAssistantOpen ? '1464px' : undefined}
            paddingX={isAssistantOpen ? '8' : undefined}
            className={classNames('search-page', {
                'top-filters': !isTouch,
                'with-logo-maker': showLMFlow,
            })}
        >
            {shouldShowAssistantTrigger && <AssistantTrigger onClick={onAssistantTriggerClick} />}
            {showAssistantFiltersTopBar && (
                <TopBarFilters {...commonTopBarFiltersProps} isFixed={isExternalFiltersFixed} variant="outer" />
            )}
            {showAssistant && (
                <Container
                    overflow="hidden"
                    top="22px"
                    alignSelf="flexStart"
                    position="sticky"
                    backgroundColor="white"
                    boxShadow="z1"
                    borderRadius="xl"
                    className={classNames(assistantContainerStyles, {
                        [openAssistantContainerStyles]: isAssistantOpen,
                        [assistantFullHeightContainerStyles]: assistantFullHeight,
                        [openFiltersAssistantContainerOpenStyles]: isExternalFiltersFixed,
                    })}
                >
                    <LazyComponent lazyImport={AssistantContainer} componentProps={assistantConversationProps} />
                </Container>
            )}
            <Container
                flex="1"
                backgroundColor="white"
                borderRadius="lg"
                minWidth="0"
                className={classNames('listing-layout', { 'assistant-enabled': isAssistantOpen })}
            >
                <BusinessEntryPoint source={LISTINGS_PAGE} />
                {!isEmptySearch ? (
                    <>
                        {isTouch && userIsInSearchResultArea && (
                            <>
                                <I18nProvider>
                                    {({ i18n }) => (
                                        <SearchResultsCounter
                                            paginationData={pagination}
                                            displayData={displayData}
                                            i18n={i18n}
                                        />
                                    )}
                                </I18nProvider>
                            </>
                        )}

                        {!showAssistant && shouldShowRelatedSearch && <RelatedSearch />}

                        {!isTouch && (
                            <Container className="layout-row" marginTop="8">
                                <SearchHeader
                                    query={listingAttributes.query}
                                    modifiedQueryString={modifiedQueryString}
                                    searchInsteadUrl={searchInsteadUrl}
                                    nonTranslatedQuery={nonTranslatedQuery}
                                    reasonForModification={reasonForModification}
                                />
                            </Container>
                        )}

                        <LogoMakerFork
                            listingsType={listingsType}
                            listingSource={LISTING_SOURCES.SEARCH}
                            searchQuery={displayQuery}
                            showLMFlow={showLMFlow}
                            setShowLMFlow={setShowLMFlow}
                        />

                        {!showLMFlow && (
                            <>
                                {shouldShowExposedFilters ? (
                                    <div className="layout-row">
                                        <ExposedFilters />
                                    </div>
                                ) : (
                                    <Fragment>
                                        {!isTouch && (
                                            <>
                                                {shouldShowVisualFilters && (
                                                    <div className="layout-row">
                                                        <TopFiltersCarousel
                                                            visualFilters={visualFilters}
                                                            subCategoryId={dominateSubCategoryId}
                                                        />
                                                    </div>
                                                )}
                                                {shouldShowScCarousel && (
                                                    <div className="layout-row">
                                                        <LeafCategoriesCarousel leafCategoryTiles={leafCategoryTiles} />
                                                    </div>
                                                )}
                                            </>
                                        )}

                                        {shouldShowExplicitLanguageFilter({ isTouch, displayQuery }) && (
                                            <div className="layout-row">
                                                <ExplicitLanguagePair filters={filters} />
                                            </div>
                                        )}
                                    </Fragment>
                                )}
                                {!isTouch && (
                                    <>
                                        {hasFilters && (
                                            <TopBarFilters
                                                {...commonTopBarFiltersProps}
                                                setIsFixed={setIsExternalFiltersFixed}
                                                variant={isAssistantOpen ? 'inner' : 'base'}
                                            />
                                        )}

                                        <ActiveFilters
                                            setIsDrawerOpen={setIsDrawerOpen}
                                            selectedScName={selectedScName}
                                        />

                                        {!noGigs && (
                                            <div className="layout-row flex-items-end flex-between">
                                                <div className="flex flex-col">
                                                    {displayTranslationButton && (
                                                        <MachineTranslationButtonWrapper
                                                            gigsListings={[listings.gigs]}
                                                        />
                                                    )}
                                                    <div className="flex flex-items-center flex-wrap">
                                                        {shouldShowNumberOfResults && (
                                                            <NumberOfResults resultsCount={pagination?.total} />
                                                        )}
                                                        <PricingFactorDropdown
                                                            showSeparator={shouldShowNumberOfResults}
                                                        />
                                                    </div>
                                                </div>

                                                {shouldShowSorter && (
                                                    <SortBy title={selectedSorting.alias} data={topBarSorting} />
                                                )}
                                            </div>
                                        )}
                                        {shouldShowPromoteBanner && (
                                            <div className="layout-row content-row">
                                                {' '}
                                                <PromoteBanner />{' '}
                                            </div>
                                        )}
                                    </>
                                )}

                                {isTouch && hasFilters && (
                                    <>
                                        <StickyComponent>
                                            <MobileFilters
                                                activeFilters={activeFilters}
                                                sorts={topBarSorting}
                                                filters={filters}
                                            />
                                        </StickyComponent>
                                        {displayTranslationButton && (
                                            <MachineTranslationButtonWrapper
                                                gigsListings={[listings.gigs]}
                                                showSeparator={false}
                                            />
                                        )}
                                    </>
                                )}
                                <ListingsImpressionContainer
                                    rowObservers={[
                                        promotedVideoStripListingRowObserver,
                                        agenciesInListingsGDRowObserver,
                                        starlingsBoostingNewGigsObserver,
                                    ]}
                                    key={listingKey}
                                >
                                    {shouldShowModalities && (
                                        <div className="layout-row modalities-row">
                                            <Modalities isNarrowView={isAssistantOpen} />
                                        </div>
                                    )}
                                    {shouldShowRepeatedBuyers && (
                                        <div className="layout-row repeated-buyers-recommendations-row">
                                            <RepeatedBuyersRecommendations
                                                listings={listings}
                                                gigs={recommendations.repeatedBuyers}
                                                isLoading={isLoadingListings}
                                                isNarrowView={isAssistantOpen}
                                            />
                                        </div>
                                    )}
                                    {shouldShowSellersWhoSpeak && (
                                        <div className="layout-row sellers-who-speak-recommendations-row">
                                            <SellersWhoSpeakRecommendations
                                                listings={listings}
                                                assumedLanguage={assumedLanguage}
                                                gigs={sellersWhoSpeak}
                                                isLoading={isLoadingListings}
                                                isNarrowView={isAssistantOpen}
                                            />
                                        </div>
                                    )}
                                    {shouldShowFtbFriendly && (
                                        <div className="layout-row ftb-friendly-recommendations-row">
                                            <FtbFriendlyRecommendations
                                                listings={listings}
                                                gigs={recommendations.ftbFriendly}
                                                isLoading={isLoadingListings}
                                                isNarrowView={isAssistantOpen}
                                            />
                                        </div>
                                    )}
                                    <div className="layout-row content-row">
                                        <ProFilteredListingsTitle
                                            displayData={displayData}
                                            categoryIds={categoryIds}
                                            activeFilters={activeFilters}
                                        />
                                        {shouldShowProEntryBanner && <FiProEntryBanner />}
                                        {noGigs ? (
                                            <NoResultsClearFilters
                                                displayData={displayData}
                                                listingAttributes={listingAttributes}
                                                flowName={'gigSearchFlow'}
                                            />
                                        ) : (
                                            <div className="content">
                                                <DynamicGigListings
                                                    isLoading={isLoadingListings}
                                                    listings={listings}
                                                    banners={banners}
                                                    isNarrowView={isAssistantOpen}
                                                />
                                            </div>
                                        )}

                                        {shouldShowPagination && (
                                            <Container
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                paddingY={{ default: '6', sm: '12' }}
                                            >
                                                <Pagination
                                                    pagination={pagination}
                                                    listingAttributes={listingAttributes}
                                                    flowName={flowName}
                                                />
                                            </Container>
                                        )}
                                    </div>
                                </ListingsImpressionContainer>
                            </>
                        )}
                    </>
                ) : (
                    <EmptySearchResults currency={currency} rollouts={rollouts} />
                )}
                {!isTouch && loggedIn && (
                    <RecommendationsCarousels
                        sorting={sorting}
                        activeFilters={activeFilters}
                        loggedIn={loggedIn}
                        shouldFetchData={!inFetchPersonalizationContentRollout}
                        personalizedContent={personalizedContent}
                    />
                )}
            </Container>
        </Stack>
    );
};

Search.propTypes = SearchPropTypes;

export default Search;
