import React, { useContext, useState } from 'react';
import { Button } from '@fiverr-private/button';
import { LazyComponent } from '@fiverr-private/orca';
import { FilterIcon } from '@fiverr-private/icons';
import { Divider, Stack } from '@fiverr-private/layout_components';
import { Drawer, DrawerContent, DrawerTrigger } from '@fiverr-private/overlay';
import { tracker } from '@fiverr-private/listing_lib';
import {
    ActiveSideFiltersType,
    FilterCollectionType,
    SelectedFilter as SelectedFilterType,
} from '@fiverr-private/listing_types';
import { AppContext } from '../../../context/listingsContext';
import { AllFiltersButtonContent } from '../../side_filters/AllFIltersButtonContent/AllFiltersButtonContent';
import { calculateActiveFiltersCount } from './utils/calculateActiveFiltersCount';
import { applyFilters } from './utils/applyFilters';

const FiltersDrawerContent = () =>
    import(/* webpackChunkName: 'listings/FiltersDrawer' */ './FiltersDrawerContent/FiltersDrawerContent');

interface FiltersDrawerProps {
    filterCollections: FilterCollectionType[];
    activeFilters: ActiveSideFiltersType;
    selectedFiltersHierarchy: SelectedFilterType[];
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    numOfResult: number;
}

export const FiltersDrawer = ({
    filterCollections = [],
    activeFilters,
    selectedFiltersHierarchy,
    isOpen,
    setIsOpen,
    numOfResult,
}: FiltersDrawerProps) => {
    const { inOwlsSideFiltersTest, searchUrl, searcherApiPath, dominateSubCategoryId } = useContext(AppContext);
    const [changedFilterCollections, setChangedFilterCollections] = useState(filterCollections);

    const open = () => {
        tracker.trackFilterGroupOpen('all_filters', 'drawer');
        setIsOpen(true);
    };

    const close = () => {
        setIsOpen(false);
        applyFilters(changedFilterCollections, activeFilters);
    };

    const applyFiltersHandler = () => {
        applyFilters(changedFilterCollections, activeFilters);
    };

    if (!inOwlsSideFiltersTest) {
        return null;
    }

    const selectedFiltersCount = calculateActiveFiltersCount(selectedFiltersHierarchy);

    return (
        <Drawer onClose={close} isOpen={isOpen}>
            <DrawerTrigger>
                <Stack marginRight="1" gap="4" width="fit-content" height="fit-content">
                    <Button size="lg" variant="outline" intent="secondary" onClick={open}>
                        <FilterIcon color="currentColor" />
                        <AllFiltersButtonContent selectedFiltersCount={selectedFiltersCount} />
                    </Button>
                    <Divider direction="vertical" height="auto" />
                </Stack>
            </DrawerTrigger>
            <DrawerContent renderWhenClosed size="md" position="left">
                <LazyComponent
                    lazyImport={FiltersDrawerContent}
                    componentProps={{
                        filterCollections,
                        isOpen,
                        close,
                        numOfResult,
                        searchUrl,
                        searcherApiPath,
                        dominateSubCategoryId,
                        changedFilterCollections,
                        setChangedFilterCollections,
                        applyFiltersHandler,
                    }}
                />
            </DrawerContent>
        </Drawer>
    );
};
