export const EVENTS_MAPPING = {
    PAGE_VIEWED: {
        mixpanel: 'Viewed page',
    },
    PAGE_CHANGE: {
        mixpanel: 'Paginate',
    },
    FILTERS_APPLY: {
        mixpanel: 'Filtered by',
        biquery: 'applied_filter',
    },
    FILTERS_ARROW: {
        mixpanel: 'Clicked Arrow',
    },
    FILTERS_BACK: {
        mixpanel: 'Clicked back',
    },
    FILTERS_VIEW_ALL: {
        mixpanel: 'Clicked View All',
    },
    FILTER_REMOVE: {
        mixpanel: 'Removed filter',
        biquery: 'cleared_filter',
    },
    FILTERS_CLEAR_ALL: {
        mixpanel: 'Cleared filters',
        biquery: 'cleared_entire_filters',
    },
    SORT_CHANGE: {
        mixpanel: 'Sort changed',
        biquery: 'changed_sort_by',
    },
    VIEWED_RELATED_SEARCH_TERMS: {
        mixpanel: 'Viewed related search terms',
    },
    VIEWED_CHOICE_MODALITIES: {
        mixpanel: 'Viewed Fiverr Choice Modalities',
    },
    CLICKED_GOT_IT: {
        mixpanel: 'Clicked got it',
    },
    CLICKED_CHOICE_MODALITY: {
        mixpanel: 'Clicked Fiverr Choice Modality',
    },
    MARKETPLACE_CLICK: 'bottom_click.go_to_marketplace',
    CLICK: {
        mixpanel: 'Clicked',
        TARGETS: {
            TOP_FILTER: 'Top Filter: ',
            MORE_FILTERS: 'Top Filter: More Filters',
            SUB_CATEGORY_FILTER: 'Sub Categories Filter',
            NESTED_SUB_CATEGORY_NAV: 'Nested Sub Categories nav',
            GIGS_NSC_SUB_CATEGORY_NAV: 'Gigs Carousel Nested Sub Category Nav',
            HOW_FIVERR_WORKS_VIDEO: 'How Fiverr Works Video In SubCategory',
            WEBSITE_MATCH_VIDEO: 'Clicked O2O Banner Video',
            WEBSITE_MATCH_CTA: 'Clicked O2O Listing Banner',
            SHOW_FILTER_RESULTS: 'Show Filter Results',
        },
    },
    NO_RECOMMENDATIONS_SUPPLY: 'recommendations.no_supply',
    VIEWED_WEBSITE_MATCH_BANNER: 'Viewed Website Match Banner',
    HOVER_PROMOTED_ADS_TOOLTIP: {
        mixpanel: 'Hovered ad tooltip',
    },
    CLICKED_ON_CREATE_A_BRIEF: {
        mixpanel: 'Create a brief clicked',
        biquery: 'clicked_on_create_a_brief',
    },
    CLICKED_ON_PROMOTEֹֹֹ_BANNERֹ: {
        biquery: 'seller_self_view.promoting_banner.promote_gig_clicked',
    },
    VIEW_PROMOTE_BANNER: {
        biquery: 'seller_self_view.promoting_banner.shown',
    },
    VIEW_HIGHLIGHTED_RECOMMENDATIONS: {
        mixpanel: 'Viewed Highlighted Recommendations',
    },
    FILTER_VALIDATION_ERROR: {
        mixpanel: 'Filter validation error',
        ERROR_TYPE: {
            LOW_PRICE_VALIDATION: 'low price validation',
        },
    },
};
