import { isString, isEmpty } from 'lodash';
import { translate } from '@fiverr-private/i18n-react';
import { EXPERTISE_TYPE } from '../../../constants';

export const isNonEmptyString = (variable) => isString(variable) && !isEmpty(variable);

export const getTranslation = ({ type, id, parentId, translatedName, locale }) => {
    switch (type) {
        case EXPERTISE_TYPE.SKILL:
            return translatedName;

        case EXPERTISE_TYPE.LEAF_CATEGORY_ID:
            return parentId === id
                ? translate(`sub_categories.sub_category_${id}.name`, { locale })
                : translate(`sub_categories.sub_category_${parentId}.nested_sub_cat_${id}.name`, { locale });
        default:
            return null;
    }
};

export const isTagExperise = (tag) => [EXPERTISE_TYPE.LEAF_CATEGORY_ID, EXPERTISE_TYPE.SKILL].includes(tag?.type);
