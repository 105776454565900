import { isObject } from 'lodash';
import { FILTER } from '../constants';

export const transformLanguagesPairFilters = (change = {}) => {
    const languagePairFilterId = FILTER.LANGUAGES_PAIR.ID;

    if (isObject(change) && languagePairFilterId in change) {
        const { languages_pair: languagePair = [] } = change;

        const filtersWithTransformedLanguagePair = {
            ...change,
            languages_pair: languagePair?.[0]?.split('-') || [],
        };

        return filtersWithTransformedLanguagePair;
    }

    return change;
};
