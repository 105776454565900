import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Toggler from '@fiverr/ui_toggle';
import { Icon } from '@fiverr-private/fit';
import { ChevronDownThickIcon } from '@fiverr-private/icons';
import { getContext } from '@fiverr-private/fiverr_context';
import { Popover, PopoverAnchor, PopoverContent } from '@fiverr-private/popover';
import { Button } from '@fiverr-private/button';
import { EXPERIMENTS } from '@fiverr-private/listing_experiments';
import { Typography } from '@fiverr-private/typography';
import { tracker } from '@fiverr-private/listing_lib';
import { modifyActiveFilters } from '../../../../utils/filter_builder/_utils';
import { getDropdownFiltersGroupName } from '../../listings/impressionEnrichment';
import { allocateToClientExperiment } from '../../../../clientExperiments/clientUtils';
import { AppContext } from '../../../context/listingsContext';
import { SORT_BY_COMPONENT_ID } from '../../../../config/filters';
import { MENU_CONTENT_TYPES } from './consts';
import MenuContent from './MenuContent';

const MENU_PADDING_BOTTOM = 50;
const MENU_FOOTER_HEIGHT = 80;

class FloatingMenu extends Component {
    constructor(props) {
        super(props);
        this.contentRef = React.createRef();
        this.titleRef = React.createRef();
        this.applyFilters = this.applyFilters.bind(this);
        this.closeWithoutNavigation = this.closeWithoutNavigation.bind(this);
        this.clearAll = this.clearAll.bind(this);
        this.onTogglerWillOpen = this.onTogglerWillOpen.bind(this);
        this.setMaxMenuHeight = this.setMaxMenuHeight.bind(this);
        this.onMenuOpen = this.onMenuOpen.bind(this);
        this.onTogglerDidClose = this.onTogglerDidClose.bind(this);

        this.state = {
            maxMenuHeight: 200,
            navigateOnClose: true,
            isValidFilter: true,
        };
    }

    applyFilters() {
        const { activeFilters, navigationCondition, applyNavigation, groupName } = this.props;
        const { current } = this.contentRef;
        const values = current && current.getValues();

        if (navigationCondition(values, activeFilters)) {
            const modifiedActiveFilters = modifyActiveFilters(activeFilters, values);

            applyNavigation(values, modifiedActiveFilters, groupName);
        } else {
            this.closeWithoutNavigation();
        }
    }

    clearAll() {
        const { activeFilters, navigationCondition, applyNavigation } = this.props;
        const values = this.contentRef.current.getValues() || {};
        const keys = Object.keys(values).reduce((acc, key) => {
            acc[key] = undefined;
            return acc;
        }, {});

        if (navigationCondition(keys, activeFilters)) {
            applyNavigation(keys, activeFilters);
        } else {
            const { current } = this.contentRef;
            current && current.syncValues();
            this.closeWithoutNavigation();
        }
    }

    closeWithoutNavigation() {
        this.setState({ navigateOnClose: false });
        this.toggler.close();
    }

    onTogglerWillOpen() {
        this.setMaxMenuHeight();
        this.setState({ navigateOnClose: true });
    }

    onTogglerDidClose() {
        const { navigateOnClose, isValidFilter } = this.state;

        if (isValidFilter && navigateOnClose) {
            this.applyFilters();
        }
    }

    setMaxMenuHeight() {
        const innerHeight = window.innerHeight;
        const { top, height } = this.titleRef.current.getBoundingClientRect();
        const maxMenuHeight = innerHeight - (top + height + MENU_PADDING_BOTTOM + MENU_FOOTER_HEIGHT);

        this.setState({ maxMenuHeight });
    }

    onMenuOpen(clientExperimentsGroups) {
        const { trackOpen, groupName, id } = this.props;
        const title = getDropdownFiltersGroupName(groupName);
        tracker.click(title);
        trackOpen && tracker.trackFilterGroupOpen(title);
        const current = this.contentRef.current;
        current && current.onMenuOpen && current.onMenuOpen();

        if (id === SORT_BY_COMPONENT_ID) this.allocateToSortByPrice(clientExperimentsGroups);
    }

    allocateToSortByPrice(clientExperimentsGroups) {
        const { abTests } = getContext();

        if (
            Number(clientExperimentsGroups[EXPERIMENTS.HAWKS_SORT_BY_PRICE_POST_FREEZE]) > 0 &&
            !abTests[EXPERIMENTS.HAWKS_SORT_BY_PRICE_POST_FREEZE]
        ) {
            allocateToClientExperiment(EXPERIMENTS.HAWKS_SORT_BY_PRICE_POST_FREEZE, clientExperimentsGroups);
        }
    }

    render() {
        const {
            id,
            data,
            contentType,
            title,
            anchorButtonVariant,
            active,
            showApply,
            showClearAll,
            additionalProps,
            showSortByPrice,
            buttonClassName,
        } = this.props;

        const { maxMenuHeight } = this.state;

        return (
            <AppContext.Consumer>
                {({ clientExperimentsGroups }) => (
                    <Toggler
                        ref={(toggler) => (this.toggler = toggler)}
                        togglerWillOpen={this.onTogglerWillOpen}
                        togglerDidOpen={() => this.onMenuOpen(clientExperimentsGroups)}
                        togglerDidClose={this.onTogglerDidClose}
                    >
                        {({ isOpen, toggle, close, preventCloseAction }) => (
                            <Popover isOpen={isOpen} position="bottom-start">
                                <PopoverAnchor>
                                    <Button
                                        ref={(el) => {
                                            preventCloseAction(el);
                                            this.titleRef.current = el;
                                        }}
                                        size="lg"
                                        intent={active ? 'primary' : 'secondary'}
                                        className={classNames(buttonClassName, { active })}
                                        variant={anchorButtonVariant}
                                        onClick={toggle}
                                    >
                                        <Typography fontWeight="bold">{title}</Typography>
                                        <Icon
                                            className={classNames('chevron-icon-down', {
                                                'smaller-gap': id === SORT_BY_COMPONENT_ID,
                                            })}
                                            size={12}
                                        >
                                            <ChevronDownThickIcon />
                                        </Icon>
                                    </Button>
                                </PopoverAnchor>
                                <PopoverContent renderWhenClosed={false} disablePadding>
                                    <MenuContent
                                        id={id}
                                        data={data}
                                        closeMenu={close}
                                        preventCloseAction={preventCloseAction}
                                        showClearAll={showClearAll}
                                        showApply={showApply}
                                        contentType={contentType}
                                        additionalProps={additionalProps}
                                        applyFilters={this.applyFilters}
                                        clearAll={this.clearAll}
                                        contentRef={this.contentRef}
                                        isValidFilter={this.state.isValidFilter}
                                        setIsValidFilter={(isValidFilter) => this.setState({ isValidFilter })}
                                        showSortByPrice={showSortByPrice}
                                        maxMenuHeight={maxMenuHeight}
                                    />
                                </PopoverContent>
                            </Popover>
                        )}
                    </Toggler>
                )}
            </AppContext.Consumer>
        );
    }
}

FloatingMenu.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    anchorButtonVariant: PropTypes.string,
    showApply: PropTypes.bool,
    showClearAll: PropTypes.bool,
    data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    contentType: PropTypes.oneOf(Object.keys(MENU_CONTENT_TYPES).map((key) => MENU_CONTENT_TYPES[key])),
    activeFilters: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    additionalProps: PropTypes.object,
    showSortByPrice: PropTypes.bool,
    buttonClassName: PropTypes.string,
};

FloatingMenu.defaultProps = {
    id: 'more-filters',
    anchorButtonVariant: 'outline',
    additionalProps: {},
    showApply: true,
    showClearAll: true,
    buttonClassName: '',
};

export default FloatingMenu;
