import { useContext } from 'react';
import { ActiveFilters } from '../../Types';
import { ApplyEvent, SiderbarFiltersContextType, Triggers } from './Types';
import { SidebarFiltersContext } from './Provider';

export const useSidebarFiltersContext: () => SiderbarFiltersContextType = () => useContext(SidebarFiltersContext);

export const invokeApplyEvent = (
    applyEvent: ApplyEvent[],
    filtersToApply: ActiveFilters,
    trigger = Triggers.FILTER_COMPONENT_CLICK
) => {
    applyEvent.forEach((eventFunc) => eventFunc(filtersToApply, trigger));
};

export const invokeClearAllEvent = (clearAllEvent: (() => void)[]) => {
    clearAllEvent.forEach((eventFunc) => eventFunc());
};
