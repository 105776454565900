import React, { PureComponent } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useImpressionItem } from '@fiverr-private/impressionable';
import { I18nProvider } from '@fiverr-private/fiverr_context';
import { Switch } from '@fiverr-private/fit';
import { FILTERS_LOCATION } from '@fiverr-private/listing_lib';
import { FilterContext } from '../../../../../../context/filterContext';
import { TOP_GROUPING } from '../../../../../../../config/filters';
import { createBqEventData } from '../../impressionEnrichment/filterShown';
import { hasSupply } from '../utils';
import ToggleTooltip from './ToggleTooltip';
import './index.scss';

const Toggle = (props) => {
    const { title: baseTitle, text, params, tooltip, ...rest } = props;

    const enrichment = createBqEventData({
        filterId: props.id,
        position: props.position || FILTERS_LOCATION.FLOATING_TOP_RIGHT,
    });
    const [ref] = useImpressionItem({ enrichment });

    if (!hasSupply(props.options)) {
        return null;
    }

    return (
        <I18nProvider>
            {({ i18n }) => {
                const title = i18n.t(baseTitle, params);

                if (!tooltip) {
                    return <ToggleSwitch title={title} innerRef={ref} {...rest} />;
                }

                return (
                    <ToggleTooltip content={text && i18n.t(text, params)}>
                        <ToggleSwitch title={title} innerRef={ref} {...rest} />
                    </ToggleTooltip>
                );
            }}
        </I18nProvider>
    );
};

Toggle.displayName = 'Toggle';

class ToggleSwitch extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            toggled: props.options && props.options[0].selected,
        };

        this.innerRef = props.innerRef;

        this.handleOnClick = this.handleOnClick.bind(this);
    }

    static contextType = FilterContext;

    handleOnClick = () => {
        const { selectedValue, position, id, onCustomChange, source } = this.props;
        const newValue = { [id]: !this.state.toggled ? selectedValue : undefined };

        this.setState((prevState) => ({ ...prevState, toggled: !this.state.toggled }));

        if (onCustomChange) {
            onCustomChange(newValue);
            return;
        }

        const { navigationCondition, applyNavigation, activeFilters } = this.context;

        if (navigationCondition(newValue, activeFilters)) {
            applyNavigation(newValue, activeFilters, TOP_GROUPING.TOGGLERS, position, source);
        }
    };

    render() {
        const { id, options, className, title } = this.props;
        const { selected } = options[0];

        return (
            <div ref={this.innerRef} className={classNames('toggler-wrapper', className)} onClick={this.handleOnClick}>
                <Switch
                    size={Switch.SIZES.SMALL}
                    id={id}
                    checked={this.state.toggled}
                    value={selected}
                    onClick={(e) => e.stopPropagation()}
                />
                {title}
            </div>
        );
    }
}

const ToggleSwitchPropTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    options: PropTypes.array,
    className: PropTypes.string,
};

export const TogglePropTypes = {
    ...ToggleSwitchPropTypes,
    educationContent: PropTypes.string,
    text: PropTypes.string,
    showNotification: PropTypes.bool,
    position: PropTypes.string,
    tooltip: PropTypes.bool,
    onCustomChange: PropTypes.func,
};

Toggle.propTypes = TogglePropTypes;
ToggleSwitch.propTypes = {
    ...ToggleSwitchPropTypes,
    innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
};

Toggle.defaultProps = {
    checked: false,
    position: FILTERS_LOCATION.FLOATING_TOP,
    tooltip: false,
};

export default Toggle;
