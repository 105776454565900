import React from 'react';
import classNames from 'classnames';
import { I18n } from '@fiverr-private/i18n-react';
import { CheckStarSolidIcon } from '@fiverr-private/visuals';
import classes from './index.module.scss';

export const ProFreelancersBanner = () => (
    <div className={classes.card}>
        <div className={classNames('tbody-3 text-bold', classes.title)}>
            <I18n k="search_perseus.business_listings.pro_freelancers_banner.title" />
        </div>

        <div className="tbody-6 text-normal co-text-darkest">
            <ul className={classes.bulletList}>
                <li className="tbody-6 text-semi-bold">
                    <span className={classes.iconWrapper}>
                        <CheckStarSolidIcon color="business_800" />
                    </span>
                    <I18n k="search_perseus.business_listings.pro_freelancers_banner.bullet1" />
                </li>
                <li className="tbody-6 text-semi-bold">
                    <span className={classes.iconWrapper}>
                        <CheckStarSolidIcon color="business_800" />
                    </span>
                    <I18n k="search_perseus.business_listings.pro_freelancers_banner.bullet2" />
                </li>
                <li className="tbody-6 text-semi-bold">
                    <span className={classes.iconWrapper}>
                        <CheckStarSolidIcon color="business_800" />
                    </span>
                    <I18n k="search_perseus.business_listings.pro_freelancers_banner.bullet3" />
                </li>
                <li className="tbody-6 text-semi-bold">
                    <span className={classes.iconWrapper}>
                        <CheckStarSolidIcon color="business_800" />
                    </span>
                    <I18n k="search_perseus.business_listings.pro_freelancers_banner.bullet4" />
                </li>
            </ul>
        </div>
    </div>
);

export const ProFreelancersBannerWrapper = ({ children }) => <div className={classes.bannerWrapper}>{children}</div>;
