import { string, bool, oneOf } from 'prop-types';
import { badgesType } from '../../shared/types';
import { sellerLevelType } from '../SellerLevel/types';
import { BADGES } from './constants';

export default {
    className: string,
    isPro: bool,
    isChoice: bool,
    badges: badgesType,
    badge: oneOf([BADGES.PRO, BADGES.CHOICE]),
    sellerLevel: sellerLevelType,
};
