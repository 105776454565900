import { isEmpty } from 'lodash';
import { RawSearchResults } from '@fiverr-private/listing_types';

const getModalitiesGigs = (choiceModalities: RawSearchResults['choice_modalities']) =>
    isEmpty(choiceModalities)
        ? []
        : choiceModalities.buckets.reduce((total: RawSearchResults['gigs'], bucket) => total.concat(bucket.gigs), []);

const getRecommendationsGigs = (recommendations: RawSearchResults['recommendations']) => {
    const repeatedBuyers = recommendations?.find((element) => element.type === 'returning_buyers');
    const ftbFriendly = recommendations?.find((element) => element.type === 'ftb_friendly');

    if (repeatedBuyers) {
        return repeatedBuyers.gigs || [];
    }

    if (ftbFriendly) {
        return ftbFriendly.gigs || [];
    }

    return [];
};

const getSellersWhoSpeak = (sellersWhoSpeak: RawSearchResults['localization_sellers']) => sellersWhoSpeak?.gigs ?? [];

export const getCarouselGigs = (rawListingData: RawSearchResults): RawSearchResults['gigs'] => {
    const {
        choice_modalities: choiceModalities,
        recommendations,
        localization_sellers: localizationSellers,
    } = rawListingData;

    return [
        ...getModalitiesGigs(choiceModalities),
        ...getRecommendationsGigs(recommendations),
        ...getSellersWhoSpeak(localizationSellers),
    ];
};
