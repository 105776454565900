import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from '@fiverr-private/fit';

const SwitchContainer = ({ value, id, onChange }) => {
    const onChangeHandler = ({ target }) => {
        onChange(id, target.checked ? true : undefined);
    };

    return <Switch checked={!!value} onChange={onChangeHandler} />;
};

SwitchContainer.defaultProps = {
    onChange: (id, value) => ({ id, value }),
};

SwitchContainer.propTypes = {
    id: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
};

export default SwitchContainer;
