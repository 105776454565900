import { localStorageService } from '@fiverr-private/listing_lib';

export const keepQuery = (query: string): void => {
    localStorageService.set(localStorageService.KEYS.PROFESSION_FILTER_RELATED_QUERY, query);
};

export const fetchQuery = (): string | null =>
    localStorageService.get(localStorageService.KEYS.PROFESSION_FILTER_RELATED_QUERY);

export const keepImageIndex = (imageIndex: number | null): void => {
    const imageIndexAsString = JSON.stringify(imageIndex);
    localStorageService.set(localStorageService.KEYS.PROFESSION_FILTER_IMAGE_INDEX, imageIndexAsString);
};

export const fetchImageIndex = (): number | null => {
    const imageIndexAsString = localStorageService.get(localStorageService.KEYS.PROFESSION_FILTER_IMAGE_INDEX);
    const imageIndex = imageIndexAsString ? parseInt(imageIndexAsString, 10) : null;
    return imageIndex;
};

export const resetImageIndex = (): void => {
    localStorageService.set(localStorageService.KEYS.PROFESSION_FILTER_IMAGE_INDEX, null);
};

export const keepImageMapper = (imageMapper: Record<string, string>): void => {
    localStorageService.set(localStorageService.KEYS.PROFESSION_FILTER_IMAGE_MAPPER, imageMapper);
};

export const fetchImageMapper = (): Record<string, string> => {
    const imageMapper = localStorageService.get(localStorageService.KEYS.PROFESSION_FILTER_IMAGE_MAPPER);
    return imageMapper || {};
};

export const resetImageMapper = (): void => {
    localStorageService.set(localStorageService.KEYS.PROFESSION_FILTER_IMAGE_MAPPER, {});
};
