import video_editing from './images/video_editing.svg';
import short_video_ads from './images/short_video_ads.svg';
import business_plans from './images/business_plans.svg';
import market_research from './images/market_research.svg';
import presentations from './images/presentations.svg';
import business_consulting from './images/business_consulting.svg';
import branding_services from './images/branding_services.svg';
import financial_consulting from './images/financial_consulting.svg';
import flyer_distribution from './images/flyer_distribution.svg';
import data_entry from './images/data_entry.svg';
import lead_generation from './images/lead_generation.svg';
import hr_consulting from './images/hr_consulting.svg';
import web_analytics from './images/web_analytics.svg';
import social_media_marketing from './images/social_media_marketing.svg';
import web_traffic from './images/web_traffic.svg';
import video_marketing from './images/video_marketing.svg';
import email_marketing from './images/email_marketing.svg';
import local_seo from './images/local_seo.svg';
import influencer_marketing from './images/influencer_marketing.svg';
import music_promotion from './images/music_promotion.svg';
import book_ebook_marketing from './images/book_ebook_marketing.svg';
import text_message_marketing from './images/text_message_marketing.svg';
import cartoons_comics from './images/cartoons_comics.svg';
import logo_design from './images/logo_design.svg';
import illustration from './images/illustration.svg';
import photoshop_editing from './images/photoshop_editing.svg';
import presentation_design from './images/presentation_design.svg';
import flyer_design from './images/flyer_design.svg';
import business_cards_stationery from './images/business_cards_stationery.svg';
import web_banners from './images/web_banners.svg';
import social_media_design from './images/social_media_design.svg';
import invitation_design from './images/invitation_design.svg';
import t_shirts_merchandise from './images/t_shirts_merchandise.svg';
import vector_tracing from './images/vector_tracing.svg';
import infographic_design from './images/infographic_design.svg';
import packaging_design from './images/packaging_design.svg';
import industrial_product_design from './images/industrial_product_design.svg';
import portraits_caricatures from './images/portraits_caricatures.svg';
import car_wraps from './images/car_wraps.svg';
import brochure_design from './images/brochure_design.svg';
import poster_design from './images/poster_design.svg';
import menu_design from './images/menu_design.svg';
import postcard_design from './images/postcard_design.svg';
import catalog_design from './images/catalog_design.svg';
import brand_style_guides from './images/brand_style_guides.svg';
import character_modeling from './images/character_modeling.svg';
import storyboards from './images/storyboards.svg';
import graphics_for_streamers from './images/graphics_for_streamers.svg';
import tattoo_design from './images/tattoo_design.svg';
import album_cover_design from './images/album_cover_design.svg';
import pattern_design from './images/pattern_design.svg';
import resume_design from './images/resume_design.svg';
import life_coaching from './images/life_coaching.svg';
import health_nutrition_fitness from './images/health_nutrition_fitness.svg';
import online_tutoring from './images/online_tutoring.svg';
import astrology_psychics from './images/astrology_psychics.svg';
import gaming from './images/gaming.svg';
import viral_videos from './images/viral_videos.svg';
import arts_crafts from './images/arts_crafts.svg';
import your_message_on from './images/your_message_on.svg';
import greeting_cards_videos from './images/greeting_cards_videos.svg';
import fitness_lessons from './images/fitness_lessons.svg';
import cooking_lessons from './images/cooking_lessons.svg';
import craft_lessons from './images/craft_lessons.svg';
import personal_stylists from './images/personal_stylists.svg';
import mixing_mastering from './images/mixing_mastering.svg';
import voice_over from './images/voice_over.svg';
import session_musicians from './images/session_musicians.svg';
import producers_composers from './images/producers_composers.svg';
import singers_vocalists from './images/singers_vocalists.svg';
import audiobook_production from './images/audiobook_production.svg';
import songwriters from './images/songwriters.svg';
import dj_drops_tags from './images/dj_drops_tags.svg';
import online_music_lessons from './images/online_music_lessons.svg';
import databases from './images/databases.svg';
import mobile_apps from './images/mobile_apps.svg';
import user_testing from './images/user_testing.svg';
import qa_review from './images/qa_review.svg';
import support_it from './images/support_it.svg';
import chatbots from './images/chatbots.svg';
import online_coding_lessons from './images/online_coding_lessons.svg';
import spokespersons_videos from './images/spokespersons_videos.svg';
import logo_animation from './images/logo_animation.svg';
import whiteboard_animated_explainers from './images/whiteboard_animated_explainers.svg';
import lyric_music_videos from './images/lyric_music_videos.svg';
import character_animation from './images/character_animation.svg';
import slideshow_videos from './images/slideshow_videos.svg';
import live_action_explainers from './images/live_action_explainers.svg';
import product_photography from './images/product_photography.svg';
import intros_outros from './images/intros_outros.svg';
import animation_for_kids from './images/animation_for_kids.svg';
import animated_gifs from './images/animated_gifs.svg';
import animation_for_streamers from './images/animation_for_streamers.svg';
import threeD_product_animation from './images/threeD_product_animation.svg';
import game_trailers from './images/game_trailers.svg';
import local_photography from './images/local_photography.svg';
import subtitles_captions from './images/subtitles_captions.svg';
import app_website_previews from './images/app_website_previews.svg';
import book_trailers from './images/book_trailers.svg';
import real_estate_promos from './images/real_estate_promos.svg';
import article_to_video from './images/article_to_video.svg';
import elearning_video_production from './images/elearning_video_production.svg';
import unboxing_videos from './images/unboxing_videos.svg';
import drone_videography from './images/drone_videography.svg';
import creative_writing from './images/creative_writing.svg';
import translation from './images/translation.svg';
import transcripts from './images/transcripts.svg';
import resume_writing from './images/resume_writing.svg';
import proofreading_editing from './images/proofreading_editing.svg';
import articles_blog_posts from './images/articles_blog_posts.svg';
import research_summaries from './images/research_summaries.svg';
import legal_writing from './images/legal_writing.svg';
import product_descriptions from './images/product_descriptions.svg';
import website_content from './images/website_content.svg';
import sales_copy from './images/sales_copy.svg';
import email_copy from './images/email_copy.svg';
import business_names_slogans from './images/business_names_slogans.svg';
import technical_writing from './images/technical_writing.svg';
import beta_reading from './images/beta_reading.svg';
import speechwriting from './images/speechwriting.svg';
import brand_voice_tone from './images/brand_voice_tone.svg';
import cover_letters from './images/cover_letters.svg';
import linkedin_profiles from './images/linkedin_profiles.svg';
import book_editing from './images/book_editing.svg';
import ad_copy from './images/ad_copy.svg';
import elearning_content_development from './images/elearning_content_development.svg';

export default {
    107: creative_writing,
    108: translation,
    109: transcripts,
    112: resume_writing,
    114: proofreading_editing,
    163: articles_blog_posts,
    169: research_summaries,
    175: legal_writing,
    334: product_descriptions,
    335: website_content,
    336: sales_copy,
    337: email_copy,
    338: business_names_slogans,
    341: technical_writing,
    347: beta_reading,
    355: speechwriting,
    396: brand_voice_tone,
    397: cover_letters,
    398: linkedin_profiles,
    430: book_editing,
    435: ad_copy,
    436: elearning_content_development,
    101: spokespersons_videos,
    104: logo_animation,
    228: whiteboard_animated_explainers,
    234: lyric_music_videos,
    235: character_animation,
    249: slideshow_videos,
    330: live_action_explainers,
    332: product_photography,
    343: intros_outros,
    350: animation_for_kids,
    356: animated_gifs,
    368: animation_for_streamers,
    369: threeD_product_animation,
    372: game_trailers,
    374: local_photography,
    384: subtitles_captions,
    399: app_website_previews,
    400: book_trailers,
    401: real_estate_promos,
    421: article_to_video,
    426: elearning_video_production,
    427: unboxing_videos,
    439: drone_videography,
    99: video_editing,
    329: short_video_ads,
    87: databases,
    141: mobile_apps,
    144: user_testing,
    145: qa_review,
    146: support_it,
    328: chatbots,
    416: online_coding_lessons,
    10: mixing_mastering,
    16: voice_over,
    156: session_musicians,
    157: producers_composers,
    158: singers_vocalists,
    391: audiobook_production,
    392: songwriters,
    405: dj_drops_tags,
    412: online_music_lessons,
    21: business_plans,
    23: market_research,
    24: presentations,
    26: business_consulting,
    27: branding_services,
    28: financial_consulting,
    326: flyer_distribution,
    339: data_entry,
    351: lead_generation,
    409: hr_consulting,
    59: web_analytics,
    67: social_media_marketing,
    68: web_traffic,
    69: video_marketing,
    194: email_marketing,
    206: local_seo,
    212: influencer_marketing,
    327: music_promotion,
    415: book_ebook_marketing,
    446: text_message_marketing,
    48: cartoons_comics,
    49: logo_design,
    50: illustration,
    53: photoshop_editing,
    54: presentation_design,
    55: flyer_design,
    56: business_cards_stationery,
    148: web_banners,
    149: social_media_design,
    153: invitation_design,
    154: t_shirts_merchandise,
    155: vector_tracing,
    246: infographic_design,
    331: packaging_design,
    333: industrial_product_design,
    340: portraits_caricatures,
    345: car_wraps,
    358: brochure_design,
    359: poster_design,
    360: menu_design,
    361: postcard_design,
    362: catalog_design,
    363: brand_style_guides,
    366: character_modeling,
    367: storyboards,
    371: graphics_for_streamers,
    385: tattoo_design,
    411: album_cover_design,
    420: pattern_design,
    422: resume_design,
    73: life_coaching,
    75: health_nutrition_fitness,
    77: online_tutoring,
    78: astrology_psychics,
    279: gaming,
    295: viral_videos,
    310: arts_crafts,
    318: your_message_on,
    321: greeting_cards_videos,
    414: fitness_lessons,
    417: cooking_lessons,
    418: craft_lessons,
    431: personal_stylists,
};
