const { TYPE } = require('../service/flpDataByCategoryId/constants');

const PAGE_NAME = 'search_perseus';

const MEDIA_GRID_SM = '600px';
const MEDIA_GRID_MD = '900px';
const MEDIA_GRID_LG = '1160px';
const MEDIA_GRID_XL = '1760px';

const CUSTOM_FILTER_OPTION = 'custom';

const EDUCATION_TOOLTIPS_PREFIX = 'fiverr_education_tooltips';

const QUERY_PARAMS_FILTER_FUNCTIONS = {
    leaf_category: ({ nested_sub_category, sub_category }) => nested_sub_category || sub_category,
};

const STATIC_FILTERS_MAPPING_SEPARATORS = {
    HYPHEN: '-',
};

const SERIALIZE_LOG_OBJECT_DELIMETERS = {
    ARRAY_DELIMETER: ',',
    KEY_VALUE_DELIMETER: ':',
    PAIRS_DELIMETER: '|',
};

const FLOWS = {
    CATEGORY: 'category',
    SEARCH: 'search',
    BUSINESS_CATEGORIES: 'business_categories',
    BUSINESS_SEARCH: 'business_search',
    PROFESSION_GIGS_SEARCH: 'profession_gigs_search',
    PROFESSION_ROLES_SEARCH: 'profession_roles_search',
};

const FLOWS_ENRICHMENT = {
    LOGO_MAKER_BANNER: 'logo_maker_banner',
    SEARCH_CHOICE_MODALITIES: 'search_gigs_with_modalities',
    SEARCH_CHOICE_MODALITIES_WITH_N_BUCKETS: (bucketsAmount) => `search_gigs_with_modalities_${bucketsAmount}_buckets`,
    SEARCH_CHOICE_MODALITIES_WITH_LOGO_MAKER_BANNER: 'search_gigs_with_modalities_logo_maker_banner',
    SEARCH_RECOMMENDATIONS: (rowNumber = 1) => `search_gigs_with_recommendations_row_${rowNumber}`,
    SEARCH_RECOMMENDATIONS_WITH_LOGO_MAKER_BANNER: (rowNumber = 1) =>
        `search_gigs_with_recommendations_row_${rowNumber}_logo_maker_banner`,
    SEARCH_GIGS_WITH_SELLERS_WHO_SPEAKS: 'search_gigs_with_sellers_who_speak',
};

const HANDLERS = {
    SSR: 'ssr',
};

const CAROUSEL_SOURCES = {
    YOUR_RECENTLY_VIEWED_GIGS: 'your_recently_viewed_gigs',
};

const VIEW_TYPE = {
    GRID: 'grid',
    LIST: 'list',
};

const CONTEXT_PARAMS_DEFAULTS = {
    page_size: 48,
    agency_page_size: 24,
    roles_page_size: 18,
    page: 1,
    offset: 0,
    view_type: VIEW_TYPE.GRID,
};

const USD_CURRENCY = 'USD';

const REPORT_DATA = {
    MAIN: {
        group: 'algos',
        type: 'listings_impression',
    },
    LISTINGS: {
        now_toggle_exists: false,
        now_toggle_used: false,
        pro_toggle_exists: false,
        pro_toggle_used: false,
        view_type: VIEW_TYPE.GRID,
    },
};

const CAROUSEL_POSITIONS = {
    MAIN: 1,
};

/** @deprecated please use String literals directly based on the Actual BQ Schema */
const BI_SOURCES = {
    TOGGLE_FILTERS: 'toggle_filters',
    VISUAL_FILTERS: 'visual_filters',
    METADATA_FILTERS: 'metadata_filters',
    DROPDOWN_FILTERS: 'drop_down_filters',
    PAGINATION: 'pagination',
    PRICE_BUCKETS: 'price_buckets_filter',
    PRICING_FACTOR: 'pricing_factor_filter',
    TAG_FILTERS: 'tag_filters',
    BUSINESS_SIDE_FILTERS: 'business_side_filters',
    SSYL_SEE_MORE_BUTTON: 'ssyl_see_more_button',
    DRAWER: 'drawer',
};

const REQUEST_CONTEXT_FIELDS = [
    'query',
    'flow',
    'currentUser',
    'currency',
    'isTouch',
    'categoryIds',
    'source',
    'view_type',
    'loggedIn',
    'categoryName',
    'subCategoryName',
    'nestedSubCategoryName',
    'should_suggest',
    'showListViewCards',
    'staticFilters',
    'searchAutoComplete',
    'searchComponentData',
    'significantLeafCategories',
    'cachedSlug',
    'dominateSubCategoryId',
    'dominantLeafCategoryParent',
    'dominantLeafCategory',
    'abTests',
    'userGuid',
    'logoMakerContext',
    'searchUrl',
];

const REQUEST_CONTEXT_DEFAULTS = {
    query: '',
    staticFilters: {},
};

const FULL_INDEX_SIZE = 10;
const HALF_INDEX_SIZE = FULL_INDEX_SIZE / 2;
const MAX_INDEX = 20;

const SEARCH_DID_YOU_MEAN_SOURCE = 'search_did_you_mean'; // todo arrange sources under a single const
const BREADCRUMBS_SOURCE = 'breadcrumbs';
const SEARCH_AUTOCOMPLETE = 'search-autocomplete';
const SEARCH_AUTOCOMPLETE_ORIGINAL_TERM = `${SEARCH_AUTOCOMPLETE}-original-term`;
const SEARCH_AUTOCOMPLETE_TYPE = `${SEARCH_AUTOCOMPLETE}-type`;
const SEARCH_AUTOCOMPLETE_POSITION = `${SEARCH_AUTOCOMPLETE}-position`;
const SEARCH_AUTOCOMPLETE_AVAILABLE = `${SEARCH_AUTOCOMPLETE}-available`;
const SEARCH_AUTOCOMPLETE_SUB_TYPE = `${SEARCH_AUTOCOMPLETE}-sub-type`;
const SEARCH_AUTOCOMPLETE_LEAF_CATEGORY = 'sub_category';

const SEARCH_AUTOCOMPLETE_MAPPING = {
    [SEARCH_AUTOCOMPLETE_ORIGINAL_TERM]: () => ['autocomplete_original_term'],
    [SEARCH_AUTOCOMPLETE_TYPE]: () => ['autocomplete_type'],
    [SEARCH_AUTOCOMPLETE_POSITION]: (pos) => ['autocomplete_position', pos && Number(pos)],
    [SEARCH_AUTOCOMPLETE_AVAILABLE]: (isAvailable) => ['is_autocomplete', !!isAvailable],
    [SEARCH_AUTOCOMPLETE_SUB_TYPE]: (subType) => ['autocomplete_sub_type', subType],
    [SEARCH_AUTOCOMPLETE_LEAF_CATEGORY]: (id) => ['autocomplete_leaf_category_id', id],
};

const PERSEUS_ERROR_TYPES = {
    OOPS_PAGE_ERROR: 'oopsPageError',
};

const LOG_LEVELS = {
    ERROR: 'error',
    WARN: 'warn',
};

const SORT_BY = 'sort-by';

const FIVERR_CHOICE = 'fiverr_choice';
const PROMOTED_GIGS = 'promoted_gigs';
const VIDEO_STRIP = 'video_strip';
const MODALITY_GIG_TYPE = 'choice_modality';
const REPEATED_BUYERS = 'returning_buyers';
const REPEATED_BUYERS_TYPE = 'recommendation_returning_buyers';
const FTB_FRIENDLY = 'ftb_friendly';
const FTB_FRIENDLY_TYPE = 'recommendation_ftb_friendly';
const SELLERS_WHO_SPEAKS_TYPE = 'localization_sellers';

const NSC_PATH_MAPPER = {
    nsc_tiles_carousel: 'NESTED_SUB_CATEGORY_NAV',
    gigs_carousel: 'GIGS_NSC_SUB_CATEGORY_NAV',
};

const HOME_TITLES = {
    ROOT: 'fiverr',
    HOME: 'home',
};

const CONTEXT = {
    SUB_CATEGORIES: 'sub_categories',
};

const SORT_BY_FILTER_TYPES = {
    AUTO: 'auto',
    RATING: 'rating',
    NEW: 'new',
    PRICE_ASC: 'price_asc',
    PRICE_DESC: 'price_desc',
};

const CHOICE_MODALITIES = {
    BUCKETS_SIZE: {
        MEDIUM: 3,
    },
    TYPE: {
        PRICE: 'price',
        SERVICE: 'service',
    },
};

const PAIR_FILTERS_IDS_FLP = [TYPE.LANGUAGES_PAIR];
const MULTIPLE_FILTERS_IDS_FLP = [TYPE.MULTIPLE_FILTERS];

const LOGO_DESIGN_SUBCATEGORY_ID = 49;
const VOICE_OVER_SUBCATEGORY_ID = 16;

const LOGO_MAKER_DESKTOP_ROW_POSITION = 4;
const LOGO_MAKER_MOBILE_ROW_POSITION = 5;

const STICKY_COMPONENT_SMALL_SIZE = 70;
const STICKY_COMPONENT_LARGE_SIZE = 110;

const MINIMUM_RELATED_GUIDES = 3;
const BRAND_STYLE_GUIDE_SC_SLUG = 'brand-style-guides';

const PROMOTE_ENROLLED_STATUS = 'Activation';

const SEMANTIC_REDIRECT_SOURCE = 'semantic-redirect-cancel';

const SKILLS_REDIRECT_SOURCE = 'category-skills';

const QUERY_MODIFICATION_REASONS = {
    SPELLCHECK: 'spellcheck',
    SEMANTIC_REDIRECT: 'semantic_redirect',
};

const SELLER_FOCUSEDED_COMPONENT_NAME = 'seller_focused_card';

const LISTING_TYPE = {
    GIGS: 'gigs',
    EXPERTS: 'experts',
};

export {
    STATIC_FILTERS_MAPPING_SEPARATORS,
    SERIALIZE_LOG_OBJECT_DELIMETERS,
    FULL_INDEX_SIZE,
    HALF_INDEX_SIZE,
    MAX_INDEX,
    FLOWS,
    FLOWS_ENRICHMENT,
    VIEW_TYPE,
    CONTEXT_PARAMS_DEFAULTS,
    USD_CURRENCY,
    REPORT_DATA,
    CAROUSEL_POSITIONS,
    HOME_TITLES,
    BREADCRUMBS_SOURCE,
    SEARCH_DID_YOU_MEAN_SOURCE,
    SKILLS_REDIRECT_SOURCE,
    REQUEST_CONTEXT_FIELDS,
    REQUEST_CONTEXT_DEFAULTS,
    MEDIA_GRID_SM,
    MEDIA_GRID_MD,
    MEDIA_GRID_LG,
    MEDIA_GRID_XL,
    SEARCH_AUTOCOMPLETE,
    SEARCH_AUTOCOMPLETE_ORIGINAL_TERM,
    SEARCH_AUTOCOMPLETE_TYPE,
    SEARCH_AUTOCOMPLETE_POSITION,
    SEARCH_AUTOCOMPLETE_AVAILABLE,
    SEARCH_AUTOCOMPLETE_MAPPING,
    CAROUSEL_SOURCES,
    HANDLERS,
    PERSEUS_ERROR_TYPES,
    SORT_BY,
    LOG_LEVELS,
    FIVERR_CHOICE,
    MODALITY_GIG_TYPE,
    REPEATED_BUYERS,
    REPEATED_BUYERS_TYPE,
    FTB_FRIENDLY,
    FTB_FRIENDLY_TYPE,
    PROMOTED_GIGS,
    BI_SOURCES,
    NSC_PATH_MAPPER,
    PAGE_NAME,
    LOGO_DESIGN_SUBCATEGORY_ID,
    LOGO_MAKER_DESKTOP_ROW_POSITION,
    LOGO_MAKER_MOBILE_ROW_POSITION,
    PAIR_FILTERS_IDS_FLP,
    MULTIPLE_FILTERS_IDS_FLP,
    CONTEXT,
    VOICE_OVER_SUBCATEGORY_ID,
    STICKY_COMPONENT_SMALL_SIZE,
    STICKY_COMPONENT_LARGE_SIZE,
    CUSTOM_FILTER_OPTION,
    EDUCATION_TOOLTIPS_PREFIX,
    MINIMUM_RELATED_GUIDES,
    BRAND_STYLE_GUIDE_SC_SLUG,
    SORT_BY_FILTER_TYPES,
    PROMOTE_ENROLLED_STATUS,
    CHOICE_MODALITIES,
    SELLERS_WHO_SPEAKS_TYPE,
    SEMANTIC_REDIRECT_SOURCE,
    QUERY_MODIFICATION_REASONS,
    SELLER_FOCUSEDED_COMPONENT_NAME,
    QUERY_PARAMS_FILTER_FUNCTIONS,
    LISTING_TYPE,
    VIDEO_STRIP,
};
