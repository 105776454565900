import { setCookie, expireCookie } from '@fiverr-private/listing_lib';
import { SHOW_PRO_MODAL_DISMISSED_COOKIE_KEY } from '../../../../../shared/constants/proSearchModal';

export const storage = {
    isModalDismissed: () =>
        typeof document !== 'undefined' ? document.cookie.includes(SHOW_PRO_MODAL_DISMISSED_COOKIE_KEY) : true,
    setModalDismissed: () => {
        setCookie({
            name: SHOW_PRO_MODAL_DISMISSED_COOKIE_KEY,
            value: true,
            expirationDays: 1,
            domain: 'fiverr.com',
        });
    },
    _reset: () => {
        expireCookie(SHOW_PRO_MODAL_DISMISSED_COOKIE_KEY);
    },
};
