import React from 'react';
import { Typography } from '@fiverr-private/typography';
import { getOptionCountCopy } from './util/getOptionCountCopy';

interface MetadataFilterTileCountProps {
    count?: number;
}

export const MetadataFilterTileCount = ({ count }: MetadataFilterTileCountProps) => {
    if (!count) {
        return null;
    }

    const optionCountCopy = getOptionCountCopy(count);

    return (
        <Typography fontWeight="normal" color="grey_900">
            {optionCountCopy}
        </Typography>
    );
};
