const getNumOfItemsToShow = (itemsWidth, maxWidth) => {
    let sum = 0;
    let numOfItems = 0;

    for (const itemWidth of itemsWidth) {
        sum += itemWidth;
        if (sum <= maxWidth) {
            numOfItems++;
        } else {
            break;
        }
    }

    return numOfItems;
};

const measureElementWidth = (element) => {
    const style = getComputedStyle(element);

    const marginLeft = parseInt(style.marginLeft, 10);
    const marginRight = parseInt(style.marginRight, 10);
    const width = element.offsetWidth;

    return width + marginRight + marginLeft;
};

export { getNumOfItemsToShow, measureElementWidth };
