const { calculateGroup } = require('@fiverr-private/experiments_package');
const { ALLOCATION_DEFAULTS } = require('./constants');

const allocationUtils = ({
    id,
    experimentType = ALLOCATION_DEFAULTS.experimentType,
    numOfGroups = ALLOCATION_DEFAULTS.numOfGroups,
}) => ({
    calculateGroup: (context) => calculateGroup({ experimentId: id, experimentType, numOfGroups, context }),
});

const getAllocationUtils = (clientExperiments) => {
    const clientExperimentsWithUtils = {};

    Object.entries(clientExperiments).forEach(([key, value]) => {
        clientExperimentsWithUtils[key] = { ...value, ...allocationUtils(value) };
    });

    return clientExperimentsWithUtils;
};

module.exports = {
    getAllocationUtils,
};
