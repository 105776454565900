import { isEmpty } from 'lodash';
import { LoyaltyProgramTier } from '@fiverr-private/loyalty_utils';

export const shouldShowLoyaltyBanner = ({ loyaltyData, isRelatedToAI, emptyFilters, resultsCount }) => {
    const minimumItemsToShow = 3;

    if (isEmpty(loyaltyData)) {
        return false;
    }

    if (!resultsCount) {
        return false;
    }

    if (!emptyFilters) {
        return false;
    }

    if (loyaltyData?.tier === LoyaltyProgramTier.SILVER) {
        return false;
    }

    if (isRelatedToAI) {
        return false;
    }

    if (resultsCount < minimumItemsToShow) {
        return false;
    }

    return true;
};
