const HttpStatusCode = {
    OK: 200,
    CREATED: 201,
    ACCEPTED: 202,
    MOVED_PERMANENTLY: 301,
    MOVED_TEMPORARILY: 302,
    BAD_REQUEST: 400,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    REQUEST_TIMEOUT: 408,
    TOO_MANY_REQUESTS: 429,
    INTERNAL_SERVER_ERROR: 500,
    SERVICE_UNAVAILABLE: 503,
    GATEWAY_TIMEOUT: 504,
};

const V2StatusCode = {
    REDIRECT: 403,
};

module.exports = {
    HttpStatusCode,
    V2StatusCode,
};
