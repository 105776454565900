import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getContext } from '@fiverr-private/fiverr_context';
import { translate } from '@fiverr-private/i18n-react';
import TooltipWrapper from '../../../shared/components/TooltipWrapper';
import { toClassname } from '../../utils';
import { EXCLUDED_SELLER_LEVELS } from '../constants';
import './index.scss';

const SellerLevel = ({ level }) => {
    const { locale } = getContext();

    if (!level) {
        return null;
    }

    const className = classNames('level', toClassname(level));

    const showSellerLevel = !EXCLUDED_SELLER_LEVELS.includes(level);

    const tooltipContent = translate(`gig_listings.gig_cards.seller_level.${level}_tooltip`, { locale });

    return (
        <span className={className}>
            <TooltipWrapper
                className="seller-name-tooltip"
                shouldRenderTooltip={showSellerLevel}
                content={tooltipContent}
            >
                {showSellerLevel && translate(`gig_listings.gig_cards.seller_level.${level}`, { locale })}
            </TooltipWrapper>
        </span>
    );
};

SellerLevel.propTypes = {
    level: PropTypes.string,
};

export default SellerLevel;
