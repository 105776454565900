import React, { useContext } from 'react';
import { I18nProvider } from '@fiverr-private/fiverr_context';
import SeoFloatingMenu from '../floating_topbar/menus/FloatingMenu';
import { MENU_CONTENT_TYPES } from '../floating_topbar/menus/consts';
import { sortNavigation, shouldSortNavigate } from '../../../service/clientNavigation';
import { AppContext } from '../../context/listingsContext';
import { SORT_BY_COMPONENT_ID, SORT_GROUPING } from '../../../config/filters';
import { btnStyles } from './SortBy.ve.css';

const SortBy = ({ title, data }) => {
    const {
        appFilters: { showSortByPrice },
    } = useContext(AppContext);

    return (
        <I18nProvider>
            {({ i18n }) => (
                <div className="sort-by-wrapper">
                    <span className="pre-title co-grey-900">{i18n.t('search_perseus.sorting.sort_by')}</span>
                    <SeoFloatingMenu
                        title={title}
                        id={SORT_BY_COMPONENT_ID}
                        data={showSortByPrice ? data : data.filter(({ isPriceRelated }) => !isPriceRelated)}
                        menuTitle={i18n.t('search_perseus.sorting.sort_by')}
                        buttonClassName={btnStyles}
                        anchorButtonVariant="ghost"
                        showApply={false}
                        showClearAll={false}
                        contentType={MENU_CONTENT_TYPES.LABELS_LIST}
                        activeFilters={data}
                        navigationCondition={shouldSortNavigate}
                        applyNavigation={sortNavigation}
                        additionalProps={{ closeOnClick: true }}
                        showSortByPrice={showSortByPrice}
                        trackOpen
                        groupName={SORT_GROUPING}
                    />
                </div>
            )}
        </I18nProvider>
    );
};

export default SortBy;
