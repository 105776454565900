import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './index.scss';

const RadioGroup = ({ value, options, id, onChange }) => {
    const onSelect = ({ target }) => {
        const { value } = target;
        onChange(id, value);
    };

    return (
        <ul className="radio-group touch">
            {options.map(({ alias, id: optionId }) => (
                <li key={id} className={classNames({ value })}>
                    <label>
                        <input
                            type="radio"
                            name={id}
                            checked={value === optionId}
                            value={optionId}
                            onChange={onSelect}
                        />
                        <span className="touch-option">{alias}</span>
                    </label>
                </li>
            ))}
        </ul>
    );
};

RadioGroup.propTypes = {
    value: PropTypes.string,
    options: PropTypes.array,
    id: PropTypes.string,
    onChange: PropTypes.func,
};

RadioGroup.defaultProps = {
    options: [],
    onChange: () => true,
};

export default RadioGroup;
