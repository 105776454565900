import React, { useState, useContext } from 'react';
import { get, isEmpty } from 'lodash';
import { array } from 'prop-types';
import classnames from 'classnames';
import { I18n } from '@fiverr-private/i18n-react';
import { Button } from '@fiverr-private/fit';
import { ComboBox } from '@fiverr-private/orca';
import { getContext } from '@fiverr-private/fiverr_context';
import { FilterIcon } from '@fiverr-private/icons';
import { grey_1200 } from '@fiverr-private/sass/helpers';
import { FILTER } from '@fiverr-private/listing_lib';
import { AppContext } from '../../../../../../context/listingsContext';
import { getInitialLanguage, parseLanguageList } from '../../../../../../common/ExplicitLanguagePair/utils';
import { invokeApplyEvent, Triggers, useSidebarFiltersContext } from '../../../Context';
import NoOptions from '../ComboBoxFilter/NoOptions';
import styles from './index.module.scss';

const TranslationFilter = ({ filters = [] }) => {
    const { activeFilters = {} } = useContext(AppContext);
    const { applyEvent, newActiveFilters, setNewActiveFilters, activeTrigger, clearAllEvent } =
        useSidebarFiltersContext();

    const { locale } = getContext();

    const languagesFilter = filters.find(({ id }) => id === FILTER.LANGUAGES_PAIR.ID);
    const { fromOptions = [], toOptions = [] } = languagesFilter?.options || {};

    const activeFromLanguageValue = get(newActiveFilters || activeFilters, `${FILTER.LANGUAGES_PAIR.ID}[0]`);
    const activeToLanguageValue = get(newActiveFilters || activeFilters, `${FILTER.LANGUAGES_PAIR.ID}[1]`);

    const [selectedLanguages, setSelectedLanguages] = useState({
        from: getInitialLanguage(activeFromLanguageValue, fromOptions),
        to: getInitialLanguage(activeToLanguageValue, toOptions),
    });

    const fromLanguage = selectedLanguages.from;
    const toLanguage = selectedLanguages.to;

    const fromLanguageOptions = parseLanguageList(locale, fromOptions);
    const toLanguageOptions = parseLanguageList(locale, toOptions);

    const changeFromLanguageHandler = (newValue) => {
        setSelectedLanguages({ from: newValue, to: selectedLanguages.to });

        const newFromLanguage = newValue?.value ?? FILTER.LANGUAGES_PAIR.DEFAULT;

        const activeFiltersToApply = { ...(newActiveFilters || activeFilters) };
        activeFiltersToApply[FILTER.LANGUAGES_PAIR.ID] = [newFromLanguage, toLanguage.value];

        setNewActiveFilters(activeFiltersToApply);
    };
    const changeToLanguageHandler = (newValue) => {
        setSelectedLanguages({ from: selectedLanguages.from, to: newValue });

        const newToLanguage = newValue?.value ?? FILTER.LANGUAGES_PAIR.DEFAULT;

        const activeFiltersToApply = { ...(newActiveFilters || activeFilters) };
        activeFiltersToApply[FILTER.LANGUAGES_PAIR.ID] = [fromLanguage.value, newToLanguage];

        setNewActiveFilters(activeFiltersToApply);
    };

    const applyTranslationFilter = () => {
        const activeFiltersToApply = { ...(newActiveFilters || activeFilters) };

        const fromOptionId = fromLanguage ? fromLanguage.value : FILTER.LANGUAGES_PAIR.DEFAULT;
        const toOptionId = toLanguage ? toLanguage.value : FILTER.LANGUAGES_PAIR.DEFAULT;

        activeFiltersToApply[FILTER.LANGUAGES_PAIR.ID] = [fromOptionId, toOptionId];

        invokeApplyEvent(applyEvent, activeFiltersToApply, Triggers.FILTER_COMPONENT_CLICK);
    };

    const clearAll = () => {
        const activeFiltersToApply = { ...(newActiveFilters || activeFilters) };

        activeFiltersToApply[FILTER.LANGUAGES_PAIR.ID] = null;

        invokeApplyEvent(applyEvent, activeFiltersToApply, Triggers.FILTER_COMPONENT_CLICK);

        setSelectedLanguages({ from: '', to: '' });
    };

    clearAllEvent.push(() => setSelectedLanguages({ from: '', to: '' }));

    if (isEmpty(fromOptions) || isEmpty(toOptions)) {
        return null;
    }

    const isSelfActiveTrigger = activeTrigger === Triggers.FILTER_COMPONENT_CLICK;
    const displayCTA = (fromLanguage || toLanguage) && isSelfActiveTrigger;

    return (
        <div className={styles.container}>
            <div className={styles.titleContainer}>
                <FilterIcon size={16} color={grey_1200} />
                <span className="m-l-8">{languagesFilter.alias}</span>
            </div>
            <span className={styles.subtitle}>
                <I18n k="search_perseus.filter.languages_pair.from" />
            </span>
            <ComboBox
                value={fromLanguage}
                onChange={changeFromLanguageHandler}
                options={fromLanguageOptions}
                NoOptionsMessage={NoOptions}
                isMulti={false}
                placeholder={<I18n k="search_perseus.filter.languages_pair.placeholder" />}
            />
            <span className={styles.subtitle}>
                <I18n k="search_perseus.filter.languages_pair.to" />
            </span>
            <ComboBox
                value={toLanguage}
                onChange={changeToLanguageHandler}
                options={toLanguageOptions}
                NoOptionsMessage={NoOptions}
                isMulti={false}
                placeholder={<I18n k="search_perseus.filter.languages_pair.placeholder" />}
            />
            {displayCTA && (
                <div className={styles.ctaSection}>
                    <span className={classnames(styles.clearAll, 'tbody-6 co-text-dark m-l-4')} onClick={clearAll}>
                        <I18n k="search_perseus.general.clear_all" />
                    </span>
                    <Button className={styles.applyButton} color="black" onClick={applyTranslationFilter}>
                        <I18n k="search_perseus.general.apply" />
                    </Button>
                </div>
            )}
        </div>
    );
};

TranslationFilter.propTypes = {
    filters: array,
};

export default TranslationFilter;
