import xss from 'xss';

const sanitizeSeo = (seoJson) =>
    xss(JSON.stringify(seoJson), {
        whiteList: [],
        stripIgnoreTag: true,
        stripIgnoreTagBody: ['a', 'script'],
    });

export default sanitizeSeo;
