import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { translate } from '@fiverr-private/i18n-react';
import { Radio } from '@fiverr-private/fit';
import { FILTER, PriceConverter } from '@fiverr-private/listing_lib';
import { Input, InputGroup, InputPrefix } from '@fiverr-private/forms';
import { Stack } from '@fiverr-private/layout_components';
import { onRangeChange, selectCustomOnFocus } from './PriceRange.utils';
import {
    CUSTOM_BUDGET_VALUE,
    DOMAttributes,
    MAX_GIG_PRICE_RANGE,
    MIN_GIG_PRICE,
    MIN_GIG_PRICE_RANGE,
    RANGE_KEYS,
} from './PriceRange.constants';

interface PriceRangeProps {
    minInputRef: React.RefObject<HTMLInputElement>;
    currencySymbol: string;
    currency: { code: string };
    budgetValue: { min: number; max: number };
    setBudgetValue: (value: { min: number; max: number }) => void;
    isCustomBucketSelected: boolean;
    hasPriceBuckets: boolean;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    setIsValidFilter: (value: boolean) => void;
    disableMinPrice: boolean;
}

export const PriceRange: React.FC<PriceRangeProps> = ({
    minInputRef,
    currencySymbol,
    currency,
    budgetValue,
    setBudgetValue,
    isCustomBucketSelected,
    hasPriceBuckets,
    onChange,
    setIsValidFilter,
    disableMinPrice,
}) => {
    const [isMaxValueValid, setIsMaxValueValid] = useState(true);
    const customRadioRef = useRef<any>(null);
    const { min, max } = budgetValue || {};
    const priceConverter = new PriceConverter(currency);
    const minGigPrice = Math.ceil(priceConverter.convert(MIN_GIG_PRICE));
    const minGigPriceTemplate = `${currencySymbol + minGigPrice}`;

    const minPriceLabel = translate('search_perseus.filter_builder.selected.price_range.min');
    const maxPriceLabel = translate('search_perseus.filter_builder.selected.price_range.max');
    const upToLabel = translate('search_perseus.filter_builder.selected.price_range.up_to');
    const customPriceLabel = translate('search_perseus.filters.global.price_buckets.custom');
    const placeholderMin = translate('search_perseus.general.any') as string;
    const placeholderMax = translate('search_perseus.filter_builder.selected.price_range.enter_budget_title') as string;
    const validationAlertText = translate('search_perseus.filter_builder.selected.price_range.services_start', {
        params: { price: minGigPriceTemplate },
    });

    useEffect(() => {
        if (!isCustomBucketSelected) {
            setIsMaxValueValid(true);
        }
    }, [isCustomBucketSelected]);

    const validateMaxPriceValue = ({ target: { value } }) => {
        const checkMaxValue = !value || minGigPrice <= value;

        setIsMaxValueValid(checkMaxValue);
        setIsValidFilter(checkMaxValue);
    };

    const handleMaxChange = ({ target }) => {
        onRangeChange(RANGE_KEYS.MAX, target.value, budgetValue, setBudgetValue);

        if (!isMaxValueValid) {
            validateMaxPriceValue({ target });
        }
    };

    const handleMinChange = ({ target: { value } }) => {
        onRangeChange(RANGE_KEYS.MIN, value, budgetValue, setBudgetValue);
    };

    return (
        <div
            className={classnames('price-range-filter', {
                'radio-list': hasPriceBuckets,
            })}
        >
            {hasPriceBuckets && (
                <div className="radio-item-wrapper">
                    <Radio
                        ref={customRadioRef}
                        value={CUSTOM_BUDGET_VALUE}
                        name={CUSTOM_BUDGET_VALUE}
                        checked={isCustomBucketSelected}
                        className={classnames('price-bucket-button m-b-12 radio-item', {
                            selected: isCustomBucketSelected,
                        })}
                        onChange={onChange}
                    >
                        <div className="inner-radio">{customPriceLabel}</div>
                    </Radio>
                </div>
            )}
            <div className="price-range-filter-inputs p-b-16" onClick={() => customRadioRef.current?.click()}>
                {!disableMinPrice && (
                    <div className="input-wrapper">
                        <label>{minPriceLabel}</label>
                        <Stack as="div" direction="row" alignItems="center" gap="2">
                            <InputGroup>
                                <InputPrefix>{currencySymbol}</InputPrefix>
                                <Input
                                    className="min"
                                    type="number"
                                    name={FILTER.GIG_PRICE_RANGE.ID}
                                    placeholder={placeholderMin}
                                    onFocus={() => selectCustomOnFocus(budgetValue, setBudgetValue)}
                                    min={MIN_GIG_PRICE_RANGE}
                                    max={MAX_GIG_PRICE_RANGE}
                                    value={min && min !== MIN_GIG_PRICE_RANGE ? min : ''}
                                    ref={minInputRef}
                                    onChange={handleMinChange}
                                    {...DOMAttributes}
                                />
                            </InputGroup>
                        </Stack>
                    </div>
                )}
                <div className="input-wrapper">
                    <label>{disableMinPrice ? upToLabel : maxPriceLabel}</label>
                    <Stack as="div" direction="row" alignItems="center" gap="2">
                        <InputGroup>
                            <InputPrefix>{currencySymbol}</InputPrefix>
                            <Input
                                className={classnames('max', {
                                    'danger-border': !isMaxValueValid,
                                })}
                                type="number"
                                id={`${FILTER.GIG_PRICE_RANGE.ID}_${RANGE_KEYS.MAX}`}
                                name={FILTER.GIG_PRICE_RANGE.ID}
                                placeholder={disableMinPrice ? '' : placeholderMax}
                                onFocus={() => selectCustomOnFocus(budgetValue, setBudgetValue)}
                                min={MIN_GIG_PRICE_RANGE}
                                max={MAX_GIG_PRICE_RANGE}
                                value={max && max !== MAX_GIG_PRICE_RANGE ? max : ''}
                                onBlur={validateMaxPriceValue}
                                onChange={handleMaxChange}
                                {...DOMAttributes}
                            />
                        </InputGroup>
                    </Stack>
                </div>
            </div>
            {!isMaxValueValid && <p className="validation-alert tbody-6">{validationAlertText}</p>}
        </div>
    );
};
