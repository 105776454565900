import React, { useContext } from 'react';
import { FILTER, isRolesListings } from '@fiverr-private/listing_lib';
import { filterType } from '../../../../../../../types';
import { AppContext } from '../../../../../../context/listingsContext';
import Toggle, { TogglePropTypes } from '../toggle';

const ProToggle = ({ filter, ...rest }) => {
    const { flowName } = useContext(AppContext);
    if (!filter) {
        return null;
    }
    const keySuffix = isRolesListings(flowName) ? 'proRoleFlow' : 'pro';

    return (
        <Toggle
            selectedValue={FILTER.PRO.SELECTED_VALUE}
            title={`search_perseus.filters.global.${keySuffix}.alias`}
            text={`search_perseus.filters.global.${keySuffix}.text`}
            {...filter}
            {...rest}
        />
    );
};

ProToggle.propTypes = {
    filter: filterType,
    ...TogglePropTypes,
};

export default ProToggle;
