const { omit, findKey } = require('lodash');
const {
    CATEGORY_TYPE,
    ACTIVE_FILTER_SEPARATORS,
    FILTER,
    SELECTORS,
    removeEmptyEntries,
} = require('@fiverr-private/listing_lib');
const { QUERY_PARAMS_FILTER_FUNCTIONS } = require('../constants');
const { staticUrl, staticSearchUrl } = require('../url');
const { CATEGORY_FILTER_SOURCE, UNKNOWN_ALIAS, TOP_GROUPING_MAP, TOP_GROUPING } = require('../../config/filters');
const { splitArray } = require('../index');

const additionalFilterParsers = {
    [FILTER.GIG_PRICE_RANGE.ID]: (id, val) => {
        if (!val) {
            return val;
        }

        const [min, max] = val.split(ACTIVE_FILTER_SEPARATORS.ARRAY_VALUE);
        return [Number(min), Number(max)];
    },
    [FILTER.LANGUAGES_PAIR.ID]: (id, val) => {
        const [fromValue, toValue] = val.split(ACTIVE_FILTER_SEPARATORS.PAIR_VALUE);
        return [fromValue, toValue];
    },
};

const utils = (module.exports = {
    constructOptionObject({ alias = UNKNOWN_ALIAS, description, id, count, tag }, filterValue, filterId = '') {
        const filterIdConfig = FILTER[filterId.toUpperCase()];

        const optionObject = {
            alias,
            description,
            id,
            count,
            selected: !!(filterValue && filterValue.includes(id)),
            tag,
        };

        if (filterIdConfig) {
            const groupByConfig = filterIdConfig?.GROUP;

            if (groupByConfig) {
                const groupValues = Object.values(groupByConfig.SORTED_VALUES).flat();

                if (groupValues.includes(id)) {
                    optionObject.group = groupByConfig.ID;
                }
            }
        }

        return optionObject;
    },

    constructPairOption({ id, count, activeValue, existingOption, alias }) {
        const sumCount = existingOption ? existingOption.count + count : count;

        return utils.constructOptionObject({ id, count: sumCount, alias }, activeValue);
    },

    getKeyByValues(object, value) {
        return findKey(object, (values) => values.includes(value));
    },

    sortByCountDescending(a, b) {
        return b.count - a.count;
    },

    sortByOrderAscending(a, b) {
        return a.order - b.order;
    },

    sortByAliasAlphabetical(a, b) {
        return a.alias.localeCompare(b.alias);
    },

    sortByFirstView(firstView) {
        return (a, b) => utils.getKeyByValues(firstView, a.id) - utils.getKeyByValues(firstView, b.id);
    },

    optionValidation({ customFilters }, filterId, optionId) {
        return !!(customFilters[filterId] && customFilters[filterId][optionId]);
    },

    filterValidation({ customFilters }, filterId, filtersToExclude, allowedNested) {
        if (filtersToExclude.includes(filterId) && allowedNested.length !== 0) {
            return false;
        }
        return !!customFilters[filterId];
    },

    constructResultObject({ config }, { ID, SELECTOR, FILTER_GROUP_ORDER, DISPLAY_TYPE }, additionalParams = {}) {
        const { order, display_type } = config[ID] || {};

        return {
            id: ID,
            order: FILTER_GROUP_ORDER || order,
            display_type: DISPLAY_TYPE || display_type,
            selector: SELECTOR,
            topGrouping: TOP_GROUPING_MAP[ID] || TOP_GROUPING.SERVICE,
            ...additionalParams,
        };
    },

    linkData(slugs, ref) {
        const url = staticUrl(slugs);

        const queryParams = {
            source: CATEGORY_FILTER_SOURCE,
            ...(ref && { ref }),
        };

        return removeEmptyEntries({
            url,
            params: queryParams,
        });
    },

    constructSubCategoryAllObject(alias, slugs, count = 0) {
        return {
            alias,
            selected: false,
            count,
        };
    },

    constructSearchSubCategoryOptionObject(alias, { categoryIds, allSubCategoriesCount }) {
        const { subCategoryId } = categoryIds;

        return {
            count: allSubCategoriesCount,
            alias,
            selected: subCategoryId === undefined,
            headAsLink: !!subCategoryId,
            params: {
                search_in: 'everywhere',
                sub_category: '',
            },
            url: staticSearchUrl(),
        };
    },

    constructSubCategoryObject(count = 0, slugs, { categoryIds, subCategoryData }) {
        return {
            count,
            alias: subCategoryData.name,
            selected: !categoryIds.nestedSubCategoryId,
        };
    },

    constructNestedSubCategoryTileLink(nscId, count, src, slugs, { categoryIds, translationsService, ref }) {
        const { subCategoryId, nestedSubCategoryId } = categoryIds;

        return {
            id: nscId,
            count,
            src,
            alias: translationsService.categories.nscName(subCategoryId, nscId),
            selected: nestedSubCategoryId === nscId,
            ...utils.linkData(
                { ...slugs, nestedSubCategory: translationsService.categories.nscSlug(subCategoryId, nscId) },
                ref
            ),
        };
    },

    constructSubCategoryOptionObject(scId, count, { categoryIds, translationsService }) {
        const { subCategoryId, nestedSubCategoryId } = categoryIds;

        return {
            count,
            alias: translationsService.categories.scName(scId),
            description: translationsService.categories.scSubtitle(scId),
            selected: subCategoryId === scId && !nestedSubCategoryId,
            type: CATEGORY_TYPE.SUB_CATEGORY,
            params: {
                search_in: CATEGORY_TYPE.CATEGORY,
                sub_category: scId,
            },
            url: staticSearchUrl(),
        };
    },

    constructNestedSubCategoryOptionObject(scId, nscId, count, { translationsService, categoryIds }) {
        const { subCategoryId, nestedSubCategoryId } = categoryIds;

        return {
            count,
            alias: translationsService.categories.nscTitle(nscId, scId),
            selected: nestedSubCategoryId === nscId && subCategoryId === scId,
            type: CATEGORY_TYPE.NESTED_SUB_CATEGORY,
            params: {
                search_in: CATEGORY_TYPE.CATEGORY,
                sub_category: scId,
                nested_sub_category: nscId,
            },
            url: staticSearchUrl(),
        };
    },

    parseQueryParamsFilters(queryParameters) {
        return Object.entries(QUERY_PARAMS_FILTER_FUNCTIONS).reduce((prev, [filterName, filterHandler]) => {
            const filterResult = filterHandler(queryParameters);

            return {
                ...prev,
                ...(filterResult && { [filterName]: filterResult }),
            };
        }, {});
    },

    parseActiveFilters(ref) {
        ref = ref || '';
        const filters = ref.split(ACTIVE_FILTER_SEPARATORS.PARAMS);
        const parsedActiveFilters = removeEmptyEntries(
            filters.reduce((activeFilters, filter) => {
                const [filterName, filterValue] = filter.split(ACTIVE_FILTER_SEPARATORS.PARAM_VALUE);
                const filterParsers = additionalFilterParsers[filterName]
                    ? additionalFilterParsers[filterName]
                    : _parseActiveFilterValue;
                activeFilters[filterName] = filterParsers(filterName, filterValue);

                return activeFilters;
            }, {})
        );

        const priceRangeFilterIsActive = parsedActiveFilters[FILTER.GIG_PRICE_RANGE.ID];

        const priceBucketsFilterIsActive = parsedActiveFilters[FILTER.PRICE_BUCKETS.ID];

        if (priceRangeFilterIsActive && priceBucketsFilterIsActive) {
            delete parsedActiveFilters[FILTER.PRICE_BUCKETS.ID];
        }

        return parsedActiveFilters;
    },

    getFilterSelectedOption({ id, selector, options = [] }) {
        return options.reduce((acc, { id: value, alias, selected }) => {
            selected && acc.push({ id, value, alias, selector });
            return acc;
        }, []);
    },

    modifyActiveFilters(activeFilters, currentFilters) {
        let modifiedActiveFilters = activeFilters;

        if (currentFilters && currentFilters[FILTER.GIG_PRICE_RANGE.ID]) {
            modifiedActiveFilters = omit(activeFilters, FILTER.PRICE_BUCKETS.ID);
        }

        if (currentFilters && currentFilters[FILTER.PRICE_BUCKETS.ID]) {
            modifiedActiveFilters = omit(activeFilters, FILTER.GIG_PRICE_RANGE.ID);
        }

        return modifiedActiveFilters;
    },

    sortOptionsByFirstView(options, firstView, sortRestBy) {
        const firstViewConfigFilterIds = Object.values(firstView).flat();
        const [filtered, rest] = splitArray(options, ({ id }) => firstViewConfigFilterIds.includes(id));
        const sortedFirstView = filtered.sort(utils.sortByFirstView(firstView));

        return sortedFirstView.concat(rest.sort(sortRestBy));
    },

    getSellerLocationConfig(countryCode) {
        return {
            ...FILTER.SELLER_LOCATION.FIRST_VIEW,
            1: [countryCode],
        };
    },
});

const _parseActiveFilterValue = (filterId, value) =>
    SELECTORS.isMulti(filterId) ? multiSelectFilterValue(value) : _stripSingleValue(value);
const _stripSingleValue = (value) => (Array.isArray(value) ? value[0] : value);
const multiSelectFilterValue = (value) => (value ? value.split(ACTIVE_FILTER_SEPARATORS.ARRAY_VALUE) : []);
