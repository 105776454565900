import React from 'react';
import classNames from 'classnames';
import FilterWithCount from '../index';

export const ComboBoxOptionWithCount = ({ innerProps, data, isFocused, isDisabled }) => (
    <div
        className={classNames('orca-select-option', {
            focused: isFocused,
            disabled: isDisabled,
        })}
        {...innerProps}
    >
        <FilterWithCount aliasClassName="tbody-5" alias={data.label} count={data.count} />
    </div>
);
