import { ListingAttributes } from '@fiverr-private/listing_types';
import { OrchestratorMachineState } from '../../types';

export const setListingAttributesPayload = (
    state: OrchestratorMachineState,
    listingAttributesPayload: ListingAttributes
): OrchestratorMachineState => ({
    ...state,
    context: {
        ...state.context,
        listingAttributes: listingAttributesPayload,
    },
});
