import React from 'react';
import SellerBadge from '../../../SellerBadge';
import { useExpertCardContext } from '../../../../contexts';
import propTypes from '../types';
import SellerRating from '../SellerRating';
import JobTitle from '../JobTitle';
import SellerName from '../SellerName';
import { UserAvatar } from '../UserAvatar';

import '../index.scss';

/**
 * A component that describes all the basic seller details for mobile device only.
 */
export const MobileSellerDetails = ({ isSellerOnline, isCardHovered, source, showJobTitle, onSellerDetailsClick }) => {
    const { seller } = useExpertCardContext();
    const { jobTitle, badges, sellerLevel } = seller;

    return (
        <div className="m-b-16">
            <div className="listings-seller-details-component mobile-device p-b-16">
                <UserAvatar source={source} onClick={onSellerDetailsClick} />
                <div className="seller-details-container">
                    <div className="flex flex-items-center seller-responsive-grid-row">
                        <div className="flex flex-items-center online-circle-and-seller-name-container">
                            {isSellerOnline && <div className="m-r-12 online-circle" />}
                            <SellerName
                                isCardHovered={isCardHovered}
                                source={source}
                                onClick={onSellerDetailsClick}
                                className="m-r-8"
                            />
                        </div>
                        <SellerBadge badges={badges} sellerLevel={sellerLevel} />
                    </div>
                    <SellerRating />
                </div>
            </div>
            {showJobTitle && <JobTitle jobTitle={jobTitle} size="sm" numRows={2} />}
        </div>
    );
};

MobileSellerDetails.propTypes = propTypes;
