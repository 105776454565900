import React from 'react';
import classNames from 'classnames';
import { Container } from '@fiverr-private/layout_components';
import { Link, Typography } from '@fiverr-private/typography';
import { activePage, paginationContainer } from './PaginationNumber.ve.css';

interface PaginationNumberProps {
    page: number;
    isActive: boolean;
    url: string;
}

export const PaginationNumber = ({ page, isActive, url }: PaginationNumberProps) => (
    <Link decoration="none" {...(!isActive && { href: url })}>
        <Container
            display="flex"
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classNames(paginationContainer, { [activePage]: isActive })}
        >
            <Container
                width="42px"
                height="42px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                cursor="pointer"
            >
                <Typography textAlign="center" fontWeight="semibold" size="b_md">
                    {page}
                </Typography>
            </Container>
        </Container>
    </Link>
);
