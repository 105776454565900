const TAG_TYPES = {
    NEW: 'new',
};

export const TagMapping = {
    [TAG_TYPES.NEW]: {
        color: 'pink',
        theme: 'hollow',
        text: 'new',
    },
};
