import React from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import { InfoOutlineIcon } from '@fiverr-private/icons';

export const InactiveGigMessage = () => (
    <div className="inactive-gig">
        <div className="inactive-gig-info-icon">
            <InfoOutlineIcon color="currcentColor" size={18} />
        </div>
        <p className="inactive-text inactive-text-long">
            <I18n k="listings.expert_card.inactive.service_unavailable" />
        </p>
        <p className="inactive-text inactive-text-short">
            <I18n k="listings.expert_card.inactive.service_unavailable_short" />
        </p>
    </div>
);
