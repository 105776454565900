import type { CategoryTree, FlowName } from '@fiverr-private/listing_types';
import { translate } from '@fiverr-private/i18n-react';

const buildGigCategoriesFlowPathname = (categoryIds?: Partial<CategoryTree>) => {
    if (categoryIds) {
        const { categoryId, subCategoryId, nestedSubCategoryId } = categoryIds;
        const categoryCachedSlug = translate(`categories.category_${categoryId}.cached_slug`);
        const subCategoryCachedSlug = translate(`sub_categories.sub_category_${subCategoryId}.cached_slug`);
        const nestedSubCategorySlug =
            nestedSubCategoryId &&
            translate(`sub_categories.sub_category_${subCategoryId}.nested_sub_cat_${nestedSubCategoryId}.cached_slug`);

        return nestedSubCategorySlug
            ? `/categories/${categoryCachedSlug}/${subCategoryCachedSlug}/${nestedSubCategorySlug}`
            : `/categories/${categoryCachedSlug}/${subCategoryCachedSlug}`;
    }

    throw new Error('CategoryIds are required for GigCategoriesFlow.');
};

const buildGigSearchFlowPathname = () => '/search/gigs';

const buildRoleSearchFlowPathname = () => '/search/gigs';

const buildGigBusinessSearchFlowPathname = () => {
    throw new Error('FlowType url builder not yet implemented.');
};

const buildGigBusinessCategoriesFlowPathname = () => {
    throw new Error('FlowType url builder not yet implemented.');
};

const buildGigProfessionSearchFlowPathname = () => '/search/gigs';

const FLOW_TO_PATHNAME_BUILDER_MAPPER: Record<FlowName, (categoryIds?: Partial<CategoryTree>) => string> = {
    gigCategoriesFlow: buildGigCategoriesFlowPathname,
    gigSearchFlow: buildGigSearchFlowPathname,
    roleSearchFlow: buildRoleSearchFlowPathname,
    gigBusinessSearchFlow: buildGigBusinessSearchFlowPathname,
    gigBusinessCategoriesFlow: buildGigBusinessCategoriesFlowPathname,
    gigProfessionSearchFlow: buildGigProfessionSearchFlowPathname,
};

export const buildURLPathname = (flowName: FlowName, categoryIds?: Partial<CategoryTree>) => {
    const pathnameBuilder = FLOW_TO_PATHNAME_BUILDER_MAPPER[flowName];

    if (!pathnameBuilder) {
        throw new Error(`FlowType url builder not yet implemented for ${flowName}.`);
    }

    return pathnameBuilder(categoryIds);
};
