import React from 'react';
import { func } from 'prop-types';
import { Icon } from '@fiverr-private/fit';
import { l_times } from '@fiverr-private/fit/icons';

const CloseButton = ({ close }) => (
    <Icon className="icon btn-close" color="#b5b6ba" size={14} onClick={() => close()}>
        {l_times}
    </Icon>
);

CloseButton.propTypes = {
    close: func,
};

export default CloseButton;
