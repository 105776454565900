const { EXPERIMENT_TYPE } = require('@fiverr-private/experiments_package');
const { RequestContext } = require('@fiverr-private/perseus');
const { isActive } = require('@fiverr-private/rollout_js');
const { EXPERIMENTS, ROLLOUTS } = require('@fiverr-private/listing_experiments');
const { inRollout } = require('./utils');

const isEligibleForAllocation = async () => {
    const {
        userGuid,
        experience: { isBusiness },
        isTouch,
    } = RequestContext;

    return !isBusiness && !isTouch && (await isActive(ROLLOUTS.HAWKS_SORT_BY_PRICE_POST_FREEZE, userGuid));
};

module.exports = {
    id: EXPERIMENTS.HAWKS_SORT_BY_PRICE_POST_FREEZE,
    experimentType: EXPERIMENT_TYPE.GUEST,
    numOfGroups: 2,
    shouldAllocate: isEligibleForAllocation,
    inRollout,
};
