import React from 'react';
import { StatusToast } from '@fiverr-private/orca';
import { I18n } from '@fiverr-private/fiverr_context';
import { OPTIONS } from './constants';

const raiseToast = ({ textKey = '', options = OPTIONS, theme }) =>
    StatusToast.raise({
        ...options,
        message: <I18n k={textKey} />,
        theme,
    });

export const raiseErrorToast = (textKey) => {
    raiseToast({ textKey, theme: StatusToast.THEMES.ERROR });
};
