import { Flow, FlowName, ListingAttributes, RequestMethod } from '@fiverr-private/listing_types';

export abstract class BasicFlow implements Flow {
    flowName: FlowName;

    protected constructor(flowName: FlowName) {
        this.flowName = flowName;
    }

    // Fetch configuration
    abstract timeout?: number;
    abstract metricRouteKey: string;
    abstract method: RequestMethod;

    // Validation
    abstract validateRequest: (listingAttributes: ListingAttributes) => void;

    // The url parts of the service for fetching listing items
    abstract servicePath: string;
    abstract pageSize: number;
    abstract serviceQueryParams: (listingAttributes: ListingAttributes) => string;

    // Additional data fetching and formatting
    abstract dataEnrichment: () => Promise<unknown>[];
    abstract formatResult: () => void;
}
