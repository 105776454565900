import React from 'react';
import { node } from 'prop-types';
import classNames from 'classnames';
import './index.scss';

export const BANNER_TYPE = {
    DEFAULT: 1,
    CENTERED: 2,
};

export const DEFAULT_BANNER_ROW = 1;

const getBannerProps = ({ bannerRow, bannerType }) => {
    const style = { gridRow: `${bannerRow} / span 1` };
    let className = '';

    switch (bannerType) {
        case BANNER_TYPE.DEFAULT: {
            className = 'default-banner';
            break;
        }

        case BANNER_TYPE.CENTERED: {
            className = 'centered-banner';
            break;
        }

        default: {
            break;
        }
    }

    className = `banner-wrapper ${className}`;

    return { style, className };
};

export const BannerWrapper =
    (children) =>
    // eslint-disable-next-line react/prop-types, react/display-name
    ({ bannerRow = DEFAULT_BANNER_ROW, bannerType = BANNER_TYPE.DEFAULT, className } = {}) => {
        const bannerProps = getBannerProps({ bannerRow, bannerType });

        return (
            <div style={bannerProps.style} className={classNames(bannerProps.className, className)}>
                {children}
            </div>
        );
    };

BannerWrapper.propTypes = {
    children: node.isRequired,
};

export default BannerWrapper;
