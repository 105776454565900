import { env } from '@fiverr-private/futile';
import { localStorage } from '@fiverr-private/futile/lib/storage/localStorage';
import { LASTLY_APPLIED_FILTERS_KEY } from '@fiverr-private/listing_lib';

export const clearLastlyAppliedFilters = () => {
    if (!env.browser) {
        return;
    }

    localStorage.remove(LASTLY_APPLIED_FILTERS_KEY);
};
