import { translate, hasTranslation } from '@fiverr-private/i18n-react';
import { metricsService } from '@fiverr-private/listing_lib';
import { ExposedFilterPropsMapper } from '../types';

export const metadataFiltersPropsMapper: ExposedFilterPropsMapper = (context) => {
    const { appFilters: { exposedFilters, subCategory: { id: scId } } = {} } = context;
    const { metadata_filters: metadataFilters = [] } = exposedFilters || {};
    const filter = metadataFilters[0];

    let title = '';

    if (hasTranslation(`search_perseus.filter_tiles.${scId}.${filter.id}`)) {
        title = translate(`search_perseus.filter_tiles.${scId}.${filter.id}`);
    } else {
        title = translate('search_perseus.side_filters.metadata_filters.titles.fallback');
        metricsService.count(`side_filters.missing_md_filter_title.${scId}.${filter.id}`);
    }

    return { ...filter, title };
};
