const defaultHeaderHeight = 122;

export const getHeaderHeight = ({ isClient = false }) => {
    if (!isClient) {
        return defaultHeaderHeight;
    }

    // TODO: Use an alternative method to get Fiverr's header height.
    const headerRef = document?.querySelector('#__ZONE__header');
    const headerHeight = headerRef?.clientHeight ?? defaultHeaderHeight;

    return headerHeight;
};
