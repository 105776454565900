import { getContext } from '@fiverr-private/fiverr_context';
import { FILTER } from '@fiverr-private/listing_lib';
import { getFilterById, getOptionById } from '../util';

export const MIN_TOP_RATED_RESULT_COUNT = 30;

const TOP_RATED_SELLER_OPT_ID = 'top_rated_seller';

export const getLocalSellerFilter = (filters) => {
    const { countryCode } = getContext();
    const sellerLocationFilter = getFilterById(filters, FILTER.SELLER_LOCATION.ID);
    const locationOption = getOptionById(sellerLocationFilter, countryCode);
    if (locationOption && locationOption.count > 0) {
        return { ...sellerLocationFilter, options: [locationOption], selectedValue: [countryCode] };
    }

    return null;
};

export const getTopRatedSellerFilter = (filters) => {
    const sellerLevelFilter = getFilterById(filters, FILTER.SELLER_LEVEL.ID);
    const topRatedOption = getOptionById(sellerLevelFilter, TOP_RATED_SELLER_OPT_ID);
    if (topRatedOption && topRatedOption.count >= MIN_TOP_RATED_RESULT_COUNT) {
        return { ...sellerLevelFilter, options: [topRatedOption], selectedValue: [TOP_RATED_SELLER_OPT_ID] };
    }

    return null;
};
