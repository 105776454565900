import { getContext } from '@fiverr-private/fiverr_context';
import { BI_EVENT_GROUPS, BI_PAGE_NAMES } from '../../../../../../../../shared/services/BIEvents/constants';
import { bqEnrichment } from '../../../../../../../../shared/services/BIEvents/utils';
import { PLATFORMS } from '../../../../../../../../shared/types/platforms';

export const EVENT_TYPE = 'filter_is_shown';
export const createBqEventData = ({ filterId, position }) => {
    const { pageCtxId, userGuid, userId, isTouch } = getContext();
    return bqEnrichment({
        pageCtxId,
        group: BI_EVENT_GROUPS.USER_IMPRESSION,
        type: EVENT_TYPE,
        page: {
            name: BI_PAGE_NAMES.LISTINGS,
            element: {
                position,
                metadata: filterId,
            },
        },
        payload: {
            userId,
            userGuid,
            platform: isTouch ? PLATFORMS.MOBILE : PLATFORMS.WEB,
        },
    });
};
