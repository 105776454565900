import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from '@fiverr-private/button';
import { I18n } from '@fiverr-private/i18n-react';
import { getOpenExpertPageLinkTarget } from '../../utils';
import { clickOnExpertCardEvent } from '../../../../utils/biEvents/events';
import { ELEMENT_META_DATA_NAMES, ELEMENT_NAMES } from '../../../../utils/biEvents/constants';
import { useExpertCardContext } from '../../../../contexts';

const SeeProfileButton = ({ source, size, isCta }) => {
    const { cardImpressionId, freelancerUrl, seller, isAgencyCard } = useExpertCardContext();

    const translateKey = isAgencyCard ? 'listings.card_actions.see_agency' : 'listings.card_actions.see_profile';

    return (
        <Button
            className={classNames('card-cta-button', { 'is-cta': isCta })}
            intent={isCta ? 'primary' : 'secondary'}
            variant="outline"
            size={size}
            target={getOpenExpertPageLinkTarget()}
            href={freelancerUrl}
            onClick={() =>
                clickOnExpertCardEvent({
                    pageName: source,
                    elementName: ELEMENT_NAMES.VIEW_EXPERT,
                    impressionId: cardImpressionId,
                    elementMetaData: ELEMENT_META_DATA_NAMES.BUTTON,
                    seller,
                })
            }
        >
            <I18n k={translateKey} />
        </Button>
    );
};

SeeProfileButton.propTypes = {
    source: PropTypes.string,
    size: PropTypes.string,
    isCta: PropTypes.bool,
};

export default SeeProfileButton;
