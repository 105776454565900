import React from 'react';
import { FILTERS_LOCATION } from '@fiverr-private/listing_lib';
import { filterType } from '../../../../../../../types';
import Toggle, { TogglePropTypes } from '../toggle';

const topRatedSellerKey = 'search_perseus.filters.global.top_rated_seller';

const TopRatedSellerToggle = ({ filter, ...rest }) => {
    if (!filter) {
        return null;
    }

    return (
        <Toggle
            title={`${topRatedSellerKey}.title`}
            position={FILTERS_LOCATION.FLOATING_TOP_RIGHT}
            {...filter}
            {...rest}
        />
    );
};

TopRatedSellerToggle.propTypes = {
    filter: filterType,
    ...TogglePropTypes,
};

export default TopRatedSellerToggle;
