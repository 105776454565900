import { getPageNameForMixPanel } from '../../utils';
import { sendClickEventToBQ } from '../../BigQuery';
import { sendClickEventToMixPanel } from '../../Mixpanel';
import { EVENT_TYPES, MIXPANEL_EVENT_NAMES } from '../../constants';

const clickOnLoadMore = ({ pageName } = {}) => {
    sendClickEventToBQ({ type: EVENT_TYPES.CLICK_SHOW_MORE, pageName });

    sendClickEventToMixPanel({
        eventName: MIXPANEL_EVENT_NAMES.CLICKED_ON_SHOW_MORE_EXPERTS,
        ...getPageNameForMixPanel(pageName),
    });
};

export default clickOnLoadMore;
