import React, { Component } from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import AutoComplete from '../../../../filter/AdvancedSearch/Autocomplete';
import { getPairSelected, objectValues } from '../../../../../../utils';
import { sortByCountDescending } from '../../../../../../utils/filter_builder/_utils';

import './index.scss';

class MultiPairsSelect extends Component {
    constructor(props) {
        super(props);

        this.state = getPairSelected({ options: props.items });

        this.onItemSelect = this.onItemSelect.bind(this);
        this.getValues = this.getValues.bind(this);
        this.syncValues = this.syncValues.bind(this);
    }

    onItemSelect(change) {
        this.setState(change);
    }

    getValues() {
        return { [this.props.id]: objectValues(this.state) };
    }

    syncValues() {
        const { items } = this.props;

        this.setState(getPairSelected({ options: items }));
    }

    render() {
        const {
            items: { fromOptions, toOptions },
        } = this.props;

        return (
            <div className="multi-pairs-select">
                <div>
                    <label>
                        <I18n k="search_perseus.filter.from" />
                    </label>
                    <AutoComplete
                        id="fromValue"
                        placeholder={<I18n k="search_perseus.general.any" />}
                        optionSort={sortByCountDescending}
                        options={fromOptions}
                        onChange={(value) => this.onItemSelect(value)}
                    />
                </div>
                <div>
                    <label>
                        <I18n k="search_perseus.filter.to" />
                    </label>
                    <AutoComplete
                        id="toValue"
                        placeholder={<I18n k="search_perseus.general.any" />}
                        options={toOptions}
                        optionSort={sortByCountDescending}
                        onChange={(value) => this.onItemSelect(value)}
                    />
                </div>
            </div>
        );
    }
}

export default MultiPairsSelect;
