import { get } from 'lodash';
import { RequestContext } from '@fiverr-private/perseus';
import { InitialProps } from '@fiverr-private/listing_types';

export const isEligibleForAllocation = () => {
    const {
        initialProps,
        experience: { isBusiness },
    } = RequestContext;
    const { subCategoryData, userData, hourlyRateSubCategories = [] } = initialProps as InitialProps;

    const subCategoryId = Number(get(subCategoryData, 'id'));
    const isSeller = get(userData, 'isSeller');
    const applicableHourlyRateSubCategoryId = hourlyRateSubCategories.includes(subCategoryId);
    const shouldShowHourlyRateBanners = applicableHourlyRateSubCategoryId && !isBusiness && !isSeller;

    const shouldAllocate = shouldShowHourlyRateBanners;

    RequestContext.addInitialProps({
        shouldShowHourlyRateBanners,
    });

    return shouldAllocate;
};
