import { ASSET_TYPES } from '../../shared/constants';

export const MEDIA_COMPONENT_MAPPER = {
    [ASSET_TYPES.VIDEO]: 'video',
    [ASSET_TYPES.AUDIO]: 'audio',
};

export const PLAYER_TOGGLE_PLAYING_EVENT = 'toggle_playing';

export const TOGGLE_PLAYING_TYPE = {
    PLAY: 'play',
    PAUSE: 'pause',
};
