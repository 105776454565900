import React from 'react';
import { CheckSolidIcon } from '@fiverr-private/icons';
import { I18n } from '@fiverr-private/i18n-react';
import { Tooltip } from '@fiverr-private/fit';

const T_PREFIX = 'listings.delivered_asset';

export const DeliveredAssetTag = () => (
    <div className="asset-delivered-tag flex flex-center">
        <Tooltip content={<I18n k={`${T_PREFIX}.tooltip.message`} />} delay={300}>
            <div className="delivery-icon-wrapper">
                <CheckSolidIcon size={14} color="white" />
            </div>
        </Tooltip>
    </div>
);
