import React from 'react';
import './index.scss';
import { object } from 'prop-types';
import { I18n } from '@fiverr-private/fiverr_context';
import { useImpressionItem } from '@fiverr-private/impressionable';
import { BI_CONSTS } from '@fiverr-private/listing_lib';
import { createSourceInfo } from '../../../../../../shared/utils/bi';
import ExplanationVideo from '../../../../../../shared/components/Video/ExplanationVideo';
import { BI_EVENTS_ENTITY, BI_PAGE_NAMES } from '../../../../../../shared/services/BIEvents/constants';
import { BANNER_WRAPPER_STYLE, BI_EVENTS } from '../constants';

export const MobileWebHeroBanner = ({ video = {} }) => {
    const [ref] = useImpressionItem({
        enrichment: {
            type: BI_EVENTS.BANNER_IS_SHOWN,
            action: {
                type: BI_EVENTS.BANNER_IMPRESSION,
            },
            group: BI_EVENTS.BANNER_IMPRESSION,
            entity: BI_EVENTS_ENTITY.CLIENT,
            page: {
                name: BI_PAGE_NAMES.LISTINGS,
                element: {
                    name: BI_EVENTS.BANNER_NAME,
                    type: BI_EVENTS.ELEMENT_TYPE,
                },
            },
        },
    });

    const sourceInfo = createSourceInfo({
        videoName: video.name,
        mixpanelPageName: BI_CONSTS.MIXPANEL_PAGE_NAME,
        bigQueryPageName: BI_CONSTS.BIGQUERY_PAGE_NAME,
    });

    return (
        <div className="mw-hero-banner-wrapper" ref={ref} style={BANNER_WRAPPER_STYLE}>
            <div className="mw-hero-banner">
                <div className="mw-banner-content">
                    <h3 className="title">{<I18n k="search_perseus.mobile_web_banner.title" />}</h3>
                    <p className="subtitle">{<I18n k="search_perseus.mobile_web_banner.subtitle" />}</p>
                    <ExplanationVideo
                        className="hero-banner-video"
                        videoTitle={<I18n k={video.titleKey} />}
                        video={video}
                        sourceInfo={sourceInfo}
                    />
                </div>
            </div>
        </div>
    );
};

MobileWebHeroBanner.propTypes = {
    video: object,
};
