import React from 'react';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import { bool, func, number, shape, string } from 'prop-types';
import { DIRECTION } from '../../DynamicCarousel/constants';
import Arrow from './Arrow';

import './index.scss';

const Arrows = ({ canScrollLeft = true, canScrollRight = true, navigationRef = {}, iconSize, className }) => {
    const scrollLeft = () => {
        if (!isEmpty(navigationRef.current)) {
            navigationRef.current.scrollLeft();
        }
    };

    const scrollRight = () => {
        if (!isEmpty(navigationRef.current)) {
            navigationRef.current.scrollRight();
        }
    };

    const wrapperClass = classNames('carousel-arrows-wrapper', className);

    return (
        <div className={wrapperClass}>
            <Arrow direction={DIRECTION.LEFT} onClick={scrollLeft} disabled={!canScrollLeft} iconSize={iconSize} />
            <Arrow direction={DIRECTION.RIGHT} onClick={scrollRight} disabled={!canScrollRight} iconSize={iconSize} />
        </div>
    );
};

Arrows.propTypes = {
    canScrollLeft: bool,
    canScrollRight: bool,
    navigationRef: shape({
        current: shape({
            scrollLeft: func,
            scrollRight: func,
        }),
    }),
    iconSize: number,
    className: string,
};

export default Arrows;
