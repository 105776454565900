import { AxiosError } from 'axios';
import { stats } from '@fiverr-private/obs';

const metric = stats.prefix(`services.search_perseus`);
/**
 * Extract the status code from the error
 * @param error
 * @returns {string} status code
 */
export const getStatusCode = (error: AxiosError) => {
    const errorCode = error?.code?.toString()?.toLowerCase();
    const statusCode = error?.response?.status;

    return statusCode || errorCode;
};

export const measureOnSuccess = (routeId, startTime, status) => {
    metric.time(`http.${routeId}.total_request_time`, Date.now() - startTime);
    metric.count(`http.${routeId}.request`);
    metric.count(`http.${routeId}.result.status.${status}`);
};

export const measureOnFailure = (routeId, startTime, status) => {
    metric.time(`http.${routeId}.failure_total_request_time`, Date.now() - startTime);
    metric.count(`http.${routeId}.request`);

    if (status) {
        metric.count(`http.${routeId}.result.status.${status}`);
    }
};
