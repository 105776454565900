export const setCookie = ({ name, value, expirationDays = 2, domain }) => {
    const date = new Date();
    date.setTime(date.getTime() + expirationDays * 24 * 60 * 60 * 1000);
    const expireData = date.toUTCString();
    let cookie = `${name}=${value}; expires=${expireData}; path=/`;

    if (domain) {
        cookie = `${cookie}; domain=${domain}`;
    }

    document.cookie = cookie;
};

export const expireCookie = (name) => {
    document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};
