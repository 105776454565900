import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { findLastIndex, noop } from 'lodash';
import Types from '../../types';

import styles from './index.module.scss';

const SHOW_LESS_MAX_OPTIONS_COUNT = 4;

const ShowMoreWrapper = ({
    children,
    filter,
    i18n,
    showLessMaxOptions = SHOW_LESS_MAX_OPTIONS_COUNT,
    onMoreClick = noop,
}) => {
    const { options = [], children: scOptions } = filter;

    const filterOptions = scOptions ?? options;

    const isHiddenOptionSelected = (fields) =>
        findLastIndex(fields, ({ selected }) => selected) > showLessMaxOptions - 1;

    const [showMore, setShowMore] = useState(isHiddenOptionSelected(filterOptions));

    const showMoreClicked = () => {
        setShowMore(!showMore);
        onMoreClick();
    };

    const shouldDisplayShowMoreButton = filterOptions.length > showLessMaxOptions;

    const optionsToDisplay = showMore ? filterOptions : filterOptions.slice(0, showLessMaxOptions);
    const hiddenOptions = filterOptions.length - optionsToDisplay.length;

    return (
        <>
            {children({ optionsToDisplay })}
            {shouldDisplayShowMoreButton && (
                <div className="p-b-8">
                    <span onClick={showMoreClicked} className={classNames(styles.showMoreLessButton, 'tbody-6')}>
                        {showMore
                            ? i18n.t('search_perseus.general.less')
                            : i18n.t('search_perseus.general.more', { count: hiddenOptions })}
                    </span>
                </div>
            )}
        </>
    );
};

ShowMoreWrapper.propTypes = {
    children: PropTypes.func.isRequired,
    filter: Types.filter.isRequired,
    i18n: PropTypes.object.isRequired,
    showLessMaxOptions: PropTypes.number,
};

export default ShowMoreWrapper;
