import { bool, number, string, shape, oneOfType, arrayOf } from 'prop-types';
import { HIGHLIGHTS_DATA_KEYS } from '../../shared/constants';

const commonIndustry = shape({
    name: string,
    clientsCount: number,
});

export const teamMemberType = shape({
    /**
     * Team member username
     */
    username: string,
    /**
     * Team member fullName
     */
    fullName: string,
    /**
     * Team member avatar image
     */
    src: string,
});

const savedByType = shape({
    /**
     * The id of the user that saved the seller
     */
    id: number,
    /**
     * The name of the user that saved the seller
     */
    fullName: string,
});

export const highlightsType = shape({
    workedWithYourTeam: bool,
    workedWithClients: bool,
    buyersKeepComingBack: bool,
    highlyResponsive: bool,
    savedByTeamMember: bool,
});

export const highlightsDataType = shape({
    /**
     * An array of team members
     */
    [HIGHLIGHTS_DATA_KEYS.TEAM_MEMBERS]: arrayOf(teamMemberType),
    /**
     * The industry of the highlight
     */
    [HIGHLIGHTS_DATA_KEYS.COMMON_INDUSTRY]: oneOfType([null, commonIndustry]),
    /**
     * The vacation end date
     * */
    [HIGHLIGHTS_DATA_KEYS.VACATION_END_DATE]: number,
    /**
     * Data about the user that saved the seller in list
     */
    [HIGHLIGHTS_DATA_KEYS.SAVED_BY]: savedByType,
    /**
     * The language code the seller speaks
     */
    [HIGHLIGHTS_DATA_KEYS.SELLER_LANGUAGE_CODE]: string,
});

export default {
    /**
     * An object with all the possible highlights, the selected highlight value is true, the rest are false
     */
    highlights: highlightsType,
    /**
     * An object with all the extra data that is needed for the selected highlight
     */
    highlightsData: highlightsDataType,
};
