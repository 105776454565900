import { sendClickEventToBQ } from '../../BigQuery';
import { COMPONENT_NAMES, EVENT_TYPES } from '../../constants';

export const hoverOnCustomerProtectionEvent = ({ seller, source }) => {
    sendClickEventToBQ({
        type: EVENT_TYPES.HOVER_ON_CUSTOMER_PROTECTION,
        pageName: source,
        action: 'hover',
        componentName: COMPONENT_NAMES.EXPERT_CARD,
        seller,
    });
};
