import { getContext } from '@fiverr-private/fiverr_context';
import { useIsClient } from '@fiverr-private/hooks';
import { EXPERIMENTS } from '@fiverr-private/listing_experiments';
import { bqEvent } from '../../../../shared/services/BIEvents/utils';
import { BI_EVENT_GROUPS, BI_PAGE_NAMES } from '../../../../shared/services/BIEvents/constants';
import { allocateToClientExperiment } from '../../clientExperiments/clientUtils';
import { useAppContext } from '../../components/context/listingsContext';

export const useInResponsiveCarouselsExperiment = (): boolean => {
    const { pageCtxId } = getContext();
    const { clientExperimentsGroups, activeExperiments } = useAppContext();
    const isClient = useIsClient();

    const owlsExperimentGroup = Number(activeExperiments[EXPERIMENTS.OWLS_MOBILE_WEB_CAROUSEL]);
    const inOwlsExperiment = owlsExperimentGroup > 0;

    if (inOwlsExperiment) {
        return owlsExperimentGroup > 1;
    }

    const isSmallScreen = isClient && window.innerWidth < 1160;

    const experimentGroup = Number(clientExperimentsGroups[EXPERIMENTS.HAWKS_RESPONSIVE_LISTINGS_CAROUSELS]);
    const shouldAllocate = experimentGroup > 0;
    const inExperiment = experimentGroup > 1;

    if (isSmallScreen && shouldAllocate) {
        bqEvent({
            type: 'listings_client_experiment_active',
            group: BI_EVENT_GROUPS.USER_ACTIONS,
            pageCtxId,
            page: {
                name: BI_PAGE_NAMES.LISTINGS,
            },
            payload: {
                ab_test: [
                    {
                        experiment: EXPERIMENTS.HAWKS_RESPONSIVE_LISTINGS_CAROUSELS,
                        group: experimentGroup,
                    },
                ],
            },
        });

        allocateToClientExperiment(EXPERIMENTS.HAWKS_RESPONSIVE_LISTINGS_CAROUSELS, clientExperimentsGroups);
    }

    return inExperiment;
};
