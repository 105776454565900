import { number, objectOf, string } from 'prop-types';

const searchTitleDataType = objectOf({
    modifiedQueryString: string,
    translatedQuery: string,
    nonTranslatedQuery: string,
    searchInsteadUrl: string,
});

export const resultsCounterTypes = {
    resultsCount: number.isRequired,
    searchTitleData: searchTitleDataType,
    subcategoryDisplayName: string,
};
