import React from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import { ArrowTopRightIcon } from '@fiverr-private/visuals';
import { Heading, Link, Typography } from '@fiverr-private/typography';
import { Container, Grid, GridItem } from '@fiverr-private/layout_components';

interface FaqsProps {
    title: string;
    answersLink?: string;
    faqs: {
        question: string;
        answer: string;
    }[];
}
export const Faqs = ({ title, faqs, answersLink }: FaqsProps) => (
    <Container
        borderTopWidth="sm"
        borderColor="grey_500"
        borderStyle="solid"
        as="section"
        paddingBottom="8"
        paddingTop="12"
    >
        <Heading
            as="h2"
            size={{ default: 'h_sm', md: 'h_xl' }}
            marginBottom={{ default: '6', md: '8' }}
            textAlign="center"
        >
            <I18n k={'search_perseus.seo_sub_category.faqs.title'} params={{ sub_category_title: title }} />
        </Heading>
        <Grid rowGap="6" columnGap={{ default: '6', md: '12' }} templateColumns={{ default: '100%', md: '50%_50%' }}>
            {faqs.map((content, index) => (
                <GridItem key={index}>
                    <Typography as="h3" size="b_md" marginBottom="2" color="grey_1100" fontWeight="semibold">
                        {content.question}
                    </Typography>
                    <Typography color="grey_1000" size={{ default: 'b_sm', md: 'b_md' }}>
                        {content.answer}
                    </Typography>
                </GridItem>
            ))}
            {!!answersLink && (
                <GridItem columnStart={{ md: '2' }} justifyContent={{ default: 'flexStart', md: 'spaceBetween' }}>
                    <Container
                        display="flex"
                        gap="2"
                        alignItems="center"
                        justifyContent={{ default: 'spaceBetween', md: 'flexStart' }}
                    >
                        <Link target="_blank" href={answersLink} fontSize="b_md" fontWeight="semibold">
                            <I18n
                                k={'search_perseus.seo_sub_category.faqs.cta'}
                                params={{ sub_category_title: title }}
                            />
                        </Link>
                        <ArrowTopRightIcon color="black" />
                    </Container>
                </GridItem>
            )}
        </Grid>
    </Container>
);
