export const EXPOSE_CATEGORIES = ['16', '49', '50'];
export const LOGO_DESIGN_ID = '49';

export const buildServiceOptionTitle = (subCategory) =>
    subCategory && EXPOSE_CATEGORIES.includes(subCategory.id)
        ? _renderTitle(subCategory.id, subCategory.name)
        : { serviceOptionKey: 'search_perseus.filter.service_options' };

const _renderTitle = (id, name) =>
    id === LOGO_DESIGN_ID
        ? { serviceOptionKey: 'search_perseus.filter.logo_service' }
        : { serviceOptionKey: 'search_perseus.filter.service', service: name };
