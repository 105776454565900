import { FILTER } from '@fiverr-private/listing_lib';
import { ActiveSideFiltersType } from '@fiverr-private/listing_types';

export const ID_TO_CHANGE_OBJECT: Record<string, (activeFilters: ActiveSideFiltersType) => ActiveSideFiltersType> = {
    languages_pair_from: (activeFilters) => {
        const to = activeFilters[FILTER.LANGUAGES_PAIR.ID]?.[1];

        return {
            [FILTER.LANGUAGES_PAIR.ID]:
                to === FILTER.LANGUAGES_PAIR.DEFAULT ? [] : `${FILTER.LANGUAGES_PAIR.DEFAULT}-${to}`,
        };
    },
    languages_pair_to: (activeFilters) => {
        const from = activeFilters[FILTER.LANGUAGES_PAIR.ID]?.[0];

        return {
            [FILTER.LANGUAGES_PAIR.ID]:
                from === FILTER.LANGUAGES_PAIR.DEFAULT ? [] : `${from}-${FILTER.LANGUAGES_PAIR.DEFAULT}`,
        };
    },
    [FILTER.SUB_CATEGORIES.ID]: () => ({}),
};
