import { useEffect } from 'react';
import { metricsService } from '@fiverr-private/listing_lib';

const useCountFlowView = (flow) => {
    useEffect(() => {
        metricsService.countFlowView(flow);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

export default useCountFlowView;
