import React from 'react';
import { translate } from '@fiverr-private/i18n-react';
import { PriceConverter } from '@fiverr-private/listing_lib';
import { PriceBucket } from './PriceBuckets';

const PriceBucketText = ({ text }: { text: string }) => (
    <span className="tbody-6 m-l-8 co-text-medium text-normal">{text}</span>
);

const getPriceFormatter = (priceBucket: PriceBucket, currency: { code: string }) => {
    const priceConverter = new PriceConverter(currency);

    const { min, max } = priceBucket;

    const minLocalized = priceConverter.getLocalizedPrice(min * 100, { noFractionDigits: true });
    const maxLocalized = priceConverter.getLocalizedPrice(max * 100, { noFractionDigits: true });

    return [
        translate('search_perseus.filters.global.price_buckets.value', {
            params: { price: maxLocalized },
            templates: {
                bucket_text: (text) => <PriceBucketText text={text} />,
            },
        }),
        translate('search_perseus.filters.global.price_buckets.mid_range', {
            params: { min_price: minLocalized, max_price: maxLocalized },
            templates: {
                bucket_text: (text) => <PriceBucketText text={text} />,
            },
        }),
        translate('search_perseus.filters.global.price_buckets.high_end', {
            params: { price: minLocalized },
            templates: {
                bucket_text: (text) => <PriceBucketText text={text} />,
            },
        }),
    ];
};

export const formatPrice = (priceBucket, index, currency) => {
    const formattedMapper = getPriceFormatter(priceBucket, currency);
    return formattedMapper[index];
};
