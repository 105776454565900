import React, { useEffect } from 'react';
import { Stack } from '@fiverr-private/layout_components';
import { stats } from '@fiverr-private/obs';
import { ReasonForModification } from '@fiverr-private/listing_types';
import { HeaderTitle } from './components/HeaderTitle/HeaderTitle';
import { SearchOriginalQuery } from './components/SearchOriginalQuery/SearchOriginalQuery';
import { LocalizedQueryTranslationDescription } from './components/LocalizedQueryTranslationDescription/LocalizedQueryTranslationDescription';

export interface SearchHeaderProps {
    query?: string;
    modifiedQueryString?: string;
    searchInsteadUrl?: string;
    nonTranslatedQuery?: string;
    reasonForModification?: ReasonForModification;
}

export const SearchHeader: React.FC<SearchHeaderProps> = ({
    query,
    modifiedQueryString,
    searchInsteadUrl,
    nonTranslatedQuery,
    reasonForModification,
}) => {
    useEffect(() => {
        if (modifiedQueryString) {
            stats.count('services.search_perseus.client', 'search_flow.render.search_instead');
        }

        if (nonTranslatedQuery) {
            stats.count('services.search_perseus.client', 'search_flow.render.search_translated');
        }
    }, [modifiedQueryString, nonTranslatedQuery]);

    const displayQuery = modifiedQueryString || query;

    return (
        <Stack direction="column" gap="4">
            <HeaderTitle query={displayQuery} />
            <SearchOriginalQuery
                originalQuery={query}
                searchInsteadUrl={searchInsteadUrl}
                reasonForModification={reasonForModification}
            />
            <LocalizedQueryTranslationDescription nonTranslatedQuery={nonTranslatedQuery} query={query} />
        </Stack>
    );
};
