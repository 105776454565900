import React, { useCallback, useEffect, useRef, useState } from 'react';
import { isNil, noop } from 'lodash';
import classNames from 'classnames';
import { getContext } from '@fiverr-private/fiverr_context';
import { VolumeHighSolidIcon, VolumeOffSolidIcon } from '@fiverr-private/icons';
import { convertAssetThumbnail } from '../../utils';
import { ASSET_TYPES, AUDIO_DEFAULT_THUMBNAIL } from '../../shared/constants';
import InlineVideoProgress from './InlineVideoProgress';
import { MEDIA_COMPONENT_MAPPER, PLAYER_TOGGLE_PLAYING_EVENT, TOGGLE_PLAYING_TYPE } from './constants';
import MediaPlayerPropTypes from './types';
import { playMedia, calculateMediaProgress, onEnd } from './utils';

import './index.scss';

/**
 * Media player component for audio and video assets.
 */
const MediaPlayer = ({
    className,
    assetType,
    isParentHovered,
    isActive = true,
    attachment = {},
    lazyLoadThumbnail = false,
}) => {
    const isFirstRender = useRef(true);
    const playerContainerRef = useRef(null);
    const playerRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isMuted, setIsMuted] = useState(true);
    const [progress, setProgress] = useState(100);
    const { isTouch } = getContext();

    const handleTogglePlayingEvent = useCallback(({ detail: togglePlayingType }) => {
        setIsPlaying(togglePlayingType === TOGGLE_PLAYING_TYPE.PLAY);
    }, []);

    useEffect(() => {
        if (!playerContainerRef.current) {
            return;
        }

        const { current } = playerContainerRef;

        current.addEventListener(PLAYER_TOGGLE_PLAYING_EVENT, handleTogglePlayingEvent);

        return () => current.removeEventListener(PLAYER_TOGGLE_PLAYING_EVENT, handleTogglePlayingEvent);
    }, [playerContainerRef, handleTogglePlayingEvent]);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        if (!isActive && isPlaying) {
            pauseMediaHandler();
            return;
        }

        if (!isActive || isNil(isParentHovered)) {
            return;
        }

        if (isParentHovered) {
            playMediaHandler();
        } else {
            pauseMediaHandler();
        }
    }, [isParentHovered, isActive, isPlaying]);

    const isVideo = assetType === ASSET_TYPES.VIDEO;

    const Component = MEDIA_COMPONENT_MAPPER[assetType];
    const imageUrl = isVideo ? attachment.imageUrl : AUDIO_DEFAULT_THUMBNAIL;
    const thumbnail = convertAssetThumbnail(imageUrl, isTouch);

    if (!Component) {
        return null;
    }

    const playerContainerClassName = 'player-container';

    const playMediaHandler = () => {
        playMedia({
            playerRef,
            playerContainerClassName,
            isPlaying: false,
            setIsPlaying,
        });
    };

    const pauseMediaHandler = () => {
        playMedia({
            playerRef,
            playerContainerClassName,
            isPlaying: true,
            setIsPlaying,
        });
    };

    const toggleMuteHandler = (event) => {
        event.stopPropagation();
        setIsMuted(!isMuted);
    };

    return (
        <div
            className={classNames(
                playerContainerClassName,
                { 'is-playing': isPlaying, 'is-video': isVideo },
                className
            )}
            ref={playerContainerRef}
            onMouseEnter={isNil(isParentHovered) ? playMediaHandler : noop}
            onMouseLeave={isNil(isParentHovered) ? pauseMediaHandler : noop}
        >
            <Component
                onEnded={(event) => onEnd(event, setIsPlaying)}
                poster={thumbnail}
                onTimeUpdate={(event) => calculateMediaProgress(event, setProgress)}
                ref={playerRef}
                muted={isMuted}
                playsinline
            >
                {isPlaying && <source src={attachment.streamUrl} />}
            </Component>
            <img className="thumbnail" src={thumbnail} loading={lazyLoadThumbnail ? 'lazy' : 'eager'} />
            <span className="video-button-wrapper">
                <span className="video-button" />
                <InlineVideoProgress progress={progress} />
            </span>
            {isPlaying && (
                <div className="volume-button" onClick={toggleMuteHandler}>
                    {isMuted ? <VolumeOffSolidIcon color="white" /> : <VolumeHighSolidIcon color="white" />}
                </div>
            )}
        </div>
    );
};

MediaPlayer.propTypes = MediaPlayerPropTypes;

export default MediaPlayer;
