import { ActiveFilters } from '@fiverr-private/listing_types';
import { ACTIVE_FILTER_SEPARATORS } from '../../constants/filters/activeFilterSeparators';

const arraySeparators = {
    languages_pair: ACTIVE_FILTER_SEPARATORS.PAIR_VALUE,
};

const stringifyActiveFilterValue = (name: string, value?: string | string[] | number[]) => {
    const separator = arraySeparators[name] || ACTIVE_FILTER_SEPARATORS.ARRAY_VALUE;

    return Array.isArray(value) ? value.join(separator) : value;
};

export const activeFiltersToQueryString = (activeFilters?: ActiveFilters) =>
    Object.entries(activeFilters || {})
        .filter(([, filterValue]) => filterValue)
        .map(
            ([filterName, filterValue]) =>
                `${filterName}${ACTIVE_FILTER_SEPARATORS.PARAM_VALUE}${stringifyActiveFilterValue(
                    filterName,
                    filterValue
                )}`
        )
        .join(ACTIVE_FILTER_SEPARATORS.PARAMS);
