import React, { useContext } from 'react';
import { isEmpty } from 'lodash';
import { getContext } from '@fiverr-private/fiverr_context';
import Pagination from '../common/pagination/Pagination';
import { AppContext } from '../../context/listingsContext';
import { DynamicGigListings } from '../../common';
import { ListingsImpressionContainer } from '../../../../../shared/context/ListingsImpressionContainer/ListingsImpressionContainer';
import { ProjectManageBanner } from '../banner/ProjectManageBanner';
import { BannerMode } from '../banner/util';
import styles from './index.module.scss';

const BusinessGigsGridListing = () => {
    const {
        listings: [listings],
        pagination,
    } = useContext(AppContext);

    const { isMobile } = getContext();
    const { gigs } = listings;
    const noGigs = isEmpty(gigs);
    const showPagination = !noGigs && typeof window !== 'undefined' && !!window?.location;

    if (noGigs) {
        return null;
    }

    const renderBanner = () => {
        const { number_of_results, filters } = listings?.impressionEnrichment?.listings || {};
        const minGigsToShowBanner = 9;
        const rowPosition = 4;

        if (number_of_results <= minGigsToShowBanner) {
            return null;
        }
        return (
            <ProjectManageBanner
                resultsCount={number_of_results}
                hasActiveFilters={!isEmpty(filters)}
                position={rowPosition}
                bannerMode={BannerMode.FULL_CATALOG}
            />
        );
    };

    return (
        <div className={isMobile && 'm-t-16'}>
            <ListingsImpressionContainer>
                <DynamicGigListings
                    banners={renderBanner()}
                    gigCardListingsClassName={styles.gigListingsContainer}
                    listings={{ ...listings, gigs }}
                />
            </ListingsImpressionContainer>
            {showPagination && <Pagination pagination={pagination} />}
        </div>
    );
};

export default BusinessGigsGridListing;
