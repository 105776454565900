import React, { useRef, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { I18n, I18nProvider } from '@fiverr-private/i18n-react';
import { FILTER, PriceConverter } from '@fiverr-private/listing_lib';
import { PriceBuckets } from '@fiverr-private/listing_filters';
import { AppContext } from '../../../../context/listingsContext';
import '@fiverr/i18n/singleton';
import Input from '../../../../Input';
import { MIN_GIG_PRICE_RANGE, MAX_GIG_PRICE_RANGE } from '../../Filter/consts';
import { rangeValue } from '../../../floating_topbar/menus/content/budget/utils';
import {
    CUSTOM_BUDGET_VALUE,
    RANGE_KEYS,
    DOMAttributes,
    MIN_GIG_PRICE,
} from '../../../floating_topbar/menus/content/budget/constants';
import { HourlyRateBudgetBanner } from '../../../floating_topbar/menus/content/budget/banners/HourlyRateBudgetBanner';

import './styles.scss';

const parseRangeValue = ({ min, max }) => {
    const [minVal, maxVal] = [min, max].map((v) => rangeValue(v));
    return { min: minVal, max: maxVal };
};

const Range = ({ value, id, currencySymbol, onChange, priceBucketsValue = '', currency, setIsValidFilter }) => {
    const minInputRef = useRef(null);
    const [isMaxValueValid, setIsMaxValueValid] = useState(true);
    const { appFilters: { filters = [], disableMinPrice } = {} } = useContext(AppContext);
    const { options: priceBucketOptions = [] } = filters.find(({ id }) => id === FILTER.PRICE_BUCKETS.ID) || {};
    const hasPriceBuckets = priceBucketOptions.length > 0;

    const priceConverter = new PriceConverter(currency);
    const minGigPrice = Math.ceil(priceConverter.convert(MIN_GIG_PRICE));
    const minGigPriceTemplate = `${currencySymbol + minGigPrice}`;

    const mapValues = {
        min: value[0] || MIN_GIG_PRICE_RANGE,
        max: value[1] || MAX_GIG_PRICE_RANGE,
    };

    const formattedValue = { min: value[0], max: value[1] };

    const validateMaxPriceValue = ({ target: { value } }) => {
        const checkMaxValue = !value || minGigPrice <= value;

        setIsMaxValueValid(checkMaxValue);
        setIsValidFilter(checkMaxValue);
    };

    const onRangeChange = (key, val) => {
        if (Number.isNaN(val)) {
            return;
        }

        if (val < 0) {
            val = val * -1;
        }

        if (val > MAX_GIG_PRICE_RANGE) {
            val = MAX_GIG_PRICE_RANGE;
        }

        const change = { [key]: rangeValue(val) };
        const newVal = {
            ...mapValues,
            ...change,
        };

        onChange(id, [newVal.min, newVal.max]);
    };

    const handleMaxChange = ({ target }) => {
        onRangeChange(RANGE_KEYS.MAX, target.value);

        if (!isMaxValueValid) {
            validateMaxPriceValue({ target });
        }
    };

    const onPriceBucketsChange = ({ target: { value } }) => {
        validateMaxPriceValue({ target: { value: null } });
        onChange(FILTER.PRICE_BUCKETS.ID, value);
    };

    const { min, max } = parseRangeValue(mapValues);

    const selectCustomOnFocus = () => {
        if (!value[0] && !value[1]) {
            onChange(FILTER.PRICE_BUCKETS.ID, CUSTOM_BUDGET_VALUE);
        }
    };

    return (
        <div
            className={classNames('budget flex flex-col', {
                'with-price-buckets': hasPriceBuckets,
                'without-min-price': disableMinPrice,
            })}
        >
            {hasPriceBuckets && (
                <PriceBuckets
                    priceBucketOptions={priceBucketOptions}
                    onChange={onPriceBucketsChange}
                    budgetValue={priceBucketsValue || formattedValue}
                    minInputRef={minInputRef}
                    currency={currency}
                />
            )}
            <I18nProvider>
                {({ i18n }) => (
                    <div className="range">
                        {!disableMinPrice && (
                            <div className="label-wrapper">
                                <label>
                                    <I18n k="search_perseus.filter_builder.selected.price_range.min" />
                                </label>
                                <label>
                                    <I18n k="search_perseus.filter_builder.selected.price_range.max" />
                                </label>
                            </div>
                        )}
                        <div className="flex flex-center">
                            {!disableMinPrice && (
                                <>
                                    <Input
                                        type="number"
                                        name={id}
                                        ref={minInputRef}
                                        className="input min"
                                        onFocus={selectCustomOnFocus}
                                        onChange={(e) => onRangeChange(RANGE_KEYS.MIN, e.target.value)}
                                        symbol={currencySymbol}
                                        {...DOMAttributes}
                                        value={min && min !== MIN_GIG_PRICE_RANGE ? min : ''}
                                    />
                                    <span className="light m-l-8 m-r-8">-</span>
                                </>
                            )}
                            <Input
                                type="number"
                                name={id}
                                onFocus={selectCustomOnFocus}
                                className="input max"
                                inputContainerClassName={classNames({ 'danger-border': !isMaxValueValid })}
                                onChange={handleMaxChange}
                                onBlur={validateMaxPriceValue}
                                symbol={currencySymbol}
                                placeholder={i18n.t(
                                    'search_perseus.filter_builder.selected.price_range.enter_budget_title'
                                )}
                                {...DOMAttributes}
                                value={max && max !== MAX_GIG_PRICE_RANGE ? max : ''}
                            />
                        </div>
                        {!isMaxValueValid && (
                            <p className="validation-alert tbody-6 m-t-8">
                                {i18n.t('search_perseus.filter_builder.selected.price_range.services_start', {
                                    price: minGigPriceTemplate,
                                })}
                            </p>
                        )}
                    </div>
                )}
            </I18nProvider>
            <HourlyRateBudgetBanner className="m-t-8" maxWidth="100%" />
        </div>
    );
};

Range.propTypes = {
    id: PropTypes.string.isRequired,
    currencySymbol: PropTypes.string.isRequired,
    options: PropTypes.array,
    onChange: PropTypes.func,
    onClear: PropTypes.func,
    setIsValidFilter: PropTypes.func,
};

Range.defaultProps = {
    value: [0, 0], // min, max
    onChange: () => true,
};

export default Range;
