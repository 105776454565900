import { isEmpty } from 'lodash';
import { Pushkin } from '@fiverr-private/pushkin_js';
import { logger } from '@fiverr-private/obs';
import { globalEventBus } from '@fiverr-private/futile';
import { getContext } from '@fiverr-private/fiverr_context';
import { PushkinClient, RealtimeEventHandler, RealtimeEventHandlerMapping, IPushkinConfig } from '../types';
import { IPushkinLoggerConfig } from '../types/PushkinConfig';
import { isClient } from './isClient';
import { metric } from './metric';

type InitializationState = 'initializing' | 'initialized' | 'failed';

const INITIALIZATION_EVENT = 'pushkin_client_initialized';

class RealtimeListener {
    initializationState: InitializationState | null = null;
    pushkin: PushkinClient | null = null;
    loggerConfig?: IPushkinLoggerConfig;

    async init(pushkinConfig: IPushkinConfig): Promise<void> {
        const isClientSide = isClient();

        if (!isEmpty(this.pushkin) || this.initializationState === 'initializing' || !pushkinConfig || !isClientSide) {
            return;
        }

        try {
            this.initializationState = 'initializing';
            metric.count(`initializing`);

            this.loggerConfig = pushkinConfig?.loggerConfig;

            // Start measuring time for initialization
            const startTime = Date.now();
            this.pushkin = await Pushkin.connect(pushkinConfig);
            const elapsedTime = Date.now() - startTime;

            // Log the time it took to initialize
            metric.time(`initialization.time`, elapsedTime);

            this.initializationState = 'initialized';
            metric.count(`initialized`);

            globalEventBus.deferred(INITIALIZATION_EVENT, this.pushkin);
        } catch (error: any) {
            const { userId, url } = getContext();

            this.initializationState = 'failed';
            metric.count(`failed`);

            const logEnrichment = {
                description: 'Failed to connect to Pushkin',
                source: pushkinConfig?.loggerConfig?.domain || 'fiverr_neo',
                url,
                userId,
            };

            logger.error(error, logEnrichment);
        }
    }

    async subscribe(channelName: string, handlerMapping: RealtimeEventHandlerMapping): Promise<void> {
        if (!this.pushkin) {
            globalEventBus.on(INITIALIZATION_EVENT, () => {
                this.subscribe(channelName, handlerMapping);
            });

            return;
        }

        try {
            const channel = await this.pushkin.subscribe({
                channelName,
            });

            metric.count(`subscribe.success`);

            for (const [eventName, handler] of Object.entries<RealtimeEventHandler>(handlerMapping)) {
                channel.bind(eventName, handler);
            }
        } catch (error: any) {
            const { userId, url } = getContext();
            metric.count(`subscribe.failure`);

            const logEnrichment = {
                description: 'Failed to subscribe to Pushkin channel',
                source: this.loggerConfig?.domain || 'fiverr_neo',
                url,
                userId,
            };

            logger.error(error, logEnrichment);
        }
    }

    async unsubscribe(channelName: string): Promise<void> {
        if (!this.pushkin) {
            logger.warn('Pushkin client is not initialized');
            metric.count(`unsubscribe.failure`);

            return;
        }

        try {
            await this.pushkin.unsubscribe(channelName);
            metric.count(`unsubscribe.success`);
        } catch (error: any) {
            const { userId, url } = getContext();
            metric.count(`unsubscribe.failure`);

            const logEnrichment = {
                description: 'Failed to unsubscribe from Pushkin channel',
                source: this.loggerConfig?.domain || 'fiverr_neo',
                url,
                userId,
            };

            logger.error(error, logEnrichment);
        }
    }
}

export default new RealtimeListener();
