import { pick, isEmpty, isObject } from 'lodash';
import { getDisplayedNotableClients } from '@fiverr-private/seller_presence/src/utils/helpers/notableClients';
import { EXPERTISE_TYPE } from '../../../constants';
import { HIGHLIGHTS_DATA_KEYS, HIGHLIGHTS_ID_LIST, SELLER_LEVEL } from '../../../shared/constants';
import { PRIORITY } from '../../SellerBadge';
import { BADGES } from '../../SellerBadge/constants';
import { parseNotableClients } from '../utils';
import { MAX_DISPLAYED_NOTABLE_CLIENTS } from './constants';

export const getExpertiseType = (type) => {
    switch (type) {
        case EXPERTISE_TYPE.LEAF_CATEGORY_ID: {
            return 'EXPERTISE_TYPE_LEAF_CAT_ID';
        }

        case EXPERTISE_TYPE.SKILL: {
            return 'EXPERTISE_TYPE_SKILL';
        }

        default: {
            return 'EXPERTISE_TYPE_UNSPECIFIED';
        }
    }
};

export const getHighlights = (highlight, highlightsData) => {
    if (!highlight) {
        return [];
    }

    const activeHighlight = Object.keys(highlight).find((key) => highlight[key]);

    if (!activeHighlight) {
        return [];
    }

    const { type, data = {} } = getHighlightTypeAndData(activeHighlight, highlightsData);

    return [
        {
            type,
            parameters: Object.entries(data).map(([key, value]) => ({
                parameter_name: key,
                parameter_value: isObject(value) ? JSON.stringify(value) : value,
            })),
        },
    ];
};

const getHighlightTypeAndData = (type, highlightsData) => {
    switch (type) {
        case HIGHLIGHTS_ID_LIST.WORKED_WITH_YOUR_TEAM: {
            return {
                type: 'HIGHLIGHT_TYPE_WORKED_WITH_YOUR_TEAM',
                data: pick(highlightsData, HIGHLIGHTS_DATA_KEYS.TEAM_MEMBERS),
            };
        }

        case HIGHLIGHTS_ID_LIST.WORKED_WITH_CLIENTS_IN_INDUSTRY: {
            return {
                type: 'HIGHLIGHT_TYPE_WORKED_WITH_CLIENTS_IN_INDUSTRY',
                data: pick(highlightsData, HIGHLIGHTS_DATA_KEYS.COMMON_INDUSTRY),
            };
        }

        case HIGHLIGHTS_ID_LIST.SAVED_BY_TEAM_MEMBER: {
            return {
                type: 'HIGHLIGHT_TYPE_SAVED_BY_TEAM_MEMBER',
                data: pick(highlightsData, HIGHLIGHTS_DATA_KEYS.SAVED_BY),
            };
        }

        case HIGHLIGHTS_ID_LIST.BUYER_KEEP_COMING_BACK: {
            return { type: 'HIGHLIGHT_TYPE_BUYERS_KEEP_COMING_BACK' };
        }

        case HIGHLIGHTS_ID_LIST.HIGHLY_RESPONSIVE: {
            return { type: 'HIGHLIGHT_TYPE_HIGHLY_RESPONSIVE' };
        }

        case HIGHLIGHTS_ID_LIST.SELLER_SPEAKS: {
            return {
                type: 'HIGHLIGHT_TYPE_SELLER_SPEAKS',
                data: pick(highlightsData, HIGHLIGHTS_DATA_KEYS.SELLER_LANGUAGE_CODE),
            };
        }

        case HIGHLIGHTS_ID_LIST.ON_VACATION: {
            return {
                type: 'HIGHLIGHT_TYPE_UNAVAILABLE_UNTIL',
                data: pick(highlightsData, HIGHLIGHTS_DATA_KEYS.VACATION_END_DATE),
            };
        }

        case HIGHLIGHTS_ID_LIST.INACTIVE: {
            return { type: 'HIGHLIGHT_TYPE_NO_LONGER_ACTIVE' };
        }

        default: {
            return { type: 'HIGHLIGHT_TYPE_UNSPECIFIED' };
        }
    }
};

export const getLevel = (level) => {
    switch (level) {
        case SELLER_LEVEL.LEVEL_ONE: {
            return 'SELLER_LEVEL_ONE';
        }

        case SELLER_LEVEL.LEVEL_TWO: {
            return 'SELLER_LEVEL_TWO';
        }

        case SELLER_LEVEL.TOP_RATED_SELLER: {
            return 'SELLER_LEVEL_TOP_RATED';
        }

        case SELLER_LEVEL.NO_LEVEL:
        default: {
            return 'SELLER_LEVEL_UNSPECIFIED';
        }
    }
};

export const getNotableClients = (notableClients) => {
    if (isEmpty(notableClients)) {
        return [];
    }

    let displayedClients = getDisplayedNotableClients(parseNotableClients(notableClients));
    displayedClients = displayedClients.slice(0, MAX_DISPLAYED_NOTABLE_CLIENTS);
    const clientNames = displayedClients.map((client) => ({ name: client?.enriched_details?.name }));

    return clientNames;
};

export const getBadges = (badges = {}) => {
    const result = [];

    for (const badgeType of PRIORITY) {
        if (badges[badgeType]) {
            switch (badgeType) {
                case BADGES.CHOICE: {
                    result.push({ name: 'BADGE_FIVERR_CHOICE' });
                    return result;
                }

                case BADGES.PRO: {
                    result.push({ name: 'BADGE_PRO' });
                    return result;
                }

                default:
            }
        }
    }

    return result;
};
