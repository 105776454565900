const { RequestContext } = require('@fiverr-private/perseus');
const {
    ACTIVE_FILTER_SEPARATORS,
    activeFiltersToQueryString,
    removeEmptyEntries,
    CATEGORY_TYPE,
    FILTER,
    isRolesListings,
} = require('@fiverr-private/listing_lib');
const { GLOBAL_FILTERS, DISPLAY_TYPES, TOP_GROUPING } = require('../../config/filters');
const { FLOWS } = require('../constants');
const { objectValues } = require('../index');
const {
    getPricingFactorTranslationKey,
} = require('../../components/common/floating_topbar/menus/content/pricing_factor_dropdown/utils');
const { isInCaterpillarsToggleRemovalTestWithProRemoved } = require('../../experiments/onlineProToggleRemoval/utils');
const { shouldShowSideFilters } = require('../../experiments/sideFilters/util/shouldShowSideFilters');
const {
    sortByCountDescending,
    constructResultObject,
    constructOptionObject,
    constructSearchSubCategoryOptionObject,
    constructSubCategoryAllObject,
    constructSubCategoryObject,
    constructNestedSubCategoryTileLink,
    constructNestedSubCategoryOptionObject,
    constructSubCategoryOptionObject,
    optionValidation,
    filterValidation,
    constructPairOption,
    sortByAliasAlphabetical,
    sortOptionsByFirstView,
    getSellerLocationConfig,
} = require('./_utils');
const { getPriceBucketAlias } = require('./priceBuckets/utils');
const { createFilterOptions, getFilterLabel } = require('./professionTranslation/professionTranslation');

module.exports = {
    [FILTER.DELIVERY_TIME.ID]({ subCategoryData: { filters }, activeFilters, translationsService }) {
        const { delivery_time: deliveryTimeActive } = activeFilters;
        const currentFilter = FILTER.DELIVERY_TIME;

        const isSelected = (filter, id) => (!filter && !id ? true : !!(filter && filter === id));
        const constructOptionObject = ({ alias, id }, activeFilter) => ({
            alias,
            id,
            selected: isSelected(activeFilter, id),
        });

        const options = currentFilter.DEFAULT_IDS.map((id) => ({
            id,
            alias: translationsService.filters.value(FILTER.DELIVERY_TIME.ID, id),
        })).map((option) => constructOptionObject(option, deliveryTimeActive));
        const selected = !!deliveryTimeActive;

        return constructResultObject(filters, currentFilter, {
            ...currentFilter.DEFAULT_FILTER,
            display_type: currentFilter.DISPLAY_TYPE,
            selected,
            options,
            alias: translationsService.filters.name(currentFilter.ID),
        });
    },

    [FILTER.GIG_PRICE_RANGE.ID]({
        subCategoryData: { filters },
        activeFilters,
        currency: { symbol },
        translationsService,
    }) {
        const { gig_price_range: priceRangeActive = [] } = activeFilters;
        const currentFilter = FILTER.GIG_PRICE_RANGE;

        const [min, max] = priceRangeActive;
        const options = [
            {
                ...removeEmptyEntries({
                    min,
                    max,
                    selected: !!(min || max),
                    id: currentFilter.OPTION_ID,
                }),
            },
        ];

        const alias = translationsService.filters.name(currentFilter.ID);

        return constructResultObject(filters, currentFilter, {
            options,
            symbol,
            alias,
            display_type: currentFilter.DISPLAY_TYPE,
        });
    },

    [FILTER.PACKAGE_INCLUDES.ID](
        { subCategoryData: { filters }, activeFilters, dominateSubCategoryId, translationsService },
        facetValues
    ) {
        const { package_includes: packageIncludesActive } = activeFilters;
        const currentFilter = FILTER.PACKAGE_INCLUDES;

        const options = facetValues
            .filter(({ id }) => optionValidation(filters, currentFilter.ID, id))
            .map((facet) => ({
                ...facet,
                alias: translationsService.filters.pricingFactor(facet.id, dominateSubCategoryId),
            }))
            .map((option) => constructOptionObject(option, packageIncludesActive, FILTER.PACKAGE_INCLUDES.ID))
            .sort(sortByCountDescending);

        if (!options.length) {
            return undefined;
        }

        return constructResultObject(filters, currentFilter, {
            options,
            alias: translationsService.filters.name(currentFilter.ID),
        });
    },

    [FILTER.PRO.ID]({ subCategoryData: { filters }, activeFilters, translationsService }, facetValues) {
        const { pro: proActive } = activeFilters;

        const currentFilter = FILTER.PRO;
        const proGigs = facetValues.find((value) => value.id === true.toString());
        const count = proGigs ? proGigs.count : 0;

        const selected = [currentFilter.SELECTED_VALUE, currentFilter.SELECTED_VALUE_ONLY].includes(proActive);

        const options = [{ count, selected, id: currentFilter.ID }];

        return constructResultObject(filters, currentFilter, {
            options,
            display_type: FILTER.PRO.DISPLAY_TYPE,
            alias: translationsService.filters.name(currentFilter.ID),
        });
    },

    [FILTER.SELLER_LANGUAGE.ID](
        { subCategoryData: { filters }, activeFilters, translationsService, isBusiness, flowName },
        facetValues
    ) {
        const { seller_language: sellerLanguageActive } = activeFilters;
        const currentFilter = FILTER.SELLER_LANGUAGE;
        const filterId = isBusiness || isRolesListings(flowName) ? currentFilter.BUSINESS_ID : currentFilter.ID;

        const options = facetValues
            .filter(({ id }) => currentFilter.WHITELIST[id])
            .map((facet) => ({ ...facet, alias: translationsService.filters.value(currentFilter.ID, facet.id) }))
            .map((option) => constructOptionObject(option, sellerLanguageActive, FILTER.SELLER_LANGUAGE.ID));

        return constructResultObject(filters, currentFilter, {
            options,
            alias: translationsService.filters.name(filterId),
        });
    },

    [FILTER.IS_AGENCY.ID](
        { subCategoryData: { filters }, activeFilters, isBusiness, translationsService },
        facetValues
    ) {
        const { is_agency: sellerTypeActive } = activeFilters;
        const currentFilter = FILTER.IS_AGENCY;
        const agenciesCount = facetValues.find((value) => value.id === true.toString());

        const options = [
            {
                alias: translationsService.filters.value(currentFilter.ID, true.toString()),
                description: translationsService.filters.description(currentFilter.ID, true.toString()),
                id: 'true',
                count: agenciesCount ? agenciesCount.count : 0,
                selected: sellerTypeActive || sellerTypeActive === true.toString(),
                tag: currentFilter.TAGS[true.toString()],
            },
        ];

        return constructResultObject(filters, currentFilter, {
            options,
            alias: translationsService.filters.name(currentFilter.ID),
            ...(isBusiness && { topGrouping: TOP_GROUPING.TOGGLERS }),
        });
    },
    [FILTER.HAS_HOURLY.ID]({ subCategoryData: { filters }, activeFilters, translationsService }, facetValues) {
        const { has_hourly } = activeFilters;
        const currentFilter = FILTER.HAS_HOURLY;
        const hourlyCount = facetValues.find((value) => value.id === true.toString());

        const optionAlias = translationsService.filters.value(currentFilter.ID, true.toString());
        const filterAlias = translationsService.filters.name(currentFilter.ID);

        const options = [
            {
                alias: optionAlias,
                id: true.toString(),
                count: hourlyCount ? hourlyCount.count : 0,
                selected: has_hourly === true.toString(),
                tag: currentFilter.TAGS[true.toString()],
            },
        ];

        return constructResultObject(filters, currentFilter, {
            options,
            alias: filterAlias,
        });
    },

    [FILTER.SELLER_LEVEL.ID](
        {
            userData: { inShowOnlineToggleList = false } = {},
            subCategoryData: { filters },
            activeFilters,
            translationsService,
            isBusiness,
            flowName,
            isTouch,
            abTests,
        },
        facetValues
    ) {
        if (isInCaterpillarsToggleRemovalTestWithProRemoved(abTests, isBusiness, isTouch)) {
            return null;
        }

        const { seller_level: sellerLevelActive } = activeFilters;
        const currentFilter = {
            ...FILTER.SELLER_LEVEL,
            ...(!inShowOnlineToggleList && {
                FILTER_GROUP_ORDER: 1,
            }),
        };

        const filterId = isBusiness || isRolesListings(flowName) ? currentFilter.BUSINESS_ID : currentFilter.ID;

        const options = currentFilter.DEFAULT_IDS.map((defaultId) => {
            const matchingFacet = facetValues.find(({ id }) => id === defaultId) || {};

            return {
                id: defaultId,
                count: 0,
                alias: translationsService.filters.value(currentFilter.ID, defaultId),
                ...matchingFacet,
            };
        }).map((option) => constructOptionObject(option, sellerLevelActive, FILTER.SELLER_LEVEL.ID));

        return constructResultObject(filters, currentFilter, {
            options,
            alias: translationsService.filters.name(filterId),
        });
    },

    [FILTER.SELLER_TIER.ID](
        { subCategoryData: { filters }, activeFilters, translationsService, isBusiness, abTests, isTouch },
        facetValues
    ) {
        const inCaterpillarsToggleRemovalTestWithProRemoved = isInCaterpillarsToggleRemovalTestWithProRemoved(
            abTests,
            isBusiness,
            isTouch
        );

        if (!inCaterpillarsToggleRemovalTestWithProRemoved) {
            return null;
        }

        const { seller_tier: sellerTierActive } = activeFilters;

        const currentFilter = FILTER.SELLER_TIER;

        const options = currentFilter.DEFAULT_IDS.map((defaultId) => {
            const matchingFacet = facetValues.find(({ id }) => id === defaultId) || {};

            return {
                id: defaultId,
                count: 0,
                alias: translationsService.filters.value(FILTER.SELLER_TIER.ID, defaultId),
                ...matchingFacet,
            };
        }).map((option) => constructOptionObject(option, sellerTierActive, FILTER.SELLER_TIER.ID));

        return constructResultObject(filters, FILTER.SELLER_TIER, {
            options,
            alias: translationsService.filters.name(FILTER.SELLER_TIER.ID),
        });
    },

    [FILTER.SELLER_LOCATION.ID](
        { subCategoryData: { filters }, activeFilters, translationsService, isBusiness, flowName },
        facetValues
    ) {
        const { countryCode = '' } = RequestContext;

        const { seller_location: sellerLocationActive } = activeFilters;
        const currentFilter = FILTER.SELLER_LOCATION;
        const sellerLocationFirstView = getSellerLocationConfig(countryCode);

        const filterId = isBusiness || isRolesListings(flowName) ? currentFilter.BUSINESS_ID : currentFilter.ID;

        const options = facetValues
            .filter(({ id }) => currentFilter.WHITELIST[id])
            .map((facet) => ({ ...facet, alias: translationsService.filters.value(currentFilter.ID, facet.id) }))
            .map((option) => constructOptionObject(option, sellerLocationActive, currentFilter.ID));

        const sortedOptions = sortOptionsByFirstView(options, sellerLocationFirstView, sortByAliasAlphabetical);

        return constructResultObject(filters, currentFilter, {
            options: sortedOptions,
            alias: translationsService.filters.name(filterId),
        });
    },

    [FILTER.IS_SELLER_ONLINE.ID](
        {
            userData: { inShowOnlineToggleList = false } = {},
            subCategoryData: { filters },
            activeFilters,
            translationsService,
            isBusiness,
            flowName,
        },
        facetValues
    ) {
        const { is_seller_online: sellerOnlineActive } = activeFilters;

        const currentFilter = {
            ...FILTER.IS_SELLER_ONLINE,
            ...(!inShowOnlineToggleList && {
                DISPLAY_TYPE: DISPLAY_TYPES.CHECKBOX,
            }),
        };

        const filterId = isBusiness || isRolesListings(flowName) ? currentFilter.BUSINESS_ID : currentFilter.ID;
        const onlineSellers = facetValues.find((value) => value.id === true.toString());
        const options = [
            {
                count: onlineSellers ? onlineSellers.count : 0,
                selected: sellerOnlineActive || sellerOnlineActive === true.toString(),
                alias: translationsService.filters.value(currentFilter.ID, true.toString()),
                ...(!inShowOnlineToggleList && { id: 'true' }),
            },
        ];
        const hasSupply = !options[0].count;

        if (!inShowOnlineToggleList && hasSupply) {
            return null;
        }

        return constructResultObject(filters, currentFilter, {
            options,
            alias: translationsService.filters.name(filterId),
            ...(!inShowOnlineToggleList && { topGrouping: TOP_GROUPING.SELLER }),
        });
    },

    [FILTER.SERVICE_OFFERINGS.ID]({ subCategoryData: { filters }, activeFilters, translationsService }, facetValues) {
        const { offer_consultation: offerConsultation, subscription } = activeFilters;

        const serviceOfferingsActiveFilters = [];
        if (offerConsultation) {
            serviceOfferingsActiveFilters.push(FILTER.SERVICE_OFFERINGS.OFFER_CONSULTATION);
        }

        if (subscription) {
            serviceOfferingsActiveFilters.push(FILTER.SERVICE_OFFERINGS.SUBSCRIPTION);
        }

        const options = FILTER.SERVICE_OFFERINGS.DEFAULT_IDS.map((defaultId) => {
            const matchingFacet = facetValues.find(({ id }) => id === defaultId);

            if (!matchingFacet?.count) {
                return null;
            }

            return {
                id: defaultId,
                count: 0,
                alias: translationsService.filters.value(FILTER.SERVICE_OFFERINGS.ID, defaultId),
                ...matchingFacet,
            };
        })
            .filter((option) => !!option)
            .map((option) => constructOptionObject(option, serviceOfferingsActiveFilters, FILTER.SERVICE_OFFERINGS.ID));

        const hasSupply = !!options.length;
        if (!hasSupply) {
            return null;
        }

        return constructResultObject(filters, FILTER.SERVICE_OFFERINGS, {
            options,
            alias: translationsService.filters.name(FILTER.SERVICE_OFFERINGS.ID),
        });
    },

    [FILTER.PRICE_BUCKETS.ID]({
        subCategoryData: { filters },
        currency,
        translationsService,
        priceBucketsSkeleton = [],
        activeFilters,
        isBusiness,
        isTouch,
        abTests,
    }) {
        const { [FILTER.PRICE_BUCKETS.ID]: activePriceBucket } = activeFilters;
        const showSideFilters = shouldShowSideFilters(isBusiness, isTouch, abTests);

        const currentFilter = {
            ...FILTER.PRICE_BUCKETS,
            ...(showSideFilters && {
                DISPLAY_TYPE: DISPLAY_TYPES.PRICE_BUCKETS,
            }),
        };

        const options = priceBucketsSkeleton.map((option, index) => {
            const bucketTranslations = getPriceBucketAlias(option, currency, translationsService)[index];

            return {
                ...option,
                selected: option.id === activePriceBucket,
                alias: bucketTranslations.alias,
                activeFilterAlias: bucketTranslations.activeFilterAlias,
            };
        });

        return constructResultObject(filters, currentFilter, {
            options,
            alias: showSideFilters
                ? translationsService.general.translate('search_perseus.filters.global.budget.alias')
                : '',
        });
    },

    [FILTER.CUSTOM_FILTER.ID](
        {
            subCategoryData: { filters, allowed_nested = [] },
            activeFilters,
            translationsService,
            dominateSubCategoryId,
            categoryIds: { nestedSubCategoryId },
        },
        facetValues,
        filterId,
        { filterExcludes }
    ) {
        const currentFilter = FILTER.CUSTOM_FILTER;
        const filterIdConfig = FILTER[filterId.toUpperCase()];
        let sortedOptions = null;

        if (!filterValidation(filters, filterId, filterExcludes, allowed_nested)) {
            return;
        }

        const optionsObject = facetValues
            .filter(({ id }) => optionValidation(filters, filterId, id))
            .map((facet) => ({
                ...facet,
                alias: translationsService.filters.value(
                    filterId,
                    facet.id,
                    dominateSubCategoryId,
                    nestedSubCategoryId
                ),
            }))
            .map((option) => constructOptionObject(option, activeFilters[filterId], filterId));

        if (filterIdConfig?.FIRST_VIEW || filterIdConfig?.GROUP) {
            const orderMap = filterIdConfig.FIRST_VIEW || filterIdConfig.GROUP.SORTED_VALUES;
            sortedOptions = sortOptionsByFirstView(optionsObject, orderMap, sortByAliasAlphabetical);
        }

        const options = sortedOptions ? sortedOptions : optionsObject.sort(sortByCountDescending);

        return constructResultObject(
            filters,
            { ...currentFilter, ID: filterId },
            { options, alias: translationsService.filters.name(filterId, dominateSubCategoryId, nestedSubCategoryId) }
        );
    },

    [FILTER.LANGUAGES_PAIR.ID](
        { subCategoryData: { filters }, activeFilters, translationsService, dominateSubCategoryId },
        facetValues
    ) {
        const { languages_pair: languagesPairActive = FILTER.LANGUAGES_PAIR.INITIAL } = activeFilters;
        const currentFilter = FILTER.LANGUAGES_PAIR;
        const [fromValue, toValue] = languagesPairActive;
        const { fromOptions, toOptions } = facetValues.reduce(
            ({ fromOptions, toOptions }, facet) => {
                const { id, count } = facet;
                const [fromLanguage, toLanguage] = id.split(ACTIVE_FILTER_SEPARATORS.PAIR_VALUE);

                if (fromLanguage && toLanguage) {
                    fromOptions[fromLanguage] = constructPairOption({
                        id: fromLanguage,
                        count,
                        activeValue: fromValue,
                        filters,
                        existingOption: fromOptions[fromLanguage],
                        alias: translationsService.filters.value(
                            currentFilter.ID,
                            fromLanguage,
                            currentFilter.SUB_CATEGORY_ID
                        ),
                    });
                    toOptions[toLanguage] = constructPairOption({
                        id: toLanguage,
                        count,
                        activeValue: toValue,
                        filters,
                        existingOption: toOptions[toLanguage],
                        alias: translationsService.filters.value(
                            currentFilter.ID,
                            toLanguage,
                            currentFilter.SUB_CATEGORY_ID
                        ),
                    });
                }

                return { fromOptions, toOptions };
            },
            { fromOptions: {}, toOptions: {} }
        );

        return constructResultObject(filters, currentFilter, {
            options: {
                fromOptions: objectValues(fromOptions),
                toOptions: objectValues(toOptions),
            },
            alias: translationsService.filters.name(currentFilter.ID, dominateSubCategoryId),
        });
    },

    [FILTER.PRICING_FACTOR.ID]({ subCategoryData: { filters }, dominateSubCategoryId, pricingFactorSkeleton = {} }) {
        const currentFilter = FILTER.PRICING_FACTOR;
        const { locale } = RequestContext;

        const {
            options: skeletonOptions = [],
            default: defaultOption,
            selected: selectedOption,
            pricing_factor_key: pricingFactorKey,
        } = pricingFactorSkeleton;

        const options = skeletonOptions.map((option) => ({
            ...option,
            selected: option.id === selectedOption.id,
            alias: '',
        }));

        const translationKey = getPricingFactorTranslationKey(pricingFactorKey, dominateSubCategoryId, locale);

        return constructResultObject(filters, currentFilter, {
            ...currentFilter.DEFAULT_FILTER,
            display_type: currentFilter.DISPLAY_TYPE,
            defaultOption,
            selectedOption,
            options,
            translationKey,
            alias: '',
        });
    },

    [FLOWS.CATEGORY](context, { sub_categories: subCategories = [], nested_sub_categories: nestedSubCategories = [] }) {
        const { categoryIds, subCategoryData, translationsService } = context;
        const { subCategoryId, nestedSubCategoryId } = categoryIds;

        const slugs = {
            category: subCategoryData.category.cached_slug,
            subCategory: subCategoryData.cached_slug,
        };

        const currentSubCategory = subCategories.find(({ id }) => id === subCategoryId) || {};
        const subCategoryCount = nestedSubCategoryId
            ? nestedSubCategories.reduce((sum, { count }) => sum + count, 0)
            : currentSubCategory.count;

        const all = constructSubCategoryAllObject(
            translationsService.general.translate('search_perseus.filter_builder.sub_category.all_in', {
                category: subCategoryData.category.name,
            }),
            { category: subCategoryData.category.cached_slug }
        );

        const subCategory = constructSubCategoryObject(subCategoryCount, slugs, context);
        const enrichedNestedSubCategories = getEnrichedNestedSubCategories(subCategoryData, nestedSubCategories);

        const options = enrichedNestedSubCategories
            .map(({ id, count, src }) => constructNestedSubCategoryTileLink(id, count, src, slugs, context))
            .filter((opt) => !!opt.alias);

        return {
            ...all,
            globalFiltersQuery: getGlobalActiveFiltersQuery(context),
            children: [{ ...subCategory, children: options }],
        };
    },
    [FLOWS.BUSINESS_CATEGORIES](
        context,
        { sub_categories: subCategories = [], nested_sub_categories: nestedSubCategories = [] }
    ) {
        const { categoryIds, subCategoryData, translationsService } = context;
        const { subCategoryId, nestedSubCategoryId } = categoryIds;

        const slugs = {
            category: subCategoryData.category.cached_slug,
            subCategory: subCategoryData.cached_slug,
        };

        const currentSubCategory = subCategories.find(({ id }) => id === subCategoryId) || {};
        const subCategoryCount = nestedSubCategoryId
            ? nestedSubCategories.reduce((sum, { count }) => sum + count, 0)
            : currentSubCategory.count;

        const all = constructSubCategoryAllObject(
            translationsService.general.translate('search_perseus.filter_builder.sub_category.all_in', {
                category: subCategoryData.category.name,
            }),
            { category: subCategoryData.category.cached_slug }
        );

        const subCategory = constructSubCategoryObject(subCategoryCount, slugs, context);
        const enrichedNestedSubCategories = getEnrichedNestedSubCategories(subCategoryData, nestedSubCategories);

        const options = enrichedNestedSubCategories
            .map(({ id, count, src }) => constructNestedSubCategoryTileLink(id, count, src, slugs, context))
            .filter((opt) => !!opt.alias);

        return {
            ...all,
            globalFiltersQuery: getGlobalActiveFiltersQuery(context),
            children: [{ ...subCategory, children: options }],
        };
    },
    [FLOWS.SEARCH](context, { sub_categories: subCategories = [], leaf_categories: leafCategories = [] }) {
        const { translationsService } = context;
        const all = constructSearchSubCategoryOptionObject(
            translationsService.general.translate('search_perseus.filter_builder.sub_category.all_categories'),
            context
        );
        const categoryFacetList = leafCategories.length ? leafCategories : subCategories;
        const options = getLeafCategoriesFacetOption(categoryFacetList, context);

        return {
            ...all,
            globalFiltersQuery: getGlobalActiveFiltersQuery(context),
            children: options,
        };
    },
    [FLOWS.PROFESSION_GIGS_SEARCH](
        context,
        { sub_categories: subCategories = [], leaf_categories: leafCategories = [] }
    ) {
        // COPIED FROM FLOWS.SEARCH
        const { translationsService } = context;
        const all = constructSearchSubCategoryOptionObject(
            translationsService.general.translate('search_perseus.filter_builder.sub_category.all_categories'),
            context
        );
        const categoryFacetList = leafCategories.length ? leafCategories : subCategories;
        const options = getLeafCategoriesFacetOption(categoryFacetList, context);

        return {
            ...all,
            globalFiltersQuery: getGlobalActiveFiltersQuery(context),
            children: options,
        };
    },
    [FLOWS.BUSINESS_SEARCH](context, { sub_categories: subCategories = [], leaf_categories: leafCategories = [] }) {
        const { translationsService } = context;
        const all = constructSearchSubCategoryOptionObject(
            translationsService.general.translate('search_perseus.filter_builder.sub_category.all_categories'),
            context
        );
        const categoryFacetList = leafCategories.length ? leafCategories : subCategories;
        const options = getLeafCategoriesFacetOption(categoryFacetList, context);

        return {
            ...all,
            globalFiltersQuery: getGlobalActiveFiltersQuery(context),
            children: options,
        };
    },
    /** Profession Based Filter */
    [FILTER.PROFESSION.ID](
        { subCategoryData: { filters }, activeFilters, translationsService, professionFilterTranslationMaps },
        facetValues = []
    ) {
        const { [FILTER.PROFESSION.ID]: selectedProfessionFilterOptions } = activeFilters;
        const isGroupedFormat = Array.isArray(facetValues) && facetValues.every((group) => group.values && group.id);
        const maxVisibleOptions = FILTER.PROFESSION.MAX_VISIBLE_OPTIONS;

        if (isGroupedFormat) {
            const normalizedFacetGroups = facetValues.map((facetGroup) => {
                const facetGroupName = `${FILTER.PROFESSION.ID}.${facetGroup.name}`;
                const { [facetGroupName]: selectedProfessionOptions } = activeFilters;
                const options = createFilterOptions(
                    facetGroup.values,
                    professionFilterTranslationMaps.profession,
                    selectedProfessionOptions
                );

                if (!options.length) return null;

                return constructResultObject(filters, FILTER.PROFESSION, {
                    options,
                    id: facetGroupName,
                    alias: getFilterLabel(facetGroup, professionFilterTranslationMaps.profession),
                    topGrouping: TOP_GROUPING.PROFESSION,
                });
            });

            return normalizedFacetGroups.filter((group) => !!group);
        } else {
            const options = createFilterOptions(
                facetValues,
                professionFilterTranslationMaps.profession,
                selectedProfessionFilterOptions
            );
            const hasSupply = !!options.length;
            if (!hasSupply) return null;

            const filterGroupLabel = translationsService.filters.name(FILTER.PROFESSION.ID);
            return constructResultObject(filters, FILTER.PROFESSION, {
                options,
                alias: filterGroupLabel,
                topGrouping: TOP_GROUPING.PROFESSION,
                maxVisibleOptions,
            });
        }
    },
    /** Profession Based Filter */
    [FILTER.OFFERED_SERVICE.ID](
        { subCategoryData: { filters }, activeFilters, translationsService, professionFilterTranslationMaps },
        facetValues = []
    ) {
        const { [FILTER.OFFERED_SERVICE.ID]: selectedOfferedServiceOptions } = activeFilters;

        const options = createFilterOptions(
            facetValues,
            professionFilterTranslationMaps.offered_service,
            selectedOfferedServiceOptions
        );
        const hasSupply = !!options.length;
        if (!hasSupply) return null;
        const filterGroupLabel = translationsService.filters.name(FILTER.OFFERED_SERVICE.ID);
        return constructResultObject(filters, FILTER.OFFERED_SERVICE, {
            options,
            alias: filterGroupLabel,
            topGrouping: TOP_GROUPING.SERVICE,
        });
    },

    /** Profession Based Filter */
    [FILTER.PROFICIENCIES.ID]: (
        { subCategoryData: { filters }, activeFilters, professionFilterTranslationMaps },
        facetGroups = []
    ) => {
        const normalizedFacetGroups = facetGroups.map((facetGroup) => {
            const { [facetGroup.name]: selectedProficienciesOptions } = activeFilters;
            const options = createFilterOptions(
                facetGroup.values,
                professionFilterTranslationMaps.proficiencies,
                selectedProficienciesOptions
            );

            if (!options.length) return null;
            return constructResultObject(filters, FILTER.PROFICIENCIES, {
                options,
                id: facetGroup.name,
                alias: getFilterLabel(facetGroup, professionFilterTranslationMaps.proficiencies),
                topGrouping: TOP_GROUPING.PROFICIENCIES,
            });
        });

        return normalizedFacetGroups.filter((group) => !!group);
    },
};

const getLeafCategoriesFacetOption = (categoryFacetList, context) =>
    categoryFacetList
        .map(({ id, count, type, parentId }) =>
            type === CATEGORY_TYPE.NESTED_SUB_CATEGORY
                ? constructNestedSubCategoryOptionObject(parentId, id, count, context)
                : constructSubCategoryOptionObject(id, count, context)
        )
        .filter((opt) => !!opt.alias);

const getGlobalActiveFiltersQuery = ({ activeFilters }) => {
    const filterKeys = Object.keys(activeFilters).filter((key) => GLOBAL_FILTERS.includes(key));
    const globalFilter = filterKeys.reduce((filter, key) => {
        filter[key] = activeFilters[key];
        return filter;
    }, {});

    return activeFiltersToQueryString(globalFilter);
};

const getEnrichedNestedSubCategories = (subCategoryData, nestedSubCategories) => {
    if (!subCategoryData.nestedSubCategories) {
        return [];
    }

    const result = [];

    for (const nsc of nestedSubCategories) {
        const nscData = subCategoryData.nestedSubCategories.find(({ id }) => id === parseInt(nsc.id, 10));

        if (nscData) {
            result.push({
                ...nsc,
                src: nscData.src,
            });
        }
    }

    return result;
};
