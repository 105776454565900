import { GoSearcherRoute, SearchPerseusConfig } from '@fiverr-private/listing_types';
import { validateQuery } from '../../util';
import { FetchRelatedSearchTerms, fetchRelatedSearchTermsFromSearchService } from '../../dataEnrichmentPromises';
import { GigFlow } from './GigFlow';

export class GigSearchFlow extends GigFlow {
    timeout?: number;
    metricRouteKey: string;
    servicePath: string;
    fetchRelatedSearchTerms: () => Promise<FetchRelatedSearchTerms>;

    route: GoSearcherRoute = 'search';
    validateRequest = validateQuery;

    constructor(serviceConfigs: SearchPerseusConfig) {
        super('gigSearchFlow', serviceConfigs);

        const { search_service: searchService } = serviceConfigs;

        const { timeout, name, url } = searchService;

        if (timeout?.[this.route]) {
            this.timeout = timeout[this.route];
        }

        this.metricRouteKey = `${name}.${this.route}`;
        this.servicePath = `${url}/v5/${this.route}`;

        this.fetchRelatedSearchTerms = () => fetchRelatedSearchTermsFromSearchService(serviceConfigs.search_service);
    }
}
