import { isEmpty, isNil } from 'lodash';
import { ActiveFilterOptions, ActiveFilters } from '../Types';

export const checkActiveFiltersEmpty = (activeFilters: ActiveFilters | null) => {
    if (!activeFilters || isEmpty(activeFilters)) {
        return true;
    }

    const filterKeys = Object.keys(activeFilters);

    for (const index in filterKeys) {
        const key = filterKeys[index];

        const filter = activeFilters[key];

        if (filter instanceof Array) {
            if (!isEmpty(filter)) {
                return false;
            }
        } else if (filter) {
            return false;
        }
    }

    return true;
};

export const areEqual = (a1: ActiveFilters, a2: ActiveFilters) => {
    if (a1 === a2) {
        return true;
    }

    if (isNil(a1) || isNil(a2)) {
        return false;
    }

    const keys1 = Object.keys(a1).filter((key) => !activeFilterOptionsEmpty(a1[key]));
    const keys2 = Object.keys(a2).filter((key) => !activeFilterOptionsEmpty(a2[key]));

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        const filter1 = a1[key];
        const filter2 = a2[key];

        if (filter1 instanceof Array) {
            if (!(filter2 instanceof Array)) {
                return false;
            }

            if (filter1.length !== filter2.length) {
                return false;
            }

            for (const index in filter1) {
                const filter2HasCurrentValue = filter2.includes(filter1[index]);

                if (!filter2HasCurrentValue) {
                    return false;
                }
            }
        } else if (filter1 !== filter2) {
            return false;
        }
    }

    return true;
};

const activeFilterOptionsEmpty = (options: ActiveFilterOptions) => {
    if (!options) {
        return true;
    }

    if (options instanceof Array) {
        return isEmpty(options);
    }

    return false;
};

export const countActiveFilters = (activeFilters: ActiveFilters) => {
    let activeFiltersCount = 0;

    Object.keys(activeFilters ?? {}).forEach((filterId) => {
        const currentFilter = activeFilters[filterId];
        if (typeof currentFilter === 'object') {
            activeFiltersCount += currentFilter?.length ?? 0;
        } else {
            activeFiltersCount += 1;
        }
    });

    return activeFiltersCount;
};
