import React from 'react';
import ListingCarousel from '../../GigCardListings/ListingCarousel';
import BasicGigCardSml from '../../GigCards/ModernGigCards/BasicGigCardSml';
import { withListingsTransformer } from '../../GigCardListings/hoc/withListingsTransformer';
import { CAROUSEL_BREAKPOINTS, SMALL_SLIDER_BASE_NUM } from '../../GigCardListings/ListingCarousel/constants';
import { clearGig } from '../../GigCardListings/service';
import { SmallGigCardCarouselPropTypes } from '../../GigCardListings/types/types';
import { SMALL_GIG_CARD, SMALL_CARD_CLASS } from '../../GigCardListings/utils/constants';

const SmallGigCardCarousel = ({
    gigs,
    gigImpression,
    onImpression,
    isTouch,
    tracking,
    sliderBaseNum = SMALL_SLIDER_BASE_NUM,
    sliderBreakpoints = CAROUSEL_BREAKPOINTS,
    isLoading,
    isInfinite,
    lazyLoad,
    onClearGig = clearGig,
    showTranslatedUGC,
    collectProps,
    hidePrefix = false,
    showTopRightArrows = false,
    HeaderComponent,
    rollouts,
    excludedBadges = [],
}) => (
    <ListingCarousel
        gigs={gigs}
        tracking={tracking}
        gigImpression={gigImpression}
        isTouch={isTouch}
        isInfinite={isInfinite}
        isLoading={isLoading}
        lazyLoad={lazyLoad}
        RenderGig={BasicGigCardSml}
        onClear={onClearGig}
        slidesToShow={sliderBaseNum}
        sliderBreakpoints={sliderBreakpoints}
        showTranslatedUGC={showTranslatedUGC}
        layoutClass={SMALL_CARD_CLASS}
        collectProps={collectProps}
        onImpression={onImpression}
        hidePrefix={hidePrefix}
        showTopRightArrows={showTopRightArrows}
        rollouts={rollouts}
        HeaderComponent={HeaderComponent}
        excludedBadges={excludedBadges}
    />
);

SmallGigCardCarousel.propTypes = SmallGigCardCarouselPropTypes;

export default withListingsTransformer(SMALL_GIG_CARD, true)(SmallGigCardCarousel);
