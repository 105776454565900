import { RequestContext } from '@fiverr-private/perseus';
import { isActive } from '@fiverr-private/rollout_js';
import { EXPERIMENT_TYPE } from '@fiverr-private/experiments_package';
import { EXPERIMENTS, ROLLOUTS } from '@fiverr-private/listing_experiments';
import { isEligibleForAllocation } from './common';

const inRollout = async () => {
    const { userId } = RequestContext;
    return userId ? await isActive(ROLLOUTS.DOLPHINS_HOURLY_RATE_BANNER_LISTINGS_USER, userId) : false;
};

export const hourlyBannerUser = {
    id: EXPERIMENTS.DOLPHINS_HOURLY_RATE_BANNER_LISTINGS_USER,
    experimentType: EXPERIMENT_TYPE.USER,
    numOfGroups: 2,
    shouldAllocate: isEligibleForAllocation,
    inRollout,
};
