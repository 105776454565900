import React, { useEffect } from 'react';
import { tracker } from '@fiverr-private/listing_lib';
import { EmptySearchResults } from '@fiverr-private/listing_results';

export const ErrorPage = ({ currency, rollouts }) => {
    useEffect(() => {
        tracker.error();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <EmptySearchResults currency={currency} rollouts={rollouts} />;
};
