import { useState, useEffect, useCallback } from 'react';
import { getWindow } from '../../../../../../../shared/utils';

const getAdaEmbed = () => getWindow()?.adaEmbed;
const chatPollingTimeMs = 1000;

export const useSupportChat = () => {
    const [chat, setChat] = useState(getAdaEmbed());

    const startChat = () => {
        getWindow()?.dispatchEvent(
            new CustomEvent('supportchat:init', {
                detail: { chat_id: 'business', department: 'Fiverr Business', tags: ['business'], state: 'SHOWN' },
            })
        );
    };

    const openChatWindow = async () => {
        if (getAdaEmbed()) {
            const { isChatOpen } = await getAdaEmbed().getInfo();
            if (!isChatOpen) {
                getAdaEmbed().toggle();
            }
        }
    };

    const toggleChat = useCallback(async () => {
        if (chat) {
            getAdaEmbed()?.toggle();
        } else {
            startChat();
            setTimeout(openChatWindow, 2000);
        }
    }, [chat]);

    useEffect(() => {
        let timeId;
        if (!chat) {
            timeId = setInterval(() => setChat(getAdaEmbed()), chatPollingTimeMs);
        }
        return () => clearInterval(timeId);
    }, [chat]);

    return {
        isChatAvailable: !!chat,
        toggleChat,
    };
};
