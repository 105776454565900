import { clientAllocate } from '@fiverr-private/experiments_package';
import { ALLOCATION_DEFAULTS } from '../constants';
import { clientExperiments } from '../clientExperiments';

/**
 * @param {number} experimentId
 * @param {Record<number, string|number>} clientExperimentsGroups
 * @returns {number | undefined}
 */
export const allocateToClientExperiment = (experimentId, clientExperimentsGroups) => {
    const experiment = Object.values(clientExperiments).find(({ id }) => id === experimentId);

    if (!experiment) {
        return;
    }

    const { experimentType = ALLOCATION_DEFAULTS.experimentType, numOfGroups = ALLOCATION_DEFAULTS.numOfGroups } =
        experiment;

    return clientAllocate({
        experimentId,
        experimentType,
        numOfGroups,
        rolloutResult: Number(clientExperimentsGroups[experimentId]) > 0,
    });
};
