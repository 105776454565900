import React, { useContext } from 'react';
import { string, bool, func } from 'prop-types';
import {
    LogoMakerForkEntry,
    shouldShowLogoMakerSwitcher,
    Toggle,
    SWITCHER_STATES,
} from '@fiverr-private/logo_maker_marketplace';
import { AppContext } from '../../context/listingsContext';

const LogoMakerFork = ({ listingsType, searchQuery, showLMFlow, setShowLMFlow, listingSource }) => {
    const { logoMakerConfig = {} } = useContext(AppContext);
    const logoMakerBiProps = {
        listingsType,
        searchQuery,
    };

    return shouldShowLogoMakerSwitcher(logoMakerConfig, listingSource) ? (
        <>
            <div className="layout-row logo-maker-switcher-wrapper">
                <Toggle
                    onChange={(listingType) => setShowLMFlow(listingType === SWITCHER_STATES.LOGO_MAKER)}
                    activeState={showLMFlow ? SWITCHER_STATES.LOGO_MAKER : SWITCHER_STATES.LOGO_SERVICES}
                    biProps={logoMakerBiProps}
                />
            </div>

            {showLMFlow && (
                <div className="layout-row logo-maker-flow logo-maker-static-filters-wrapper">
                    <LogoMakerForkEntry
                        logoMakerConfig={logoMakerConfig}
                        rollouts={{}}
                        onNavigateToLogoServicesClick={() => setShowLMFlow(false)}
                    />
                </div>
            )}
        </>
    ) : null;
};

LogoMakerFork.propTypes = {
    listingsType: string,
    searchQuery: string,
    showLMFlow: bool,
    setShowLMFlow: func,
    listingSource: string,
};

export default LogoMakerFork;
