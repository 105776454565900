import React from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import { Heading } from '@fiverr-private/typography';
import { FiverrChoiceBadge } from '@fiverr-private/badges';
import { Stack } from '@fiverr-private/layout_components';

export const ModalitiesHeaderContent: React.FC = () => (
    <Stack width="auto" alignItems="center" wrap="wrap">
        <FiverrChoiceBadge />
        <Heading as="h3">
            <I18n k="search_perseus.choice_modalities.title" />
        </Heading>
    </Stack>
);
