import React from 'react';
import PropTypes from 'prop-types';
import { translate } from '@fiverr-private/i18n-react';
import { Tooltip } from '@fiverr-private/tooltip';
import { Icon } from '@fiverr-private/fit';
import { colorWhite } from '@fiverr-private/sass/helpers';
import { r_times } from '@fiverr-private/icons';

const ClearButton = ({ clearClick }) => (
    <div className="clear-btn-wrapper">
        <Tooltip content={translate('gig_listings.gig_cards.clear')} position="top" appendToBody="true">
            <button className="clear-btn b-0 box-shadow-z2 flex-center" onClick={clearClick}>
                <Icon size="10" color={colorWhite}>
                    {r_times}
                </Icon>
            </button>
        </Tooltip>
    </div>
);

ClearButton.propTypes = {
    clearClick: PropTypes.func.isRequired,
};

export default ClearButton;
