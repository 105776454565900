import { RequestContext } from '@fiverr-private/perseus';
import { SUPPORTED_FIVERR_LOCALES_KEYS, DEFAULT_FIVERR_LOCALE } from '@fiverr-private/localization';
import { MT_CONTEXTS, getTranslateOnLoad } from '@fiverr-private/machine_translation';
import type { FlowName, InitialProps } from '@fiverr-private/listing_types';

interface TranslationConfig {
    abTests: Record<string, string | number>;
    allowTranslationForCrawlers: boolean;
    gigsPhoenixUrl: string | null;
    knownCrawler: boolean;
    locale: string;
    translationPegasusUrl: string;
    sourceInfo: {
        context: string;
        userGuid: string;
        page: {
            ctxId: string;
        };
        user: {
            id?: number;
        };
        name: 'search_gigs' | 'sub_category' | 'business_search' | 'business_categories' | 'search_roles';
        component: {
            name: 'search_sub_header' | 'sub_category_sub_header';
        };
    };
    timeout?: number;
}

const ALLOWED_LOCALES_FOR_CRAWLERS = [
    SUPPORTED_FIVERR_LOCALES_KEYS.IT_IT,
    SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE,
    SUPPORTED_FIVERR_LOCALES_KEYS.FR_FR,
    SUPPORTED_FIVERR_LOCALES_KEYS.ES,
];

const FLOWS_BI_DATA_UGC_MAPPER: Record<FlowName, Pick<TranslationConfig['sourceInfo'], 'name' | 'component'>> = {
    gigSearchFlow: { name: 'search_gigs', component: { name: 'search_sub_header' } },
    gigCategoriesFlow: { name: 'sub_category', component: { name: 'sub_category_sub_header' } },
    gigBusinessSearchFlow: { name: 'business_search', component: { name: 'search_sub_header' } },
    gigBusinessCategoriesFlow: { name: 'business_categories', component: { name: 'sub_category_sub_header' } },
    roleSearchFlow: { name: 'search_roles', component: { name: 'search_sub_header' } },
    gigProfessionSearchFlow: { name: 'search_gigs', component: { name: 'search_sub_header' } },
};

export const buildTranslationConfig = ({
    translationPegasusUrl,
    translationPegasusTimeout,
    gigsPhoenixServiceUrl,
}: {
    translationPegasusUrl: string;
    gigsPhoenixServiceUrl: string;
    translationPegasusTimeout?: number;
}): TranslationConfig | undefined => {
    const { initialProps, pageCtxId, locale, userId, knownCrawler, abTests, userGuid, cookies } = RequestContext;
    const { rollouts, Flow } = initialProps as InitialProps;

    const isLocaleAllowedForCrawlers = ALLOWED_LOCALES_FOR_CRAWLERS.includes(locale);
    const isDefaultMTEnabled = rollouts?.lions_default_mt_translation;
    const translateUGC = getTranslateOnLoad(cookies, isDefaultMTEnabled) && locale !== DEFAULT_FIVERR_LOCALE;

    if (!Flow || !translateUGC || (knownCrawler && !isLocaleAllowedForCrawlers)) {
        return undefined;
    }

    const isLocalCatalogEnabled = rollouts?.lions_local_catalog_buyer_side;
    const gigsPhoenixUrl = isLocalCatalogEnabled ? gigsPhoenixServiceUrl : null;

    const sourceInfoBIData = FLOWS_BI_DATA_UGC_MAPPER[Flow.flowName];

    return {
        translationPegasusUrl,
        gigsPhoenixUrl,
        locale,
        knownCrawler,
        sourceInfo: {
            ...sourceInfoBIData,
            user: {
                id: userId,
            },
            userGuid,
            page: {
                ctxId: pageCtxId,
            },
            context: MT_CONTEXTS.GIG_TITLE,
        },
        allowTranslationForCrawlers: true,
        abTests,
        ...(translationPegasusTimeout && { serverTranslationTimeout: translationPegasusTimeout }),
    };
};
