import React from 'react';
import { array, bool, func, oneOfType, string } from 'prop-types';
import { CONTENT_MAPPING } from '../../../../../floating_topbar/menus/content';
import { MENU_CONTENT_TYPES } from '../../../../../floating_topbar/menus/consts';

import './index.scss';

const List = ({ items, selected, onSelect, isMulti = false }) => {
    const Component = isMulti
        ? CONTENT_MAPPING[MENU_CONTENT_TYPES.CHECKBOX_LIST]
        : CONTENT_MAPPING[MENU_CONTENT_TYPES.RADIO_LIST];

    return (
        <div className="list">
            <Component items={items} selected={selected} onSelect={onSelect} />
        </div>
    );
};

List.propTypes = {
    items: array,
    selected: oneOfType([string, array]),
    onSelect: func,
    isMulti: bool,
};

export default List;
