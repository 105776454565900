import { URI } from '@fiverr-private/futile';
import { tracker, EVENTS_MAPPING } from '@fiverr-private/listing_lib';
import { NSC_PATH_MAPPER } from '../../../../../utils/constants';
import { IMAGES_PATH } from '../constants';
import { sendBiClickEvent } from '../../../../generic_listings/TagFilters/utils';
import { BI_ELEMENTS_NAMES, ELEMENT_TYPE } from '../../../../generic_listings/TagFilters/constants';

export const handleClick = (path, elementMetadata) => {
    const event = EVENTS_MAPPING.CLICK.TARGETS[NSC_PATH_MAPPER[path]];

    sendBiClickEvent(BI_ELEMENTS_NAMES.LEAF_CATEGORY, elementMetadata, ELEMENT_TYPE.VISUAL);
    tracker.click(event);
};

export const urlBuilder = (url, params = {}) => {
    const { source, ref } = params;
    const uri = new URI(url);
    source && uri.addParams({ source });
    ref && uri.addParams({ ref });
    return uri;
};

export const getNscImagePath = (id) => `${IMAGES_PATH}/${id}.svg`;
