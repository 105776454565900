import { isEmpty } from 'lodash';
import { Gig } from './Gig/Gig';
import { Recommendations } from './Recommendations';
import { ChoiceModalities } from './ChoiceModalities';
import { PromotedGigsBanner } from './PromotedGigsBanner';
import { ImpressionEnrichment } from './ImpressionEnrichment';
import { ListingResultLegacyContext } from './ListingResultLegacyContext';
import { ListingResultFeatures } from './ListingResultFeatures';
import { Role } from './Role/Role';

export interface GigsListingResult {
    gigs: Gig[];
    proData?: Gig[];
    sellersWhoSpeak?: Gig[];
    promotedGigsBanner?: PromotedGigsBanner;
    choiceModalities?: ChoiceModalities;
    recommendations?: Recommendations;
    showTranslatedUGC: boolean;
    hidePrefix: boolean;
    translationStatus: number;
    isHeadQuery: boolean;
    isNonExperiential: boolean;
    showListViewCards: boolean;
    impressionEnrichment: Partial<ImpressionEnrichment>;
    context: Partial<ListingResultLegacyContext>;
    features: Partial<ListingResultFeatures>;
}

export interface RolesListingResult {
    roles: Role[];
    showTranslatedUGC?: boolean;
}

export type ListingResult = GigsListingResult | RolesListingResult;

export const isGigsListingResult = (data: any): data is GigsListingResult => !isEmpty(data) && 'gigs' in data;
export const isRolesListingResult = (data: any): data is RolesListingResult => !isEmpty(data) && 'roles' in data;
