import React, { useContext, useState } from 'react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { I18n } from '@fiverr-private/i18n-react';
import { Dropdown, Menu } from '@fiverr-private/orca';
import { useImpressionItem } from '@fiverr-private/impressionable';
import { FILTERS_LOCATION, FILTER, tracker } from '@fiverr-private/listing_lib';
import { BI_SOURCES } from '../../../../../../utils/constants';
import { filterNavigation } from '../../../../../../service/clientNavigation';
import { TOP_GROUPING } from '../../../../../../config/filters';
import { sendImpressionEvent } from '../../../../../../../../shared/context/ListingsImpressionContainer/utils/sendImpressionEvent/sendImpressionEvent';
import { AppContext } from '../../../../../context/listingsContext';
import { PAGE_NAME } from '../../../../../../../../shared/context/ListingsImpressionContainer/constants/pageName';
import number from '../../../../../../../../shared/utils/formatting/number';
import { getPricingFactorOptionAlias } from './utils';

import styles from './index.module.scss';

const applyFilters = (
    change,
    active,
    filtersGroupName = TOP_GROUPING.PRICING_FACTOR,
    location = FILTERS_LOCATION.FLOATING_TOP,
    source = BI_SOURCES.DROPDOWN_FILTERS
) => filterNavigation(change, active, location, source, filtersGroupName);

const PricingFactorDropdown = ({ showSeparator = true }) => {
    const {
        appFilters: { filters = [] },
        activeFilters,
        locale,
    } = useContext(AppContext);

    const filter = filters.find(({ id }) => id === FILTER.PRICING_FACTOR.ID) || {};
    const { options, selectedOption = {}, defaultOption = {}, translationKey } = filter;

    const getOptionAlias = (option = {}) => {
        if (isEmpty(option)) {
            return;
        }

        return getPricingFactorOptionAlias({ count: number(option.value), translationKey, locale });
    };

    const activeOption = !isEmpty(selectedOption) ? selectedOption : defaultOption;
    const [activeOptionId, setActiveOptionId] = useState(activeOption.id);
    const [activeOptionAlias, setActiveOptionAlias] = useState(getOptionAlias(activeOption));
    const [ref] = useImpressionItem({
        enrichment: {
            name: 'pricing_factor_filter',
        },
        sendImpressionToBQ: false,
        onImpression: (impressionData) => sendImpressionEvent(impressionData, PAGE_NAME.CUSTOM_LISTINGS_PAGE),
    });

    if (isEmpty(filter) || isEmpty(options)) {
        return null;
    }

    const handleOnChange = (selectedOptionId) => {
        if (activeOptionId === selectedOptionId) {
            return;
        }

        const selectedOption = options.find(({ id }) => id === selectedOptionId);

        if (!selectedOption) {
            return;
        }

        setActiveOptionId(selectedOptionId);
        setActiveOptionAlias(getOptionAlias(selectedOption));
        applyFilters({ [FILTER.PRICING_FACTOR.ID]: selectedOptionId }, activeFilters);
    };

    const handleOpen = () => {
        tracker.trackFilterGroupOpen(BI_SOURCES.PRICING_FACTOR);
    };

    return (
        <>
            {showSeparator && <div className={classNames('m-l-8', 'm-r-12', styles.separator)} />}

            <div className={classNames('price-scope-filter', 'flex', 'flex-items-center', styles.container)} ref={ref}>
                <div className={classNames(styles.title)}>
                    <I18n k="search_perseus.filters.global.pricing_factor_skeleton.title" />
                </div>

                <Dropdown
                    onOpen={handleOpen}
                    position={Dropdown.POSITIONS.LEFT}
                    appendToBody={false}
                    containerClassName={styles.menuContainer}
                    boxContentClassName={styles.boxContent}
                    menu={
                        <Menu selectable>
                            <Menu.Group checked={activeOptionId} onChange={handleOnChange} shouldCloseOnClick>
                                {options.map((option) => (
                                    <Menu.Item
                                        key={option.id}
                                        value={option.id}
                                        className={classNames('m-r-8', 'm-l-8', styles.menuItem)}
                                    >
                                        {getOptionAlias(option)}
                                    </Menu.Item>
                                ))}
                            </Menu.Group>

                            <div className={styles.menuDivider}></div>

                            <div
                                className={classNames(
                                    'tbody-6',
                                    'p-t-16',
                                    'p-b-8',
                                    'p-l-20',
                                    'p-r-20',
                                    styles.menuText
                                )}
                            >
                                <I18n
                                    k="search_perseus.filters.global.pricing_factor_skeleton.text"
                                    templates={{
                                        first_row: (text) => <div>{text}</div>,
                                        second_row: (text) => <div>{text}</div>,
                                    }}
                                />
                            </div>
                        </Menu>
                    }
                >
                    <Dropdown.Button
                        label={activeOptionAlias}
                        outlined={true}
                        iconClassName={styles.buttonIcon}
                        className={classNames('text-semi-bold', styles.button)}
                    />
                </Dropdown>
            </div>
        </>
    );
};

export default PricingFactorDropdown;
