import React from 'react';
import classnames from 'classnames';
import { I18n } from '@fiverr-private/i18n-react';
import { ChevronLeftIcon } from '@fiverr-private/icons';

export const ClearFilter = ({ className, onClick }) => (
    <div onClick={onClick} className={classnames('tbody-6', className)}>
        <ChevronLeftIcon size={10} />
        <span className="m-l-4">
            <I18n k={'search_perseus.filter_builder.sub_category.all_categories'} />
        </span>
    </div>
);
