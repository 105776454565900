import React, { forwardRef } from 'react';
import classnames from 'classnames';

import './Input.scss';

const Input = forwardRef(({ symbol, inputContainerClassName, ...inputProps }, forwardRef) => (
    <div className={classnames('input-container', inputContainerClassName)}>
        <span>{symbol}</span>
        <input ref={forwardRef} {...inputProps} />
    </div>
));

Input.displayName = 'Input';

export default Input;
