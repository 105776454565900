import React from 'react';
import { I18n } from '@fiverr-private/fiverr_context';

const refresh = () => window.location.reload();

const AppError = () => (
    <div className="listings-error">
        <h2>
            <I18n k={'search_perseus.general.listings_error'} />
        </h2>
        <button className="btn-lrg-standard btn-cta" onClick={refresh}>
            <I18n k={'search_perseus.general.reload'} />{' '}
        </button>
    </div>
);
export default AppError;
