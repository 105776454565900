import React, { createContext, FC, ReactNode, useState } from 'react';
import { ActiveFilters } from '../../Types';
import { SiderbarFiltersContextType, Triggers } from './Types';

export const SidebarFiltersContext = createContext<SiderbarFiltersContextType>({
    activeTrigger: Triggers.FILTER_COMPONENT_CLICK,
    applyEvent: [],
    clearAllEvent: [],
    showFacetResultsCounters: true,
    newActiveFilters: {},
    setNewActiveFilters: (_activeFilters) => ({}),
});

export const SidebarFiltersContextProvider: FC<{
    children: ReactNode;
    activeTrigger: Triggers;
    showFacetResultsCounters: boolean;
    activeFilters: ActiveFilters;
}> = ({ children, activeTrigger, showFacetResultsCounters, activeFilters }) => {
    const [newActiveFilters, setNewActiveFilters] = useState<ActiveFilters>(activeFilters);

    return (
        <SidebarFiltersContext.Provider
            value={{
                activeTrigger,
                applyEvent: [],
                clearAllEvent: [],
                showFacetResultsCounters,
                newActiveFilters,
                setNewActiveFilters,
            }}
        >
            {children}
        </SidebarFiltersContext.Provider>
    );
};
