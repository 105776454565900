import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { ProFreelancersBanner, ProFreelancersBannerWrapper } from '../../banner/ProFreelancersBanner';
import { useBusinessListingsContext } from '../Context';
import SidebarFilters, { Triggers, SidebarFiltersProvider } from '../SidebarFilters';
import styles from './index.module.scss';

const SIDEBAR_VERTICAL_MARGIN = 20;

const reachedBottom = (element) => !!element && element.scrollHeight - element.scrollTop === element.clientHeight;

const Sidebar = () => {
    const containerRef = useRef(null);
    const [showFade, setShowFade] = useState(false);
    const [sidebarMaxHeight, setSidebarMaxHeight] = useState(null);

    const { isExpertListings, hasItems, filters, selectedFilters, activeFilters } = useBusinessListingsContext();
    const hasFilters = filters?.length > 0;
    const hasSelectedFilters = selectedFilters?.length > 0;

    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            const scrollHeight = containerRef.current?.scrollHeight || 0;
            const clientHeight = containerRef.current?.clientHeight || 0;
            const sidebarReachedBottom = reachedBottom(containerRef.current);

            if (scrollHeight > clientHeight && !sidebarReachedBottom) {
                setShowFade(true);
            } else {
                setShowFade(false);
            }
        });

        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [containerRef]);

    useEffect(() => {
        adjustSidebarHeight();
        window.addEventListener('scroll', adjustSidebarHeight);
        window.addEventListener('resize', adjustSidebarHeight);

        return () => {
            window.removeEventListener('scroll', adjustSidebarHeight);
            window.removeEventListener('resize', adjustSidebarHeight);
        };
    }, []);

    const onScrollEvent = (e) => {
        const { target } = e;

        const sidebarReachedBottom = reachedBottom(target);

        if (sidebarReachedBottom) {
            setShowFade(false);
        } else {
            setShowFade(true);
        }
    };

    const adjustSidebarHeight = () => {
        const distanceFromViewportTop = containerRef.current?.getBoundingClientRect().top;
        const viewportHeight = window.innerHeight;

        if (distanceFromViewportTop > SIDEBAR_VERTICAL_MARGIN) {
            const sidebarDesiredHeight = viewportHeight - distanceFromViewportTop - SIDEBAR_VERTICAL_MARGIN;
            setSidebarMaxHeight(sidebarDesiredHeight);
        } else {
            setSidebarMaxHeight(null);
        }
    };

    if (!hasFilters) {
        return null;
    }

    const sidebarClassNames = classNames(styles.sidebar, `${hasSelectedFilters ? 'm-t-40' : 'm-t-28'}`, {
        [styles.fade]: showFade,
    });

    const sidebarStyle = sidebarMaxHeight && { maxHeight: `${sidebarMaxHeight}px` };

    return (
        <div ref={containerRef} onScroll={onScrollEvent} className={sidebarClassNames} style={sidebarStyle}>
            <SidebarFiltersProvider
                activeFilters={activeFilters}
                activeTrigger={Triggers.FILTER_COMPONENT_CLICK}
                showFacetResultsCounters={true}
            >
                <SidebarFilters sidebarContainerRef={containerRef} />
            </SidebarFiltersProvider>
            {isExpertListings && hasItems && (
                <ProFreelancersBannerWrapper>
                    <ProFreelancersBanner />
                </ProFreelancersBannerWrapper>
            )}
        </div>
    );
};

export default Sidebar;
