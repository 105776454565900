export const FILTER_HASH_PREFIX = 'filter-';
export const FILTER_GROUP_OPEN_DELAY = 300;
export const FILTER_SCROLL_DELAY = 600;
export const ALL_GROUP = 'all';

export const FILTER_COLLECTION_IDS = {
    SERVICE_FILTERS: 'service-filters',
    SELLER_FILTERS: 'seller-filters',
    BUDGET_FILTERS: 'budget-filters',
    DELIVERY_TIME: 'delivery_time',
    SUB_CATEGORY: 'sub_category',
};

export const INPUT_TICKED = true;
export const INPUT_UNTICKED = false;
