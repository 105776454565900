import React from 'react';
import { Typography } from '@fiverr-private/typography';
import { I18n } from '@fiverr-private/i18n-react';
import { SelectedFilterOption as SelectedFilterOptionType } from '@fiverr-private/listing_types';
import { ActiveFilterOptionCountStyles } from './ActiveFilterOptionCount.ve.css';

interface ActiveFilterOptionCountProps {
    options: SelectedFilterOptionType[];
}

export const ActiveFilterOptionCount = ({ options }: ActiveFilterOptionCountProps) => {
    if (options.length < 2) {
        return null;
    }

    const optionCountToShow = options.length - 1;

    return (
        <Typography
            className={ActiveFilterOptionCountStyles}
            as="span"
            color="grey_1100"
            fontSize="b_md"
            fontWeight="bold"
        >
            <I18n k="search_perseus.side_filters.active_filter_option_count" params={{ count: optionCountToShow }} />
        </Typography>
    );
};
