import { chain } from 'lodash';
import { ASSET_TYPES, AUDIO_DEFAULT_THUMBNAIL } from '../../../../shared/constants';
import { convertAssetThumbnail } from '../../../../utils';
import { sortAssets } from '../../../AssetsCarousel/utils';

// TODO: Deprecate this file - needs to be removed from <GigService/>

const invokeCloudImage = (attachments, attachmentId) =>
    chain(attachments).find({ id: attachmentId }).get('imageUrl').value();

export const assetThumbnailByType = (item, attachments) => {
    let imageUrl = item.cloudImgMainGig;

    if (item.type === ASSET_TYPES.VIDEO) {
        imageUrl = invokeCloudImage(attachments, item.attachmentId);
    } else if (item.type === ASSET_TYPES.AUDIO) {
        imageUrl = invokeCloudImage(attachments, item.attachmentId) || AUDIO_DEFAULT_THUMBNAIL;
    }

    return convertAssetThumbnail(imageUrl);
};

const deliveryThumbnailByType = (delivery) =>
    delivery.type === 'audio' ? AUDIO_DEFAULT_THUMBNAIL : convertAssetThumbnail(delivery.imageUrl);

export const formatGigAssetsToThumbnails = (assets = [], attachments = [], deliveries = []) => {
    const assetsThumbnails = assets.map((asset) => ({
        src: assetThumbnailByType(asset, attachments),
        type: asset.type,
    }));
    const deliveriesThumbnails = deliveries.map((asset) => ({
        src: deliveryThumbnailByType(asset),
        type: ASSET_TYPES[asset.type.toUpperCase()],
        isDelivery: true,
    }));

    const gigAssetsAndDeliveries = [...assetsThumbnails, ...deliveriesThumbnails];

    return sortAssets(gigAssetsAndDeliveries);
};
