import React from 'react';
import { Icon } from '@fiverr-private/fit';
import { I18n } from '@fiverr-private/i18n-react';
import buyersKeepComingBack from '../assets/buyersKeepComingBack';

const BuyersKeepComingBack = () => (
    <>
        <Icon className="seller-highlight-icon">{buyersKeepComingBack}</Icon>
        <I18n k={'listings.highlights.buyers_keep_coming_back'} />
    </>
);

export default BuyersKeepComingBack;
