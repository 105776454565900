import { getContext } from '@fiverr-private/fiverr_context';
import { ELEMENT_NAMES, ELEMENT_TYPES, EVENT_TYPES, MIXPANEL_EVENT_NAMES } from '../../constants';
import { sendClickEventToBQ } from '../../BigQuery';
import { sendClickEventToMixPanel } from '../../Mixpanel';
import { convertElementNameForMixPanel, convertSellerForMixPanel, getPageNameForMixPanel } from '../../utils';

export const clickOnVisualsEvent = ({ seller, elementPosition, componentName, pageName, params } = {}) => {
    sendClickEventToBQ({
        type: EVENT_TYPES.CLICKED_ON_SELLER_GALLERY,
        elementName: ELEMENT_NAMES.SELLER_GALLERY,
        elementType: ELEMENT_TYPES.IMAGE,
        group: 'user_actions',
        elementPosition,
        componentName,
        pageName,
        seller,
        params,
    });

    sendClickEventToMixPanel({
        eventName: MIXPANEL_EVENT_NAMES.CLICKED_ON_EXPERT_CARD,
        ...getPageNameForMixPanel(pageName),
        'Clicked Element': convertElementNameForMixPanel(ELEMENT_NAMES.SELLER_GALLERY),
        'Element Metadata': { position: elementPosition },
        ...(seller && { ...convertSellerForMixPanel(seller) }),
    });
};

export const clickOnLightboxScrollEvent = ({ seller, elementText, componentName, pageName, params } = {}) => {
    sendClickEventToBQ({
        type: EVENT_TYPES.CLICKED_ON_SCROLL_SELLER_GALLERY,
        elementName: ELEMENT_NAMES.SCROLL_SELLER_GALLERY,
        elementType: ELEMENT_TYPES.BUTTON,
        group: 'user_actions',
        elementText,
        seller,
        componentName,
        pageName,
        params,
    });

    const { userId } = getContext();

    sendClickEventToMixPanel({
        eventName: MIXPANEL_EVENT_NAMES.SCROLL_EXPERT_CARD_IMAGES,
        'user id': userId?.toString(),
        'is guest': !userId,
        'is seller': !!seller,
        Page: pageName,
        sourceStr: 'Lightbox carousel',
    });
};

export default { clickOnVisualsEvent, clickOnLightboxScrollEvent };
