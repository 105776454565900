import React from 'react';
import { Icon } from '@fiverr-private/fit';
import { I18n } from '@fiverr-private/i18n-react';
import workedWithClientsInIndustry from '../assets/workedWithClientsInIndustry';
import { HIGHLIGHTS_DATA_KEYS } from '../../../shared/constants';
import { highlightsDataType } from '../types';

const WorkedWithClientsInIndustry = ({ highlightsData = {} }) => {
    const commonIndustry = highlightsData[HIGHLIGHTS_DATA_KEYS.COMMON_INDUSTRY] || {};
    const { name: industry, clientsCount } = commonIndustry;

    return (
        <>
            <Icon className="seller-highlight-icon">{workedWithClientsInIndustry}</Icon>
            <I18n k={'listings.highlights.worked_with_clients_in_industry'} params={{ industry, clientsCount }} />
        </>
    );
};

WorkedWithClientsInIndustry.propTypes = {
    highlightsData: highlightsDataType,
};

export default WorkedWithClientsInIndustry;
