import { isString } from 'lodash';
import { CUSTOM_BUDGET_VALUE, MAX_GIG_PRICE_RANGE } from './PriceRange.constants';

export const onRangeChange = (key, val, budgetValue, setBudgetValue) => {
    if (Number.isNaN(val)) {
        return;
    }

    if (isString(budgetValue)) {
        budgetValue = {};
    }

    if (val < 0) {
        val = val * -1;
    }

    if (val > MAX_GIG_PRICE_RANGE) {
        val = MAX_GIG_PRICE_RANGE;
    }

    const change = { [key]: parseInt(val, 10) || 0 };
    const newVal = {
        ...budgetValue,
        ...change,
    };

    setBudgetValue(newVal);
};

export const selectCustomOnFocus = (budgetValue, setBudgetValue) => {
    if (!budgetValue.min && !budgetValue.max) {
        setBudgetValue(CUSTOM_BUDGET_VALUE);
    }
};
