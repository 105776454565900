/* eslint-disable react/display-name */
import React from 'react';

export default () => (
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.2724 0.818098C4.35587 0.484223 4.65586 0.25 5.00001 0.25H8.50001C8.7275 0.25 8.94269 0.353253 9.08504 0.530704C9.22738 0.708156 9.2815 0.940625 9.23215 1.1627L8.43497 4.75H12.5C12.7782 4.75 13.0336 4.90401 13.1634 5.1501C13.2932 5.39618 13.2761 5.6939 13.119 5.92351L6.61899 15.4235C6.42029 15.7139 6.04643 15.8271 5.72001 15.6958C5.39358 15.5644 5.20236 15.2238 5.26021 14.8767L6.11466 9.75H3.00001C2.76906 9.75 2.55098 9.6436 2.40885 9.46156C2.26672 9.27952 2.21639 9.04215 2.2724 8.8181L4.2724 0.818098ZM5.58559 1.75L3.96059 8.25H7.00001C7.22047 8.25 7.42977 8.347 7.57227 8.51522C7.71477 8.68344 7.77605 8.90583 7.7398 9.1233L7.29786 11.775L11.0781 6.25H7.50001C7.27252 6.25 7.05732 6.14675 6.91498 5.9693C6.77263 5.79184 6.71852 5.55937 6.76787 5.3373L7.56504 1.75H5.58559Z"
        />
    </svg>
);
