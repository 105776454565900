export const FILTERS_LOCATION = {
    TOP: 'Top',
    FLOATING_TOP: 'Floating top',
    FLOATING_TOP_RIGHT: 'Floating top right',
    CLEAR_FLOATING_TOP: 'Clear Floating top',
    CLEAR_FLOATING_TOP_MORE: 'Clear Floating top (more filters)',
    CLEAR_FLOATING_TOP_GROUP: 'Clear Floating top (filter group)',
    MORE_FILTERS: 'More filters',
    SIDEBAR: 'SideBar',
    SELECTED: 'Selected',
    MOBILE_CLEAR_ALL: 'mobile clear all',
    MOBILE_FILTER: 'Filter modal',
    FILTER_RESULTS: 'Filter Results',
    SORT_BY: 'Sort by',
    BUSINESS_SIDE_FILTERS: 'Business Side Filters',
    EXPOSED_FILTERS: 'Exposed Filters',
};
