import React from 'react';
import { isEmpty } from 'lodash';
import { translate } from '@fiverr-private/i18n-react';
import { ROLLOUTS } from '@fiverr-private/listing_experiments';
import { SelectedFilters } from '../../../../common';
import { useBusinessListingsContext } from '../../Context';
import { reportClearFilterClickedToBigQuery } from '../NoResults/bqReports';
import styles from './index.module.scss';

const SelectedFiltersWrapper = () => {
    const {
        rollouts,
        resultsCount,
        activeFilters,
        subCategory,
        selectedFilters,
        isSearch,
        isExpertListings,
        managedAccount,
    } = useBusinessListingsContext();
    const { alias } = subCategory?.children.find(({ selected }) => selected) || {};
    const selectedScName = isSearch && alias;
    const inMustangsDefaultFiltersRollout = rollouts[ROLLOUTS.MUSTANGS_CUSTOM_DEFAULT_FILTERS] ?? false;

    if (isEmpty(selectedFilters) && !selectedScName) {
        return null;
    }

    const onClearFilterClick = () => {
        reportClearFilterClickedToBigQuery(
            isExpertListings,
            translate('search_perseus.filter.clear_filters'),
            resultsCount,
            managedAccount
        );
    };

    return (
        <div className={styles.container}>
            <SelectedFilters
                onClearFilterClick={onClearFilterClick}
                className={styles.selectedFilters}
                selectedFilters={selectedFilters}
                selectedScName={selectedScName}
                activeFilters={activeFilters}
                showClearAll={true}
                inMustangsDefaultFiltersRollout={inMustangsDefaultFiltersRollout}
            />
        </div>
    );
};

export default SelectedFiltersWrapper;
