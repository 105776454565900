import { string, arrayOf, bool } from 'prop-types';
import { biEnrichmentType } from '../ExpertCard/types.js';
import expertiseType from './ExpertiseTag/types.js';

export default {
    /**
     * The expertises the seller offer
     */
    expertises: arrayOf(expertiseType),
    /**
     * Custom className that is applied to the list
     */
    className: string,
    /**
     * Bi enrichment props
     */
    biEnrichment: biEnrichmentType,
    /**
     *
     */
    hasHourlyRate: bool,
};
