const CONTENT_TYPES = {
    GIG_LISTINGS_PAGE: 'GigListingsPage',
    GIG_CAROUSEL_CATALOG: 'gigsCarouselCatalog',
};

const TYPE = {
    MULTIPLE_FILTERS: 'multiple_filters',
    LANGUAGES_PAIR: 'languages_pair',
};

module.exports = {
    CONTENT_TYPES,
    TYPE,
};
