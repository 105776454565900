import React from 'react';
import {
    ActiveFilters,
    BreadcrumbsEntry,
    Filter,
    ManagedAccount,
    ScFilter,
    SearchQueryData,
    SelectedFilter,
    VisualFiltersCtx,
} from '../Types';

export interface BusinessListingsContextType {
    rollouts: Map<string, boolean>;
    resultsCount: number;
    isExpertListings: boolean;
    isSearch: boolean;
    filters: Filter[];
    activeFilters: ActiveFilters;
    visualFilters: VisualFiltersCtx;
    selectedFilters: SelectedFilter[];
    subCategory: ScFilter;
    subcategoryName: string;
    breadcrumbs: BreadcrumbsEntry[];
    hasItems: boolean;
    categoryIds: Map<string, string>;
    dominateSubCategoryId: number;
    significantLeafCategories: string[];
    displayQuery: string;
    searchQueryData: SearchQueryData;
    managedAccount: ManagedAccount;
}

export const BusinessListingsContext = React.createContext<BusinessListingsContextType | null>(null);

export const BusinessListingsContextProvider: React.FC<BusinessListingsContextType & { children: React.ReactNode }> = ({
    children,
    rollouts,
    resultsCount,
    isExpertListings,
    isSearch,
    filters,
    activeFilters,
    visualFilters,
    selectedFilters,
    subCategory,
    subcategoryName,
    breadcrumbs,
    hasItems,
    categoryIds,
    dominateSubCategoryId,
    significantLeafCategories,
    displayQuery,
    searchQueryData,
    managedAccount,
}) => (
    <BusinessListingsContext.Provider
        value={{
            rollouts,
            resultsCount,
            isExpertListings,
            isSearch,
            filters,
            activeFilters,
            visualFilters,
            selectedFilters,
            subCategory,
            subcategoryName,
            breadcrumbs,
            hasItems,
            categoryIds,
            dominateSubCategoryId,
            significantLeafCategories,
            displayQuery,
            searchQueryData,
            managedAccount,
        }}
    >
        {children}
    </BusinessListingsContext.Provider>
);
