import { ListingAttributes } from '@fiverr-private/listing_types';

export const listingAttributesStub: ListingAttributes = {
    id: '47ae2aa822bc49059b3dd6b346a4fdff',
    query: 'logo',
    sortBy: 'auto',
    activeFilters: {
        is_seller_online: 'true',
    },
    page: 1,
    pageSize: 48,
    offset: 0,
    utmCampaign: 'utmCampaign',
    utmMedium: 'utmMedium',
    utmSource: 'utmSource',
    assumedLanguage: 'en-US',
    currencyExchangeRate: 0.9676669947,
    categoryIds: {
        categoryId: '1',
        subCategoryId: '2',
        nestedSubCategoryId: '3',
    },
    isRncUser: false,
    shouldSuggest: true,
    platform: 'desktop',
    requestIp: '111.222.333',
    userAgent:
        'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36',
};
