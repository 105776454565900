import React, { useEffect } from 'react';
import { string } from 'prop-types';
import pathfinder from '@fiverr-private/pathfinder';
import { Button } from '@fiverr-private/fit';
import { I18n } from '@fiverr-private/fiverr_context';
import { reportClickBigQuery, reportImpBigQuery } from '../bqReports';

const PromoteYourGigBtn = ({ username = '', flow = '', promotionEncouragementStatus = '' }) => {
    const onPromoteClick = () => {
        reportClickBigQuery(flow, promotionEncouragementStatus);
    };

    useEffect(() => {
        reportImpBigQuery(flow, promotionEncouragementStatus);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Button
                className="promote-btn"
                onClick={onPromoteClick}
                href={pathfinder('user_promoted_gigs', { user_id: username })}
                target="_blank"
                size={Button.SIZES.SMALL}
            >
                <I18n k="search_perseus.promoted_gigs_banner.cta" />
            </Button>
        </>
    );
};

PromoteYourGigBtn.propTypes = {
    flow: string,
    promotionEncouragementStatus: string,
};

export default PromoteYourGigBtn;
