import { AxiosError } from 'axios';
import { RequestContext } from '@fiverr-private/perseus';
import { fetchGigsByGoSearcherResult } from '@fiverr-private/gig_listings/src/GigCardListings/sdk';
import { Gig, InitialProps, SearchPerseusConfig, isRawSearchResult } from '@fiverr-private/listing_types';
import { reportRequestCount, reportRequestTime } from '../../util/stats/reportRequest';
import { CannotFetchListingsData } from '../../errors';
import { normalizeCurrencyInActiveFilters } from '../../util/normalizeCurrencyInActiveFilters/normalizeCurrencyInActiveFilters';
import { buildTranslationConfig } from './utils/buildTranslationConfig';
import { enrichGigs } from './utils/enrichGigs';
import { getCarouselGigs } from './utils/getCarouselGigs';

export interface FetchGigsDataFromPhoenixResult {
    gigs: Gig[];
    translationStatus?: string;
}

const METRIC_ROUTE_KEY = 'gig_listings_package.fetch_gigs_by_go_searcher';

export const fetchGigsDataFromPhoenix = async (
    serviceConfigs: SearchPerseusConfig
): Promise<FetchGigsDataFromPhoenixResult> => {
    const {
        listings_service: listingsService,
        federation_gql_pegasus: federationGqlPegasus,
        machine_translation_pegasus: machineTranslationPegasus,
        gigs_phoenix: gigsPhoenix,
    } = serviceConfigs;

    const { initialProps, userId, abTests, locale, knownCrawler, isTouch, pageCtxId } = RequestContext;
    const { rawListingData, currency, activeFilters, listingAttributes, Flow } = initialProps as InitialProps;

    if (!isRawSearchResult(rawListingData)) {
        throw new Error('No rawListingData found in initialProps');
    }

    const {
        gigs,
        pricing_factor_skeleton: { pricing_factor_key: pricingFactorKey } = {},
        dominant_leaf_category_parent: dominantLeafCategoryParent,
    } = rawListingData;

    const normalizeActiveFilters = normalizeCurrencyInActiveFilters(activeFilters, currency.rate);

    const carouselGigs = getCarouselGigs(rawListingData);
    const listingGigs = gigs.concat(carouselGigs);

    const translationConfig = buildTranslationConfig({
        translationPegasusUrl: machineTranslationPegasus.url,
        gigsPhoenixServiceUrl: gigsPhoenix.url,
        translationPegasusTimeout: machineTranslationPegasus.timeout?.ugc,
    });

    const enrichedGigsForPhoenix = enrichGigs({
        gigs: listingGigs,
        sortBy: listingAttributes.sortBy,
        funnel: pageCtxId,
        dominantLeafCategoryParent,
        activeFilters: normalizeActiveFilters,
        locale,
        pricingFactorKey,
    });

    try {
        const fetchGigsInitTimestamp = Date.now();
        const result: FetchGigsDataFromPhoenixResult = await fetchGigsByGoSearcherResult({
            listingsPhoenixUrl: listingsService.url,
            federationPegasusUrl: federationGqlPegasus.url,
            federationPegasusTimeout: federationGqlPegasus.timeout?.fetch_sellers_data,
            gigs: enrichedGigsForPhoenix,
            userId,
            filter: listingAttributes.sortBy,
            hidePrefix: isTouch && !knownCrawler,
            translationConfig,
            abTests,
            requestOptions: {
                timeout: listingsService.timeout?.fetch_gigs_by_go_searcher,
            },
        });

        const responseTime = Date.now() - fetchGigsInitTimestamp;
        reportRequestTime('sdk', METRIC_ROUTE_KEY, responseTime);
        reportRequestCount('sdk', METRIC_ROUTE_KEY);

        return result;
    } catch (error) {
        if (error instanceof AxiosError) {
            reportRequestCount('sdk', METRIC_ROUTE_KEY, error.response?.status);
        }

        throw new CannotFetchListingsData(error as Error, listingAttributes, Flow?.flowName);
    }
};
