import React from 'react';
import { translate } from '@fiverr-private/i18n-react';
import { IconButton } from '@fiverr-private/button';
import { ArrowLeftIcon, ArrowRightIcon } from '@fiverr-private/icons';
import { grey_600, grey_900 } from '@fiverr-private/sass/helpers';

const PaginationArrow = ({
    iconComponent,
    isDisabled,
    url,
    ariaLabel,
}: {
    iconComponent: React.ReactNode;
    isDisabled?: boolean;
    url: string;
    ariaLabel: string;
}) => (
    <IconButton
        disabled={isDisabled}
        shape="circle"
        aria-label={ariaLabel}
        intent="secondary"
        variant="outline"
        {...(!isDisabled && { href: url })}
    >
        {iconComponent}
    </IconButton>
);

export const PaginationPrevious = ({ url, isDisabled }: { url: string; isDisabled?: boolean }) => (
    <PaginationArrow
        iconComponent={<ArrowLeftIcon color={isDisabled ? grey_600 : grey_900} />}
        isDisabled={isDisabled}
        url={url}
        ariaLabel={translate('listing_pagination.previous')}
    />
);

export const PaginationNext = ({ url, isDisabled }: { url: string; isDisabled?: boolean }) => (
    <PaginationArrow
        iconComponent={<ArrowRightIcon color={isDisabled ? grey_600 : grey_900} />}
        isDisabled={isDisabled}
        url={url}
        ariaLabel={translate('listing_pagination.next')}
    />
);
