export const filterAndSort = (filters, groupingKey) =>
    filters.filter(({ topGrouping }) => topGrouping === groupingKey).sort((a, b) => a.order - b.order);

export const getFilterDisplay = (filter, activeFilters) => ({
    title: filter.alias,
    active: isActive([filter], activeFilters),
});

export const isActive = (filters, activeFilters) =>
    filters.reduce((active, filter) => active || !!activeFilters[filter.id], false);
