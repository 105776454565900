import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { getContext } from '@fiverr-private/fiverr_context';
import { Carousel } from '@fiverr-private/orca';
import { AssetsCarousel } from '../../../AssetsCarousel';
import { scrollOnGigServiceCardImageEvent } from '../../../../utils/biEvents/events/gigCardImages';
import { useGigServiceCardLightbox } from '../../../GigServices/GigServiceCardLightbox/useGigServiceCardLightbox';
import { GigServiceCardLightbox } from '../../../GigServices/GigServiceCardLightbox';
import { transformToCamelCase } from '../../../../utils';
import { biEnrichmentType } from '../../types';
import { ARROW_MODE } from '../../../../shared/constants';

import './index.scss';
import { assetType, attachmentType, filteredDeliveryAttachmentType } from '../../../../shared/types';

export const MiniVisuals = ({
    className,
    biEnrichment,
    assets,
    deliveries,
    attachments,
    isHovered,
    disableSwipeMode = false,
    lazyLoadAssets,
}) => {
    const { userId, isTouch } = getContext();
    const slides = [...assets, ...deliveries];

    const { isPreviewOpened, sourceInitialIndex, setSourceInitialIndex, sources, closePreview, openPreview } =
        useGigServiceCardLightbox({ assets, attachments, filteredDeliveryAttachments: deliveries });

    const carouselScrollHandler = ({ currentPosition }) => {
        const gig = slides[currentPosition % slides.length].gig;
        setSourceInitialIndex(currentPosition);
        biEnrichment &&
            scrollOnGigServiceCardImageEvent({
                gigId: gig.id,
                target: 'scroll',
                sourceStr: 'carousel',
                position: currentPosition,
                userId,
                isSeller: true,
                sellerId: biEnrichment.seller.id,
                source: biEnrichment.params.event_source,
            });
    };

    const lighboxScrollHandler = () => {
        const gig = slides[sourceInitialIndex % slides.length].gig;
        scrollOnGigServiceCardImageEvent({
            gigId: gig.gig_id,
            target: 'scroll',
            sourceStr: 'Lightbox carousel',
            userId,
            isSeller: true,
            sellerId: biEnrichment.seller.id,
            source: biEnrichment.params.event_source,
        });
    };

    return (
        <>
            <AssetsCarousel
                className={classNames(className, 'gig-assets-carousel-small')}
                onScroll={carouselScrollHandler}
                assets={transformToCamelCase(assets)}
                attachments={transformToCamelCase(attachments)}
                filteredDeliveryAttachments={deliveries}
                isInfinite={false}
                isHovered={isHovered}
                arrowsDisplayMode={isTouch ? ARROW_MODE.ALWAYS : ARROW_MODE.HOVER}
                swipeMode={isTouch && !disableSwipeMode}
                swipeModeIndicator={Carousel.SWIPE_MODE_INDICATOR.DOTS}
                onAssetClick={(index) => {
                    setSourceInitialIndex(index);
                    openPreview();
                }}
                lazyLoad={lazyLoadAssets}
            />
            {isPreviewOpened && (
                <GigServiceCardLightbox
                    sources={sources}
                    sourceInitialIndex={sourceInitialIndex}
                    onScroll={lighboxScrollHandler}
                    onClose={closePreview}
                />
            )}
        </>
    );
};

MiniVisuals.propTypes = {
    className: PropTypes.string,
    biEnrichment: biEnrichmentType,
    assets: PropTypes.arrayOf(assetType),
    deliveries: PropTypes.arrayOf(filteredDeliveryAttachmentType),
    attachments: PropTypes.arrayOf(attachmentType),
    isHovered: PropTypes.bool,
    disableSwipeMode: PropTypes.bool,
    lazyLoadAssets: PropTypes.bool,
};
