import React from 'react';
import { BetaBadge } from '@fiverr-private/badges';
import { useOpenBriefCreationDrawer } from '@fiverr-private/dm_brief_creation_triggers';
import { I18n } from '@fiverr-private/i18n-react';
import { Card, Stack, Container } from '@fiverr-private/layout_components';
import { ClientInitialProps } from '@fiverr-private/listing_types';
import { Typography, Link, Text } from '@fiverr-private/typography';
import { ArrowRightIcon, DynamicBriefL2Icon } from '@fiverr-private/visuals';

export const GenerateBrief: React.FC<{ currency: ClientInitialProps['currency'] }> = ({ currency }) => {
    const { openBriefCreationDrawer } = useOpenBriefCreationDrawer();

    return (
        <Card background="linear-gradient(85.53deg, #FFFFFF 57.43%, #F8F9E5 114.23%)" borderRadius="lg" padding="6">
            <Stack direction="row" gap="4">
                <Stack direction="column" gap="2">
                    <Stack direction="row">
                        <Text fontWeight="bold">
                            <I18n k="listing_results.empty_results.empty_search_results.dynamic_matching.title" />
                        </Text>
                        <BetaBadge />
                    </Stack>
                    <Typography fontSize="b_sm" color="grey_1100">
                        <I18n k="listing_results.empty_results.empty_search_results.dynamic_matching.description" />
                    </Typography>
                    <Stack alignItems="center">
                        <Link
                            fontSize="b_sm"
                            fontWeight="semibold"
                            color="grey_1100"
                            cursor="pointer"
                            onClick={() => {
                                openBriefCreationDrawer({ currency, source: 'search_perseus' });
                            }}
                        >
                            <I18n k="listing_results.empty_results.empty_search_results.dynamic_matching.button" />
                        </Link>
                        <ArrowRightIcon />
                    </Stack>
                </Stack>
                <Container flexShrink="0">
                    <DynamicBriefL2Icon />
                </Container>
            </Stack>
        </Card>
    );
};
