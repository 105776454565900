import React from 'react';
import { Typography } from '@fiverr-private/typography';
import { I18n } from '@fiverr-private/i18n-react';

interface ActiveFiltersAliasProps {
    alias: string;
}

export const ActiveFilterAlias = ({ alias }: ActiveFiltersAliasProps) => {
    if (!alias) {
        return null;
    }

    return (
        <Typography as="span" fontSize="b_md" fontWeight="inherit">
            <I18n k="search_perseus.side_filters.active_filter_alias" params={{ alias }} />
        </Typography>
    );
};
