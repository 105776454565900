import { prepareFirstImageAsset } from '../../assetsHelper';

export const smallCardTransformer = (props) => {
    if (props.showTranslatedUGC && props.localizedAssets?.length) {
        return {
            ...props,
            asset: prepareFirstImageAsset(props.localizedAssets, props.isTouch, props.title),
        };
    }

    return {
        ...props,
        asset: prepareFirstImageAsset(props.assets, props.isTouch, props.title),
    };
};
