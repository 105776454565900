import React from 'react';
import { RecommendationsCarouselsTypes } from '../../../../types';
import ErrorBoundary from '../../errors/error_boundary/ErrorBoundary';
import RecentlyViewedCarousel from './recently_viewed/RecentlyViewedCarousel';
import './index.scss';

const RecommendationsCarousels = ({ sorting, activeFilters, loggedIn, shouldFetchData, personalizedContent }) => (
    <div className="recommendations-carousels">
        <ErrorBoundary>
            <RecentlyViewedCarousel
                sorting={sorting}
                activeFilters={activeFilters}
                loggedIn={loggedIn}
                shouldFetchData={shouldFetchData}
                personalizedContent={personalizedContent}
            />
        </ErrorBoundary>
    </div>
);

RecommendationsCarousels.propTypes = RecommendationsCarouselsTypes;

export default RecommendationsCarousels;
