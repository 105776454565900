import React from 'react';
import { Icon } from '@fiverr-private/fit';
import { s_times_circle } from '@fiverr-private/icons';
import { I18n } from '@fiverr-private/i18n-react';
import { red_1100 } from '@fiverr-private/sass/helpers';

const Inactive = () => (
    <>
        <Icon className={'seller-highlight-icon'} color={red_1100}>
            {s_times_circle}
        </Icon>
        <I18n k={'listings.highlights.inactive'} />
    </>
);

export default Inactive;
