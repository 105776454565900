import React from 'react';
import { Image } from '@fiverr-private/media';
import { Container } from '@fiverr-private/layout_components';
import { Heading, Text } from '@fiverr-private/typography';

interface HighlightedRecommendationsHeaderProps {
    iconPath: string;
    title: string;
    description: string;
}

export const HighlightedRecommendationsHeaderContent: React.FC<HighlightedRecommendationsHeaderProps> = ({
    iconPath,
    title,
    description,
}) => (
    <>
        <Image src={iconPath} alt={title} width="54px" height="54px" />
        <Container className="header" gap="1" marginRight="4">
            <Heading as="h4">{title}</Heading>
            <Text>{description}</Text>
        </Container>
    </>
);
