import React from 'react';
import { Heading } from '@fiverr-private/typography';

interface MetadataFilterCarouselTitleProps {
    title: string;
}

export const MetadataFilterCarouselTitle = ({ title }: MetadataFilterCarouselTitleProps) => (
    <Heading size="h_sm" fontWeight="bold" as="h4">
        {title}
    </Heading>
);
