import React from 'react';
import { bool, shape, object } from 'prop-types';
import { ChevronRightIcon, ChevronLeftIcon } from '@fiverr-private/icons';
import { Icon } from '@fiverr-private/fit';
import { grey_1100 } from '@fiverr-private/sass/helpers';

export const CarouselNavigation = ({ shouldDisplayNavigation, canScrollLeft, canScrollRight, navigationRef }) => {
    if (!shouldDisplayNavigation) {
        return null;
    }

    return (
        <div className="tiles-carousel-navigation">
            <button
                className="tiles-carousel-arrow p-0"
                disabled={!canScrollLeft}
                onClick={() => navigationRef.current.scrollLeft()}
            >
                <Icon className="tiles-carousel-arrow-icon" color={grey_1100} size={12}>
                    <ChevronLeftIcon />
                </Icon>
            </button>
            <button
                className="tiles-carousel-arrow p-0"
                disabled={!canScrollRight}
                onClick={() => navigationRef.current.scrollRight()}
            >
                <Icon className="tiles-carousel-arrow-icon" color={grey_1100} size={12}>
                    <ChevronRightIcon />
                </Icon>
            </button>
        </div>
    );
};

CarouselNavigation.propTypes = {
    shouldDisplayNavigation: bool,
    canScrollLeft: bool,
    canScrollRight: bool,
    navigationRef: shape({ current: object }),
};
