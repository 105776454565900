import React, { useState } from 'react';
import { logger } from '@fiverr-private/obs';
import { LazyComponent } from '@fiverr-private/orca';
import { getContext } from '@fiverr-private/fiverr_context';
import { SHOW_PRO_ORIENTED_SEARCH_MODAL_INFO } from '../../../../../shared/constants/proSearchModal';
import { storage } from './utils';

const lazyImport = () => import(/* webpackChunkName: 'ProOrientedSearchModal' */ './ProOrientedSearchModal');

export const ProOrientedSearchModal = () => {
    const [isOpen, setIsOpen] = useState(true);
    const { queryParameters, userId } = getContext();

    if (!queryParameters[SHOW_PRO_ORIENTED_SEARCH_MODAL_INFO] || !userId || storage.isModalDismissed() || !isOpen) {
        return null;
    }

    const onClose = () => {
        setIsOpen(false);
        storage.setModalDismissed();
    };

    return (
        <LazyComponent
            lazyImport={lazyImport}
            componentProps={{ onClose }}
            onError={(err) => logger.error('Failed to load ProOrientedSearchModal', err)}
        />
    );
};
