import React from 'react';
import { bool, node, object, string } from 'prop-types';
import Arrows from '../Arrows';

export const ListingCarouselHeader = ({
    headerComponent,
    canScrollLeft,
    canScrollRight,
    displayScroll,
    navigationRef,
    arrowsWrapperClassName,
}) => (
    <header className="listing-carousel-header m-b-24">
        {headerComponent}
        {displayScroll && (
            <div className="m-l-24">
                <Arrows
                    navigationRef={navigationRef}
                    canScrollLeft={canScrollLeft}
                    canScrollRight={canScrollRight}
                    iconSize={12}
                    className={arrowsWrapperClassName}
                />
            </div>
        )}
    </header>
);

ListingCarouselHeader.propTypes = {
    headerComponent: node,
    canScrollLeft: bool,
    canScrollRight: bool,
    displayScroll: bool,
    navigationRef: object,
    arrowsWrapperClassName: string,
};
