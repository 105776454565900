export const BI_CONSTS = {
    BUTTON: 'button',
    LINK: 'link',
    CHECKBOX: 'checkbox',
    USER_ACTIONS: 'user_actions',
    CLICKED_ON_FILTER_GROUP: 'clicked_on_filter_group',
    CLICKED_ON_DRAWER_GROUP: 'clicked_on_drawer_group',
    WEBSITE_ACTIONS: 'website_actions',
    BUYERS: 'buyers',
    CATERPILLARS: 'caterpillars',
    CLICK: 'click',
    HOVER: 'hover',
    MIXPANEL_PAGE_NAME: 'Sub Category',
    BIGQUERY_PAGE_NAME: 'sub_category',
    DRAWER: 'drawer',
};
