import { URI } from '@fiverr-private/futile';
import { sendBiClickEvent } from '../../../../generic_listings/TagFilters/utils';
import { BI_ELEMENTS_NAMES, ELEMENT_TYPE } from '../../../../generic_listings/TagFilters/constants';
import { clearFiltersUrl } from '../../../../../service/clientNavigation';

const SEARCH_CHECKBOX_FILTERS_IDS = ['seller_language', 'seller_location', 'language'];

export const SUBCATEGORY_FILTER_VISUAL_URL_PARAM = 'is_scv';

export const shouldConvertFilterToSearch = ({ id }) => SEARCH_CHECKBOX_FILTERS_IDS.includes(id);

export const removeSubcategoryFilter = () => {
    if (!window?.location) {
        return;
    }

    window.location.href = clearFiltersUrl();
};

export const applyLeafCategoryFilterToUrl = (url, params, isVisualFilter = false) => {
    const uri = new URI(url);

    const { params: urlParams } = uri;
    const elementMetadata = params.nested_sub_category || params.sub_category;

    sendBiClickEvent(BI_ELEMENTS_NAMES.LEAF_CATEGORY, elementMetadata, ELEMENT_TYPE.TAGS);

    Object.entries(params).forEach(([key, value]) => {
        if (urlParams[key]) {
            uri.removeParams(key);
        }

        uri.addParams({ [key]: value });
    });

    if (!params.nested_sub_category && !!urlParams.nested_sub_category) {
        uri.removeParams('nested_sub_category');
    }

    if (isVisualFilter) {
        uri.addParams({ [SUBCATEGORY_FILTER_VISUAL_URL_PARAM]: true });
    }

    return uri.href;
};

export const navigateLeafCategory = (params, isItemSelected, isVisualFilter) => {
    if (isItemSelected) {
        removeSubcategoryFilter();
        return;
    }

    window.location.href = applyLeafCategoryFilterToUrl(window.location.href, params, isVisualFilter);
};

export const isSubCategory = (obj) => !!obj.selectedScName;

export const isSubcategoryFilterActive = (activeFilters) => !!activeFilters && !!activeFilters.leaf_category;
