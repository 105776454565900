import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import Toggler from '@fiverr/ui_toggle';
import { URI } from '@fiverr-private/futile';
import { I18n } from '@fiverr-private/i18n-react';
import { getContext } from '@fiverr-private/fiverr_context';
import { Icon } from '@fiverr-private/fit';
import { CheckIcon, ChevronDownThickIcon } from '@fiverr-private/icons';
import { FILTER, tracker, EVENTS_MAPPING, CATEGORY_TYPE } from '@fiverr-private/listing_lib';
import { Button } from '@fiverr-private/button';
import { AppContext } from '../../../context/listingsContext';
import number from '../../../../../../shared/utils/formatting/number';
import { BI_SOURCES } from '../../../../utils/constants';
import { objectValues } from '../../../../utils';
import { getDropdownFiltersGroupName } from '../../listings/impressionEnrichment';
import { setLastlyAppliedFiltersToLocalStorage } from '../../../../service/clientNavigation';
import { TOP_GROUPING } from '../../../../config/filters';

import './index.scss';

const DEFAULT_PROPS = {
    headAsLink: true,
};

export class LeafCategoriesFilter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: this.getSelected(),
        };

        this.handleLinkClick = this.handleLinkClick.bind(this);
        this.buildItemUrl = this.buildItemUrl.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.data !== prevProps.data) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                selected: this.getSelected(),
            });
        }
    }

    getSelected() {
        const { alias, children = {}, headAsLink } = this.props.data;
        const selectedChild = objectValues(children).filter((child) => child.selected)[0];

        return selectedChild && headAsLink ? selectedChild.alias : alias;
    }

    buildItemUrl({ params }) {
        const { url = '' } = getContext();

        const uri = new URI(url);
        const { search_in, sub_category, nested_sub_category } = params;

        uri.addParams({
            search_in,
            source: BI_SOURCES.DROPDOWN_FILTERS,
        });

        sub_category
            ? uri.addParams({ [CATEGORY_TYPE.SUB_CATEGORY]: sub_category })
            : uri.removeParams(CATEGORY_TYPE.SUB_CATEGORY);

        nested_sub_category
            ? uri.addParams({ [CATEGORY_TYPE.NESTED_SUB_CATEGORY]: nested_sub_category })
            : uri.removeParams(CATEGORY_TYPE.NESTED_SUB_CATEGORY);

        return uri.href;
    }

    handleLinkClick(e, { params }, alias) {
        if (this.state.selected === alias) {
            e.preventDefault && e.preventDefault();
            return;
        }

        const { sub_category, nested_sub_category } = params;

        const payload = {
            location: 'Top Filter',
            alias,
            id: nested_sub_category || sub_category,
        };

        tracker.trackLeafCategoryFilter(EVENTS_MAPPING.FILTERS_APPLY, payload);

        const change = {
            leaf_category: [nested_sub_category || sub_category],
        };

        setLastlyAppliedFiltersToLocalStorage(change, BI_SOURCES.DROPDOWN_FILTERS, TOP_GROUPING.CATEGORY);
    }

    onOpen() {
        const groupName = getDropdownFiltersGroupName(TOP_GROUPING.CATEGORY);
        tracker.click(groupName);
        tracker.trackFilterGroupOpen(groupName);
    }

    shouldShowFilter({ children }) {
        const { filters = [] } = this.props;
        const pricingFactorFilter = filters.find(({ id }) => id === FILTER.PRICING_FACTOR.ID) || {};

        return children && isEmpty(pricingFactorFilter.options);
    }

    render() {
        const data = {
            ...DEFAULT_PROPS,
            ...this.props.data,
        };

        const { alias, children, count, headAsLink } = data;

        if (!this.shouldShowFilter({ children })) {
            return null;
        }

        const isFilteredBySubCategory = children.some((child) => child.selected);

        return (
            <AppContext.Consumer>
                {({ inOwlsSideFiltersTest }) => {
                    if (inOwlsSideFiltersTest) {
                        return null;
                    }

                    return (
                        <Toggler
                            ref={(toggler) => (this.toggler = toggler)}
                            togglerWillOpen={this.syncValues}
                            togglerDidOpen={this.onOpen}
                        >
                            {({ isOpen, toggle, preventCloseAction }) => (
                                <div className={'top-filters-subcategories-filter as-filter'} ref={preventCloseAction}>
                                    <Button
                                        size="lg"
                                        variant="outline"
                                        className={classNames({ 'filtered-sc': isFilteredBySubCategory })}
                                        intent="secondary"
                                        onClick={toggle}
                                    >
                                        <I18n k="search_perseus.filter_builder.sub_category.category" />
                                        <Icon size={12} className="chevron-icon-down">
                                            <ChevronDownThickIcon />
                                        </Icon>
                                    </Button>
                                    <ul className={classNames('subcategories-list', { 'is-open': isOpen })}>
                                        <li className={classNames('item', { selected: !headAsLink })}>
                                            <Icon className={'item-check'} size={14}>
                                                <CheckIcon />
                                            </Icon>
                                            {
                                                <a
                                                    href={this.buildItemUrl(data)}
                                                    onClick={(e) => this.handleLinkClick(e, data, alias)}
                                                >
                                                    {alias}
                                                </a>
                                            }
                                            {count && <span>({number(count)})</span>}
                                        </li>

                                        {children.map((child) => (
                                            <React.Fragment key={child.alias}>
                                                <li
                                                    className={classNames('item', {
                                                        selected: headAsLink && child.selected,
                                                    })}
                                                >
                                                    <Icon className="item-check" size={14}>
                                                        <CheckIcon />
                                                    </Icon>
                                                    <a
                                                        href={this.buildItemUrl(child)}
                                                        onClick={(e) => this.handleLinkClick(e, child, child.alias)}
                                                    >
                                                        {child.alias}
                                                    </a>
                                                    {child.count && (
                                                        <span className="item-count"> ({number(child.count)}) </span>
                                                    )}
                                                </li>
                                            </React.Fragment>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </Toggler>
                    );
                }}
            </AppContext.Consumer>
        );
    }
}

LeafCategoriesFilter.propTypes = {
    data: PropTypes.shape({
        alias: PropTypes.string.isRequired,
        count: PropTypes.number,
        children: PropTypes.array,
        globalFiltersQuery: PropTypes.string,
        headAsLink: PropTypes.bool,
    }),
    filters: PropTypes.object,
};
