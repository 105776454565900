import React from 'react';
import { object, string } from 'prop-types';
import { TILE_TYPES } from '../../tiles_carousel/constants';
import { getNscImagePath, handleClick, urlBuilder } from './utils';

import '../index.scss';

const NSC_PATH = 'nsc_tiles_carousel';

const NscTile = ({ id, alias, url, src, params = {} }) => {
    const href = urlBuilder(url, params);

    return (
        <a href={href} className={TILE_TYPES.CHIP_TILE} onClick={() => handleClick(NSC_PATH, id)}>
            <div className="chip-tile-icon-wrapper">
                <img src={src || getNscImagePath(id)} alt={alias} loading="lazy" />
            </div>
            <span className="chip-tile-text tbody-5">{alias}</span>
        </a>
    );
};

NscTile.propTypes = {
    id: string,
    alias: string,
    url: string,
    src: string,
    params: object,
};

export default NscTile;
