export const FILTERS_DISPLAY_MEDIA_MAP = {
    XL: 1200,
    LG: 1080,
    MD: 760,
    SM: 600,
};

export const FILTER_DISPLAY_TYPES = {
    CHECKBOX: 'checkbox',
    CHECKBOX_GROUP: 'checkbox_group',
    RADIO: 'radio',
    INPUT_RANGE: 'input_range',
    PRO: 'pro',
    AUTO_COMPLETE: 'autocomplete',
    MULTI_PAIRS_SELECT: 'multi_pairs_select',
};

export const MENU_CONTENT_TYPES = {
    CHECKBOX_LIST: 'checkbox-list',
    RADIO_LIST: 'radio-list',
    FILTERS_GROUP: 'filters-group',
    RANGE: 'range',
    BUDGET: 'budget',
    LABELS_LIST: 'labels-list',
    MULTI_PAIRS_SELECT: 'multi-pairs-select',
};

export const FILTER_MENU_CONTENT_MAPPING = {
    [FILTER_DISPLAY_TYPES.CHECKBOX_GROUP]: MENU_CONTENT_TYPES.CHECKBOX_LIST,
    [FILTER_DISPLAY_TYPES.AUTO_COMPLETE]: MENU_CONTENT_TYPES.CHECKBOX_LIST,
    [FILTER_DISPLAY_TYPES.MULTI_PAIRS_SELECT]: MENU_CONTENT_TYPES.MULTI_PAIRS_SELECT,
    [FILTER_DISPLAY_TYPES.CHECKBOX]: MENU_CONTENT_TYPES.CHECKBOX,
    [FILTER_DISPLAY_TYPES.PRO]: MENU_CONTENT_TYPES.CHECKBOX,
    [FILTER_DISPLAY_TYPES.RADIO]: MENU_CONTENT_TYPES.RADIO_LIST,
    [FILTER_DISPLAY_TYPES.INPUT_RANGE]: MENU_CONTENT_TYPES.RANGE,
};

export const MAX_GIG_PRICE_RANGE = 50000;
export const MIN_GIG_PRICE_RANGE = 0;
