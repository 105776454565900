import React, { Fragment } from 'react';
import { bool, func } from 'prop-types';
import { I18n } from '@fiverr-private/i18n-react';
import recentlyViewedService from '../../../../../service/recentlyViewed';

export const RecentlyViewedCarouselHeader = ({ clearGigs, showSeeAll }) => (
    <div className="recently-viewed-carousel-header flex-between flex-items-center">
        <h4>
            <I18n k="search_perseus.browsing_history_carousel.title" />
        </h4>
        <span className="buttons-wrapper">
            <button className="tbody-5" onClick={clearGigs}>
                <I18n k="search_perseus.browsing_history_carousel.clear_all" />
            </button>
            {showSeeAll && (
                <Fragment>
                    <span className="separator m-l-24 m-r-24">|</span>
                    <a className="tbody-5" href={recentlyViewedService.recentlyViewedPageURL()}>
                        <I18n k="search_perseus.browsing_history_carousel.see_all" />
                    </a>
                </Fragment>
            )}
        </span>
    </div>
);

RecentlyViewedCarouselHeader.propTypes = {
    clearGigs: func,
    showSeeAll: bool,
};
