import React from 'react';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';
import { Radio } from '@fiverr-private/forms';
import { invokeApplyEvent, useSidebarFiltersContext, Triggers } from '../../Context';
import Types from '../../types';
import styles from './index.module.scss';

const DEFAULT_RADIO_ID = '';

const RadioList = ({ filter, activeFilters }) => {
    const { applyEvent, newActiveFilters } = useSidebarFiltersContext();

    const { options } = filter;

    const radioName = `sidebar-filter-${filter.id}-radio-${uuid()}`;

    const radioChanged = (optionId) => {
        const activeFiltersToChange = { ...(newActiveFilters || activeFilters || {}) };

        if (optionId) {
            activeFiltersToChange[filter.id] = optionId;
        } else {
            activeFiltersToChange[filter.id] = '';
        }

        invokeApplyEvent(applyEvent, activeFiltersToChange, Triggers.FILTER_COMPONENT_CLICK);
    };

    return (
        <div className={classNames(styles.container, 'p-b-24')}>
            {options.map(({ id, alias }) => {
                const isDefaultId = id === DEFAULT_RADIO_ID;
                const defaultSelected = !newActiveFilters[filter.id];

                const selected = newActiveFilters[filter.id] === id || (isDefaultId && defaultSelected);

                return (
                    <div key={`sidebar-filter-${filter.id}-radio-option-${id}`} className={'p-b-8'}>
                        <Radio
                            name={radioName}
                            onChange={() => radioChanged(id)}
                            className={classNames(styles.radioContainer, 'tbody-6')}
                            checked={selected}
                        >
                            {alias}
                        </Radio>
                    </div>
                );
            })}
        </div>
    );
};

RadioList.propTypes = {
    filter: Types.filter.isRequired,
    activeFilters: Types.activeFilters,
};

export default RadioList;
