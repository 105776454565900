import React, { useContext, useState } from 'react';
import { array, func } from 'prop-types';
import { applyFilter } from '../../../filter_tile/utils';
import { CarouselContext } from '../../../../top_filters_carousel';
import { AppContext } from '../../../../../context/listingsContext';
import Header from '../shared/header';
import Footer from '../shared/footer';
import List from '../shared/list';

const FilterListModal = ({ close, items }) => {
    const [selected, setSelected] = useState([]);
    const { filterId, subCategoryId, hasNextStep, setStepIndex, stepIndex, stepNum } = useContext(CarouselContext);
    const { activeFilters } = useContext(AppContext);
    const titleKey = `search_perseus.filter_tiles.${subCategoryId}.${filterId}`;

    const onSelect = ({ item, checked }) => {
        const newSelected = checked ? [...selected, item.id] : selected.filter((value) => value !== item.id);
        setSelected(newSelected);
    };

    const onApply = () => {
        if (selected.length) {
            hasNextStep && setStepIndex(stepIndex + 1);

            applyFilter({
                filterId,
                value: selected,
                activeFilters,
                stepNum,
            });
        }

        close();
    };

    const onClear = () => {
        setSelected([]);
    };

    return (
        <>
            <Header close={close} titleKey={titleKey} />
            <List items={items} selected={selected} onSelect={onSelect} isMulti />
            <Footer onApply={onApply} onClear={onClear} />
        </>
    );
};

FilterListModal.propTypes = {
    close: func,
    items: array,
};

export default FilterListModal;
