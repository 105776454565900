import { getContext } from '@fiverr-private/fiverr_context';
import { metricsService } from '@fiverr-private/listing_lib';
import {
    BI_EVENTS_SUB_GROUPS,
    BI_EVENTS_ENTITY,
    BI_EVENT_GROUPS,
    BI_PAGE_NAMES,
    BI_ACTION_TYPES,
} from '../../../../../shared/services/BIEvents/constants';
import { QUERY_MODIFICATION_REASONS } from '../../../utils/constants.js';
import { REDIRECT_QUERY_BI_EVENT, SPELLCHECK_QUERY_BI_EVENT } from './constants';

const reportSemanticSearchRendered = () => metricsService.count('listings.search_header.semantic_search_component');

const MODIFICATION_QUERY_EVENT_MAPPER = {
    [QUERY_MODIFICATION_REASONS.SPELLCHECK]: SPELLCHECK_QUERY_BI_EVENT,
    [QUERY_MODIFICATION_REASONS.SEMANTIC_REDIRECT]: REDIRECT_QUERY_BI_EVENT,
};
const buildEventEnrichmentObject = (displayQuery, reasonForModification) => {
    const { pageCtxId, userId, userGuid, queryParameters: { ref_ctx_id: refCtxId } = {} } = getContext();
    const modificationEvent = MODIFICATION_QUERY_EVENT_MAPPER[reasonForModification] || {};

    return {
        type: modificationEvent.TYPE,
        action: {
            type: BI_ACTION_TYPES.IMPRESSION,
        },
        group: BI_EVENT_GROUPS.USER_IMPRESSION,
        sub_group: BI_EVENTS_SUB_GROUPS.CONTENT_IMPRESSION,
        entity: BI_EVENTS_ENTITY.CLIENT,
        uid: userGuid,
        user: {
            id: userId,
        },
        page: {
            name: BI_PAGE_NAMES.LISTINGS,
            ctx_id: pageCtxId,
            element: {
                name: displayQuery,
            },
            referrer: {
                ctx_id: refCtxId,
            },
            component: {
                name: modificationEvent.COMPONENT_NAME,
            },
        },
    };
};

export { reportSemanticSearchRendered, buildEventEnrichmentObject };
