const { gql, GraphQLClient } = require('graphql-request');
const { TRANSLATION_LAYERS_FETCH_TIMEOUT } = require('../constants');

const GET_TRANSLATION_LAYERS = gql`
    query GetTranslationLayers($gigIds: [ID!]!) {
        gigs(ids: $gigIds) {
            id
            localizedTitle {
                localized
            }
            attachments {
                ... on LocalizedGigAttachment {
                    id
                    type
                    previewUrls(transformations: [GIG_ATTACHMENT_MEDIUM_PREVIEW]) {
                        transformation
                        url
                    }
                    mediaUrl
                }
            }
        }
    }
`;

const getTranslationLayers = async (gigsPhoenixUrl, gigIds, timeout = TRANSLATION_LAYERS_FETCH_TIMEOUT) => {
    const client = new GraphQLClient(gigsPhoenixUrl, { timeout });
    return client.request(GET_TRANSLATION_LAYERS, { gigIds });
};

module.exports = {
    getTranslationLayers,
};
