import { useCallback, useState } from 'react';
import { useEventListener, useIsomorphicLayoutEffect } from '@fiverr-private/hooks';

export const useElementScrollable = (element: HTMLElement | null) => {
    const [canScroll, setCanScroll] = useState([false, false]);
    const [canScrollLeft, canScrollRight] = canScroll;

    const handleScroll = useCallback(() => {
        if (!element) {
            return;
        }
        const canScrollLeft = element.scrollLeft > 0;
        const canScrollRight = element.scrollWidth - element.clientWidth > element.scrollLeft;
        setCanScroll([canScrollLeft, canScrollRight]);
    }, [element]);

    const handleScrollInitial = useCallback(() => {
        if (canScrollLeft || canScrollRight) {
            return;
        }

        handleScroll();
    }, [canScrollLeft, canScrollRight, handleScroll]);

    useEventListener('scroll', handleScroll, element);
    useEventListener('resize', handleScrollInitial, typeof window !== 'undefined' ? window : null);

    useIsomorphicLayoutEffect(() => {
        handleScroll();
    }, [element]);

    return {
        canScrollLeft,
        canScrollRight,
    };
};
