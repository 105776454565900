import React from 'react';
import { getContext } from '@fiverr-private/fiverr_context';
import { MAX_ITEMS_TO_SHOW_NO_RESULTS } from '../../../../constants';
import { useBusinessListingsContext } from '../Context';
import SelectedFiltersWrapper from './SelectedFiltersWrapper';
import NoResults from './NoResults';
import ResultsCounter from './ResultsCounter';
import styles from './index.module.scss';

const ItemsContainer = ({ children }) => {
    const { resultsCount, isExpertListings } = useBusinessListingsContext();

    const { isMobile } = getContext();
    const showNoResults = (isExpertListings && resultsCount <= MAX_ITEMS_TO_SHOW_NO_RESULTS) || resultsCount < 1;

    return (
        <div className={styles.itemsContainer}>
            {!isMobile && <SelectedFiltersWrapper />}
            {!!resultsCount && (
                <div className="m-b-8 m-t-6">
                    <ResultsCounter />
                </div>
            )}
            {children}
            {showNoResults && <NoResults />}
        </div>
    );
};

export default ItemsContainer;
