import React from 'react';
import { Container, Stack } from '@fiverr-private/layout_components';
import { Link, Text } from '@fiverr-private/typography';
import { I18n } from '@fiverr-private/i18n-react';
import { ChevronLeftIcon, ChevronRightIcon } from '@fiverr-private/icons';
import { grey_900 } from '@fiverr-private/sass/helpers';
import { PaginationProps } from '../types';
import { getPaginationConfig } from '../utils';
import { bringArrowCloser } from './MobilePagination.ve.css';

const DoubleChevronLeftIcon = () => (
    <Container display="flex">
        <Container as="span" display="flex" alignItems="center">
            <ChevronLeftIcon color={grey_900} />
        </Container>
        <Container as="span" display="flex" alignItems="center" className={bringArrowCloser}>
            <ChevronLeftIcon color={grey_900} />
        </Container>
    </Container>
);

export const MobilePagination = (props: PaginationProps) => {
    const { page: currentPage } = props.pagination;
    const {
        previousPageButtonRelevant,
        previousPageUrl,
        nextPageButtonRelevant,
        nextPageUrl,
        firstPageButtonRelevant,
        firstPageUrl,
    } = getPaginationConfig(props);

    return (
        <Stack gap="0" alignItems="center" justifyContent="center" position="relative">
            {firstPageButtonRelevant && (
                <Link paddingX="1" href={firstPageUrl} position="absolute" left="-34px">
                    <DoubleChevronLeftIcon />
                </Link>
            )}
            <Stack gap="10" alignItems="center">
                <Link
                    visibility={previousPageButtonRelevant ? 'visible' : 'hidden'}
                    {...(previousPageButtonRelevant && { href: previousPageUrl })}
                >
                    <Container display="flex">
                        <ChevronLeftIcon color={grey_900} />
                    </Container>
                </Link>
                <Text>
                    <I18n k="listing_pagination.page" params={{ page_number: currentPage }} />
                </Text>
                <Link
                    visibility={nextPageButtonRelevant ? 'visible' : 'hidden'}
                    {...(nextPageButtonRelevant && { href: nextPageUrl })}
                >
                    <Container display="flex">
                        <ChevronRightIcon color={grey_900} />
                    </Container>
                </Link>
            </Stack>
        </Stack>
    );
};
