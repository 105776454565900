import { filterSelectorsMap } from './filter';

const MULTI = 'multi';
const SINGLE = 'single';
const PAIR = 'pair';

export const SELECTORS = {
    ...filterSelectorsMap,
    origin: SINGLE,
    isPair(id) {
        return this[id] !== undefined ? this[id] === PAIR : false;
    },
    isMulti(id) {
        return this[id] !== undefined ? this[id] === MULTI : true;
    },
    getType(id) {
        if (this.isPair(id)) {
            return this.types.PAIR;
        }

        if (this.isMulti(id)) {
            return this.types.MULTI;
        }

        return this.types.SINGLE;
    },
    types: {
        MULTI,
        SINGLE,
        PAIR,
    },
};
