import React from 'react';
import { string, object, bool } from 'prop-types';
import classNames from 'classnames';
import { I18n } from '@fiverr-private/i18n-react';
import Badge from '../../../Components/Badge';
import { SELLER_LEVELS } from '../../../../../GigCardListings/utils/gigBadges/constants';
import ContextualLink from '../../../../Components/ContextualLink';
import SellerCountryFlag from '../../../../Components/SellerCountryFlag';
import { createClickedElement, trackGigForClickType } from '../../../../utils';
import { useCardContext } from '../../../../../GigCardListings/context/CardContext';
import { ELEMENTS_NAME, ELEMENTS_TYPE } from '../../../../Components/constants';

import styles from './index.module.scss';

const clickedElement = (name) => createClickedElement(ELEMENTS_NAME.SELLER_TITLE, ELEMENTS_TYPE.LINK, name);
const SellerDetails = ({
    name: username,
    displayName,
    badge,
    level,
    url,
    urlTarget,
    isPromotedGig,
    country,
    showCountryFlag = false,
}) => {
    const {
        enrichedTracker: { trackEvent },
    } = useCardContext();
    const isNoPadding = !badge.type && level !== SELLER_LEVELS.TOP_RATED_SELLER;
    const name = displayName ? displayName : username;

    return (
        <div className={classNames(styles.sellerDetails, 'p-t-4')}>
            <div className={classNames('tbody-6', 'co-grey-1200', 'flex', 'flex-items-center')}>
                {isPromotedGig && (
                    <span className={classNames('text-normal', styles.promotedGigText)}>
                        <I18n k="gig_cards.badges.promoted_gigs.seller_name_prefix.ad_by" />
                        &nbsp;
                    </span>
                )}
                <ContextualLink
                    className={classNames(styles.sellerName, 'text-trunc', 'text-bold')}
                    onMouseDown={(e) => trackGigForClickType(e.button, trackEvent, clickedElement(name))}
                    href={url}
                    target={urlTarget}
                    rel="nofollow noopener noreferrer"
                >
                    <span title={name}>{name}</span>
                </ContextualLink>
                {showCountryFlag && <SellerCountryFlag country={country} className="m-l-8" />}
            </div>
            <Badge
                level={level}
                wrapperClassName={styles.badge}
                className={classNames({ 'p-0': isNoPadding })}
                {...badge}
            />
        </div>
    );
};

SellerDetails.propTypes = {
    name: string,
    displayName: string,
    badge: object,
    level: string,
    url: string,
    urlTarget: string,
    isPromotedGig: bool,
    country: string,
    showCountryFlag: bool,
};

export default SellerDetails;
