import React, { useContext } from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import { CarouselContext } from '.';

const Title = () => {
    const { filterId, subCategoryId } = useContext(CarouselContext);

    return (
        <div className="title">
            <h6 className="tbody-3">
                <I18n k={`search_perseus.filter_tiles.${subCategoryId}.${filterId}`} />
            </h6>
        </div>
    );
};

export default Title;
