import React, { useEffect, useRef, useState } from 'react';
import { bool, func, string } from 'prop-types';
import { isNil } from 'lodash';
import { logger } from '@fiverr-private/obs';
import { I18n } from '@fiverr-private/i18n-react';

const BASE_KEY = 'listings.user_activation_modal.contact_seller.description';

const UserActivationModalWrapper = ({ renderAndOpen, onClose, sellerName, source }) => {
    const userActivationPackageRef = useRef();
    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(true);

    const modalHasBeenLoaded = !isNil(userActivationPackageRef.current);
    const UserActivationModal = modalHasBeenLoaded ? userActivationPackageRef.current : EmptyComponent;

    useEffect(() => {
        const lazyLoadModal = () =>
            import(/* webpackChunkName: 'UserActivationModal' */ '@fiverr-private/user_activation')
                .then(({ UserActivationModal }) => (userActivationPackageRef.current = UserActivationModal))
                .catch((error) => logger.error(error));

        const render = () => {
            if (modalHasBeenLoaded) {
                openModal();
            } else {
                // eslint-disable-next-line promise/catch-or-return
                lazyLoadModal().then(() => openModal());
            }
        };

        if (renderAndOpen) {
            render();
        }
    }, [renderAndOpen, modalHasBeenLoaded]);

    const handleClose = () => {
        onClose();
        setIsOpen(false);
    };

    const props = {
        isOpen,
        onClose: handleClose,
        pageName: source,
        description: <I18n k={BASE_KEY} params={{ sellerName }} />,
    };

    return <UserActivationModal {...props} />;
};

const EmptyComponent = () => null;

UserActivationModalWrapper.propTypes = {
    renderAndOpen: bool,
    onClose: func,
    sellerName: string,
    source: string,
};

export default UserActivationModalWrapper;
