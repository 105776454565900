import { get, maxBy, take } from 'lodash';
import { FIRST_HIGH_PRICED_GIGS } from '../constants';

/**
 * This function parses the response of recommendations API to provide the actual gigs
 * @param { Object } data - The data returned from recommendations API
 * @returns { Object[] }  actual gigs array
 */
export const parseRecommendations = (data) => get(data, 'sections.carousels[0].items.gigs', []);

/**
 * This function is used to get a fixed amount of organic gigs within
 * a given DLC, from the provided gigs array
 * @param { String | Number } dlc - The DLC to verify each gig with
 * @param { Array } gigs - Array of listing gigs
 * @returns { Number | undefined } gigId
 */
export const getFirstOrganicDlcGigId = (dlc, gigs = []) => {
    if (!dlc) {
        return;
    }

    const [firstOrganicDlcGig = {}] = gigs.filter(
        ({ type, sub_category_id }) => !type && sub_category_id && sub_category_id.toString() === dlc.toString()
    );

    return firstOrganicDlcGig.gig_id;
};

/**
 * This function is used to get the gig with the highest price from the first X gigs
 * @param { Array } gigs - Array of listing gigs
 * @param { Number } numOfGigs first X gigs to check in.
 * @returns { Number } gigId
 */
export const getHighestPricedGigId = (gigs = [], numOfGigs = FIRST_HIGH_PRICED_GIGS) => {
    const gig = maxBy(take(gigs, numOfGigs), ({ price_i }) => price_i) || {};

    return gig.gig_id;
};
