import React, { useState } from 'react';
import classnames from 'classnames';
import { I18n } from '@fiverr-private/i18n-react';
import { useImpressionItem } from '@fiverr-private/impressionable';
import { FilterIcon } from '@fiverr-private/icons';
import { grey_1200 } from '@fiverr-private/sass/helpers';
import {
    BI_ELEMENTS_NAMES,
    BI_EVENTS_TYPES,
    ELEMENT_TYPE,
} from '../../../../../../generic_listings/TagFilters/constants';
import { buildEventEnrichment } from '../../../../../../generic_listings/TagFilters/utils';
import { BI_EVENT_GROUPS } from '../../../../../../../../../shared/services/BIEvents/constants';
import { invokeApplyEvent, useSidebarFiltersContext, Triggers } from '../../../Context';
import ComboBoxFilter from '../ComboBoxFilter';
import ViewAllModal from './ViewAllModal';
import styles from './index.module.scss';

const SHOW_MORE_COUNT = 6;

const TagFilter = ({ filter, allFilters, activeFilters, subCategoryId, withComboBox }) => {
    const { applyEvent, newActiveFilters } = useSidebarFiltersContext();

    const { id: filterId, options: mostPopularOptions } = filter;
    const elementName = filterId || BI_ELEMENTS_NAMES.LEAF_CATEGORY;
    const [selected, setSelected] = useState(newActiveFilters?.[filterId] || activeFilters[filterId] || []);
    const enrichment = buildEventEnrichment({
        type: BI_EVENTS_TYPES.IMPRESSION,
        group: BI_EVENT_GROUPS.USER_IMPRESSION,
        elementName,
        elementType: ELEMENT_TYPE.TAGS,
    });
    const [ref] = useImpressionItem({ enrichment });

    const { options: fullFilterOptions = [], alias } = allFilters.find((filter) => filter.id === filterId) || {};

    if (!mostPopularOptions) {
        return null;
    }

    const handleTagClick = (optionId) => {
        const isCurrentSelected = selected.includes(optionId);

        let newActiveOptionsForFilter;
        if (!isCurrentSelected) {
            newActiveOptionsForFilter = [...selected, optionId];
        } else {
            newActiveOptionsForFilter = selected.filter((id) => id !== optionId);
        }

        setSelected(newActiveOptionsForFilter);

        const filtersAfterChange = { ...(newActiveFilters || activeFilters) };
        filtersAfterChange[filterId] = newActiveOptionsForFilter;

        invokeApplyEvent(applyEvent, filtersAfterChange, Triggers.FILTER_COMPONENT_CLICK);
    };

    const handleApply = (selected) => {
        setSelected(selected);

        const filtersAfterChange = { ...(newActiveFilters || activeFilters) };
        filtersAfterChange[filterId] = selected;

        invokeApplyEvent(applyEvent, filtersAfterChange, Triggers.FILTER_COMPONENT_CLICK);
    };

    const shouldDisplayViewAllModal = !withComboBox && fullFilterOptions.length > SHOW_MORE_COUNT;

    const viewMoreParams = {
        isMulti: true,
        subCategoryId,
        currentFilterOptions: fullFilterOptions,
        modalTitleKey: `search_perseus.filter_tiles.${subCategoryId}.${filterId}`,
        activeFilters,
        onFilterApply: handleApply,
        className: styles.viewAllTrigger,
    };

    return (
        <div ref={ref} className={classnames(styles.container)}>
            <div className={styles.titleContainer}>
                <FilterIcon size={16} color={grey_1200} />
                <span className="m-l-8">{alias}</span>
            </div>
            {withComboBox && (
                <>
                    <div className={styles.comboBoxWrapper}>
                        <ComboBoxFilter filter={filter} allFilters={allFilters} activeFilters={activeFilters} />
                    </div>
                    <span className={styles.mostPopularSubtitle}>
                        <I18n k="search_perseus.general.most_popular" />
                    </span>
                </>
            )}
            <div className={classnames(styles.tagsContainer, withComboBox ? 'm-t-8' : 'm-t-16')}>
                {mostPopularOptions.slice(0, SHOW_MORE_COUNT).map(({ alias, id }) => (
                    <span
                        key={id}
                        onClick={() => handleTagClick(id)}
                        className={classnames(styles.filterTag, 'tbody-6', {
                            [styles.selected]: selected.includes(id),
                        })}
                    >
                        {alias}
                    </span>
                ))}
            </div>
            {shouldDisplayViewAllModal && <ViewAllModal initialValue={selected} {...viewMoreParams} />}
        </div>
    );
};

export default TagFilter;
