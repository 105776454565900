import React, { useContext } from 'react';
import { AppContext } from '../../../context/listingsContext';
import { EXPOSED_FILTER_KEY_TO_COMPONENT_MAPPER, EXPOSED_FILTER_KEY_TO_PROPS_MAPPER } from './util/mappers';

export const ExposedFilters = () => {
    const context = useContext(AppContext);
    const { appFilters: { shownExposedFilter = '' } = {} } = context;
    const Component = EXPOSED_FILTER_KEY_TO_COMPONENT_MAPPER[shownExposedFilter];

    if (!Component) {
        return null;
    }

    const props = EXPOSED_FILTER_KEY_TO_PROPS_MAPPER[shownExposedFilter](context);

    return <Component {...props} />;
};
