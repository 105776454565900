import React, { useRef, useEffect, useState, useMemo } from 'react';
import classNames from 'classnames';
import { isEmpty, slice } from 'lodash';
import { number } from 'prop-types';
import { Popover, PopoverAnchor, PopoverContent } from '@fiverr-private/popover';
import { HourlyIcon } from '@fiverr-private/visuals';
import { Tag } from '@fiverr-private/data_display';
import { getContext } from '@fiverr-private/fiverr_context';
import { I18n } from '@fiverr-private/i18n-react';
import { clickOnMoreSkillsEvent, clickOnSkillsEvent } from '../../utils/biEvents/events/clickOnSkillsEvent';
import { useCurrentWidth } from '../../hooks';
import { getNumOfItemsToShow, measureElementWidth } from './utils';
import { getTranslation, isTagExperise } from './ExpertiseTag/utils';
import ExpertiseTag from './ExpertiseTag';
import propTypes from './types';

import './index.scss';

const LIST_SIZE_BUFFER = 40;
const SMALL_CARD_MAX_WIDTH_THRESHOLD = 600;

const MoreExperties = ({ expertises, onClick, isSmallMode }) => (
    <ul className={classNames('more-experties-popover', { 'small-mode': isSmallMode })}>
        {expertises.map((expertise, index) => (
            <li key={index} onClick={() => onClick(expertise)}>
                <ExpertiseTag {...expertise} />
            </li>
        ))}
    </ul>
);

const ShowMoreText = ({ onClick, showMoreCount }) => (
    <span className="show-more m-r-4 co-text-darker" onClick={onClick}>
        {showMoreCount > 0 && `+${showMoreCount}`}
    </span>
);

const MoreExpertisesIndicator = ({ moreExpertises, handleSkillClick, isSmallMode, biEnrichment, showMoreCount }) => {
    const { isMobile } = getContext();

    if (isEmpty(moreExpertises)) {
        return null;
    }

    const handleMoreSkillsClick = () => {
        clickOnMoreSkillsEvent({ ...biEnrichment, moreCount: showMoreCount });
    };

    if (isMobile) {
        return <ShowMoreText showMoreCount={showMoreCount} onClick={handleMoreSkillsClick} />;
    }

    return (
        <Popover triggerEvent="click" position="right-start">
            <PopoverAnchor as="span">
                <ShowMoreText showMoreCount={showMoreCount} onClick={handleMoreSkillsClick} />
            </PopoverAnchor>
            <PopoverContent appendToBody>
                <MoreExperties expertises={moreExpertises} onClick={handleSkillClick} isSmallMode={isSmallMode} />
            </PopoverContent>
        </Popover>
    );
};

/**
 * A component that shows all the expertises the seller offer.
 */
const ExpertisesList = ({
    expertises = [],
    biEnrichment,
    forceSmallCard = false,
    className = '',
    hasHourlyRate = false,
}) => {
    const tags = useMemo(() => {
        const _tags = [...expertises];
        if (hasHourlyRate) {
            _tags.unshift({
                type: 'offer',
                key: 'listings.expert_card.offer_hourly_rate',
                icon: <HourlyIcon />,
                variant: 'filled',
            });
        }
        return _tags;
    }, [hasHourlyRate, expertises]);

    const [numOfItemsToShow, setNumOfitemsToShow] = useState(tags.length);
    const tagsWidth = useRef([]);
    const listRef = useRef();
    const itemsRef = useRef([]);
    const windowSize = useCurrentWidth(listRef);
    const listWidth = listRef.current?.clientWidth;
    const isSmallMode = forceSmallCard || (listWidth && listWidth < SMALL_CARD_MAX_WIDTH_THRESHOLD);
    const tagsToShow = tags.slice(0, numOfItemsToShow);
    const moreExpertises = tags.slice(numOfItemsToShow);
    const showMoreCount = moreExpertises.length;

    const handleSkillClick = (expertise) => {
        clickOnSkillsEvent({ ...biEnrichment, elementText: getTranslation(expertise) });
    };

    useEffect(() => {
        const listMaxWidth = listWidth - LIST_SIZE_BUFFER;

        isEmpty(tagsWidth.current) &&
            itemsRef.current.forEach((itemRef) => itemRef && tagsWidth.current.push(measureElementWidth(itemRef)));

        let itemsToShowWhenSmall = 0;

        if (listRef?.current && isSmallMode) {
            itemsToShowWhenSmall = getNumOfItemsToShow(tagsWidth.current, listWidth);
        }

        const numOfItemsToShow =
            itemsToShowWhenSmall +
            getNumOfItemsToShow(slice(tagsWidth.current, itemsToShowWhenSmall, tagsWidth.current.length), listMaxWidth);

        setNumOfitemsToShow(numOfItemsToShow);
    }, [windowSize, isSmallMode, listWidth, hasHourlyRate]);

    return (
        !isEmpty(tags) && (
            <ul ref={listRef} className={classNames('listings-expertises-tag-list flex flex-items-center', className)}>
                {tagsToShow.map((tag, index) => {
                    if (isTagExperise(tag)) {
                        return (
                            <li
                                key={index}
                                ref={(el) => (itemsRef.current[index] = el)}
                                className="m-r-8"
                                onClick={() => handleSkillClick(tag)}
                            >
                                <ExpertiseTag {...tag} />
                            </li>
                        );
                    }
                    return (
                        <li key={index} ref={(el) => (itemsRef.current[index] = el)} className="m-r-8">
                            <Tag size="sm" variant={tag.variant}>
                                {!!tag.icon && tag.icon}
                                <div className="expertise-text-wrapper">
                                    <I18n k={tag.key} />
                                </div>
                            </Tag>
                        </li>
                    );
                })}
                <MoreExpertisesIndicator
                    moreExpertises={moreExpertises}
                    handleSkillClick={handleSkillClick}
                    isSmallMode={isSmallMode}
                    biEnrichment={biEnrichment}
                    showMoreCount={showMoreCount}
                />
            </ul>
        )
    );
};

ExpertisesList.propTypes = propTypes;
MoreExperties.propTypes = {
    expertises: propTypes.expertises,
    isSmallMode: propTypes.bool,
    onClick: propTypes.func,
};

MoreExpertisesIndicator.propTypes = {
    moreExpertises: propTypes.expertises,
    biEnrichment: propTypes.biEnrichment,
    isSmallMode: propTypes.bool,
    showMoreCount: number,
    handleSkillClick: propTypes.func,
};

ShowMoreText.propTypes = {
    showMoreCount: number,
    onClick: propTypes.func,
};

export default ExpertisesList;
