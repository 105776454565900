import { RequestContext } from '@fiverr-private/perseus';
import { isActive } from '@fiverr-private/rollout_js';
import { EXPERIMENT_TYPE } from '@fiverr-private/experiments_package';
import { EXPERIMENTS, ROLLOUTS } from '@fiverr-private/listing_experiments';
import { isEligibleForAllocation } from './common';

const inRollout = async () => {
    const { userGuid } = RequestContext;
    return await isActive(ROLLOUTS.DOLPHINS_HOURLY_RATE_BANNER_LISTINGS_GUEST, userGuid);
};

export const hourlyBannerGuest = {
    id: EXPERIMENTS.DOLPHINS_HOURLY_RATE_BANNER_LISTINGS_GUEST,
    experimentType: EXPERIMENT_TYPE.GUEST,
    numOfGroups: 2,
    shouldAllocate: isEligibleForAllocation,
    inRollout,
};
