import { COMPONENT_NAMES, EVENT_TYPES, MIXPANEL_EVENT_NAMES } from '../../constants';
import { convertSellerForMixPanel, getPageNameForMixPanel } from '../../utils';
import { sendClickEventToBQ } from '../../BigQuery';
import { sendClickEventToMixPanel } from '../../Mixpanel';

const clickOnSendMessageEvent = ({ pageName, seller, impressionId } = {}) => {
    sendClickEventToBQ({
        type: EVENT_TYPES.CLICK_ON_SEND_MESSAGE_BUTTON,
        componentName: COMPONENT_NAMES.CONTACT_SELLER_MODAL,
        pageName,
        seller,
        impressionId,
    });

    sendClickEventToMixPanel({
        eventName: MIXPANEL_EVENT_NAMES.SENT_EXPERT_MESSAGE,
        ...getPageNameForMixPanel(pageName),
        ...(seller && { ...convertSellerForMixPanel(seller) }),
    });
};

export default clickOnSendMessageEvent;
