import { useRef, useState, useCallback } from 'react';

const useScroll = ({ canScrollRightDefault, canScrollLeftDefault }) => {
    const navigationRef = useRef({});
    const [canScrollRight, setCanScrollRight] = useState(canScrollRightDefault);
    const [canScrollLeft, setCanScrollLeft] = useState(canScrollLeftDefault);

    const updateScrollState = useCallback(
        ({ canScrollRight, canScrollLeft }) => {
            setCanScrollLeft(canScrollLeft);
            setCanScrollRight(canScrollRight);
        },
        [setCanScrollLeft, setCanScrollRight]
    );

    return [updateScrollState, navigationRef, canScrollRight, canScrollLeft];
};

export default useScroll;
