import React, { useContext, useEffect } from 'react';
import { registerStickyFilter } from '@fiverr-private/listing_lib';
import { BusinessListingPropTypes } from '../../types/index';
import { FLOWS, LISTING_TYPE } from '../../utils/constants';
import { useCountFlowAndListingTypeView } from '../../hooks';
import { AppContext } from '../context/listingsContext';
import { BusinessLayout, BusinessListingsProvider, ItemsContainer, Sidebar } from './BusinessPageLayout';
import BusinessGigsGridListing from './BusinessGigsGridListing';

export const BusinessGigsListing = (props) => {
    const { flow, breadcrumbs, managedAccount, rollouts } = props;

    const {
        listings: [listings],
        activeFilters,
        categoryIds,
        dominateSubCategoryId,
        significantLeafCategories = [],
        displayQuery,
        appFilters,
        pagination,
        displayData,
    } = useContext(AppContext);

    useEffect(() => {
        registerStickyFilter(activeFilters);
    }, [activeFilters]);

    let searchTitleData;
    const isSearch = flow === FLOWS.SEARCH || flow === FLOWS.BUSINESS_SEARCH;

    if (isSearch) {
        const {
            searchComponentData: { modifiedQueryString, nonTranslatedQuery, searchInsteadUrl, query },
        } = displayData;

        searchTitleData = { modifiedQueryString, nonTranslatedQuery, searchInsteadUrl, translatedQuery: query };
    }

    const { filters, visualFilters, selectedFilters, subCategory = {} } = appFilters;
    const { gigs } = listings;
    const { header } = displayData;
    const subcategoryName = header?.title;

    // Using hasGigs is a temporary workaround since currently we get 1 result for gigs when there are none
    const hasGigs = gigs.length > 0;
    const resultsCount = !hasGigs ? 0 : pagination?.total;

    useCountFlowAndListingTypeView(flow, LISTING_TYPE.GIGS);

    return (
        <BusinessListingsProvider
            rollouts={rollouts}
            resultsCount={resultsCount}
            isExpertListings={false}
            isSearch={isSearch}
            filters={filters}
            activeFilters={activeFilters}
            visualFilters={visualFilters}
            selectedFilters={selectedFilters}
            subCategory={subCategory}
            subcategoryName={subcategoryName}
            breadcrumbs={breadcrumbs}
            hasItems={hasGigs}
            categoryIds={categoryIds}
            dominateSubCategoryId={dominateSubCategoryId}
            significantLeafCategories={significantLeafCategories}
            displayQuery={displayQuery}
            searchQueryData={searchTitleData}
            managedAccount={managedAccount}
        >
            <BusinessLayout>
                <Sidebar />
                <ItemsContainer>
                    <BusinessGigsGridListing />
                </ItemsContainer>
            </BusinessLayout>
        </BusinessListingsProvider>
    );
};

BusinessGigsListing.propTypes = BusinessListingPropTypes;
