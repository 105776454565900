import { getContext } from '@fiverr-private/fiverr_context';
import { setTranslateOnLoad } from '@fiverr-private/machine_translation';
import ACTION_TYPES from '../../components/context/listingsContextReducerActionTypes';
import {
    getFlatChoiceGigs,
    getFlatRepeatedGigs,
    getFlatFtbFriendlyGigs,
    getFlatExpressRecommendationGigs,
    getTranslatedListings,
    getFlatSellerWhoSpeaksGigs,
} from './utils/utils';
import { fetchTranslationData } from './utils/fetchTranslationData';
import { translationRequestSucceeded } from './utils/translationRequestSucceeded';

export const onTranslateBtnClick = async (gigsListings, appContext, hidePrefix) => {
    const { showTranslatedUGC, wasTranslatedBefore, listings, dispatch } = appContext;

    if (!wasTranslatedBefore) {
        const flatGigsListings = gigsListings.flat();
        const flatRepeatedBuyer = getFlatRepeatedGigs({ listings });
        const flatFtbFriendly = getFlatFtbFriendlyGigs({ listings });
        const flatExpressRecommendation = getFlatExpressRecommendationGigs({ listings });
        const flatFiverrChoice = getFlatChoiceGigs({ listings });
        const flatSellersWhoSpeak = getFlatSellerWhoSpeaksGigs({ listings });
        const { abTests } = getContext();

        const translationData = await fetchTranslationData({
            ...appContext,
            flatGigsListings,
            flatRepeatedBuyer,
            flatFtbFriendly,
            flatExpressRecommendation,
            flatFiverrChoice,
            flatSellersWhoSpeak,
            hidePrefix,
            abTests,
        });

        const isTranslationSucceeded = translationRequestSucceeded(translationData?.data?.translationStatus);
        setTranslateOnLoad(true);

        const payload = {
            listings: getTranslatedListings({
                listings,
                gigsListings,
                flatFiverrChoice,
                flatRepeatedBuyer,
                flatFtbFriendly,
                flatExpressRecommendation,
                flatSellersWhoSpeak,
                translationData,
            }),
            showTranslatedUGC: isTranslationSucceeded,
            wasTranslatedBefore: isTranslationSucceeded,
        };
        dispatch({ type: ACTION_TYPES.TRANSLATE_UGC, payload });
    } else {
        const toggledValue = !showTranslatedUGC;
        const ugcListings = listings.map((listing) => ({ ...listing, showTranslatedUGC: toggledValue }));
        setTranslateOnLoad(toggledValue);

        const payload = {
            listings: ugcListings,
            showTranslatedUGC: toggledValue,
        };
        dispatch({ type: ACTION_TYPES.TRANSLATE_UGC, payload });
    }
};
