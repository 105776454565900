/* eslint-disable react/display-name */
import React from 'react';

export default () => (
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.88289 12.1309H7.18791C6.66616 11.2083 5.68618 10.5581 4.52902 10.5508C3.31492 10.5898 2.37635 11.2661 1.88289 12.1309ZM4.50943 9.05078C2.15179 9.11282 0.48248 10.8163 0.0889269 12.67C-0.0230784 13.1975 0.42997 13.6309 0.969287 13.6309H8.0895C8.64179 13.6309 9.10017 13.1776 8.97719 12.6392C8.52573 10.6626 6.75936 9.05078 4.50943 9.05078Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.798 7.81638C11.3344 7.34591 9.90824 7.66479 8.84405 8.42166C8.66137 8.55158 8.48936 8.69442 8.32965 8.84838C8.12583 9.04485 7.94206 9.25946 7.78174 9.48849L7.85444 9.56119C8.72684 10.1428 9.30844 10.9425 9.67194 11.9603C9.74464 12.1057 9.81734 12.1057 9.96274 12.1057H15.0392C15.5746 12.1057 16.0255 11.6778 15.9114 11.1547C15.6051 9.74992 14.515 8.34923 12.798 7.81638ZM10.719 10.6057H14.0949C13.7356 10.012 13.1454 9.49476 12.3534 9.24898L12.3462 9.24674L12.339 9.24443C11.4835 8.96942 10.6481 9.09777 9.97863 9.47448C10.2668 9.82282 10.5122 10.2011 10.719 10.6057Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.4438 4.5445C10.4438 5.14222 10.9073 5.58901 11.4884 5.58901C11.9641 5.58901 12.4774 5.16945 12.5321 4.50732C12.5107 3.96978 12.0288 3.5 11.4884 3.5C10.9355 3.5 10.4438 3.99163 10.4438 4.5445ZM14.0329 4.5445C14.0329 3.1632 12.8697 2 11.4884 2C10.107 2 8.94385 3.1632 8.94385 4.5445C8.94385 5.99851 10.107 7.08901 11.4884 7.08901C12.8697 7.08901 13.9602 5.92581 14.0329 4.5445Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.55336 6.07185C5.55336 5.51897 5.06174 5.02734 4.50886 5.02734C3.98157 5.02734 3.5165 5.42993 3.46514 6.03637C3.48666 6.55073 3.95042 7.0629 4.54713 7.11557C5.08422 7.09357 5.55336 6.61193 5.55336 6.07185ZM4.50886 8.61635C5.89016 8.61635 7.05336 7.45315 7.05336 6.07185C7.05336 4.69055 5.89016 3.52734 4.50886 3.52734C3.12756 3.52734 2.03706 4.61785 1.96436 5.99915C1.96436 7.38045 3.12756 8.54365 4.50886 8.61635Z"
        />
    </svg>
);
