import React from 'react';
import SellerBadge from '../../../SellerBadge';
import { useExpertCardContext } from '../../../../contexts';
import SellerRating from '../SellerRating';

export const BadgeAndRating = () => {
    const { seller } = useExpertCardContext();
    const { badges, sellerLevel } = seller;

    return (
        <>
            <SellerBadge badges={badges} sellerLevel={sellerLevel} />
            <SellerRating />
        </>
    );
};
