import React from 'react';
import { Container, Divider, Stack } from '@fiverr-private/layout_components';
import { MetadataFilterOptionType } from '@fiverr-private/listing_types';
import TilesCarousel from '../../tiles_carousel';
import { MetadataFilterTile } from '../MetadataFilterTile/MetadataFilterTile';
import { MetadataFilterCarouselTitle } from '../MetadataFilterCarouselTitle/MetadataFilterCarouselTitle';
import { buildMetadataTileProps } from './util/buildMetadataTileProps';
import { TILES_TO_SHOW } from './constants';

export interface MetadataFilterCarouselProps {
    options: MetadataFilterOptionType[];
    alias: string;
    title: string;
    id: string;
}

export const MetadataFilterCarousel = ({ options, alias, title, id }: MetadataFilterCarouselProps) => {
    const tilesData = buildMetadataTileProps(options, id, alias);

    return (
        <Stack direction="column" width="100%" marginTop="8" gap="0">
            <MetadataFilterCarouselTitle title={title} />
            <TilesCarousel TileComponent={MetadataFilterTile} items={tilesData} maxTilesToShow={TILES_TO_SHOW} />
            <Container marginTop="8">
                <Divider direction="horizontal" />
            </Container>
        </Stack>
    );
};
