import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { array, bool, func, object } from 'prop-types';
import { ExpertCardListings } from '@fiverr-private/listings';
import { getContext } from '@fiverr-private/fiverr_context';
import { reportActivity } from '@fiverr-private/bianka_js';
import { useImpressionContainer, useImpressionItem } from '@fiverr-private/impressionable';
import { mixpanel } from '@fiverr-private/obs';
import { PaginationButton } from '@fiverr-private/listings/src/components';
import { ALLOCATIONS, EXPERIMENTS, ROLLOUTS } from '@fiverr-private/listing_experiments';
import { LoyaltyIndicationBanner } from '@fiverr-private/loyalty_indication';
import { getBaseEventPayload } from '../../../../../shared/context/ListingsImpressionContainer/utils/sendImpressionEvent/sendImpressionEvent';
import { ProjectManageBanner } from '../../business_listings/banner/ProjectManageBanner';
import { MAX_ITEMS_TO_SHOW_NO_RESULTS } from '../../../constants';
import { BannerMode, useFetchUserData } from '../../business_listings/banner/util';
import { AIConsultationBanner } from '../../business_listings/banner/AIConsultationBanner';
import { shouldShowLoyaltyBanner } from '../../business_listings/banner/LoyaltyIndicationBanner/utils';
import { shouldShowAIConsultationBanner } from '../../business_listings/banner/AIConsultationBanner/utils';
import { getSearchTitleData } from '../../business_listings/BusinessPageLayout/utils';
import { getImpressionEnrichment } from './impression';
import { CARD_VARIANT, EXPERT_LISTINGS_SOURCE } from './constants';
import { MIXPANEL_PAGE_NAMES } from './biEvents/constants';

const ExpertListingContainer = ({
    isSearch,
    categoryIds = {},
    hasMore,
    isLoading,
    onPagination,
    cardItems = [],
    impressionEnrichment = {},
    isExperiential,
    currency = {},
    resultsCount,
    activeFilters,
    searchHeaderProps,
    rollouts,
    loyaltyData = {},
}) => {
    const { isMobile, abTests } = getContext();
    const inProCatalogGridExp =
        rollouts[ROLLOUTS.MUSTANGS_PRO_CATALOG_GRID] &&
        abTests[EXPERIMENTS.MUSTANGS_PRO_CATALOG_GRID] === Number(ALLOCATIONS.TEST_B);
    const userData = useFetchUserData();
    const enrichment = getImpressionEnrichment(impressionEnrichment);
    const onImpression = (impressionData) => {
        const payload = getBaseEventPayload('expert_listings');

        Object.assign(payload.event, impressionData.enrichment);
        delete impressionData.enrichment;
        Object.assign(payload.event, { component: impressionData });

        reportActivity(payload);
    };

    const hourlyRateRolloutActive = rollouts[ROLLOUTS.ZEBRAS_HOURLY_RATES];

    useEffect(() => {
        const { queryParameters: { query } = {} } = getContext();
        const { categoryId, subCategoryId, nestedSubCategoryId } = categoryIds;

        mixpanel.trackPageView({
            'Page Name': MIXPANEL_PAGE_NAMES[EXPERT_LISTINGS_SOURCE],
            ...(isSearch && { 'Search Query': query }),
            ...(!isSearch && {
                'Category Id': categoryId,
                'SubCategory Id': subCategoryId,
                ...(nestedSubCategoryId && { 'Nested SubCategory Id': nestedSubCategoryId }),
            }),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [ref, containerId] = useImpressionContainer();
    useImpressionItem({
        selfRef: ref,
        selfId: containerId,
        enrichment,
        sendImpressionToBQ: false,
        intersectionThreshold: 0,
        onImpression,
    });

    const hasItems = !isEmpty(cardItems);
    const cardVariant = isExperiential ? CARD_VARIANT.VISUAL : CARD_VARIANT.DEFAULT;
    const layout = inProCatalogGridExp ? 'GRID' : 'LIST';

    if (!hasItems) {
        return null;
    }

    const { displayQuery } = getSearchTitleData(searchHeaderProps);

    const showAIConsultationBanner = shouldShowAIConsultationBanner({
        resultsCount,
        categoryIds,
        isExpertListings: true,
        filters: activeFilters,
        searchTerm: displayQuery,
    });

    const showLoyaltyIndicationBanner = shouldShowLoyaltyBanner({
        loyaltyData,
        isRelatedToAI: showAIConsultationBanner,
        emptyFilters: isEmpty(activeFilters),
        resultsCount,
    });

    const getBanner = () => {
        if (showAIConsultationBanner) {
            const bannerPosition = 0;

            return {
                Component: () => (
                    <AIConsultationBanner
                        resultsCount={resultsCount}
                        hasActiveFilters={!isEmpty(activeFilters)}
                        position={bannerPosition + 1}
                        userData={userData}
                    />
                ),
                position: bannerPosition,
            };
        }

        if (resultsCount > MAX_ITEMS_TO_SHOW_NO_RESULTS) {
            const bannerPosition = 2;
            return {
                Component: () => (
                    <ProjectManageBanner
                        resultsCount={resultsCount}
                        hasActiveFilters={!isEmpty(impressionEnrichment.activeFilters)}
                        position={bannerPosition + 1}
                        bannerMode={BannerMode.PRO_LISTINGS}
                        userData={userData}
                    />
                ),
                position: bannerPosition,
            };
        }
    };

    return (
        // TODO: Remove containerType once it's added in expert card itself
        <div ref={ref} className="m-b-16" style={{ containerType: 'inline-size' }}>
            {showLoyaltyIndicationBanner && (
                <LoyaltyIndicationBanner
                    gigInfo={{ showBanner: true, gigPriceInUSDCents: 0 }}
                    type="listings"
                    loyaltyIndicationData={loyaltyData}
                    containerStyles={{ marginBottom: 6 }}
                />
            )}
            <ExpertCardListings
                banner={getBanner()}
                items={cardItems}
                currency={currency}
                onImpression={onImpression}
                source={EXPERT_LISTINGS_SOURCE}
                cardVariant={cardVariant}
                forceSmallCard={isMobile}
                layout={layout}
                displayOptions={{ showContact: !isMobile && inProCatalogGridExp }}
                inProCatalogGridExp={inProCatalogGridExp}
                showHourlyPrice={hourlyRateRolloutActive && activeFilters?.has_hourly}
                hourlyRateRolloutActive={hourlyRateRolloutActive}
            />
            {hasMore && (
                <PaginationButton isLoading={isLoading} onPagination={onPagination} source={EXPERT_LISTINGS_SOURCE} />
            )}
        </div>
    );
};

ExpertListingContainer.propTypes = {
    cardItems: array,
    isSearch: bool,
    categoryIds: object,
    hasMore: bool,
    isLoading: bool,
    onPagination: func,
    isExperiential: bool,
    impressionEnrichment: object,
    currency: object,
    activeFilters: object,
    loyaltyData: object,
};

export default ExpertListingContainer;
