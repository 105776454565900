import { BI_ACTION_TYPES, BI_EVENT_GROUPS } from '../../../../../shared/services/BIEvents/constants';
import { getEventPayload } from '../biUtils';
import { BI_EVENT_TYPES } from './constants';

export const getProEducationBannerImpressionEventPayload = (isExpertListings) =>
    getEventPayload({
        type: BI_EVENT_TYPES.PRO_CATALOG_EDUCATION_BANNER_IS_SHOWN,
        group: BI_EVENT_GROUPS.BANNER_IMPRESSION,
        actionType: BI_ACTION_TYPES.IMPRESSION,
        isExpertListings,
    });

export const getDismissProEducationBannerClickEventPayload = (isExpertListings) =>
    getEventPayload({
        type: BI_EVENT_TYPES.DISMISS_PRO_EDUCATION_BANNER,
        group: BI_EVENT_GROUPS.USER_ACTIONS,
        actionType: BI_ACTION_TYPES.CLICK,
        isExpertListings,
    });

export const getToggleItemClickEventPayload = (isExpertListings) =>
    getEventPayload({
        type: BI_EVENT_TYPES.TOGGLE_ITEM_CLICK,
        group: BI_EVENT_GROUPS.USER_ACTIONS,
        actionType: BI_ACTION_TYPES.CLICK,
        isExpertListings,
    });
