import React from 'react';
import { Stack } from '@fiverr-private/layout_components';
import { PaginationProps } from '../types';
import { getPaginationConfig } from '../utils';
import { PaginationNumber } from './PaginationNumber';
import { PaginationPrevious, PaginationNext } from './PaginationArrows';

export const DesktopPagination = (props: PaginationProps) => {
    const { previousPageButtonRelevant, previousPageUrl, nextPageButtonRelevant, nextPageUrl, pages } =
        getPaginationConfig(props);

    return (
        <Stack justifyContent="center" alignItems="center" gap="2">
            <PaginationPrevious isDisabled={!previousPageButtonRelevant} url={previousPageUrl} />
            <Stack alignItems="center" gap="2">
                {pages.map(({ pageNumber, url, isCurrentPage }) => (
                    <PaginationNumber key={pageNumber} page={pageNumber} isActive={isCurrentPage} url={url} />
                ))}
            </Stack>
            <PaginationNext isDisabled={!nextPageButtonRelevant} url={nextPageUrl} />
        </Stack>
    );
};
