import { useContext } from 'react';
import { getContext } from '@fiverr-private/fiverr_context';
import { EXPERIMENTS } from '@fiverr-private/listing_experiments';
import { AppContext } from '../../components/context/listingsContext';
import { allocateToClientExperiment } from '../../clientExperiments/clientUtils';
import useListingsPositionObserver from '../useListingsPositionObserver';
import { POSITION_TO_ALLOCATE_TO_PROMOTED_VIDEO_IN_LISTINGS } from '../../clientExperiments/promotedVideoStrip/constants';
import { bqEvent } from '../../../../shared/services/BIEvents/utils';
import { BI_ACTION_TYPES, BI_EVENT_GROUPS, BI_PAGE_NAMES } from '../../../../shared/services/BIEvents/constants';

const usePromotedVideoStripListingRowObserver = () => {
    const { pageCtxId, abTests } = getContext();
    const { clientExperimentsGroups } = useContext(AppContext);

    const experiment = EXPERIMENTS.PROMOTED_VIDEO_STRIP;
    const experimentGroup = Number(clientExperimentsGroups[experiment]);

    return useListingsPositionObserver(
        {
            shouldObserve: !!experimentGroup,
            position: POSITION_TO_ALLOCATE_TO_PROMOTED_VIDEO_IN_LISTINGS,
        },
        () => {
            if (experimentGroup > 0) {
                bqEvent({
                    type: 'listings_client_video_strip_experiment_active',
                    group: BI_EVENT_GROUPS.USER_ACTIONS,
                    actionType: BI_ACTION_TYPES.IMPRESSION,
                    pageCtxId,
                    page: {
                        name: BI_PAGE_NAMES.LISTINGS,
                    },
                    payload: {
                        ab_test: [
                            {
                                experiment,
                                group: experimentGroup,
                            },
                        ],
                    },
                } as any);

                if (!abTests[experiment]) {
                    allocateToClientExperiment(experiment, clientExperimentsGroups);
                }
            }
        }
    );
};

export default usePromotedVideoStripListingRowObserver;
