import { isDefaultOption } from './isDefaultOption';

export const clearActiveDefaults = (activeFilters = {}) => {
    if (!activeFilters) {
        return {};
    }

    Object.keys(activeFilters).forEach((key) => {
        const value = activeFilters[key];

        if (isDefaultOption(key, value)) {
            activeFilters[key] = undefined;
        }
    });

    return activeFilters;
};
