import React from 'react';
import { Video } from '@fiverr-private/orca';
import { VIDEO_PROP_TYPES } from '../constants';
import { VIDEO_TYPE } from './constants';
import { getVideoSourceByLocale } from './services/getVideoSourceByLocale';

const ModalBody = ({ video, sourceInfo }) => {
    const sources = [
        {
            src: getVideoSourceByLocale(video),
            type: VIDEO_TYPE,
        },
    ];

    return <Video width="100%" height="100%" controls autoPlay source={sources} sourceInfo={sourceInfo} />;
};

ModalBody.propTypes = {
    ...VIDEO_PROP_TYPES,
};

export default ModalBody;
