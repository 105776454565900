import { compact, isEqual } from 'lodash';
import { URI } from '@fiverr-private/futile/lib/url';
import { getContext } from '@fiverr-private/fiverr_context';
import { localStorage } from '@fiverr-private/futile/lib/storage/localStorage';
import {
    activeFiltersToQueryString,
    removeEmptyEntries,
    tracker,
    transformServiceOfferingsToBooleans,
    FILTER,
    EVENTS_MAPPING,
    DEFAULT_SORT_OPTIONS,
    LASTLY_APPLIED_FILTERS_KEY,
    CATEGORY_TYPE,
    transformLanguagesPairFilters,
    deregisterStickyFilter,
    clearActiveDefaults,
    metricsService,
} from '@fiverr-private/listing_lib';

export const aboveTheFoldFilters = {};

export const shouldFilterNavigate = (change, activeFilters) => {
    const enrichederviceOfferingsChange = transformServiceOfferingsToBooleans(change);
    const enrichedLanguagesPairChange = transformLanguagesPairFilters(enrichederviceOfferingsChange);

    const combinedFilters = removeEmptyEntries({
        ...activeFilters,
        ...clearActiveDefaults(enrichedLanguagesPairChange),
    });

    return !isEqual(activeFilters, combinedFilters);
};

export const filterUrl = (change, activeFilters, source, params) => {
    if (!_isClient()) {
        return EMPTY_STRING;
    }

    const enrichedChange = transformServiceOfferingsToBooleans(change);
    const combinedFilters = removeEmptyEntries({
        ...activeFilters,
        ...clearActiveDefaults(enrichedChange),
    });

    const uri = new URI(window.location.href);
    uri.removeParams('page');

    if (source) {
        uri.addParams({ source });
    }

    if (params) {
        const { sub_category, nested_sub_category } = params;

        sub_category
            ? uri.addParams({ [CATEGORY_TYPE.SUB_CATEGORY]: sub_category })
            : uri.removeParams(CATEGORY_TYPE.SUB_CATEGORY);

        nested_sub_category
            ? uri.addParams({ [CATEGORY_TYPE.NESTED_SUB_CATEGORY]: nested_sub_category })
            : uri.removeParams(CATEGORY_TYPE.NESTED_SUB_CATEGORY);
    }

    const ref = activeFiltersToQueryString(combinedFilters);
    ref === '' ? uri.removeParams('ref') : uri.addParams({ ref });

    // Check if the newly applied filter is price related.
    const isNewFilterPriceRelated = !!Object.keys(change).find(
        (newlyAppliedFilter) => Object.values(FILTER).find(({ ID }) => ID === newlyAppliedFilter)?.IS_PRICE_RELATED
    );

    // Check if the query param called filter(AKA the sorter value) is the high to low or low to high.
    const {
        params: { filter: uriSortParam },
    } = uri;
    const priceRelatedSorters = DEFAULT_SORT_OPTIONS.filter((sorter) => sorter.isPriceRelated).map(({ id }) => id);
    const isSortParamPriceRelated = priceRelatedSorters.includes(uriSortParam);

    // If both are true remove the sort query param.
    if (isSortParamPriceRelated && isNewFilterPriceRelated) {
        uri.removeParams('filter');
    }

    return uri.toString();
};

export const setLastlyAppliedFiltersToLocalStorage = (change, source, filtersGroupName, filterKeysToGroups = {}) => {
    if (!_isClient()) {
        return;
    }

    const lastlyAppliedFilters = Object.keys(change).map((filterKey, index) => {
        const values = change[filterKey];

        const isAboveTheFold = !!aboveTheFoldFilters[filterKey];

        return {
            name: filterKey,
            values,
            filtersGroupName: filterKeysToGroups[filterKey] || filtersGroupName,
            indexInGroup: index,
            source,
            isAboveTheFold,
        };
    });

    const localStorageData = compact(lastlyAppliedFilters);

    localStorage.set(LASTLY_APPLIED_FILTERS_KEY, JSON.stringify(localStorageData));
};

export const filterNavigation = (
    change,
    activeFilters,
    location,
    source,
    filtersGroupName,
    params,
    filterKeysToGroups
) => {
    const navigationUrl = filterUrl(change, activeFilters, source, params);
    setLastlyAppliedFiltersToLocalStorage(change, source, filtersGroupName, filterKeysToGroups);

    Object.keys(change).forEach((filterId) => {
        tracker.seoFilterTrackingUpdate(activeFilters, change, location, filterId);
    });

    metricsService.count('listings.applied_filters.success');
    window.location.href = navigationUrl;
};

export const clearFiltersUrl = () => {
    deregisterStickyFilter();

    const { url } = getContext();
    const uri = new URI(url);
    uri.removeParams('page', 'ref', 'source', 'sub_category', 'nested_sub_category');

    return uri.toString();
};

export const shouldSortNavigate = (change, current) => {
    if (!change) {
        return false;
    }

    const newValue = change['sort-by'];
    const prev = current.find(({ id }) => id === newValue);

    if (!prev) {
        return false;
    }

    const wasAlreadySelected = prev.selected;

    return !wasAlreadySelected;
};

export const sortNavigation = (change) => {
    if (!_isClient()) {
        return EMPTY_STRING;
    }
    const filter = change['sort-by'];

    const uri = new URI(window.location.href);
    uri.removeParams('page');
    uri.addParams({ source: 'sorting_by' });
    uri.addParams({ filter });

    tracker.track(EVENTS_MAPPING.SORT_CHANGE, { Sort: filter });
    window.location.href = uri.toString();
};

export const paginationUrl = (page) => {
    if (!_isClient()) {
        return EMPTY_STRING;
    }
    const uri = new URI(window.location.href);
    page === 1 ? uri.removeParams('page') : uri.addParams({ page });

    return uri.toString();
};

const EMPTY_STRING = '';
const _isClient = () => typeof window !== 'undefined' && window.location;
