import React from 'react';
import { array } from 'prop-types';
import { isEmpty } from 'lodash';
import { Avatar, AvatarImage, AvatarGroup } from '@fiverr-private/avatar';
import { I18n } from '@fiverr-private/i18n-react';
import './index.scss';

const MAX_EMPLOYEES_TO_SHOW = 3;

export const EmployeesShortList = ({ employees }) => {
    if (isEmpty(employees)) {
        return null;
    }

    return (
        <div className="flex flex-items-center employees-short-list-container">
            <AvatarGroup>
                {employees.slice(0, MAX_EMPLOYEES_TO_SHOW).map(({ name, profileImageUrl }) => (
                    <Avatar key={name} size="xs" username={name}>
                        <AvatarImage src={profileImageUrl} />
                    </Avatar>
                ))}
            </AvatarGroup>

            <p className="m-l-8 tbody-5 one-row-ellipsis">
                <I18n k="listings.card_actions.employees" params={{ numOfEmployees: employees.length }} />
            </p>
        </div>
    );
};

EmployeesShortList.propTypes = {
    employees: array,
};
