import { logger } from '@fiverr-private/obs';
import pathfinder from '@fiverr-private/pathfinder';
import { InitialProps, ServiceConfig } from '@fiverr-private/listing_types';
import { RequestContext } from '@fiverr-private/perseus';
import { maxios } from '../../maxios/maxios';

interface EnrichedRelatedSearchTerms {
    pos: string;
    query: string;
    termUrl: string;
}
type RelatedSearchTermsResponse = string[];
export type FetchRelatedSearchTerms = EnrichedRelatedSearchTerms[];

const relatedSearchTermsRouteKey = 'fetch_related_search_terms_go_searcher';

export const buildContextWithQuery = (data: RelatedSearchTermsResponse): EnrichedRelatedSearchTerms[] =>
    data.map((searchTerm, index) => ({
        pos: index.toString(),
        query: searchTerm,
        termUrl: pathfinder('search_gigs', undefined, {
            query: {
                query: searchTerm,
                source: 'related_search_terms',
                context_alg: 'related_search_terms',
                context: 'recommendation',
            },
        }),
    }));

export const fetchRelatedSearchTermsFromSearchService = async (
    searchService: ServiceConfig
): Promise<FetchRelatedSearchTerms> => {
    try {
        const {
            listingAttributes: { query },
        } = RequestContext.initialProps as InitialProps;

        if (!query) {
            return [];
        }

        const { url, timeout, name: serviceName } = searchService;

        const queryRoute = `${url}/v1/related_search/${encodeURIComponent(query.toString().replace(/\//g, ' '))}`;

        const { data } = await maxios.get(queryRoute, {
            service: serviceName,
            routeKey: relatedSearchTermsRouteKey,
            timeout: timeout?.[relatedSearchTermsRouteKey],
        });

        return buildContextWithQuery(data);
    } catch (error) {
        logger.error(error as Error, {
            description: 'Error while fetching related search terms',
            context: RequestContext.toString(),
        });

        return [];
    }
};
