import React from 'react';
import { Heading, Typography } from '@fiverr-private/typography';
import { Container, Stack } from '@fiverr-private/layout_components';
import { Tag } from '@fiverr-private/data_display';

interface RelatedLinksProps {
    title: string;
    relatedLinks?: {
        url: string;
        text: string;
        openInNewTab: boolean;
    }[];
}

export const RelatedLinks = ({ title, relatedLinks = [] }: RelatedLinksProps) => (
    <Container
        borderTopWidth="sm"
        borderColor="grey_500"
        borderStyle="solid"
        as="section"
        paddingBottom="8"
        paddingX="5"
        paddingTop="12"
    >
        <Heading
            as="h2"
            size={{ default: 'h_sm', md: 'h_xl' }}
            marginBottom={{ default: '6', md: '8' }}
            textAlign="center"
        >
            {title}
        </Heading>
        <Stack gap="4" wrap="wrap" justifyContent="center">
            {relatedLinks.map((link, idx) => (
                <Tag href={link.url} target={link.openInNewTab ? '_blank' : ''} ariaLabel={link.text} key={idx}>
                    <Typography size="b_sm" color="grey_1000">
                        {link.text}
                    </Typography>
                </Tag>
            ))}
        </Stack>
    </Container>
);
