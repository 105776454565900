export const DOMAIN = 'buyers';
export const SUB_DOMAIN = 'caterpillars';
export const BI_EVENT_GROUPS = {
    PAGE_VIEW: 'page_view',
    USER_ACTION: 'user_action',
    USER_ACTIONS: 'user_actions',
    USER_IMPRESSION: 'user_impressions',
    BANNER_IMPRESSION: 'banner_impression',
};

export const BI_EVENTS_SUB_GROUPS = {
    WEBSITE_ACTIONS: 'website_actions',
    CONTENT_IMPRESSION: 'content_impression',
    FIVERR_PRO: 'fiverr_pro',
};

export const BI_PAGE_NAMES = {
    LISTINGS: 'listings_page',
    ROLES_LISTINGS: 'profession_listings_page',
};

export const BI_ACTION_TYPES = {
    CLICK: 'click',
    IMPRESSION: 'impression',
};

export const BI_EVENTS_ENTITY = {
    CLIENT: 'client',
};
