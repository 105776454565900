import { EVENT_TYPES, MIXPANEL_EVENT_NAMES } from '../../constants';
import { convertElementNameForMixPanel, convertSellerForMixPanel, getPageNameForMixPanel } from '../../utils';
import { sendClickEventToBQ } from '../../BigQuery';
import { sendClickEventToMixPanel } from '../../Mixpanel';

const clickOnExpertCardEvent = ({
    pageName,
    elementName,
    elementMetaData,
    seller,
    impressionId,
    componentName,
} = {}) => {
    sendClickEventToBQ({
        type: EVENT_TYPES.CLICKED_ON_EXPERT_CARD,
        componentName,
        pageName,
        elementName,
        elementMetaData,
        seller,
        impressionId,
    });

    sendClickEventToMixPanel({
        eventName: MIXPANEL_EVENT_NAMES.CLICKED_ON_EXPERT_CARD,
        ...getPageNameForMixPanel(pageName),
        'Clicked Element': convertElementNameForMixPanel(elementName),
        ...(elementMetaData && { 'Element Metadata': elementMetaData }),
        ...(seller && { ...convertSellerForMixPanel(seller) }),
        ...(componentName && { 'Component Name': componentName }),
    });
};

export default clickOnExpertCardEvent;
