import React from 'react';
import classNames from 'classnames';
import { ArrowLeftIcon, ArrowRightIcon, ChevronLeftIcon, ChevronRightIcon } from '@fiverr-private/icons';
import { grey_900 } from '@fiverr-private/sass/helpers/index';
import { getContext } from '@fiverr-private/fiverr_context';
import { PaginationPropTypes } from '../../../../types';
import { MAX_INDEX } from '../../../../utils/constants';
import FirstPageButton from './first_page_button';
import { renderPageNumbers, pageUrl } from './utils';

import './style.scss';

const Pagination = ({ pagination }) => {
    /**
     * Calculate what page pagination to show
     * @param { number } page - the current page we are on
     */

    const { isTouch } = getContext();

    const { page, page_size, total, offset } = pagination;

    const totalPages = Math.ceil(total / page_size);
    const numberOfPages = Math.min(MAX_INDEX, totalPages);

    const hideLeftArrow = page === 1,
        hideRightArrow = page === numberOfPages || total === 0;

    const ulClassNames = classNames('page-pagination', { desktop: !isTouch });
    const arrowsClassNames = classNames('pagination-arrows', { 'is-touch': isTouch });
    const leftArrowClasses = classNames(arrowsClassNames, { disabled: hideLeftArrow });
    const rightArrowClasses = classNames(arrowsClassNames, { disabled: hideRightArrow });

    const nextUrl = pageUrl(page + 1, offset);
    const prevUrl = pageUrl(page - 1, offset);
    const firstPageUrl = pageUrl(1, offset);

    const ArrowLeft = isTouch ? ChevronLeftIcon : ArrowLeftIcon;
    const ArrowRight = isTouch ? ChevronRightIcon : ArrowRightIcon;

    return (
        <ul id="pagination" className={ulClassNames}>
            <FirstPageButton page={page} firstPageUrl={firstPageUrl} arrowsClassNames={arrowsClassNames} />
            <li className={leftArrowClasses}>
                <a href={prevUrl}>
                    <ArrowLeft color={grey_900} />
                </a>
            </li>
            {renderPageNumbers(page, numberOfPages, offset, isTouch)}
            <li className={rightArrowClasses}>
                <a href={nextUrl}>
                    <ArrowRight color={grey_900} />
                </a>
            </li>
        </ul>
    );
};

Pagination.propTypes = PaginationPropTypes;

export default Pagination;
