import React from 'react';
import { CollectionsProvider } from '@fiverr-private/collect_actions';
import { ListingsOrchestratorProvider } from '@fiverr-private/listings_orchestrator';
import { AppProvider, AppContext } from '../components/context/listingsContext';
import { ListingsImpressionProvider } from '../../../shared/context/ListingsImpressionContainer/ListingsImpressionContainer';
import { PAGE_NAME } from '../utils/constants';

const Store = ({ children, ...props }) => (
    <AppProvider {...props}>
        <ListingsOrchestratorProvider>
            <CollectionsProvider bi={{ source: PAGE_NAME }}>
                <ListingsImpressionProvider onImpression={props.onImpression}>{children}</ListingsImpressionProvider>
            </CollectionsProvider>
        </ListingsOrchestratorProvider>
    </AppProvider>
);

export default Store;
export { AppContext };
