import { logger } from '@fiverr-private/obs';

// README: Predefined subcategories with "Other" title
const otherSubCategoryKeys = [9, 20, 31, 38, 97, 117, 118, 120, 121, 122, 123, 137, 240, 457];

/**
 * @type {(data: Partial<Record<'categoryId'|'subCategoryId'|'nestedSubCategoryId', string|number> & {replaceOther?: boolean}>) => string|undefined}
 */
export const getCategoryKey = ({ categoryId, subCategoryId, nestedSubCategoryId, replaceOther = true }) => {
    const categoryKey = `categories.category_${categoryId}`;
    const categoryKeyName = categoryId ? `${categoryKey}.name` : undefined;
    const subCategoryKey = `sub_categories.sub_category_${subCategoryId}`;
    const nestedSubCategoryName = `${subCategoryKey}.nested_sub_cat_${nestedSubCategoryId}.name`;
    if (subCategoryId && nestedSubCategoryId) {
        return nestedSubCategoryName;
    }
    if (subCategoryId) {
        let subCategoryKeyName = `${subCategoryKey}.name`;

        if (replaceOther && otherSubCategoryKeys.includes(Number(subCategoryId))) {
            subCategoryKeyName = categoryId ? categoryKeyName : subCategoryKeyName;

            if (!categoryId) {
                logger.warn({
                    message: '"categoryId" not defined for replace "Other" sub-category title',
                    details: { categoryId, subCategoryId, nestedSubCategoryId },
                });
            }
        }

        return subCategoryKeyName;
    }
    return categoryKeyName;
};
