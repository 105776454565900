import React from 'react';
import classnames from 'classnames';
import { string } from 'prop-types';
import { Avatar, AvatarIcon, AvatarImage } from '@fiverr-private/avatar';
import { CheckStarSolidIcon } from '@fiverr-private/visuals';
import { ELEMENT_NAMES } from '../../../../utils/biEvents/constants';
import { clickOnExpertCardEvent } from '../../../../utils/biEvents/events';
import { useExpertCardContext } from '../../../../contexts';
import { getOpenExpertPageLinkTarget } from '../../utils';
import propTypes from '../types';

import './index.scss';

/**
 * A component that describes user avatar.
 */
const UserAvatar = ({ source, containerClassName, onClick }) => {
    const { cardImpressionId, seller, freelancerUrl, componentName, isAgencyCard } = useExpertCardContext();
    const { username, avatarUrl, isPro } = seller;

    return (
        <a
            className={classnames('seller-avatar-wrapper', containerClassName)}
            target={getOpenExpertPageLinkTarget()}
            href={freelancerUrl}
            onClick={(e) => {
                clickOnExpertCardEvent({
                    pageName: source,
                    elementName: ELEMENT_NAMES.SELLER_AVATAR,
                    impressionId: cardImpressionId,
                    componentName,
                    seller,
                });
                if (onClick) {
                    e.preventDefault();
                    onClick(e);
                }
            }}
        >
            <Avatar size="xl" username={username} shape={isAgencyCard ? 'square' : 'circle'}>
                <AvatarImage src={avatarUrl} />
                {isPro && !isAgencyCard && (
                    <AvatarIcon padding="1.5" backgroundColor="business_800" position="bottom-right" showRing={false}>
                        <CheckStarSolidIcon color="white" />
                    </AvatarIcon>
                )}
            </Avatar>
        </a>
    );
};

UserAvatar.propTypes = {
    source: propTypes.source,
    containerClassName: string,
    onClick: propTypes.onClick,
};

export { UserAvatar };
