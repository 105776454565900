import { ListingAttributes } from '@fiverr-private/listing_types';

export class FlowNotDefinedError extends Error {
    override message: string;
    listingAttributes?: string;

    constructor(listingAttributes?: ListingAttributes) {
        super();

        this.message = 'Flow is not defined';
        this.listingAttributes = JSON.stringify(listingAttributes);
    }
}
