import React from 'react';
import { noop } from 'lodash';
import classNames from 'classnames';
import { func, string, bool, number } from 'prop-types';
import { Icon } from '@fiverr-private/fit';
import { grey_1000 } from '@fiverr-private/sass/helpers';
import { l_chevron_left } from '@fiverr-private/icons';

const Arrow = ({ className, icon, direction, onClick, disabled, iconSize }) => (
    <button
        className={classNames('arrow-btn', 'box-shadow-z1', 'flex-center', direction, className)}
        onClick={onClick}
        aria-label={direction}
        disabled={disabled}
    >
        <Icon color={grey_1000} className="chevron-icon" size={iconSize}>
            {icon}
        </Icon>
    </button>
);

Arrow.defaultProps = {
    icon: l_chevron_left,
    direction: '',
    className: '',
    disabled: false,
    onClick: noop,
    iconSize: 14,
};

Arrow.propTypes = {
    icon: func,
    direction: string,
    className: string,
    disabled: bool,
    onClick: func,
    iconSize: number,
};

export default Arrow;
