import React from 'react';
import { bool, object, string } from 'prop-types';
import classnames from 'classnames';
import { I18n } from '@fiverr-private/i18n-react';
import { convertFromUSD } from '../../../utils/currencyConverter';

import './index.scss';

export const Price = ({ containerClassName, priceInUSD, currency, isHourlyPrice }) => {
    const text = isHourlyPrice ? 'listings.expert_card.footer.hourlyPrice' : 'listings.expert_card.footer.price';

    const price = convertFromUSD(priceInUSD, currency);

    return (
        <div className={classnames('footer-price co-text-darker', containerClassName)}>
            <I18n
                k={text}
                params={{ price }}
                templates={{
                    priceSection: (text) => <span className="text-bold">{text}</span>,
                }}
            />
        </div>
    );
};

Price.propTypes = {
    priceInUSD: string.isRequired,
    currency: object,
    containerClassName: string,
    isHourlyPrice: bool,
};
