import React, { forwardRef, useCallback, useContext, useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import { isEmpty, isFunction, isString } from 'lodash';
import { FILTER } from '@fiverr-private/listing_lib';
import { PriceRange, PriceBuckets } from '@fiverr-private/listing_filters';
import { FilterContext } from '../../../../../context/filterContext';
import { AppContext } from '../../../../../context/listingsContext';
import { MIN_GIG_PRICE_RANGE, MAX_GIG_PRICE_RANGE } from '../../consts';
import { HourlyRateBudgetBanner } from './banners/HourlyRateBudgetBanner';
import { shouldShowBannerCondition } from './banners/HourlyRates.utils';
import { OrDivider } from './OrDivider';
import { CUSTOM_BUDGET_VALUE } from './constants';
import { getBudgetValue } from './utils';

import './index.scss';

const Budget = forwardRef(({ currencySymbol, setIsValidFilter, closeMenu }, forwardRef) => {
    const { activeFilters = {}, filters = [] } = useContext(FilterContext);
    const { currency, rollouts, appFilters: { disableMinPrice } = {} } = useContext(AppContext);
    const [budgetValue, setBudgetValue] = useState(getBudgetValue(activeFilters));
    const minInputRef = useRef({});
    const isCustomBucketSelected = !!(budgetValue === CUSTOM_BUDGET_VALUE || budgetValue?.min || budgetValue?.max);

    const { options: priceBucketOptions = [] } = filters.find(({ id }) => id === FILTER.PRICE_BUCKETS.ID) || {};
    const hasPriceBuckets = priceBucketOptions.length > 0;

    const getValues = useCallback(() => {
        if (isEmpty(budgetValue) || budgetValue === CUSTOM_BUDGET_VALUE) {
            return;
        }

        let { min, max } = budgetValue;

        if (isString(budgetValue)) {
            return { [FILTER.PRICE_BUCKETS.ID]: budgetValue };
        }

        if (max && min > max) {
            max = budgetValue.min;
            min = budgetValue.max;
        }

        return { [FILTER.GIG_PRICE_RANGE.ID]: [min || MIN_GIG_PRICE_RANGE, max || MAX_GIG_PRICE_RANGE] };
    }, [budgetValue]);

    const syncValues = () => {
        setBudgetValue({});
    };

    const onMenuOpen = () => {
        !hasPriceBuckets && !disableMinPrice && isFunction(minInputRef?.current?.focus) && minInputRef.current.focus();
    };

    const onPriceBucketChange = ({ target: { value } }) => {
        setBudgetValue(value);
        value === CUSTOM_BUDGET_VALUE && isFunction(minInputRef?.current?.focus) && minInputRef.current.focus();
    };

    useEffect(() => {
        if (forwardRef) {
            forwardRef.current = { getValues, syncValues, onMenuOpen };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getValues]);

    return (
        <div
            className={classNames('budget-filter', {
                'has-price-buckets': hasPriceBuckets,
                'min-price-disable': disableMinPrice,
            })}
        >
            <HourlyRateBudgetBanner className="m-b-8" closeMenu={closeMenu} />
            {shouldShowBannerCondition({ rollouts }) && <OrDivider />}
            {hasPriceBuckets && (
                <PriceBuckets
                    priceBucketOptions={priceBucketOptions}
                    setBudgetValue={setBudgetValue}
                    budgetValue={budgetValue}
                    minInputRef={minInputRef}
                    onChange={onPriceBucketChange}
                    currency={currency}
                />
            )}
            <PriceRange
                currencySymbol={currencySymbol}
                currency={currency}
                minInputRef={minInputRef}
                setBudgetValue={setBudgetValue}
                budgetValue={budgetValue}
                isCustomBucketSelected={isCustomBucketSelected}
                hasPriceBuckets={hasPriceBuckets}
                onChange={onPriceBucketChange}
                setIsValidFilter={setIsValidFilter}
                disableMinPrice={disableMinPrice}
            />
        </div>
    );
});

Budget.displayName = 'Budget';

export default Budget;
