const translateSilentFail = (key, locale) => {
    const alternatives = i18n.alternatives(key, { $scope: locale });
    return i18n.find(...alternatives);
};

const getPricingFactorTranslationKey = (pricingFactorKey, dominateSubCategoryId, locale) => {
    const keyPrefix = `pricing_factors.${pricingFactorKey}`;

    // TODO: Change this key to be from pricing_factors (Cheetahs) copies
    const key = `search_perseus.filters.global.pricing_factor_skeleton.${keyPrefix}.sub_category_${dominateSubCategoryId}.unit`;

    const subCategoryUnit = translateSilentFail(key, locale);

    if (subCategoryUnit) {
        return key;
    }

    return `${keyPrefix}.unit`;
};

const getPricingFactorOptionAlias = ({ count, translationKey, locale }) =>
    i18n.t(translationKey, { count, $scope: locale });

module.exports = {
    getPricingFactorTranslationKey,
    getPricingFactorOptionAlias,
};
