import React, { useContext } from 'react';
import classNames from 'classnames';
import { Button } from '@fiverr-private/button';
import { Stack } from '@fiverr-private/layout_components';
import { Typography } from '@fiverr-private/typography';
import { FILTERS_LOCATION } from '@fiverr-private/listing_lib';
import { MetadataFilterOptionType } from '@fiverr-private/listing_types';
import { VIEW_ALL_COMPONENT } from '../../top_filters_carousel/constants';
import { MetadataFilterViewAllTile } from '../MetadataFilterViewAllTile/MetadataFilterViewAllTile';
import { filterNavigation } from '../../../../service/clientNavigation';
import { AppContext } from '../../../context/listingsContext';
import { BI_SOURCES } from '../../../../utils/constants';
import { TOP_GROUPING } from '../../../../config/filters';
import { MetadataFilterTileCount } from '../MetadataFilterTileCount/MetadataFilterTileCount';
import { buttonStyles, outlinedButton } from './MetadataFilterTile.ve.css';

export interface MetadataFilterTileProps {
    filterId: string;
    id: string;
    count?: number;
    alias?: string;
    filterTitle?: string;
    options?: MetadataFilterOptionType[];
}

export const MetadataFilterTile = ({
    filterId,
    id,
    count,
    alias,
    options = [],
    filterTitle = '',
}: MetadataFilterTileProps) => {
    const { activeFilters } = useContext(AppContext);

    if (id === VIEW_ALL_COMPONENT) {
        return <MetadataFilterViewAllTile filterId={filterId} filterTitle={filterTitle} options={options} />;
    }

    const onClick = () => {
        const changedFilters = { [filterId]: id };

        // TODO: Adjust source and location to what the analysts want
        filterNavigation(
            changedFilters,
            activeFilters,
            FILTERS_LOCATION.EXPOSED_FILTERS,
            BI_SOURCES.METADATA_FILTERS,
            TOP_GROUPING.METADATA_FILTERS
        );
    };

    return (
        <Button variant="outline" className={classNames(buttonStyles, outlinedButton)} onClick={onClick}>
            <Stack gap="2">
                <Typography fontWeight="bold">{alias}</Typography>
                <MetadataFilterTileCount count={count} />
            </Stack>
        </Button>
    );
};
