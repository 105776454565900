import { useState } from 'react';

const usePagination = ({ onLoadMore, initialPaginationParams }) => {
    const [paginationParams, setPaginationParams] = useState(initialPaginationParams);
    const [isLoading, setIsLoading] = useState(false);

    const onPagination = async () => {
        try {
            setIsLoading(true);
            const newPaginationParams = await onLoadMore({ paginationParams });
            setPaginationParams(newPaginationParams);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            throw error;
        }
    };

    return { isLoading, onPagination };
};

export default usePagination;
