import { uniqBy } from 'lodash';
import { getUgcData } from '@fiverr-private/gig_listings/src/GigCardListings/externalUtils/getUgcData';
import ACTION_TYPES from '../../../components/context/listingsContextReducerActionTypes';
import { maxios } from '../../../../../shared/utils/request';
import { ROUTE_KEYS } from '../../../../../shared/utils/request/constants';
import { FETCH_TRANSLATED_UGC_URL } from './constants';

export const fetchTranslationData = async ({
    flatGigsListings = [],
    flatRepeatedBuyer = [],
    flatFtbFriendly = [],
    flatExpressRecommendation = [],
    flatFiverrChoice = [],
    flatSellersWhoSpeak = [],
    hidePrefix,
    dispatch,
    sourceInfo,
    locale,
    abTests,
    knownCrawler,
}) => {
    try {
        dispatch({ type: ACTION_TYPES.TOGGLE_LOADING, loading: true });

        const gigs = uniqBy(
            flatGigsListings
                .concat(flatRepeatedBuyer)
                .concat(flatFtbFriendly)
                .concat(flatExpressRecommendation)
                .concat(flatFiverrChoice)
                .concat(flatSellersWhoSpeak),
            ({ gig_id }) => gig_id
        );

        const ugcData = getUgcData(gigs, locale, hidePrefix);

        return await maxios.post(
            FETCH_TRANSLATED_UGC_URL,
            { gigsListings: ugcData, sourceInfo, knownCrawler, abTests, locale },
            { routeKey: ROUTE_KEYS.FETCH_TRANSLATED_UGC }
        );
    } catch (error) {
        return null;
    } finally {
        dispatch({ type: ACTION_TYPES.TOGGLE_LOADING, loading: false });
    }
};
