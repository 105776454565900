import React, { useContext } from 'react';
import classNames from 'classnames';
import { string } from 'prop-types';
import { tracker, EVENTS_MAPPING } from '@fiverr-private/listing_lib';
import { CarouselContext } from '../../top_filters_carousel';
import { AppContext } from '../../../context/listingsContext';
import { VIEW_ALL_COMPONENT } from '../../top_filters_carousel/constants';
import { BI_SOURCES } from '../../../../utils/constants';
import { TOP_GROUPING } from '../../../../config/filters';
import ViewAllTile from '../view_all_tile/viewAllTile';
import { LIST_TYPES } from '../view_all_tile/constants';
import { TILE_TYPES } from '../../tiles_carousel/constants';
import { applyFilter } from './utils';
import imageMapper from './imageMapper';

import '../index.scss';

const FilterTile = ({ id, alias }) => {
    const { filterId, subCategoryId, tileSize, allFilters, setStepIndex, stepIndex, hasNextStep, stepNum } =
        useContext(CarouselContext);
    const { activeFilters } = useContext(AppContext);
    const subCategoryIcons = imageMapper[subCategoryId];
    const image = subCategoryIcons && subCategoryIcons[id];

    const clickedViewAll = () => {
        tracker.trackTileFilter(EVENTS_MAPPING.FILTERS_VIEW_ALL, filterId, stepNum);
    };

    const onTileClick = () => {
        hasNextStep && setStepIndex(stepIndex + 1);

        applyFilter({
            filterId,
            value: id,
            activeFilters,
            stepNum,
            source: BI_SOURCES.VISUAL_FILTERS,
            groupName: TOP_GROUPING.VISUAL_FILTERS,
        });
    };

    if (id === VIEW_ALL_COMPONENT) {
        return (
            <ViewAllTile
                alias={alias}
                tileSize={tileSize}
                tileType={TILE_TYPES.CHIP_TILE}
                items={allFilters}
                listType={LIST_TYPES.FILTERS}
                onOpen={clickedViewAll}
            />
        );
    }

    return (
        <button className={classNames('filter-tile', TILE_TYPES.CHIP_TILE, tileSize)} onClick={onTileClick}>
            <div className="chip-tile-icon-wrapper">
                <img src={image} alt={alias} loading="lazy" />
            </div>
            <span className="chip-tile-text tbody-5">{alias}</span>
        </button>
    );
};

FilterTile.propTypes = {
    id: string,
    alias: string,
};

export default FilterTile;
