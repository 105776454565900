import { isEmpty } from 'lodash';
import { ListingAttributes, FlowName, ActiveFilters, CategoryTree, ListingsType } from '@fiverr-private/listing_types';
import { activeFiltersToQueryString } from '../activeFiltersToQueryString/activeFiltersToQueryString';
import { buildURLPathname } from '../buildURLPathname/buildURLPathname';
import { updateURLQueryParams } from '../updateURLQueryParams/updateURLQueryParams';
import { LISTINGS_TYPE_QUERY_PARAM } from '../../constants';

const getFilterRelatedQueryParams = ({
    categoryIds,
    activeFilters,
}: {
    categoryIds?: Partial<CategoryTree>;
    activeFilters?: ActiveFilters;
}): { ref: string; sub_category?: string; nested_sub_category?: string } => {
    const { leaf_category: categoryFilter, ...activeFiltersWithoutCategoryFilter } = activeFilters || {};

    const isCategoryFilterActive = !isEmpty(categoryFilter);

    return {
        ref: activeFiltersToQueryString(activeFiltersWithoutCategoryFilter),
        sub_category: isCategoryFilterActive ? categoryIds?.subCategoryId : undefined,
        nested_sub_category: isCategoryFilterActive ? categoryIds?.nestedSubCategoryId : undefined,
    };
};

const FLOW_NAME_TO_LISTING_TYPE: Record<FlowName, ListingsType | undefined> = {
    roleSearchFlow: 'roles',
    gigProfessionSearchFlow: 'gigs',
    gigSearchFlow: undefined,
    gigCategoriesFlow: undefined,
    gigBusinessSearchFlow: undefined,
    gigBusinessCategoriesFlow: undefined,
};

export const buildListingPageUrl = (
    window: Window,
    listingAttributes: ListingAttributes,
    flowName: FlowName,
    source?: string
) => {
    const {
        id,
        query,
        sortBy,
        activeFilters,
        page,
        pageSize,
        offset,
        utmCampaign,
        utmMedium,
        utmSource,
        categoryIds,
        shouldSuggest,
        leafClassification,
    } = listingAttributes;

    const url = new URL(window.location.href);

    url.pathname = buildURLPathname(flowName, categoryIds);

    // TODO to a util
    const chat_id = url.searchParams.get('chat_id') ?? undefined;
    const stream_id = url.searchParams.get('stream_id') ?? undefined;

    const { ref, sub_category, nested_sub_category } = getFilterRelatedQueryParams({ activeFilters, categoryIds });

    const queryParams = {
        query,
        source,
        filter: sortBy,
        page: (page || 1).toString(),
        offset: offset?.toString(),
        utm_campaign: utmCampaign,
        utm_medium: utmMedium,
        utm_source: utmSource,
        limit: pageSize?.toString(),
        should_suggest: shouldSuggest?.toString(),
        ref,
        sub_category,
        nested_sub_category,
        leaf_classification_id: leafClassification?.toString(),
        chat_id,
        stream_id,
        ref_ctx_id: id,
        [LISTINGS_TYPE_QUERY_PARAM]: FLOW_NAME_TO_LISTING_TYPE[flowName]?.toString(),
    };

    const uri = updateURLQueryParams(url, queryParams);

    return uri.toString();
};
