import { LabelList, RadioList } from '@fiverr-private/listing_filters';
import { MENU_CONTENT_TYPES } from '../consts';
import CheckboxList from './checkbox_list/CheckboxList';
import Budget from './budget';
import MultiPairsSelect from './multi_pairs_select/MultiPairsSelect';
import MoreFiltersList from './MoreFiltersList';

export const CONTENT_MAPPING = {
    [MENU_CONTENT_TYPES.BUDGET]: Budget,
    [MENU_CONTENT_TYPES.CHECKBOX_LIST]: CheckboxList,
    [MENU_CONTENT_TYPES.FILTERS_GROUP]: MoreFiltersList,
    [MENU_CONTENT_TYPES.RADIO_LIST]: RadioList,
    [MENU_CONTENT_TYPES.LABELS_LIST]: LabelList,
    [MENU_CONTENT_TYPES.MULTI_PAIRS_SELECT]: MultiPairsSelect,
};

export { CheckboxList, LabelList, RadioList, MultiPairsSelect, MoreFiltersList };
