const replaceAll = (src, search, replace) => src.replace(new RegExp(search, 'g'), replace);
const trim = (src) => src.trim();
const lower = (src) => src.toLowerCase();
const stripNonAlphaNumeric = (src, replacement) => src.replace(/\W/g, replacement);

const execute = (ref, method, args = []) => {
    ref.value = method.call(ref, ...[ref.value, ...args]);
    return ref;
};

module.exports = {
    replaceAll,
    manipulator: function (source) {
        const inner = {
            source,
            value: source,
            replaceAll: (search, replace) => execute(inner, replaceAll, [search, replace]),
            trim: () => execute(inner, trim),
            lower: () => execute(inner, lower),
            stripNonAlphaNumeric: (replace) => execute(inner, stripNonAlphaNumeric, [replace]),
            result: () => inner.value,
        };
        return inner;
    },
};
