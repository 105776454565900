import React from 'react';
import classnames from 'classnames';
import { noop, isEmpty } from 'lodash';
import { useImpressionContainer, useImpressionItem } from '@fiverr-private/impressionable';
import { Container, Stack } from '@fiverr-private/layout_components';
import { Text } from '@fiverr-private/typography';
import { useIsClient } from '@fiverr-private/hooks';
import { CollectionsProvider } from '@fiverr-private/collect_actions';
import { CARD_VARIANT } from '../../shared/constants';
import ExpertCard from '../../components/ExpertCard';
import { LAYOUT_CLASSNAME, LISTINGS_LAYOUT } from '../../constants';
import { ExpertCardSkeleton } from '../../components/ExpertCard/Skeleton';
import propTypes from './types';

import './index.scss';

/**
 * @typedef ExpertCardListingsProps
 * @property {import('prop-types').InferProps<typeof propTypes>['displayOptions']} displayOptions
 */

/**
 * An entry for Expert Card Listings, includes responsive design, mobile support and impression events
 *
 * @param {Partial<ExpertCardListingsProps>} props
 */
const ExpertCardListings = ({
    items = [],
    currency = {},
    layout = LISTINGS_LAYOUT.LIST,
    containerImpressionEnrichment = {},
    onImpression = noop,
    source,
    cardVariant = CARD_VARIANT.DEFAULT,
    forceSmallCard = false,
    displayOptions = {},
    renderFooterContent,
    banner,
    saveToListProps = {},
    notes,
    inProCatalogGridExp,
    showHourlyPrice = false,
    hourlyRateRolloutActive = false,
    isLoading = false,
    skeletonAmount = 3,
}) => {
    const [ref, containerId] = useImpressionContainer();
    useImpressionItem({
        selfRef: ref,
        selfId: containerId,
        sendImpressionToBQ: false,
        intersectionThreshold: 0,
        enrichment: containerImpressionEnrichment,
        onImpression,
    });

    const isClient = useIsClient();
    const { position: bannerPosition, Component: BannerComponent } = banner || {};

    // eslint-disable-next-line react/prop-types
    const BannerWrapper = ({ bannerPosition }) => (
        <div style={{ gridArea: `${bannerPosition + 1} / 1 / span 1 / -1` }}>
            <BannerComponent />
        </div>
    );

    return (
        <CollectionsProvider bi={{ source }}>
            <div className={classnames('listings-expert-cards-section', LAYOUT_CLASSNAME[layout])} ref={ref}>
                <div className={classnames('listings-expert-cards')}>
                    {isLoading
                        ? Array.from({ length: skeletonAmount }).map((_, index) => (
                              <ExpertCardSkeleton key={index} isVisual={cardVariant === CARD_VARIANT.VISUAL} />
                          ))
                        : items.map((item, index) => (
                              <>
                                  {BannerComponent && bannerPosition === index && (
                                      <BannerWrapper bannerPosition={bannerPosition} />
                                  )}
                                  <Stack gap="2" display={isEmpty(notes) ? 'contents' : 'flex'} direction="column">
                                      <ExpertCard
                                          key={item.seller.username}
                                          {...item}
                                          displayOptions={displayOptions}
                                          position={index + 1}
                                          cardVariant={cardVariant}
                                          forceSmallCard={forceSmallCard}
                                          currency={currency}
                                          source={source}
                                          onImpression={onImpression}
                                          lazyLoadAssets={!isClient}
                                          renderFooterContent={renderFooterContent}
                                          saveToListProps={saveToListProps}
                                          inProCatalogGridExp={inProCatalogGridExp}
                                          showHourlyPrice={showHourlyPrice}
                                          hourlyRateRolloutActive={hourlyRateRolloutActive}
                                      />
                                      {notes?.[item.seller.username] && (
                                          <Container backgroundColor="grey_200" borderRadius="lg">
                                              <Text className="note" padding="4">
                                                  {notes[item.seller.username]}
                                              </Text>
                                          </Container>
                                      )}
                                  </Stack>
                              </>
                          ))}
                </div>
            </div>
        </CollectionsProvider>
    );
};

ExpertCardListings.propTypes = propTypes;
ExpertCardListings.LAYOUTS = LISTINGS_LAYOUT;

export default ExpertCardListings;
