import React from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import { ComboBox } from '@fiverr-private/orca';

const ComboBoxInput = ComboBox.components.Input;
const ComboBoxNoOptionsMessage = ComboBox.components.NoOptionsMessage;

export const Input = (props) => <ComboBoxInput {...props} isHidden={false} />;

export const NoOptionsMessage = (props) => (
    <ComboBoxNoOptionsMessage {...props}>
        <I18n k="search_perseus.filter.no_results" />
    </ComboBoxNoOptionsMessage>
);
