import { isEmpty } from 'lodash';
import { useIsClient } from '@fiverr-private/hooks';
import { ClientInitialProps, FlowName, ListingAttributes } from '@fiverr-private/listing_types';
import { ProfessionFiltersProps } from '../../side_filters/ExposedFilters/types';
import { FALLBACK_IMAGES, PROFESSION_FILTER_MAX_ITEMS_TO_SHOW } from '../constants';
import { buildOptionUrl } from './buildOptionUrl';
import { buildImageMapper } from './buildImageMapper';

export interface ProfessionFilterCarouselItem {
    title: string;
    imageUrl?: string;
    linkUrl?: string;
}

export const useProfessionFilterCarouselItems = ({
    professionFilterCarouselImages,
    professionFilter,
    listingAttributes,
    flowName,
    query,
}: {
    professionFilterCarouselImages: ClientInitialProps['professionFilterCarouselImages'];
    professionFilter: ProfessionFiltersProps['professionFilter'];
    listingAttributes: ListingAttributes;
    flowName: FlowName;
    query: string;
}) => {
    const isClient = useIsClient();
    if (isEmpty(professionFilter)) {
        return [];
    }

    const { options } = professionFilter;
    const optionsToShow = options.slice(0, PROFESSION_FILTER_MAX_ITEMS_TO_SHOW);

    const imagesList = professionFilterCarouselImages.length === 0 ? FALLBACK_IMAGES : professionFilterCarouselImages;

    // since images are kept in localStorage, and SSR has no access to it, we need to check if we are on the client side
    const imageMapper = isClient
        ? buildImageMapper({ options: optionsToShow, professionFilterCarouselImages: imagesList, query })
        : {};

    const carouselItems: ProfessionFilterCarouselItem[] = optionsToShow.map((option) => {
        const item = {
            title: option.alias,
            linkUrl: isClient ? buildOptionUrl({ option, professionFilter, listingAttributes, flowName }) : '',
            imageUrl: imageMapper[option.id],
        };
        return item;
    });

    return carouselItems;
};
