import { defaults, toNumber } from 'lodash';
import { getContext } from '@fiverr-private/fiverr_context';
import {
    CONTEXT_PARAMS_DEFAULTS,
    SEARCH_AUTOCOMPLETE_ORIGINAL_TERM,
    SEARCH_AUTOCOMPLETE_POSITION,
    SEARCH_AUTOCOMPLETE_TYPE,
} from '../../../../utils/constants';
import { CONTEXT_ENUM } from '../../../../../../shared/context/ListingsImpressionContainer/constants/contextEnum';
import {
    getActiveExperiments,
    getFilters,
    getFiverrContext,
    getPageContext,
    getSortType,
    getTogglesList,
    getViewType,
} from '../../../common/listings/impressionEnrichment';

export const getImpressionEnrichment = ({
    appFilters,
    activeFilters,
    categoryIds,
    isSearch,
    flow,
    dominateSubCategoryId,
    significantLeafCategories,
    modifiedQueryString,
    nonTranslatedQuery,
    translatedQuery,
    querySourceLanguage,
    activeExperiments,
    numberOfResults,
    defaultFilters,
}) => {
    const context = getPageContext(flow);
    const filters = getFilters(appFilters, activeFilters, defaultFilters);
    const { queryParameters = {} } = getContext();
    const { page, view_type, filter } = defaults(queryParameters, CONTEXT_PARAMS_DEFAULTS);
    const componentNamePrefix = isSearch ? 'search' : 'category';

    const result = {
        name: `${componentNamePrefix}_expert_listings_page`,
        enrichment: {
            listing: {
                listing_attributes: {
                    results_number: numberOfResults,
                    page_num: page,
                    sorter: getSortType(filter),
                    context,
                    fiverr_context: getFiverrContext(flow),
                    view_type: getViewType(view_type),
                    toggles_list: getTogglesList(appFilters?.filters),
                    active_experiments: getActiveExperiments(activeExperiments),
                    ...filters,
                },
            },
        },
    };

    const {
        enrichment: {
            listing: { listing_attributes },
        },
    } = result;

    if (context === CONTEXT_ENUM.SUBCATEGORY) {
        listing_attributes.category_attributes = {
            category_id: categoryIds.categoryId,
            subcategory_id: categoryIds.subCategoryId,
            nested_subcategory_id: categoryIds.nestedSubCategoryId,
            leaf_category_id: categoryIds.nestedSubCategoryId || categoryIds.subCategoryId,
        };
    } else if (context === CONTEXT_ENUM.SEARCH) {
        const {
            pos,
            query,
            [SEARCH_AUTOCOMPLETE_ORIGINAL_TERM]: autocomplete_original_term,
            [SEARCH_AUTOCOMPLETE_TYPE]: autocomplete_type,
            [SEARCH_AUTOCOMPLETE_POSITION]: autocomplete_position,
        } = queryParameters;

        listing_attributes.search_attributes = {
            dominant_leaf_category: dominateSubCategoryId,
            significant_leaf_categories: significantLeafCategories,
            search_query: nonTranslatedQuery ? nonTranslatedQuery : query,
            modified_search_query: modifiedQueryString,
            ...(translatedQuery && { translated_search_query: translatedQuery }),
            ...(querySourceLanguage && { query_source_language: querySourceLanguage }),
            ...(autocomplete_type && {
                autocomplete: {
                    original_term: autocomplete_original_term,
                    type: autocomplete_type,
                    position: autocomplete_position && Number(autocomplete_position),
                },
            }),
            ...(pos && {
                related: {
                    position: toNumber(pos),
                },
            }),
        };
    }

    return result;
};
