import React from 'react';
import { Icon } from '@fiverr-private/fit';
import { r_heart } from '@fiverr-private/fit/icons';
import { getContext } from '@fiverr-private/fiverr_context';
import { I18n } from '@fiverr-private/i18n-react';
import { HIGHLIGHTS_DATA_KEYS } from '../../../shared/constants';
import { highlightsDataType } from '../types';

const SavedByTeamMember = ({ highlightsData = {} }) => {
    const { userId } = getContext();
    const savedBy = highlightsData[HIGHLIGHTS_DATA_KEYS.SAVED_BY];

    if (!savedBy) {
        return null;
    }

    const { id, fullName } = savedBy;
    const savedByYou = userId === id;

    return (
        <>
            <Icon className="seller-highlight-icon">{r_heart}</Icon>
            {savedByYou ? (
                <I18n k={'listings.highlights.saved_by_you'} />
            ) : (
                <I18n k={'listings.highlights.saved_by_team_member'} params={{ fullName }} />
            )}
        </>
    );
};

SavedByTeamMember.propTypes = {
    highlightsData: highlightsDataType,
};

export default SavedByTeamMember;
