import { isString } from 'lodash';
import { stats } from '@fiverr-private/obs';
import { ListingAttributes } from '@fiverr-private/listing_types';
import { InvalidSearchQueryError } from '../../errors';

const queryLengthValid = (query?: string) => isString(query) && query.trim() && query.length <= 80;
const latinCharactersOnly = (query?: string) =>
    isString(query) && /^([_A-Za-z\0-9\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/.test(query);
const reportQueryValidationCount = (key: string) =>
    stats.count('services.search_perseus', `validation.query.${key}`, 1);

export const validateQuery = ({ query }: ListingAttributes): void => {
    if (!queryLengthValid(query)) {
        reportQueryValidationCount('length');
        throw new InvalidSearchQueryError('Bad request length is not valid', query);
    }
    if (!latinCharactersOnly(query)) {
        reportQueryValidationCount('illegal_chars');
        throw new InvalidSearchQueryError('Bad request non Latin characters present', query);
    }
};
