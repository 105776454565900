import React, { useRef, useState, useEffect, useContext, useCallback } from 'react';
import classNames from 'classnames';
import { AppContext } from '../../../context/listingsContext';
import { fixedStickyBarStyles } from './StickyBar.ve.css';

const isIntersectionObserverExists = typeof IntersectionObserver !== 'undefined';

const StickyBar = ({ children, isFixed = false, setIsFixed, variant = 'base' }) => {
    const topbarNode = useRef(null);
    const { isExpertListings } = useContext(AppContext);
    const [isSticky, setIsSticky] = useState(false);

    const onElementSticky = useCallback(
        ([entry]) => {
            const isIntersectingFromTop = entry.intersectionRatio < 1 && entry.intersectionRect.y === 0;

            if (variant === 'inner') {
                setIsFixed(isIntersectingFromTop);
            }

            if (variant === 'base') {
                setIsSticky(isIntersectingFromTop);
            }
        },
        [variant, setIsFixed, setIsSticky]
    );

    useEffect(() => {
        const observer = isIntersectionObserverExists && new IntersectionObserver(onElementSticky, { threshold: 1 });
        const currentTopBarNode = topbarNode.current;

        if (variant !== 'outer' && isIntersectionObserverExists) {
            observer.observe(currentTopBarNode);
        }

        return () => {
            observer.unobserve(currentTopBarNode);
        };
    }, [topbarNode, variant, onElementSticky]);

    const shouldAddStickyShadowStyles = (variant === 'base' && isSticky) || (variant === 'outer' && isFixed);

    return (
        <div
            id="topbar"
            ref={topbarNode}
            className={classNames('sticky-wrapper', {
                'is-sticky box-shadow-z4': shouldAddStickyShadowStyles,
                [fixedStickyBarStyles]: variant === 'outer',
            })}
        >
            <div className="shadow-effect">
                <div className={classNames('floating-top-bar', { 'expert-listings': isExpertListings })}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default StickyBar;
