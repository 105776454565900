import { CARD_VARIANT, ENGINE_TYPE } from '../../../shared/constants';
import { convertFromUSD } from '../../../utils/currencyConverter';
import { getBadges, getExpertiseType, getHighlights, getLevel, getNotableClients } from './utils';

export const getName = (cardVariant) => {
    const cardName = cardVariant === CARD_VARIANT.VISUAL ? 'experiential' : 'non_experiential';
    return `expert_card_${cardName}`;
};

// todo should use bianka js, but for now cant update package from v2 to v4
const PRICE_TYPES = {
    PER_PROJECT: 1,
    PER_HOUR: 2,
};

export const getImpressionEnrichment = ({
    rating,
    completedOrdersCount,
    isSellerOnline,
    expertises = [],
    highlights = {},
    highlightsData = {},
    seller: { id: sellerId, username, jobTitle, countryCode, badges = [], isPro, achievementLevel } = {},
    notableClients = [],
    priceInUsd,
    currency = {},
    gigId,
    gigEngine,
    cardVariant,
    componentName = getName(cardVariant),
    hourlyCard = false,
}) => ({
    name: componentName,
    enrichment: {
        expert_card: {
            seller: {
                id: sellerId,
            },
            gig: {
                id: gigId,
            },
            expert_card_attributes: {
                shown_seller_rating: rating,
                completed_orders: completedOrdersCount,
                is_online: isSellerOnline,
                badge: getBadges(badges),
                expertise: expertises.map((expertise, index) => ({
                    id: expertise.id,
                    type: getExpertiseType(expertise.type),
                    position: index + 1,
                })),
                highlights: getHighlights(highlights, highlightsData),
                seller: {
                    username,
                    title: jobTitle,
                    is_pro: isPro,
                    level: getLevel(achievementLevel),
                    location: countryCode,
                },
                notable_client: getNotableClients(notableClients),
                price_usd: priceInUsd,
                price_type: hourlyCard ? PRICE_TYPES.PER_HOUR : PRICE_TYPES.PER_PROJECT,
                shown_price: priceInUsd ? convertFromUSD(priceInUsd, currency, true) : 0,
                engine: gigEngine ?? ENGINE_TYPE.DEFAULT,
            },
        },
    },
});
