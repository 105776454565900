import React from 'react';
import classnames from 'classnames';
import { string } from 'prop-types';
import { ELEMENT_NAMES } from '../../../../utils/biEvents/constants';
import { clickOnExpertCardEvent } from '../../../../utils/biEvents/events';
import { useExpertCardContext } from '../../../../contexts';
import { getOpenExpertPageLinkTarget, getSellerDisplayName } from '../../utils';
import propTypes from '../types';

import './index.scss';

/**
 * A component that describes seller name.
 */
const SellerName = ({ isCardHovered, source, onClick, className }) => {
    const { cardImpressionId, seller, freelancerUrl, componentName } = useExpertCardContext();
    const sellerName = getSellerDisplayName(seller);

    return (
        <div
            className={classnames('seller-name-container', 'text-display-6', className, {
                'seller-min-size': sellerName?.length > 8,
            })}
        >
            <div className="seller-row-ellipsis flex flex-items-center">
                <a
                    className={classnames('seller-name co-text-darkest', { 'card-hovered': isCardHovered })}
                    target={getOpenExpertPageLinkTarget()}
                    href={freelancerUrl}
                    onClick={(e) => {
                        clickOnExpertCardEvent({
                            pageName: source,
                            elementName: ELEMENT_NAMES.SELLER_NAME,
                            impressionId: cardImpressionId,
                            componentName,
                            seller,
                        });
                        if (onClick) {
                            e.preventDefault();
                            onClick(e);
                        }
                    }}
                >
                    {sellerName}
                </a>
            </div>
        </div>
    );
};

SellerName.propTypes = {
    isCardHovered: propTypes.isCardHovered,
    source: propTypes.source,
    onClick: propTypes.onClick,
    className: string,
};

export default SellerName;
