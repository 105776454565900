import React from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import { Stack } from '@fiverr-private/layout_components';
import { Link, Text } from '@fiverr-private/typography';
import { ReasonForModification } from '@fiverr-private/listing_types';

interface SearchOriginalQueryProps {
    originalQuery?: string;
    searchInsteadUrl?: string;
    reasonForModification?: ReasonForModification;
}

export const SearchOriginalQuery = ({
    originalQuery,
    searchInsteadUrl,
    reasonForModification,
}: SearchOriginalQueryProps) => {
    if (reasonForModification !== 'spellcheck') {
        return null;
    }

    return (
        <Stack color="grey_900" gap="1">
            <Text color="bodySecondary" size="b_lg">
                <I18n k="listings_header.search_header.search_instead_for" />
                <Link color="inherit" href={searchInsteadUrl}>
                    {originalQuery}
                </Link>
            </Text>
        </Stack>
    );
};
