import React, { useState, useEffect, useContext } from 'react';
import { isEmpty } from 'lodash';
import SmallGigCardCarousel from '@fiverr-private/gig_listings/src/entries/SmallGigCardCarousel';
import { logger } from '@fiverr-private/obs';
import { AppContext } from '../../../../../store';
import recentlyViewedService from '../../../../../service/recentlyViewed';
import { RecentlyViewedTypes } from '../../../../../types';
import { sendImpressionEvent } from '../../../../../../../shared/context/ListingsImpressionContainer/utils/sendImpressionEvent/sendImpressionEvent';
import { isEligibleForNewImpressions } from '../../../../../../../shared/context/ListingsImpressionContainer/utils/isEligibleForNewImpressions/isEligibleForNewImpressions';
import { SECTIONS } from '../../../PersonalizedSectionWrapper/constants';
import { RecentlyViewedCarouselHeader } from './RecentlyViewedCarouselHeader';

import './index.scss';

const extractRecentlyViewed = ({ personalizedContent = {} }) => {
    const section = personalizedContent?.sections?.[SECTIONS.BOTTOM]?.[0];

    if (!section) {
        return {};
    }

    const { items: { gigs = [] } = {}, contextEnrichment = {} } = section;

    return {
        ...contextEnrichment,
        gigs,
    };
};

const RecentlyViewedCarousel = (props) => {
    const appContext = useContext(AppContext);
    const {
        user,
        showTranslatedUGC,
        inReportNewImpressionsRollout,
        listings: [{ features, hidePrefix, context } = {}] = [],
        isLoadingListings,
    } = appContext;
    const { activeFilters, sorting, shouldFetchData = true, personalizedContent = {} } = props;
    const { loggedIn } = user;

    const [listings, setListings] = useState([
        {
            gigs: [],
            showTranslatedUGC,
            hidePrefix,
            features,
        },
    ]);

    useEffect(() => {
        if (!shouldFetchData && !isEmpty(personalizedContent)) {
            const recentlyViewedData = extractRecentlyViewed({ personalizedContent });

            setListings(([listings]) => [
                {
                    ...listings,
                    ...recentlyViewedData,
                },
            ]);
        }
    }, [shouldFetchData, personalizedContent]);

    useEffect(() => {
        if (!shouldFetchData) {
            return;
        }

        recentlyViewedService
            .fetch(sorting, activeFilters, appContext)
            .then((listings) => {
                setListings([{ ...listings }]);
            })
            .catch((err) => {
                logger.error(err, { message: 'Failed to fetch recently viewed gigs.' });
            });
    }, [activeFilters, sorting, appContext, shouldFetchData]);

    const showCarousel = listings[0].gigs.length > 0;
    const showSeeAll = loggedIn;
    const onCarouselItemClear = recentlyViewedService.clearRecentlyViewedGig(loggedIn);

    const shouldUseNewImpressions =
        context && isEligibleForNewImpressions({ inReportNewImpressionsRollout, currentPage: context?.currentPage });

    const clearGigs = () => {
        setListings([{ gigs: [] }]);

        recentlyViewedService.clearAllRecentlyViewedGigs(loggedIn);
    };

    return showCarousel ? (
        <div className="recently-viewed-carousel">
            <SmallGigCardCarousel
                onClearGig={onCarouselItemClear}
                {...listings[0]}
                isLoading={isLoadingListings}
                lazyLoad={false}
                showTopRightArrows={true}
                HeaderComponent={<RecentlyViewedCarouselHeader clearGigs={clearGigs} showSeeAll={showSeeAll} />}
                onImpression={(impressionData) => {
                    shouldUseNewImpressions && sendImpressionEvent(impressionData);
                }}
            />
        </div>
    ) : null;
};

RecentlyViewedCarousel.propTypes = RecentlyViewedTypes;

export default RecentlyViewedCarousel;
