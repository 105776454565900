import React from 'react';
import PropTypes from 'prop-types';
import { Input, InputGroup, InputPrefix } from '@fiverr-private/forms';
import { MAX_GIG_PRICE_RANGE, MIN_GIG_PRICE_RANGE } from '../../../../../common/floating_topbar/menus/consts';

import styles from './index.module.scss';

export const PriceInput = ({ value, currencySymbol, placeholder = '', onInputChange, isError, isDisabled }) => (
    <div className={styles.priceInputContainer}>
        <InputGroup disabled={isDisabled} isError={isError}>
            <Input
                type="number"
                min={MIN_GIG_PRICE_RANGE}
                max={MAX_GIG_PRICE_RANGE}
                value={value}
                placeholder={placeholder}
                onChange={onInputChange}
            />
            <InputPrefix>{currencySymbol}</InputPrefix>
        </InputGroup>
    </div>
);

PriceInput.propTypes = {
    value: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
    currencySymbol: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    onInputChange: PropTypes.func.isRequired,
    isError: PropTypes.bool,
};
