import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { string, number } from 'prop-types';
import { Tooltip } from '@fiverr-private/tooltip';
import { DEFAULT_LINES, DEFAULT_TAG } from './constants';

import './index.scss';

const Ellipsis = ({ text, textClassName = '', maxLines = DEFAULT_LINES }) => {
    const textRef = useRef(null);
    const [hasEllipsis, setHasEllipsis] = useState(false);
    const style = { '--lines': maxLines };

    useEffect(() => {
        if (textRef.current.scrollHeight > textRef.current.clientHeight) {
            setHasEllipsis(true);
        }
    }, []);

    const Wrapper = hasEllipsis ? Tooltip : DEFAULT_TAG;

    return (
        <Wrapper content={text} className="ellipsis-tooltip">
            <p style={style} ref={textRef} className={classNames('ellipsis-text', textClassName)}>
                {text}
            </p>
        </Wrapper>
    );
};

Ellipsis.propTypes = {
    text: string,
    textClassName: string,
    maxLines: number,
};

export default Ellipsis;
