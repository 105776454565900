import React from 'react';
import classNames from 'classnames';
import { useAppContext } from '../../context/listingsContext';
import SelectedFilters from '../selected_filters/SelectedFilters';
import { ActiveFiltersSection } from '../side_filters/ActiveFiltersSection/ActiveFiltersSection';

interface ActiveFiltersProps {
    setIsDrawerOpen?: (value: React.SetStateAction<boolean>) => void;
    selectedScName?: string;
}

// TODO don't move to package - SelectedFilters code used by the mustangs
export const ActiveFilters = ({ setIsDrawerOpen, selectedScName }: ActiveFiltersProps) => {
    const {
        activeFilters,
        inOwlsSideFiltersTest: shouldShowSideFilters,
        appFilters: { selectedFiltersHierarchy, selectedFilters },
    } = useAppContext();

    if (shouldShowSideFilters) {
        const onFilterClick = () => {
            setIsDrawerOpen?.(true);
        };

        return (
            <div className="layout-row">
                <ActiveFiltersSection
                    onFilterClick={onFilterClick}
                    selectedFilters={selectedFiltersHierarchy}
                    activeFilters={activeFilters}
                />
            </div>
        );
    }

    // Mustangs
    return (
        <div className={classNames('layout-row', 'selected-filters-wrapper')}>
            <SelectedFilters
                selectedFilters={selectedFilters}
                activeFilters={activeFilters}
                selectedScName={selectedScName}
            />
        </div>
    );
};
