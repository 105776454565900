import fallback_1 from './assets/fallback_1.webp';
import fallback_2 from './assets/fallback_2.webp';
import fallback_3 from './assets/fallback_3.webp';
import fallback_4 from './assets/fallback_4.webp';
import fallback_5 from './assets/fallback_5.webp';
import fallback_6 from './assets/fallback_6.webp';
import fallback_7 from './assets/fallback_7.webp';
import fallback_8 from './assets/fallback_8.webp';
import fallback_9 from './assets/fallback_9.webp';
import fallback_10 from './assets/fallback_10.webp';

export const SOURCE = 'profession_filters';
export const PROFESSION_FILTER_MAX_ITEMS_TO_SHOW = 10;
export const FALLBACK_IMAGES = [
    fallback_1,
    fallback_2,
    fallback_3,
    fallback_4,
    fallback_5,
    fallback_6,
    fallback_7,
    fallback_8,
    fallback_9,
    fallback_10,
];
