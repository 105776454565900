import { useContext } from 'react';
import { getContext } from '@fiverr-private/fiverr_context';
import { EXPERIMENTS } from '@fiverr-private/listing_experiments';
import { bqEvent } from '../../../../shared/services/BIEvents/utils';
import { BI_EVENT_GROUPS, BI_PAGE_NAMES } from '../../../../shared/services/BIEvents/constants';
import { allocateToClientExperiment } from '../../clientExperiments/clientUtils';
import { POSITION_TO_ALLOCATE_TO_AGENCIES_IN_LISTINGS_GD } from '../../clientExperiments/agenciesInListingsGD/constants';
import useListingsPositionObserver from '../useListingsPositionObserver';
import { AppContext } from '../../components/context/listingsContext';

const useAllocateAgenciesInListingsGDRowObserver = () => {
    const { pageCtxId, abTests } = getContext();
    const { clientExperimentsGroups } = useContext(AppContext);

    return useListingsPositionObserver(
        {
            shouldObserve: true,
            position: POSITION_TO_ALLOCATE_TO_AGENCIES_IN_LISTINGS_GD,
        },
        () => {
            const experimentGroup = Number(clientExperimentsGroups[EXPERIMENTS.AGENCIES_IN_LISTINGS_GD]);
            if (experimentGroup > 0) {
                bqEvent({
                    type: 'listings_client_experiment_active',
                    group: BI_EVENT_GROUPS.USER_ACTIONS,
                    pageCtxId,
                    page: {
                        name: BI_PAGE_NAMES.LISTINGS,
                    },
                    payload: {
                        ab_test: [
                            {
                                experiment: EXPERIMENTS.AGENCIES_IN_LISTINGS_GD,
                                group: experimentGroup,
                            },
                        ],
                    },
                });

                if (!abTests[EXPERIMENTS.AGENCIES_IN_LISTINGS_GD]) {
                    allocateToClientExperiment(EXPERIMENTS.AGENCIES_IN_LISTINGS_GD, clientExperimentsGroups);
                }
            }
        }
    );
};
export default useAllocateAgenciesInListingsGDRowObserver;
