import { useCallback, useContext } from 'react';
import { useMutation } from 'react-query';
import axios from 'axios';
import { ListingAttributes, Conversation, InitialProps, Message, FlowName } from '@fiverr-private/listing_types';
import { OrchestratorMachineDispatchContext, OrchestratorMachineContext } from '../../contexts';
import { useHandleOrchestratorTimeouts } from '../useHandleOrchestratorTimeouts';
import { ListingAttributesEvent } from '../../types/ListingAttributesMessagePayload';
import { onReceiveChatHistory } from './util/onReceiveChatHistory';
import { handleListingAttributesMessage } from './util/handleListingAttributesMessage';

export interface useListingsOrchestratorProps {
    handleNewListingResult: (listings: InitialProps) => void;
    setLoadingState: (isLoading: boolean) => void;
    flowName: FlowName;
}

export const useListingsOrchestrator = ({
    handleNewListingResult,
    setLoadingState,
    flowName,
}: useListingsOrchestratorProps) => {
    const dispatch = useContext(OrchestratorMachineDispatchContext);
    const {
        context: { listingAttributes, isCurrentListingCreatedByAssistant, currentListingIndex },
    } = useContext(OrchestratorMachineContext);

    useHandleOrchestratorTimeouts();

    const { mutateAsync } = useMutation((atrr: ListingAttributes) =>
        axios.post<InitialProps>('/listings/search_result', { listing_attributes: atrr })
    );

    const onMessageSend = useCallback(() => {
        dispatch({ event: 'MESSSAGE_SENT_TO_ASSISTANT' });
    }, [dispatch]);

    const onReceiveMessage = useCallback(
        (message: Message) => {
            if (!message.searchEventId) {
                dispatch({ event: 'PLAIN_TEXT_MESSAGE_RECEIVED' });

                return;
            }

            if (message.searchEventId === listingAttributes?.id) {
                dispatch({ event: 'LISTING_ATTRIBUTES_LINKED_MESSAGE_RECEIVED' });
            }
        },
        [dispatch, listingAttributes]
    );

    const onReceiveListingAttributes = useCallback(
        ({ payload: { listingAttributes: attr } }: ListingAttributesEvent) => {
            void handleListingAttributesMessage(
                dispatch,
                setLoadingState,
                attr,
                flowName,
                mutateAsync,
                handleNewListingResult,
                isCurrentListingCreatedByAssistant,
                currentListingIndex
            );
        },
        [
            dispatch,
            setLoadingState,
            flowName,
            mutateAsync,
            handleNewListingResult,
            isCurrentListingCreatedByAssistant,
            currentListingIndex,
        ]
    );

    const onReceiveChat = useCallback(
        (conversation: Conversation) => {
            onReceiveChatHistory(conversation, dispatch);
        },
        [dispatch]
    );

    const onUserAttemptingRetry = useCallback(() => {
        dispatch({ event: 'RETRY_GETTING_RESPONSE' });
    }, [dispatch]);

    return { onReceiveListingAttributes, onReceiveMessage, onReceiveChat, onMessageSend, onUserAttemptingRetry };
};
