export const SOURCE_PARAMS = [
    'category_tree',
    'pagination',
    'vertical-buckets',
    'vertical-carousel',
    'category_filters',
    'drop_down_filters',
    'hplo_subcat_first_step',
    'visual_filters',
    'toggle_filters',
    'sorting_by',
    'hplo_search_tag',
    'gig_sub_category_link',
    'recommendation_returning_buyers',
    'category_tree_trending',
    'gig_nested_sub_category_link',
    'explicit_language_pair_filter',
    'breadcrumbs',
    'side-menu',
    'gig_cards',
    'gallery-listing',
    '404',
    'sidebar-navigation',
    'lihp_banners',
    'top-bar',
    'filtered_pro_gigs_explore_other',
    'header_nav',
    'top_nav',
    'footer',
    'hplo_made_on_fiverr',
    'hplo_cat_sec',
    'category_vertical',
    'main_banner',
    'checkout',
    'logo_maker_pop',
    'gig_page',
    'side_navigation',
    'gig_category_link',
    'related_search_terms',
    'header_navigation',
    'pro_recommendations_by_sc',
    'business_lp',
    'inbox',
    'tag_filters',
    'ecommerce-management',
    'your_recently_viewed_gigs',
    'pro_landing_page',
    'recommended_in_sc',
    'search',
    'choice_modalities_pricing',
    'high_quality_business_gigs',
    'rest_of_business_gigs',
    'FIB_catalog_page_popular_services_carousel',
    'FIB_catalog_page_projects_carousel',
    'business_logged_out_homepage-categories-component',
    'cold_start_and_rnc',
    'business_lihp_banners',
    'recommendation_ftb_friendly',
    'recommendation_express_delivery',
    'filtered_pro_gigs',
    'search_did_you_mean',
    'guest-homepage',
    'sellers_who_speak_fr',
    'sellers_who_speak_es',
    'sellers_who_speak_de',
    'sellers_who_speak_it',
    'sellers_who_speak_pt',
    'sellers_who_speak_nl',
    'search_redirect_query',
    'opensearch',
    'twitch_overlay',
    'imf_banner_services',
    'similar_gigs',
    'other_gigs_by',
    'recently_and_inspired',
    'gig_edit',
    'business_catalog',
    'seller_page',
    'header_business_navigation',
    'expert_listings_page',
];

// The values should be the same as the values in the url
