import React from 'react';
import classNames from 'classnames';
import { number, shape, string } from 'prop-types';
import { StarSolidIcon } from '@fiverr-private/icons';
import { numberFormat } from '@fiverr-private/localization';
import { grey_1200 } from '@fiverr-private/sass/helpers';
import { useExpertCardContext } from '../../../../contexts';

import './index.scss';

const SellerRating = ({ containerClassName, rating }) => {
    const { rating: ratingFromContext } = useExpertCardContext();
    const localRating = rating ?? ratingFromContext;
    const { averageValuation, totalReviewsCount } = localRating;

    const showRating = !!(averageValuation && totalReviewsCount);

    if (!showRating) {
        return null;
    }

    const formattedReviewsCount = numberFormat(totalReviewsCount, { fallbackValue: totalReviewsCount });
    const formattedScore = numberFormat(averageValuation, {
        fallbackValue: totalReviewsCount,
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
    });

    return (
        <span className={classNames('flex flex-items-center seller-rating', containerClassName)}>
            <StarSolidIcon color={grey_1200} />
            <p className="m-l-4 co-text-darkest text-bold">{formattedScore}</p>
            <p className="m-l-4 co-text-medium">({formattedReviewsCount})</p>
        </span>
    );
};

SellerRating.propTypes = {
    containerClassName: string,
    rating: shape({
        averageValuation: number,
        totalReviewsCount: number,
    }),
};

export default SellerRating;
