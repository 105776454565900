import { FlowName } from '@fiverr-private/listing_types';
import {
    reportBaseBqClickEvent,
    reportBaseBqImpressionEvent,
} from '../../../../../banners/HourlyRateBanners/HourlyRate.events';

export const reportBqClickEvent = ({ flowName }: { flowName: FlowName }) =>
    reportBaseBqClickEvent({
        flowName,
        type: 'clicked_on_hourly_budget_Listings',
        component: {
            type: 'Button',
            name: 'hourly_budget',
            parent: { id: 'budget_filter' },
        },
    });

export const reportBqImpressionEvent = ({ flowName }: { flowName: FlowName }) =>
    reportBaseBqImpressionEvent({
        flowName,
        type: 'viewed_hourly_budget_Listings',
        component: {
            type: 'Button',
            name: 'hourly_budget',
            parent: { id: 'budget_filter' },
        },
    });
