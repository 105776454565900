export const EVENT_TYPES = {
    CLICKED_ON_EXPERT_CARD: 'clicked_on_expert_card',
    CLICKED_ON_SELLER_GALLERY: 'clicked_on_seller_gallery',
    CLICKED_ON_SELLER_SKILLS: 'clicked_on_seller_skills',
    CLICKED_ON_SELLER_MORE_SKILLS: 'clicked_on_seller_more_skills',
    CLICKED_ON_SCROLL_SELLER_GALLERY: 'clicked_on_scroll_seller_gallery',
    CLICKED_ON_EXPERT_GIG_CARD: 'clicked_on_expert_gig_card',
    CLICK_ON_SEND_MESSAGE_BUTTON: 'click_on_send_message_button',
    CLICK_SHOW_MORE: 'click_show_more',
    BRIEF_AND_MATCH_MODAL_SHOW: 'brief_and_match_banner_is_shown',
    BRIEF_AND_MATCH_MODAL_CLOSE: 'clicked_on_dismiss_brief_and_match_banner',
    CLICK_CREATE_BRIEF_BUTTON: 'clicked_on_create_a_brief',
    PRO_BANNER_SHOWN: 'pro_vetted_banner_is_shown',
    PRO_BANNER_CLICKED: 'pro_vetted_banner_clicked',
    HOVER_ON_CUSTOMER_PROTECTION: 'hover_on_customer_protection',
};

export const COMPONENT_NAMES = {
    EXPERT_CARD: 'expert_card',
    EXPERT_GIG_CARD: 'expert_gig_card',
    CONTACT_SELLER_MODAL: 'contact_seller_modal',
    PRO_BANNER: 'pro_vetted_banner',
    RECENTLY_VIEWED_FREELANCERS: 'recently_viewed',
};

export const ELEMENT_NAMES = {
    SELLER_AVATAR: 'seller_avatar',
    SELLER_NAME: 'seller_name',
    VIEW_EXPERT: 'view_expert',
    EXPERT_CARD: 'expert_card',
    VIEW_ALL_SERVICES: 'view_all_services',
    GIG_CAROUSEL_SCROLL: 'gig_carousel_scroll',
    ADD_TO_LIST: 'add_to_list',
    REMOVE_FROM_LIST: 'remove_from_list',
    CONTACT_SELLER: 'contact_seller',
    GIG_CARD: 'gig_card',
    VIDEO_ICON: 'video_icon',
    SELLER_SKILLS: 'seller_skills',
    SELLER_MORE_SKILLS: 'seller_more_skills',
    SELLER_GALLERY: 'seller_gallery',
    SCROLL_SELLER_GALLERY: 'scroll_seller_gallery',
    PRO_BANNER: 'pro_vetted_banner',
    PRO_BANNER_BUTTON: 'pro_vetted_banner_button',
    SERVICE_CARD: 'service_card',
    CUSTOM_BUTTON_DEFAULT: 'custom_button_default',
};

export const ELEMENT_TYPES = {
    IMAGE: 'image',
    BUTTON: 'button',
    BANNER: 'banner',
};

export const ELEMENT_META_DATA_NAMES = {
    BUTTON: 'button',
    MENU: 'menu',
};

export const MIXPANEL_EVENT_NAMES = {
    HOVERED_ON_SERVICE_CARD_IMAGE: 'hover on service card image',
    CLICKED_ON_SERVICE_CARD_IMAGE: 'clicked on service card image',
    SCROLLED_SERVICE_CARD_IMAGES: 'scroll service card images',
    CLICKED_ON_EXPERT_CARD: 'Clicked on Expert Card',
    SCROLL_EXPERT_CARD_IMAGES: 'Scroll Expert Card images',
    CLICKED_ON_EXPERT_CARD_GIG: 'Clicked on Expert Card Gig',
    SENT_EXPERT_MESSAGE: 'Sent Expert Message',
    CLICKED_ON_SHOW_MORE_EXPERTS: 'Clicked Show More Experts',
    CLICKED_ON_GIG_SERVICE_CARD: 'Click on service card',
    CLICKED_ON_GIG_REQUEST_QUOTE: 'Click request a quote',
    BRIEF_AND_MATCH_MODAL_SHOW: 'B&M floating modal show',
    BRIEF_AND_MATCH_MODAL_CLICKED: 'B&M floating modal clicked',
    BRIEF_AND_MATCH_MODAL_CLOSED: 'B&M floating modal close',
    PRO_BANNER_SHOWN: 'pro_vetted_banner_is_shown',
    PRO_BANNER_CLICKED: 'pro_vetted_banner_clicked',
    CLICKED_ON_BRICK_ITEM: 'Click on brick item',
    HOVERED_ON_BRICK_ITEM: 'Hover on brick item',
};

export const GROUPS = {
    ACTIONS: 'user_actions',
    IMPRESSIONS: 'user_impressions',
};

export const EXPERT_LISTINGS_SOURCE = 'expert_listings_page';
export const LISTINGS_SOURCE = 'listings_page';
export const MARKETPLACE_LISTINGS_SOURCE = 'mp_listing_page';

export const MIXPANEL_PAGE_NAMES = {
    [EXPERT_LISTINGS_SOURCE]: 'Expert Listings',
};
