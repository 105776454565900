import React from 'react';
import { ComboBox } from '@fiverr-private/orca';
import Types from '../../types';
import { useBusinessListingsContext } from '../../../Context';
import { invokeApplyEvent, useSidebarFiltersContext, Triggers } from '../../Context';
import { ComboBoxOptionWithCount } from '../FilterWithCount/ComboBoxOptionWithCount';
import { comboBoxStyle } from './styles.ve.css';

const SearchFilter = ({ filter }) => {
    const { activeFilters } = useBusinessListingsContext();
    const { applyEvent, newActiveFilters, activeTrigger } = useSidebarFiltersContext();
    const { options } = filter;

    const activeOptionsIds = newActiveFilters[filter.id] ?? [];
    const nonSelectedOptions = options.filter(({ id: optionId }) => !activeOptionsIds.includes(optionId));

    const displayOptions = nonSelectedOptions.map(({ id, alias, count }) => ({ value: id, count, label: alias }));

    const shouldAllowMultiselect = activeTrigger !== Triggers.FILTER_COMPONENT_CLICK;

    const selectedOptions = activeOptionsIds.map((optionId) => {
        const { alias, count } = options.find(({ id }) => id === optionId);

        return { value: optionId, count, label: alias };
    });

    const comboBoxChanged = (currentData) => {
        const filtersAfterChange = { ...(newActiveFilters || activeFilters || {}) };

        let currentValues;
        if (currentData.length > 0) {
            currentValues = currentData.map(({ value }) => value);
        } else {
            currentValues = currentData.value ? [currentData.value] : [];
        }

        filtersAfterChange[filter.id] = shouldAllowMultiselect
            ? [...currentValues]
            : [...activeOptionsIds, ...currentValues];

        invokeApplyEvent(applyEvent, filtersAfterChange, Triggers.FILTER_COMPONENT_CLICK);
    };

    return (
        <div className="m-b-32">
            <ComboBox
                options={displayOptions}
                isMulti={shouldAllowMultiselect}
                onChange={comboBoxChanged}
                OptionComponent={ComboBoxOptionWithCount}
                className={comboBoxStyle}
                value={shouldAllowMultiselect ? selectedOptions : []}
            />
        </div>
    );
};

SearchFilter.propTypes = {
    filter: Types.filter.isRequired,
};

export default SearchFilter;
