import { useEffect } from 'react';
import { metricsService } from '@fiverr-private/listing_lib';

const useCountFlowAndListingTypeView = (flow: string, listingType: string) => {
    useEffect(() => {
        metricsService.countFlowView(`${flow}.${listingType}`);
    }, [flow, listingType]);
};

export default useCountFlowAndListingTypeView;
