import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from '@fiverr-private/fiverr_context';
import { FILTER } from '@fiverr-private/listing_lib';
import SelectContainer from '../SelectContainer';
import { pairSelectTypes } from '../../../../../types';
import { sortByCountDescending } from '../../../../../utils/filter_builder/_utils';
import './index.scss';

const DEFAULT_VALUES = {
    fromValue: FILTER.LANGUAGES_PAIR.DEFAULT,
    toValue: FILTER.LANGUAGES_PAIR.DEFAULT,
};

const valuesLinkedList = {
    fromValue: 0,
    toValue: 1,
};

const PairSelect = ({ id, value, options, onClear, onChange }) => {
    const { fromOptions, toOptions } = options;
    const { fromValue, toValue } = valuesLinkedList;
    const currentFromValue = value[fromValue] === DEFAULT_VALUES.fromValue ? undefined : value[fromValue];
    const currentToValue = value[toValue] === DEFAULT_VALUES.toValue ? undefined : value[toValue];

    const clear = (elementId) => {
        const currentValue = updateValue(elementId, DEFAULT_VALUES[elementId]);
        onClear(id, currentValue);
    };

    const onChangeHandler = (selectId, selectedValue) => {
        const currentValue = updateValue(selectId, selectedValue);
        onChange(id, currentValue);
    };

    const updateValue = (id, selectedValue) => {
        const currentValue = fillDefaultValues(value);
        currentValue[valuesLinkedList[id]] = selectedValue;
        return currentValue;
    };

    // Ensure all the values either have a value or set to default.
    const fillDefaultValues = (currentValue) => {
        const updatedValue = [...currentValue];
        const { fromValue, toValue } = valuesLinkedList;
        updatedValue[fromValue] = updatedValue[fromValue] || DEFAULT_VALUES.fromValue;
        updatedValue[toValue] = updatedValue[toValue] || DEFAULT_VALUES.toValue;
        return updatedValue;
    };

    return (
        <div className="pair-select">
            <label>
                <I18n k={`search_perseus.filter.${id}.from`} />
                <span onClick={() => clear('fromValue')}>
                    <I18n k={'search_perseus.general.clear'} />
                </span>
            </label>
            <SelectContainer
                id="fromValue"
                value={currentFromValue}
                onChange={onChangeHandler}
                placeholder={`search_perseus.filter.${id}.any`}
                optionSort={sortByCountDescending}
                options={fromOptions}
            />
            <label>
                <I18n k={`search_perseus.filter.${id}.to`} />
                <span onClick={() => clear('toValue')}>
                    <I18n k={'search_perseus.general.clear'} />
                </span>
            </label>
            <SelectContainer
                id="toValue"
                value={currentToValue}
                onChange={onChangeHandler}
                placeholder={`search_perseus.filter.${id}.any`}
                options={toOptions}
                optionSort={sortByCountDescending}
            />
        </div>
    );
};

PairSelect.propTypes = {
    id: PropTypes.string,
    value: PropTypes.array,
    onChange: PropTypes.func,
    onClear: PropTypes.func,
    ...pairSelectTypes,
};

PairSelect.defaultProps = {
    value: [],
    options: {},
    onChange: () => true,
    onClear: () => true,
};

export default PairSelect;
