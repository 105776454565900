import { useContext, useState } from 'react';
import { get } from 'lodash';
import { getContext } from '@fiverr-private/fiverr_context';
import { FILTER } from '@fiverr-private/listing_lib';
import { LanguagesPairVisualFilterOption } from '@fiverr-private/listing_types';
import { getInitialLanguage, parseLanguageList } from '../../components/common/ExplicitLanguagePair/utils';
import { AppContext } from '../../components/context/listingsContext';

export const useLanguagePairsOptions = (languagesListOptions: LanguagesPairVisualFilterOption) => {
    const { activeFilters = {} } = useContext(AppContext);
    const { locale } = getContext();

    const { fromOptions = [], toOptions = [] } = languagesListOptions ?? {};

    const activeFromLanguageValue = get(activeFilters, `${FILTER.LANGUAGES_PAIR.ID}[0]`);
    const activeToLanguageValue = get(activeFilters, `${FILTER.LANGUAGES_PAIR.ID}[1]`);

    const [fromLanguage, setFromLanguage] = useState(getInitialLanguage(activeFromLanguageValue, fromOptions));
    const [toLanguage, setToLanguage] = useState(getInitialLanguage(activeToLanguageValue, toOptions));

    const fromLanguageOptions = parseLanguageList(locale, fromOptions);
    const toLanguageOptions = parseLanguageList(locale, toOptions);

    return {
        activeFromLanguageValue,
        activeToLanguageValue,
        fromLanguage,
        setFromLanguage,
        toLanguage,
        setToLanguage,
        fromLanguageOptions,
        toLanguageOptions,
    };
};
