import { merge } from 'lodash';
import { bigQuery } from '@fiverr-private/obs';
import { getContext } from '@fiverr-private/fiverr_context';
import { BI_CONSTS } from '@fiverr-private/listing_lib';
import { getBqEventSource, getEventPayload } from '../../../biUtils';
import {
    BI_ACTION_TYPES,
    BI_EVENT_GROUPS,
    BI_EVENTS_SUB_GROUPS,
} from '../../../../../../../shared/services/BIEvents/constants';
import { MAX_ITEMS_TO_SHOW_NO_RESULTS } from '../../../../../constants';
import { BI_EVENT_TYPES } from './constants';
import { hasBsm } from './utils';

const FEATURE_NAME = 'fiverr_pro';

const getBsmState = (isExpertListings, managedAccount) => {
    const WITH_BSM = 'with_bsm';
    const WITHOUT_BSM = 'no_bsm';

    if (!isExpertListings) {
        return null;
    }
    if (hasBsm(managedAccount)) {
        return WITH_BSM;
    }
    return WITHOUT_BSM;
};

export const getImpressionEventPayload = (isExpertListings, managedAccount, resultsCount, hasActivityFilters) => {
    const { pageCtxId, userId, userGuid } = getContext();

    const basePayLoad = getEventPayload({
        type: BI_EVENT_TYPES.NO_RESULTS_IMPRESSION,
        group: BI_EVENT_GROUPS.USER_IMPRESSION,
        actionType: BI_ACTION_TYPES.IMPRESSION,
        isExpertListings,
    });
    const impressionEventPayload = {
        sub_group: BI_EVENTS_SUB_GROUPS.FIVERR_PRO,
        feature: {
            name: FEATURE_NAME,
        },
        user: {
            id: userId,
        },
        uid: userGuid,
        page: {
            ctx_id: pageCtxId,
            component: {
                displayed_state: getBsmState(isExpertListings, managedAccount),
                num_of_elements: resultsCount,
                type: getPageComponentType(resultsCount),
            },
        },
        gig_impression: {
            listings: {
                filters: hasActivityFilters,
            },
        },
        out_of_session: false,
    };

    return merge(basePayLoad, impressionEventPayload);
};

const getPageComponentType = (resultsCount) => {
    const withoutResult = 'no_results';
    const fewResult = 'low_supply';
    const regular = 'regular';
    if (resultsCount === 0) {
        return withoutResult;
    } else if (resultsCount <= MAX_ITEMS_TO_SHOW_NO_RESULTS) {
        return fewResult;
    }
    return regular;
};

export const getBigQueryButtonClickEventPayload = (
    type,
    isExpertListings,
    buttonText,
    resultsCount,
    hasActivityFilters,
    managedAccount
) => {
    const { pageCtxId, userId, userGuid } = getContext();

    const basePayLoad = getEventPayload({
        type,
        group: BI_EVENT_GROUPS.USER_ACTION,
        actionType: BI_ACTION_TYPES.CLICK,
        isExpertListings,
    });
    const buttonClickEventPayload = {
        sub_group: BI_EVENTS_SUB_GROUPS.FIVERR_PRO,
        feature: {
            name: FEATURE_NAME,
        },
        user: {
            id: userId,
        },
        uid: userGuid,
        page: {
            ctx_id: pageCtxId,
            element: {
                type: BI_CONSTS.BUTTON,
                text: buttonText,
                state: getBsmState(isExpertListings, managedAccount),
            },
            component: {
                num_of_elements: resultsCount,
                type: getPageComponentType(resultsCount),
            },
        },
        listings: {
            filters: hasActivityFilters,
        },
        out_of_session: false,
    };

    return merge(basePayLoad, buttonClickEventPayload);
};

export const reportChatClickedToBigQuery = (
    isExpertListings,
    buttonText,
    resultsCount,
    hasActivityFilters,
    managedAccount
) => {
    const payload = getBigQueryButtonClickEventPayload(
        BI_EVENT_TYPES.LET_CHAT_CLICK,
        isExpertListings,
        buttonText,
        resultsCount,
        hasActivityFilters,
        managedAccount
    );
    bigQuery.send(payload);
};

export const reportBriefClickedToBigQuery = (
    isExpertListings,
    buttonText,
    resultsCount,
    hasActivityFilters,
    managedAccount
) => {
    const basePayLoad = getBigQueryButtonClickEventPayload(
        BI_EVENT_TYPES.NO_RESULTS_BRIEF_CLICK,
        isExpertListings,
        buttonText,
        resultsCount,
        hasActivityFilters,
        managedAccount
    );
    const briefClickEventPayload = {
        event_source: getBqEventSource(isExpertListings),
    };
    const payload = merge(basePayLoad, briefClickEventPayload);
    bigQuery.send(payload);
};

export const reportClearFilterClickedToBigQuery = (isExpertListings, buttonText, resultsCount, managedAccount) => {
    const basePayLoad = getBigQueryButtonClickEventPayload(
        BI_EVENT_TYPES.PRO_CLEAR_FILTERS_CLICK,
        isExpertListings,
        buttonText,
        resultsCount,
        true,
        managedAccount
    );
    const clearFilterEventPayload = {
        event_source: getBqEventSource(isExpertListings),
    };
    const payload = merge(basePayLoad, clearFilterEventPayload);

    bigQuery.send(payload);
};
