import React, { createContext, Dispatch, useReducer, useRef } from 'react';
import { noop } from 'lodash';
import { QueryClient, QueryClientProvider } from 'react-query';
import { orchestratorMachineReducer } from '../reducers/OrchestratorMachine/orchestratorMachineReducer';
import { OrchestratorMachineDispatchedAction, OrchestratorMachineState } from '../types';

const queryClient = new QueryClient();

const INITIAL_STATE: OrchestratorMachineState = {
    activeState: 'initialLoad',
    context: {},
    onEnterStateMap: {},
    onLeaveStateMap: {},
};

export const OrchestratorMachineContext = createContext<OrchestratorMachineState>(INITIAL_STATE);

export const OrchestratorMachineDispatchContext = createContext<Dispatch<OrchestratorMachineDispatchedAction>>(noop);

export const ListingsOrchestratorProvider: React.FC = ({ children }) => {
    const [orchestratorState, dispatch] = useReducer(orchestratorMachineReducer, INITIAL_STATE);
    const isCurrentListingCreatedByAssistant = useRef(false);
    const currentListingIndex = useRef(1);

    const machineContextValue = {
        ...orchestratorState,
        context: {
            ...orchestratorState.context,
            isCurrentListingCreatedByAssistant,
            currentListingIndex,
        },
    };

    return (
        <OrchestratorMachineContext.Provider value={machineContextValue}>
            <QueryClientProvider client={queryClient}>
                <OrchestratorMachineDispatchContext.Provider value={dispatch}>
                    {children}
                </OrchestratorMachineDispatchContext.Provider>
            </QueryClientProvider>
        </OrchestratorMachineContext.Provider>
    );
};
