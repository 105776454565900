import React from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import styles from './index.module.scss';

export const Divider = () => (
    <div className={styles.divider}>
        <p>
            <I18n k={'search_perseus.business_listings.no_results.or'} />
        </p>
    </div>
);
