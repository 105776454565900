import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { SaveToListButton, SaveToListPopover } from '@fiverr-private/collect_actions';
import { withQueryClient } from '@fiverr-private/query_client_hoc';
import { useExpertCardContext } from '../../../../contexts';

const SaveExpertButtonView = ({ source, size = 'md' }) => {
    const { seller, saveToListProps } = useExpertCardContext();

    return (
        <SaveToListPopover
            bi={{ source }}
            resourceType="SELLER"
            resourceId={seller.id}
            position="bottom-end"
            fullWidth
            renderTrigger={({ inCollections, isLoading }) => (
                <SaveToListButton size={size} checked={!isEmpty(inCollections)} isLoading={isLoading} />
            )}
            {...(saveToListProps || {})}
        />
    );
};

SaveExpertButtonView.propTypes = {
    source: PropTypes.string,
    size: PropTypes.string,
};

export const SaveExpertButton = withQueryClient(SaveExpertButtonView);

SaveExpertButton.displayName = 'SaveExpertButton';
