import { useRef } from 'react';
import { noop } from 'lodash';

const useListingsPositionObserver = ({ shouldObserve = true, position = 1 } = {}, callback = noop) => {
    const isIntersected = useRef(false);

    if (!shouldObserve) {
        return noop;
    }

    return (impressionData = {}) => {
        if (isIntersected.current) {
            return;
        }

        if (impressionData.position_in_parent === position) {
            isIntersected.current = true;
            callback();
        }
    };
};

export default useListingsPositionObserver;
