import React, { useState, useEffect, forwardRef } from 'react';
import classNames from 'classnames';
import { object, func, string, array, oneOfType } from 'prop-types';
import { ComboBox } from '@fiverr-private/orca';
import { Input, NoOptionsMessage } from './components';
import { EDITABLE_COMBO_BOX_CLASSNAME_PREFIX, EDITABLE_COMBO_BOX_MENU } from './constants';

const ComboBoxMenu = ComboBox.components.Menu;

import './index.scss';

/**
 *
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<{readonly onChange?: *, readonly options?: *, readonly className?: *, readonly placeholder?: *, readonly value?: *}> & React.RefAttributes<unknown>>}
 */
// eslint-disable-next-line react/display-name
const EditableComboBox = forwardRef(({ value, onChange, className, options, placeholder }, selectRef) => {
    const labelValue = value ? value.label : '';
    const [inputValue, setInputValue] = useState(labelValue);

    useEffect(() => {
        setInputValue(labelValue);
    }, [labelValue]);

    const onInputChange = (inputValue, { action }) => {
        if (action === 'input-blur') {
            setInputValue(value ? value.label : '');
        } else if (action === 'input-change') {
            setInputValue(inputValue);

            if (!inputValue) {
                onChange(null);
            }
        }
    };

    const Menu = (props) => (
        <ComboBoxMenu className={classNames(EDITABLE_COMBO_BOX_MENU, { 'text-bold': !!inputValue })} {...props} />
    );

    return (
        <ComboBox
            ref={selectRef}
            value={value}
            hideSelectedOptions={false}
            isMulti={false}
            isInPortal={false}
            className={classNames(`${EDITABLE_COMBO_BOX_CLASSNAME_PREFIX}-container`, className)}
            classNamePrefix={EDITABLE_COMBO_BOX_CLASSNAME_PREFIX}
            inputValue={inputValue}
            onInputChange={onInputChange}
            onChange={onChange}
            NoOptionsMessage={NoOptionsMessage}
            options={options}
            placeholder={placeholder}
            InputComponent={Input}
            MenuComponent={Menu}
        />
    );
});

EditableComboBox.propTypes = {
    value: oneOfType([object, string]),
    options: array,
    className: string,
    placeholder: string,
    onChange: func,
};

export default EditableComboBox;
