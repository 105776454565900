import React from 'react';
import { string } from 'prop-types';
import FAQFormatter from './utils/FAQFormatter';
import breadcrumbFormatter from './utils/breadcrumbFormatter';

const SeoMarkupScript = ({ scriptContent }) => (
    <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: scriptContent }} />
);

SeoMarkupScript.propTypes = {
    scriptContent: string,
};

export default SeoMarkupScript;

export { FAQFormatter, breadcrumbFormatter };
