import PropTypes, { string, number, bool, shape, arrayOf, oneOfType, oneOf } from 'prop-types';
import { SUPPORTED_SELLER_LANGUAGES } from '@fiverr-private/localization';
import {
    ASSET_APPROVED_STATUS_BY_TYPE,
    ASSET_TYPES,
    CARD_VARIANT,
    DELIVERY_ATTACHMENT_TYPES,
    LANGUAGE_LEVEL,
} from '../constants';

export const currencyType = shape({
    name: string,
    rate: number,
    template: string,
    forceRound: bool,
    forceRoundFromAmount: number,
    symbol: string,
});

/**
 * Badges of the seller: Pro / Choice
 */
export const badgesType = shape({
    pro: bool,
    choice: bool,
});

export const languageLevelType = oneOf([
    LANGUAGE_LEVEL.UNSPECIFIED.ID,
    LANGUAGE_LEVEL.BASIC.ID,
    LANGUAGE_LEVEL.CONVERSATIONAL.ID,
    LANGUAGE_LEVEL.FLUENT.ID,
    LANGUAGE_LEVEL.NATIVE_OR_BILINGUAL.ID,
]);

export const languageType = shape({
    code: oneOf(SUPPORTED_SELLER_LANGUAGES),
    level: languageLevelType,
});

export const cardVariantType = oneOf(Object.values(CARD_VARIANT));

export const SellerLevel = {
    BadActor: 'BAD_ACTOR',
    LevelOne: 'LEVEL_ONE',
    LevelTrs: 'LEVEL_TRS',
    LevelTwo: 'LEVEL_TWO',
    LowQuality: 'LOW_QUALITY',
    NewSeller: 'NEW_SELLER',
    NoLevel: 'NO_LEVEL',
};

export const sellerLevelType = oneOf(Object.values(SellerLevel));

export const sellerType = shape({
    /**
     * The seller's id
     */
    id: number,
    /**
     * The display name of the seller
     */
    displayName: string,
    /**
     * The username of the seller
     */
    username: string,
    /**
     * The url to seller's avatar
     */
    avatarUrl: string,
    /**
     * The country code of the seller
     */
    countryCode: string,
    /**
     * The job title of the seller
     */
    jobTitle: string,
    /**
     * Badges of the seller: Pro / Choice
     */
    badges: badgesType,
    /**
     * whether a FIB user is saved
     */
    savedBy: shape({
        id: number,
        fullName: string,
    }),
    /**
     * whether a FIB user is allowed to save / remove expert
     */
    allowedActions: shape({
        addToTeam: bool,
        removeFromTeam: bool,
    }),
    /**
     * Total number of seller's gig
     */
    totalGigsCount: number,
    /**
     * Spoken languages and proficiency
     *
     * TODO: Should be renamed to "languages" and replace the current languages array
     */
    proficientLanguages: arrayOf(languageType),
    /**
     * Whether the seller is a pro seller
     */
    isPro: bool,
    sellerLevel: sellerLevelType,
});

export const attachmentType = shape({
    /**
     * Attachment id
     */
    id: string,
    /**
     * Audio / video source
     */
    streamUrl: string,
    /**
     * Video thumbnail image
     */
    imageUrl: string,
});

export const mediaType = oneOf([ASSET_TYPES.IMAGE, ASSET_TYPES.VIDEO, ASSET_TYPES.AUDIO, ASSET_TYPES.DELIVERY]);

export const assetType = shape({
    /**
     * Asset url
     */
    cloudImgMainGig: string,
    /**
     * The type of the asset image / video
     */
    type: mediaType,
    /**
     * The status describes approved state for delivery video=0 / audio=1
     */
    status: oneOf([ASSET_APPROVED_STATUS_BY_TYPE.VIDEO, ASSET_APPROVED_STATUS_BY_TYPE.AUDIO]),
    /**
     * Asset id
     */
    id: oneOfType([string, number]),
    /**
     * Attachment id related to the asset
     */
    attachmentId: string,
    /**
     * If the asset is delivery done on fiverr
     */
    isDelivery: PropTypes.bool,
});

export const filteredDeliveryAttachmentType = shape({
    id: string,
    streamUrl: string,
    imageUrl: string,
    isVisible: bool,
    type: oneOf(Object.values(DELIVERY_ATTACHMENT_TYPES)),
});
