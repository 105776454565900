import { HIGHLIGHTS_ID_LIST } from '../../shared/constants';
import WorkedWithYourTeam from './highlightTypes/workedWithYourTeam';
import WorkedWithClientsInIndustry from './highlightTypes/workedWithClientsInIndustry';
import BuyersKeepComingBack from './highlightTypes/buyersKeepComingBack';
import HighlyResponsive from './highlightTypes/highlyResponsive';
import SavedByTeamMember from './highlightTypes/savedByTeamMember';
import SellerSpeaks from './highlightTypes/sellerSpeaks';
import Inactive from './highlightTypes/inactive';
import OnVacation from './highlightTypes/onVacation';

export const HIGHLIGHTS_COMPONENT_MAPPER = {
    [HIGHLIGHTS_ID_LIST.WORKED_WITH_YOUR_TEAM]: WorkedWithYourTeam,
    [HIGHLIGHTS_ID_LIST.WORKED_WITH_CLIENTS_IN_INDUSTRY]: WorkedWithClientsInIndustry,
    [HIGHLIGHTS_ID_LIST.BUYER_KEEP_COMING_BACK]: BuyersKeepComingBack,
    [HIGHLIGHTS_ID_LIST.HIGHLY_RESPONSIVE]: HighlyResponsive,
    [HIGHLIGHTS_ID_LIST.SELLER_SPEAKS]: SellerSpeaks,
    [HIGHLIGHTS_ID_LIST.SAVED_BY_TEAM_MEMBER]: SavedByTeamMember,
    [HIGHLIGHTS_ID_LIST.INACTIVE]: Inactive,
    [HIGHLIGHTS_ID_LIST.ON_VACATION]: OnVacation,
};

export const HIGHLIGHTS_COMPONENT_CLASSNAME_MAP = {
    [HIGHLIGHTS_ID_LIST.INACTIVE]: 'inactive',
    [HIGHLIGHTS_ID_LIST.ON_VACATION]: 'on-vacation',
};
