import { useState } from 'react';
import { getContext } from '@fiverr-private/fiverr_context';
import { useAppContext } from '../../../../context/listingsContext';
import { reportBqUpgradeClickEvent } from '../HourlyRate.events';
import { openHourlyMigrationModal, redirectGuestToProCatalog } from '../HourlyRate.utils';

export const useUpgradeClick = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { flowName } = useAppContext();
    const { userId } = getContext();

    const onUpgradeClick = (e: React.SyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (!e.currentTarget.contains(e.target as HTMLElement)) {
            return;
        }

        const isLoggedIn = !!userId;
        reportBqUpgradeClickEvent({ flowName });

        if (isLoggedIn) {
            // Open seamless upgrade modal, with an hourly copy
            openHourlyMigrationModal();
        } else {
            // Open Pro catalog, filtered hourly.
            redirectGuestToProCatalog();
            setIsLoading(true);
        }
    };

    return {
        onUpgradeClick,
        isLoading,
    };
};
