const { LOCALES } = require('./locales');

const DEFAULT_LOCALE = LOCALES.EN;

const READINESS_MANAGER_REGISTERED_ACTIONS = {
    GLOBALS_INIT: 'search_perseus_globals_init',
};

module.exports = {
    DEFAULT_LOCALE,
    READINESS_MANAGER_REGISTERED_ACTIONS,
};
