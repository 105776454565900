import React from 'react';
import classnames from 'classnames';
import { Radio } from '@fiverr-private/fit';
import { formatPrice } from './PriceBuckets.utils';

import './PriceBuckets.styles.scss';

export interface PriceBucket {
    id: string;
    min: number;
    max: number;
}

interface PriceBucketProps {
    budgetValue: string | { min: number; max: number };
    priceBucketOptions: PriceBucket[];
    onChange: () => void;
    currency: { code: string };
}

export const PriceBuckets: React.FC<PriceBucketProps> = ({
    budgetValue,
    priceBucketOptions = [],
    onChange,
    currency,
}) => (
    <div className="price-buckets-wrapper radio-list flex flex-col">
        {priceBucketOptions.map((priceBucket, index) => (
            <div key={index} className="radio-item-wrapper">
                <Radio
                    value={priceBucket.id}
                    name={priceBucket.id}
                    checked={budgetValue === priceBucket.id}
                    className={classnames('radio-item', { selected: budgetValue === priceBucket.id })}
                    onChange={onChange}
                >
                    <div className="inner-radio">{formatPrice(priceBucket, index, currency)}</div>
                </Radio>
            </div>
        ))}
    </div>
);
