import { parseListingResult } from './listingsContext';
import ACTION_TYPES from './listingsContextReducerActionTypes';

export const reducer = (state, action) => {
    switch (action.type) {
        case ACTION_TYPES.TOGGLE_LOADING:
            return {
                ...state,
                loading: action.loading,
            };
        case ACTION_TYPES.TRANSLATE_UGC:
            return {
                ...state,
                ...action.payload,
            };
        case ACTION_TYPES.DISPLAY_EXPRESS_RECOMMENDATION:
            return {
                ...state,
                shouldShowExpressDelivery: action.payload,
            };
        case ACTION_TYPES.SET_RECOMMENDATIONS_LISTINGS:
            return {
                ...state,
                listings: action.payload,
                isLoading: false,
            };
        case ACTION_TYPES.SET_LISTINGS:
            const parsedResult = parseListingResult({
                ...state,
                ...action.payload,
            });

            return parsedResult;
        case ACTION_TYPES.SET_IS_LOADING_LISTINGS:
            return {
                ...state,
                isLoadingListings: action.payload,
            };
        default:
            return state;
    }
};
