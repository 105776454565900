import React from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import { Stack, Divider } from '@fiverr-private/layout_components';
import { Text } from '@fiverr-private/typography';

export const OrDivider = () => (
    <Stack width="100%" maxWidth="685px" alignItems="center" gap="4">
        <Divider />
        <Text color="bodySecondary" size="b_xs">
            <I18n k="listing_results.empty_results.empty_search_results.or" />
        </Text>
        <Divider />
    </Stack>
);
