import React, { useState, useCallback } from 'react';
import classNames from 'classnames';
import { bool } from 'prop-types';
import { URI } from '@fiverr-private/futile';
import { I18n } from '@fiverr-private/i18n-react';
import { sendClickEventToMixPanel } from '@fiverr-private/listings/src/utils/biEvents/Mixpanel';
import { CheckStarSolidIcon } from '@fiverr-private/visuals';
import { bigQuery } from '@fiverr-private/obs';
import { EXPERT_LISTINGS_URL_PARAM } from '../../generic_listings/constants';
import { MIXPANEL_EVENT_TYPES } from '../../generic_listings/ExpertListingsContainer/biEvents/constants';
import { getBiPageName } from '../common/biEventsHelpers';
import { getToggleItemClickEventPayload } from './events';

import './index.scss';

const Toggle = ({ clickProCatalogItem, clickFullCatalogItem, isExpertModeOn }) => (
    <div className="flex toggle-container">
        <div
            onClick={clickProCatalogItem}
            className={classNames('item flex flex-items-center', {
                active: isExpertModeOn,
            })}
        >
            <div className="check-icon-wrap">
                <CheckStarSolidIcon />
            </div>
            <span className="text">
                <I18n k="search_perseus.business_listings.catalog_toggle_items.experts" />
            </span>
        </div>
        <div
            onClick={clickFullCatalogItem}
            className={classNames('item flex flex-items-center', {
                active: !isExpertModeOn,
            })}
        >
            <span className="text">
                <I18n k="search_perseus.business_listings.catalog_toggle_items.services" />
            </span>
        </div>
        <div
            className={classNames('animation-bar', {
                active: isExpertModeOn,
            })}
        ></div>
    </div>
);

/**
 * Renders a toggle between expert and regular listings.
 */
const ListingsToggle = ({ isExpertListings = true }) => {
    const [isExpertModeOn, setIsExpertModeOn] = useState(isExpertListings);

    const biPageName = getBiPageName(isExpertListings);

    const clickFullCatalogItem = () => {
        if (!isExpertModeOn) {
            return;
        }

        setIsExpertModeOn(false);
        handleRedirect();
    };

    const clickProCatalogItem = () => {
        if (isExpertModeOn) {
            return;
        }

        setIsExpertModeOn(true);
        handleRedirect();
    };

    const handleRedirect = useCallback(() => {
        const event = getToggleItemClickEventPayload(isExpertListings);
        bigQuery.send(event);

        sendClickEventToMixPanel({
            eventName: MIXPANEL_EVENT_TYPES.CLICKED_LISTING_TOGGLE,
            'Page Name': biPageName,
        });

        const uri = new URI(window.location.href);
        uri.addParams({ [EXPERT_LISTINGS_URL_PARAM]: (!isExpertModeOn).toString() });
        window.location.href = uri.href;
    }, [biPageName, isExpertListings, isExpertModeOn]);

    return (
        <div className="toggle-container-wrap">
            <Toggle
                clickProCatalogItem={clickProCatalogItem}
                clickFullCatalogItem={clickFullCatalogItem}
                isExpertModeOn={isExpertModeOn}
            />
        </div>
    );
};

ListingsToggle.propTypes = {
    isExpertListings: bool,
};

export default ListingsToggle;
