export const GIG_CARDS_LAZY_OFFSET = 8,
    GIG_CARDS_MOBILE_LAZY_OFFSET = 4,
    LIST_VIEW_CARDS_LAZY_OFFSET = 8,
    LISTINGS_DESKTOP_SIZE = 48;

export const LOGO_MAKER_BANNER_ROW = {
    DESKTOP: 5,
    TABLET: 4,
    MOBILE: 5,
};

export const SOURCE_COMPONENT = {
    FILTERED_PRO_GIGS: 'filtered_pro_gigs',
    FILTERED_PRO_GIGS_EXPLORE_OTHER: 'filtered_pro_gigs_explore_other',
};

export const WEBSITE_MATCH_BANNER_ROW = 5;
