import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { Button } from '@fiverr-private/button';
import { I18n } from '@fiverr-private/i18n-react';
import { convertFromUSD } from '@fiverr-private/listings';
import { tracker, EVENTS_MAPPING, PriceConverter } from '@fiverr-private/listing_lib';
import { MAX_GIG_PRICE_RANGE, MIN_GIG_PRICE_RANGE } from '../../../../../common/floating_topbar/menus/consts';
import Types from '../../types';
import { AppContext } from '../../../../../context/listingsContext';
import { trackingPageName } from '../../../../../../../../shared/services/BIEvents/utils';
import { useBusinessListingsContext } from '../../../Context';
import { invokeApplyEvent, Triggers, useSidebarFiltersContext } from '../../Context';
import { PriceInput } from './PriceInput';

import styles from './index.module.scss';

const isInPriceRange = (num) => num > MIN_GIG_PRICE_RANGE && num < MAX_GIG_PRICE_RANGE;
const isLegitPrice = (priceStr) => !isNaN(priceStr) && isInPriceRange(priceStr);

const getInRangeNumber = (num) => {
    if (isNaN(num)) {
        return num;
    }

    if (num > MAX_GIG_PRICE_RANGE) {
        return MAX_GIG_PRICE_RANGE;
    }

    if (num < MIN_GIG_PRICE_RANGE) {
        return MIN_GIG_PRICE_RANGE;
    }

    return num;
};

const didInputChange = ({ maxPrice, maxPriceOrPlaceholder, activeMaxPrice }) => {
    const maxPriceStr = maxPrice?.toString();

    return maxPriceStr !== maxPriceOrPlaceholder?.toString() && maxPriceStr !== activeMaxPrice?.toString();
};

// Get placeholder in case the budget filter is active during rendering, if num is the max or the min value of the range,
// it means the filter is only using one of its options (from/to) and the other should be displayed as a placeholder.
const getActiveFilterPlaceholder = (num) => (isLegitPrice(num) ? num : '');

const Budget = ({ filter, i18n, isDisabled = false }) => {
    const { activeFilters } = useBusinessListingsContext();
    const { applyEvent, activeTrigger, newActiveFilters, setNewActiveFilters, clearAllEvent } =
        useSidebarFiltersContext();

    const shouldHaveApplyButton = activeTrigger === Triggers.FILTER_COMPONENT_CLICK;

    const { flow, isExpertListings, lowestGigsPrice: lowestGigsPriceInUSD, currency } = useContext(AppContext);
    const currencySymbol = filter.symbol ?? '';

    const activeMaxPrice = activeFilters[filter.id]?.[1] ?? MAX_GIG_PRICE_RANGE;

    const maxPriceOrPlaceholder = getActiveFilterPlaceholder(activeMaxPrice);

    const [maxPrice, setMaxPrice] = useState(maxPriceOrPlaceholder);
    const [hasError, setHasError] = useState(false);

    const inputChanged = didInputChange({
        maxPrice,
        maxPriceOrPlaceholder,
        activeMaxPrice,
    });

    const applyClicked = () => {
        const max = isLegitPrice(maxPrice) ? maxPrice : MAX_GIG_PRICE_RANGE;

        const currentActiveFilters = { ...(newActiveFilters || activeFilters) };
        currentActiveFilters[filter.id] = [MIN_GIG_PRICE_RANGE, max];

        invokeApplyEvent(applyEvent, currentActiveFilters, Triggers.FILTER_COMPONENT_CLICK);
    };

    const clearAllClicked = () => {
        const currentActiveFilters = { ...(newActiveFilters || activeFilters) };
        currentActiveFilters[filter.id] = [];

        invokeApplyEvent(applyEvent, currentActiveFilters, Triggers.FILTER_COMPONENT_CLICK);

        setMaxPrice('');
    };

    clearAllEvent.push(() => setMaxPrice(''));

    const pricePlaceholder = i18n.t('search_perseus.filter_builder.selected.price_range.enter_budget_title');

    const priceConverter = new PriceConverter(currency);
    const lowestGigsPrice = priceConverter.convert(lowestGigsPriceInUSD || 0);
    const minGigPriceTemplate = convertFromUSD(lowestGigsPriceInUSD || 0, currency);

    const shouldSetError = (maxPrice) => maxPrice && maxPrice < lowestGigsPrice;

    const debouncedErrorWait = 500;
    const debouncedError = useMemo(
        () =>
            debounce((maxPrice) => {
                setHasError(true);
                tracker.trackFilterValidationError(
                    filter.id,
                    maxPrice,
                    trackingPageName(isExpertListings, flow),
                    EVENTS_MAPPING.FILTER_VALIDATION_ERROR.ERROR_TYPE.LOW_PRICE_VALIDATION
                );
            }, debouncedErrorWait),
        [filter.id, isExpertListings, flow]
    );

    const onMaxPriceChange = (e) => {
        const newMaxPrice = getInRangeNumber(e.target.value);
        setMaxPrice(newMaxPrice);

        const maxPriceError = shouldSetError(newMaxPrice);

        if (maxPriceError) {
            debouncedError(newMaxPrice);
        } else {
            setHasError(false);
            debouncedError.cancel();
        }

        const currentActiveFilters = { ...(newActiveFilters || activeFilters) };

        const maxPriceNotInActiveFilters = currentActiveFilters[filter.id]?.[1] !== newMaxPrice;
        const currentInputChanged = didInputChange({
            newMaxPrice,
            maxPriceOrPlaceholder,
            activeMaxPrice,
        });

        if (!shouldHaveApplyButton && currentInputChanged && maxPriceNotInActiveFilters) {
            currentActiveFilters[filter.id] =
                !maxPriceError && isLegitPrice(newMaxPrice) ? [MIN_GIG_PRICE_RANGE, newMaxPrice] : null;

            setNewActiveFilters(currentActiveFilters);
        }
    };

    const shouldRenderMinPriceMessage = isExpertListings && (!maxPrice || hasError);

    return (
        <div className={styles.container}>
            <div className="p-b-16">
                <div className={styles.formContainer}>
                    <PriceInput
                        inputGroupClassName={styles.inputGroup}
                        isError={hasError}
                        title={i18n.t('search_perseus.filter_builder.selected.price_range.max')}
                        value={getInRangeNumber(maxPrice)}
                        currencySymbol={currencySymbol}
                        placeholder={pricePlaceholder}
                        onInputChange={onMaxPriceChange}
                        isDisabled={isDisabled}
                    />
                </div>

                {shouldRenderMinPriceMessage && (
                    <p className={classNames({ [styles.validationAlert]: hasError }, 'tbody-6', 'm-t-4')}>
                        {hasError ? (
                            <I18n
                                k="search_perseus.business_listings.sidebar_filters.budget.max_price_error"
                                params={{ minPrice: minGigPriceTemplate }}
                            />
                        ) : (
                            <I18n
                                k="search_perseus.business_listings.sidebar_filters.budget.price_starts_at"
                                params={{ minPrice: minGigPriceTemplate }}
                            />
                        )}
                    </p>
                )}
            </div>

            {shouldHaveApplyButton && (
                <div className={classNames(styles.actionsContainer, 'm-b-32')}>
                    <span className={classNames(styles.clearAll, 'tbody-6')} onClick={clearAllClicked}>
                        <I18n k="search_perseus.general.clear_all" />
                    </span>
                    <Button onClick={applyClicked} size={'sm'} disabled={!inputChanged || shouldSetError(maxPrice)}>
                        <I18n k="search_perseus.business_listings.sidebar_filters.budget.apply" />
                    </Button>
                </div>
            )}
        </div>
    );
};

Budget.propTypes = {
    filter: Types.filter.isRequired,
    i18n: PropTypes.object.isRequired,
};

export default Budget;
