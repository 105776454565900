import { isUndefined, chain } from 'lodash';
import { ASSET_TYPES, ASSET_APPROVED_STATUS_BY_TYPE, DELIVERY_ATTACHMENT_TYPES } from '../../shared/constants';
import { convertAssetThumbnail, transformToCamelCase } from '../../utils';

const AUDIO_DEFAULT_THUMBNAIL =
    'https://fiverr-res.cloudinary.com/t_gig_cards_web,q_auto/search_perseus/default-audio-bg.png';

const assetStatusValidators = {
    [ASSET_TYPES.IMAGE]: () => true,
    [ASSET_TYPES.VIDEO]: (status) => status === ASSET_APPROVED_STATUS_BY_TYPE.VIDEO,
    [ASSET_TYPES.AUDIO]: (status) => status === ASSET_APPROVED_STATUS_BY_TYPE.AUDIO,
};

const filerrFormatters = {
    [DELIVERY_ATTACHMENT_TYPES[ASSET_TYPES.IMAGE]]: ({ imageUrl, isVisible, id }) =>
        imageUrl && {
            id,
            type: ASSET_TYPES.IMAGE,
            isVisible,
            cloudImgMainGig: convertAssetThumbnail(conditionalEncodeURI(imageUrl)),
        },
    [DELIVERY_ATTACHMENT_TYPES[ASSET_TYPES.AUDIO]]: ({ streamUrl, type, id, isVisible, imageUrl }) =>
        streamUrl && {
            id,
            type: ASSET_TYPES.AUDIO,
            streamUrl,
            isVisible,
            attachmentId: `${id}_${type}`,
            cloudImgMainGig: imageUrl || AUDIO_DEFAULT_THUMBNAIL,
        },
    [DELIVERY_ATTACHMENT_TYPES[ASSET_TYPES.VIDEO]]: ({ streamUrl, imageUrl, type, id, isVisible }) =>
        streamUrl &&
        imageUrl && {
            id,
            type: ASSET_TYPES.VIDEO,
            streamUrl,
            isVisible,
            attachmentId: `${id}_${type}`,
            cloudImgMainGig: convertAssetThumbnail(conditionalEncodeURI(imageUrl)),
        },
};

const conditionalEncodeURI = (url) => (decodeURI(url) === url ? encodeURI(url) : url);

export const sortAssets = (assets) => {
    const order = [ASSET_TYPES.VIDEO, ASSET_TYPES.IMAGE, ASSET_TYPES.AUDIO];
    return chain(assets)
        .orderBy((asset) => order.indexOf(asset.type), 'asc')
        .orderBy((asset) => asset.isDelivery, 'desc')
        .value();
};

export const combineAssets = ({ assets = [], attachments = [], filteredDeliveryAttachments = [] }) => {
    const gigAssets = transformToCamelCase(assets)
        .filter(({ type, status }) => {
            if (type === ASSET_TYPES.DeliveryAsset) {
                return false;
            }
            const assetType = assetStatusValidators[type];
            return assetType ? assetType(status) : false;
        })
        .map((item) => ({
            ...item,
            ...(!isUndefined(item.cloudImgMainGig) && { cloudImgMainGig: convertAssetThumbnail(item.cloudImgMainGig) }),
        }));

    const gigAttachments = transformToCamelCase(attachments).map((item) => ({
        ...item,
        imageUrl: convertAssetThumbnail(item.imageUrl),
    }));

    const deliveryFiles = transformToCamelCase(filteredDeliveryAttachments)
        .map((item) => {
            const data = filerrFormatters[item.type](item);
            return data ? { ...data, isDelivery: true } : null;
        })
        .filter((item) => item && item.isVisible)
        .reduce(
            (acc, asset) => ({
                assets: [...acc.assets, asset],
                attachments: asset.attachmentId
                    ? [
                          ...acc.attachments,
                          {
                              id: asset.attachmentId,
                              imageUrl: asset.cloudImgMainGig,
                              streamUrl: asset.streamUrl,
                          },
                      ]
                    : acc.attachments,
            }),
            { assets: [], attachments: [] }
        );

    return {
        assets: sortAssets(gigAssets.concat(deliveryFiles.assets)),
        attachments: gigAttachments.concat(deliveryFiles.attachments),
    };
};
