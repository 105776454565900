import React, { useMemo, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { I18n } from '@fiverr-private/i18n-react';
import { useExpertCardContext } from '../../../contexts';
import { convertFromUSD } from '../../../utils/currencyConverter';
import { getCategoryKey } from '../../utils';
import { getDisplayPrice, getOpenExpertPageLinkTarget } from '../utils';
import { formatGigAssetsToThumbnails } from '../VisualArea/Visuals/utils';
import { ACTIVE_GIG_STATUSES } from '../../../constants';
import { InactiveGigMessage } from './InactiveGigMessage';

import './index.scss';

export const GigService = ({ className, gig, currency }) => {
    const { freelancerUrl } = useExpertCardContext();
    const containerRef = useRef(null);

    const { categoryId, nestedSubCategoryId, subCategoryId } = gig;
    const princeInUsd = getDisplayPrice([gig]);
    const price = princeInUsd && convertFromUSD(princeInUsd, currency);
    const isGigDefaultActive = !gig.status;
    const isGigActive = isGigDefaultActive || ACTIVE_GIG_STATUSES.includes(gig.status);

    const thumbnailSrc = useMemo(() => {
        if (gig) {
            const { assets, attachments, filteredDeliveryAttachments } = gig;
            return formatGigAssetsToThumbnails(assets, attachments, filteredDeliveryAttachments)[0]?.src;
        }

        return '';
    }, [gig]);

    return (
        <div className={classNames('service-card-container', className)} ref={containerRef}>
            <img className="service-image" src={thumbnailSrc} style={!isGigActive ? { filter: 'grayscale(1)' } : {}} />
            {!isGigActive ? (
                <InactiveGigMessage />
            ) : (
                <a className="gig-details co-text-darkest" target={getOpenExpertPageLinkTarget()} href={freelancerUrl}>
                    <p className="tbody-5 text-semi-bold text-trunc">
                        <I18n k={getCategoryKey({ categoryId, subCategoryId, nestedSubCategoryId })} />
                    </p>
                    <span className="flex">
                        <p className="tbody-6 text-trunc co-text-darker">
                            <I18n
                                k="listings.expert_card.footer.price"
                                params={{ price }}
                                templates={{
                                    priceSection: (text) => <span className="tbody-6 co-text-darkest">{text}</span>,
                                }}
                            />
                        </p>
                    </span>
                </a>
            )}
        </div>
    );
};

GigService.propTypes = {
    gig: PropTypes.array,
    className: PropTypes.string,
    currency: PropTypes.object,
};
