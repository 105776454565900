import { Conversation } from '@fiverr-private/listing_types';
import { OrchestratorMachineDispatchedAction } from '../../../types';

export const onReceiveChatHistory = (
    { messages = [] }: Conversation,
    dispatch: (value: OrchestratorMachineDispatchedAction) => void
) => {
    if (!messages?.length) {
        dispatch({ event: 'LAST_MESSAGE_BELONGS_TO_ASSISTANT' });
    }

    const lastMessage = messages[messages.length - 1];

    if (lastMessage?.sender.id === 'BOT') {
        dispatch({ event: 'LAST_MESSAGE_BELONGS_TO_ASSISTANT' });

        return;
    }

    if (lastMessage?.sender.id === 'USER') {
        dispatch({ event: 'LAST_MESSAGE_BELONGS_TO_USER' });

        return;
    }

    dispatch({ event: 'LAST_MESSAGE_BELONGS_TO_ASSISTANT' });
};
