import { reportActivity, ReportActivityOptions } from '@fiverr-private/bianka_js';
import { logger } from '@fiverr-private/obs';
import { getContext } from '@fiverr-private/fiverr_context';
import { metricsService } from '@fiverr-private/listing_lib';
import { PAGE_NAME } from '../../constants/pageName';
import { CONTEXT_ENUM } from '../../constants/contextEnum';
import { ImpressionSchema } from '../../types/impressionSchema';
import { ListingImpression } from '../../types/listingImpression';

interface ReportActivityResult {
    status: number;
    message?: string;
    description?: string;
}

const prepareListingPayload = (
    listing: ListingImpression,
    payload: ReportActivityOptions,
    impressionData: ImpressionSchema
) => {
    payload.event.listing = { ...listing };
    const {
        listing_attributes: { context },
    } = listing;

    if (context === CONTEXT_ENUM.SUBCATEGORY) {
        payload.event.component = { name: 'category_listings_page' };
    } else if (context === CONTEXT_ENUM.SEARCH) {
        payload.event.component = { name: 'search_listings_page' };
    }

    delete impressionData.listing;
};

const prepareGenericComponentPayload = (
    component: Record<string, any>,
    payload: ReportActivityOptions,
    impressionData: ImpressionSchema
) => {
    const componentName = Object.keys(component)[0];

    if (!componentName) {
        return;
    }

    payload.event[componentName] = { ...component[componentName] };

    if (!impressionData.name) {
        impressionData.name = componentName;
    }

    delete impressionData[componentName];
};

export const getBaseEventPayload = (pageName: string): ReportActivityOptions => {
    const { queryParameters, pageCtxId, userId, userGuid, isTouch } = getContext();
    const { context_referrer: contextReferrer, ref_ctx_id: refCtxId } = queryParameters;

    const event = {
        group: 'components_impressions',
        type: 'listings_impression',
        page: {
            name: pageName,
            ctx_id: pageCtxId,
            ...(refCtxId && {
                referrer: {
                    ctx_id: refCtxId,
                    name: contextReferrer,
                },
            }),
        },
    };

    const payload = {
        pageName,
        event,
        type: 'bianka.event.activity.v1.Impression',
        uid: userGuid,
        contextId: pageCtxId,
        platform: isTouch ? 'MOBILE_WEB' : 'WEB',
        testing: false,
        ...(userId && { user: { id: userId } }),
    };

    return payload;
};

/** @deprecated Should be replaced with direct calling to Bianka's `reportActivity`
 * @see new usage example:  `ProfessionBasedContent.tsx` */
export const sendImpressionEvent = async (
    impressionData: ImpressionSchema,
    pageName = PAGE_NAME.LISTINGS_PAGE
): Promise<void> => {
    const payload = getBaseEventPayload(pageName);

    const { listing, gig_card, modalities_carousel, delivery_card, seller_focused_card } = impressionData;

    if (listing) {
        prepareListingPayload(listing, payload, impressionData);
        metricsService.count(`listings.bianka_client.impression.${payload.event.component?.name}.started`);
    } else {
        prepareGenericComponentPayload(
            {
                ...(gig_card && { gig_card }),
                ...(modalities_carousel && { modalities_carousel }),
                ...(delivery_card && { delivery_card }),
                ...(seller_focused_card && { seller_focused_card }),
            },
            payload,
            impressionData
        );
    }

    payload.event.component = { ...payload.event.component, ...impressionData };

    const result = await reportActivity(payload);
    const { status, message, description } = result as unknown as ReportActivityResult;

    if (status !== 200) {
        logger.warn('Failed to send impression to bianka', { errorMessage: message, description, status });
    }

    if (listing) {
        metricsService.count(`listings.bianka_client.impression.${payload.event.component?.name}.status.${status}`);
        metricsService.count(`listings.bianka_client.impression.${payload.event.component?.name}.ended`);
    }
};
