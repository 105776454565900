import React from 'react';
import { Container } from '@fiverr-private/layout_components';
import { Typography } from '@fiverr-private/typography';
import { Checkbox } from '@fiverr-private/forms';
import number from '../../../../../../shared/utils/formatting/number';

interface MetadataFilterOptionProps {
    id: string;
    selected: boolean;
    alias: string;
    count: number;
    onOptionClick: (id: string, value: boolean) => void;
}

export const MetadataFilterOption = ({ id, selected, alias, count, onOptionClick }: MetadataFilterOptionProps) => {
    const onCheckboxClick: React.ChangeEventHandler<HTMLInputElement> = ({ target: { checked } }) => {
        onOptionClick(id, checked);
    };

    const countText = number(count);

    return (
        <Checkbox checked={selected} onChange={onCheckboxClick}>
            <Container flex="1">
                <Typography color="grey_1200" size="b_md">
                    {alias}
                </Typography>
            </Container>
            <Typography color="grey_1100" size="b_sm">
                {countText}
            </Typography>
        </Checkbox>
    );
};
