import { FILTER_COLLECTION_IDS } from '@fiverr-private/listing_lib';
import { DISPLAY_TYPES } from '../../../../../config/filters';

export const RADIO_BASED_FILTER_COLLECTIONS = [
    FILTER_COLLECTION_IDS.BUDGET_FILTERS,
    FILTER_COLLECTION_IDS.DELIVERY_TIME,
    FILTER_COLLECTION_IDS.SUB_CATEGORY,
];

export const SINGLE_SELECT_BASED_DISPLAY_TYPES = [
    DISPLAY_TYPES.INPUT_RANGE,
    DISPLAY_TYPES.NUMBER_INPUT,
    DISPLAY_TYPES.PRICE_BUCKETS,
];

export const FILTERS_DRAWER_TRACKING_LOCATION = 'Side Filters';
