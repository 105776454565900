import React, { useContext } from 'react';
import { array } from 'prop-types';
import { I18n } from '@fiverr-private/fiverr_context';
import { Button } from '@fiverr-private/fit';
import { FILTER } from '@fiverr-private/listing_lib';
import EditableComboBox from '../../../../../shared/components/EditableSelect';
import { AppContext } from '../../context/listingsContext';
import { useLanguagePairsOptions } from '../../../hooks/useLanguagePairsOptions/useLanguagePairsOptions';
import translation from './translation.svg';
import { applyLanguagePair, isSelected } from './utils';

import './index.scss';

const ExplicitLanguagePair = ({ filters = [] }) => {
    const { activeFilters = {} } = useContext(AppContext);

    const languagesList = filters.find(({ id }) => id === FILTER.LANGUAGES_PAIR.ID);

    const {
        activeFromLanguageValue,
        activeToLanguageValue,
        fromLanguage,
        setFromLanguage,
        toLanguage,
        setToLanguage,
        fromLanguageOptions,
        toLanguageOptions,
    } = useLanguagePairsOptions(languagesList?.options);

    if (isSelected(activeFromLanguageValue) && isSelected(activeToLanguageValue)) {
        return null;
    }

    return (
        <div className="m-b-30">
            <h5 className="m-b-16 m-t-16">
                <I18n k="search_perseus.filter.languages_pair.title" />
            </h5>
            <div className="language-pair-container">
                <img src={translation} />
                <div className="form-wrapper">
                    <div className="language-combo-box-container">
                        <p className="tbody-5">
                            <I18n k="search_perseus.filter.languages_pair.from" />
                        </p>
                        <EditableComboBox
                            className="language-combo-box"
                            value={fromLanguage}
                            onChange={setFromLanguage}
                            options={fromLanguageOptions}
                            placeholder={<I18n k="search_perseus.filter.languages_pair.placeholder" />}
                        />
                    </div>
                    <div className="language-combo-box-container">
                        <p className="tbody-5">
                            <I18n k="search_perseus.filter.languages_pair.to" />
                        </p>
                        <EditableComboBox
                            className="language-combo-box"
                            value={toLanguage}
                            onChange={setToLanguage}
                            options={toLanguageOptions}
                            placeholder={<I18n k="search_perseus.filter.languages_pair.placeholder" />}
                        />
                    </div>
                    <Button
                        className="apply-btn"
                        color="black"
                        onClick={() => applyLanguagePair(fromLanguage, toLanguage, activeFilters)}
                    >
                        <I18n k="search_perseus.general.apply" />
                    </Button>
                </div>
            </div>
        </div>
    );
};

ExplicitLanguagePair.propTypes = {
    filters: array,
};

export default ExplicitLanguagePair;
