import { ELEMENT_NAMES, ELEMENT_TYPES, EVENT_TYPES } from '../../constants';
import { sendClickEventToBQ } from '../../BigQuery';

export const clickOnSkillsEvent = ({ seller, elementText, componentName, pageName, params } = {}) => {
    sendClickEventToBQ({
        type: EVENT_TYPES.CLICKED_ON_SELLER_SKILLS,
        elementName: ELEMENT_NAMES.SELLER_SKILLS,
        elementType: ELEMENT_TYPES.BUTTON,
        group: 'user_actions',
        elementText,
        seller,
        componentName,
        pageName,
        params,
    });
};

export const clickOnMoreSkillsEvent = ({ seller, moreCount, componentName, pageName, params } = {}) => {
    sendClickEventToBQ({
        type: EVENT_TYPES.CLICKED_ON_SELLER_MORE_SKILLS,
        elementName: ELEMENT_NAMES.SELLER_MORE_SKILLS,
        elementType: ELEMENT_TYPES.BUTTON,
        elementText: `${ELEMENT_NAMES.SELLER_MORE_SKILLS}_${moreCount}`,
        group: 'user_actions',
        seller,
        componentName,
        pageName,
        params,
    });
};

export default { clickOnSkillsEvent, clickOnMoreSkillsEvent };
