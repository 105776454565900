import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getContext } from '@fiverr-private/fiverr_context';
import { I18n } from '@fiverr-private/i18n-react';
import { ProBadge } from '@fiverr-private/badges';
import { toClassname } from '../../utils';
import { CAPTIONED_BADGES_LIST, PRO_BADGE_LOCALE_CLASS_MAPPER, DEFAULT_LOCALE } from '../constants';
import TooltipWrapper from '../../../shared/components/TooltipWrapper';
import './style.scss';

const Badge = ({ type, classes, excludeTooltip }) => {
    const { locale = DEFAULT_LOCALE } = getContext();

    if (!type) {
        return null;
    }

    const containerClassNames = classNames('badge-wrapper', `badge-${type}`);

    if (type === 'pro') {
        return (
            <div className={containerClassNames}>
                <ProBadge showTooltip={!excludeTooltip} />
            </div>
        );
    }

    const localeClass = PRO_BADGE_LOCALE_CLASS_MAPPER[locale] || '';

    const badgeProps = {
        className: classNames('gig-badge', localeClass, classes, toClassname(type)),
    };

    const hasCaption = CAPTIONED_BADGES_LIST.includes(type);
    const tooltipContent = <I18n k={`gig_listings.gig_cards.badges.${type}.tooltip`} />;

    return (
        <div className={containerClassNames}>
            <TooltipWrapper className="badge-tooltip" shouldRenderTooltip={!excludeTooltip} content={tooltipContent}>
                <div {...badgeProps}>{hasCaption && <I18n k={`gig_listings.gig_cards.badges.${type}.caption`} />}</div>
            </TooltipWrapper>
        </div>
    );
};

Badge.propTypes = {
    type: PropTypes.string,
    classes: PropTypes.string,
    excludeTooltip: PropTypes.bool,
};

Badge.defaultProps = {
    classes: '',
    excludeTooltip: false,
};

export default Badge;
