import { isEmpty } from 'lodash';
import { getContext } from '@fiverr-private/fiverr_context';
import { shouldShowVisualFilters } from '../../../experiments/serviceTilesSC/utils';
import { biUserAction } from '../../../../../shared/services/BIEvents/utils';
import { BI_PAGE_NAMES } from '../../../../../shared/services/BIEvents/constants';
import { MIN_SLC_TAGS, TAG_TYPE, TAG_ENTITIES, BI_EVENTS_TYPES } from './constants';

export const getFilterTagsProps = ({
    isSearch,
    displayQuery,
    dominateSubCategoryId,
    significantLeafCategories,
    appFilters,
    categoryIds,
}) => {
    if (isEmpty(appFilters)) {
        return;
    }

    const { queryParameters: { sub_category } = {}, isTouch } = getContext();
    const { visualFilters } = appFilters;

    if (isSearch) {
        const showVisualFilters = visualFilters?.shouldDisplayVisualFilters;

        if (showVisualFilters) {
            return getVisualFilterTags({ visualFilters, appFilters, subCategoryId: dominateSubCategoryId });
        }

        if (!sub_category && !isEmpty(significantLeafCategories)) {
            return getSlcFilterTags({ significantLeafCategories, appFilters, displayQuery });
        }
    } else {
        const { subCategory } = appFilters;
        const { subCategoryId, nestedSubCategoryId } = categoryIds;

        if (!nestedSubCategoryId) {
            const { children = [] } = subCategory;
            const nscItems = children[0]?.children;

            if (!isEmpty(nscItems)) {
                return getNscFilterTags({ nscItems });
            }
        }

        const matchFilterCarouselRules =
            visualFilters && shouldShowVisualFilters({ isTouch, subCategoryId, nestedSubCategoryId });

        if (matchFilterCarouselRules) {
            return getVisualFilterTags({ visualFilters, appFilters, subCategoryId });
        }
    }
};

export const getLeafCategoryById = (leafCategories, id) =>
    leafCategories.find(({ params: { sub_category, nested_sub_category } }) => {
        const leafCategoryId = nested_sub_category || sub_category;

        return leafCategoryId === id;
    });

const getVisualFilterTags = ({ visualFilters, appFilters, subCategoryId }) => {
    const { visualFiltersList, stepIndex } = visualFilters;
    const { filters = [] } = appFilters;

    if (stepIndex === -1) {
        return;
    }

    const { id: filterId, options } = visualFiltersList[stepIndex];
    const currentFilter = filters.find(({ id }) => id === filterId);

    if (!currentFilter) {
        return;
    }

    const { options: currentFilterOptions = [] } = currentFilter;

    const tags = options.map(({ alias, id: optionId }) => ({
        alias,
        filterId,
        optionId,
        type: TAG_TYPE.FILTER,
    }));

    if (!tags.length) {
        return;
    }

    const showMoreLink = currentFilterOptions.length > options.length;

    if (showMoreLink) {
        tags.push({
            filterId,
            viewMoreParams: {
                isMulti: true,
                subCategoryId,
                currentFilterOptions,
                modalTitleKey: `search_perseus.filter_tiles.${subCategoryId}.${filterId}`,
            },
            type: TAG_TYPE.VIEW_MORE,
        });
    }

    return { tags, entity: TAG_ENTITIES.VISUAL_FILTERS, filterId, subCategoryId };
};

const getSlcFilterTags = ({ significantLeafCategories, appFilters, displayQuery }) => {
    const { subCategory: { children = [] } = {} } = appFilters;

    const tags = [];

    for (const significantLeafCategory of significantLeafCategories) {
        const leafCategoryItem = getLeafCategoryById(children, significantLeafCategory);

        if (!leafCategoryItem) {
            continue;
        }

        const { alias, params = {} } = leafCategoryItem;
        const tagId = params.nested_sub_category || params.sub_category;

        tags.push({
            alias,
            params,
            id: tagId,
            type: TAG_TYPE.FILTER,
        });
    }

    if (tags.length < MIN_SLC_TAGS) {
        return;
    }

    const currentFilterOptions = children.map(({ alias, params = {} }) => ({
        alias,
        params,
        id: params.nested_sub_category || params.sub_category,
    }));

    tags.push({
        viewMoreParams: {
            isMulti: false,
            currentFilterOptions,
            modalTitleKey: 'search_perseus.subcategory_tiles.view_all.title',
            modalTitleParams: { query: displayQuery },
        },
        type: TAG_TYPE.VIEW_MORE,
    });

    return { tags, entity: TAG_ENTITIES.SLC_FILTERS };
};

export const getNscFilterTags = ({ nscItems }) => {
    const tags = nscItems.map(({ id, alias, url, params }) => {
        const urlParams = new URLSearchParams(params);
        const href = `${url}?${urlParams.toString()}`;

        return {
            leafCategoryId: id,
            alias,
            href,
            type: TAG_TYPE.LINK,
        };
    });

    if (isEmpty(tags)) {
        return;
    }

    return { tags, entity: TAG_ENTITIES.LEAF_CATEGORIES };
};

export const buildEventEnrichment = ({
    type,
    group,
    elementName,
    elementType,
    elementMetadata,
    elementPosition,
    componentName,
}) => {
    const { pageCtxId, queryParameters: { ref_ctx_id: refCtxId } = {} } = getContext();

    return {
        type,
        ...(group && { group }),
        page: {
            name: BI_PAGE_NAMES.LISTINGS,
            ctx_id: pageCtxId,
            element: {
                name: elementName,
                type: elementType,
                ...(elementMetadata && { metadata: elementMetadata }),
                ...(elementPosition && { position: elementPosition }),
            },
            ...(componentName && {
                component: {
                    name: componentName,
                },
            }),
            ...(refCtxId && {
                referrer: {
                    ctx_id: refCtxId,
                },
            }),
        },
    };
};

export const sendBiClickEvent = (elementName, elementMetadata, elementType, elementPosition, componentName) => {
    const eventEnrichment = buildEventEnrichment({
        type: BI_EVENTS_TYPES.CLICK,
        elementName,
        elementMetadata,
        elementType,
        elementPosition,
        componentName,
    });
    biUserAction({ ...eventEnrichment });
};
