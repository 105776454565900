import { RequestContext } from '@fiverr-private/perseus';
import { isActive } from '@fiverr-private/rollout_js';
import { InitialProps } from '@fiverr-private/listing_types';
import { EXPERIMENT_TYPE } from '@fiverr-private/experiments_package';
import { EXPERIMENTS, ROLLOUTS } from '@fiverr-private/listing_experiments';

const isEligibleForAllocation = () => {
    const { userId, initialProps } = RequestContext;
    const { userData } = initialProps as InitialProps;
    const isLoggedIn = !!userId;
    const isRnc = !!userData.isRncUser;
    return isLoggedIn && !isRnc;
};

const inRollout = async () => {
    const { userId } = RequestContext;
    if (!userId) return false;
    return await isActive(ROLLOUTS.STARLINGS_BOOSTING_NEW_GIGS, Number(userId));
};

export const starlingsBoostingNewGigs = {
    id: EXPERIMENTS.STARLINGS_BOOSTING_NEW_GIGS,
    experimentType: EXPERIMENT_TYPE.USER,
    numOfGroups: 2,
    shouldAllocate: isEligibleForAllocation,
    inRollout,
};
