import React, { useMemo } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { I18n } from '@fiverr-private/i18n-react';
import { logger } from '@fiverr-private/obs';
import { combineAssets } from '../../../AssetsCarousel/utils'; // TODO: Move
import { assetType, attachmentType, filteredDeliveryAttachmentType } from '../../../../shared/types';
import { clickOnVisualsEvent, clickOnLightboxScrollEvent } from '../../../../utils/biEvents/events/clickOnVisualsEvent';
import { GigServiceCardLightbox } from '../../../GigServices/GigServiceCardLightbox';
import { useGigServiceCardLightbox } from '../../../GigServices/GigServiceCardLightbox/useGigServiceCardLightbox';
import { AssetThumbnail } from '../../../AssetThumbnail';
import { biEnrichmentType } from '../../types';

import './index.scss';

export const Visuals = ({ assets, deliveries, attachments, numOfThumbnailsToShow, biEnrichment, lazyLoadAssets }) => {
    const { isPreviewOpened, sourceInitialIndex, setSourceInitialIndex, sources, closePreview, openPreview } =
        useGigServiceCardLightbox({ assets, attachments, filteredDeliveryAttachments: deliveries });

    const thumbnails = useMemo(() => {
        const { assets: combinedAssets = [], attachments: combinedAttachments = [] } = combineAssets({
            assets,
            attachments,
            filteredDeliveryAttachments: deliveries,
        });
        return combinedAssets.slice(0, numOfThumbnailsToShow).map((asset) => ({
            asset,
            attachment: combinedAttachments.find(({ id }) => id === asset.attachmentId),
        }));
    }, [assets, attachments, deliveries, numOfThumbnailsToShow]);

    const thumbnailClickHandle = (i) => {
        setSourceInitialIndex(i);
        openPreview();
        clickOnVisualsEvent({
            elementPosition: i + 1,
            ...biEnrichment,
        });
    };

    const lightboxScrollClickHandle = (direction) => {
        clickOnLightboxScrollEvent({
            elementText: direction,
            ...biEnrichment,
        });
    };

    const moreLength = sources.length - numOfThumbnailsToShow;
    const shouldOverlayMoreItems = (index) => moreLength > 0 && index === numOfThumbnailsToShow - 1;

    if (isEmpty(sources) || isEmpty(assets)) {
        logger.error('Expert card visuals - empty sources', biEnrichment);
        return null;
    }

    // TODO: Replace with CSS Grid
    const maxSlideWidth = `calc((100% - 8px * (${numOfThumbnailsToShow} - 1)) / ${numOfThumbnailsToShow})`;

    return (
        <>
            <div className="expert-card-visuals-container">
                {thumbnails.map(({ asset, attachment }, i) => (
                    <div
                        className="visuals-wrapper"
                        style={{ maxWidth: maxSlideWidth }}
                        key={i}
                        onClick={() => thumbnailClickHandle(i)}
                    >
                        <div className={shouldOverlayMoreItems(i) && 'more-overlay'} />
                        <AssetThumbnail
                            className="visual-asset"
                            asset={asset}
                            attachment={attachment}
                            lazyLoad={lazyLoadAssets}
                            zoomOnHover
                        />
                        {shouldOverlayMoreItems(i) && (
                            <span className="more-text">
                                <span className="tbody-4 text-bold">+{moreLength}</span>
                                <I18n k="listings.expert_card.visuals.more_assets" className="tbody-5" />
                            </span>
                        )}
                    </div>
                ))}
            </div>
            {isPreviewOpened && (
                <GigServiceCardLightbox
                    sources={sources}
                    sourceInitialIndex={sourceInitialIndex}
                    onScroll={lightboxScrollClickHandle}
                    onClose={closePreview}
                />
            )}
        </>
    );
};

Visuals.propTypes = {
    assets: PropTypes.arrayOf(assetType),
    deliveries: PropTypes.arrayOf(filteredDeliveryAttachmentType),
    attachments: PropTypes.arrayOf(attachmentType),
    numOfThumbnailsToShow: PropTypes.number,
    biEnrichment: biEnrichmentType,
    lazyLoadAssets: PropTypes.bool,
};
