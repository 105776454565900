import React, { useEffect, useState } from 'react';
import { groupBy, isEmpty } from 'lodash';
import { getContext } from '@fiverr-private/fiverr_context';
import { usePagination } from '@fiverr-private/listings/src/hooks';
import { activeFiltersToQueryString, registerStickyFilter } from '@fiverr-private/listing_lib';
import { FLOWS, LISTING_TYPE } from '../utils/constants';
import { useCountFlowAndListingTypeView } from '../hooks';
import { EXPERT_LISTINGS_URL_PARAM, INITIAL_PAGE } from './generic_listings/constants';
import { onLoadMore } from './generic_listings/utils';
import { AppError, ErrorBoundary } from './common';
import { pushParamToUrlHistory } from './common/util';
import ExpertListingContainer from './generic_listings/ExpertListingsContainer';
import GenericListingsAppProvider from './context/GenericListingsAppProvider';
import { BusinessLayout, ItemsContainer, Sidebar } from './business_listings/BusinessPageLayout';
import { BusinessListingsContextProvider } from './business_listings/BusinessPageLayout/Context/provider';
import './generic_listings/index.scss';

const GenericListings = (props) => {
    const {
        flow,
        items,
        currency,
        appFilters,
        activeFilters,
        dominateSubCategoryId,
        significantLeafCategories,
        modifiedQueryString,
        searchQueryEnrichment = {},
        searchInsteadUrl,
        activeExperiments,
        subCategoryData,
        categoryIds,
        breadcrumbs,
        pagination = {},
        rollouts = {},
        isExperiential,
        resultsCount,
        displayData,
        managedAccount,
        // TODO: Cleanup after default filters test is done
        defaultFilters,
        isDefaultFilters = false,
        // TODO: Cleanup - remove from here and from perseus backend
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        vettedResultsCount,
        loyaltyData = {},
    } = props;

    useEffect(() => {
        registerStickyFilter(activeFilters);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [cardItems, setCardItems] = useState(items);
    const [hasMore, setHasMore] = useState(pagination.initialHasMore);
    const initialPaginationParams = { offset: pagination.initialOffset, page: INITIAL_PAGE };
    const { queryParameters: { query } = {} } = getContext();

    useEffect(() => {
        pushParamToUrlHistory(EXPERT_LISTINGS_URL_PARAM, 'true');

        // TODO: Cleanup after default filters test is done
        if (isDefaultFilters) {
            pushParamToUrlHistory('ref', activeFiltersToQueryString(activeFilters));
        }
    }, [activeFilters, isDefaultFilters]);

    const { isLoading, onPagination } = usePagination({
        onLoadMore: ({ paginationParams }) => onLoadMore({ setHasMore, setCardItems, cardItems, paginationParams }),
        initialPaginationParams,
    });

    const hasItems = !isEmpty(items);
    const isSearch = flow === FLOWS.SEARCH || flow === FLOWS.BUSINESS_SEARCH;
    const displayQuery = modifiedQueryString ? modifiedQueryString : query;
    const { nonTranslatedQuery, query: translatedQuery, querySourceLanguage } = searchQueryEnrichment;

    useCountFlowAndListingTypeView(flow, LISTING_TYPE.EXPERTS);

    const { RecommendedForFiverrBusiness: recommendedItems = [] } = groupBy(
        cardItems,
        (item) => item?.seller?.groupType
    );

    const hasRecommendedItems = recommendedItems.length;
    const searchTitleKey = hasRecommendedItems ? 'search_title' : 'search_title_only_more_result';

    const searchHeaderProps = {
        searchTitleKey,
        modifiedQueryString,
        nonTranslatedQuery,
        searchInsteadUrl,
        translatedQuery,
    };
    const { filters, selectedFilters, subCategory, visualFilters } = appFilters;
    const { header } = displayData;
    const subcategoryName = header?.title ?? subCategoryData?.display?.name;

    return (
        <div className="listings-perseus">
            <ErrorBoundary fallback={AppError}>
                <GenericListingsAppProvider {...props}>
                    <BusinessListingsContextProvider
                        rollouts={rollouts}
                        resultsCount={resultsCount}
                        isExpertListings={true}
                        isSearch={isSearch}
                        filters={filters}
                        activeFilters={activeFilters}
                        visualFilters={visualFilters}
                        selectedFilters={selectedFilters}
                        subCategory={subCategory}
                        subcategoryName={subcategoryName}
                        breadcrumbs={breadcrumbs}
                        hasItems={hasItems}
                        displayQuery={displayQuery}
                        searchQueryData={searchHeaderProps}
                        managedAccount={managedAccount}
                    >
                        <BusinessLayout>
                            <Sidebar />
                            <ItemsContainer>
                                <ExpertListingContainer
                                    resultsCount={resultsCount}
                                    cardItems={cardItems}
                                    hasMore={hasMore}
                                    onPagination={onPagination}
                                    isLoading={isLoading}
                                    isSearch={isSearch}
                                    categoryIds={categoryIds}
                                    isExperiential={isExperiential}
                                    currency={currency}
                                    activeFilters={activeFilters}
                                    searchHeaderProps={searchHeaderProps}
                                    loyaltyData={loyaltyData}
                                    rollouts={rollouts}
                                    impressionEnrichment={{
                                        appFilters,
                                        activeFilters,
                                        categoryIds,
                                        isSearch,
                                        flow,
                                        dominateSubCategoryId,
                                        significantLeafCategories,
                                        modifiedQueryString,
                                        nonTranslatedQuery,
                                        translatedQuery,
                                        querySourceLanguage,
                                        activeExperiments,
                                        numberOfResults: resultsCount,
                                        defaultFilters,
                                    }}
                                />
                            </ItemsContainer>
                        </BusinessLayout>
                    </BusinessListingsContextProvider>
                </GenericListingsAppProvider>
            </ErrorBoundary>
        </div>
    );
};

export default GenericListings;
