import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { noop } from 'lodash';
import { Icon } from '@fiverr-private/fit';
import { FullscreenIcon } from '@fiverr-private/icons';
import { white } from '@fiverr-private/sass/helpers/index';
import { Image } from '@fiverr-private/media';

import './index.scss';

export const AssetThumbnailImage = ({
    src,
    onClick = null,
    onHover = noop,
    zoomOnHover = false,
    className = '',
    lazyLoad,
}) => {
    const handleClick = (e) => {
        e.stopPropagation();
        onClick();
    };

    return (
        <div
            className={classNames(className, 'asset-thumbnail-image', { 'zoom-on-hover': zoomOnHover })}
            onClick={onClick && handleClick}
            onMouseOver={onHover}
        >
            {!zoomOnHover && (
                <div className="icon-wrapper">
                    <Icon color={white} size={16}>
                        <FullscreenIcon />
                    </Icon>
                </div>
            )}
            <Image src={src} loading={lazyLoad ? 'lazy' : 'eager'} />
        </div>
    );
};

AssetThumbnailImage.propTypes = {
    src: PropTypes.string,
    onClick: PropTypes.func,
    onHover: PropTypes.func,
    zoomOnHover: PropTypes.bool,
    className: PropTypes.string,
    lazyLoad: PropTypes.bool,
};
