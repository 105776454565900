import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { ServiceConfig } from '@fiverr-private/listing_types';
import { isBrowser } from '../util/isBrowser/isBrowser';
import { getStatusCode, measureOnFailure, measureOnSuccess } from './maxios.utils';

type CustomAxiosConfig = AxiosRequestConfig & {
    service: ServiceConfig;
    routeKey: string;
};

/**
 * Intercept all axios requests and responses for monitoring successes and failures
 */
export const monitoringInterceptor = (axiosInstance: AxiosInstance) => {
    if (!axiosInstance) {
        return;
    }

    axiosInstance.interceptors.request.use(
        (config = {}) => {
            const { service, routeKey, ...axiosConfig } = (config || {}) as CustomAxiosConfig;
            const serviceName: string = isBrowser() ? 'search_perseus_client' : service.name;

            return {
                ...axiosConfig,
                startedAt: Date.now(),
                identifier: `${serviceName}.${routeKey}`,
            };
        },
        (error) => Promise.reject(error)
    );

    axiosInstance.interceptors.response.use(
        (response) => {
            const { identifier, startedAt } = (response?.config || {}) as any;
            measureOnSuccess(identifier, startedAt, response?.status);

            return response;
        },
        (error = {}) => {
            const { identifier, startedAt } = error.config || {};
            const status = getStatusCode(error);
            measureOnFailure(identifier, startedAt, status);

            throw error;
        }
    );
};

/**
 * maxios is an axios instance which supports extended axios config. It allows us to make axios requests
 * and get metrics that can help us investigate the behavior of each request without sending
 * the metrics manually.
 *
 * routeKey - path unique id (pathfinder value) - for AJAX routes
 * service - external service configuration object - for external requests on the server
 */
const axiosInstanceSingleton = axios.create();
monitoringInterceptor(axiosInstanceSingleton);
export const maxios: AxiosInstance | any = axiosInstanceSingleton; // fixme remove any
