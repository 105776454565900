import { translate } from '../../../../../shared/utils';
import { constructOptionObject } from '../_utils';

export const getFilterLabel = (facet, translationMap) => {
    const filterGroupTranslationKey = translationMap[facet.id];
    if (!filterGroupTranslationKey) {
        return facet.name;
    }
    const groupTranslation = translate(filterGroupTranslationKey);
    return groupTranslation || facet.name;
};

export const createFilterOptions = (facets, translationMap, selectedOptions) =>
    facets
        .map((facet) => {
            const { count } = facet || {};
            if (!count) return null;
            const filterOption = {
                ...facet,
                id: facet.name,
                alias: getFilterLabel(facet, translationMap),
            };

            return constructOptionObject(filterOption, selectedOptions);
        })
        .filter((option) => !!option);
