export const AI_CONSULTATION_BANNER_BG_IMAGE_URLS = {
    xl: 'https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto/v1/attachments/generic_asset/asset/443dec29b02a760ef618b7bb77d0aab6-1709463737444/AI_XL_1760px.png',
    lg: 'https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto/v1/attachments/generic_asset/asset/c5e4700ef8cf89ae4afc5b314f5fec95-1709463930246/AI_LG_1399-1160px.png',
    md: 'https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto/v1/attachments/generic_asset/asset/443dec29b02a760ef618b7bb77d0aab6-1709463737448/AI_MD_1159px-900px.png',
    sm: 'https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto/v1/attachments/generic_asset/asset/443dec29b02a760ef618b7bb77d0aab6-1709463737424/AI_SM_899px-600px.png',
    default:
        'https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto/v1/attachments/generic_asset/asset/443dec29b02a760ef618b7bb77d0aab6-1709463737431/AI_Mobile%20XS_599-320.png',
};

export const AI_CONSULTATION_BANNER_SOURCES = Object.keys(AI_CONSULTATION_BANNER_BG_IMAGE_URLS).map((media) => ({
    media,
    srcSet: {
        regular: AI_CONSULTATION_BANNER_BG_IMAGE_URLS[media],
    },
}));
