import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isFunction } from 'lodash';
import { Carousel } from '@fiverr-private/orca';
import { useImpressionContainer } from '@fiverr-private/impressionable';
import { createTracker, setCarouselGigsData, useRenderableGigs } from '../utils';
import { SCOPE_CLASS } from '../utils/constants';
import withImpressions from '../hoc/withImpressions';
import { EVENT_TYPES } from '../utils/tracking/constants';
import { RolloutsContextProvider } from '../context/RolloutsContext/RolloutsContext';
import { ListingCarouselHeader } from './Components/ListingCarouselHeader';
import useScroll from './Hooks/useScroll';

import './index.scss';

const ListingCarousel = React.forwardRef(
    (
        {
            slidesToShow,
            sliderBreakpoints,
            isTouch,
            collectImpressionable,
            isInfinite,
            tracking,
            isLoading,
            gigImpression,
            onClear,
            gigs,
            RenderGig,
            layoutClass,
            lazyLoad,
            getContainerRef,
            showTranslatedUGC,
            collectProps,
            onImpression,
            hidePrefix,
            showTopRightArrows = false,
            HeaderComponent,
            rollouts = {},
            arrowsWrapperClassName,
        },
        forwardRef
    ) => {
        const ref = useRef(null);
        const [containerRef] = useImpressionContainer();
        const [loadingState, setLoadingState] = useState(isLoading);
        const [removedGigs, setRemovedGigs] = useState([]);
        const [tracker] = useState(createTracker(tracking));
        const listingCarouselObserver = useRef(null);

        const carouselArrowClick = () => {
            tracker.carousel.trackSlideEvent();
            collectImpressionable();
        };

        useEffect(() => {
            const { sourceComponent, sourcePage, pageCtxId } = gigImpression;

            if (isLoading && typeof IntersectionObserver !== 'undefined') {
                listingCarouselObserver.current = new IntersectionObserver(([entry]) => {
                    if (entry.isIntersecting) {
                        tracker.carousel.trackLoading({
                            type: EVENT_TYPES.CAROUSEL_DELAY_IMPRESSION,
                            group: 'carousel_impression',
                            item_context: {
                                source_component: sourceComponent,
                                source_page: sourcePage,
                                page_ctx_id: pageCtxId,
                            },
                        });
                        listingCarouselObserver.current.unobserve(ref.current);
                    }
                });

                listingCarouselObserver.current.observe(ref.current);
            }

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        useEffect(() => {
            if (loadingState !== isLoading && listingCarouselObserver.current) {
                listingCarouselObserver.current.unobserve(ref.current);
            }

            setLoadingState(isLoading);
        }, [loadingState, isLoading, ref]);

        const onClearClick = (id) => {
            setRemovedGigs([...removedGigs, id]);
            onClear && onClear(id);
        };

        const { gig: gigTracker } = tracker;
        const slides = useRenderableGigs(
            gigs.filter((gig) => !removedGigs.includes(gig.id)),
            isLoading,
            slidesToShow,
            lazyLoad && slidesToShow
        );

        const carouselClassName = classNames(SCOPE_CLASS, 'listing-carousel', layoutClass, { 'touch-slider': isTouch });
        const carouselGigs = setCarouselGigsData(
            slides,
            {
                layoutClass,
                isTouch,
                isLoading,
                getContainerRef,
                showTranslatedUGC,
                collectProps,
                onImpression,
                hidePrefix,
            },
            onClearClick,
            gigTracker
        );

        const [updateScroll, navigationRef, canScrollRight, canScrollLeft] = useScroll({
            canScrollLeftDefault: false,
            canScrollRightDefault: carouselGigs.length > slidesToShow,
        });

        const onScroll = (...eventProps) => {
            if (showTopRightArrows) {
                updateScroll(...eventProps);
            }
        };

        const displayScroll = canScrollLeft || canScrollRight;

        return (
            <RolloutsContextProvider value={{ rollouts }}>
                <div
                    ref={(el) => {
                        ref.current = el;
                        isFunction(forwardRef) && forwardRef(el);
                    }}
                >
                    <div className={carouselClassName} ref={containerRef}>
                        {showTopRightArrows && (
                            <ListingCarouselHeader
                                headerComponent={HeaderComponent}
                                displayScroll={displayScroll}
                                navigationRef={navigationRef}
                                canScrollLeft={canScrollLeft}
                                canScrollRight={canScrollRight}
                                arrowsWrapperClassName={arrowsWrapperClassName}
                            />
                        )}
                        <Carousel
                            slides={carouselGigs}
                            Component={RenderGig}
                            scrollType={Carousel.SCROLL_TYPE.ROW}
                            slidesToShow={slidesToShow}
                            isInfinite={isInfinite}
                            breakpoints={sliderBreakpoints}
                            swipeMode={isTouch}
                            hideDefaultArrows={showTopRightArrows}
                            navigationRef={navigationRef}
                            onScroll={onScroll}
                            onArrowClick={carouselArrowClick}
                            autoSlidesWidth={false}
                        />
                    </div>
                </div>
            </RolloutsContextProvider>
        );
    }
);

ListingCarousel.propTypes = {
    gigs: PropTypes.array,
    isTouch: PropTypes.bool,
    isLoading: PropTypes.bool,
    lazyLoad: PropTypes.bool,
    sliderBreakpoints: PropTypes.array.isRequired,
    slidesToShow: PropTypes.number.isRequired,
    isInfinite: PropTypes.bool,
    tracking: PropTypes.object.isRequired,
    getContainerRef: PropTypes.func.isRequired,
    collectImpressionable: PropTypes.func.isRequired,
    onClear: PropTypes.func,
    layoutClass: PropTypes.string,
    showTranslatedUGC: PropTypes.bool,
    hidePrefix: PropTypes.bool,
    gigImpression: PropTypes.object,
    collectProps: PropTypes.object,
    onImpression: PropTypes.func,
    RenderGig: PropTypes.node,
    showTopRightArrows: PropTypes.bool,
    HeaderComponent: PropTypes.node,
    rollouts: PropTypes.object,
    arrowsWrapperClassName: PropTypes.string,
};

ListingCarousel.displayName = 'ListingCarousel';

export { ListingCarousel };
export default withImpressions(ListingCarousel);
