import React from 'react';
import { Modal, ModalContent, ModalCloseBtn } from '@fiverr-private/overlay';
import { Video } from '@fiverr-private/media';
import { Container, Wrap } from '@fiverr-private/layout_components';

const VIDEO = {
    src: 'https://fiverr-res.cloudinary.com/video/upload/t_fiverr_hd/v1/video-attachments/generic_asset/asset/f9ffece19371f692ee7310c1ebe9856f-1722502586561/FiverrPro_Hourly_Buyers',
    type: 'video/mp4',
};

export const HowItWorksVideo: React.FC<{ isOpen: boolean; onClose(): void }> = ({ isOpen, onClose }) => (
    <Modal isOpen={isOpen} onClose={onClose} fullScreenBreakpoint="none">
        <ModalContent size="xl" closeOnOverlayClick closeOnEscapeKey>
            <Container position="absolute" zIndex="1" margin="4" top={0} right={0}>
                <ModalCloseBtn color="dark" onClick={onClose} />
            </Container>
            <Wrap justifyContent="center" alignItems="center" width="100%" height="100%">
                <Video sources={[VIDEO]} width="100%" autoPlay />
            </Wrap>
        </ModalContent>
    </Modal>
);
