import React from 'react';
import { array } from 'prop-types';
import { I18n } from '@fiverr-private/i18n-react';
import { useImpressionItem } from '@fiverr-private/impressionable';
import { getContext } from '@fiverr-private/fiverr_context';
import LeafCategoryTile from '../tiles/leafCategoryTile';
import TilesCarousel from '../tiles_carousel';
import { buildEventEnrichment } from '../../generic_listings/TagFilters/utils';
import { VIEW_ALL_COMPONENT } from '../top_filters_carousel/constants';
import { BI_ELEMENTS_NAMES, BI_EVENTS_TYPES, ELEMENT_TYPE } from '../../generic_listings/TagFilters/constants';
import { BI_EVENT_GROUPS } from '../../../../../shared/services/BIEvents/constants';
import {
    MAX_TILES_TO_SHOW,
    MINIMUM_TILES_LENGTH,
    MOBILE_SLIDE_PADDING,
    SLIDE_PADDING,
} from '../tiles_carousel/constants';

import './index.scss';

const Title = ({ query }) => (
    <h6 className="m-t-12 tbody-3">
        <I18n k={'search_perseus.subcategory_tiles.title'} params={{ query }} />
    </h6>
);

const LeafCategoriesCarousel = ({ leafCategoryTiles = [] }) => {
    const { isTouch } = getContext();
    const enrichment = buildEventEnrichment({
        type: BI_EVENTS_TYPES.IMPRESSION,
        group: BI_EVENT_GROUPS.USER_IMPRESSION,
        elementName: BI_ELEMENTS_NAMES.LEAF_CATEGORY,
        elementType: ELEMENT_TYPE.VISUAL,
    });
    const [ref] = useImpressionItem({ enrichment });

    if (leafCategoryTiles.length < MINIMUM_TILES_LENGTH) {
        return null;
    }

    leafCategoryTiles.push({ id: VIEW_ALL_COMPONENT });

    return (
        <div ref={ref} className="sc-carousel">
            <Title />
            <div className="sc-carousel-wrapper">
                <TilesCarousel
                    items={leafCategoryTiles}
                    TileComponent={LeafCategoryTile}
                    tilePadding={isTouch ? MOBILE_SLIDE_PADDING : SLIDE_PADDING}
                    maxTilesToShow={MAX_TILES_TO_SHOW}
                />
            </div>
        </div>
    );
};

LeafCategoriesCarousel.propTypes = {
    leafCategoryTiles: array,
};

export default LeafCategoriesCarousel;
