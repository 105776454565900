import { useState, useEffect } from 'react';
import { env } from '@fiverr-private/futile';

const getWidth = () =>
    env.browser ? window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth : null;

/**
 * Update window width on each window resize
 */
const useCurrentWidth = () => {
    const [width, setWidth] = useState(getWidth());

    useEffect(() => {
        const resizeHandler = () => setWidth(getWidth());
        window.addEventListener('resize', resizeHandler);

        return () => window.removeEventListener('resize', resizeHandler);
    }, []);

    return width;
};

export default useCurrentWidth;
