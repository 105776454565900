import React from 'react';
import { getContext } from '@fiverr-private/fiverr_context';
import { useExpertCardContext } from '../../../contexts';
import { MobileSellerDetails } from './MobileSellerDetails';
import { DesktopSellerDetails } from './DesktopSellerDetails';
import { AgencySellerDetails } from './AgencySellerDetails';

import propTypes from './types';

/**
 * A component that describes all the basic seller details.
 */
const SellerDetails = ({ isSellerOnline, isCardHovered, source, showJobTitle, onSellerDetailsClick }) => {
    const { isMobile } = getContext();
    const { isAgencyCard } = useExpertCardContext();

    let Component;
    if (isAgencyCard) {
        Component = AgencySellerDetails;
    } else if (isMobile) {
        Component = MobileSellerDetails;
    } else {
        Component = DesktopSellerDetails;
    }

    return (
        <Component
            isSellerOnline={isSellerOnline}
            isCardHovered={isCardHovered}
            source={source}
            showJobTitle={showJobTitle}
            onSellerDetailsClick={onSellerDetailsClick}
        />
    );
};

SellerDetails.propTypes = propTypes;

export default SellerDetails;
