import React from 'react';
import classnames from 'classnames';
import { chain, isEmpty } from 'lodash';
import { Tag, Tooltip } from '@fiverr-private/fit';
import { I18n } from '@fiverr-private/i18n-react';
import { ProBadge } from '@fiverr-private/badges';
import { SellerLevelBadge } from '../SellerLevel';
import propTypes from './types';
import { BADGES } from './constants';

import './index.scss';

export const PRIORITY = [BADGES.CHOICE, BADGES.PRO, BADGES.BUSINESS];

const badgeMap = {
    [BADGES.CHOICE]: {
        text: (
            <I18n
                k="listings.badges.choice.caption"
                templates={[(text) => text, (text) => <span className="co-green">{text}</span>]}
            />
        ),
        tooltip: <I18n k="listings.badges.choice.tooltip" />,
    },
    [BADGES.BUSINESS]: {
        text: <I18n k="listings.badges.business.caption" />,
        tooltip: '',
    },
};

const SellerBadge = ({ className, badges = {}, badge: definedBadge, dataTestId, sellerLevel }) => {
    const badge = isEmpty(badges)
        ? definedBadge
        : chain(badges)
              .keys()
              .orderBy((key) => PRIORITY.indexOf(key))
              .find((badge) => badges[badge] === true)
              .value();

    if (badge === BADGES.PRO) {
        return (
            <div className={classnames('flex', className)}>
                <ProBadge appendTooltipToBody dataTestId={dataTestId} />
            </div>
        );
    }

    const { text = '', tooltip = '' } = badgeMap[badge] || {};

    if (!text) {
        if (sellerLevel) {
            return <SellerLevelBadge sellerLevel={sellerLevel} />;
        }

        return null;
    }

    const contentChild = (
        <Tag className={classnames('seller-level-tag', badge, className)} borderRadius={Tag.BORDER_RADIUSES.ROUNDED}>
            {text}
        </Tag>
    );

    return tooltip ? (
        <Tooltip boxContentClassName="seller-badge-tooltip-box" content={tooltip}>
            {contentChild}
        </Tooltip>
    ) : (
        <>{contentChild}</>
    );
};

SellerBadge.propTypes = propTypes;
SellerBadge.BADGES = BADGES;

export default SellerBadge;
