import { isNil } from 'lodash';

export const shouldRenderFilterOption = (count) => isNil(count) || count > 0;

export const transformToComboboxOptions = (label, value) => ({
    label,
    value,
});

export const sortByDescLength = (a, b) => b.length - a.length;

export const groupByFilterGroup = (items) =>
    items.reduce(
        (acc, item) => {
            if (!item.group) {
                acc.all.push(item);
                return acc;
            }

            if (!acc[item.group]) {
                acc[item.group] = [];
            }

            acc[item.group].push(item);
            return acc;
        },
        { all: [] }
    );
