import { isArray, isString } from 'lodash';
import { logger } from '@fiverr-private/obs';

export const queryParamToString = (queryParam: unknown): string | undefined => {
    if (!queryParam) {
        return;
    }

    if (isString(queryParam)) {
        return queryParam;
    } else if (isArray(queryParam)) {
        return queryParam.join(',');
    }

    logger.warn({ description: `queryParamToString: queryParam is not a string or an array`, queryParam });
};
