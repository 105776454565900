import React, { useContext } from 'react';
import pathfinder from '@fiverr-private/pathfinder';
import { I18n } from '@fiverr-private/i18n-react';
import { Wrap } from '@fiverr-private/layout_components';
import { Image } from '@fiverr-private/media';
import { Text, Link } from '@fiverr-private/typography';
import { Button } from '@fiverr-private/button';
import { tracker, EXPERT_BANNER_TYPE } from '@fiverr-private/listing_lib';
import { BannerContainer, BI_EVENT_TYPES, mixPanelBasePayload, T_PREFIX } from '../util';
import { AppContext } from '../../../context/listingsContext';
import { reportBigQueryBannerClick } from '../bqReports';
import { getBqEventSource } from '../../biUtils';
import { AI_CONSULTATION_BANNER_BG_IMAGE_URLS, AI_CONSULTATION_BANNER_SOURCES } from './constants';

import { AIBannerButton, bannerContainer } from './AIConsultationBanner.ve.css';

export const AIConsultationBanner = ({ resultsCount, hasActiveFilters, position, userData }) => {
    const { isExpertListings } = useContext(AppContext);

    const openAIConsultationPage = () => {
        reportBigQueryBannerClick(
            BI_EVENT_TYPES.CLICK_ON_AI_SERVICE_BANNER,
            isExpertListings,
            resultsCount,
            hasActiveFilters,
            position
        );

        tracker.trackAIConsultationBannerClick(mixPanelBasePayload(userData?.role, isExpertListings));
    };

    const buttonTextProps = { color: 'white', fontWeight: 'semibold' };

    return (
        <Link
            href={pathfinder(
                'content_pages_page',
                { page_slug: 'ai-dev-services' },
                { query: { source: getBqEventSource(isExpertListings) } }
            )}
            onClick={openAIConsultationPage}
            decoration="none"
        >
            <BannerContainer
                position={position}
                resultsCount={resultsCount}
                hasActiveFilters={hasActiveFilters}
                bannerType={EXPERT_BANNER_TYPE.AI_CONSULTATION}
                disableStyles
            >
                <Wrap
                    alignItems="center"
                    justifyContent="flexStart"
                    width="100%"
                    className={bannerContainer}
                    height={{
                        default: '166px',
                        sm: '220px',
                        xl: '240px',
                    }}
                    overflow="hidden"
                    borderRadius="lg"
                    position="relative"
                >
                    <Image
                        alt="AIConsultationBanner"
                        src={AI_CONSULTATION_BANNER_BG_IMAGE_URLS.default}
                        sources={AI_CONSULTATION_BANNER_SOURCES}
                        width="100%"
                        height="100%"
                        objectFit="cover"
                    />
                    <Wrap
                        position="absolute"
                        top="0"
                        direction="column"
                        alignItems="flexStart"
                        gap={{
                            default: '3',
                            sm: '6',
                        }}
                        marginY={{
                            default: '6',
                            sm: '10',
                            md: '8',
                            xl: '12',
                        }}
                        marginX={{
                            default: '4',
                            sm: '7',
                        }}
                        width={{
                            default: '202px',
                            sm: '373px',
                            lg: '412px',
                        }}
                    >
                        <Text
                            color="white"
                            fontWeight="bold"
                            size={{
                                default: 'b_lg',
                                sm: 'h_lg',
                                xl: 'h_xl',
                            }}
                            lineHeight={{
                                default: 'h_xs',
                                sm: 'h_lg',
                                xl: 'h_xl',
                            }}
                            marginY={{
                                default: '3',
                                sm: '0',
                            }}
                        >
                            <I18n k={`${T_PREFIX}.ai_consultation.title`} />
                        </Text>
                        <Button
                            onClick={openAIConsultationPage}
                            colorScheme="business"
                            className={AIBannerButton}
                            size="sm"
                        >
                            <Text {...buttonTextProps} hidden={{ default: false, sm: true }}>
                                <I18n k={`${T_PREFIX}.ai_consultation.button_short`} />
                            </Text>
                            <Text {...buttonTextProps} hidden={{ default: true, sm: false }}>
                                <I18n k={`${T_PREFIX}.ai_consultation.button`} />
                            </Text>
                        </Button>
                    </Wrap>
                </Wrap>
            </BannerContainer>
        </Link>
    );
};
