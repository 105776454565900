import React from 'react';
import { string, node } from 'prop-types';
import { Tooltip } from '@fiverr-private/tooltip';

const ToggleTooltip = ({ content, children }) => (
    <Tooltip position="bottom" content={content}>
        {children}
    </Tooltip>
);

ToggleTooltip.propTypes = {
    content: string,
    children: node,
};

export default ToggleTooltip;
