import { SearchPerseusConfig } from '@fiverr-private/listing_types';

export const searchPerseusConfigStub: SearchPerseusConfig = {
    port: 1234,
    appName: 'search',
    plugins: {},
    search_service: { name: 'go_searcher', url: '', timeout: { search: 100, categories: 100 } },
    business_accounts_chimera: { name: '', url: '', timeout: {} },
    listings_service: { name: '', url: '', timeout: {} },
    autocomplete_service: { name: '', url: '', timeout: {} },
    machine_translation_pegasus: { name: '', url: '', timeout: {} },
    listings_pegasus: { name: '', url: '', timeout: {} },
    recommendations_service: { name: '', url: '', timeout: {} },
    promoted_gigs_service: { name: '', url: '', timeout: {} },
    cms_kimera: { name: '', url: '', timeout: {} },
    website_creation_chimera_service: { name: '', url: '', timeout: {} },
    business_users_kimera: { name: '', url: '', timeout: {} },
    reviews_kimera: { name: '', url: '', timeout: {} },
    seo_service: { name: '', url: '', timeout: {} },
    content_pages_pegasus_service: { name: '', url: '', timeout: {} },
    experiment_service: { name: '', url: '', timeout: {} },
    rollout_pegasus: { name: '', url: '', timeout: {} },
    maker_algo: { name: '', url: '', timeout: {} },
    logo_maker_kotlin_phoenix: { name: '', url: '', timeout: {} },
    logo_maker_editor_kimera: { name: '', url: '', timeout: {} },
    federation_gql_pegasus: { name: '', url: '', timeout: {} },
    gigs_phoenix: { name: '', url: '', timeout: {} },
    go_personalization: { name: '', url: '', timeout: {} },
};
