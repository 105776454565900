import React from 'react';
import { useIsClient } from '@fiverr-private/hooks';
import { getContext } from '@fiverr-private/fiverr_context';
import { PaginationProps } from './types';
import { MobilePagination } from './MobilePagination/MobilePagination';
import { DesktopPagination } from './DesktopPagination/DesktopPagination';

export const Pagination = (props: PaginationProps) => {
    const isClient = useIsClient();
    const { isTouch } = getContext();

    if (props.pagination.total === 0) {
        return null;
    }

    if (!isClient) {
        return null;
    }

    return isTouch ? <MobilePagination {...props} /> : <DesktopPagination {...props} />;
};
