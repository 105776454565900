import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { forEach } from 'lodash';
import { Checkbox, Tag } from '@fiverr-private/fit';
import { I18n } from '@fiverr-private/i18n-react';
import number from '../../../../../../../../shared/utils/formatting/number';
import { shouldRenderFilterOption, groupByFilterGroup, sortByDescLength } from '../../../../filter/utils';
import { TagMapping } from './constants';
import './index.scss';

const LABEL_CLASS_NAME = 'label';
const EXTRA_ITEM_CLASS_NAME = 'extra-item';

class CheckboxList extends Component {
    constructor(props) {
        super(props);
        this.checkboxListRef = React.createRef();
        this.getValues = this.getValues.bind(this);

        const groupedByFilterGroup = groupByFilterGroup(
            props.items.filter(({ count }) => shouldRenderFilterOption(count))
        );

        this.state = {
            groups: Object.keys(groupedByFilterGroup)
                .sort(sortByDescLength)
                .map((key) => ({
                    id: key,
                    items: groupedByFilterGroup[key],
                })),
        };
    }

    componentDidMount() {
        this.updateActualLabelWidth();
    }

    componentDidUpdate() {
        this.updateActualLabelWidth(false);
    }

    updateActualLabelWidth(excludeExtraItems = true) {
        const queryExcludeExtraItems = excludeExtraItems ? `label:not(.${EXTRA_ITEM_CLASS_NAME})` : '';
        const labels = this.checkboxListRef.current.querySelectorAll(`${queryExcludeExtraItems} .${LABEL_CLASS_NAME}`);

        forEach(labels, (label) => {
            const offsetWidth = label.querySelector('span').offsetWidth;

            if (offsetWidth && !label.style.width) {
                label.style.width = `${offsetWidth + 1}px`;
            }
        });
    }

    getValues() {
        return { [this.props.id]: this.props.selected };
    }

    syncValues() {
        return this.props.onClear();
    }

    render() {
        const { id, selected = [], onSelect, checkboxClassname, visibleCheckboxListItems, isExpanded } = this.props;
        const { groups } = this.state;
        const hasGroups = Object.keys(groups).length > 1;

        return (
            <>
                {groups.map((group, groupIndex) => {
                    const shouldHideCheckbox = !isExpanded && groupIndex > 0;

                    return (
                        <Fragment key={group.id}>
                            {hasGroups && (
                                <div
                                    className={classNames('tbody-8 text-bold co-text-light m-t-16', {
                                        hidden: shouldHideCheckbox,
                                    })}
                                >
                                    <I18n k={`search_perseus.filter.${group.id}`} />
                                </div>
                            )}
                            <div className="checkbox-list" ref={this.checkboxListRef}>
                                {group.items.map((item, filterIndex) => {
                                    const isSelected = selected.includes(item.id);

                                    return (
                                        <Checkbox
                                            name={item.id}
                                            key={item.id}
                                            data-testid={`checkbox-filter-${item.id}`}
                                            onChange={(e) =>
                                                onSelect({
                                                    id,
                                                    item,
                                                    checked: e.target.checked,
                                                })
                                            }
                                            className={classNames('checkbox', checkboxClassname, {
                                                [EXTRA_ITEM_CLASS_NAME]:
                                                    shouldHideCheckbox || filterIndex + 1 > visibleCheckboxListItems,
                                            })}
                                            checkmarkClassName={classNames('checkmark-box', {
                                                'black-checked-mark': isSelected,
                                            })}
                                            checked={isSelected}
                                            value={item.id}
                                        >
                                            <div className="inner-checkbox">
                                                <div className={LABEL_CLASS_NAME}>
                                                    <span>{item.alias}</span>
                                                </div>
                                                {!!item.tag && (
                                                    <Tag
                                                        className="tag"
                                                        theme={TagMapping[item.tag].theme}
                                                        color={TagMapping[item.tag].color}
                                                    >
                                                        <I18n
                                                            k={`search_perseus.filter.tags.${
                                                                TagMapping[item.tag].text
                                                            }`}
                                                        />
                                                    </Tag>
                                                )}
                                                {!!item.count && (
                                                    <span className="count">{`(${number(item.count)})`}</span>
                                                )}
                                                {!!item.description && (
                                                    <span className="description m-t-4 co-text-light tbody-6">
                                                        {item.description}
                                                    </span>
                                                )}
                                            </div>
                                        </Checkbox>
                                    );
                                })}
                            </div>
                        </Fragment>
                    );
                })}
            </>
        );
    }
}

CheckboxList.propTypes = {
    id: PropTypes.string,
    items: PropTypes.array.isRequired,
    selected: PropTypes.array.isRequired,
    onSelect: PropTypes.func.isRequired,
    onClear: PropTypes.func,
    checkboxClassname: PropTypes.string,
    visibleCheckboxListItems: PropTypes.number,
    isExpanded: PropTypes.bool,
};

export default CheckboxList;
