const { EXPERIMENT_TYPE } = require('@fiverr-private/experiments_package');

const ALLOCATION_DEFAULTS = {
    experimentType: EXPERIMENT_TYPE.GUEST,
    numOfGroups: 2,
};

module.exports = {
    ALLOCATION_DEFAULTS,
};
