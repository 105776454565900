import { useContext } from 'react';
import { BusinessListingsContext, BusinessListingsContextProvider, BusinessListingsContextType } from './provider';

export const useBusinessListingsContext: () => BusinessListingsContextType = () => {
    const context: BusinessListingsContextType | null = useContext(BusinessListingsContext);

    if (!context) {
        throw new Error('BusinessListingsContext was used outside of a provider');
    }

    return context;
};
export const BusinessListingsProvider = BusinessListingsContextProvider;
