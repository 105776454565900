import { getContext } from '@fiverr-private/fiverr_context';
import { FlowName } from '@fiverr-private/listing_types';
import { ModalType, openFipMigrationModal } from '@fiverr-private/pro_migration';
import { buildRedirectQueryParameters, BUSINESS_TRIGGER_CTA } from '@fiverr-private/business_success_sign_modal';
import { getProCatalogLinkWithHourlyFilter } from '../../floating_topbar/menus/content/budget/banners/HourlyRates.utils';
import { reportBqUpgradeClickEvent } from './HourlyRate.events';

export const SOURCE = 'hourly_listing_entry';

export const openHourlyMigrationModal = () => {
    openFipMigrationModal({
        variant: ModalType.SeamlessHourly,
        payload: {
            source: SOURCE,
            biEnrichment: {
                pageElementName: 'hourly_entry',
            },
            onSuccess: () => {
                const redirectUrl = getProCatalogLinkWithHourlyFilter({ source: SOURCE });
                const url = buildRedirectQueryParameters({ redirectUrl, type: BUSINESS_TRIGGER_CTA.DEFAULT });
                window.open(url, '_self');
            },
        },
    });
};

export const redirectGuestToProCatalog = () => {
    const redirectUrl = getProCatalogLinkWithHourlyFilter({ source: SOURCE });
    window.open(redirectUrl, '_self');
};

export const onUpgradeClick = ({ flowName, onRedirectWaiting }: { flowName: FlowName; onRedirectWaiting(): void }) => {
    const isLoggedIn = !!getContext().userId;
    reportBqUpgradeClickEvent({ flowName });

    if (isLoggedIn) {
        // Open seamless upgrade modal, with an hourly copy
        openHourlyMigrationModal();
    } else {
        // Open Pro catalog, filtered hourly.
        redirectGuestToProCatalog();
        onRedirectWaiting();
    }
};
