import React, { useMemo, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { isEmpty, noop } from 'lodash';
import { Carousel } from '@fiverr-private/orca';
import { assetType, attachmentType, filteredDeliveryAttachmentType } from '../../shared/types';
import { ARROW_MODE } from '../../shared/constants';
import { AssetCarouselItem } from './AssetCarouselItem';
import { combineAssets } from './utils';

import './index.scss';

export const AssetsCarousel = ({
    className,
    assets = [],
    attachments = [],
    filteredDeliveryAttachments = [],
    onAssetClick = noop,
    onScroll,
    isInfinite = true,
    arrowsDisplayMode = ARROW_MODE.ALWAYS,
    isHovered,
    swipeMode,
    trackClassName,
    swipeModeIndicator,
    lazyLoad = false,
}) => {
    const [isCarouselHovered, setIsCarouselHovered] = useState(false);
    const showArrowsOnHover = arrowsDisplayMode === ARROW_MODE.HOVER;
    const slides = useMemo(() => {
        const data = combineAssets({ assets, attachments, filteredDeliveryAttachments });
        return data.assets.map((asset, index) => ({
            asset,
            attachment: data.attachments.find(({ id }) => id === asset.attachmentId),
            onClick: () => onAssetClick(index),
        }));
    }, [assets, attachments, filteredDeliveryAttachments, onAssetClick]);

    const assetItemProps = {
        lazyLoad,
        isParentHovered: isCarouselHovered,
    };

    return (
        <div
            className={classnames(className, 'assets-carousel-component', {
                'show-arrows-on-hover': showArrowsOnHover,
            })}
            onClick={(e) => e.stopPropagation()}
            onMouseEnter={() => setIsCarouselHovered(true)}
            onMouseLeave={() => setIsCarouselHovered(false)}
        >
            {isEmpty(slides) ? null : (
                <Carousel
                    Component={AssetCarouselItem}
                    componentProps={assetItemProps}
                    onScroll={onScroll}
                    arrowsClassName={classnames('assets-carousel-arrow', { hovered: isHovered })}
                    trackClassName={classnames('assets-carousel-track', trackClassName)}
                    slidesToShow={1}
                    slides={slides}
                    arrowSize={14}
                    breakpoints={{
                        [Carousel.BREAKPOINTS.BP_1300]: 1,
                    }}
                    swipeMode={swipeMode}
                    withSlidesIndicator
                    isInfinite={isInfinite}
                    swipeModeIndicator={swipeModeIndicator}
                />
            )}
        </div>
    );
};

AssetsCarousel.propTypes = {
    className: PropTypes.string,
    assets: PropTypes.arrayOf(assetType),
    attachments: PropTypes.arrayOf(attachmentType),
    filteredDeliveryAttachments: PropTypes.arrayOf(filteredDeliveryAttachmentType),
    onAssetClick: PropTypes.func,
    onScroll: PropTypes.func,
    isInfinite: PropTypes.bool,
    isHovered: PropTypes.bool,
    arrowsDisplayMode: PropTypes.oneOf(Object.values(ARROW_MODE)),
    swipeMode: PropTypes.bool,
    trackClassName: PropTypes.string,
    swipeModeIndicator: PropTypes.oneOf(Object.values(Carousel.SWIPE_MODE_INDICATOR)),
    lazyLoad: PropTypes.bool,
};
