const { RequestContext } = require('@fiverr-private/perseus');
const { isActive } = require('@fiverr-private/rollout_js');
const { ROLLOUTS, ALLOCATIONS, EXPERIMENTS } = require('@fiverr-private/listing_experiments');

const inRollout = async () => {
    const { userGuid } = RequestContext;

    return await isActive(ROLLOUTS.HAWKS_SORT_BY_PRICE_POST_FREEZE, userGuid);
};

const shouldShowSortByPrice = async (clientExperiments, context) => {
    const {
        experience: { isBusiness },
        isTouch,
        abTests,
    } = RequestContext;

    if (isBusiness || isTouch) {
        return false;
    }

    if (
        Number(abTests[EXPERIMENTS.HAWKS_SORT_BY_PRICE_POST_FREEZE]) === Number(ALLOCATIONS.TEST_B) ||
        Number(abTests[EXPERIMENTS.HAWKS_SORT_BY_PRICE_POST_FREEZE]) === Number(ALLOCATIONS.TEST_C)
    ) {
        return true;
    }

    if (Number(abTests[EXPERIMENTS.HAWKS_SORT_BY_PRICE_POST_FREEZE]) === Number(ALLOCATIONS.CONTROL)) {
        return false;
    }

    if (!(await inRollout())) {
        return false;
    }

    const sortByPriceGroup = Number(await clientExperiments.sortByPrice.calculateGroup(context));

    return sortByPriceGroup === Number(ALLOCATIONS.TEST_B) || sortByPriceGroup === Number(ALLOCATIONS.TEST_C);
};

module.exports = {
    inRollout,
    shouldShowSortByPrice,
};
