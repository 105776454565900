import { NestedSubCategoryFilter } from '../../types';
import { ExposedFilterPropsMapper } from '../types';

export const nestedSubCategoriesMapper: ExposedFilterPropsMapper = (context) => {
    const { appFilters: { exposedFilters } = {} } = context;

    const nestedSubCategoryFilter: NestedSubCategoryFilter = exposedFilters?.nested_subcategory_filters?.[0] || {};
    const children = nestedSubCategoryFilter.children;
    const items = children?.[0]?.children;

    return { items };
};
