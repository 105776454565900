import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from '@fiverr-private/i18n-react';
import { numberFormat } from '@fiverr-private/localization';
import InlineStarSvg from './inlineStarSvg';
import { MAX_COUNT } from './constants';
import './index.scss';

const Rating = ({ rating, count }) => {
    if (!rating) {
        return <div className="rating-wrapper"></div>;
    }
    const formattedRating = numberFormat(rating, { fallbackValue: rating, minimumFractionDigits: 1 });

    return (
        <div className="rating-wrapper">
            <span className="gig-rating text-body-2">
                <InlineStarSvg />
                {formattedRating}
                <span>({count > MAX_COUNT ? <I18n k="gig_listings.gig_cards.count_format" /> : count})</span>
            </span>
        </div>
    );
};

Rating.propTypes = {
    rating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    count: PropTypes.number,
};

export default Rating;
