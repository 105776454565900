import React from 'react';
import { object } from 'prop-types';
import classNames from 'classnames';
import { noop } from 'lodash';
import { Base } from '@fiverr-private/modal/src';
import { Button, Icon } from '@fiverr-private/fit';
import { PlayOutlineSolidIcon } from '@fiverr-private/icons';
import { tracker as listingsTracker } from '@fiverr-private/listing_lib';
import ModalBody from '../ModalBody';
import { GENERAL_VIDEO_MODAL_NAME, VIDEO_PROP_TYPES } from '../constants';

import './index.scss';

const ExplanationVideo = ({
    subcat = {},
    onOpen = noop,
    className,
    video,
    videoTitle,
    sourceInfo,
    showButton = true,
}) => {
    const handleOpen = () => {
        if (onOpen !== noop) {
            onOpen();
        } else {
            const { categoryName, title } = subcat;

            listingsTracker.trackHowFiverrWorksVideo({ categoryName, subcategoryName: title });
        }
    };

    return (
        <div className={classNames('explanation-video', className)}>
            {subcat.subtitle && <p className="sc-subtitle">{subcat.subtitle}</p>}
            <span className="separator"> &#124; </span>
            {showButton && (
                <Base
                    name={GENERAL_VIDEO_MODAL_NAME}
                    onOpen={handleOpen}
                    trigger={({ open }) => (
                        <Button className="btn-play" size={Button.SIZES.SMALL} onClick={open}>
                            <Icon size={14} className="play-icon">
                                <PlayOutlineSolidIcon />
                            </Icon>
                            {videoTitle}
                        </Button>
                    )}
                >
                    {() => <ModalBody video={video} sourceInfo={sourceInfo} />}
                </Base>
            )}
        </div>
    );
};

ExplanationVideo.propTypes = {
    ...VIDEO_PROP_TYPES,
    subcat: object,
};

export default ExplanationVideo;
