import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { I18nProvider } from '@fiverr-private/fiverr_context';
import RelatedGuides from '@fiverr-private/related_guides';
import { BRAND_STYLE_GUIDE_SC_SLUG } from '../../../utils/constants';
import { Faqs } from './components/Faqs';
import { RelatedLinks } from './components/RelatedLinks';
import './index.scss';

const SeoSubCategory = ({
    title,
    faqs,
    answersLink,
    relatedLinksTitle,
    relatedLinks,
    relatedSkillsTitle,
    relatedSkillsLinks,
    relatedGuides,
    seeMoreText,
    seeMoreLink,
    cachedSlug,
}) => {
    const relatedGuidesSourcePageKey = `subcategories_${cachedSlug}`;
    return (
        <React.Fragment>
            {!_.isEmpty(relatedLinks) && <RelatedLinks title={relatedLinksTitle} relatedLinks={relatedLinks} />}
            {!_.isEmpty(relatedSkillsLinks) && (
                <RelatedLinks title={relatedSkillsTitle} relatedLinks={relatedSkillsLinks} />
            )}
            {!_.isEmpty(faqs) && <Faqs title={title} faqs={faqs} answersLink={answersLink} />}
            {!_.isEmpty(relatedGuides) && (
                <I18nProvider>
                    {({ i18n }) => {
                        const relatedGuidesTitle =
                            cachedSlug === BRAND_STYLE_GUIDE_SC_SLUG
                                ? i18n.t('search_perseus.seo_sub_category.related_guides.brand_style_guides_title')
                                : i18n.t('search_perseus.seo_sub_category.related_guides.title', {
                                      sub_category_title: title,
                                  });
                        return (
                            <RelatedGuides
                                guides={relatedGuides}
                                seeMoreText={seeMoreText}
                                seeMoreLink={seeMoreLink}
                                title={relatedGuidesTitle}
                                sourcePageKey={relatedGuidesSourcePageKey}
                            />
                        );
                    }}
                </I18nProvider>
            )}
        </React.Fragment>
    );
};

SeoSubCategory.propTypes = {
    relatedLinksTitle: PropTypes.string,
    relatedSkillsTitle: PropTypes.string,
    title: PropTypes.string,
    cachedSlug: PropTypes.string,
    seeMoreText: PropTypes.string,
    seeMoreLink: PropTypes.string,
    faqs: PropTypes.array,
    relatedLinks: PropTypes.array,
    relatedSkillsLinks: PropTypes.array,
    relatedGuides: PropTypes.array,
};

SeoSubCategory.defaultProps = {
    title: '',
    relatedLinksTitle: '',
    relatedSkillsTitle: '',
    cachedSlug: '',
    seeMoreText: '',
    seeMoreLink: '',
    faqs: [],
    relatedLinks: [],
    relatedSkillsLinks: [],
    relatedGuides: [],
};

export default SeoSubCategory;
