import React from 'react';
import classNames from 'classnames';
import { string, element } from 'prop-types';

export const IconItem = ({ containerClassName, Icon, text, iconColor }) => (
    <span className={classNames('flex flex-items-center', containerClassName)}>
        <Icon color={iconColor} />
        <p className="m-l-4">{text}</p>
    </span>
);

IconItem.propTypes = {
    iconColor: string,
    text: element,
    Icon: element,
    containerClassName: string,
};
