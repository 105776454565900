import { ListingAttributes } from '@fiverr-private/listing_types';
import { UnableToSetListingsData } from './UnableToSetListingsData';

export class CannotFetchListingsData extends UnableToSetListingsData {
    description: string;
    flowName?: string;
    listingAttributes?: string;
    requestUrl?: string;

    constructor(error: Error, listingAttributes: ListingAttributes, flowName?: string, url?: string) {
        super(error);

        this.description = `Unable to fetch listings data`;
        this.flowName = flowName;
        this.listingAttributes = JSON.stringify(listingAttributes);
        this.requestUrl = url;
    }
}
