import { get } from 'lodash';
import { metric } from '../../services/metric';
import { OrchestratorStateMachineEvent, OrchestratorMachineState } from '../../types';
import { ORCHESTRATOR_MACHINE_METRIC_PREFIX } from '../../constants';
import { ORCHESTRATOR_TRANSITIONS } from './orchestratorMachineReducer.config';

export const handleEventAction = (
    state: OrchestratorMachineState,
    action: OrchestratorStateMachineEvent
): OrchestratorMachineState => {
    const { onEnterStateMap, onLeaveStateMap } = state;

    const prevMachineState = state.activeState;
    const newMachineState = get(ORCHESTRATOR_TRANSITIONS, [prevMachineState, action.event], '');

    if (!newMachineState) {
        return state;
    }

    metric.count(
        `${ORCHESTRATOR_MACHINE_METRIC_PREFIX}.transition.${prevMachineState}.${action.event}.${newMachineState}`
    );

    if (onLeaveStateMap[prevMachineState]) {
        onLeaveStateMap?.[prevMachineState]?.(newMachineState, state.context);
    }

    if (onEnterStateMap[newMachineState]) {
        onEnterStateMap?.[newMachineState]?.(prevMachineState, state.context);
    }

    return {
        ...state,
        activeState: newMachineState,
    };
};
