import { GoSearcherBusinessRoute, SearchPerseusConfig } from '@fiverr-private/listing_types';
import { validateCategoryIds } from '../../util';
import { GigFlow } from './GigFlow';

export class GigBusinessCategoriesFlow extends GigFlow {
    timeout?: number;
    metricRouteKey: string;
    servicePath: string;

    route: GoSearcherBusinessRoute = 'fiverr_business/categories';
    routeName = 'fiverr_business_categories';
    validateRequest = validateCategoryIds;

    constructor(serviceConfigs: SearchPerseusConfig) {
        super('gigBusinessCategoriesFlow', serviceConfigs);

        const { search_service: searchService } = serviceConfigs;
        const { timeout, name, url } = searchService;

        if (timeout?.[this.routeName]) {
            this.timeout = timeout[this.routeName];
        }

        this.metricRouteKey = `${name}.${this.routeName}`;
        this.servicePath = `${url}/v5/${this.route}`;
    }
}
