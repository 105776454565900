export const PROJECT_PARTNER_IMAGE =
    'https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto/v1/attachments/generic_asset/asset/1d590183275d6c68814d0e025c6ffc51-1713177675270/136443297.png';

export const CUSTOM_TITLE_CATEGORIES = {
    10: 'programming_tech', // programming-tech
    2: 'online_marketing', // online-marketing
    3: 'graphics_design', // graphics-design
    20: 'video_animation', // video-animation
};

export const CUSTOM_HREF_CATEGORIES = {
    10: 'tech-project-management', // programming-tech
};

export const CUSTOM_CATEGORIES_SOURCE = {
    10: ['full_listing_p&t', 'pro_listing_p&t'], // programming-tech
    2: ['full_listing_dm', 'pro_listing_dm'], // online-marketing
    3: ['full_listing_g&d', 'pro_listing_g&d'], // graphics-design
    20: ['full_listing_v&a', 'pro_listing_v&a'], // video-animation
};
