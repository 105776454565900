import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isArray } from 'lodash';
import { I18n } from '@fiverr-private/i18n-react';
import { Button } from '@fiverr-private/button';
import { AppContext } from '../../../../context/listingsContext';
import { MENU_CONTENT_TYPES } from '../consts';
import { CONTENT_MAPPING } from './index';

const MAX_VISIBLE_OPTIONS = 4;

class MoreFiltersList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            toggleMap: {},
            selected: props.items.reduce(
                (acc, item) => ({
                    ...acc,
                    ...(isArray(item.options) && {
                        [item.id]: item.options.filter((option) => option.selected).map((item) => item.id),
                    }),
                }),
                {}
            ),
        };

        this.getValues = this.getValues.bind(this);
        this.syncValues = this.syncValues.bind(this);
        this.getRef = this.getRef.bind(this);
        this.initRefMap = this.initRefMap.bind(this);
        this.collapseExpandItem = this.collapseExpandItem.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.onClear = this.onClear.bind(this);

        this.initRefMap();
    }

    static contextType = AppContext;

    initRefMap() {
        const { items } = this.props;
        this.refMap = {};
        items.map((item) => (this.refMap[item.id] = React.createRef()));
    }

    getRef(id) {
        if (this.refMap[id]) {
            return this.refMap[id];
        }

        this.refMap[id] = React.createRef();
        return this.refMap[id];
    }

    collapseExpandItem(id) {
        const { toggleMap } = this.state;
        const collapsed = !!toggleMap[id];

        this.setState({
            toggleMap: { ...toggleMap, [id]: !collapsed },
        });
    }

    getValues() {
        const { items } = this.props;

        const activeRefs = items.map((item) => item.id);
        return activeRefs.reduce(
            (total, refKey) => ({
                ...total,
                ...this.getRef(refKey).current.getValues(),
            }),
            {}
        );
    }

    syncValues() {
        const { items } = this.props;

        const activeRefs = items.map((item) => item.id);
        activeRefs.forEach((key) => {
            this.getRef(key).current.syncValues();
        });
    }

    onSelect({ id, item, checked }) {
        this.setState((prevState) => ({
            selected: {
                ...prevState.selected,
                [id]: checked
                    ? [...prevState.selected[id], item.id]
                    : prevState.selected[id].filter((value) => value !== item.id),
            },
        }));
    }

    onClear() {
        const { items } = this.props;

        this.setState({
            selected: items.reduce(
                (acc, item) => ({
                    ...acc,
                    [item.id]: [],
                }),
                {}
            ),
        });
    }

    render() {
        const { items } = this.props;
        const { toggleMap, selected } = this.state;

        return (
            <div>
                {items.map((item) => {
                    const FilterComponent =
                        CONTENT_MAPPING[item.component] || CONTENT_MAPPING[MENU_CONTENT_TYPES.CHECKBOX_LIST];
                    const isExpanded = toggleMap[item.id];

                    const maxVisibleOptions = item.maxVisibleOptions ?? MAX_VISIBLE_OPTIONS;
                    const hiddenOptions = item.options.length - maxVisibleOptions;

                    const shouldShowMoreLess = hiddenOptions > 0;

                    return (
                        <div
                            key={item.id}
                            className={classNames('more-filter-item with-carrot', { expanded: isExpanded })}
                        >
                            <div className={'content-title'}> {item.alias} </div>
                            <FilterComponent
                                ref={this.getRef(item.id)}
                                id={item.id}
                                items={item.options}
                                selected={selected[item.id]}
                                onSelect={this.onSelect}
                                onClear={this.onClear}
                                visibleCheckboxListItems={maxVisibleOptions}
                                isExpanded={isExpanded}
                            />
                            {shouldShowMoreLess && (
                                <Button
                                    variant="ghost"
                                    onClick={() => this.collapseExpandItem(item.id)}
                                    className="show-more-less tbody-6"
                                >
                                    {isExpanded ? (
                                        <I18n k="search_perseus.general.less" />
                                    ) : (
                                        <I18n k="search_perseus.general.more" params={{ count: hiddenOptions }} />
                                    )}
                                </Button>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    }
}

MoreFiltersList.propTypes = {
    items: PropTypes.array,
};

export default MoreFiltersList;
