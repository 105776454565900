import { isEmpty } from 'lodash';
import { ListingAttributes } from '@fiverr-private/listing_types';

export const abTestsToQueryString = (abTests: ListingAttributes['abTests']) => {
    if (isEmpty(abTests)) {
        return null;
    }

    return Object.entries(abTests)
        .map(([key, value]) => `${key}:${value}`)
        .join(',');
};
