import { mapKeys, camelCase } from 'lodash';
import { getContext } from '@fiverr-private/fiverr_context';
import { ACTIVE_FILTER_SEPARATORS, ASSET_SIZES, PLACEHOLDER } from '../constants';

export const convertAssetThumbnail = (image = '', isTouch) => {
    const assetSize = isTouch ? ASSET_SIZES.MOBILE : ASSET_SIZES.DESKTOP;
    return image.replace(PLACEHOLDER, assetSize);
};

export const parseActiveFilters = () => {
    const { queryParameters: { ref = '' } = {} } = getContext();
    const parsedActiveFilters = {};

    const filters = ref.split(ACTIVE_FILTER_SEPARATORS.PARAMS);
    filters.forEach((filter) => {
        const [filterName, filterValue] = filter.split(ACTIVE_FILTER_SEPARATORS.PARAM_VALUE);
        parsedActiveFilters[filterName] = filterValue;
    });

    return parsedActiveFilters;
};

/** @param {Array | Record} item */
export const transformToCamelCase = (item) =>
    Array.isArray(item) ? item.map(transformToCamelCase) : mapKeys(item, (v, k) => camelCase(k));
