import { FlowName } from '@fiverr-private/listing_types';
import {
    GigBusinessCategoriesFlow,
    GigBusinessSearchFlow,
    GigCategoriesFlow,
    GigSearchFlow,
    GigProfessionSearchFlow,
} from './GigFlows';
import { RoleSearchFlow } from './RoleFlows/RoleSearchFlow';

type FlowType =
    | typeof GigCategoriesFlow
    | typeof GigSearchFlow
    | typeof GigBusinessCategoriesFlow
    | typeof GigBusinessSearchFlow
    | typeof RoleSearchFlow
    | typeof GigProfessionSearchFlow;

type FlowMapper = {
    [key in FlowName]: FlowType;
};

export const flowMapper: FlowMapper = {
    gigSearchFlow: GigSearchFlow,
    gigCategoriesFlow: GigCategoriesFlow,
    gigBusinessSearchFlow: GigBusinessSearchFlow,
    gigBusinessCategoriesFlow: GigBusinessCategoriesFlow,
    roleSearchFlow: RoleSearchFlow, // TODO: Implement RoleSearchFlow
    gigProfessionSearchFlow: GigProfessionSearchFlow,
};
