import React from 'react';

export const NoResultsClearFiltersIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="320" height="214" fill="none">
        <path
            stroke="#222325"
            strokeMiterlimit="10"
            strokeWidth="1.5"
            d="M165.441 133.372c3.916 0 7.091-3.194 7.091-7.134 0-3.94-3.175-7.134-7.091-7.134-3.916 0-7.09 3.194-7.09 7.134 0 3.94 3.174 7.134 7.09 7.134ZM127.81 1.622H87.142V42.54h40.668V1.622ZM182.798 1.622H142.13V42.54h40.668V1.622ZM237.778 1.622H197.11V42.54h40.668V1.622Z"
        />
        <path
            fill="#1DBF73"
            d="M162.464 31.544c5.194 0 9.405-4.237 9.405-9.463 0-5.226-4.211-9.463-9.405-9.463-5.194 0-9.405 4.237-9.405 9.463 0 5.226 4.211 9.463 9.405 9.463Z"
        />
        <path
            stroke="#222325"
            strokeMiterlimit="10"
            strokeWidth="1.5"
            d="M216.246 1.622c-.163 4.74 2.07 7.757 3.814 11.373 1.744 3.617-.236 7.036-4.89 9.996-4.653 2.96-4.368 8.07-1.63 11.103 2.739 3.034.318 8.446.318 8.446"
        />
        <path
            fill="#222325"
            d="M87.142 35.538c1.14-5.117 4.156-10.233 10.831-11.586 6.675-1.354 12.788 4.1 16.52 0 3.733-4.1.473-8.471 4.523-12.71a7.745 7.745 0 0 1 4.092-2.375 7.706 7.706 0 0 1 4.702.456V42.54H87.142v-7.003Z"
        />
        <path
            stroke="#222325"
            strokeMiterlimit="10"
            strokeWidth="1.5"
            d="M282.652 197.537H145.235M131.894 197.537h-13.668M60.084 99.358V90.6a15.71 15.71 0 0 1 4.56-11.074 15.519 15.519 0 0 1 11.006-4.587h168.241c4.128 0 8.088 1.65 11.007 4.587a15.712 15.712 0 0 1 4.559 11.075v8.84"
        />
        <path
            fill="#222325"
            d="M94.24 107.386a3.188 3.188 0 0 0 3.179-3.198 3.188 3.188 0 0 0-3.178-3.198 3.188 3.188 0 0 0-3.179 3.198 3.188 3.188 0 0 0 3.178 3.198ZM126.963 107.386a3.188 3.188 0 0 0 3.178-3.198 3.188 3.188 0 0 0-3.178-3.198 3.188 3.188 0 0 0-3.179 3.198 3.188 3.188 0 0 0 3.179 3.198ZM223.491 107.386a3.188 3.188 0 0 0 3.179-3.198 3.188 3.188 0 0 0-3.179-3.198 3.188 3.188 0 0 0-3.178 3.198 3.188 3.188 0 0 0 3.178 3.198Z"
        />
        <path
            stroke="#222325"
            strokeMiterlimit="10"
            strokeWidth="1.5"
            d="M150.304 168.361h94.614c.334 0 .668 0 .994-.058a9.353 9.353 0 0 0 5.646-1.855 9.45 9.45 0 0 0 3.409-4.893l14.621-50.152a9.5 9.5 0 0 0-.78-7.152 9.404 9.404 0 0 0-5.561-4.524 9.346 9.346 0 0 0-7.114.787 9.45 9.45 0 0 0-4.5 5.6l-12.657 43.46h-86.218M102.537 149.542H81.983l-12.657-43.46a9.38 9.38 0 0 0-4.43-5.844 9.312 9.312 0 0 0-7.257-.852 9.336 9.336 0 0 0-3.296 1.751 9.407 9.407 0 0 0-2.354 2.908 9.455 9.455 0 0 0-.593 7.326l14.613 50.151a9.403 9.403 0 0 0 2.005 3.616 9.327 9.327 0 0 0 3.358 2.395c1.17.533 2.44.812 3.724.82h58.289"
        />
        <path fill="#222325" d="M245.399 197.537h-8.444l-13.464-29.176h12.772l9.136 29.176Z" />
        <path
            fill="#222325"
            stroke="#222325"
            strokeMiterlimit="10"
            strokeWidth="2"
            d="m99.636 123.048 9.535 30.512 10.115 28.462a20.343 20.343 0 0 1 1.003 9.544 20.288 20.288 0 0 1-3.483 8.935 20.13 20.13 0 0 1-7.185 6.313 20.002 20.002 0 0 1-9.268 2.268H45.129v-17.138l34.971-4.625-6.324-31.299a26.18 26.18 0 0 1 2.839-18.011A25.926 25.926 0 0 1 90.5 126.287l9.136-3.239Z"
        />
        <path
            stroke="#222325"
            strokeMiterlimit="10"
            strokeWidth="1.5"
            d="M99.636 123.048c5.322-1.96 29.658-8.151 29.658-8.151l11.793-17.22a24.66 24.66 0 0 1 14.453-10.04 24.527 24.527 0 0 1 17.429 2.184s7.335 3.789 9.707 5.183c3.635 2.14 12.119 9.7 12.755 10.66.636.959.049 2.132-2.282 1.066a39.581 39.581 0 0 1-5.078-2.985M108.43 152.97l25.949 1.459-2.331 44.707s-8.508 5.625-9.217 7.585c-.359 1 .285 2.124 3.26.467"
        />
        <path
            stroke="#222325"
            strokeMiterlimit="10"
            strokeWidth="1.5"
            d="m186.45 97.505 1.369-2.108 45.159 29.807a17.391 17.391 0 0 1 4.823 4.769 17.504 17.504 0 0 1 2.699 13.065 17.49 17.49 0 0 1-2.534 6.307l-54.418-35.916"
        />
        <path
            fill="#222325"
            d="M237.64 149.542h-84.622v-18.663h8.101l2.266 1.443 3.374 1.05 3.056-.894 2.071-1.599h38.101l15.729 10.381 11.924 8.282Z"
        />
        <path
            fill="#222325"
            d="M161.497 131.797c4.156-.82 11.051-6.355 10.285-8.749a6.29 6.29 0 0 0-3.022-3.387 6.229 6.229 0 0 0-4.5-.459c-3.57 1.05-6.936 4.723-5.917 7.725a12.377 12.377 0 0 0 3.154 4.87Z"
        />
        <path
            stroke="#222325"
            strokeMiterlimit="10"
            strokeWidth="1.5"
            d="M171.421 89.034 166.066 103M132.929 181.866H148.3M45.944 193.461c-2.446-.164-5.877-1.64-9.055.558-3.179 2.197-2.225 6.953-6.439 8.2-4.213 1.246-15.159 1.599-17.058 4.149-1.899 2.55 1.256 4.477 9.275 4.1 8.02-.377 23.276-1.845 23.276-1.845"
        />
        <path
            stroke="#fff"
            strokeMiterlimit="10"
            strokeWidth="1.5"
            d="M110.125 149.542s-8.28 31.021-11.019 42.009c-2.738 10.988-11.81 18.966-22.991 18.966"
        />
        <path
            stroke="#222325"
            strokeMiterlimit="10"
            strokeWidth="1.5"
            d="M132.285 202.645s-9.658 6.412-9.78 8.437c-.098 1.321 1.393 1.714 2.746.878 1.353-.837 3.676-2.411 3.676-2.411"
        />
        <path
            stroke="#222325"
            strokeMiterlimit="10"
            strokeWidth="1.5"
            d="M185.953 106.639s6.463 4.797 7.685 5.97c1.223 1.173 1.051 2.821-.905 2.337s-10.643-6.388-10.643-6.388l-.058.099c.496 1.22.92 2.469 1.272 3.739.212 1.172 1.328 4.149-.628 3.673-1.956-.475-6.74-10.069-6.74-10.069l-16.528-4.978s-7.335 10.283-7.131 12.202c.962 8.741 1.059 33.989 0 43.05-1.06 9.061-7.482 45.215-7.482 45.215s7.27 2.001 7.466 3.436c.163 1.14-.75 2-4.165 1.32l-5.485-1.083s-11.948 7.422-13.855 7.807c-1.744.361-2.062-1.41-1.027-2.46s8.859-6.56 8.859-6.56"
        />
        <path
            stroke="#222325"
            strokeMiterlimit="10"
            strokeWidth="1.5"
            d="M188.072 103.704a53.613 53.613 0 0 1 5.493 4.002c2.844 2.353 4.303 4.206 3.406 5.117-.896.91-4.556-1.87-6.813-3.01"
        />
    </svg>
);
