import { getContext } from '@fiverr-private/fiverr_context';
import { bigQuery } from '@fiverr-private/obs';

export const sendBigQueryReport = (type: string) => {
    const { pageCtxId, userGuid, abTests = {} } = getContext();

    const bigQueryPayload = {
        type,
        page: {
            ctx_id: pageCtxId,
        },
        uid: userGuid,
        ab_test: Object.entries(abTests).map(([experiment, group]) => ({
            experiment,
            group,
        })),
    };

    bigQuery.send(bigQueryPayload);
};
