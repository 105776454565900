import React from 'react';
import PropTypes from 'prop-types';
import { Lightbox, LightboxHeader, LightboxArrows, LightboxContent, LightboxLabel } from '@fiverr-private/overlay';

export const GigServiceCardLightbox = ({ sources, sourceInitialIndex, onClose, onScroll }) => (
    <Lightbox
        onClose={onClose}
        sources={sources}
        onClickPrev={() => onScroll('previous')}
        onClickNext={() => onScroll('next')}
        sourceInitialIndex={sourceInitialIndex}
    >
        <LightboxHeader />
        <LightboxArrows />
        <LightboxContent />
        <LightboxLabel />
    </Lightbox>
);

GigServiceCardLightbox.propTypes = {
    sources: PropTypes.arrayOf(PropTypes.object),
    sourceInitialIndex: PropTypes.string,
    onClose: PropTypes.func,
    onScroll: PropTypes.func,
};
