import { array, bool, object, oneOf, shape, string, number, arrayOf } from 'prop-types';
import { FLOWS } from '../utils/constants';

const AppFiltersType = shape({
    subCategory: object.isRequired,
    selected: array,
    filters: array,
});

const GigCardListingsPropTypes = arrayOf(
    shape({
        gigs: array,
        choiceModalities: object,
        recommendations: object,
        showListViewCards: bool,
        isTouch: bool,
        impressionEnrichment: object.isRequired,
        context: object.isRequired,
    })
);

const PaginationProps = shape({
    page: number.isRequired,
    page_size: number.isRequired,
    total: number.isRequired,
});

const AppDataPropTypes = shape({
    filter: AppFiltersType,
    pagination: PaginationProps,
    flow: oneOf([FLOWS.SEARCH, FLOWS.CATEGORY, FLOWS.BUSINESS_SEARCH, FLOWS.BUSINESS_CATEGORIES]),
});

const LogoMakerConfigProp = shape({
    banner: shape({
        showBannerInSubcategory: bool,
        showBannerInSearch: bool,
        shouldEnrich: bool,
    }),
    listings: shape({
        showListingsOnSubCategory: bool,
        showListingsOnSearch: bool,
        projects: arrayOf(object),
        hasNextPage: bool,
    }),
});

const BaseListingsPerseusPropTypes = {
    listings: GigCardListingsPropTypes,
    displayData: object.isRequired,
    requestContext: object,
    topBarSorting: array.isRequired,
    appData: AppDataPropTypes,
    logoMakerConfig: LogoMakerConfigProp,
};

const ListingsPerseusPropTypes = BaseListingsPerseusPropTypes;

const SubCategoriesPropTypes = {
    appFilters: AppFiltersType,
    ...BaseListingsPerseusPropTypes,
};

const SearchPropTypes = {
    appFilters: AppFiltersType,
    ...BaseListingsPerseusPropTypes,
};

const BusinessListingPropTypes = {
    appFilters: AppFiltersType,
    ...BaseListingsPerseusPropTypes,
};

const dataLayerObjectTypes = {
    categorySlug: string,
    subCategorySlug: string,
    perseus_name: string,
    app_base_path: string,
    flow: string,
    controller: string,
    action: string,
    nestedSubCategorySlug: string,
};

const ClientListingsPropTypes = {
    listings: GigCardListingsPropTypes,
    displayData: object.isRequired,
    topBarSorting: array.isRequired,
    tracking: object.isRequired,
    requestContext: object.isRequired,
    dataLayerObject: shape(dataLayerObjectTypes),
    loyaltyData: object,
};

const PaginationPropTypes = {
    pagination: PaginationProps,
};

const RecentlyViewedTypes = {
    loggedIn: bool,
    activeFilters: object,
    sorting: object,
};

const RecommendationsCarouselsTypes = {
    ...RecentlyViewedTypes,
};

const RelatedSearchTypes = {
    displayData: shape({
        searchComponentData: shape({
            modifiedQueryString: string,
            query: string.isRequired,
        }),
    }),
};

const SearchResultsCounterTypes = {
    displayData: shape({
        searchComponentData: shape({
            modifiedQueryString: string,
            searchInsteadUrl: string,
            query: string.isRequired,
        }),
    }),
};

const pairSelectTypes = {
    options: shape({
        fromOptions: array,
        toOptions: array,
    }),
};

const filterType = {
    id: string,
    options: shape({
        selected: bool,
    }),
};

const searchTermTag = {
    query: string,
    termurl: string,
    position: string,
};

const SemanticSearchTypes = {
    searchInsteadUrl: string,
};

export {
    ClientListingsPropTypes,
    ListingsPerseusPropTypes,
    SubCategoriesPropTypes,
    SearchPropTypes,
    BusinessListingPropTypes,
    PaginationPropTypes,
    RecentlyViewedTypes,
    RecommendationsCarouselsTypes,
    RelatedSearchTypes,
    SearchResultsCounterTypes,
    pairSelectTypes,
    filterType,
    searchTermTag,
    SemanticSearchTypes,
};
