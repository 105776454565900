import { URI } from '@fiverr-private/futile';
import { getContext } from '@fiverr-private/fiverr_context';
import { CATEGORY_TYPE } from '@fiverr-private/listing_lib';
import imageMapper from '../tiles/leafCategoryTile/imageMapper';
import { getNscImagePath } from '../tiles/nsc_tile/utils';
import { getLeafCategoryById } from '../../generic_listings/TagFilters/utils';
import { BI_SOURCES } from '../../../utils/constants';

export const getUrl = (leafCategory) => {
    const { url = '' } = getContext();

    const uri = new URI(url);

    uri.addParams({
        [CATEGORY_TYPE.SUB_CATEGORY]: leafCategory.params.sub_category,
        ...(leafCategory.params.nested_sub_category && {
            [CATEGORY_TYPE.NESTED_SUB_CATEGORY]: leafCategory.params.nested_sub_category,
        }),
        source: BI_SOURCES.VISUAL_FILTERS,
    });

    return uri.href;
};

export const getLeafCategoryTilesData = (significantLeafCategories, leafCategories) => {
    const leafCategoryTiles = [];
    significantLeafCategories.forEach((significantLeafCategory) => {
        const leafCategory = getLeafCategoryById(leafCategories, significantLeafCategory);

        if (leafCategory) {
            const isNested = leafCategory.type === CATEGORY_TYPE.NESTED_SUB_CATEGORY;
            const {
                params: { nested_sub_category, sub_category },
            } = leafCategory;

            const url = getUrl(leafCategory);
            const id = isNested ? nested_sub_category : sub_category;
            const src = isNested ? getNscImagePath(id) : imageMapper[id];
            src && leafCategoryTiles.push({ id, alias: leafCategory.alias, url, src, count: leafCategory.count });
        }
    });

    return leafCategoryTiles;
};
