import {
    GigFlowName,
    GoSearcherRoute,
    ListingAttributes,
    RequestMethod,
    SearchPerseusConfig,
} from '@fiverr-private/listing_types';
import { buildGoSearcherQueryParams } from '../../url';
import { BasicFlow } from '../BasicFlow';
import { fetchGigsDataFromPhoenix, FetchGigsDataFromPhoenixResult } from '../../dataEnrichmentPromises';
import { GIG_FLOW_PAGE_SIZE } from '../../constants';

export abstract class GigFlow extends BasicFlow {
    abstract override timeout?: number;
    abstract override metricRouteKey: string;
    abstract override servicePath: string;
    abstract route: GoSearcherRoute;
    serviceQueryParams: (listingAttributes: ListingAttributes) => string;
    override pageSize: number;
    fetchGigsData: () => Promise<FetchGigsDataFromPhoenixResult>;

    protected constructor(gigFlowName: GigFlowName, serviceConfigs: SearchPerseusConfig) {
        super(gigFlowName);

        this.serviceQueryParams = buildGoSearcherQueryParams;
        this.pageSize = GIG_FLOW_PAGE_SIZE;
        this.fetchGigsData = () => fetchGigsDataFromPhoenix(serviceConfigs);
    }

    method: RequestMethod = 'get';

    // TODO: Implement dataEnrichment and formatResult when building related middlewares
    formatResult = () => undefined;
    dataEnrichment = () => [];
}
