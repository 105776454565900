import React from 'react';
import PropTypes from 'prop-types';
import { ASSET_TYPES } from '../../shared/constants';
import { assetType, attachmentType } from '../../shared/types';
import { DeliveredAssetTag } from './DeliveredAssetTag';
import { AssetThumbnailImage } from './AssetThumbnailImage';
import { AssetThumbnailMedia } from './AssetThumbnailMedia';

import './index.scss';

export const AssetThumbnail = ({
    className,
    isParentHovered,
    isActive = true,
    asset,
    attachment,
    zoomOnHover,
    lazyLoad,
}) => {
    const isImage = asset.type === ASSET_TYPES.IMAGE;

    return (
        <div className="asset-thumbnail">
            {asset.isDelivery && <DeliveredAssetTag />}
            {isImage ? (
                <AssetThumbnailImage
                    className={className}
                    src={asset.cloudImgMainGig}
                    zoomOnHover={zoomOnHover}
                    lazyLoad={lazyLoad}
                />
            ) : (
                <AssetThumbnailMedia
                    className={className}
                    isParentHovered={isParentHovered}
                    isActive={isActive}
                    assetType={asset.type}
                    attachment={attachment}
                    lazyLoad={lazyLoad}
                />
            )}
        </div>
    );
};

AssetThumbnail.propTypes = {
    className: PropTypes.string,
    isParentHovered: PropTypes.bool,
    isActive: PropTypes.bool,
    asset: assetType.isRequired,
    attachment: attachmentType,
    zoomOnHover: PropTypes.bool,
    lazyLoad: PropTypes.bool,
};
