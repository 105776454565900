import PropTypes from 'prop-types';
import { ASSET_TYPES, DELIVERY_ATTACHMENT_TYPES } from '../../../shared/constants';

const urlType = PropTypes.string;

const assetType = PropTypes.shape({
    id: PropTypes.string,
    attachment_id: PropTypes.string,
    type: PropTypes.oneOf(Object.values(ASSET_TYPES)),
    cloud_img_main_gig: urlType,
});

const attachmentType = PropTypes.shape({
    id: PropTypes.string, // assetType['attachment_id']
    stream_url: urlType,
    image_url: urlType,
});

const filteredDeliveryAttachmentType = PropTypes.shape({
    id: PropTypes.string,
    stream_url: PropTypes.string,
    image_url: PropTypes.string,
    is_visible: PropTypes.bool,
    type: PropTypes.oneOf(Object.values(DELIVERY_ATTACHMENT_TYPES)),
});

export const gigType = PropTypes.shape({
    gig_id: PropTypes.number,
    category_id: PropTypes.number,
    sub_category_id: PropTypes.number,
    nested_sub_category_id: PropTypes.number,
    package_i: PropTypes.number,
    cached_slug: PropTypes.string,
    assets: PropTypes.arrayOf(assetType),
    attachments: PropTypes.arrayOf(attachmentType),
    buying_review_rating: PropTypes.number,
    buying_review_rating_count: PropTypes.number,
    filtered_delivery_attachments: PropTypes.arrayOf(filteredDeliveryAttachmentType),
});

export const biEnrichmentType = PropTypes.shape({
    domain: PropTypes.shape({
        name: PropTypes.string,
    }),
});

export const customOrderPropsType = PropTypes.shape({
    requestButton: PropTypes.element,
});
