import { raiseErrorToast } from '@fiverr-private/machine_translation';
import { logger } from '@fiverr-private/obs';
import { HttpStatusCode } from '../../../../../shared/types/httpStatusCode';

export const translationRequestSucceeded = (status) => {
    if (status !== HttpStatusCode.OK) {
        raiseErrorToast();
        logger.error(`Failed to translate UGC. Error code: ${status}`);
    }

    return status === HttpStatusCode.OK;
};
