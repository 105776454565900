import {
    FilterType,
    FilterCollectionType,
    FilterOptionType,
    ActiveSideFiltersType,
} from '@fiverr-private/listing_types';
import { EVENTS_MAPPING, FILTER_COLLECTION_IDS, tracker } from '@fiverr-private/listing_lib';
import { ALL_SUBCATEGORY } from '../../../../../../../lib/utils/side_filters/constants';
import { filterNavigation, shouldFilterNavigate } from '../../../../../service/clientNavigation';
import { BI_SOURCES } from '../../../../../utils/constants';
import { FILTERS_DRAWER_TRACKING_LOCATION } from '../FiltersDrawerContent/constants';
import { DISPLAY_TYPES } from '../../../../../config/filters';

export const applyFilters = (filterCollections: FilterCollectionType[], activeFilters: ActiveSideFiltersType) => {
    const newlyAppliedFilters = transformNewlyAppliedFilters(filterCollections);
    const selectedScOption = findSelectedSCOption(filterCollections);
    const filterKeysToGroups = mapFilterKeysToCollections(filterCollections);
    const activeLeafCategory = activeFilters?.leaf_category?.[0] || ALL_SUBCATEGORY;

    if (
        !shouldFilterNavigate(newlyAppliedFilters, activeFilters) &&
        activeLeafCategory === (selectedScOption?.id || ALL_SUBCATEGORY)
    ) {
        return;
    }

    delete activeFilters.leaf_category;

    if (selectedScOption) {
        handleSCFilterApply(newlyAppliedFilters, activeFilters, selectedScOption, filterKeysToGroups);
    } else {
        filterNavigation(newlyAppliedFilters, activeFilters, null, BI_SOURCES.DRAWER, null, {}, filterKeysToGroups);
    }
};

const mapFilterKeysToCollections = (filterCollections: FilterCollectionType[]) =>
    filterCollections.reduce(
        (prev, { filters, topGrouping }) => ({
            ...prev,
            ...filters.reduce((prev, { id: filterId }) => ({ ...prev, [filterId]: topGrouping }), {}),
        }),
        {}
    );

const transformNewlyAppliedFilters = (filterCollections: FilterCollectionType[]) => {
    const appliedFilters: Record<string, string | string[]> = filterCollections
        .filter(({ id }) => id !== FILTER_COLLECTION_IDS.SUB_CATEGORY)
        .flatMap(({ filters }) => filters)
        .reduce(
            (prev, filter) => ({
                ...prev,
                [filter.id]: transformAppliedFilter(filter),
            }),
            {}
        );

    // TODO to a config with separate util to handle languages pairs
    const languagesPairFrom = appliedFilters.languages_pair_from?.[0] ?? '*';
    const languagesPairTo = appliedFilters.languages_pair_to?.[0] ?? '*';

    delete appliedFilters.languages_pair_from;
    delete appliedFilters.languages_pair_to;
    const languagesPair = `${languagesPairFrom}-${languagesPairTo}`;

    if (languagesPair !== '*-*') {
        appliedFilters.languages_pair = [languagesPair];
    } else {
        appliedFilters.languages_pair = [];
    }

    return appliedFilters;
};
const handleSCFilterApply = (
    newlyAppliedFilters: ActiveSideFiltersType,
    activeFilters: ActiveSideFiltersType,
    selectedSCOption: FilterOptionType,
    filterKeysToGroups: Record<string, string>
) => {
    const { params, alias } = selectedSCOption;
    const { sub_category: subCategory, nested_sub_category: nestedSubCategory } = params || {};
    const leafCategory = nestedSubCategory || subCategory;

    const payload = {
        location: FILTERS_DRAWER_TRACKING_LOCATION,
        alias,
        id: leafCategory,
    };

    tracker.trackLeafCategoryFilter(EVENTS_MAPPING.FILTERS_APPLY, payload);
    filterNavigation(newlyAppliedFilters, activeFilters, null, BI_SOURCES.DRAWER, null, params, filterKeysToGroups);
};
const findSelectedSCOption = (filterCollections: FilterCollectionType[]) => {
    const scCollection = filterCollections.find(({ id }) => id === FILTER_COLLECTION_IDS.SUB_CATEGORY);
    const scFilter = scCollection?.filters[0] || { options: [] };
    const selectedScOption = scFilter.options.find(({ selected }) => selected);

    return selectedScOption;
};
const transformAppliedFilter = (filter: FilterType) => {
    const { options, display_type: displayType } = filter;

    if (displayType === DISPLAY_TYPES.INPUT_RANGE) {
        const [{ selected, max }] = options;

        if (selected) {
            if (!max) {
                return null;
            }

            return [0, max];
        }

        return null;
    }

    return options.filter(({ selected, id }) => selected && id).map(({ id }) => id);
};
