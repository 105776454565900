import { bigQuery } from '@fiverr-private/obs';
import { getContext } from '@fiverr-private/fiverr_context';
import { FlowName } from '@fiverr-private/listing_types';
import { isRolesListings } from '@fiverr-private/listing_lib';
import { LISTINGS_PAGE } from '../../../../constants';
import { BI_EVENT_GROUPS, BI_ACTION_TYPES } from '../../../../../../shared/services/BIEvents/constants';

const getListingType = (flowName: FlowName) => (isRolesListings(flowName) ? 'professions' : 'services');

export const reportBaseBqClickEvent = ({
    flowName,
    type,
    component,
}: {
    flowName: FlowName;
    type: string;
    component: { type: string; name: string; parent: { id: string } };
}) => {
    const { userId, pageCtxId } = getContext();
    const payload = {
        group: BI_EVENT_GROUPS.USER_ACTIONS,
        event_triggered_by: BI_ACTION_TYPES.CLICK,
        action: { type: BI_ACTION_TYPES.CLICK },
        type,
        listing_type: getListingType(flowName),
        user: { id: userId },
        page: {
            ctx_id: pageCtxId,
            element: {
                name: LISTINGS_PAGE,
            },
        },
        component,
    };

    bigQuery.send(payload);
};

export const reportBaseBqImpressionEvent = ({
    flowName,
    type,
    component,
}: {
    flowName: FlowName;
    type: string;
    component: { type: string; name: string; parent: { id: string } };
}) => {
    const { userId, pageCtxId } = getContext();
    const payload = {
        group: BI_EVENT_GROUPS.BANNER_IMPRESSION,
        event_triggered_by: BI_ACTION_TYPES.IMPRESSION,
        action: { type: BI_ACTION_TYPES.IMPRESSION },
        type,
        listing_type: getListingType(flowName),
        user: { id: userId },
        page: {
            ctx_id: pageCtxId,
            element: {
                name: LISTINGS_PAGE,
            },
        },
        component,
    };

    bigQuery.send(payload);
};

export const reportBqUpgradeClickEvent = ({ flowName }: { flowName: FlowName }) =>
    reportBaseBqClickEvent({
        flowName,
        type: 'clicked_on_hourly_entry_banner_Listings',
        component: {
            type: 'Button',
            name: 'hourly_entry_banner',
            parent: { id: 'listing_page' },
        },
    });

export const reportBqDismissClickEvent = ({ flowName }: { flowName: FlowName }) =>
    reportBaseBqClickEvent({
        flowName,
        type: 'clicked_on_hourly_entry_dismiss_Listings',
        component: {
            type: 'Button',
            name: 'hourly_entry_dismiss',
            parent: { id: 'listing_page' },
        },
    });

export const reportBqVideoClickEvent = ({ flowName }: { flowName: FlowName }) =>
    reportBaseBqClickEvent({
        flowName,
        type: 'clicked_on_hourly_entry_video_Listings',
        component: {
            type: 'Button',
            name: 'hourly_entry_video',
            parent: { id: 'listing_page' },
        },
    });

export const reportBqBannerImpressionEvent = ({ flowName }: { flowName: FlowName }) =>
    reportBaseBqImpressionEvent({
        flowName,
        type: 'viewed_hourly_entry_Listings',
        component: {
            type: 'Button',
            name: 'hourly_entry',
            parent: { id: 'listing_page' },
        },
    });
