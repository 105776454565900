import { UnableToSetListingsData } from './UnableToSetListingsData';

export class CannotEnrichItemsError extends UnableToSetListingsData {
    description: string;
    url: string;

    constructor(error: Error, url: string) {
        super(error);

        this.description = `Unable to fetch items data`;
        this.url = url;
    }
}
