import React, { PureComponent } from 'react';
import { string, func, array } from 'prop-types';
import EditableComboBox from '../../../../../../../shared/components/EditableSelect';
import { transformToComboboxOptions } from '../../utils';

import './styles.scss';

class AutoComplete extends PureComponent {
    constructor(props) {
        super(props);

        const { options = [], optionSort } = props;
        const { alias, id } = options.find((opt) => opt.selected) || {};

        this.state = {
            selectedOption: alias ? transformToComboboxOptions(alias, id) : '',
            transformedOptions: options.sort(optionSort).map(({ alias, id }) => transformToComboboxOptions(alias, id)),
        };

        this.handleSearchChange = this.handleSearchChange.bind(this);
    }

    handleSearchChange(selectedOption) {
        this.setState(
            {
                selectedOption,
            },
            () =>
                this.props.onChange({
                    [this.props.id]: selectedOption?.value,
                })
        );
    }

    render() {
        const { placeholder } = this.props;
        const { transformedOptions, selectedOption } = this.state;

        return (
            <EditableComboBox
                className="autocomplete-combo-box"
                value={selectedOption}
                onChange={(e) => this.handleSearchChange(e)}
                options={transformedOptions}
                placeholder={placeholder}
            />
        );
    }
}

AutoComplete.propTypes = {
    id: string,
    placeholder: string,
    options: array,
    onChange: func,
    optionSort: func,
};

AutoComplete.defaultProps = {
    options: [],
    optionSort: (a, b) => (a.alias > b.alias ? 1 : -1),
    isTouch: false,
};

export default AutoComplete;
