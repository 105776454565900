import { orderBy } from 'lodash';
import { FILTER } from '@fiverr-private/listing_lib';
import { filterNavigation } from '../../../service/clientNavigation';
import { transformToComboboxOptions } from '../filter/utils';
import { SOURCE, LOCATION, MOST_PUPULAR_LANGUAGES, INCLUDED_QUERIES } from './constants';

export const parseLanguageList = (locale, listItems) => {
    const groupedOptions = [
        {
            label: i18n.t('search_perseus.general.most_popular', { $scope: locale }),
            options: [],
        },
    ];

    const reorderedList = orderBy(listItems, ['alias']);

    reorderedList.forEach((listItem) => {
        const { id, alias } = listItem;
        const option = transformToComboboxOptions(alias, id);

        if (MOST_PUPULAR_LANGUAGES.includes(id)) {
            groupedOptions[0].options.splice(MOST_PUPULAR_LANGUAGES.indexOf(id), 0, option);
        } else {
            groupedOptions.push(option);
        }
    });
    return groupedOptions;
};

export const getInitialLanguage = (activeValue, languageList) => {
    if (!activeValue || activeValue === FILTER.LANGUAGES_PAIR.DEFAULT) {
        return '';
    }

    const languageOption = languageList.find(({ id }) => id === activeValue) || {};
    const { alias, id } = languageOption;
    return { label: alias, value: id };
};

export const isSelected = (value) => value && value !== FILTER.LANGUAGES_PAIR.DEFAULT;

export const applyLanguagePair = (fromLanguage, targetLanguage, activeFilters) => {
    if (!fromLanguage && !targetLanguage) {
        return;
    }

    const fromValue = fromLanguage ? fromLanguage.value : FILTER.LANGUAGES_PAIR.DEFAULT;
    const toValue = targetLanguage ? targetLanguage.value : FILTER.LANGUAGES_PAIR.DEFAULT;
    filterNavigation({ [FILTER.LANGUAGES_PAIR.ID]: [fromValue, toValue] }, activeFilters, LOCATION, SOURCE);
};

export const shouldShowExplicitLanguageFilter = ({ isTouch, subCategoryId, displayQuery }) =>
    !isTouch && (subCategoryId === FILTER.LANGUAGES_PAIR.SUB_CATEGORY_ID || INCLUDED_QUERIES.includes(displayQuery));
