export const ELEMENT_TYPES = {
    METADATA_FILTER: 'metadata',
    SLC_FILTER: 'slc',
    LEAF_CATEGORIES_FILTER: 'nsc',
    EXPOSED_METADATA_FILTERS: 'metadata_filters',
    LANGUAGE_PAIR: 'language_pair',
    VISUAL_FILTERS: 'visual_filters',
    PROFESSION_FILTERS: 'profession_filters',
};

export const EVENT_TYPE = 'exposed_filters_eligible_page';
export const EVENT_GROUP = 'technical_events';
export const ACTION_TYPE = 'no_action';
