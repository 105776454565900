import React from 'react';
import { getContext } from '@fiverr-private/fiverr_context';
import { I18n } from '@fiverr-private/i18n-react';
import { PinIcon, VideoIcon } from '@fiverr-private/icons';
import { grey_1200 } from '@fiverr-private/sass/helpers';
import CardActions from '../../CardActions';
import { useExpertCardContext } from '../../../../contexts';
import propTypes from '../types';
import JobTitle from '../JobTitle';
import SellerName from '../SellerName';
import { UserAvatar } from '../UserAvatar';
import { IconItem } from '../IconItem';
import { BadgeAndRating } from '../BadgeAndRating';

import '../index.scss';

/**
 * A component that describes all the basic seller details for non mobile device.
 */
export const DesktopSellerDetails = ({ isSellerOnline, isCardHovered, source, showJobTitle, onSellerDetailsClick }) => {
    const { locale } = getContext();
    const { seller, offerConsultation } = useExpertCardContext();
    const { jobTitle, countryCode, badges, sellerLevel } = seller;

    return (
        <div className="listings-seller-details-component desktop-device p-b-24">
            <UserAvatar source={source} containerClassName="small-avatar" onClick={onSellerDetailsClick} />
            <div className="seller-details-container">
                <div className="flex-center seller-responsive-grid-row divided-seller-details">
                    <div className="online-circle-and-seller-name-container flex flex-row">
                        {isSellerOnline && <div className="m-r-12 online-circle" />}
                        <SellerName
                            isCardHovered={isCardHovered}
                            source={source}
                            onClick={onSellerDetailsClick}
                            sellerLevel={sellerLevel}
                            badges={badges}
                        />
                    </div>
                    <BadgeAndRating />
                </div>
                {showJobTitle && (
                    <div className="m-b-4 seller-responsive-grid-row">
                        <JobTitle jobTitle={jobTitle} />
                    </div>
                )}
                <div className="flex flex-items-center locale-section">
                    {countryCode && (
                        <IconItem
                            iconColor={grey_1200}
                            Icon={PinIcon}
                            text={<I18n k={`countries.${countryCode}`} locale={locale} />}
                        />
                    )}
                    <div className="locale-section-divider" />
                    {offerConsultation && (
                        <IconItem
                            iconColor={grey_1200}
                            Icon={VideoIcon}
                            text={<I18n k="listings.expert_card.seller_details.offers_consultation" />}
                        />
                    )}
                </div>
            </div>
            <CardActions source={source} />
        </div>
    );
};

DesktopSellerDetails.propTypes = propTypes;
