import { MachineState, OnLeaveStateEffect, OrchestratorMachineState } from '../../types';

export const setOnLeaveState = (
    state: OrchestratorMachineState,
    machineState: MachineState,
    callback: OnLeaveStateEffect
): OrchestratorMachineState => ({
    ...state,
    onLeaveStateMap: {
        ...state.onLeaveStateMap,
        [machineState]: callback,
    },
});
