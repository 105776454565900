import React, { createContext, useContext, useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RawCollection } from '../types';

export interface CollectionsContext {
    hasProvider?: boolean;
    bi: {
        source: string;
        componentName?: string;
    };
    initialCollections?: RawCollection[];
    triggerData?: {
        triggerType?: string;
        triggerCopy?: string;
        triggerPlacement?: string;
    };
}

const CollectionsContext = createContext<CollectionsContext>({
    hasProvider: false,
    bi: {
        source: '',
    },
    triggerData: {
        triggerType: '',
        triggerPlacement: '',
    },
});

interface CollectionsProviderProps extends Omit<CollectionsContext, 'hasProvider'> {
    children: React.ReactNode;
}

export const QUERY_CLIENT_KEY = 'collect_actions_query_client';

export const CollectionsProvider = ({ children, bi, initialCollections, triggerData }: CollectionsProviderProps) => {
    const [queryClient] = useState(() => {
        if (typeof window === 'undefined') {
            return new QueryClient();
        }

        if (!window[QUERY_CLIENT_KEY]) {
            window[QUERY_CLIENT_KEY] = new QueryClient();
        }

        return window[QUERY_CLIENT_KEY];
    });

    return (
        <QueryClientProvider client={queryClient}>
            <CollectionsContext.Provider value={{ bi, initialCollections, hasProvider: true, triggerData }}>
                {children}
            </CollectionsContext.Provider>
        </QueryClientProvider>
    );
};

interface UseCollectionsContextProps {
    overrides?: Partial<Omit<CollectionsContext, 'hasProvider'>>;
}

export const useCollectionsContext = ({ overrides }: UseCollectionsContextProps = {}): CollectionsContext => {
    const contextValue = useContext<CollectionsContext>(CollectionsContext);

    return {
        ...contextValue,
        bi: {
            ...contextValue.bi,
            ...overrides?.bi,
        },
        triggerData: contextValue.triggerData,
        initialCollections: overrides?.initialCollections || contextValue.initialCollections,
    };
};

export interface CollectionsComponentProps {
    source?: string;
    bi?: CollectionsContext['bi'];
    initialCollections?: RawCollection[];
    triggerData?: {
        triggerType?: string;
        triggerCopy?: string;
        triggerPlacement?: string;
    };
}

/**
 * HoC which will automatically wrap in a CollectionsProvider if not available.
 */
export const withCollectionsProvider = <P extends CollectionsComponentProps>(
    WrappedComponent: React.ComponentType<P>
) => {
    const WithCollectionsProvider = (props: P) => {
        const collectionsContext = useCollectionsContext();

        if (!collectionsContext?.hasProvider) {
            const bi = {
                source: props.source || '',
                ...props.bi,
            };
            const initialCollections = props.initialCollections || collectionsContext.initialCollections;
            const triggerData = props.triggerData || {};

            return (
                <CollectionsProvider bi={bi} initialCollections={initialCollections} triggerData={triggerData}>
                    <WrappedComponent {...props} />
                </CollectionsProvider>
            );
        }

        return <WrappedComponent {...props} />;
    };

    WithCollectionsProvider.displayName = `withCollectionsProvider(${WrappedComponent.displayName || 'Component'})`;

    return WithCollectionsProvider;
};
