import React from 'react';
import { Container, Stack } from '@fiverr-private/layout_components';
import { Heading, Text } from '@fiverr-private/typography';
import { Button } from '@fiverr-private/button';
import { I18n } from '@fiverr-private/i18n-react';
import { ClientInitialProps } from '@fiverr-private/listing_types';
import { isProFilterActive } from '../../../utils/isProFilterActive/isProFilterActive';
import { NoResultsPro } from '../NoResultsPro/NoResultsPro';
import { useClearFiltersUrl } from './hooks/useClearFiltersUrl/useClearFiltersUrl';
import { NoResultsClearFiltersIcon } from './NoResultsClearFiltersIcon';

interface NoResultsClearFiltersProps {
    listingAttributes: ClientInitialProps['listingAttributes'];
    displayData: ClientInitialProps['displayData'];
    flowName: ClientInitialProps['flowName'];
}

export const NoResultsClearFilters = ({ listingAttributes, displayData, flowName }: NoResultsClearFiltersProps) => {
    const clearFiltersUrl = useClearFiltersUrl({ listingAttributes, flowName });

    if (isProFilterActive(listingAttributes.activeFilters)) {
        return (
            <Container marginLeft="2.5">
                <NoResultsPro displayData={displayData} />
            </Container>
        );
    }

    return (
        <Stack alignItems="center" direction="column" gap="10" margin="8">
            <Container width={{ default: '266px', sm: '319px' }}>
                <NoResultsClearFiltersIcon />
            </Container>
            <Stack alignItems="center" direction="column" gap="6">
                <Stack alignItems="center" direction="column" gap="2">
                    <Heading width={{ default: '100%', sm: '326px' }} as="h5" textAlign="center">
                        <I18n k="listing_results.empty_results.no_results_clear_filters.title" />
                    </Heading>
                    <Text width={{ default: '100%', sm: '380px' }} color="bodySecondary" textAlign="center">
                        <I18n k="listing_results.empty_results.no_results_clear_filters.description" />
                    </Text>
                </Stack>
                <Button href={clearFiltersUrl} target="_self" rel="noreferrer">
                    <I18n k="listing_results.empty_results.no_results_clear_filters.button" />
                </Button>
            </Stack>
        </Stack>
    );
};
