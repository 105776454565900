import { CategoryTree } from '@fiverr-private/listing_types';

export class InvalidListingError extends Error {
    constructor(message: string) {
        super(message);
    }
}

export class InvalidSearchQueryError extends InvalidListingError {
    query?: string;
    description: string;

    constructor(message: string, query?: string) {
        super(message);

        this.query = query;
        this.description = 'User searched an invalid query therefore Empty Result page displayed';
    }
}

export class InvalidCategoryTreeError extends InvalidListingError {
    categoryIds: Partial<CategoryTree>;
    description: string;

    constructor(message: string, categoryIds: Partial<CategoryTree>) {
        super(message);

        this.categoryIds = categoryIds;
        this.description = 'User browsed to an invalid query therefore Empty Result page displayed';
    }
}
