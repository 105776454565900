export const MAX_TILES_TO_SHOW = 7;
export const CHIP_MAX_TILES_TO_SHOW = 8;

export const SLIDE_PADDING = 12;
export const MOBILE_SLIDE_PADDING = 8;
export const CHIP_SLIDE_PADDING = 8;

export const MINIMUM_TILES_LENGTH = 2;

export const TILE_TYPES = {
    CHIP_TILE: 'chip-tile',
    RECTANGLE_TILE: 'rectangle-tile',
};
