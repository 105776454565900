import React from 'react';
import { getContext } from '@fiverr-private/fiverr_context';
import { I18n } from '@fiverr-private/i18n-react';
import { PinIcon, AgencyPeopleIcon, CalendarEventIcon } from '@fiverr-private/icons';
import { grey_1200 } from '@fiverr-private/sass/helpers';
import CardActions from '../../CardActions';
import { useExpertCardContext } from '../../../../contexts';
import propTypes from '../types';
import SellerName from '../SellerName';
import { UserAvatar } from '../UserAvatar';
import { EmployeesShortList } from '../../Employees';
import { IconItem } from '../IconItem';
import { BadgeAndRating } from '../BadgeAndRating';
import './index.scss';
import '../index.scss';

/**
 * A component that describes all the basic agency details for non Desktop device.
 */
export const AgencySellerDetails = ({ isCardHovered, source, onSellerDetailsClick }) => {
    const { locale } = getContext();
    const { seller } = useExpertCardContext();
    const {
        countryCode,
        agency: { established, members },
    } = seller;

    return (
        <div className="listings-seller-details-component desktop-device agency p-b-24">
            <UserAvatar source={source} containerClassName="small-avatar" onClick={onSellerDetailsClick} />
            <div className="seller-details-container">
                <div className="flex flex-items-center name-rating-section m-b-4">
                    <div className="flex flex-items-center name-badge-container divided-seller-details">
                        <SellerName isCardHovered={isCardHovered} source={source} onClick={onSellerDetailsClick} />
                        <BadgeAndRating />
                    </div>
                </div>
                <div className="flex flex-items-center agency-data">
                    <IconItem
                        containerClassName="no-shrink"
                        Icon={() => <AgencyPeopleIcon color={grey_1200} size={20} />}
                        text={<I18n k={`listings.expert_card.header.agency`} />}
                    />
                    <IconItem
                        containerClassName="established no-shrink"
                        Icon={CalendarEventIcon}
                        iconColor={grey_1200}
                        text={
                            <I18n
                                k={`listings.expert_card.header.established`}
                                params={{ establishedYear: established }}
                            />
                        }
                    />
                    {countryCode && (
                        <IconItem
                            containerClassName="established shrink"
                            iconColor={grey_1200}
                            Icon={PinIcon}
                            text={<I18n k={`countries.${countryCode}`} locale={locale} />}
                        />
                    )}
                </div>
                <EmployeesShortList employees={members} />
            </div>
            <CardActions source={source} />
        </div>
    );
};

AgencySellerDetails.propTypes = propTypes;
