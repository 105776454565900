import React from 'react';
import { ExposedFilter } from '@fiverr-private/listing_types';
import ExplicitLanguagePair from '../../../ExplicitLanguagePair';
import LeafCategoriesCarousel from '../../../LeafCategoriesCarousel';
import NscTilesCarousel from '../../../nsc_tiles_carousel';
import TopFiltersCarousel from '../../../top_filters_carousel';
import { MetadataFilterCarousel } from '../../MetadataFilterCarousel/MetadataFilterCarousel';
import { ProfessionFiltersCarousel } from '../../../ProfessionFiltersCarousel/ProfessionFiltersCarousel';
import { ExposedFilterPropsMapper, GeneralProps } from '../types';
import { explicitLanguagePairPropsMapper } from './explicitLanguagePairPropsMapper';
import { leafCategoriesPropsMapper } from './leafCategoriesPropsMapper';
import { nestedSubCategoriesMapper } from './nestedSubCategoriesMapper';
import { visualFiltersPropsMapper } from './visualFiltersPropsMapper';
import { metadataFiltersPropsMapper } from './metadataFiltersPropsMapper';
import { professionFiltersPropsMapper } from './professionFiltersPropsMapper';

export const EXPOSED_FILTER_KEY_TO_COMPONENT_MAPPER: Record<ExposedFilter, React.ComponentType<GeneralProps>> = {
    LEAF_CATEGORIES: LeafCategoriesCarousel,
    NESTED_SUB_CATEGORIES: NscTilesCarousel,
    VISUAL_FILTERS: TopFiltersCarousel,
    METADATA_FILTERS: MetadataFilterCarousel,
    EXPLICIT_LANGUAGE_PAIR: ExplicitLanguagePair,
    PROFESSION_FILTERS: ProfessionFiltersCarousel,
};

export const EXPOSED_FILTER_KEY_TO_PROPS_MAPPER: Record<ExposedFilter, ExposedFilterPropsMapper> = {
    LEAF_CATEGORIES: leafCategoriesPropsMapper,
    NESTED_SUB_CATEGORIES: nestedSubCategoriesMapper,
    VISUAL_FILTERS: visualFiltersPropsMapper,
    METADATA_FILTERS: metadataFiltersPropsMapper,
    EXPLICIT_LANGUAGE_PAIR: explicitLanguagePairPropsMapper,
    PROFESSION_FILTERS: professionFiltersPropsMapper,
};
