import { PERSONALIZED_CONTENT_BY_FUNNEL_ROUTE } from '../../components/subcategories/constants';
import { appName as APP_NAME } from '../../../../page/entries/listings/constants';
import { BI_PAGE_NAMES } from '../../../../shared/services/BIEvents/constants';
import { maxios } from '../../../../shared/utils/request';
import { ROUTE_KEYS } from '../../../../shared/utils/request/constants';

export const fetchPersonalizationContentByFunnel = async (funnelName, fetchParams) => {
    const { data = {} } = await maxios.post(
        PERSONALIZED_CONTENT_BY_FUNNEL_ROUTE,
        {
            funnelName,
            pageName: BI_PAGE_NAMES.LISTINGS,
            app_name: APP_NAME,
            perseus_name: APP_NAME,
            browse_history: [],
            context_filters: '',
            ...fetchParams,
        },
        { routeKey: ROUTE_KEYS.PERSONALIZED_CONTENT_BY_FUNNEL }
    );

    return data;
};
