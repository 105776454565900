import React, { useContext } from 'react';
import classnames from 'classnames';
import MachineTranslationButton from '@fiverr-private/machine_translation/src/components/MachineTranslationButton';
import { MT_CONTEXTS } from '@fiverr-private/machine_translation';
import { AppContext } from '../../context/listingsContext';

import './index.scss';

const MachineTranslationButtonWrapper = ({ gigsListings }) => {
    const appContext = useContext(AppContext);

    const { isTouch, showTranslatedUGC, loading, onTranslateBtnClick, sourceInfo, knownCrawler } = appContext;

    const classes = classnames('machine-translation-button-wrapper', {
        'layout-row is-touch': isTouch,
    });
    const hidePrefix = isTouch && !knownCrawler;
    const gigListingsSourceInfo = { ...sourceInfo, context: MT_CONTEXTS.GIG_TITLE };
    const gigListingsAppContext = { ...appContext, sourceInfo: gigListingsSourceInfo };

    return (
        <>
            <div className={classes}>
                <MachineTranslationButton
                    loading={loading}
                    toggled={showTranslatedUGC}
                    style={MachineTranslationButton.STYLES.LEAN_WITH_DISCLAIMER}
                    sourceInfo={gigListingsSourceInfo}
                    onClick={async () => await onTranslateBtnClick(gigsListings, gigListingsAppContext, hidePrefix)}
                />
            </div>
        </>
    );
};

export default MachineTranslationButtonWrapper;
