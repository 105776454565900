import { useContext, useRef } from 'react';
import { getContext } from '@fiverr-private/fiverr_context';
import { EXPERIMENTS } from '@fiverr-private/listing_experiments';
import { Gig } from '@fiverr-private/listing_types';
import { allocateToClientExperiment } from '../../clientExperiments/clientUtils';
import { AppContext } from '../../components/context/listingsContext';

export const useAllocateStarlingsBoostingNewGigsObserver = () => {
    const isAllocatedRef = useRef(false);
    const { abTests } = getContext();
    const { clientExperimentsGroups, gigsByImpressionIdMap: _gigsByImpressionIdMap } = useContext(AppContext);
    const gigsByImpressionIdMap: Map<string, Gig> = _gigsByImpressionIdMap;

    return (impressionData?: { imp_id: string }) => {
        if (isAllocatedRef.current) return;
        if (!impressionData) return;

        const experimentGroup = Number(clientExperimentsGroups[EXPERIMENTS.STARLINGS_BOOSTING_NEW_GIGS]);
        if (experimentGroup <= 0) return;
        if (abTests[EXPERIMENTS.STARLINGS_BOOSTING_NEW_GIGS]) return;

        const gig = gigsByImpressionIdMap.get(impressionData.imp_id);
        if (!gig) return;
        if (!gig.is_profession_related) return;

        allocateToClientExperiment(EXPERIMENTS.STARLINGS_BOOSTING_NEW_GIGS, clientExperimentsGroups);
        isAllocatedRef.current = true;
    };
};
