import axios from 'axios';
import { compact, findKey, isEmpty } from 'lodash';
import pathfinder from '@fiverr-private/pathfinder';
import { getContext } from '@fiverr-private/fiverr_context';
import { PATHFINDER } from '../../constants';
import { clickOnExpertCardEvent } from '../../utils/biEvents/events';
import { ELEMENT_NAMES, EXPERT_LISTINGS_SOURCE } from '../../utils/biEvents/constants';

const CLICKABLE_ITEMS = ['div', 'ul'];
const HOVERABLE_ITEMS = ['div', 'ul', 'img'];

export const fetchOnlineStatus = (username) => {
    const sellerOnlineUrl = pathfinder(PATHFINDER.SELLER_ONLINE, { username });
    return axios.get(sellerOnlineUrl).then((res) => {
        const { is_online: isOnline = false } = res.data;
        return isOnline;
    });
};

export const getFreelancerUrl = ({
    impressionId,
    seller,
    expertises = [],
    gigs = [],
    source,
    agencySlug,
    isExperiential,
    contextReferrer,
}) => {
    const { pageCtxId, queryParameters: { ref } = {} } = getContext();
    const { username, badges: { choice = false } = {} } = seller;

    const parsedExpertises = expertises
        .map(({ type, id, parentId = '' }) => `type:${type},id:${id},parentId:${parentId}`)
        .join('|');
    const parsedGigs = gigs
        .map(({ id, recommendedPackage = {} }) => {
            const extraFast = recommendedPackage.extraFast ? ',extra_fast:true' : '';

            return `id:${id},pckg_id:${recommendedPackage.id}${extraFast}`;
        })
        .join('|');

    const queryParams = {
        ref_ctx_id: pageCtxId,
        ...(expertises.length && { expertises: parsedExpertises }),
        ...(gigs.length && { gigs: parsedGigs }),
        ...(impressionId && { imp_id: impressionId }),
        ...(ref && { ref }),
        ...(choice && { is_choice: choice.toString() }),
        ...(source && { source }),
        ...(isExperiential && { is_experiential: isExperiential.toString() }),
        ...(contextReferrer && { context_referrer: contextReferrer }),
    };

    return agencySlug
        ? pathfinder('agency_page', { agency_slug: agencySlug }, { query: queryParams })
        : pathfinder('freelancer_page', { username }, { query: queryParams });
};

export const getOpenExpertPageLinkTarget = () => {
    const { experience } = getContext();
    return experience.isBusiness ? '_blank' : '_self';
};

export const handleCardHover =
    (ref, isHovered, setIsHovered) =>
    ({ target }) => {
        if (!ref.current) {
            return;
        }

        const { tagName } = target;

        if (ref.current.contains(target) && HOVERABLE_ITEMS.includes(tagName.toLowerCase())) {
            setIsHovered(isHovered);
        }
    };

export const handleExpertCardClick =
    ({ freelancerUrl, impressionId, ref, source, seller, componentName, listenOnAllElements, onClick }) =>
    (event) => {
        const { target } = event;
        if (!ref.current) {
            return;
        }

        const { tagName } = target;
        const isTargetClickable = ref.current.contains(target) && CLICKABLE_ITEMS.includes(tagName.toLowerCase());
        if (listenOnAllElements || isTargetClickable) {
            clickOnExpertCardEvent({
                pageName: source,
                elementName: ELEMENT_NAMES.EXPERT_CARD,
                impressionId,
                seller,
                componentName,
            });
            onClick ? onClick(event) : window.open(freelancerUrl, getOpenExpertPageLinkTarget()).focus();
        }
    };

export const getDisplayPrice = (gigs) => {
    const priceList = compact(gigs.map((gig) => gig?.recommendedPackage?.priceInDollars));
    return isEmpty(priceList) ? null : priceList[0];
};

export const getBiEnrichment = ({ seller, componentName }) => ({
    pageName: EXPERT_LISTINGS_SOURCE,
    componentName,
    params: {
        event_source: EXPERT_LISTINGS_SOURCE,
    },
    seller,
});

export const parseNotableClients = (notableClients) =>
    notableClients.map(({ enrichedDetails }) => {
        const { name, logo, industry, inFortune } = enrichedDetails;

        return {
            enriched_details: {
                name,
                logo,
                industry,
                in_fortune: inFortune,
            },
        };
    });

export const getSellerDisplayName = (seller) => seller.displayName || seller.username;

export const hasOfferConsultationGig = (gigs) => gigs.some((gig) => gig.offerConsultation);

export const hasHighlights = (highlights) => !!findKey(highlights, (highlight) => highlight);
