import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import MediaPlayer from '../../MediaPlayer';
import { attachmentType, mediaType } from '../../../shared/types';

import './index.scss';

export const AssetThumbnailMedia = ({ className, assetType, isParentHovered, isActive, attachment, lazyLoad }) => (
    <MediaPlayer
        className={classNames(className, 'asset-thumbnail-media')}
        assetType={assetType}
        isParentHovered={isParentHovered}
        isActive={isActive}
        attachment={attachment}
        lazyLoad={lazyLoad}
    />
);

AssetThumbnailMedia.propTypes = {
    className: PropTypes.string,
    assetType: mediaType,
    isParentHovered: PropTypes.bool,
    isActive: PropTypes.bool,
    attachment: attachmentType,
    lazyLoad: PropTypes.bool,
};
