import React from 'react';
import { number, string } from 'prop-types';
import classNames from 'classnames';
import { getContext } from '@fiverr-private/fiverr_context';
import { Icon } from '@fiverr-private/fit';
import { l_chevron_left } from '@fiverr-private/fit/icons';

const MIN_PAGE_TO_APPEAR = 3;

const FirstPageButton = ({ page, firstPageUrl, arrowsClassNames }) => {
    const { isTouch } = getContext();

    if (!isTouch || page < MIN_PAGE_TO_APPEAR) {
        return null;
    }

    return (
        <li className={classNames(arrowsClassNames, 'arrow-first-page')}>
            <a href={firstPageUrl}>
                <Icon className="pagination-icon">{l_chevron_left}</Icon>
                <Icon className="pagination-icon">{l_chevron_left}</Icon>
            </a>
        </li>
    );
};

export default FirstPageButton;

FirstPageButton.propTypes = {
    page: number,
    firstPageUrl: string,
    arrowsClassNames: string,
};
