import { MetadataFilterOptionType } from '@fiverr-private/listing_types';
import { VIEW_ALL_COMPONENT } from '../../../top_filters_carousel/constants';
import { MetadataFilterTileProps } from '../../MetadataFilterTile/MetadataFilterTile';
import { TILES_TO_SHOW } from '../constants';

export const buildMetadataTileProps = (
    options: MetadataFilterOptionType[],
    id: string,
    title: string
): MetadataFilterTileProps[] => [
    ...options.slice(0, TILES_TO_SHOW).map((option) => ({ ...option, filterId: id })),
    ...(options.length > TILES_TO_SHOW ? [{ id: VIEW_ALL_COMPONENT, filterTitle: title, options, filterId: id }] : []),
];
