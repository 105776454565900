import { MAX_ITEMS_TO_SHOW_NO_RESULTS } from '../../../../constants';
import relevantSearchTerms from './relevantSearchTerms.json';
import relevantLeafCategoryIds from './relevantLeafCategoryIds.json';

/**
 * The banner should appear in all leaf categories (SC/NCS levels)- In relevantLeafCategoryIds.json
 * The banner should appear in following search terms - In relevantSearchTerms.json
 * The banner should appear no matter which filters are applied, besides the budget filter when it should appear only if the budget is more than $1000+
 *
 * @param resultsCount
 * @param filters
 * @param isExpertListings
 * @param categoryIds
 * {
 *    nestedSubCategoryId: string;
 *    subCategoryId: string;
 *    categoryId: string;
 * }
 * @param searchTerm
 * @returns {boolean}
 */
export const shouldShowAIConsultationBanner = ({
    resultsCount,
    filters,
    isExpertListings,
    categoryIds,
    searchTerm,
}) => {
    if (!isExpertListings) {
        return false;
    }

    if (resultsCount <= MAX_ITEMS_TO_SHOW_NO_RESULTS) {
        return false;
    }

    if (filters?.budget && filters?.budget.min >= 1000) {
        return false;
    }

    if (searchTerm && relevantSearchTerms.includes(searchTerm)) {
        return true;
    }

    const { subCategoryId, nestedSubCategoryId } = categoryIds;

    if (
        relevantLeafCategoryIds.find(
            (leafCategoryId) =>
                leafCategoryId === nestedSubCategoryId || (leafCategoryId === subCategoryId && !nestedSubCategoryId)
        )
    ) {
        return true;
    }
};
