import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Switch } from '@fiverr-private/forms';
import { NewBadge } from '@fiverr-private/badges';
import Types from '../../types';
import { invokeApplyEvent, useSidebarFiltersContext, Triggers } from '../../Context';
import styles from './index.module.scss';

const ToggleFilter = ({ filter, text, activeFilters, disabled, isNew = false }) => {
    const { applyEvent, newActiveFilters } = useSidebarFiltersContext();

    const filterIsActive = !!newActiveFilters[filter.id];

    const toggleChanged = (toggleChecked) => {
        const activeFiltersToChange = { ...(newActiveFilters || activeFilters || {}) };

        if (toggleChecked) {
            activeFiltersToChange[filter.id] = toggleChecked;
        } else {
            activeFiltersToChange[filter.id] = null;
        }

        invokeApplyEvent(applyEvent, activeFiltersToChange, Triggers.FILTER_COMPONENT_CLICK);
    };

    return (
        <div className={classNames('p-l-16', 'p-r-16')}>
            <div className={classNames(styles.container, 'p-b-16', 'p-t-16')}>
                <span
                    className={classNames(
                        'text-semi-bold flex flex-items-center',
                        disabled ? 'co-text-medium' : 'co-text-darkest'
                    )}
                >
                    {text}
                    {isNew && (
                        <span className="m-l-8">
                            <NewBadge />
                        </span>
                    )}
                </span>
                <Switch
                    disabled={disabled}
                    checked={filterIsActive}
                    size="sm"
                    onChange={(e) => toggleChanged(e.target.checked)}
                />
            </div>
        </div>
    );
};

ToggleFilter.propTypes = {
    filter: Types.filter.isRequired,
    text: PropTypes.string.isRequired,
    activeFilters: Types.activeFilters,
    disabled: PropTypes.bool,
    isNew: PropTypes.bool,
};

export default ToggleFilter;
