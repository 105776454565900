import { isUndefined, isNull, omitBy, isEmpty } from 'lodash';
import { URI } from '@fiverr-private/futile';

export const updateURLQueryParams = (url: URL, queryParams: Record<string, string | undefined>) => {
    const uriResult = new URI(url.href);
    const existingsParamKeys = [...url.searchParams.keys()];

    if (existingsParamKeys.length > 0) {
        uriResult.removeParams(...existingsParamKeys);
    }

    const paramsToAdd = omitBy(queryParams, (value) => isUndefined(value) || isNull(value) || isEmpty(value));
    uriResult.addParams(paramsToAdd);

    return uriResult;
};
