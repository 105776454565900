const { INCLUDED_SC_NAVIGATION_TILES_TESTED_OUT } = require('./constants');

const isIdTestedOut = (id) => INCLUDED_SC_NAVIGATION_TILES_TESTED_OUT.includes(id);

const shouldShowVisualFilters = ({ isTouch, subCategoryId, nestedSubCategoryId }) =>
    !isTouch && (isIdTestedOut(subCategoryId) || isIdTestedOut(nestedSubCategoryId));

module.exports = {
    shouldShowVisualFilters,
    isIdTestedOut,
};
