import React from 'react';
import { string, oneOf } from 'prop-types';
import classNames from 'classnames';

import './index.scss';

const JobTitle = ({ size = 'lg', jobTitle, numRows = 1 }) => {
    const ellipsisClassName = numRows === 1 ? 'one-row-ellipsis' : 'two-rows-ellipsis';
    return (
        <p
            className={classNames('m-r-8', ellipsisClassName, 'seller-job-title', { 'job-title-small': size === 'sm' })}
            title={jobTitle}
        >
            {jobTitle}
        </p>
    );
};

JobTitle.propTypes = {
    size: oneOf(['sm', 'lg']),
    jobTitle: string.isRequired,
    numRows: oneOf([1, 2]),
};

export default JobTitle;
