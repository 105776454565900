import React, { Component } from 'react';
import classNames from 'classnames';
import { CheckIcon } from '@fiverr-private/visuals';
import { Box } from '@fiverr-private/theme';
import { Center } from '@fiverr-private/layout_components';

interface LabelItem {
    id: string;
    alias: string;
    selected: boolean;
}
interface LabelListProps {
    items: LabelItem[];
    id: string;
    closeOnClick: boolean;
    applyFilters: () => void;
}

interface LabelListState {
    selected?: string;
}

export class LabelList extends Component<LabelListProps, LabelListState> {
    constructor(props) {
        super(props);

        this.state = { selected: props.items.find((item) => item.selected).id };

        this.onItemSelect = this.onItemSelect.bind(this);
        this.getValues = this.getValues.bind(this);
        this.syncValues = this.syncValues.bind(this);
    }

    onItemSelect(item) {
        const { closeOnClick, applyFilters } = this.props;
        this.setState({ selected: item }, () => closeOnClick && applyFilters());
    }

    getValues() {
        return { [this.props.id]: this.state.selected };
    }

    syncValues() {
        const { items } = this.props;

        this.setState({
            selected: items.find((item) => item.selected)?.id,
        });
    }

    override render() {
        const { items } = this.props;
        const { selected } = this.state;

        return (
            <div className="labels-list">
                {items.map((item) => {
                    const isSelected = item.id === selected;
                    return (
                        <div className={classNames('label-item tbody-6', { selected: isSelected })} key={item.id}>
                            <Center
                                color="grey_1200"
                                marginRight="4"
                                flexShrink={0}
                                visibility={isSelected ? 'visible' : 'hidden'}
                            >
                                <CheckIcon />
                            </Center>
                            <Box
                                as="label"
                                fontSize="b_sm"
                                lineHeight="b_sm"
                                color="grey_1200"
                                cursor="pointer"
                                onClick={() => this.onItemSelect(item.id)}
                            >
                                {item.alias}
                            </Box>
                        </div>
                    );
                })}
            </div>
        );
    }
}
