import React, { ReactNode, RefObject, useRef } from 'react';
import {
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerTrigger,
} from '@fiverr-private/overlay';
import { Button } from '@fiverr-private/button';
import { I18n } from '@fiverr-private/i18n-react';
import { Container, Stack } from '@fiverr-private/layout_components';
import { useBusinessListingsContext } from '../Context';
import SidebarFilters, {
    invokeApplyEvent,
    invokeClearAllEvent,
    SidebarFiltersProvider,
    Triggers,
    useSidebarFiltersContext,
} from '../SidebarFilters';
import { areEqual, checkActiveFiltersEmpty } from '../FiltersUtils';

const T_PREFIX = 'search_perseus.business_listings.sidebar_filters.filters_drawer';

const ApplyButton = () => {
    const { activeFilters } = useBusinessListingsContext();
    const { newActiveFilters, applyEvent } = useSidebarFiltersContext();

    const buttonDisabled = areEqual(activeFilters, newActiveFilters);

    return (
        <Button
            disabled={buttonDisabled}
            onClick={() => invokeApplyEvent(applyEvent, newActiveFilters, Triggers.GLOBAL_APPLY_BUTTON)}
            fullWidth
        >
            <I18n k="search_perseus.general.apply" />
        </Button>
    );
};

const ClearAllButton = () => {
    const { activeFilters } = useBusinessListingsContext();
    const { clearAllEvent, newActiveFilters } = useSidebarFiltersContext();

    const buttonDisabled = checkActiveFiltersEmpty(activeFilters) && checkActiveFiltersEmpty(newActiveFilters);

    return (
        <Button variant={'ghost'} disabled={buttonDisabled} onClick={() => invokeClearAllEvent(clearAllEvent)}>
            <I18n k={`${T_PREFIX}.clear_all`} />
        </Button>
    );
};

interface FiltersDrawerProps {
    children: ReactNode;
}

export const FiltersDrawer = ({ children }: FiltersDrawerProps) => {
    const filtersContainerRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
    const { activeFilters } = useBusinessListingsContext();

    const drawerTitleTranslationKey = `${T_PREFIX}.title_small`;

    return (
        <SidebarFiltersProvider
            activeTrigger={Triggers.GLOBAL_APPLY_BUTTON}
            showFacetResultsCounters={false}
            activeFilters={activeFilters}
        >
            <Drawer>
                <DrawerTrigger>{children}</DrawerTrigger>
                <DrawerContent position={'left'} renderWhenClosed={true}>
                    <DrawerHeader>
                        <Stack position={'relative'} justifyContent={'spaceBetween'} direction={'rowReverse'}>
                            <ClearAllButton />
                            <Container
                                position="absolute"
                                left={0}
                                right={0}
                                top="50%"
                                transform="translateY(-50%)"
                                pointerEvents="none"
                                textAlign="center"
                                display="inline"
                            >
                                <I18n k={drawerTitleTranslationKey} />
                            </Container>
                            <DrawerCloseButton />
                        </Stack>
                    </DrawerHeader>
                    <DrawerBody>
                        <div ref={filtersContainerRef}>
                            <SidebarFilters sidebarContainerRef={filtersContainerRef} />
                        </div>
                    </DrawerBody>
                    <DrawerFooter>
                        <Stack width={'100%'} direction={'rowReverse'}>
                            <ApplyButton />
                        </Stack>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </SidebarFiltersProvider>
    );
};
