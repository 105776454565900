import React, { useEffect } from 'react';
import { useImpressionManager } from '@fiverr-private/impressionable';
import { init as biankaClientInit } from '@fiverr-private/bianka_js';
import ListingsPerseus from '../../../apps/listings/components/ListingsPerseus';
import withRequestContext from '../../../apps/listings/components/hoc/withRequestContext';
import { ClientListingsPropTypes } from '../../../apps/listings/types';
import GenericListings from '../../../apps/listings/components/GenericListings';
import { appName } from './constants';

const ClientListings = (props) => {
    useImpressionManager();

    const { isExpertListings, bianka } = props;

    const Component = isExpertListings ? GenericListings : ListingsPerseus;

    useEffect(() => {
        const init = async () => {
            await biankaClientInit(bianka.serviceUrl, appName);
        };

        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Component {...props} />;
};

ClientListings.propTypes = ClientListingsPropTypes;

const ListingsWithRequestContext = withRequestContext(ClientListings);

export default ListingsWithRequestContext;
