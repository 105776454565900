import React from 'react';
import { string } from 'prop-types';
import { I18n, translate as t } from '@fiverr-private/i18n-react';
import { GuaranteeIcon } from '@fiverr-private/icons';
import { Stack } from '@fiverr-private/layout_components';
import { Text } from '@fiverr-private/typography';
import { Tooltip } from '@fiverr-private/tooltip';
import { hoverOnCustomerProtectionEvent } from '../../../utils/biEvents/events/HoverOnCustomerProtection';
import { useExpertCardContext } from '../../../contexts';

import './index.scss';

const COPY_KEY = 'listings.expert_card.footer';

const CustomerProtection = ({ source }) => {
    const { seller } = useExpertCardContext();

    const onHover = () => {
        hoverOnCustomerProtectionEvent({ seller, source });
    };

    return (
        <Tooltip
            position="bottom"
            disableFlip
            appendToBody
            content={t(`${COPY_KEY}.customer_protection_tooltip`)}
            as="div"
        >
            <Stack
                gap="1.5"
                alignItems="center"
                className="footer-customer-protection"
                color="grey_900"
                onMouseEnter={onHover}
            >
                <Text size="b_xs" color="inherit">
                    <I18n k={`${COPY_KEY}.customer_protection`} />
                </Text>
                <GuaranteeIcon size="16" color="currentColor" />
            </Stack>
        </Tooltip>
    );
};

CustomerProtection.propTypes = {
    source: string,
};

export default CustomerProtection;
