import React, { useState } from 'react';
import classnames from 'classnames';
import { I18n } from '@fiverr-private/i18n-react';
import { useImpressionItem } from '@fiverr-private/impressionable';
import { ArrowRightIcon } from '@fiverr-private/icons';
import {
    BI_ELEMENTS_NAMES,
    BI_EVENTS_TYPES,
    ELEMENT_TYPE,
} from '../../../../../../generic_listings/TagFilters/constants';
import { buildEventEnrichment } from '../../../../../../generic_listings/TagFilters/utils';
import { BI_EVENT_GROUPS } from '../../../../../../../../../shared/services/BIEvents/constants';

import styles from './index.module.scss';

const MAX_TAGS_COUNT = 6;

const NestedSubcategoriesFilter = ({ items, subcategoryName, disableClicks }) => {
    const [itemsToDisplay, setItemsToDisplay] = useState(items.slice(0, MAX_TAGS_COUNT));
    const elementName = BI_ELEMENTS_NAMES.LEAF_CATEGORY;
    const enrichment = buildEventEnrichment({
        type: BI_EVENTS_TYPES.IMPRESSION,
        group: BI_EVENT_GROUPS.USER_IMPRESSION,
        elementName,
        elementType: ELEMENT_TYPE.TAGS,
    });
    const [ref] = useImpressionItem({ enrichment });

    const shouldShowViewAll = items.length > MAX_TAGS_COUNT;
    const isShowAllOpen = items.length === itemsToDisplay.length;

    return (
        <div ref={ref} className={classnames(styles.container)}>
            <span className={styles.titleContainer}>{subcategoryName}</span>
            <div className={classnames(styles.tagsContainer, 'm-t-16')}>
                {itemsToDisplay.map(({ alias, href, leafCategoryId }) => (
                    <a
                        key={leafCategoryId}
                        href={href}
                        className={classnames(styles.filterTag, 'tbody-6')}
                        onClick={disableClicks}
                    >
                        <span>{alias}</span>
                        <span className="m-l-4">
                            <ArrowRightIcon size={12} />
                        </span>
                    </a>
                ))}
            </div>
            {shouldShowViewAll && (
                <div
                    onClick={() => setItemsToDisplay(isShowAllOpen ? items.slice(0, MAX_TAGS_COUNT) : items)}
                    className={classnames('tbody-6 m-t-12', styles.viewAllTrigger)}
                >
                    {isShowAllOpen ? (
                        <I18n k={'search_perseus.general.less'} />
                    ) : (
                        <I18n k={'search_perseus.general.more'} params={{ count: items.length - MAX_TAGS_COUNT }} />
                    )}
                </div>
            )}
        </div>
    );
};

export default NestedSubcategoriesFilter;
