import React from 'react';
import { string, bool, func } from 'prop-types';
import { I18n } from '@fiverr-private/i18n-react';
import { createClickedElement, trackGigForClickType } from '../../utils';
import { ELEMENTS_NAME, ELEMENTS_TYPE } from '../constants';
import ContextualLink from '../ContextualLink';
import './index.scss';

const SellerName = ({ name, url, urlTarget, isPromoted = false }, context) => (
    <div className="seller-name">
        {isPromoted && (
            <span className="prefix">
                <I18n k="gig_cards.badges.promoted_gigs.seller_name_prefix.ad_by" />{' '}
            </span>
        )}
        <ContextualLink
            className="text-semi-bold"
            href={url}
            target={urlTarget}
            rel="nofollow noopener noreferrer"
            onMouseDown={(e) => trackGigForClickType(e.button, context.trackEvent, clickedElement(name))}
        >
            {name}
        </ContextualLink>
    </div>
);

SellerName.propTypes = {
    name: string.isRequired,
    url: string.isRequired,
    urlTarget: string,
    isPromoted: bool,
};

SellerName.contextTypes = {
    trackEvent: func.isRequired,
};

export const clickedElement = (name) => createClickedElement(ELEMENTS_NAME.SELLER_TITLE, ELEMENTS_TYPE.LINK, name);

export default SellerName;
