const LOCALES = {
    EN: 'en-US',
    DE: 'de-DE',
    ES: 'es',
    FR: 'fr-FR',
    IT: 'it-IT',
    NL: 'nl-NL',
    PT: 'pt-BR',
};

module.exports = {
    LOCALES,
};
