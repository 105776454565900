import { padEnd, parseInt } from 'lodash';
import { interpolator, CurrencyConverter } from '@fiverr-private/futile';
import { currencyFormat } from '@fiverr-private/localization';
import { DEFAULT_LOCALE } from '../../constants';

const CONVERSION_OPTIONS = { commaSeparate: true, convertRate: false };
const NUMBER_PARTS = {
    INTEGER: 'integer',
    FRACTION: 'fraction',
    DECIMAL: 'decimal',
};

interface CurrencyChunk {
    type: string;
    value: string;
}

export class PriceConverter {
    currencyConverter: CurrencyConverter;
    currency: { rate: number; name: string; template: string };

    constructor(currency) {
        this.currencyConverter = new CurrencyConverter(currency);
        this.currency = currency;
    }

    convert(price) {
        return price * this.currency.rate;
    }

    getLocalizedPrice(priceInCents, { noFractionDigits = false } = {}, locale = DEFAULT_LOCALE) {
        const priceInDollars = priceInCents / 100;
        const convertedPrice = this.convert(priceInDollars);

        return currencyFormat(convertedPrice, this.currency.name, {
            noFractionDigits,
            fallbackValue: this.currencyConverter.convert(convertedPrice, CONVERSION_OPTIONS),
            ...(locale && {
                formattingLocale: locale,
            }),
        });
    }

    buildPriceStr(numberArr: CurrencyChunk[] = []): string {
        const formattedParts = numberArr.map(({ type, value }) => {
            switch (type) {
                case NUMBER_PARTS.INTEGER:
                    return `<span>${value}</span>`;
                case NUMBER_PARTS.FRACTION:
                    return `<sup>${padEnd(value, 2, '0')}</sup>`;
                case NUMBER_PARTS.DECIMAL:
                    return '';
                default:
                    return value;
            }
        });
        return formattedParts.join('');
    }

    formatFraction(price) {
        const [integer, fraction] = price.toString().split('.');

        return `<span>${integer}</span>${parseInt(fraction) > 0 ? `<sup>${padEnd(fraction, 2, '0')}<sup>` : ''}`;
    }

    getTemplateFormat(price) {
        const { template, rate, name } = this.currency;
        const interpolate = interpolator(/{{([^{}]*)}}/g);
        const convertedPrice = price * rate;

        const formattedNum: CurrencyChunk[] | number = currencyFormat(convertedPrice, name, {
            fallbackValue: convertedPrice,
            asParts: true,
        });

        if (Array.isArray(formattedNum)) {
            return this.buildPriceStr(formattedNum);
        }

        return interpolate(template, { amount: this.formatFraction(formattedNum.toFixed(2)) });
    }
}
