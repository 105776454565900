import React from 'react';
import { func, string } from 'prop-types';
import { I18n } from '@fiverr-private/i18n-react';
import { Button } from '@fiverr-private/fit';
import './index.scss';

const Footer = ({ onApply, onClear, className }) => (
    <footer className={className}>
        <span onClick={onClear} className="clear-all tbody-5">
            <I18n k={'search_perseus.general.clear_all'} />
        </span>
        <Button className="apply" color="black" size={Button.SIZES.SMALL} onClick={onApply}>
            <I18n k={'search_perseus.general.apply'} />
        </Button>
    </footer>
);

Footer.defaultProps = {
    className: '',
};

Footer.propTypes = {
    onClear: func,
    onApply: func,
    className: string,
};

export default Footer;
