import React from 'react';
import { useImpressionItem } from '@fiverr-private/impressionable';
import { getContext } from '@fiverr-private/fiverr_context';
import { searchTermTag } from '../../../../types';
import { biUserAction } from '../../../../../../shared/services/BIEvents/utils';
import {
    BI_PAGE_NAMES,
    BI_EVENTS_SUB_GROUPS,
    BI_ACTION_TYPES,
} from '../../../../../../shared/services/BIEvents/constants';
import { BI_EVENTS } from './consts';

const Tag = ({ query, termUrl, position }) => {
    const { pageCtxId } = getContext();

    const eventEnrichment = {
        position,
        page: {
            name: BI_PAGE_NAMES.LISTINGS,
            ctx_id: pageCtxId,
            component: {
                name: BI_EVENTS.COMPONENT_NAME,
            },
            element: {
                name: query,
            },
        },
    };

    const [ref] = useImpressionItem({
        enrichment: {
            type: BI_EVENTS.IMPRESSION,
            action: {
                type: BI_ACTION_TYPES.IMPRESSION,
            },
            sub_group: BI_EVENTS_SUB_GROUPS.CONTENT_IMPRESSION,
            ...eventEnrichment,
        },
    });

    const onClick = () => biUserAction({ type: BI_EVENTS.CLICK, ...eventEnrichment, payload: { position } });

    return (
        <a href={termUrl} onClick={onClick} ref={ref}>
            {query}
        </a>
    );
};

export default Tag;

Tag.propTypes = searchTermTag;
