import { tracker, EVENTS_MAPPING } from '@fiverr-private/listing_lib';
import { getUrl } from '../../LeafCategoriesCarousel/utils';
import { getLeafCategoryById, sendBiClickEvent } from '../../../generic_listings/TagFilters/utils';
import { BI_ELEMENTS_NAMES, ELEMENT_TYPE } from '../../../generic_listings/TagFilters/constants';
import { OTHER_SC_IDS } from './constants';

export const handleClick = ({ subCategory, selected }) => {
    const selectedLeafCategory = getLeafCategoryById(subCategory.children, selected);
    const {
        alias,
        params: { sub_category, nested_sub_category },
    } = selectedLeafCategory;
    const leafCategoryId = nested_sub_category || sub_category;

    trackLeafCategoryFilter(alias, leafCategoryId);
    sendBiClickEvent(BI_ELEMENTS_NAMES.LEAF_CATEGORY, leafCategoryId, ELEMENT_TYPE.VISUAL);
};

export const trackLeafCategoryFilter = (alias, leafCategoryId) => {
    const payload = {
        location: 'Top Tile',
        alias,
        id: leafCategoryId,
    };

    tracker.trackLeafCategoryFilter(EVENTS_MAPPING.FILTERS_APPLY, payload);
};

export const transformLeafCategoryItems = (leafCategories = []) =>
    leafCategories.map((category) => ({
        id: category.params.nested_sub_category || category.params.sub_category,
        alias: category.alias,
        url: getUrl(category),
        count: category.count,
    }));

export const sortByMostSignificant = (leafCategories = [], significantLeafCategories) => {
    const reversedOrder = [...significantLeafCategories].reverse();

    const sortedList = leafCategories.sort(
        (a, b) => reversedOrder.findIndex((k) => k === b.id) - reversedOrder.findIndex((i) => i === a.id)
    );

    return moveOtherSCToLast(sortedList);
};

const moveOtherSCToLast = (sortedList) => {
    const otherScIndex = sortedList.findIndex((el) => OTHER_SC_IDS.includes(el.id));
    if (otherScIndex !== -1) {
        const option = sortedList.splice(otherScIndex, 1)[0];
        sortedList.push(option);
    }
    return sortedList;
};
