import { useImpressionContainer, useImpressionItem } from '@fiverr-private/impressionable';

interface UseImpressionProps {
    onImpression: (e: Event) => void;
    sourceComponent?: string;
    intersectionThreshold?: number;
}

export const useImpression = ({ onImpression, sourceComponent, intersectionThreshold }: UseImpressionProps) => {
    const [containerRef, containerId] = useImpressionContainer();

    useImpressionItem({
        selfId: containerId,
        selfRef: containerRef,
        sendImpressionToBQ: false,
        enrichment: {
            ...(sourceComponent && { name: sourceComponent }),
            modalities_carousel: {},
        },
        intersectionThreshold,
        onImpression,
    });

    return containerRef;
};
