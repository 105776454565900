import { merge } from 'lodash';
import { getContext } from '@fiverr-private/fiverr_context';
import { getBiPageName } from './common/biEventsHelpers';

export const getEventPayload = ({
    type,
    group,
    actionType,
    isExpertListings,
    entity = 'client',
    extraProps = {},
} = {}) => {
    const { organizationId } = getContext();

    const payload = {
        type,
        group,
        entity,
        action: {
            type: actionType,
        },
        page: {
            name: getBiPageName(isExpertListings),
        },
        organization: {
            id: organizationId,
        },
    };

    return merge(payload, extraProps);
};

export const getBqEventSource = (isExpertListings) => (isExpertListings ? 'fip_pro_listings' : 'fip_full_listings');
