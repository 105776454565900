import { func, bool, string } from 'prop-types';

export default {
    /**
     * Callback function fired on pagination
     */
    onPagination: func,
    /**
     * Toggles when fetching data
     */
    isLoading: bool,
    /**
     * The source param in the url
     */
    source: string,
};
