import React from 'react';
import PropTypes from 'prop-types';
import Tag from './Tag';
import './index.scss';

const SearchTags = ({ relatedTerms }) => (
    <div className="search-tags">
        {relatedTerms.map(({ pos, query, termUrl }) => (
            <Tag key={pos} query={query} termUrl={termUrl} position={pos} />
        ))}
    </div>
);

SearchTags.propTypes = {
    relatedTerms: PropTypes.array,
};

SearchTags.defaultProps = {
    relatedTerms: [],
};

export default SearchTags;
