const INCLUDED_SC_NAVIGATION_TILES_TESTED_OUT = [
    '156',
    '515',
    '340',
    '154',
    '512',
    '163',
    '16',
    '99',
    '371',
    '49',
    '54',
    '67',
    '139',
    '138',
    '249',
    '384',
    '392',
    '50',
    '386',
    '329',
    '158',
    '234',
    '2441',
    '2442',
    '2443',
    '2240',
    '2412',
    '2414',
    '2416',
    '2357',
    '2358',
];

module.exports = {
    INCLUDED_SC_NAVIGATION_TILES_TESTED_OUT,
};
