import React from 'react';
import PropTypes from 'prop-types';
import '../style.scss';

export const TopListingBanner = ({ children }) => (
    <section className="top-listing-banner-wrap">
        <div className="top-listing-banner">{children}</div>
    </section>
);

TopListingBanner.propTypes = {
    children: PropTypes.node.isRequired,
};

export default TopListingBanner;
