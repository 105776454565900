import React from 'react';
import { isEmpty, uniq } from 'lodash';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Checkbox } from '@fiverr-private/forms';
import Types from '../../types';
import { invokeApplyEvent, Triggers, useSidebarFiltersContext } from '../../Context';
import FilterWithCount from '../FilterWithCount';
import ShowMoreWrapper from '../ShowMoreWrapper';
import styles from './index.module.scss';

const CheckboxList = ({ filter, i18n, activeFilters, onMoreClick }) => {
    const { applyEvent, newActiveFilters } = useSidebarFiltersContext();

    const checkboxClicked = ({ option, isSelected }) => {
        const activeFiltersOptions = newActiveFilters[filter.id] || activeFilters[filter.id] || [];

        let newActiveOptionsForFilter;
        if (isSelected) {
            newActiveOptionsForFilter = uniq([option, ...activeFiltersOptions]);
        } else {
            newActiveOptionsForFilter = activeFiltersOptions.filter((it) => it !== option);
        }

        const filtersAfterChange = { ...(isEmpty(newActiveFilters) ? activeFilters || {} : newActiveFilters) };
        filtersAfterChange[filter.id] = newActiveOptionsForFilter;

        invokeApplyEvent(applyEvent, filtersAfterChange, Triggers.FILTER_COMPONENT_CLICK);
    };

    return (
        <div className={styles.container}>
            <div className="p-b-24">
                <ShowMoreWrapper filter={filter} i18n={i18n} onMoreClick={onMoreClick}>
                    {({ optionsToDisplay }) =>
                        optionsToDisplay.map(({ alias, id, count }) => {
                            const selected = newActiveFilters[filter.id]?.includes(id);

                            return (
                                <div
                                    key={`sidebar-filter-${filter.id}-option-${id}`}
                                    className={classNames(styles.checkboxContainer, 'tbody-6', 'p-b-8')}
                                >
                                    <Checkbox
                                        className={styles.checkboxLabel}
                                        checked={selected}
                                        onChange={(e) => checkboxClicked({ option: id, isSelected: e.target.checked })}
                                    >
                                        <FilterWithCount aliasClassName="tbody-6" alias={alias} count={count} />
                                    </Checkbox>
                                </div>
                            );
                        })
                    }
                </ShowMoreWrapper>
            </div>
        </div>
    );
};

CheckboxList.propTypes = {
    filter: Types.filter.isRequired,
    activeFilters: Types.activeFilters.isRequired,
    i18n: PropTypes.object.isRequired,
    onMoreClick: PropTypes.func,
};

export default CheckboxList;
