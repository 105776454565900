import React from 'react';
import { bool, func, object, oneOf, string, arrayOf, shape, number } from 'prop-types';
import { some } from 'lodash';
import classnames from 'classnames';
import { getContext } from '@fiverr-private/fiverr_context';
import { Stack } from '@fiverr-private/layout_components';
import { CARD_VARIANT, CTAS } from '../../../shared/constants';
import { cardVariantType, sellerType } from '../../../shared/types';
import { highlightsDataType, highlightsType } from '../../SellerHighlight/types';
import SellerHighlight from '../../SellerHighlight';
import ContactButton from '../CardActions/ContactButton';
import CustomButton from '../CardActions/CustomButton';
import { SaveExpertButton } from '../CardActions/SaveExpertButton';
import SeeProfileButton from '../CardActions/SeeProfileButton';
import { hasHighlights } from '../utils';
import CustomerProtection from './CustomerProtection';
import { Price } from './price';

import '../../SellerHighlight/index.scss';
import './index.scss';

const Footer = ({
    priceInUSD,
    highlights,
    highlightsData,
    showPrice = true,
    showSeeProfile: propShowSeeProfile = true,
    showContact: propShowContact,
    showSaveExpert,
    mainCta = CTAS.CONTACT,
    renderContent,
    seller,
    cardVariant,
    currency,
    source,
    footerCustomButtons = [],
    inProCatalogGridExp,
    hourlyRateInCents,
    showHourlyPrice = false,
}) => {
    const {
        experience: { isBusiness },
    } = getContext();

    // If a button is the main CTA, show it by default
    const showContact = propShowContact ?? mainCta === CTAS.CONTACT;
    const showSeeProfile = propShowSeeProfile ?? mainCta === CTAS.SEE_PROFILE;
    const shouldShowPrice = priceInUSD && showPrice && cardVariant !== CARD_VARIANT.SERVICE;
    const shouldShowSmallFooter = shouldShowPrice || showContact || showSaveExpert || showSeeProfile;
    const shouldShowNormalFooter = shouldShowPrice || some(Object.values(highlights));
    const shouldShowHighlights = hasHighlights(highlights);
    const shouldShowDivider = inProCatalogGridExp ? !shouldShowHighlights : cardVariant !== CARD_VARIANT.VISUAL;
    const shouldShowCustomerProtection = isBusiness && seller.isPro;

    const preventClickPropagation = (event) => {
        event.stopPropagation();
    };

    const isHourlyPrice = showHourlyPrice && !!hourlyRateInCents;

    return (
        <>
            {shouldShowSmallFooter && (
                <div
                    className={classnames('card-footer-small p-t-16', {
                        [`m-t-36 divider`]: shouldShowDivider,
                    })}
                >
                    {inProCatalogGridExp && shouldShowHighlights && (
                        <div className="m-b-8">
                            <SellerHighlight highlights={highlights} highlightsData={highlightsData} />
                        </div>
                    )}
                    <div className="flex flex-justify-between flex-items-center">
                        {renderContent
                            ? renderContent({ seller })
                            : shouldShowPrice && (
                                  <Stack direction="column" gap="0">
                                      <Price
                                          isHourlyPrice={isHourlyPrice}
                                          priceInUSD={isHourlyPrice ? hourlyRateInCents / 100 : priceInUSD}
                                          currency={currency}
                                          containerClassName="m-l-0 p-r-12"
                                      />
                                      {!inProCatalogGridExp && shouldShowCustomerProtection && !isHourlyPrice && (
                                          <CustomerProtection source={source} />
                                      )}
                                  </Stack>
                              )}
                        {
                            <div
                                className="flex flex-justify-end listings-action-menu-container"
                                onClick={preventClickPropagation}
                            >
                                {showContact && (
                                    <ContactButton
                                        source={source}
                                        className="m-r-0"
                                        size="sm"
                                        isCta={mainCta === CTAS.CONTACT}
                                    />
                                )}
                                {showSaveExpert && <SaveExpertButton size="sm" />}
                                {showSeeProfile && (
                                    <SeeProfileButton source={source} isCta={mainCta === CTAS.SEE_PROFILE} size="sm" />
                                )}
                                {footerCustomButtons.map(
                                    ({ label, onClick, isCta, elementName, loading, disabled, variant, icon }) => (
                                        <CustomButton
                                            key={label}
                                            label={label}
                                            onClick={onClick}
                                            isCta={isCta}
                                            source={source}
                                            elementName={elementName}
                                            size="sm"
                                            loading={loading}
                                            disabled={disabled}
                                            variant={variant}
                                            icon={icon}
                                        />
                                    )
                                )}
                            </div>
                        }
                    </div>
                </div>
            )}
            {shouldShowNormalFooter &&
                (renderContent ? (
                    <div className="card-footer divider flex flex-justify-between p-t-20 m-t-16">
                        {renderContent({ seller })}
                    </div>
                ) : (
                    <div
                        className={classnames('card-footer flex flex-justify-between m-t-24', {
                            [`p-t-24 divider`]: shouldShowDivider,
                        })}
                    >
                        <SellerHighlight highlights={highlights} highlightsData={highlightsData} />
                        {shouldShowPrice && (
                            <Stack direction="column" gap="0">
                                <Price
                                    isHourlyPrice={isHourlyPrice}
                                    priceInUSD={isHourlyPrice ? hourlyRateInCents / 100 : priceInUSD}
                                    currency={currency}
                                    containerClassName="m-l-0 p-r-12"
                                />
                                {shouldShowCustomerProtection && !isHourlyPrice && (
                                    <CustomerProtection source={source} />
                                )}
                            </Stack>
                        )}
                    </div>
                ))}
        </>
    );
};

Footer.propTypes = {
    priceInUSD: string.isRequired,
    highlights: highlightsType.isRequired,
    highlightsData: highlightsDataType.isRequired,
    showPrice: bool,
    showSaveExpert: bool,
    showContact: bool,
    showSeeProfile: bool,
    cardVariant: cardVariantType,
    currency: object,
    source: string,
    mainCta: oneOf(Object.values(CTAS)),
    seller: sellerType,
    renderContent: func,
    footerCustomButtons: arrayOf(shape({ label: string, onClick: func, isCta: bool, elementName: string })),
    inProCatalogGridExp: bool,
    hourlyRateInCents: number,
    showHourlyPrice: bool,
};

export default Footer;
