import React from 'react';
import classNames from 'classnames';
import { bool, func, string, number } from 'prop-types';
import { translate } from '@fiverr-private/i18n-react';
import { getTitle } from '../../../Components/Title/utils';
import { SellerRepresentative } from '../../../../constants/seller';
import { trackGigForClickType } from '../../../utils';
import { clickedElement } from '../../../Components/Title';
import { useCardContext } from '../../../../GigCardListings/context/CardContext';
import ContextualLink from '../../../Components/ContextualLink';
import { GIG_TITLE_PREFIX_KEY, AGENCY_TITLE_PREFIX_KEY, MAX_ROWS, MAX_ROWS_MAPPER } from './constants';

import styles from './index.module.scss';

const GigTitle = ({
    hidePrefix,
    title,
    showTranslatedUGC,
    translatedTitle,
    localizedTitle,
    url,
    urlTarget,
    onMouseEnter,
    onMouseLeave,
    sellerType = SellerRepresentative.FREELANCER,
    className = 'p-t-8',
    maxRows = MAX_ROWS.TWO,
    titleClassName: inputTitleClassName,
}) => {
    const {
        enrichedTracker: { trackEvent },
    } = useCardContext();
    const titleClassName = inputTitleClassName ?? classNames('tbody-5', 'text-normal');
    const titlePrefixKey = sellerType === SellerRepresentative.AGENCY ? AGENCY_TITLE_PREFIX_KEY : GIG_TITLE_PREFIX_KEY;
    const textTitle = getTitle({
        hidePrefix,
        showTranslatedUGC,
        translatedTitle,
        localizedTitle,
        title,
        gigTitleKey: titlePrefixKey,
    });

    return (
        <ContextualLink
            href={url}
            target={urlTarget}
            className={classNames(styles.gigTitle, MAX_ROWS_MAPPER[maxRows], className)}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onMouseDown={(e) => trackGigForClickType(e.button, trackEvent, clickedElement(textTitle))}
            ariaLabel={translate('gig_cards.gig_link_aria_label')}
        >
            <p role="heading" aria-level="3" className={classNames(styles.title, titleClassName)} title={textTitle}>
                {textTitle}
            </p>
        </ContextualLink>
    );
};

GigTitle.propTypes = {
    title: string,
    translatedTitle: string,
    localizedTitle: string,
    showTranslatedUGC: bool,
    hidePrefix: bool,
    url: string,
    urlTarget: string,
    onMouseEnter: func,
    onMouseLeave: func,
    sellerType: SellerRepresentative,
    className: string,
    maxRows: number,
    titleClassName: string,
};

export default GigTitle;
