import { isNil } from 'lodash';
import { URI } from '@fiverr-private/futile';

export const pushParamToUrlHistory = (paramName, paramValue) => {
    const currentUrl = new URI(window.location.href);
    const { params } = currentUrl;
    const currentParamValue = params[paramName];

    if (currentParamValue === paramValue) {
        return;
    }

    if (!isNil(currentParamValue)) {
        currentUrl.removeParams(paramName);
    }

    currentUrl.addParams({ [paramName]: paramValue });

    window.history.replaceState(null, null, currentUrl.href);
};
