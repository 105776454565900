import React, { useRef } from 'react';
import { getContext } from '@fiverr-private/fiverr_context';
import { BannerWrapper } from '@fiverr-private/gig_listings';
import { useAppContext } from '../../../context/listingsContext';
import { useHourlyRateBannersAllocation } from './hooks/useHourlyRateBannersAllocation';
import { HourlyRateTopBanner, HourlyRateTopBannerProps } from './HourlyRateTopBanner';
import { TestType } from './HourlyRate.constants';

export const HourlyRateBannerResolver: React.FC<HourlyRateTopBannerProps> = () => {
    const { experience = { isBusiness: false } } = getContext();
    const ref = useRef<HTMLDivElement | null>(null);
    const { shouldShowHourlyRateBanners } = useAppContext();
    const { testType, guest, user } = useHourlyRateBannersAllocation(ref);

    if (experience?.isBusiness || !shouldShowHourlyRateBanners) {
        return null;
    }

    switch (testType) {
        case TestType.GUEST:
            if (guest.inGroupB) {
                return <HourlyRateTopBanner />;
            }
            break;
        case TestType.USER:
            if (user.inGroupB) {
                return <HourlyRateTopBanner />;
            }
            break;
        default:
            break;
    }

    return <div ref={ref} />;
};

export const HourlyRateBannerResolverWithWrapper: React.FC<HourlyRateTopBannerProps> = (props) =>
    BannerWrapper(<HourlyRateBannerResolver {...props} />)({
        className: 'hourly-rate-banner-listings',
        bannerRow: 3,
    });
