import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { numberFormat } from '@fiverr-private/localization';
import { useSidebarFiltersContext } from '../../Context';
import styles from './index.module.scss';

const FilterWithCount = ({ alias, count, aliasClassName }) => {
    const { showFacetResultsCounters } = useSidebarFiltersContext();
    let formattedCount = '';
    if (typeof count === 'number') {
        formattedCount = `(${numberFormat(count, { fallbackValue: count?.toLocaleString() })})`;
    }

    return (
        <span className={classnames(styles.alias, aliasClassName)}>
            {alias}{' '}
            {showFacetResultsCounters && <span className={classnames(styles.count, 'tbody-6')}>{formattedCount}</span>}
        </span>
    );
};

FilterWithCount.propTypes = {
    alias: PropTypes.string,
    count: PropTypes.number,
    aliasClassName: PropTypes.string,
};

export default FilterWithCount;
