import { MIN_GIG_PRICE_RANGE, MAX_GIG_PRICE_RANGE } from '../../consts';

export const rangeValue = (val) => parseInt(val, 10) || 0;

export const parseRangeValue = (priceRange = [MIN_GIG_PRICE_RANGE, MAX_GIG_PRICE_RANGE]) => {
    const [minVal, maxVal] = priceRange.map((v) => rangeValue(v));

    return { min: minVal, max: maxVal };
};

export const getBudgetValue = (activeFilters) => {
    const { price_buckets: priceBuckets, gig_price_range: gigPriceRange } = activeFilters;

    if (gigPriceRange) {
        return parseRangeValue(gigPriceRange);
    }

    if (priceBuckets) {
        return priceBuckets;
    }

    return '';
};
