import React from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import { Typography } from '@fiverr-private/typography';

export const LocalizedQueryTranslationDescription = ({
    nonTranslatedQuery,
    query,
}: {
    nonTranslatedQuery?: string;
    query?: string;
}) => {
    if (!nonTranslatedQuery) {
        return null;
    }

    return (
        <Typography size="b_lg" color="grey_900">
            <I18n
                k="listings_header.search_header.machine_translated_query_description"
                params={{ nonTranslatedQuery, query }}
            />
        </Typography>
    );
};
