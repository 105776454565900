import React from 'react';
import { ComboBox } from '@fiverr-private/orca';
import { useSidebarFiltersContext, invokeApplyEvent, Triggers } from '../../../Context';
import { ComboBoxOptionWithCount } from '../../FilterWithCount/ComboBoxOptionWithCount';
import NoOptions from './NoOptions';

const ComboBoxFilter = ({ filter, allFilters, activeFilters }) => {
    const { applyEvent, newActiveFilters } = useSidebarFiltersContext();

    const { id: filterId } = filter;
    const { options: fullFilterOptions = [] } = allFilters.find((filter) => filter.id === filterId) || {};
    const activeOptions = newActiveFilters?.[filterId] || activeFilters[filterId] || [];

    const isCurrentOptionSelected = (optionId) => activeOptions.includes(optionId);

    const onSelect = ({ value }) => {
        const newActiveOptionsForFilter = [...activeOptions, value];

        const filtersAfterChange = { ...(newActiveFilters || activeFilters) };
        filtersAfterChange[filterId] = newActiveOptionsForFilter;

        invokeApplyEvent(applyEvent, filtersAfterChange, Triggers.FILTER_COMPONENT_CLICK);
    };

    const comboOptions = fullFilterOptions
        .filter(({ id }) => !isCurrentOptionSelected(id))
        .map(({ alias, id, count }) => ({ value: id, label: alias, count }));

    return (
        <ComboBox
            options={comboOptions}
            isMulti={false}
            onChange={onSelect}
            NoOptionsMessage={NoOptions}
            OptionComponent={ComboBoxOptionWithCount}
        />
    );
};

export default ComboBoxFilter;
