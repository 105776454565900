import axios from 'axios';
import { logger } from '@fiverr-private/obs';

export const getUserTeamData = (userId) =>
    axios
        .get(`/workspaces/users/${userId}/context`)
        .then((res) => res.data)
        .catch((error) => {
            logger.error(error);
            throw error;
        });
