import React from 'react';
import { Stack } from '@fiverr-private/layout_components';
import { MetadataFilterOption } from '../MetadataFilterOption/MetadataFilterOption';
import { EnrichedMetadataFilterOption } from './types';

export interface MetadataFilterOptionsColumnProps {
    items: EnrichedMetadataFilterOption[];
    onOptionClick: (id: string, value: boolean) => void;
}

export const MetadataFilterOptionsColumn = ({ items, onOptionClick }: MetadataFilterOptionsColumnProps) => (
    <Stack flex="1" direction="column" gap="6">
        {items.map(({ id, count, alias, selected }) => (
            <MetadataFilterOption
                key={id}
                id={id}
                count={count}
                alias={alias}
                selected={selected}
                onOptionClick={onOptionClick}
            />
        ))}
    </Stack>
);
