import number from '../../../../../shared/utils/formatting/number';

const ROUND_DOWN_THRESHOLD = 1000;

const roundDownSecondDigit = (count: number): number => {
    const orderOfMagnitude = Math.floor(Math.log10(count));
    const divisor = 10 ** (orderOfMagnitude - 1);

    return Math.floor(count / divisor) * divisor;
};

export const calculateNumberOfResult = (numFound: number): string => {
    if (!numFound) {
        return '';
    }

    return numFound < ROUND_DOWN_THRESHOLD ? number(numFound) : `${number(roundDownSecondDigit(numFound))}+`;
};
