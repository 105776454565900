type CurrentPage =
    | 'Fiverr Business Category'
    | 'Business Search'
    | 'Sub Category'
    | 'Search'
    | 'Profession Based Gigs Search'
    | 'Profession Based Roles Search';

export const isEligibleForNewImpressions = ({
    inReportNewImpressionsRollout = false,
    currentPage,
}: {
    inReportNewImpressionsRollout: boolean;
    currentPage: CurrentPage;
}) => {
    switch (currentPage) {
        case 'Sub Category':
        case 'Search':
        case 'Profession Based Gigs Search':
        case 'Profession Based Roles Search': {
            return inReportNewImpressionsRollout;
        }

        case 'Fiverr Business Category':
        case 'Business Search': {
            return true;
        }

        default: {
            return false;
        }
    }
};
