import { useContext } from 'react';
import { isNil } from 'lodash';
import { PriceConverter } from '@fiverr-private/listing_lib';
import { AppContext } from '../../../context/listingsContext';
import { ChoiceModalities, ChoiceModalitiesListing, ChoiceModalitiesType, ModalitiesGig } from '../types';

const MODALITIES_QUERY_PARAMETERS = {
    fiverr_choice: 'true',
};

interface BuildGigModalitiesConfigResult {
    type: ChoiceModalitiesType;
    gigs: ModalitiesGig[];
    gigToBucket: Record<string, never>;
    sourceComponent: 'choice_modalities_service' | 'choice_modalities_pricing';
    modalitiesListings: ChoiceModalitiesListing;
    priceConverter: PriceConverter;
}

const hasChoiceModalities = (
    listing: Record<string, unknown>
): listing is { choiceModalities: ChoiceModalities; context: Record<string, unknown> } =>
    'choiceModalities' in listing && 'context' in listing && !isNil(listing.choiceModalities);

export const useGigModalitiesConfig = (): BuildGigModalitiesConfigResult => {
    const {
        listings: [listing],
        currency,
    } = useContext(AppContext);
    const priceConverter = new PriceConverter({ ...currency, forceRoundFromAmount: 0 });

    if (!hasChoiceModalities(listing)) {
        return {
            gigs: [],
            gigToBucket: {},
            type: 'price',
            sourceComponent: 'choice_modalities_pricing',
            modalitiesListings: listing,
            priceConverter,
        };
    }

    const {
        choiceModalities: { buckets, type },
    } = listing;

    const gigs = buckets.map(({ gig }) => gig);
    const gigToBucket = buckets.reduce((res, { gig, ...rest }, index: number) => {
        res[gig.gigId] = {
            bucketNumber: index + 1,
            ...rest,
        };
        return res;
    }, {});
    const sourceComponent = type === 'service' ? 'choice_modalities_service' : 'choice_modalities_pricing';

    const modalitiesListings = {
        ...listing,
        context: {
            ...listing.context,
            sourceComponent,
            queryParameters: MODALITIES_QUERY_PARAMETERS,
        },
    };

    return { gigs, gigToBucket, type, sourceComponent, modalitiesListings, priceConverter };
};
