const REDIRECT_QUERY_BI_EVENT = {
    TYPE: 'original_term_of_semantic_redirect_is_shown',
    COMPONENT_NAME: 'tail_query_redirect',
};

const SPELLCHECK_QUERY_BI_EVENT = {
    TYPE: 'original_term_of_spellcheck_is_shown',
    COMPONENT_NAME: 'spellcheck_query_redirect',
};

export { REDIRECT_QUERY_BI_EVENT, SPELLCHECK_QUERY_BI_EVENT };
