const yup = require('yup');

module.exports = {
    GIG_SCHEMA: yup.object({
        gig_id: yup.number().integer().required(),
        cached_slug: yup.string().required(),
        title: yup.string().required(),
        seller_name: yup.string().required(),
        seller_id: yup.number().required(),
        seller_url: yup.string().required(),
        gig_url: yup.string().required(),
        assets: yup.array().min(1).required(),
    }),
};
