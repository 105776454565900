import React from 'react';
import classNames from 'classnames';
import { SMALL_GIG_CARD } from '../../../GigCardListings/utils/constants';
import gigTitleStyles from '../Components/GigTitle/index.module.scss';

export const getGigWrapperProps = (props, enrichedTracker) => {
    const { uId, cardClasses, isPro, isLoading } = props;

    return {
        type: SMALL_GIG_CARD,
        uId,
        classes: [cardClasses, 'basic-gig-card-small'],
        isPro,
        isLoading,
        LoadingComponent: null,
        gig: props,
        enrichedTracker,
    };
};

export const getMediaProps = (props, gigPageLinkHoverState) => {
    const {
        url,
        id: gigId,
        gigAttachmentIds,
        urlTarget,
        asset,
        localizedAssets,
        lazyLoad,
        seller,
        isTouch,
        isUnavailable,
        type: gigType,
        showTranslatedUGC,
        showNewImageRatio,
    } = props;

    return {
        url,
        gigId,
        gigAttachmentIds,
        urlTarget,
        seller,
        asset,
        localizedAssets,
        lazyLoad,
        isTouch,
        isUnavailable,
        gigType,
        showGalleryDecisionIndicators: false,
        showTranslatedUGC,
        showNewImageRatio,
        playOnHover: false,
        showPlayerControls: false,
        showAudioRemainingTime: false,
        showSingleSlide: true,
        onMouseEnter: gigPageLinkHoverState.onMouseEnter,
        onMouseLeave: gigPageLinkHoverState.onMouseLeave,
    };
};

export const getGigTitleProps = (props, gigPageLinkHoverState) => {
    const {
        title,
        url,
        urlTarget,
        isUnavailable,
        isTouch,
        hidePrefix,
        showTranslatedUGC,
        translatedTitle,
        localizedTitle,
    } = props;

    return {
        title,
        url,
        urlTarget,
        isUnavailable,
        isTouch,
        hidePrefix,
        showTranslatedUGC,
        translatedTitle,
        localizedTitle,
        className: classNames('basic-gig-card-small-title p-t-12', {
            [gigTitleStyles.hovering]: gigPageLinkHoverState.isHovering,
        }),
        onMouseEnter: gigPageLinkHoverState.onMouseEnter,
        onMouseLeave: gigPageLinkHoverState.onMouseLeave,
    };
};

export const getBadgeProps = (props) => ({
    ...props.badge,
});

export const getCollectWrapperProps = (props) => {
    const { id, collectProps = {}, triggerData = {} } = props;

    // eslint-disable-next-line react/prop-types
    const HeartButton = ({ className }) => <div className={classNames('collect-btn', className)} />;

    const collectedIcon = <HeartButton className="collected" />;
    const notCollectedIcon = <HeartButton />;

    return {
        id,
        collectProps,
        className: 'collect-wrapper',
        collectedIcon,
        notCollectedIcon,
        triggerData,
    };
};

export const getClearButtonProps = (props) => ({
    clearClick: (e, callback) => {
        e && e.preventDefault();
        const { id, clearClick } = props;

        clearClick && clearClick(id, callback);
    },
});
