import React, { useContext } from 'react';
import classNames from 'classnames';
import GigCardListings from '@fiverr-private/gig_listings/src/entries/GigCardListings';
import { getContext } from '@fiverr-private/fiverr_context';
import { AppContext } from '../../../store';
import { ListingsImpressionContext } from '../../../../../shared/context/ListingsImpressionContainer/ListingsImpressionContainer';
import { GIG_CARDS_LAZY_OFFSET, GIG_CARDS_MOBILE_LAZY_OFFSET, LISTINGS_DESKTOP_SIZE } from './constants';

const DynamicGigListings = (props) => {
    const {
        listings,
        gigCardLazyLoadOffset = GIG_CARDS_LAZY_OFFSET,
        isVintageProTest,
        banners,
        gigCardListingsClassName,
        isLoading,
        isNarrowView,
    } = props;

    const { isMobile } = getContext();
    const appContext = useContext(AppContext);
    const { onImpression } = useContext(ListingsImpressionContext);
    const { activeFilters, rollouts, subCategoryData, experimentsToAllocate } = appContext;
    const { showListViewCards } = listings;
    const gridContainerSize = isNarrowView ? 'sm' : 'md';

    return (
        <GigCardListings
            {...listings}
            lazyLoadOffset={isMobile ? GIG_CARDS_MOBILE_LAZY_OFFSET : gigCardLazyLoadOffset}
            size={LISTINGS_DESKTOP_SIZE}
            isVintageProTest={isVintageProTest}
            isExperiential={true}
            banners={banners}
            activeFilters={activeFilters}
            displayISpeak={true}
            onImpression={onImpression}
            showGalleryDecisionIndicators={!showListViewCards}
            rollouts={rollouts}
            experimentsToAllocate={experimentsToAllocate}
            isLoading={isLoading}
            listingsContainerClassName={classNames(gigCardListingsClassName, {
                'list-view-basic-gig-card-container': showListViewCards,
            })}
            subCategoryData={subCategoryData}
            gridContainerSize={gridContainerSize}
        />
    );
};

export default DynamicGigListings;
