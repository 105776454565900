import PropTypes from 'prop-types';

const filterOption = PropTypes.shape({
    id: PropTypes.string,
    alias: PropTypes.string,
    selected: PropTypes.bool,
});

const filter = PropTypes.shape({
    id: PropTypes.string,
    alias: PropTypes.string,
    options: PropTypes.arrayOf(filterOption),
    topGrouping: PropTypes.number,
    order: PropTypes.number,
    selector: PropTypes.string,
    display_type: PropTypes.string,
});

const activeFilters = PropTypes.objectOf(PropTypes.any);

const subCategory = PropTypes.shape({
    id: PropTypes.string.isRequired,
    alias: PropTypes.string.isRequired,
    name: PropTypes.string,
    selected: PropTypes.bool,
    selectedScName: PropTypes.string,
});

const significantLeafCategories = PropTypes.arrayOf(PropTypes.string);

export default {
    filter,
    filterOption,
    activeFilters,
    subCategory,
    significantLeafCategories,
};
