import { string, shape, number, bool } from 'prop-types';
import { attachmentType, mediaType } from '../../shared/types';

export default {
    /**
     * Custom className that is applied to the component
     */
    className: string,
    /**
     * The type of the asset image / video
     */
    assetType: mediaType,
    /**
     * Indicates if parent hovered, used to determine if the media should be played or paused.
     * If not specified, the media will be played when the component itself is hovered.
     */
    isParentHovered: bool,
    /**
     * Indicates if the media is presented.
     * For example, in carousel it indicated that this slide is the current one.
     */
    isActive: bool,
    /**
     * Contains more details on the selected asset
     */
    attachment: attachmentType,
    /**
     * IDs info from a gig
     */
    gigIds: shape({
        id: number,
        categoryId: number,
        subCategoryId: number,
        nestedSubCategoryId: number,
    }),
    /**
     * Whether the thumbnail image should be lazy loaded
     */
    lazyLoadThumbnail: bool,
};
