const { isEmpty } = require('lodash');
const { toQueryString } = require('@fiverr-private/futile/bundle');
const pathfinder = require('@fiverr-private/pathfinder');
const { removeEmptyEntries } = require('@fiverr-private/listing_lib');
const { PATHFINDER } = require('../config/filters');
const {
    SEARCH_DID_YOU_MEAN_SOURCE,
    SEARCH_AUTOCOMPLETE_ORIGINAL_TERM,
    SEMANTIC_REDIRECT_SOURCE,
    QUERY_MODIFICATION_REASONS,
    SKILLS_REDIRECT_SOURCE,
} = require('./constants');
const { maybeAdjustRefCurrency } = require('.');

const PageMapping = [
    {
        condition: ({ category, subCategory, nestedSubCategory }) => nestedSubCategory && subCategory && category,
        getResult: ({ category, subCategory, nestedSubCategory }) => ({
            page: PATHFINDER.NESTED_SUB_CATEGORY_PAGE,
            params: { category_id: category, id: subCategory, nested_sub_category_id: nestedSubCategory },
        }),
    },
    {
        condition: ({ category, subCategory, nestedSubCategory }) => !nestedSubCategory && subCategory && category,
        getResult: ({ category, subCategory }) => ({
            page: PATHFINDER.SUB_CATEGORY_PAGE,
            params: { category_id: category, id: subCategory },
        }),
    },
    {
        condition: ({ category, subCategory, nestedSubCategory }) => !nestedSubCategory && !subCategory && category,
        getResult: ({ category }) => ({
            page: PATHFINDER.CATEGORY_PAGE,
            params: { id: category },
        }),
    },
    {
        condition: () => true,
        getResult: () => undefined,
    },
];

const enrichParamsByModificationReason = (reasonForModification, sharedParams = {}) => {
    switch (reasonForModification) {
        case QUERY_MODIFICATION_REASONS.SPELLCHECK: {
            return {
                ...sharedParams,
                source: SEARCH_DID_YOU_MEAN_SOURCE,
                [SEARCH_AUTOCOMPLETE_ORIGINAL_TERM]: sharedParams.query,
            };
        }
        case QUERY_MODIFICATION_REASONS.SEMANTIC_REDIRECT: {
            return {
                ...sharedParams,
                source: SEMANTIC_REDIRECT_SOURCE,
            };
        }
        default: {
            return {};
        }
    }
};

const url = (module.exports = {
    enrichParamsByModificationReason,

    toQueryString(params = {}) {
        return toQueryString(removeEmptyEntries(params));
    },

    rootUrl(queryParams) {
        return pathfinder(PATHFINDER.ROOT, null, formatQueryParams(queryParams));
    },

    getPath({ PATH, PARAMS, QUERY }) {
        return pathfinder(PATH, PARAMS, formatQueryParams(QUERY));
    },

    staticUrl(slugs, queryParams) {
        const mapping = PageMapping.find((page) => page.condition(slugs)).getResult(slugs);

        return mapping ? pathfinder(mapping.page, mapping.params, formatQueryParams(queryParams)) : '';
    },

    searchUrl(params) {
        const {
            activeFilters,
            filter,
            categoryIds,
            isTouch,
            assumedLanguage,
            isRncUser = false,
            abTests,
            utm_source,
            utm_medium,
            utm_campaign,
        } = params;

        const offset = params.page === 1 ? 0 : params.offset;
        const ref = maybeAdjustRefCurrency(params.ref, activeFilters, params.currency);

        const abTestsAsParam = Object.entries(abTests).reduce(
            (result, [key, value], index) =>
                result.concat(`${key}:${value}${index === Object.entries(abTests).length - 1 ? '' : ','}`),
            ''
        );

        const searchParams = removeEmptyEntries({
            query_string: params.query,
            page_size: params.page_size,
            page: params.page,
            offset,
            filter,
            platform: isTouch ? 'mobile_web' : 'desktop',
            browser_type: params.browser_type,
            page_ctx_id: params.page_ctx_id,
            category_id: categoryIds.categoryId,
            sub_category_id: categoryIds.subCategoryId,
            nested_sub_category_id: categoryIds.nestedSubCategoryId,
            user_id: params.currentUser.id,
            request_ip: params.request_ip,
            user_country: params.countryCode ? params.countryCode.toLowerCase() : '',
            traffic_source: params.source,
            shuffle: params.shuffle,
            pro_only: params.pro_only,
            ppc_active: params.ppc_active,
            view_type: params.view_type,
            should_suggest: params.should_suggest,
            studio_only: params.studio_only,
            user_agent: params.user_agent,
            user_guid: params.userGuid,
            assumed_language: assumedLanguage,
            user_allocated_tests: abTestsAsParam,
            utm_source,
            utm_medium,
            utm_campaign,
            is_rnc_user: isRncUser.toString(),
            ref,
        });

        return `${filter}?${url.toQueryString(searchParams)}`;
    },

    staticSearchUrl() {
        return pathfinder(PATHFINDER.SEARCH);
    },

    staticFilterUrl(filterUid, slugs) {
        const baseUrl = `${url.staticUrl(slugs)}`;
        const buyUrl = baseUrl.replace(`${slugs.category}/`, `${slugs.category}/buy/`);

        return `${buyUrl}/${filterUid}`;
    },

    skillUrl(skillSlug) {
        return pathfinder('skill', { skill: skillSlug }, formatQueryParams({ source: SKILLS_REDIRECT_SOURCE }));
    },

    searchInsteadUrl(query, modifiedQueryString, reasonForModification, pageCtxId = '') {
        const sharedParams = {
            query,
            modified_query: modifiedQueryString,
            should_suggest: 'false',
            ref_ctx_id: pageCtxId,
        };

        const searchInsteadQuery = enrichParamsByModificationReason(reasonForModification, sharedParams);

        return pathfinder(PATHFINDER.SEARCH, null, formatQueryParams(searchInsteadQuery));
    },
});
const formatQueryParams = (queryParams = {}) => (!isEmpty(queryParams) ? { query: queryParams } : undefined);
