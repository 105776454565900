import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SellerImage from '../SellerImage';
import SellerLevel from '../SellerLevel';
import SellerLanguage from '../SellerLanguage';
import SellerName from '../SellerName';
import NotableClientsWrapper from '../NotableClientsWrapper';
import './index.scss';
import SellerCountryFlag from '../SellerCountryFlag';
import { useGigListingsContext } from '../../../GigCardListings/context/GigListingsContext';

const SellerInfo = ({
    id,
    name: username,
    displayName,
    url,
    urlTarget,
    imgSrc,
    level,
    isOnline,
    isPromoted,
    sellerLanguages,
    sellerType,
    country,
    gigId,
    showISpeak,
    showCountryFlag,
}) => {
    const [containerRef, setContainerRef] = useState();
    const { experimentalFeatures = {}, notableClients } = useGigListingsContext();
    const { displayCountryFlag } = experimentalFeatures;

    const sellerNotableClients = notableClients?.[id];
    const displayNotableClients = !showISpeak && !!sellerNotableClients && !displayCountryFlag;

    const name = displayName ? displayName : username;

    return (
        <div className="seller-info text-body-2" ref={setContainerRef}>
            <div className="inner-wrapper">
                <SellerImage imgSrc={imgSrc} alt={name} isOnline={isOnline} />
                <div className="seller-identifiers">
                    <div className="seller-name-and-country">
                        <SellerName url={url} urlTarget={urlTarget} name={name} isPromoted={isPromoted} />
                        {showCountryFlag && <SellerCountryFlag country={country} />}
                    </div>
                    {showISpeak ? (
                        <SellerLanguage sellerLanguages={sellerLanguages} sellerType={sellerType} />
                    ) : (
                        <SellerLevel level={level} />
                    )}
                </div>
                {displayNotableClients && (
                    <NotableClientsWrapper
                        sellerInfoContainerCurrent={containerRef}
                        seller={{ id, name: username }}
                        gigId={gigId}
                        className="p-r-0"
                    />
                )}
            </div>
        </div>
    );
};

SellerInfo.propTypes = {
    gigId: PropTypes.number,
    id: PropTypes.number,
    name: PropTypes.string.isRequired,
    displayName: PropTypes.string,
    url: PropTypes.string.isRequired,
    urlTarget: PropTypes.string,
    country: PropTypes.string,
    level: PropTypes.string,
    imgSrc: PropTypes.string.isRequired,
    isOnline: PropTypes.bool,
    sellerLanguages: PropTypes.array,
    sellerType: PropTypes.string,
    isPromoted: PropTypes.bool,
    showISpeak: PropTypes.bool,
    showCountryFlag: PropTypes.bool,
};

SellerInfo.defaultProps = {
    level: '',
    isOnline: false,
    isPromoted: false,
    sellerLanguages: [],
    sellerType: 'freelancer',
};

export default SellerInfo;
