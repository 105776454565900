import { PriceConverter } from '@fiverr-private/listing_lib';

export const getPriceBucketAlias = (priceBucket, currency, translationsService) => {
    const priceConverter = new PriceConverter(currency);
    const { min, max } = priceBucket;
    const minLocalized = priceConverter.getLocalizedPrice(min * 100, { noFractionDigits: true });
    const maxLocalized = priceConverter.getLocalizedPrice(max * 100, { noFractionDigits: true });

    return [
        {
            alias: translationsService.general.translate('search_perseus.filters.global.price_buckets.alias.value', {
                price: maxLocalized,
            }),
            activeFilterAlias: translationsService.general.translate(
                'search_perseus.filters.global.price_buckets.active_filter_alias.value',
                {
                    price: maxLocalized,
                }
            ),
        },
        {
            alias: translationsService.general.translate(
                'search_perseus.filters.global.price_buckets.alias.mid_range',
                {
                    min_price: minLocalized,
                    max_price: maxLocalized,
                }
            ),
            activeFilterAlias: translationsService.general.translate(
                'search_perseus.filters.global.price_buckets.active_filter_alias.mid_range',
                {
                    min_price: minLocalized,
                    max_price: maxLocalized,
                }
            ),
        },
        {
            alias: translationsService.general.translate('search_perseus.filters.global.price_buckets.alias.high_end', {
                price: minLocalized,
            }),
            activeFilterAlias: translationsService.general.translate(
                'search_perseus.filters.global.price_buckets.active_filter_alias.high_end',
                {
                    price: minLocalized,
                }
            ),
        },
    ];
};
