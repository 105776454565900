import { get, isEmpty } from 'lodash';

export const getFlatRepeatedGigs = ({ listings }) =>
    listings.map(({ recommendations }) => get(recommendations, 'repeatedBuyers', [])).flat();

export const getFlatFtbFriendlyGigs = ({ listings }) =>
    listings.map(({ recommendations }) => get(recommendations, 'ftbFriendly', [])).flat();

export const getFlatExpressRecommendationGigs = ({ listings }) =>
    listings.map(({ recommendations }) => get(recommendations, 'express', [])).flat();

export const getFlatChoiceGigs = ({ listings }) =>
    listings.map(({ choiceModalities }) => get(choiceModalities, 'buckets', []).map(({ gig }) => gig)).flat();

export const getFlatSellerWhoSpeaksGigs = ({ listings }) =>
    listings.map(({ sellersWhoSpeak }) => sellersWhoSpeak || []).flat();

export const enrichTranslatedGigs = ({ gigs = [], translationData = {} }) =>
    gigs.map((gig) => ({
        ...gig,
        ...translationData.data.translatedBlob[gig.gig_id],
    }));

export const translateGigsListings = ({ gigsListings, translationData }) =>
    gigsListings.map((gigs) => enrichTranslatedGigs({ gigs, translationData }));

export const getTranslatedListings = ({
    listings,
    gigsListings,
    flatRepeatedBuyer,
    flatFtbFriendly,
    flatExpressRecommendation,
    flatFiverrChoice,
    flatSellersWhoSpeak,
    translationData,
}) => {
    const translatedGigsListings = translateGigsListings({ gigsListings, translationData });
    const translatedRepeatedGigs = enrichTranslatedGigs({ gigs: flatRepeatedBuyer, translationData });
    const translatedFtbFriendly = enrichTranslatedGigs({ gigs: flatFtbFriendly, translationData });
    const translatedExpressGigs = enrichTranslatedGigs({ gigs: flatExpressRecommendation, translationData });
    const translatedChoiceGigs = enrichTranslatedGigs({ gigs: flatFiverrChoice, translationData });
    const translatedSellersWhoSpeakGigs = enrichTranslatedGigs({ gigs: flatSellersWhoSpeak, translationData });

    return listings.map((listing, index) => {
        const translatedListings = {
            ...listing,
            showTranslatedUGC: true,
            gigs: translatedGigsListings[index],
        };

        if (!isEmpty(translatedChoiceGigs)) {
            translatedListings.choiceModalities.buckets.forEach((bucket) => {
                bucket.gig = {
                    ...bucket.gig,
                    ...translatedChoiceGigs.find(({ gig_id }) => gig_id === bucket.gig.gig_id),
                };
            });
        }

        if (!isEmpty(translatedRepeatedGigs)) {
            translatedListings.recommendations.repeatedBuyers = translatedRepeatedGigs;
        }

        if (!isEmpty(translatedFtbFriendly)) {
            translatedListings.recommendations.ftbFriendly = translatedFtbFriendly;
        }

        if (!isEmpty(translatedExpressGigs)) {
            translatedListings.recommendations.express = translatedExpressGigs;
        }

        if (!isEmpty(translatedSellersWhoSpeakGigs)) {
            translatedListings.sellersWhoSpeak = translatedSellersWhoSpeakGigs;
        }

        return translatedListings;
    });
};
