import { Component } from 'react';
import PropTypes from 'prop-types';
import { logger } from '@fiverr-private/obs';
import { metricsService } from '@fiverr-private/listing_lib';

class ErrorBoundary extends Component {
    static getDerivedStateFromError() {
        return {
            hasError: true,
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            hasError: false,
        };
    }

    componentDidCatch(error) {
        metricsService.count('error_boundry');
        logger.error(error);
    }

    render() {
        const { children, fallback } = this.props;
        const { hasError } = this.state;

        if (hasError) {
            return fallback();
        }

        return children;
    }
}

ErrorBoundary.propTypes = {
    fallback: PropTypes.func,
};

ErrorBoundary.defaultProps = {
    fallback: () => null,
};

export default ErrorBoundary;
