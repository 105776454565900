/* eslint-disable react/display-name */
import React from 'react';

export default () => (
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.25 2.5C5.25 1.5335 6.0335 0.75 7 0.75L13 0.750001C13.9665 0.750001 14.75 1.5335 14.75 2.5L14.75 14H15.25C15.6642 14 16 14.3358 16 14.75C16 15.1642 15.6642 15.5 15.25 15.5H0.75C0.335786 15.5 0 15.1642 0 14.75C0 14.3358 0.335786 14 0.75 14H1.25L1.25 6.5C1.25 5.5335 2.0335 4.75 3 4.75L10 4.75C10.9665 4.75 11.75 5.5335 11.75 6.5L11.75 14H13.25L13.25 2.5C13.25 2.36193 13.1381 2.25 13 2.25L7 2.25C6.86193 2.25 6.75 2.36193 6.75 2.5V3.5C6.75 3.91421 6.41421 4.25 6 4.25C5.58579 4.25 5.25 3.91421 5.25 3.5V2.5ZM10.25 14L10.25 6.5C10.25 6.36193 10.1381 6.25 10 6.25L3 6.25C2.86193 6.25 2.75 6.36193 2.75 6.5L2.75 14H10.25ZM4.25 8.69999C4.25 8.28578 4.58579 7.94999 5 7.94999H8C8.41421 7.94999 8.75 8.28578 8.75 8.69999C8.75 9.11421 8.41421 9.44999 8 9.44999H5C4.58579 9.44999 4.25 9.11421 4.25 8.69999ZM4.25 11.7C4.25 11.2858 4.58579 10.95 5 10.95H8C8.41421 10.95 8.75 11.2858 8.75 11.7C8.75 12.1142 8.41421 12.45 8 12.45H5C4.58579 12.45 4.25 12.1142 4.25 11.7Z"
        />
    </svg>
);
