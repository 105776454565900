const { isPlainObject, isArray } = require('lodash');

const createHiddenInput = (name, value) => {
    const inputElement = document.createElement('input');
    inputElement.setAttribute('type', 'hidden');
    inputElement.setAttribute('name', name);
    inputElement.setAttribute('value', value);
    return inputElement;
};

const appendObjectData = (form, inputKey, data) => {
    Object.keys(data).map((key) => form.append(createHiddenInput(`${inputKey}[${key}]`, data[key])));
};

// will append only objects without arrays and array of values
const appendData = (form, inputKey, data) => {
    if (data.length) {
        data.forEach((value, index) => {
            if (isPlainObject(value)) {
                appendObjectData(form, `${inputKey}[${index}]`, value);
            } else {
                form.append(createHiddenInput(`${inputKey}[${index}]`, value));
            }
        });
    }
};

/**
 * Generate the given data as FormData.
 * @example -
 *   payment_funnel_source, checkout
 *   gig_items[0][type], page
 *   gig_items[0][title], Extra Pages
 * @param data - object
 * @returns {HTMLFormElement}
 */

const generateFormData = (data, method, action) => {
    if (!isPlainObject(data)) {
        return null;
    }

    const formElement = document.createElement('form');
    document.body.appendChild(formElement);

    if (method && action) {
        formElement.setAttribute('method', method);
        formElement.setAttribute('action', action);
    }

    Object.keys(data).forEach((key) => {
        const value = data[key];
        if (value) {
            if (isArray(value)) {
                appendData(formElement, key, value);
            } else {
                formElement.append(createHiddenInput(key, value));
            }
        }
    });
    return formElement;
};

module.exports = {
    generateFormData,
};
