import { FILTER } from '@fiverr-private/listing_lib';
import { FILTER_TYPES, GLOBAL_FILTERS, NON_FACET_FILTER_IDS } from '../../config/filters';
import handler from './handlers';
import { sortByOrderAscending } from './_utils';

const refineResults = [FILTER.SUB_CATEGORIES.ID, FILTER.NESTED_SUB_CATEGORIES.ID];

export const getNonFacetActiveFilters = (context, excludedNonFacetFilters) => {
    const nonFacetActiveFilters: unknown[] = [];

    NON_FACET_FILTER_IDS.filter((nonFacetsFilter) => !excludedNonFacetFilters.includes(nonFacetsFilter)).forEach(
        (filterId) => {
            if (context.activeFilters[filterId]) {
                const filterResult = handler[filterId](context);
                nonFacetActiveFilters.push(filterResult);
            }
        }
    );

    return nonFacetActiveFilters;
};

export const enrichFacetFilters = (facetArray, context, flow) => {
    const enrichedFilters: unknown[] = [];

    facetArray
        .filter(([filter]) => !refineResults.includes(filter))
        .forEach(([filter, facetValues]) => {
            const handlerFunction = handler[filter] || handler.customFilter;
            const filterResult = handlerFunction(context, facetValues, filter, flow);
            enrichedFilters.push(filterResult);
        });

    return enrichedFilters;
};

export const combineAndSortFilters = (mapped, context, excludedNonFacetFilters) => {
    const additionalFilters: unknown[] = [];

    NON_FACET_FILTER_IDS.filter((nonFacetsFilter) => !excludedNonFacetFilters.includes(nonFacetsFilter)).forEach(
        (filterId) => {
            const filterResult = handler[filterId](context);
            additionalFilters.push(filterResult);
        }
    );

    return mapped
        .flat()
        .filter((filter) => !!filter && filter.alias !== undefined)
        .concat(additionalFilters)
        .map((filter) => ({
            ...filter,
            type: GLOBAL_FILTERS.includes(filter.id) ? FILTER_TYPES.GLOBAL : FILTER_TYPES.SPECIFIC,
        }))
        .sort(sortByOrderAscending);
};
