import React from 'react';
import { I18n } from '@fiverr-private/fiverr_context';
import { TooltipEllipsis } from '@fiverr-private/tooltip';
import { tracker } from '@fiverr-private/listing_lib';
import { CHOICE_MODALITIES } from '../../../../utils/constants';
import './styles.scss';

const getHeaderKey = (max, min) => {
    if (!min) {
        return 'search_perseus.choice_modalities.less';
    }
    if (!max) {
        return 'search_perseus.choice_modalities.more';
    }
    return 'search_perseus.choice_modalities.between';
};

const localizePrice = (price, converter) =>
    price && converter.getLocalizedPrice(price * 100, { noFractionDigits: true });

const getBucketHeader = ({ type, bucket, converter }) => {
    const { range: { min, max } = {}, name } = bucket;

    switch (type) {
        case CHOICE_MODALITIES.TYPE.PRICE:
            return (
                <I18n
                    k={getHeaderKey(parseInt(max, 10), parseInt(min, 10))}
                    params={{
                        max: localizePrice(max, converter),
                        min: localizePrice(min, converter),
                    }}
                />
            );
        case CHOICE_MODALITIES.TYPE.SERVICE:
            return <TooltipEllipsis>{name}</TooltipEllipsis>;
        default:
            return null;
    }
};

const extractPriceBucketData = ({ bucket }) => {
    const {
        range: { min, max },
    } = bucket;
    return {
        bucketData: {
            'Min Range': !min ? '0' : min,
            'Max Range': !max ? '' : max,
        },
    };
};

const extractServiceBucketData = ({ bucket }) => ({
    bucketData: {
        'Service Name': bucket.name,
    },
});

const track = ({ type, bucket = {}, gig }) => {
    const { bucketNumber } = bucket;
    const eventData = {
        bucketNumber,
        gig,
    };

    switch (type) {
        case CHOICE_MODALITIES.TYPE.PRICE:
            Object.assign(eventData, extractPriceBucketData({ bucket }));
            break;
        case CHOICE_MODALITIES.TYPE.SERVICE:
            Object.assign(eventData, extractServiceBucketData({ bucket }));
            break;
        default:
            return null;
    }
    tracker.clickChoiceModality(eventData);
};

const extractBucket = (type, gigToBucketMap, gig, converter) => {
    const bucket = gigToBucketMap[gig.id] || {};

    return {
        header: getBucketHeader({ type, bucket, converter }),
        tracker: () => track({ type, bucket, gig }),
    };
};

const ModalityCardWrapper = ({
    type = CHOICE_MODALITIES.TYPE.PRICE,
    gig = {},
    gigToBucketMap = {},
    converter,
    children,
}) => {
    const bucketData = extractBucket(type, gigToBucketMap, gig, converter);

    return (
        <div className="modality-card-wrapper" onClick={bucketData.tracker}>
            {bucketData.header && <h6 className="modality-header">{bucketData.header}</h6>}
            {children}
        </div>
    );
};

export default ModalityCardWrapper;
