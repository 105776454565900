import React from 'react';
import classNames from 'classnames';
import { getContext } from '@fiverr-private/fiverr_context';
import { FILTERS_LOCATION } from '@fiverr-private/listing_lib';
import { filterType } from '../../../../../../../types';
import Toggle, { TogglePropTypes } from '../toggle';
import { getOptionById } from '../../../../../util';

const LocalSellerToggle = ({ filter, className, ...rest }) => {
    const { countryCode } = getContext();

    if (!getOptionById(filter, countryCode)) {
        return null;
    }

    return (
        <Toggle
            className={classNames('local-filter', className)}
            position={FILTERS_LOCATION.FLOATING_TOP_RIGHT}
            title="search_perseus.filters.global.is_local_seller.title"
            text="search_perseus.filters.global.is_local_seller.text"
            {...filter}
            {...rest}
        />
    );
};

LocalSellerToggle.propTypes = {
    filter: filterType,
    ...TogglePropTypes,
};

export default LocalSellerToggle;
