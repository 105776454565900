import { ListingAttributes } from '@fiverr-private/listing_types';
import { InvalidCategoryTreeError } from '../../errors';

export const validateCategoryIds = ({ categoryIds = {} }: ListingAttributes): void => {
    const { subCategoryId, nestedSubCategoryId } = categoryIds;

    if (!subCategoryId && !nestedSubCategoryId) {
        throw new InvalidCategoryTreeError('Category ids does not exists', categoryIds);
    }
};
