import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { getContext } from '@fiverr-private/fiverr_context';
import { ChevronRightIcon } from '@fiverr-private/icons';
import { Icon } from '@fiverr-private/fit';
import homeIcon from '../../../assets/icons/home-breadcrumb.svg';

import './index.scss';

const MobileBreadcrumbs = ({ breadcrumbs }) => (
    <Fragment>
        {breadcrumbs.map((breadcrumb) => (
            <li key={breadcrumb.title}>
                <a href={breadcrumb.url}>{breadcrumb.title}</a>
                <Icon size={10} className="chevron-icon-right">
                    <ChevronRightIcon />
                </Icon>
            </li>
        ))}
    </Fragment>
);

const DesktopBreadcrumbs = ({ breadcrumbs }) => {
    const [home, ...steps] = breadcrumbs;
    return (
        <Fragment>
            <li>
                <a className="breadcrumbs-home" href={home.url}>
                    <img src={homeIcon} alt={home.title} />
                </a>
            </li>
            {steps.map((breadcrumb) => (
                <li key={breadcrumb.title}>
                    <span className="divider">/</span>
                    <a href={breadcrumb.url}>{breadcrumb.title}</a>
                </li>
            ))}
        </Fragment>
    );
};

const BreadCrumbs = ({ breadcrumbs, className }) => {
    const { isTouch } = getContext();

    return (
        <ul className={classnames(className, 'breadcrumbs')}>
            {isTouch ? (
                <MobileBreadcrumbs breadcrumbs={breadcrumbs} />
            ) : (
                <DesktopBreadcrumbs breadcrumbs={breadcrumbs} />
            )}
        </ul>
    );
};

const BreadCrumbType = PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
});

BreadCrumbs.propTypes = {
    breadcrumbs: PropTypes.arrayOf(BreadCrumbType),
};

export default BreadCrumbs;
