import React from 'react';
import { string, number, array } from 'prop-types';
import { remove, first } from 'lodash';
import { Icon, Tooltip } from '@fiverr-private/fit';
import { getContext } from '@fiverr-private/fiverr_context';
import { I18n } from '@fiverr-private/i18n-react';
import { HIGHLIGHTS_DATA_KEYS } from '../../../shared/constants';
import { teamMemberType, highlightsDataType } from '../types';
import workedWithYourTeam from '../assets/workedWithYourTeam';

const WorkedWithYourTeam = ({ highlightsData = {} }) => {
    const { userId } = getContext();
    const teamMembersData = highlightsData[HIGHLIGHTS_DATA_KEYS.TEAM_MEMBERS];
    const teamMembers = teamMembersData ? [...teamMembersData] : [];
    const currentUserTeamMember = first(remove(teamMembers, (teamMember) => teamMember?.id === userId));
    let teamMember;

    if (currentUserTeamMember) {
        teamMember = currentUserTeamMember;
    } else {
        teamMember = teamMembers.shift();
    }

    const teamMembersFullNames = teamMembers.map((member) => member.fullName);
    const numOfTeamMembers = teamMembers.length;

    const shouldShowTooltip = numOfTeamMembers > 0;

    return shouldShowTooltip ? (
        <Tooltip
            contentClassName="flex-items-center"
            position={Tooltip.POSITIONS.BOTTOM}
            content={<TooltipContent teamMembersFullNames={teamMembersFullNames} />}
        >
            <Content
                translationKey={currentUserTeamMember ? 'worked_with_you' : 'worked_with_your_team'}
                teamMember={teamMember}
                totalTeamMembers={numOfTeamMembers}
            />
        </Tooltip>
    ) : (
        <Content
            teamMember={teamMember}
            translationKey={currentUserTeamMember ? 'worked_with_you_single' : 'worked_with_your_team_single'}
        />
    );
};

const TooltipContent = ({ teamMembersFullNames = [] }) =>
    teamMembersFullNames.map((name, index) => <div key={index}>{name}</div>);

const Content = ({ translationKey, totalTeamMembers, teamMember = {} }) => {
    const { fullName = '' } = teamMember;

    return (
        <>
            <Icon className="seller-highlight-icon">{workedWithYourTeam}</Icon>
            <I18n k={`listings.highlights.${translationKey}`} params={{ fullName, amountMore: totalTeamMembers }} />
        </>
    );
};

TooltipContent.propTypes = {
    teamMembersFullNames: array,
};

Content.propTypes = {
    translationKey: string,
    totalTeamMembers: number,
    teamMember: teamMemberType,
};

WorkedWithYourTeam.propTypes = {
    highlightsData: highlightsDataType,
};

export default WorkedWithYourTeam;
