import React from 'react';
import { array } from 'prop-types';
import { isEmpty } from 'lodash';
import { Avatar, AvatarImage } from '@fiverr-private/avatar';
import './index.scss';

const MAX_EMPLOYEES_TO_SHOW = 4;

export const EmployeesBigList = ({ employees }) => {
    if (isEmpty(employees)) {
        return null;
    }

    return (
        <div className="flex flex-items-center m-b-24 employees-big-list-container">
            {employees.slice(0, MAX_EMPLOYEES_TO_SHOW).map(({ name, profileImageUrl, role }) => (
                <div key={name} className="flex flex-items-center employee-item">
                    <Avatar size="sm" username={name}>
                        <AvatarImage src={profileImageUrl} />
                    </Avatar>
                    <div className="tbody-6 co-text-darkest text-container">
                        <p className="text-semi-bold one-row-ellipsis">{name}</p>
                        <p className="one-row-ellipsis">{role}</p>
                    </div>
                </div>
            ))}
        </div>
    );
};

EmployeesBigList.propTypes = {
    employees: array,
};
