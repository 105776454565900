import React from 'react';
import { I18n } from '@fiverr-private/i18n-react';

interface AllFiltersButtonContentProps {
    selectedFiltersCount: number;
}

export const AllFiltersButtonContent = ({ selectedFiltersCount }: AllFiltersButtonContentProps) => {
    if (selectedFiltersCount > 0) {
        return (
            <I18n k="search_perseus.side_filters.all_filters_button_title" params={{ count: selectedFiltersCount }} />
        );
    }

    return <I18n k="search_perseus.side_filters.all_filters_button_title_empty" />;
};
