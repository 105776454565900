import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FILTER } from '@fiverr-private/listing_lib';
import { AppContext } from '../../../context/listingsContext';
import { isActive } from '../utils';
import FloatingMenu from './FloatingMenu';
import { FILTER_MENU_CONTENT_MAPPING, MENU_CONTENT_TYPES } from './consts';

const FilterGroup = ({ title, filters, activeFilters, navigationCondition, applyNavigation }) => {
    const { inOwlsSideFiltersTest } = useContext(AppContext);

    const firstFilter = filters[0];
    const { id: firstFilterId } = firstFilter || {};

    if (!filters.length) {
        return null;
    }

    if (inOwlsSideFiltersTest && firstFilterId !== FILTER.SELLER_LANGUAGE.ID) {
        return null;
    }

    return (
        <FloatingMenu
            data={filters.map((filter) => ({ ...filter, component: FILTER_MENU_CONTENT_MAPPING[filter.display_type] }))}
            title={title}
            groupName={filters[0]?.topGrouping}
            activeFilters={activeFilters}
            navigationCondition={navigationCondition}
            applyNavigation={applyNavigation}
            isGrouped
            active={isActive(filters, activeFilters)}
            contentType={MENU_CONTENT_TYPES.FILTERS_GROUP}
            trackOpen
        />
    );
};

FilterGroup.propTypes = {
    title: PropTypes.string.isRequired,
    filters: PropTypes.array.isRequired,
    activeFilters: PropTypes.object,
};

export default FilterGroup;
