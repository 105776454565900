import { VISUAL_FILTERS_DEFAULT_STEP_INDEX } from '../constants';
import { ExposedFilterPropsMapper } from '../types';

export const visualFiltersPropsMapper: ExposedFilterPropsMapper = (context) => {
    const { appFilters: { exposedFilters } = {}, dominateSubCategoryId } = context;
    const { visual_filters: visualFilters } = exposedFilters || {};

    return {
        visualFilters: { visualFiltersList: visualFilters, stepIndex: VISUAL_FILTERS_DEFAULT_STEP_INDEX },
        subCategoryId: dominateSubCategoryId,
    };
};
