import React, { useState } from 'react';
import { noop, isEqual } from 'lodash';
import classnames from 'classnames';
import { Base } from '@fiverr-private/modal';
import { I18n } from '@fiverr-private/i18n-react';
import Header from '../../../../../../../common/tiles/view_all_tile/modals/shared/header';
import List from '../../../../../../../common/tiles/view_all_tile/modals/shared/list';
import Footer from '../../../../../../../common/tiles/view_all_tile/modals/shared/footer';

import styles from './index.module.scss';

const ViewAllModal = ({
    modalTitleKey,
    initialValue,
    modalTitleParams = {},
    currentFilterOptions,
    isMulti,
    onFilterApply = noop,
    className,
}) => {
    const [selected, setSelected] = useState(initialValue);

    const onSelect = (item, checked) => {
        const parsedItem = item.item ?? item;

        // TODO: use lodash isNil or isUndefined?
        const parsedCheck = typeof checked === 'undefined' ? item.checked : checked;

        const newSelected = parsedCheck
            ? [...selected, parsedItem.id]
            : selected.filter((value) => value !== parsedItem.id);
        setSelected(newSelected);
    };

    const onApply = (close) => {
        if (isEqual(selected, initialValue)) {
            close();
            return;
        }

        onFilterApply(selected);
        close();
    };

    const onClear = () => {
        setSelected([]);
    };

    return (
        <Base
            name="search_perseus listings-perseus list-modal"
            trigger={({ open }) => (
                <div onClick={open} className={classnames('tbody-6', styles.showMoreLess, className)}>
                    <I18n k={'search_perseus.filter_tiles.view_all'} />
                </div>
            )}
        >
            {({ close }) => (
                <>
                    <Header close={close} titleKey={modalTitleKey} params={modalTitleParams} />
                    <List items={currentFilterOptions} selected={selected} onSelect={onSelect} isMulti={isMulti} />
                    <Footer onApply={() => onApply(close)} onClear={onClear} />
                </>
            )}
        </Base>
    );
};

export default ViewAllModal;
