export const removeEmptyEntries = (params = {}) => {
    Object.keys(params).forEach((key) => {
        const value = params[key];
        if (value === null || value === undefined || value === '') {
            delete params[key];
        }

        if (Array.isArray(value) && value.length === 0) {
            delete params[key];
        }
    });

    return params;
};
