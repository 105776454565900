import { FILTER, FILTER_COLLECTION_IDS } from '@fiverr-private/listing_lib';
import { translate } from '@fiverr-private/i18n-react';
import { FilterType, LanguagesPairSideFilter } from '@fiverr-private/listing_types';
import { DISPLAY_TYPES, TOP_GROUPING } from '../../../config/filters';
import { ALL_SUBCATEGORY } from '../../../../../lib/utils/side_filters/constants';
import { EXPOSE_CATEGORIES, LOGO_DESIGN_ID } from '../../../service/customFilters';

interface TransformerConfig {
    selector: (filter: FilterType) => boolean;
    filterTransformer?: (filter: any) => FilterType[];
}

// TODO: Mert will do in server-side once he handles the new owls' response..
export const buildFilterCollections = (
    serviceFilters,
    sellerFilters,
    budgetFilters,
    singleFilters,
    service,
    isBusiness,
    subCategory,
    fromSearchFlow
) => [
    ...((fromSearchFlow && [
        {
            id: FILTER_COLLECTION_IDS.SUB_CATEGORY,
            title: '',
            topGrouping: TOP_GROUPING.CATEGORY,
            filters: [
                {
                    id: FILTER.SUB_CATEGORIES.ID,
                    display_type: DISPLAY_TYPES.RADIO,
                    alias: translate('search_perseus.side_filters.collections.sub_category.alias'),
                    options: [
                        {
                            ...subCategory,
                            options: null,
                            id: ALL_SUBCATEGORY,
                        },
                        ...(subCategory?.children || [])
                            .filter((opt) => hasSupply(opt.count))
                            .map((opt) => ({
                                ...opt,
                                id: opt?.params?.nested_sub_category || opt?.params?.sub_category,
                            })),
                    ],
                },
            ],
        },
    ]) ||
        []),
    {
        id: FILTER_COLLECTION_IDS.SERVICE_FILTERS,
        topGrouping: TOP_GROUPING.SERVICE,
        title: translate(buildServiceOptionTitle(service).serviceOptionKey),
        filters: serviceFilters.reduce((filters, filter: FilterType) => {
            const transformerConfig = FILTER_TO_SIDE_FILTER_TRANSFORMERS.find(({ selector }) => selector(filter));

            const newFilters = transformerConfig?.filterTransformer?.(filter) ?? [filter];
            filters.push(...newFilters);

            return filters;
        }, []),
    },
    {
        id: FILTER_COLLECTION_IDS.SELLER_FILTERS,
        topGrouping: TOP_GROUPING.SELLER,
        title: translate(`search_perseus.side_filters.collections.${getSellerFilterKeyTitle(isBusiness)}.alias`),
        filters: sellerFilters,
    },
    {
        id: FILTER_COLLECTION_IDS.BUDGET_FILTERS,
        topGrouping: TOP_GROUPING.BUDGET,
        title: '',
        filters: budgetFilters.sort(({ order: orderA = 0 }, { order: orderB = 0 }) => orderA - orderB),
    },
    ...singleFilters.map(({ id, options, display_type, alias }) => ({
        id,
        title: '',
        filters: [{ id, options, display_type, alias }],
    })),
];

const buildServiceOptionTitle = (subCategory) =>
    subCategory && EXPOSE_CATEGORIES.includes(subCategory.id)
        ? renderTitle(subCategory.id, subCategory.name)
        : { serviceOptionKey: 'search_perseus.side_filters.collections.service_options.alias' };

const renderTitle = (id, name) =>
    id === LOGO_DESIGN_ID
        ? { serviceOptionKey: 'search_perseus.side_filters.collections.logo_service.alias' }
        : { serviceOptionKey: 'search_perseus.side_filters.collections.service.alias', service: name };

const getSellerFilterKeyTitle = (isBusiness: boolean) => (isBusiness ? 'expert' : 'seller');

const hasSupply = (count: number) => count;

const isLanguagesPairFilter = (filter: FilterType) => filter.id === FILTER.LANGUAGES_PAIR.ID;

const isLanguagesPairVisualFilter = (filter: FilterType | LanguagesPairSideFilter): filter is LanguagesPairSideFilter =>
    filter.id === FILTER.LANGUAGES_PAIR.ID &&
    !!(filter as LanguagesPairSideFilter).options?.fromOptions &&
    !!(filter as LanguagesPairSideFilter).options?.toOptions;

const buildLanguagesPairSideFilter = (filter: LanguagesPairSideFilter | FilterType): FilterType[] => {
    if (!isLanguagesPairVisualFilter(filter)) {
        return [];
    }

    return [
        {
            ...filter,
            id: FILTER.LANGUAGES_PAIR.SIDE_FILTER_ID.FROM,
            options: filter.options.fromOptions,
        },
        {
            ...filter,
            id: FILTER.LANGUAGES_PAIR.SIDE_FILTER_ID.TO,
            options: filter.options.toOptions,
        },
    ];
};

const FILTER_TO_SIDE_FILTER_TRANSFORMERS: TransformerConfig[] = [
    {
        selector: isLanguagesPairFilter,
        filterTransformer: buildLanguagesPairSideFilter,
    },
];
