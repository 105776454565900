import React from 'react';
import { array } from 'prop-types';
import SeoMarkupScript, { FAQFormatter, breadcrumbFormatter } from '../../../../shared/components/SeoMarkup';

const SeoMarkups = ({ baseUrl, breadcrumbs = [], faqs = [] }) => (
    <div>
        {!!breadcrumbs.length && <SeoMarkupScript scriptContent={breadcrumbFormatter(breadcrumbs, baseUrl)} />}
        {!!faqs.length && <SeoMarkupScript scriptContent={FAQFormatter(faqs)} />}
    </div>
);

SeoMarkups.propTypes = {
    breadcrumbs: array,
    faqs: array,
};

export default SeoMarkups;
