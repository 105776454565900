import sanitizeSeo from '../sanitize';

const removeUrlParams = (url) => url.split('?')[0];

const breadcrumbFormatter = (breadcrumbs, baseUrl) => {
    const urlPrefix = `https://${baseUrl}`;
    const itemListElement = breadcrumbs.map((item, index) => ({
        '@type': 'ListItem',
        position: index + 1,
        name: item.title,
        item: `${urlPrefix}${removeUrlParams(item.url)}`,
    }));

    const breadcrumbsScript = {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement,
    };

    return sanitizeSeo(breadcrumbsScript);
};

export default breadcrumbFormatter;
