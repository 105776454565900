import React from 'react';
import classNames from 'classnames';
import { I18n } from '@fiverr-private/i18n-react';
import { BI_SOURCES, FULL_INDEX_SIZE, HALF_INDEX_SIZE } from '../../../../utils/constants';

const replaceUrlParam = (paramName, paramValue, url) => {
    url = (url || (window && window.location ? window.location.href : '')).replace('#!', '');

    if (paramValue === null) {
        paramValue = '';
    }
    const pattern = new RegExp(`\\b(${paramName}=).*?(&|#|$)`);

    if (url.search(pattern) >= 0) {
        return url.replace(pattern, `$1${paramValue}$2`);
    }
    url = url.replace(/[?#]$/, '');
    return `${url}${url.indexOf('?') > 0 ? '&' : '?'}${paramName}=${paramValue}`;
};

export const renderPageNumbers = (page, numberOfPages, offset, isTouch) => {
    const { startIndex, endIndex } = calculatePagesIndexes(page, numberOfPages);

    const pagesNum = endIndex - startIndex + 1;
    const pageNumbers = Array(...Array(pagesNum)).map((_, i) => i + startIndex);

    if (isTouch) {
        return (
            <li className="is-touch-page-index">
                <I18n k="search_perseus.pagination.page" /> {page}
            </li>
        );
    }

    return pageNumbers.map((number) => {
        const url = pageUrl(number, offset);
        const pressed = classNames('page-number', {
            'active-page': isActivePage(page, number),
            'tbody-5': !isTouch,
        });

        return (
            <li key={number} className={pressed}>
                {isActivePage(page, number) ? <span>{number}</span> : <a href={url}>{number}</a>}
            </li>
        );
    });
};

export const pageUrl = (pageNumber, offset) => {
    let url = replaceUrlParam('source', BI_SOURCES.PAGINATION);
    url = replaceUrlParam('page', pageNumber, url);

    return offset ? replaceUrlParam('offset', offset, url) : url;
};

const calculatePagesIndexes = (page, numberOfPages) => {
    let startIndex = 1,
        endIndex = FULL_INDEX_SIZE;

    if (numberOfPages < FULL_INDEX_SIZE) {
        return { startIndex, endIndex: numberOfPages };
    }

    // End of the pagination
    if (page + HALF_INDEX_SIZE - 1 >= numberOfPages) {
        startIndex = numberOfPages - FULL_INDEX_SIZE + 1;
        endIndex = numberOfPages;
    } else if (page - HALF_INDEX_SIZE > 1 && page + HALF_INDEX_SIZE - 1 < numberOfPages) {
        // Middle of the pagination
        startIndex = page - HALF_INDEX_SIZE;
        endIndex = page + HALF_INDEX_SIZE - 1;
    }

    return { startIndex, endIndex };
};

const isActivePage = (page, number) => page === number;
