import React, { useEffect } from 'react';
import { tracker } from '@fiverr-private/listing_lib';
import { AppProvider } from './listingsContext';

const GenericListingsAppProvider = ({
    children,
    currency,
    isExpertListings,
    isBusiness,
    categoryIds,
    significantLeafCategories,
    assumedLanguage,
    activeFilters,
    appFilters,
    lowestGigsPrice,
    rollouts,
    subCategoryData,
}) => {
    const listings = [
        {
            context: {
                currency,
            },
        },
    ];

    const appData = {
        isExpertListings,
        isBusiness,
        assumedLanguage,
        rollouts,
    };

    const requestContext = {
        categoryIds,
        significantLeafCategories,
    };

    const trackerContext = {
        reportData: {
            listings: {},
        },
    };

    useEffect(() => {
        tracker.initialize(trackerContext);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AppProvider
            currency={currency}
            listings={listings}
            appData={appData}
            activeFilters={activeFilters}
            requestContext={requestContext}
            appFilters={appFilters}
            lowestGigsPrice={lowestGigsPrice}
            subCategoryData={subCategoryData}
        >
            {children}
        </AppProvider>
    );
};

export default GenericListingsAppProvider;
