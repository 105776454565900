import React from 'react';
import classnames from 'classnames';
import { I18n } from '@fiverr-private/i18n-react';
import { metricsService } from '@fiverr-private/listing_lib';
import formatNumber from '../../../../../../../shared/utils/formatting/number';
import { getSearchTitleData } from '../../utils';
import { useBusinessListingsContext } from '../../Context';
import { resultsCounterTypes } from './types';
import styles from './index.module.scss';

const T_PRRFIX = 'search_perseus.business_listings.results_counter';
const getListingTypeKey = (isExpertListings) => (isExpertListings ? 'experts' : 'services');
const darkerTextTemplate = (text) => <span className="co-text-darker text-semi-bold">{text}</span>;

const SearchResults = ({ resultsCount, searchTitleData, isExpertListings }) => {
    const { displayQuery, modifiedQueryString, nonTranslatedQuery, searchInsteadUrl, query } =
        getSearchTitleData(searchTitleData);
    metricsService.countSearchInstead(modifiedQueryString, nonTranslatedQuery);

    const listingTypeKey = getListingTypeKey(isExpertListings);

    if (!displayQuery) {
        return null;
    }

    return (
        <div className={classnames(styles.searchResults, 'co-text-dark tbody-6')}>
            <I18n
                k={`${T_PRRFIX}.${listingTypeKey}.search`}
                params={{ count: formatNumber(resultsCount), query: displayQuery }}
                templates={{ darker: darkerTextTemplate }}
            />
            {nonTranslatedQuery && (
                <span className="co-text-darker text-semi-bold">
                    {' '}
                    <I18n k={`${T_PRRFIX}.search_translated`} params={{ query: nonTranslatedQuery }} />
                </span>
            )}
            {modifiedQueryString && (
                <span className="co-text-darker text-semi-bold">
                    {' '}
                    <I18n
                        k={`${T_PRRFIX}.search_instead`}
                        params={{ query }}
                        templates={{
                            link: (text) => <a href={searchInsteadUrl}>{text}</a>,
                        }}
                    />
                </span>
            )}
        </div>
    );
};

const SubcategoryResults = ({ resultsCount, subcategoryName, isExpertListings }) => {
    if (!subcategoryName) {
        return null;
    }

    const listingTypeKey = getListingTypeKey(isExpertListings);

    return (
        <div className={classnames(styles.searchResults, 'co-text-dark tbody-6')}>
            <I18n
                k={`${T_PRRFIX}.${listingTypeKey}.subcategory`}
                params={{ count: formatNumber(resultsCount), subcategory: subcategoryName }}
                templates={{ darker: darkerTextTemplate }}
            />
        </div>
    );
};

const ResultsCounter = () => {
    const { resultsCount, isExpertListings, isSearch, subcategoryName, searchQueryData } = useBusinessListingsContext();

    if (resultsCount < 1) {
        return null;
    }

    return isSearch ? (
        <SearchResults
            resultsCount={resultsCount}
            searchTitleData={searchQueryData}
            isExpertListings={isExpertListings}
        />
    ) : (
        <SubcategoryResults
            resultsCount={resultsCount}
            subcategoryName={subcategoryName}
            isExpertListings={isExpertListings}
        />
    );
};

ResultsCounter.propTypes = resultsCounterTypes;

export default ResultsCounter;
