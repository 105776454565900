import React, { useState } from 'react';
import { Button, ButtonProps } from '@fiverr-private/button';
import { Container, Wrap } from '@fiverr-private/layout_components';
import { PlayCircleSolidIcon } from '@fiverr-private/visuals';
import { I18n } from '@fiverr-private/i18n-react';
import { Text } from '@fiverr-private/typography';
import { useAppContext } from '../../../context/listingsContext';
import { HowItWorksVideo } from './HowItWorksVideo';
import { reportBqVideoClickEvent } from './HourlyRate.events';

const T_PREFIX = 'search_perseus.business_listings.banners';

interface HowItWorksButtonProps {
    howItWorksLink?: string;
}

const useVideo = () => {
    const [isShow, setShowVideo] = useState(false);
    const showVideo = () => setShowVideo(true);
    const closeVideo = () => {
        setShowVideo(false);
    };

    const VideoElement = () => <HowItWorksVideo isOpen={isShow} onClose={closeVideo} />;

    return {
        showVideo,
        VideoElement,
    };
};

export const HowItWorksButton: React.FC<
    HowItWorksButtonProps &
        React.ComponentProps<typeof Wrap> & { fullWidth?: ButtonProps['fullWidth']; size?: ButtonProps['size'] }
> = ({ fullWidth, size, ...wrapperProps }) => {
    const { flowName } = useAppContext();
    const { showVideo, VideoElement } = useVideo();

    const onClick = (e: React.SyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();
        showVideo();
        reportBqVideoClickEvent({ flowName });
    };

    return (
        <>
            <Wrap {...wrapperProps}>
                <Button size={size} variant="outline" intent="secondary" onClick={onClick} fullWidth={fullWidth}>
                    <PlayCircleSolidIcon size={typeof size === 'object' ? size.default : size} />
                    <I18n k={`${T_PREFIX}.hourly_banner.how_it_works`} />
                </Button>
            </Wrap>
            <VideoElement />
        </>
    );
};

export const HowItWorksInlineButton: React.FC<HowItWorksButtonProps & React.ComponentProps<typeof Container>> = ({
    ...containerProps
}) => {
    const { flowName } = useAppContext();
    const { showVideo, VideoElement } = useVideo();

    const onClick = (e: React.SyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();
        showVideo();
        reportBqVideoClickEvent({ flowName });
    };

    return (
        <>
            <Container
                display="inlineFlex"
                gap="2"
                alignItems="center"
                cursor="pointer"
                as="a"
                color="inherit"
                onClick={onClick}
                {...containerProps}
            >
                <PlayCircleSolidIcon size="sm" />
                <Text size="b_sm" decoration="underline" color="inherit" fontWeight="semibold" as="span">
                    <I18n k={`${T_PREFIX}.hourly_banner.how_it_works`} />
                </Text>
            </Container>
            <VideoElement />
        </>
    );
};
