const { translate } = require('@fiverr-private/i18n-react');
const { isAgencyGig } = require('../../../utils/agency');
const { DEFAULT_LOCALE } = require('./constants');

const getUgcData = (gigs = [], locale = DEFAULT_LOCALE, hidePrefix) =>
    gigs.reduce((ugcData, gig) => {
        if (!gig.localizedTitle) {
            ugcData[gig.gig_id] = getUgcDataByGig(gig, locale, hidePrefix);
        }
        return ugcData;
    }, {});

const getUgcDataByGig = (gig, locale, hidePrefix) => ({
    translatedTitle: hidePrefix ? gig.title : getTitle(gig, locale),
});

const getTitle = (gig, locale) => {
    const { title } = gig;
    const gigTitleKey = isAgencyGig(gig) ? 'gig_cards.our_agency_will' : 'gig_cards.i_will';

    return translate(gigTitleKey, { locale, params: { title } });
};

module.exports = {
    getUgcData,
};
