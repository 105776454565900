const MINIMUM_FILTER_COUNT = 8;
const MINIMUM_FILTER_OPTIONS = 2;
const TILE_SIZES = {
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large',
};

const DLC_LIST = ['49', '16'];
const QUERY_LIST = [
    'voice',
    'voiceover',
    'ivr',
    'voice over',
    'voicemail greeting',
    'voice actor',
    'voicemail',
    'voice acting',
    'anime voice',
    'voice recording',
    'professional voicemail phone greeting',
    'writer',
    'blog writer',
    'blog article',
    'writing',
    'writing blog articles',
    'blog writing',
    'blog',
    'rewrite article',
    'article',
    'seo content writer',
    'seo blog',
    'seo blog writing',
    'write blog',
    'blog post',
    'create logo',
    'design logo',
    'versatile logo',
    'redesign logo',
    'app logo',
    'name logo',
    'branding',
    'edit logo',
    'typography',
    'logo redesign',
    'logo design business',
    'design a logo',
    'shopify logo',
    'brand logo',
    'logo update',
    'custom logo',
    'logo creation',
    'logo design',
    'logos',
    'creative logo design',
    'logo',
    'favicon',
    'professional logo',
    'branding kit',
    'initial logo',
    'elegant logo',
    'business branding',
    'simple logo',
    'logo edit',
    'unique business logo',
    'watermark',
    'logo designer',
    'simple logo design',
    'team logo',
    'website logo',
    'personal logo',
    'website logo design',
];

module.exports = {
    MINIMUM_FILTER_COUNT,
    MINIMUM_FILTER_OPTIONS,
    TILE_SIZES,
    DLC_LIST,
    QUERY_LIST,
};
