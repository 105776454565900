import { EXPERT_LISTINGS_SOURCE } from '../constants';
import { BI_PAGE_NAMES } from '../../../../../../shared/services/BIEvents/constants';

export const BQ_EVENT_TYPES = {
    CLICK_LISTING_TYPE_TOGGLE: 'click_listing_type_toggle',
};

export const MIXPANEL_EVENT_TYPES = {
    CLICKED_LISTING_TOGGLE: 'Clicked Listings Toggle',
};

export const MIXPANEL_PAGE_NAMES = {
    [EXPERT_LISTINGS_SOURCE]: 'Expert Listings',
    [BI_PAGE_NAMES.LISTINGS]: 'Listings Page',
};
