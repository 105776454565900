import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { I18nProvider } from '@fiverr-private/fiverr_context';
import { Switch } from '@fiverr-private/fit';
import { setCookie, expireCookie, FILTERS_LOCATION, FILTER } from '@fiverr-private/listing_lib';
import { FilterContext } from '../../../../../../context/filterContext';
import { TOP_GROUPING } from '../../../../../../../config/filters';
import ToggleTooltip from '../toggle/ToggleTooltip';
import { hasSupply } from '../utils';

import '../toggle/index.scss';

class BusinessProToggle extends PureComponent {
    constructor(props) {
        super(props);
        const { options = [] } = props;
        const { selected } = options[0] || false;

        this.state = {
            toggled: selected,
        };

        this.handleOnClick = this.handleOnClick.bind(this);
    }

    static contextType = FilterContext;

    setProCookies = (newToggleValue) => {
        if (newToggleValue) {
            setCookie({ name: FILTER.PRO.COOKIE_NAME, value: FILTER.PRO.SELECTED_VALUE });
        } else {
            expireCookie(FILTER.PRO.COOKIE_NAME);
        }
    };

    handleOnClick = () => {
        const { source } = this.props;
        const { navigationCondition, applyNavigation, activeFilters } = this.context;
        const newValue = { [FILTER.PRO.ID]: !this.state.toggled ? FILTER.PRO.SELECTED_VALUE : undefined };

        this.setState((prevState) => {
            const newToggleValue = !prevState.toggled;
            this.setProCookies(newToggleValue);

            return {
                toggled: newToggleValue,
            };
        });

        if (navigationCondition(newValue, activeFilters)) {
            const location = FILTERS_LOCATION.FLOATING_TOP;
            applyNavigation(newValue, activeFilters, TOP_GROUPING.TOGGLERS, location, source);
        }
    };

    render() {
        const { options = [], className } = this.props;
        const { selected } = options[0] || false;

        if (!hasSupply(options)) {
            return null;
        }

        const translationPrefix = 'search_perseus.filters.global.proBusiness';
        const title = `${translationPrefix}.alias`;
        const text = `${translationPrefix}.text`;

        return (
            <div id="pro-toggle">
                <I18nProvider>
                    {({ i18n }) => (
                        <ToggleTooltip
                            title={i18n.t(title)}
                            content={text && i18n.t(text)}
                            educationContent={text && i18n.t(text)}
                            position="left"
                            showNotification
                        >
                            <div className={classNames('toggler-wrapper', className)} onClick={this.handleOnClick}>
                                <Switch
                                    size={Switch.SIZES.SMALL}
                                    checked={this.state.toggled}
                                    value={selected}
                                    onClick={(e) => e.stopPropagation()}
                                />
                                {i18n.t(title)}
                            </div>
                        </ToggleTooltip>
                    )}
                </I18nProvider>
            </div>
        );
    }
}

BusinessProToggle.propTypes = {
    className: PropTypes.string,
    options: PropTypes.array,
};

export default BusinessProToggle;
