import React from 'react';
import { noop } from 'lodash';
import { Button } from '@fiverr-private/fit';
import { I18n } from '@fiverr-private/i18n-react';
import { clickOnLoadMore } from '../../utils/biEvents/events';
import propTypes from './types';

const PaginationButton = ({ onPagination = noop, source, isLoading }) => {
    const handleClick = () => {
        clickOnLoadMore({ pageName: source });
        onPagination();
    };

    return (
        <div className="listings-show-more-wrapper flex flex-center p-t-24 p-b-64">
            <Button
                className="show-more-button"
                color={Button.COLORS.LIGHT_GREY}
                fill={Button.FILLS.GHOST}
                textColor={Button.TEXT_COLORS.DARK_GREY}
                onClick={handleClick}
                loading={isLoading}
            >
                <I18n k="listings.pagination.show_more_experts" />
            </Button>
        </div>
    );
};

PaginationButton.propTypes = propTypes;

export default PaginationButton;
