import React, { createContext } from 'react';

const FilterContext = createContext();

const FilterProvider = ({ activeFilters, navigationCondition, applyNavigation, filters, children }) => (
    <FilterContext.Provider value={{ activeFilters, navigationCondition, filters, applyNavigation }}>
        {children}
    </FilterContext.Provider>
);

export { FilterProvider, FilterContext };
