import React, { useContext } from 'react';
import { object, string } from 'prop-types';
import { useImpressionItem } from '@fiverr-private/impressionable';
import { tracker, EVENTS_MAPPING } from '@fiverr-private/listing_lib';
import TilesCarousel from '../tiles_carousel';
import FilterTile from '../tiles/filter_tile';
import { AppContext } from '../../context/listingsContext';
import { TILE_SIZES } from '../../../utils/filter_builder/visual_filters/constants';
import { BI_EVENT_GROUPS } from '../../../../../shared/services/BIEvents/constants';
import { BI_EVENTS_TYPES, COMPONENT_NAMES, ELEMENT_TYPE } from '../../generic_listings/TagFilters/constants';
import { buildEventEnrichment } from '../../generic_listings/TagFilters/utils';
import { CHIP_MAX_TILES_TO_SHOW, CHIP_SLIDE_PADDING } from '../tiles_carousel/constants';
import { VIEW_ALL_COMPONENT } from './constants';
import Title from './title';

import './index.scss';

export const CarouselContext = React.createContext();

const TopFiltersCarousel = ({ visualFilters = {}, subCategoryId }) => {
    const { appFilters: { filters } = {}, isTouch } = useContext(AppContext);
    const { stepIndex, visualFiltersList = [] } = visualFilters;

    const { id: filterId, options = [], tileSize = TILE_SIZES.MEDIUM } = visualFiltersList[stepIndex] || {};

    const enrichment = buildEventEnrichment({
        type: BI_EVENTS_TYPES.IMPRESSION,
        group: BI_EVENT_GROUPS.USER_IMPRESSION,
        elementName: filterId,
        elementType: ELEMENT_TYPE.VISUAL,
        componentName: COMPONENT_NAMES.VISUAL_FILTERS,
    });

    const [ref] = useImpressionItem({ enrichment });

    if (!visualFiltersList.length) {
        return null;
    }

    const { options: filterOptions = [] } = filters.find((el) => el.id === filterId) || {};

    const stepNum = stepIndex + 1;
    const showViewAllTile =
        filterOptions.length > options.length && !options.find(({ id }) => id === VIEW_ALL_COMPONENT);

    showViewAllTile && options.push({ id: VIEW_ALL_COMPONENT });

    const carouselContext = {
        filterId,
        subCategoryId,
        allFilters: filterOptions,
        tileSize,
    };

    return (
        <div ref={ref} className={'top-filters-carousel'}>
            <CarouselContext.Provider value={{ ...carouselContext }}>
                <Title />
                <div className="top-filters-carousel-wrapper">
                    <TilesCarousel
                        items={options}
                        isTouch={isTouch}
                        TileComponent={FilterTile}
                        tilePadding={CHIP_SLIDE_PADDING}
                        maxTilesToShow={CHIP_MAX_TILES_TO_SHOW}
                        onArrowClick={() => tracker.trackTileFilter(EVENTS_MAPPING.FILTERS_ARROW, filterId, stepNum)}
                    />
                </div>
            </CarouselContext.Provider>
        </div>
    );
};

TopFiltersCarousel.propTypes = {
    visualFilters: object,
    subCategoryId: string,
};

export default TopFiltersCarousel;
