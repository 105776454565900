import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import classNames from 'classnames';
import { Button } from '@fiverr-private/button';
import { clickOnExpertCardEvent } from '../../../../utils/biEvents/events';
import { ELEMENT_META_DATA_NAMES, ELEMENT_NAMES } from '../../../../utils/biEvents/constants';
import { useExpertCardContext } from '../../../../contexts';

const CustomButton = ({
    source,
    size,
    isCta,
    label,
    onClick = noop,
    elementName,
    loading = false,
    disabled = false,
    variant = 'outline',
    icon,
}) => {
    const { cardImpressionId, seller } = useExpertCardContext();

    return (
        <Button
            className={classNames('card-cta-button', { 'is-cta': isCta })}
            intent={isCta ? 'primary' : 'secondary'}
            variant={variant}
            size={size}
            loading={loading}
            disabled={disabled}
            onClick={() => {
                clickOnExpertCardEvent({
                    pageName: source,
                    elementName: elementName ? elementName : ELEMENT_NAMES.CUSTOM_BUTTON_DEFAULT,
                    impressionId: cardImpressionId,
                    elementMetaData: ELEMENT_META_DATA_NAMES.BUTTON,
                    seller,
                });
                onClick();
            }}
        >
            {icon}
            {label}
        </Button>
    );
};

CustomButton.propTypes = {
    source: PropTypes.string,
    size: PropTypes.string,
    isCta: PropTypes.bool,
    label: PropTypes.string,
    onClick: PropTypes.func,
    elementName: PropTypes.string,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    variant: PropTypes.string,
    icon: PropTypes.node,
};

export default CustomButton;
