import { logger } from '@fiverr-private/obs';

export const TRACKING_NAMES = {
    SUBCATEGORY_PAGE: 'Sub Category',
    SEARCH_PAGE: 'Search',
    PROFESSION_GIG_SEARCH_PAGE: 'Profession Based Gigs Search',
    PROFESSION_ROLE_SEARCH_PAGE: 'Profession Based Roles Search',
};

export const PAGE_VIEW_EVENT_TYPES = {
    SUBCATEGORY: 'sub_categories.show',
    SEARCH: 'search.gigs_json',
    PROFESSION_GIG_SEARCH: 'profession.search.gigs',
    PROFESSION_ROLE_SEARCH: 'profession.search.roles',
};

export const PAGE_VIEW_ENRICHMENT_GROUPS = {
    SUBCATEGORY: 'subCategoryPage',
    SEARCH: 'searchPage',
    PROFESSION_GIG_SEARCH: 'professionGigsSearchPage',
    PROFESSION_ROLE_SEARCH: 'professionRolesSearchPage',
};

export const PERFORMANCE_MONITOR_PAGE_NAME = {
    SUBCATEGORY: 'listings_sub_category',
    SEARCH: 'listings_search',
    BUSINESS_EXPERTS_SEARCH: 'business_listings_experts_search',
    BUSINESS_EXPERTS_SUBCATEGORY: 'business_listings_experts_sub_category',
    BUSINESS_GIGS_SEARCH: 'business_listings_gigs_search',
    BUSINESS_GIGS_SUBCATEGORY: 'business_listings_gigs_sub_category',
};

export const LISTINGS_TYPES = {
    SEARCH: 'Search',
};

export const OTHER_SUBCATEGORY_IDS = [
    '9',
    '20',
    '31',
    '38',
    '97',
    '117',
    '118',
    '120',
    '121',
    '122',
    '123',
    '137',
    '240',
    '457',
];

export const LISTINGS_PAGE = 'listings';

export const MAX_NON_HEAD_QUERY_LENGTH = 4;

export class GoSearcherBadRequestError extends Error {
    constructor({ message, stack } = {}, context = {}) {
        super(message);

        this.stack = stack;
        this.name = 'GoSearcherBadRequestError';
        this.isPerseusError = true;

        const {
            page_ctx_id,
            currentUser: user,
            activeFilters,
            flow,
            isTouch,
            abTests,
            query,
            user_agent,
            request_ip,
            originUrl,
            searchUrl,
            filtersUid,
        } = context;

        const contextData = {
            page_ctx_id,
            currentUser: user,
            activeFilters,
            flow,
            isTouch,
            abTests,
            query,
            user_agent,
            request_ip,
            originUrl,
            searchUrl,
            filtersUid,
        };

        logger.warn({ message: `${this.name}: ${message}`, context: contextData, stack });
    }
}

export class UnableToAddInitialPropsError extends Error {
    constructor(error, serviceName) {
        super(error);

        this.name = this.constructor.name;
        this.isPerseusError = true;
        this.serviceName = serviceName;
    }
}

export const MAX_ITEMS_TO_SHOW_NO_RESULTS = 3;
