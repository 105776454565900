import type { ClientInitialProps } from '@fiverr-private/listing_types';
import { GeneralFilterOption } from '../../side_filters/types';
import {
    fetchQuery,
    keepQuery,
    fetchImageIndex,
    keepImageIndex,
    resetImageIndex,
    fetchImageMapper,
    keepImageMapper,
    resetImageMapper,
} from './localStorageUtils';

export const buildImageMapper = ({
    professionFilterCarouselImages,
    options,
    query,
}: {
    professionFilterCarouselImages: ClientInitialProps['professionFilterCarouselImages'];
    options: GeneralFilterOption[];
    query: string;
}): Record<string, string> => {
    let existingRelatedQuery = fetchQuery();
    // Reset image urls if the query has changed
    if (!existingRelatedQuery || existingRelatedQuery !== query) {
        keepQuery(query);
        resetImageIndex();
        resetImageMapper();
        existingRelatedQuery = query;
    }

    let existingImageIndex = fetchImageIndex();
    const existingImageMapper = fetchImageMapper();
    const newImageMapper = options.reduce((accImageMapper, option) => {
        const existingImageLink = existingImageMapper[option.id];
        if (existingImageLink) {
            return {
                ...accImageMapper,
                [option.id]: existingImageLink,
            };
        }

        if (existingImageIndex === null) {
            const randomImageIndex = Math.floor(Math.random() * professionFilterCarouselImages.length);
            existingImageIndex = randomImageIndex;
        } else {
            existingImageIndex = (existingImageIndex + 1) % professionFilterCarouselImages.length;
        }

        const imageLink = professionFilterCarouselImages[existingImageIndex];

        return {
            ...accImageMapper,
            [option.id]: imageLink,
        };
    }, {});

    keepImageIndex(existingImageIndex);
    keepImageMapper(newImageMapper);

    return newImageMapper;
};
