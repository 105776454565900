import { useEffect, useState } from 'react';
import { FlowName, ListingAttributes } from '@fiverr-private/listing_types';
import { buildListingPageUrl } from '@fiverr-private/listing_lib';

export const useClearFiltersUrl = ({
    listingAttributes,
    flowName,
}: {
    listingAttributes: ListingAttributes;
    flowName: FlowName;
}): string => {
    const [clearFiltersUrl, setClearFiltersUrl] = useState('');

    useEffect(() => {
        const url = buildListingPageUrl(
            window,
            {
                ...listingAttributes,
                page: 1,
                offset: 0,
                activeFilters: {},
            },
            flowName
        );

        setClearFiltersUrl(url);
    }, [listingAttributes, flowName]);

    return clearFiltersUrl;
};
