import { getContext } from '@fiverr-private/fiverr_context';
import { FILTER } from '@fiverr-private/listing_lib';
import { getFilterById, getOptionById } from '../util';

export const decideOnSellerToggleFilters = (filters) => {
    const { countryCode } = getContext();
    const locationFilter = getFilterById(filters, FILTER.SELLER_LOCATION.ID);
    const locationOption = getOptionById(locationFilter, countryCode);

    if (locationOption) {
        return { ...locationFilter, options: [locationOption], selectedValue: [countryCode] };
    }
};
