/* eslint-disable react/display-name */
import React from 'react';

export default () => (
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.17031 1.46967L7.81012 4.10948C8.10302 4.40237 8.10302 4.87725 7.81012 5.17014L5.17031 7.80995C4.87742 8.10284 4.40255 8.10284 4.10965 7.80995C3.81676 7.51706 3.81676 7.04218 4.10965 6.74929L5.46913 5.38981H4.33333C2.94066 5.38981 1.75 6.58612 1.75 8.13981C1.75 9.6935 2.94066 10.8898 4.33333 10.8898C4.74755 10.8898 5.08333 11.2256 5.08333 11.6398C5.08333 12.054 4.74755 12.3898 4.33333 12.3898C2.04411 12.3898 0.25 10.4521 0.25 8.13981C0.25 5.8275 2.04411 3.88981 4.33333 3.88981L5.46913 3.88981L4.10965 2.53033C3.81676 2.23744 3.81676 1.76256 4.10965 1.46967C4.40255 1.17678 4.87742 1.17678 5.17031 1.46967ZM11.6667 3.88981C11.2525 3.88981 10.9167 4.2256 10.9167 4.63981C10.9167 5.05402 11.2525 5.38981 11.6667 5.38981C13.0593 5.38981 14.25 6.58612 14.25 8.13981C14.25 9.6935 13.0593 10.8898 11.6667 10.8898H10.7928L12.1523 9.53033C12.4452 9.23744 12.4452 8.76256 12.1523 8.46967C11.8594 8.17678 11.3845 8.17678 11.0916 8.46967L8.45184 11.1095C8.15895 11.4024 8.15895 11.8772 8.45184 12.1701L11.0916 14.8099C11.3845 15.1028 11.8594 15.1028 12.1523 14.8099C12.4452 14.5171 12.4452 14.0422 12.1523 13.7493L10.7928 12.3898H11.6667C13.9559 12.3898 15.75 10.4521 15.75 8.13981C15.75 5.8275 13.9559 3.88981 11.6667 3.88981Z"
        />
    </svg>
);
