const { translate } = require('@fiverr-private/machine_translation');

/**
 * @param {string} url
 * @param {string} pegasusUrl
 * @param {string} text
 * @param {string} locale
 * @param {object} sourceInfo
 * @param {boolean} knownCrawler
 * @param {('HIGH'|'MEDIUM'|'LOW')} timeoutPolicy - only for client translations
 * @param {number} serverTranslationTimeout - used for request to machine translation service, only for server translations
 * @param {object} abTests
 * @return {Promise<*>}
 */
const translateData = async ({
    url,
    pegasusUrl,
    text,
    locale,
    sourceInfo,
    knownCrawler,
    timeoutPolicy,
    serverTranslationTimeout,
    abTests,
}) => {
    const targetLocale = locale.substr(0, 2).toLowerCase();
    const translationPayload = {
        text,
        targetLocale,
        sourceInfo,
        knownCrawler,
        timeoutPolicy,
        timeout: serverTranslationTimeout,
        useServerCache: true,
        abTests,
    };

    if (url) {
        translationPayload.url = `${url}/translation`;
    }

    if (pegasusUrl) {
        translationPayload.pegasusUrl = `${pegasusUrl}/translation`;
    }

    const translationResult = await translate(translationPayload);

    return translationResult;
};

module.exports = {
    translateData,
};
