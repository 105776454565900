const EXPERT_LISTINGS_URL_PARAM = 'expert_listings';
const EXPERT_LISTINGS_PAGINATION_ROUTE = '/listings/experts';
const EXPERT_LISTINGS_CARDS_PER_PAGE = 16;
const EXPERT_LISTINGS_MAXIMUM_CARDS = 64;
const EXPERT_LISTINGS_PAGES_LIMIT = EXPERT_LISTINGS_MAXIMUM_CARDS / EXPERT_LISTINGS_CARDS_PER_PAGE;
const INITIAL_PAGE = 1;

module.exports = {
    EXPERT_LISTINGS_URL_PARAM,
    EXPERT_LISTINGS_PAGINATION_ROUTE,
    EXPERT_LISTINGS_PAGES_LIMIT,
    INITIAL_PAGE,
};
