import { MachineEvent, MachineState } from '../../types';

export const ORCHESTRATOR_TRANSITIONS: Record<MachineState, Partial<Record<MachineEvent, MachineState>>> = {
    initialLoad: {
        LAST_MESSAGE_BELONGS_TO_USER: 'waitingForResponse',
        LAST_MESSAGE_BELONGS_TO_ASSISTANT: 'idle',
        REJECT: 'handleAssistantFailure',
    },
    waitingForResponse: {
        TIMEOUT: 'handleAssistantFailure',
        PLAIN_TEXT_MESSAGE_RECEIVED: 'idle',
        LISTING_ATTRIBUTES_MESSAGE_RECEIVED: 'updatingSearchResults',
        LISTING_ATTRIBUTES_LINKED_MESSAGE_RECEIVED: 'waitingForListingAttributesResponse',
    },
    idle: {
        MESSSAGE_SENT_TO_ASSISTANT: 'waitingForResponse',
    },
    handleAssistantFailure: {},
    updatingSearchResults: {
        LISTING_ATTRIBUTES_LINKED_MESSAGE_RECEIVED: 'idle',
        TIMEOUT: 'retryGettingLinkedTextResponse',
    },
    waitingForListingAttributesResponse: {
        TIMEOUT: 'handleAssistantMessageFailure',
        LISTING_ATTRIBUTES_RECEIVED: 'updatingSearchResults',
    },
    handleAssistantMessageFailure: {
        RETRY_GETTING_RESPONSE: 'waitingForResponse', // This means an active click by the user requesting to retry getting a response from new. TODO: Implement this event handler in useListingsOrchestrator.
    },
    retryGettingLinkedTextResponse: {
        LISTING_ATTRIBUTES_LINKED_MESSAGE_RECEIVED: 'idle',
        TIMEOUT: 'handleAssistantMessageFailure',
    },
};
