import { FILTER, SELECTORS, isDefaultOption, PriceConverter } from '@fiverr-private/listing_lib';
import { convertToUSD } from '..';
import { shouldShowSideFilters } from '../../experiments/sideFilters/util/shouldShowSideFilters';
import { getFilterSelectedOption } from './_utils';

const priceRangeFilterId = FILTER.GIG_PRICE_RANGE.ID;
const priceRangeDefaults = FILTER.GIG_PRICE_RANGE.DEFAULTS;
const languagePairFilterId = FILTER.LANGUAGES_PAIR.ID;
const priceBucketsFilterId = FILTER.PRICE_BUCKETS.ID;
const serviceOfferingsId = FILTER.SERVICE_OFFERINGS.ID;

const aliasTemplates = [
    ({ min, max, UNDER, maxFormatted }) => min === priceRangeDefaults.MIN && max && `${UNDER} ${maxFormatted}`,
    ({ min, max, OVER, minFormatted }) => max === priceRangeDefaults.MAX && min && `${OVER} ${minFormatted}`,
    ({ min, max, minFormatted, maxFormatted }) => min && max && `${minFormatted} - ${maxFormatted}`,
];

const selectedHandlers = {
    [priceRangeFilterId]({ options = [] }, { currency, translationsService, isBusiness, isTouch, abTests }) {
        const { min, max } = options[0] || {};

        if (!(min || max) || (max === priceRangeDefaults.MAX && min === priceRangeDefaults.MIN)) {
            return [];
        }

        const [OVER, UNDER] = translationsService.general.translate(
            'search_perseus.filter_builder.selected.price_range.over_under_budget'
        );

        const converter = new PriceConverter(currency);
        const minFormatted = converter.getLocalizedPrice(convertToUSD(min, currency.rate) * 100, {
            noFractionDigits: true,
        });
        const maxFormatted = converter.getLocalizedPrice(convertToUSD(max, currency.rate) * 100, {
            noFractionDigits: true,
        });
        const template = aliasTemplates.find((template) => template({ min, max }));
        const alias = template && template({ min, max, OVER, UNDER, minFormatted, maxFormatted });
        const showSideFilters = shouldShowSideFilters(isBusiness, isTouch, abTests);

        return [
            {
                id: priceRangeFilterId,
                alias: showSideFilters ? maxFormatted : alias,
                selector: FILTER.GIG_PRICE_RANGE.SELECTOR,
            },
        ];
    },
    [priceBucketsFilterId]({ id, options, selector }) {
        return options.reduce((acc, { id: value, alias, activeFilterAlias, selected }) => {
            selected && acc.push({ id, value, alias: activeFilterAlias || alias, selector });
            return acc;
        }, []);
    },
    [languagePairFilterId]({ id, options: { fromOptions, toOptions } }, { translationsService }) {
        const { FROM, TO } = FILTER.LANGUAGES_PAIR.TYPES;
        return [
            { filterId: id, options: fromOptions, type: FROM },
            { filterId: id, options: toOptions, type: TO },
        ].reduce((acc, { filterId, options, type }) => {
            const selected = getFilterSelectedOption({ id: filterId, options });

            if (!selected.length) {
                return acc;
            }

            const [{ id, alias, value }] = selected;
            acc.push({
                id,
                alias: translationsService.general.translate(`search_perseus.filter_builder.selected.pair.${type}`, {
                    alias,
                }),
                value,
            });
            return acc;
        }, []);
    },
    [serviceOfferingsId]({ id, options = [] }) {
        const selected = getFilterSelectedOption({ id, options });

        return selected.map(({ alias, value }) => ({
            id: value,
            selector: SELECTORS.types.SINGLE,
            value: 'true',
            alias,
        }));
    },
};

export const handler = (filter, additionalArgs) => {
    const selected = selectedHandlers[filter.id]
        ? selectedHandlers[filter.id](filter, additionalArgs)
        : getFilterSelectedOption(filter);

    return selected.filter(({ id, value }) => !isDefaultOption(id, value));
};
