export const findChildElement = (
    parentElement: HTMLElement | null,
    childClassNameSelector: string
): HTMLElement | null => {
    if (!parentElement) {
        return null;
    }

    const childNodes = parentElement.childNodes;

    for (const childNode of childNodes) {
        if (childNode instanceof HTMLElement && childNode.classList.contains(childClassNameSelector)) {
            return childNode;
        }
    }

    return null;
};
