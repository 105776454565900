import React from 'react';
import { bool, string, object } from 'prop-types';
import classNames from 'classnames';
import { SellerRepresentative } from '../../../../../constants/seller';
import Badge from '../../../Components/Badge';
import SellerCountryFlag from '../../../../Components/SellerCountryFlag';
import { excludedBadgesType } from '../../../../../shared/types';
import { SellerAvatar } from '../../../Components/SellerAvatar';
import { SellerName } from '../../../Components/SellerName';
import styles from './index.module.scss';

const SellerInfo = ({
    name: username,
    displayName,
    url,
    urlTarget,
    imgSrc,
    level,
    country,
    isOnline,
    badge,
    showCountryFlag,
    isPromotedGig,
    hideSellerInfo = false,
    type,
    excludedBadges,
}) => {
    if (hideSellerInfo) {
        return null;
    }

    const shouldShowGigTypeBadge = !excludedBadges.includes(badge.type);

    return (
        <div className={classNames('flex-between flex-items-center m-t-12')}>
            <div className={classNames('flex-center', styles.flexGap)}>
                <div className={classNames('flex-center', 'tbody-6', styles.flexGap)}>
                    <SellerAvatar
                        imgSrc={imgSrc}
                        displayName={displayName}
                        username={username}
                        isOnline={isOnline}
                        type={type}
                    />
                    <SellerName
                        displayName={displayName}
                        username={username}
                        url={url}
                        urlTarget={urlTarget}
                        isPromotedGig={isPromotedGig}
                        type={type}
                    />
                </div>
                {showCountryFlag && <SellerCountryFlag country={country} />}
            </div>

            <Badge
                {...badge}
                level={level}
                shouldShowGigTypeBadge={shouldShowGigTypeBadge}
                isAgency={type === SellerRepresentative.AGENCY}
            />
        </div>
    );
};

SellerInfo.propTypes = {
    name: string,
    displayName: string,
    url: string,
    urlTarget: string,
    imgSrc: string,
    level: string,
    country: string,
    isOnline: bool,
    isPromotedGig: bool,
    badge: object,
    showCountryFlag: bool,
    hideSellerInfo: bool,
    type: SellerRepresentative,
    excludedBadges: excludedBadgesType,
};

export default SellerInfo;
