import { isEmpty } from 'lodash';
import { EXPERIMENT_TYPE } from '@fiverr-private/experiments_package';
import { RequestContext } from '@fiverr-private/perseus';
import { isActive } from '@fiverr-private/rollout_js';
import { InitialProps, isGigsListingResult } from '@fiverr-private/listing_types';
import { EXPERIMENTS, ROLLOUTS } from '@fiverr-private/listing_experiments';
import { isSupportedFiverrLanguage } from '../../components/common/recommendations/SellersWhoSpeak/utils';

const isEligibleForAllocation = (): boolean => {
    const {
        experience: { isBusiness },
        initialProps,
        isMobile = false,
    } = RequestContext;

    const { listings, assumedLanguage, shouldShowExpressDelivery } = initialProps as InitialProps;

    if (isEmpty(listings) || !isGigsListingResult(listings[0])) return false;

    const [listing] = listings;

    const { choiceModalities, recommendations, sellersWhoSpeak } = listing;

    const shouldShowModalities = !isEmpty(choiceModalities) && !isEmpty(choiceModalities.buckets);
    const shouldShowRepeatedBuyers = !isEmpty(recommendations) && !isEmpty(recommendations.repeatedBuyers);
    const shouldShowSellersWhoSpeak = !isEmpty(sellersWhoSpeak) && !!isSupportedFiverrLanguage(assumedLanguage);
    const shouldShowFtbFriendly = !isEmpty(recommendations) && !isEmpty(recommendations.ftbFriendly);

    const inOwlsAllocationOwnership = isMobile && !shouldShowExpressDelivery;
    const inHawksAllocationOwnership =
        shouldShowModalities ||
        shouldShowRepeatedBuyers ||
        shouldShowFtbFriendly ||
        shouldShowSellersWhoSpeak ||
        shouldShowExpressDelivery;

    return !isBusiness && !inOwlsAllocationOwnership && inHawksAllocationOwnership;
};

const inRollout = async () => {
    const { userGuid } = RequestContext;

    return await isActive(ROLLOUTS.HAWKS_RESPONSIVE_CAROUSELS, userGuid);
};

export const responsiveCarousels = {
    id: EXPERIMENTS.HAWKS_RESPONSIVE_LISTINGS_CAROUSELS,
    experimentType: EXPERIMENT_TYPE.GUEST,
    numOfGroups: 2,
    shouldAllocate: isEligibleForAllocation,
    inRollout,
};
