import { string, number, arrayOf, shape, bool, func } from 'prop-types';
import { assetType, attachmentType, currencyType } from '../../../shared/types';

const recommendedPackage = shape({
    /**
     * The gig package id
     */
    id: number,
    /**
     * The gig price in dollars
     */
    priceInDollars: number,
    /**
     * Whether the package is extra fast
     */
    extraFast: bool,
});

export const gigTypeObject = {
    /**
     * The title of the gig
     */
    title: string,
    /**
     * The slug of the gig
     */
    slug: string,
    /**
     * The gig package
     */
    recommendedPackage,
    /**
     * Gig image / video / audio assets
     */
    assets: arrayOf(assetType),
    /**
     * Gig audio / video extra details
     */
    attachments: arrayOf(attachmentType),
};

export const gig = shape(gigTypeObject);

export default {
    /**
     * List of gig items
     */
    gigs: arrayOf(gig),
    /**
     * Describe the currency related data
     */
    currency: currencyType,
    /**
     * Callback to run when impression occurs
     */
    onImpression: func,
    /**
     * Source url param
     */
    source: string,
};
