import React from 'react';
import classnames from 'classnames';
import { isEmpty } from 'lodash';
import { arrayOf, bool, func, object } from 'prop-types';
import { getContext } from '@fiverr-private/fiverr_context';
import { CARD_VARIANT } from '../../../shared/constants';
import { cardVariantType } from '../../../shared/types';
import { GigService } from '../GigService';
import { ClickableArea } from '../index';
import { MiniVisuals } from './MiniVisuals';
import { Visuals } from './Visuals';
import { useExtractGigAssets } from './utils';

import './index.scss';

const VisualArea = ({
    cardVariant,
    gigs,
    biEnrichment,
    isHovered,
    setIsHovered,
    clickHandlerArgs,
    currency,
    disableSwipeMode,
    lazyLoadAssets,
}) => {
    const { assets, deliveries, attachments } = useExtractGigAssets(gigs);
    const { isMobile } = getContext();
    const showVisuals = cardVariant === CARD_VARIANT.VISUAL && !isEmpty(gigs);
    const showService = cardVariant === CARD_VARIANT.SERVICE && !isEmpty(gigs);

    // eslint-disable-next-line react/prop-types
    const VisualsWrapper = ({ className, numOfThumbnailsToShow }) => (
        <div className={classnames(className, 'm-t-16')}>
            <Visuals
                assets={assets}
                attachments={attachments}
                deliveries={deliveries}
                numOfThumbnailsToShow={numOfThumbnailsToShow}
                biEnrichment={biEnrichment}
                lazyLoadAssets={lazyLoadAssets}
            />
        </div>
    );

    if (showVisuals) {
        return (
            <>
                <VisualsWrapper className="three-thumbnails" numOfThumbnailsToShow={3} />
                <VisualsWrapper className="two-thumbnails" numOfThumbnailsToShow={2} />
                <MiniVisuals
                    className={classnames(isMobile ? 'm-t-20' : 'm-t-24')}
                    assets={assets}
                    attachments={attachments}
                    deliveries={deliveries}
                    biEnrichment={biEnrichment}
                    isHovered={isHovered}
                    disableSwipeMode={disableSwipeMode}
                    lazyLoadAssets={lazyLoadAssets}
                />
            </>
        );
    }

    if (showService) {
        return (
            <ClickableArea setIsHovered={setIsHovered} clickHandlerArgs={clickHandlerArgs}>
                <GigService className="m-t-24" gig={gigs[0]} currency={currency} />
            </ClickableArea>
        );
    }

    return null;
};

VisualArea.propTypes = {
    cardVariant: cardVariantType,
    gigs: arrayOf(object),
    biEnrichment: object,
    isHovered: bool,
    setIsHovered: func,
    clickHandlerArgs: object,
    currency: object,
    disableSwipeMode: bool,
    lazyLoadAssets: bool,
};

export default VisualArea;
