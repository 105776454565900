const axios = require('axios');
const { metricsService } = require('@fiverr-private/listing_lib');
const { HTTP } = require('../../types/httpMethod');
const { isBrowser } = require('../index');

const UNKNOWN = 'unknown';

const getStatusCode = (error = {}) => {
    const errorCode = error.code?.toString()?.toLowerCase();
    const statusCode = error.response?.status;

    return statusCode || errorCode || UNKNOWN;
};

const monitoredAxios = async ({ url, data, config = {} }) => {
    const { routeKey, method, timeout } = config;
    const NO_CONFIG = {};
    const service = isBrowser() ? 'search_perseus_client' : config.service || NO_CONFIG;
    const serviceRouteKey = `${service.name}.${routeKey}`;

    try {
        const options = {
            url,
            method,
            data,
            timeout: timeout || service.timeout?.[routeKey],
        };

        const startTime = Date.now();
        const response = await axios(options);
        metricsService.measureHttpRequest(serviceRouteKey, response?.status, Date.now() - startTime);

        return response;
    } catch (error) {
        const status = getStatusCode(error);

        if (status) {
            metricsService.countHttpStatusCode(serviceRouteKey, status);
        }

        throw error;
    }
};

const maxios = {
    get: (url, config) =>
        monitoredAxios({
            url,
            config: { ...config, method: HTTP.GET },
        }),

    post: (url, data, config) =>
        monitoredAxios({
            url,
            data,
            config: { ...config, method: HTTP.POST },
        }),

    delete: (url, config) =>
        monitoredAxios({
            url,
            config: { ...config, method: HTTP.DELETE },
        }),
};

module.exports = {
    maxios,
    getStatusCode,
};
