import React from 'react';
import classNames from 'classnames';
import { Skeleton, SkeletonRound, SkeletonSquare } from '@fiverr-private/feedback';
import { getContext } from '@fiverr-private/fiverr_context';
import { Container, Stack } from '@fiverr-private/layout_components';
import styling from './index.module.scss';

interface ExpertCardSkeletonProps {
    isVisual?: boolean;
    forceSmallCard?: boolean;
    cardContainerClassName?: string;
}

const threeVisualsWidth = `calc((100% - 8px * (${3} - 1)) / ${3})`;
const twoVisualsWidth = `calc((100% - 8px * (${2} - 1)) / ${2})`;
const oneVisualWidth = `calc((100% - 8px * (${1} - 1)) / ${1})`;

export const ExpertCardSkeleton = ({ cardContainerClassName, isVisual, forceSmallCard }: ExpertCardSkeletonProps) => {
    const { isMobile } = getContext();

    const cardClassNames = classNames('listings-expert-card-container', cardContainerClassName, {
        'small-card': forceSmallCard,
        'p-24': !isMobile,
        'p-20': isMobile,
    });

    return (
        <Stack className={cardClassNames} gap="6">
            {isMobile ? (
                <div className="m-b-16">
                    <div className="listings-seller-details-component mobile-device">
                        <SkeletonRound size={80} />
                        <div className="seller-details-container">
                            <Stack className="flex flex-items-center seller-responsive-grid-row" gap="2">
                                <Skeleton>
                                    <SkeletonSquare height="16px" width="170px" />
                                </Skeleton>
                                <Skeleton>
                                    <SkeletonSquare height="16px" width="300px" />
                                </Skeleton>
                                <Skeleton>
                                    <SkeletonSquare height="16px" width="150px" />
                                </Skeleton>
                            </Stack>
                        </div>
                    </div>
                    <Skeleton>
                        <SkeletonSquare height="42px" width="110px" />
                    </Skeleton>
                </div>
            ) : (
                <div className="listings-seller-details-component desktop-device">
                    <SkeletonRound size={80} />
                    <div className="seller-details-container">
                        <Stack className="flex seller-responsive-grid-row" gap="2" direction="column">
                            <Skeleton>
                                <SkeletonSquare height="16px" width="170px" />
                            </Skeleton>
                            <Container className={styling.showFromMd}>
                                <Skeleton>
                                    <SkeletonSquare height="16px" width="300px" />
                                </Skeleton>
                            </Container>
                            <Skeleton>
                                <SkeletonSquare height="16px" width="150px" />
                            </Skeleton>
                        </Stack>
                    </div>
                    <Container className={styling.showFromMd}>
                        <Skeleton>
                            <SkeletonSquare height="42px" width="110px" />
                        </Skeleton>
                    </Container>
                </div>
            )}
            <Stack gap="4" direction="column">
                <Stack className="expert-card-body" wrap="wrap" gap="3">
                    <Skeleton>
                        <SkeletonSquare width="150px" height="32px" />
                    </Skeleton>
                    <Skeleton>
                        <SkeletonSquare width="150px" height="32px" />
                    </Skeleton>
                    <Container className={styling.showFromMd}>
                        <Skeleton>
                            <SkeletonSquare width="150px" height="32px" />
                        </Skeleton>
                    </Container>
                </Stack>
                {isVisual && (
                    <>
                        <Stack
                            gap="2"
                            className={classNames('expert-card-visuals-container three-thumbnails', styling.showFromMd)}
                        >
                            {Array.from({ length: 3 }).map((_, index) => (
                                <Stack key={index} style={{ width: threeVisualsWidth }}>
                                    <Skeleton width="100%" key={index}>
                                        <SkeletonSquare width="100%" height="185px" />
                                    </Skeleton>
                                </Stack>
                            ))}
                        </Stack>
                        <Stack gap="2">
                            {Array.from({ length: 2 }).map((_, index) => (
                                <Stack key={index} style={{ width: twoVisualsWidth }} className="two-thumbnails">
                                    <Skeleton width="100%" key={index}>
                                        <SkeletonSquare width="100%" height="135px" />
                                    </Skeleton>
                                </Stack>
                            ))}
                        </Stack>
                        <Container className="gig-assets-carousel-small" style={{ width: oneVisualWidth }}>
                            <Skeleton width="100%">
                                <SkeletonSquare width="100%" height="168px" />
                            </Skeleton>
                        </Container>
                    </>
                )}
            </Stack>
            <Stack justifyContent="spaceBetween">
                <Skeleton>
                    <SkeletonSquare width="209px" height="24px" />
                </Skeleton>
                <Skeleton>
                    <SkeletonSquare width="140px" height="24px" />
                </Skeleton>
            </Stack>
        </Stack>
    );
};
