import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { I18n } from '@fiverr-private/i18n-react';
import localStorage from '../../../utils/localStorage';
import { TOOLTIP_MAPPER } from '../../constants';
import './index.scss';

class DeliveryAssetTooltip extends Component {
    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this); // Ref for the mouse click outside the messasge box
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.state = {
            tooltipHeight: 0,
        };
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        const tooltipHeight = this.wrapperRef.getBoundingClientRect().height;

        // eslint-disable-next-line react/no-did-mount-set-state
        this.setState({ tooltipHeight });
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef(ref) {
        this.wrapperRef = ref;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.props.toggleTooltip(TOOLTIP_MAPPER.DELIVERY_ASSET, false);
        }
    }

    onClick() {
        this.props.toggleTooltip(TOOLTIP_MAPPER.DELIVERY_ASSET, false);
        localStorage.set(localStorage.KEYS.DELIVERY_ASSET, true);
    }

    render() {
        const { showTooltip } = this.props;
        const { tooltipHeight } = this.state;

        const userConfirmedMessage = localStorage.get(localStorage.KEYS.DELIVERY_ASSET);
        const tooltipStyle = { top: `-${tooltipHeight}px` };

        return userConfirmedMessage ? (
            <div
                className={classnames('delivery-asset-tooltip', { show: showTooltip })}
                ref={this.setWrapperRef}
                style={tooltipStyle}
            >
                <span className="arrow-down"></span>
                <p>
                    <I18n k="gig_listings.gig_cards.delivered_asset.tooltip.message" />
                </p>
            </div>
        ) : (
            <div className="delivery-asset-tooltip-explainer" ref={this.setWrapperRef} style={tooltipStyle}>
                <span className="arrow-down"></span>
                <header className="text-bold">
                    <I18n k="gig_listings.gig_cards.delivered_asset.tooltip.title" />
                </header>
                <p className="m-t-8">
                    <I18n k="gig_listings.gig_cards.delivered_asset.tooltip.message" />
                </p>
                <button className="m-t-8 text-bold" onClick={this.onClick}>
                    <I18n k="gig_listings.gig_cards.delivered_asset.tooltip.got_it" />
                </button>
            </div>
        );
    }
}

DeliveryAssetTooltip.propTypes = {
    showTooltip: PropTypes.bool,
    toggleTooltip: PropTypes.func.isRequired,
};

DeliveryAssetTooltip.defaultProps = {
    showTooltip: false,
};

export default DeliveryAssetTooltip;
