import { bigQuery } from '@fiverr-private/obs';
import { FLOWS } from '../../../apps/listings/utils/constants';
import { TRACKING_NAMES } from '../../../apps/listings/constants';
import { DOMAIN, SUB_DOMAIN, BI_EVENT_GROUPS, BI_EVENTS_SUB_GROUPS, BI_ACTION_TYPES } from './constants';

export const biPageView = (eventData) => bqEvent({ group: BI_EVENT_GROUPS.PAGE_VIEW, ...eventData });
export const biImpression = (eventData) => bqEvent({ group: BI_EVENT_GROUPS.USER_IMPRESSION, ...eventData });
export const biUserAction = (eventData) =>
    bqEvent({
        group: BI_EVENT_GROUPS.USER_ACTION,
        subGroup: BI_EVENTS_SUB_GROUPS.WEBSITE_ACTIONS,
        actionType: BI_ACTION_TYPES.CLICK,
        ...eventData,
    });
export const bqEnrichment = ({ type, group, subGroup, actionType, pageCtxId, page, gig, source, payload = {} }) => ({
    type,
    group,
    event_source: source,
    action: {
        type: actionType,
    },
    domain: {
        name: DOMAIN,
    },
    publisher: {
        domain: DOMAIN,
        sub_domain: SUB_DOMAIN,
    },
    page: {
        ctx_id: pageCtxId,
        ...(page && page),
    },
    ...(subGroup && { sub_group: subGroup }),
    ...(gig && { gig }),
    ...payload,
});

export const bqEvent = ({
    type,
    group,
    pageCtxId,
    page,
    actionType = undefined,
    gig = undefined,
    source = undefined,
    payload = {},
}) => {
    const enrichment = bqEnrichment({ type, group, actionType, pageCtxId, page, gig, source, payload });

    bigQuery.send(enrichment);
};

export const trackingPageName = (isExpertListings, flow) => {
    if (isExpertListings) {
        return 'Expert Listings';
    }
    // eslint-disable-next-line default-case
    switch (flow) {
        case FLOWS.BUSINESS_SEARCH:
            return 'Business Search';
        case FLOWS.BUSINESS_CATEGORIES:
            return 'Fiverr Business Category';
        case FLOWS.CATEGORY:
            return TRACKING_NAMES.SUBCATEGORY_PAGE;
        case FLOWS.SEARCH:
            return TRACKING_NAMES.SEARCH_PAGE;
    }
};
