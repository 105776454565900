import { tracker, EVENTS_MAPPING } from '@fiverr-private/listing_lib';
import { filterNavigation } from '../../../../service/clientNavigation';
import { sendBiClickEvent } from '../../../generic_listings/TagFilters/utils';
import { ELEMENT_TYPE } from '../../../generic_listings/TagFilters/constants';

export const applyFilter = ({ filterId, value, activeFilters, stepNum, source, groupName }) => {
    navigate({ filterId, value, activeFilters, stepNum, source, groupName });
};

const navigate = ({ filterId, value, activeFilters, stepNum, source, groupName }) => {
    sendBiClickEvent(filterId, value, ELEMENT_TYPE.VISUAL);
    tracker.trackFilter(EVENTS_MAPPING.FILTERS_APPLY, { id: filterId, value, location: 'Top Tile', stepNum });
    filterNavigation({ [filterId]: value }, activeFilters, null, source, groupName);
};
