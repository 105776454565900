import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { I18n } from '@fiverr-private/i18n-react';
import { Button } from '@fiverr-private/button';
import { AppContext } from '../../../context/listingsContext';
import { MENU_CONTENT_TYPES } from './consts';
import { CONTENT_MAPPING } from './content';

const MenuContent = ({
    id,
    data,
    showClearAll,
    showApply,
    contentType,
    additionalProps,
    applyFilters,
    clearAll,
    contentRef,
    isValidFilter,
    setIsValidFilter,
    closeMenu,
    preventCloseAction,
    maxMenuHeight,
}) => {
    const { appFilters: { disableMinPrice } = {} } = useContext(AppContext);
    const [selected, setSelected] = useState('');
    const Content = CONTENT_MAPPING[contentType] || CONTENT_MAPPING[MENU_CONTENT_TYPES.CHECKBOX_LIST];

    const shouldAddButtonGap = contentType === MENU_CONTENT_TYPES.BUDGET && disableMinPrice;

    const onSelect = (value) => {
        setSelected(value);
    };

    const onClear = () => {
        setSelected('');
    };

    const handleApplyFilters = () => {
        if (isValidFilter) {
            applyFilters();
        }
    };

    useEffect(() => {
        if (contentType === MENU_CONTENT_TYPES.RADIO_LIST) {
            setSelected(data.filter((item) => item.selected).map((item) => item.id)[0]);
        }
    }, [contentType, data]);

    return (
        <div ref={preventCloseAction} className="menu-content">
            <div className={'content-scroll'} style={{ maxHeight: `${maxMenuHeight}px` }}>
                <Content
                    id={id}
                    items={data}
                    ref={contentRef}
                    applyFilters={applyFilters}
                    selected={selected}
                    onSelect={onSelect}
                    onClear={onClear}
                    visibleCheckboxListItems={4}
                    setIsValidFilter={setIsValidFilter}
                    closeMenu={closeMenu}
                    {...additionalProps}
                />
            </div>

            {(showApply || showClearAll) && (
                <div className={classNames('button-row', { 'btn-wide-gap': shouldAddButtonGap })}>
                    {showClearAll && (
                        <Button size="lg" variant="ghost" className="clear-all" color="grey_1000" onClick={clearAll}>
                            <I18n k="search_perseus.general.clear_all" />
                        </Button>
                    )}
                    {showApply && (
                        <Button
                            size="lg"
                            className="apply"
                            color="white"
                            onClick={handleApplyFilters}
                            disabled={!isValidFilter}
                        >
                            <I18n k="search_perseus.general.apply" />
                        </Button>
                    )}
                </div>
            )}
        </div>
    );
};

export default MenuContent;
