import { ActiveFilters } from '../../Types';

export enum Triggers {
    FILTER_COMPONENT_CLICK,
    GLOBAL_APPLY_BUTTON,
}

export type ApplyEvent = (newActiveFilters: ActiveFilters, trigger?: Triggers) => void;

export interface SiderbarFiltersContextType {
    activeTrigger: Triggers;
    applyEvent: ApplyEvent[];
    clearAllEvent: (() => void)[];
    showFacetResultsCounters: boolean;
    newActiveFilters: ActiveFilters;
    setNewActiveFilters: (newActiveFilters: ActiveFilters) => void;
}
