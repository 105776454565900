import { useMemo } from 'react';

// TODO: Consider to stop adding gig to each object
export const useExtractGigAssets = (gigs) =>
    useMemo(() => {
        const carouselItems = gigs.reduce(
            (carouselItems, gig) => {
                carouselItems.assets.push(...gig.assets.map((asset) => ({ ...asset, gig })));
                carouselItems.deliveries.push(
                    ...(gig.filteredDeliveryAttachments || []).map((delivery) => ({
                        ...delivery,
                        gig,
                    }))
                );
                carouselItems.attachments.push(...gig.attachments.map((attachment) => ({ ...attachment, gig })));
                carouselItems.gigIds.push({
                    id: gig.id,
                    categoryId: gig.categoryId,
                    subCategoryId: gig.subCategoryId,
                    nestedSubCategoryId: gig.nestedSubCategoryId,
                });

                return carouselItems;
            },
            {
                assets: [],
                deliveries: [],
                attachments: [],
                gigIds: [],
            }
        );

        return carouselItems;
    }, [gigs]);
