import React, { Fragment, useRef, useState } from 'react';
import classNames from 'classnames';
import { array, func, node, number, oneOfType, string, object, bool } from 'prop-types';
import { getContext } from '@fiverr-private/fiverr_context';
import { Carousel } from '@fiverr-private/orca';
import { CarouselNavigation } from './carousel_navigation';

import './index.scss';

const TilesCarousel = ({
    items = [],
    TileComponent,
    onArrowClick,
    tilePadding,
    maxTilesToShow,
    className,
    breakpoints,
    autoSlidesWidth = true,
    hideDefaultArrows = true,
    arrowsClassName = '',
}) => {
    const { isTouch } = getContext();

    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(items.length >= maxTilesToShow);

    const navigationRef = useRef({});
    const shouldDisplayNavigation = !isTouch && (canScrollRight || canScrollLeft);

    const onScroll = ({ canScrollRight, canScrollLeft }) => {
        setCanScrollRight(canScrollRight);
        setCanScrollLeft(canScrollLeft);
    };

    if (!items.length) {
        return null;
    }

    const carouselClassName = classNames('tiles-carousel', className, {
        'mobile-slider': isTouch,
        'fade-right': canScrollRight,
        'fade-left': canScrollLeft,
    });

    return (
        <div className={carouselClassName}>
            <Fragment>
                <CarouselNavigation
                    shouldDisplayNavigation={shouldDisplayNavigation}
                    navigationRef={navigationRef}
                    canScrollLeft={canScrollLeft}
                    canScrollRight={canScrollRight}
                />
                <Carousel
                    slides={items}
                    breakpoints={breakpoints}
                    swipeMode={isTouch}
                    slidesToShow={maxTilesToShow}
                    Component={TileComponent}
                    onArrowClick={onArrowClick}
                    scrollType={Carousel.SCROLL_TYPE.ROW}
                    slidePadding={tilePadding}
                    onScroll={onScroll}
                    navigationRef={navigationRef}
                    isInfinite={false}
                    arrowsClassName={arrowsClassName}
                    autoSlidesWidth={autoSlidesWidth}
                    hideDefaultArrows={hideDefaultArrows}
                />
            </Fragment>
        </div>
    );
};

TilesCarousel.propTypes = {
    items: array,
    onArrowClick: func,
    TileComponent: oneOfType([node, func]).isRequired,
    tilePadding: number,
    maxTilesToShow: number,
    className: string,
    breakpoints: object,
    autoSlidesWidth: bool,
    hideDefaultArrows: bool,
    arrowsClassName: string,
};

export default TilesCarousel;
