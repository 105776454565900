export const DEFAULT_SORT_OPTIONS = [
    {
        id: 'rating',
        aliasId: 'search_perseus.sorting.best_selling',
    },
    {
        id: 'price_desc',
        aliasId: 'search_perseus.sorting.price_desc',
        isPriceRelated: true,
    },
    {
        id: 'price_asc',
        aliasId: 'search_perseus.sorting.price_asc',
        isPriceRelated: true,
    },
    {
        id: 'new',
        aliasId: 'search_perseus.sorting.newest_arrivals',
    },
];
