export const TAG_TYPE = {
    FILTER: 'filter',
    VIEW_MORE: 'view_more',
    LINK: 'link',
};

export const TAG_ENTITIES = {
    VISUAL_FILTERS: 'visualFiltersTags',
    SLC_FILTERS: 'slcFiltersTags',
    LEAF_CATEGORIES: 'leafCategoriesTags',
};

export const MIN_SLC_TAGS = 2;

export const BI_EVENTS_TYPES = {
    CLICK: 'click_on_exposed_filters',
    IMPRESSION: 'exposed_filters_is_shown',
};

export const BI_ELEMENTS_NAMES = {
    LEAF_CATEGORY: 'leaf_category',
};

export const COMPONENT_NAMES = {
    VISUAL_FILTERS: 'visual_filters',
    METADATA: 'metadata',
};

export const ELEMENT_TYPE = {
    TAGS: 'tags',
    VISUAL: 'visual',
};
