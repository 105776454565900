import { isEmpty } from 'lodash';
import { ActiveFilters } from '@fiverr-private/listing_types';
import { FILTER } from '../../constants';

const DEFAULT_EXCHANGE_RATE = 1;
export const convertByRate = (amount: number, rate = 1): string => {
    const validRate = rate !== 0 ? rate : 1;

    return (amount / validRate).toFixed(2);
};

/**
 * Converts the price range filter to USD based on the currency exchange rate, returns a new object of active filters.
 */
export const normalizeCurrencyInActiveFilters = (
    activeFilters: ActiveFilters | undefined,
    currencyExchangeRate: number
): ActiveFilters => {
    if (isEmpty(activeFilters)) {
        return {};
    }

    const priceRangeId = FILTER.GIG_PRICE_RANGE.ID;
    const priceRangeFilter = activeFilters[priceRangeId];

    if (!priceRangeFilter || currencyExchangeRate === DEFAULT_EXCHANGE_RATE) {
        return activeFilters;
    }

    let convertedPriceRange: [string, string] | null = null;
    if (priceRangeFilter) {
        const [min, max] = priceRangeFilter as [number, number];
        convertedPriceRange = [
            convertByRate(min, currencyExchangeRate),
            max === FILTER.GIG_PRICE_RANGE.DEFAULTS.MAX ? max.toString() : convertByRate(max, currencyExchangeRate),
        ];
    }

    return {
        ...activeFilters,
        ...(convertedPriceRange && { [priceRangeId]: convertedPriceRange }),
    };
};
