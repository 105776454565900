const logger = require('@fiverr-private/obs');

const isUserCameFromFiverrPage = (referrer) => {
    // means it's a direct link and is not came from fiverr
    if (!referrer) {
        return false;
    }

    try {
        const referrerURL = new URL(referrer);
        return referrerURL.host.includes('fiverr.com');
    } catch (error) {
        logger.error(`referrer url is broken: ${referrer}`, error);
        return true; // the default is came from fiverr
    }
};

module.exports = {
    isUserCameFromFiverrPage,
};
