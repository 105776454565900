import axios from 'axios';
import pathfinder from '@fiverr-private/pathfinder';
import { logger } from '@fiverr-private/obs';
import { getContext } from '@fiverr-private/fiverr_context';
import { Chat, Message } from '@fiverr-private/assistant_conversation';
import { ListingChatContext } from '../../../../../lib/types/ListingChatContext';

export interface ChatData {
    messages: Message[];
    chat: Chat;
}

export interface FetchChatDataProps {
    chatId: string;
    chatContext: ListingChatContext;
}

const NEW_PROJECT_ID = 'new_project';

const getNeoChat = async (chatId: string, chatContext: ListingChatContext): Promise<ChatData> => {
    const url = pathfinder('go_converse_post_chat_history');

    const fetchNeoChat: FetchChatDataProps = { chatId, chatContext };
    const { data } = await axios.post<ChatData>(url, fetchNeoChat);

    return data;
};

export const fetchChatDataApi = async (chatId: string, chatContext: ListingChatContext): Promise<ChatData> => {
    if (!chatId || chatId === NEW_PROJECT_ID) {
        return {
            messages: [],
            chat: {} as Chat,
        };
    }

    try {
        const data = await getNeoChat(chatId, chatContext);
        const { messages, chat } = data;

        return { messages, chat };
    } catch (error: any) {
        const { userId, url } = getContext();
        logger.error(error, {
            description: 'Failed while fetching chat data',
            source: 'listings_assistant',
            chatId,
            userId,
            url,
        });

        throw error;
    }
};
