export const SEE_ALL_CAROUSEL_SLIDE = 'see_all';

// TODO: Delete when new listings exp is tested out
export const EXPERTISES_LIST_DEFAULT_LABEL_KEY = 'expert_in';

export const LISTING_TYPES = {
    services: 'services',
    freelancers: 'freelancers',
};

export const LISTING_POSITIONS_TO_SHOW_MODAL = {
    [LISTING_TYPES.services]: 4 * 4 + 1,
    [LISTING_TYPES.freelancers]: 6,
};

export const EXPERTISES_LIST_DEFAULT_LENGTH = {
    lg: 4,
    md: 2,
};

export const EXPERTISE_TYPE = {
    SKILL: 'skill',
    LEAF_CATEGORY_ID: 'leaf_cat_id',
};

export const LISTINGS_LAYOUT = {
    GRID: 'GRID',
    LIST: 'LIST',
};

export const LAYOUT_CLASSNAME = {
    GRID: 'listings-layout-grid',
    LIST: 'listings-layout-list',
};

export const ASSET_SIZES = {
    MOBILE: 't_mobile_web_2',
    DESKTOP: 't_gig_cards_web',
};

export const PLACEHOLDER = 'PLACEHOLDER';

export const PATHFINDER = {
    CONVERSATION_PAGE: 'conversation',
    FREELANCER_PAGE: 'freelancer_page',
    SELLER_ONLINE: 'is_online',
};

export const COLORS = {
    WHITE: '#fff',
};

export const PLATFORMS = {
    MOBILE: 'mobile_web',
    WEB: 'web',
};

export const ACTIVE_FILTER_SEPARATORS = {
    PARAMS: '|',
    PARAM_VALUE: ':',
};

export const FILTER_NAMES = {
    PACKAGE_INCLUDES: 'package_includes',
};

export const ACTIVE_GIG_STATUSES = ['APPROVED', 'OVERBOOKED'];

export const MAX_RATING_COUNT = 1000;
