import React from 'react';
import { Button } from '@fiverr-private/button';
import { Typography } from '@fiverr-private/typography';
import { I18n } from '@fiverr-private/i18n-react';
import { WrapItem } from '@fiverr-private/layout_components';
import { SelectedFilter as SelectedFilterType } from '@fiverr-private/listing_types';
import { clearFiltersUrl } from '../../../../../service/clientNavigation';

import { buttonClassName, textClassName } from './ClearAvtiveFiltersButton.ve.css';

interface ClearActiveFiltersButtonProps {
    selectedFilters: SelectedFilterType[];
}

export const ClearActiveFiltersButton = ({ selectedFilters }: ClearActiveFiltersButtonProps) => {
    if (selectedFilters.length < 1) {
        return null;
    }

    return (
        <WrapItem>
            <Button className={buttonClassName} size="sm" href={clearFiltersUrl()} variant="ghost">
                <Typography className={textClassName} decoration="underline" fontWeight="normal" size="b_md">
                    <I18n k="search_perseus.side_filters.active_filters_clear_all_button" />
                </Typography>
            </Button>
        </WrapItem>
    );
};
