import React, { useContext, useState } from 'react';
import { Button } from '@fiverr-private/button';
import { ModalTrigger, Modal, ModalHeader, ModalFooter, ModalBody, ModalContent } from '@fiverr-private/overlay';
import { Stack } from '@fiverr-private/layout_components';
import { Typography } from '@fiverr-private/typography';
import { I18n } from '@fiverr-private/i18n-react';
import { FILTERS_LOCATION } from '@fiverr-private/listing_lib';
import { MetadataFilterOptionType } from '@fiverr-private/listing_types';
import { TOP_GROUPING } from '../../../../config/filters';
import { filterNavigation } from '../../../../service/clientNavigation';
import { BI_SOURCES } from '../../../../utils/constants';
import { AppContext } from '../../../context/listingsContext';
import { MetadataFilterOptionsColumn } from '../MetadataFilterOptionsColumn/MetadataFilterOptionsColumn';

interface MetadataFilterViewAllTileProps {
    filterId: string;
    filterTitle: string;
    options: MetadataFilterOptionType[];
}

export const MetadataFilterViewAllTile = ({ filterId, options, filterTitle }: MetadataFilterViewAllTileProps) => {
    const { activeFilters } = useContext(AppContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [enrichedOptions, setEnrichedOptions] = useState(options.map((option) => ({ ...option, selected: false })));

    const onOpen = () => {
        setIsModalOpen(true);
    };

    const onClose = () => {
        setIsModalOpen(false);
    };

    const onClearButtonClick = () => {
        setEnrichedOptions((prev) =>
            prev.map((option) => ({
                ...option,
                selected: false,
            }))
        );
    };

    const onApplyButtonClick = () => {
        const changedFilters = { [filterId]: enrichedOptions.filter(({ selected }) => selected).map(({ id }) => id) };

        // TODO: Adjust source and location to what the analysts want
        filterNavigation(
            changedFilters,
            activeFilters,
            FILTERS_LOCATION.EXPOSED_FILTERS,
            BI_SOURCES.METADATA_FILTERS,
            TOP_GROUPING.METADATA_FILTERS
        );
    };

    const onOptionClick = (id: string, value: boolean) => {
        setEnrichedOptions((prev) =>
            prev.map((option) => ({
                ...option,
                ...(option.id === id && { selected: value }),
            }))
        );
    };

    const oddItems = enrichedOptions.filter((_, index) => index % 2 === 0);
    const evenItems = enrichedOptions.filter((_, index) => index % 2 !== 0);

    return (
        <Modal isOpen={isModalOpen} onOpen={onOpen} onClose={onClose}>
            <ModalTrigger>
                <Button variant="ghost">
                    <Typography color="grey_1200" decoration="underline">
                        <I18n k="search_perseus.side_filters.metadata_filters.view_all_button_title" />
                    </Typography>
                </Button>
            </ModalTrigger>
            <ModalContent>
                <ModalHeader>{filterTitle}</ModalHeader>
                <ModalBody>
                    <Stack withDivider direction="row" justifyContent="spaceBetween">
                        <MetadataFilterOptionsColumn items={oddItems} onOptionClick={onOptionClick} />
                        <MetadataFilterOptionsColumn items={evenItems} onOptionClick={onOptionClick} />
                    </Stack>
                </ModalBody>
                <ModalFooter>
                    <Stack justifyContent="spaceBetween" width="100%">
                        <Button variant="ghost" onClick={onClearButtonClick}>
                            <I18n k="search_perseus.side_filters.metadata_filters.modal.clear_all_button_title" />
                        </Button>
                        <Button onClick={onApplyButtonClick}>
                            <I18n k="search_perseus.side_filters.metadata_filters.modal.apply_button_title" />
                        </Button>
                    </Stack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
