import React from 'react';
import classNames from 'classnames';
import GigCardListings from '@fiverr-private/gig_listings/src/entries/GigCardListings';
import { Container, Stack } from '@fiverr-private/layout_components';
import type { Types as ThemeTypes } from '@fiverr-private/theme';
import { useElementSize, useIsClient } from '@fiverr-private/hooks';
import { findChildElement } from '../../utils/findChildElement/findChildElement';
import { useElementScrollable } from '../../hooks/useElementScroll/useElementScrollable';
import { useImpression } from '../../hooks/useImpression/useImpression';
import {
    leftGradientClassName,
    responsiveCarouselClassName,
    rightGradientClassName,
    listingsClassName,
} from './ListingCarousel.ve.css';

const SHOW_CAROUSEL_BREAKPOINT = 1096;
const LOADING_SIZE = 4;

export interface ListingsCarouselProps {
    listings: Record<string, unknown>;
    gigs: never[];
    HeaderContent: React.FC;
    rollouts: Record<string, boolean>;
    onImpression: (e: Event) => void;
    trackView: () => void;
    sourceComponent?: string;
    intersectionThreshold?: number;
    isLoading?: boolean;
    Wrapper?: React.FC;
    className?: string;
    displayISpeak?: boolean;
    listingsContainerClassName?: string;
    backgroundColor?: ThemeTypes.SelectorBackgroundColorsType;
}

export const ListingCarousel: React.FC<ListingsCarouselProps> = ({
    className,
    listings,
    isLoading,
    gigs,
    HeaderContent,
    Wrapper,
    displayISpeak,
    rollouts,
    onImpression,
    trackView,
    listingsContainerClassName: _listingsContainerClassName,
    sourceComponent,
    intersectionThreshold,
    backgroundColor,
}) => {
    const ref = useImpression({
        sourceComponent,
        intersectionThreshold,
        onImpression: (e: Event) => {
            onImpression(e);
            trackView();
        },
    });
    const isClient = useIsClient();
    const parentSize = useElementSize(ref.current);
    const gigListingsContainer = findChildElement(ref.current, listingsClassName);
    const { canScrollLeft, canScrollRight } = useElementScrollable(gigListingsContainer);

    const listingsProps = {
        ...listings,
        gigs,
        isLoading,
        rollouts,
        onImpression,
        Wrapper,
        displayISpeak,
        isExperiential: true,
        size: isLoading ? LOADING_SIZE : gigs.length,
    };

    const shouldShowCarousel = isClient && parentSize.width < SHOW_CAROUSEL_BREAKPOINT;
    const containerClassName = classNames(responsiveCarouselClassName, className, {
        [leftGradientClassName]: shouldShowCarousel && canScrollLeft,
        [rightGradientClassName]: shouldShowCarousel && canScrollRight,
    });
    const listingContainerClassName = classNames(_listingsContainerClassName, listingsClassName);

    return (
        <Container className={containerClassName} backgroundColor={backgroundColor} ref={ref}>
            <Stack paddingX="8" paddingBottom="6">
                <HeaderContent />
            </Stack>
            <GigCardListings {...listingsProps} listingsContainerClassName={listingContainerClassName} />
        </Container>
    );
};
