/**
 * @typedef {Object} TranslationConfig
 * @property {string} translationServiceUrl - URL for the translation service
 * @property {string} translationPegasusUrl - URL for the translation pegasus service
 * @property {string} locale - target locale for the translation
 * @property {Object} sourceInfo - source information for the translation
 * @property {boolean} knownCrawler - flag to indicate if the user is a known crawler
 * @property {Object} abTests
 * @property {number} serverTranslationTimeout - timeout for the machine translation service used in server translations
 * @property {('LOW'|'MEDIUM'|'HIGH')} timeoutPolicy - timeout policy for the machine translation service used in client translations
 */

const axios = require('axios');
const { get, isEmpty } = require('lodash');
const { toQueryString } = require('@fiverr-private/futile/bundle');
const { logger, stats } = require('@fiverr-private/obs');
const { MT_CONTEXTS } = require('@fiverr-private/machine_translation');
const {
    STATS_PREFIX,
    MT_SUCCESS,
    MT_FAILURE,
    TL_FAILURE,
    TL_SUCCESS,
    TL_EXECUTION_TIME,
    GO_LISTINGS_EXECUTION_TIME,
    GO_LISTINGS_FAILURE,
    MT_EXECUTION_TIME,
    TL_TIMEOUT,
} = require('../utils/tracking/constants');
const { validatePhoenixResponse } = require('../service/validation');
const { translateData } = require('../service/translation');
const { getUgcData } = require('../externalUtils/getUgcData/index');
const { getTranslationLayers } = require('./graphql/getTranslationLayers');
const {
    ERRORS,
    STATUS,
    API_ROUTE,
    V2_API_ROUTE,
    SEARCH_ROUTE,
    SEARCH_FILTERS,
    RECOMMENDATIONS_ROUTE,
    EMPTY_RESPONSE,
    TIMEOUT_POLICY,
    DEFAULT_TIMEOUT_POLICY,
    RATING_ALGO_TYPE,
    ERR_TIMEOUT,
} = require('./constants');

const SEARCH_FILTER_KEYS = Object.keys(SEARCH_FILTERS);
const getActiveFilters = (filter) =>
    SEARCH_FILTER_KEYS.filter((key) => filter[key]).reduce((total, key) => {
        total[key] = filter[key];
        return total;
    }, {});

/**
 * @param {string} appName
 * @param {string} listingsPhoenixUrl
 * @param {string} userId
 * @param {TranslationConfig} translationConfig
 * @param {boolean} hidePrefix
 * @param {Object[]} gigs
 * @param {Object} filter
 * @param {Object} abTests
 * @param requestOptions
 * @return {Promise<{gigs: [], translationStatus: number}>}
 */
const fetchGigsByGoSearcherResult = ({
    appName,
    listingsPhoenixUrl,
    userId,
    translationConfig,
    hidePrefix,
    gigs = [],
    filter = {},
    abTests = {},
    requestOptions,
}) => {
    const gigIds = gigs.map(({ gigId }) => gigId);
    const activeFilters = getActiveFilters(filter);

    return fetchGigs({
        listingsPhoenixUrl,
        appName,
        fetchRoute: SEARCH_ROUTE,
        gigIds,
        getQueryParams: () => ({
            gig_ids: gigIds.join(),
            user_id: userId,
            ...activeFilters,
        }),
        translationConfig,
        hidePrefix,
        abTests,
        requestOptions,
    }).then((result) => {
        result.gigs = gigs
            .map((searchGig) => {
                const recommendedPackage = get(searchGig, 'packages.recommended');
                const gig = result.gigs.find(({ gig_id }) => gig_id === searchGig.gigId);

                const { attachments_ids, gigQueryParams } = searchGig;

                // If search recommend on some attachment based on gallery tagging
                if (!isEmpty(attachments_ids)) {
                    gigQueryParams.attachment_id = attachments_ids[0];
                }

                return {
                    ...searchGig,
                    ...gig,
                    u_id: `${searchGig.gigId}_${searchGig.pos}`,
                    ...(recommendedPackage && extractRecommendedPackage(recommendedPackage)),
                };
            })
            .filter(({ gig_id }) => gig_id); // filter out gigs that aren't in phoenix response

        return result;
    });
};

const extractRecommendedPackage = ({ id, price, extra_fast }) => ({
    ...(id && { package_i: id }),
    ...(price && { price_i: price }),
    ...(extra_fast && { extra_fast }),
});

const fetchGigsWithPersonalization = ({
    appName,
    listingsPhoenixUrl,
    gigIds = [],
    userId,
    filter = {},
    gigEnrichment,
    translationConfig,
    abTests = {},
    requestOptions,
}) => {
    const activeFilters = getActiveFilters(filter);

    const activeFiltersGigQueryParams = Object.keys(activeFilters).reduce((map, key) => {
        map[SEARCH_FILTERS[key]] = activeFilters[key];
        return map;
    }, {});

    return fetchGigs({
        listingsPhoenixUrl,
        appName,
        fetchRoute: SEARCH_ROUTE,
        gigIds,
        gigEnrichment: (id) => {
            const { gigQueryParams, ...consumerEnrichment } = gigEnrichment ? gigEnrichment(id) : {};
            return { ...consumerEnrichment, gigQueryParams: { ...gigQueryParams, ...activeFiltersGigQueryParams } };
        },
        getQueryParams: () => ({
            gig_ids: gigIds.join(),
            user_id: userId,
            ...activeFilters,
        }),
        translationConfig,
        abTests,
        requestOptions,
    });
};

const fetchGigsWithPackages = ({
    appName,
    listingsPhoenixUrl,
    gigIds = [],
    userId,
    packageIds = [],
    gigEnrichment,
    translationConfig,
    abTests = {},
    requestOptions,
    fetchRoute = RECOMMENDATIONS_ROUTE,
}) => {
    packageIds = packageIds.map((packageId) => (validatePackageId(packageId) ? packageId : 1));

    return fetchGigs({
        listingsPhoenixUrl,
        appName,
        fetchRoute,
        gigIds,
        packageIds,
        gigEnrichment,
        getQueryParams: () => ({
            gig_ids: gigIds.join(),
            package_ids: packageIds.join(),
            user_id: userId,
        }),
        translationConfig,
        abTests,
        requestOptions,
    });
};

const fetchGigsByRecommendationsResult = ({
    appName,
    listingsPhoenixUrl,
    gigs,
    userId,
    gigEnrichment,
    translationConfig,
    abTests = {},
    requestOptions,
}) => {
    const gigIds = gigs.map((gig) => gig.gig_id);
    const packageIds = gigs.map((gig) => (validatePackageId(gig.pckg_id) ? gig.pckg_id : 1));
    const enrichmentMap = gigs.reduce((map, gig) => {
        const mod = gig.mod;
        const algType = gig.algorithm_type;
        const algScore = gig.algorithm_score;
        const typeParts = ['recommended', algType, mod];

        map[gig.gig_id] = {
            type: gig.type,
            auction: gig.auction,
            gigQueryParams: {
                mod,
                is_pro: gig.is_pro,
                context_alg: algType,
                context: 'recommendation',
                recommended_package_id: gig.recommended_package_id,
                recommended_price: gig.recommended_price,
                price_type: gig.price_type,
            },
            impressionData: {
                // Temporarily maintaining both fields
                type: typeParts.filter((val) => val !== undefined).join('|'),
                algo_type: typeParts.filter((val) => val !== undefined).join('|'),
                alg: {
                    score: algScore,
                },
            },
        };
        return map;
    }, {});

    return fetchGigs({
        listingsPhoenixUrl,
        appName,
        fetchRoute: RECOMMENDATIONS_ROUTE,
        gigIds,
        packageIds,
        gigEnrichment: (id) => {
            const consumerEnrichment = gigEnrichment ? gigEnrichment(id) : {};

            const recommendationsEnrichment = enrichmentMap[id];
            return {
                ...recommendationsEnrichment,
                ...consumerEnrichment,
                gigQueryParams: { ...recommendationsEnrichment.gigQueryParams, ...consumerEnrichment.gigQueryParams },
                impressionData: { ...recommendationsEnrichment.impressionData, ...consumerEnrichment.impressionData },
            };
        },
        getQueryParams: () => ({
            gig_ids: gigIds.join(),
            package_ids: packageIds.join(),
            user_id: userId,
        }),
        translationConfig,
        abTests,
        requestOptions,
    });
};

const shouldTranslate = (translationConfig) => {
    if (!translationConfig) {
        return false;
    }

    const { knownCrawler, allowTranslationForCrawlers = false } = translationConfig;
    return !knownCrawler || allowTranslationForCrawlers;
};

/**
 * @param {string} listingsPhoenixUrl
 * @param {string} appName
 * @param {string} fetchRoute
 * @param {number[]} gigIds
 * @param packageIds
 * @param gigEnrichment
 * @param getQueryParams
 * @param {TranslationConfig} translationConfig
 * @param {boolean} hidePrefix
 * @param requestOptions
 * @return {Promise<{translationStatus: *, gigs: *}|{gigs: []}>}
 */
const fetchGigs = async ({
    listingsPhoenixUrl,
    appName,
    fetchRoute,
    gigIds,
    packageIds,
    gigEnrichment,
    getQueryParams,
    translationConfig,
    hidePrefix,
    requestOptions,
}) => {
    const validationResult = validateFetchParams(listingsPhoenixUrl, gigIds, packageIds);
    if (!validationResult.valid) {
        throw `Listings Fetcher validation: ${validationResult.error}`;
    }

    if (gigIds.length === 0) {
        return EMPTY_RESPONSE;
    }

    const additionalParams = { rating_algo_type: RATING_ALGO_TYPE };
    const queryString = toQueryString(removeEmptyEntries({ ...getQueryParams(), ...additionalParams }));
    const route = buildPhoenixUrl(listingsPhoenixUrl, fetchRoute, queryString);

    const [gigs, translationLayers] = await Promise.all([
        getListingsPhoenix(appName, route, requestOptions),
        fetchTranslationLayers(translationConfig, gigIds),
    ]);

    const fetchGigsResult = {
        gigs,
        translationStatus: undefined,
    };

    if (gigEnrichment) {
        fetchGigsResult.gigs = gigs.map((gig) => ({ ...gig, ...gigEnrichment(gig.gig_id) }));
    }

    if (Object.keys(translationLayers)) {
        fetchGigsResult.gigs = fetchGigsResult.gigs.map((gig) => {
            const translationLayer = translationLayers[gig.gig_id];
            return translationLayer ? { ...gig, ...translationLayer } : gig;
        });
    }

    if (shouldTranslate(translationConfig)) {
        const {
            translationServiceUrl,
            translationPegasusUrl,
            locale,
            sourceInfo,
            knownCrawler,
            abTests,
            serverTranslationTimeout,
        } = translationConfig;
        let { timeoutPolicy = DEFAULT_TIMEOUT_POLICY } = translationConfig;

        if (validateTranslationParams({ translationServiceUrl, translationPegasusUrl, targetLocale: locale })) {
            if (!validTimeOutPolicy(timeoutPolicy)) {
                timeoutPolicy = DEFAULT_TIMEOUT_POLICY;
            }

            const { translatedGigs, status } = await translateGigs({
                gigs: fetchGigsResult.gigs,
                targetLocale: locale,
                translationServiceUrl,
                translationPegasusUrl,
                sourceInfo,
                knownCrawler,
                timeoutPolicy,
                serverTranslationTimeout,
                hidePrefix,
                abTests,
            });

            reportMachineTranslationStatus(status);

            fetchGigsResult.gigs = translatedGigs;
            fetchGigsResult.translationStatus = status;
        }
    }

    const gigsOrderMap = gigIds.reduce((map, gigId, index) => {
        map[gigId] = index;
        return map;
    }, {});

    fetchGigsResult.gigs.sort((gig1, gig2) => gigsOrderMap[gig1.gig_id] - gigsOrderMap[gig2.gig_id]);
    return fetchGigsResult;
};

const getListingsPhoenix = async (appName, url, requestOptions = {}) => {
    const fetchStartTime = Date.now();

    const response = await axios.get(url, {
        headers: {
            ...(appName && { 'User-Agent': appName }),
        },
        ...requestOptions,
    });

    const responseTime = Date.now() - fetchStartTime;

    stats.time(STATS_PREFIX, GO_LISTINGS_EXECUTION_TIME, responseTime);

    if (response.status !== 200) {
        const error = new Error(
            `Failed in ListingsFetcher: url: ${url} ,status: ${response.status}, text: ${response.statusText}`
        );
        doLog(error);
        stats.count(STATS_PREFIX, GO_LISTINGS_FAILURE);
        throw error;
    }

    return validatePhoenixResponse(response.data, (message) => doLog(message, 'warn'));
};

const fetchTranslationLayers = async (translationConfig, gigIds, timeout) => {
    const gigsPhoenixUrl = translationConfig?.gigsPhoenixUrl;

    if (!gigsPhoenixUrl) {
        return {};
    }

    const fetchStartTime = Date.now();
    try {
        const data = await getTranslationLayers(gigsPhoenixUrl, gigIds, timeout);
        const responseTime = Date.now() - fetchStartTime;
        stats.time(STATS_PREFIX, TL_EXECUTION_TIME, responseTime);

        const gigs = data?.gigs || [];

        stats.count(STATS_PREFIX, TL_SUCCESS);

        return gigs.reduce(
            (map, gig) => ({
                ...map,
                [gig.id]: {
                    localizedTitle: gig.localizedTitle.localized,
                    localizedAssets: gig.attachments
                        .filter((asset) => asset.id)
                        .map((asset) => ({
                            id: asset.id,
                            type: asset.type,
                            previewUrl: asset.previewUrls.find(
                                (previewUrl) => previewUrl.transformation === 'GIG_ATTACHMENT_MEDIUM_PREVIEW'
                            )?.url,
                            mediaUrl: asset.mediaUrl,
                        })),
                },
            }),
            {}
        );
    } catch (error) {
        const warn = new Error(`Failed in TranslationLayerFetcher: url: ${gigsPhoenixUrl}, error: ${error}`);

        if (error?.type === ERR_TIMEOUT) {
            stats.count(STATS_PREFIX, TL_TIMEOUT);
        }

        doLog(warn, 'warn');
        stats.count(STATS_PREFIX, TL_FAILURE);
        return {};
    }
};

const buildPhoenixUrl = (listingsPhoenixUrl, route, query) => {
    const endsWithSlash = listingsPhoenixUrl.endsWith('/');
    const separator = !endsWithSlash ? '/' : '';
    const DYNAMIC_API_ROUTE = route === SEARCH_ROUTE ? V2_API_ROUTE : API_ROUTE;

    return `http://${listingsPhoenixUrl}${separator}${DYNAMIC_API_ROUTE}/${route}?${query}`;
};

const validateFetchParams = (listingsPhoenixUrl, gigIds, packageIds = []) => {
    const result = {};

    if (!listingsPhoenixUrl || typeof listingsPhoenixUrl !== 'string') {
        result.error = ERRORS.LISTINGS_PHOENIX_URL_MISSING;
    }

    const hasPackages = packageIds.length > 0;
    if (hasPackages && gigIds.length !== packageIds.length) {
        result.error = ERRORS.PACKAGES_MISS_MATCH_GIGS;
    }

    result.valid = result.error === undefined;
    return result;
};
const validatePackageId = (packageId) => Number(packageId) === packageId && packageId >= 1 && packageId <= 3;

const removeEmptyEntries = (object = {}) => {
    Object.keys(object).forEach((key) => {
        const value = object[key];
        (value === null || value === undefined || value === '') && delete object[key];
    });

    return object;
};

const doLog = (err, level = 'error') => logger[level](err, { flow: 'listings-sdk-fetch' });

/**
 * @param {string} translationServiceUrl
 * @param {string} translationPegasusUrl
 * @param {Object[]} gigs
 * @param {string} targetLocale
 * @param {Object} sourceInfo
 * @param {boolean} knownCrawler
 * @param {boolean} hidePrefix
 * @param {Object} abTests
 * @param {('HIGH'|'MEDIUM'|'LOW')} timeoutPolicy - only for client translations
 * @param {number} serverTranslationTimeout - only for server translations
 * @return {Promise<{translatedGigs: [], status: number}>}
 */
const translateGigs = async ({
    translationServiceUrl,
    translationPegasusUrl,
    gigs = [],
    targetLocale,
    sourceInfo,
    knownCrawler,
    hidePrefix,
    abTests,
    timeoutPolicy,
    serverTranslationTimeout,
}) => {
    if (!gigs.length) {
        return { translatedGigs: gigs, status: STATUS.BAD_REQUEST };
    }

    const defaultResponse = { translatedGigs: gigs };

    const ugcData = getUgcData(gigs, targetLocale, hidePrefix);

    if (Object.keys(ugcData).length === 0) {
        return { ...defaultResponse, status: STATUS.OK };
    }
    const fetchStartTime = Date.now();

    try {
        const { translatedBlob, status } = await translateData({
            url: translationServiceUrl,
            pegasusUrl: translationPegasusUrl,
            text: ugcData,
            locale: targetLocale,
            sourceInfo,
            knownCrawler,
            abTests,
            timeoutPolicy,
            serverTranslationTimeout,
        });

        const responseTime = Date.now() - fetchStartTime;
        stats.time(STATS_PREFIX, MT_EXECUTION_TIME, responseTime);

        if (status === STATUS.OK) {
            return {
                translatedGigs: gigs.map((gig) => ({
                    ...gig,
                    ...translatedBlob[gig.gig_id],
                })),
                status,
            };
        }

        return { ...defaultResponse, status };
    } catch (error) {
        doLog(
            new Error(
                `${ERRORS.MACHINE_TRANSLATION_FAILED}: response status : ${
                    error.response?.status || STATUS.INTERNAL_SERVER_ERROR
                }`
            )
        );

        return { ...defaultResponse, status: error.response?.status || STATUS.INTERNAL_SERVER_ERROR };
    }
};

const validateTranslationParams = ({ translationServiceUrl, translationPegasusUrl, targetLocale }) => {
    const validation_errors = [];

    !targetLocale &&
        validation_errors.push(new Error(`${ERRORS.UGC_TRANSLATION_VALIDATIONS}: targetLocale: ${targetLocale}`));

    if (!translationServiceUrl && !translationPegasusUrl) {
        const error = new Error(
            `${ERRORS.UGC_TRANSLATION_VALIDATIONS}: translationServiceUrl: ${translationServiceUrl}, translationPegasusUrl: ${translationPegasusUrl}`
        );
        validation_errors.push(error);
        throw error;
    }
    validation_errors.forEach((err) => doLog(err));

    return !validation_errors.length;
};

const validTimeOutPolicy = (timeoutPolicy) => {
    const isValidTimeOut = TIMEOUT_POLICY.includes(timeoutPolicy);
    if (!isValidTimeOut) {
        doLog(new Error(`${ERRORS.UGC_TRANSLATION_VALIDATIONS}: timeoutPolicy: ${timeoutPolicy}`));
    }

    return isValidTimeOut;
};

const translateUgcData = async ({
    translationServiceUrl,
    translationPegasusUrl,
    ugcData = {},
    targetLocale,
    sourceInfo = {},
    knownCrawler = false,
    timeoutPolicy = DEFAULT_TIMEOUT_POLICY,
    timeout,
    abTests,
}) => {
    const defaultResponse = { translatedBlob: ugcData, translationStatus: STATUS.BAD_REQUEST };
    const gigListingsSourceInfo = { ...sourceInfo, context: sourceInfo.context || MT_CONTEXTS.GENERAL };

    if (isEmpty(ugcData) || knownCrawler) {
        return defaultResponse;
    }
    if (!validateTranslationParams({ translationServiceUrl, translationPegasusUrl, targetLocale })) {
        return defaultResponse;
    }
    if (!validTimeOutPolicy(timeoutPolicy)) {
        timeoutPolicy = DEFAULT_TIMEOUT_POLICY;
    }

    const { translatedBlob, status } = await translateData({
        url: translationServiceUrl,
        pegasusUrl: translationPegasusUrl,
        text: ugcData,
        locale: targetLocale,
        sourceInfo: gigListingsSourceInfo,
        knownCrawler,
        timeoutPolicy,
        serverTranslationTimeout: timeout,
        abTests,
    });

    if (status === STATUS.OK) {
        return { translatedBlob, translationStatus: status };
    }

    return { translatedBlob: ugcData, translationStatus: status };
};

const reportMachineTranslationStatus = (translationStatus) => {
    if (translationStatus === STATUS.OK) {
        stats.count(STATS_PREFIX, MT_SUCCESS);
    } else {
        stats.count(STATS_PREFIX, MT_FAILURE);
    }
};

module.exports = {
    fetchGigsWithPackages,
    fetchGigsWithPersonalization,
    fetchGigsByRecommendationsResult,
    fetchGigsByGoSearcherResult,
    buildPhoenixUrl,
    fetchTranslationLayers,
    translateUgcData,
    translateGigs,
};
