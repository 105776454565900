import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { noop } from 'lodash';
import { getBuyerAssumedLanguage } from '@fiverr-private/localization';
import { getContext } from '@fiverr-private/fiverr_context';
import { I18n, translate } from '@fiverr-private/i18n-react';
import { Icon } from '@fiverr-private/fit';
import { r_comment } from '@fiverr-private/icons';
import { SellerRepresentative } from '../../../constants/seller';
import TooltipWrapper from '../../../shared/components/TooltipWrapper';
import './index.scss';

const SellerLanguage = ({
    sellerLanguages,
    sellerType = SellerRepresentative.FREELANCER,
    showIcon = true,
    showTooltip = true,
    className,
    onLabelClick = noop,
}) => {
    const { locale, browserLanguage, countryCode, isMobile } = getContext();
    const commonLanguageCode = getBuyerAssumedLanguage({ locale, browserLanguage, countryCode });
    const commonLanguageName = translate(`languages.codes.${commonLanguageCode}`);
    const numberOfSellerLanguages = sellerLanguages.length;
    const sellerLanguagesTranslated = sellerLanguages.map(({ name, level }) => ({
        name: translate(`languages.codes.${name}`),
        level: translate(`languages.proficiencies.level_${level}`),
    }));
    const sellerSpeaksKey =
        sellerType === SellerRepresentative.AGENCY
            ? 'gig_listings.gig_cards.seller_language_plural'
            : 'gig_listings.gig_cards.seller_language';

    const handleTooltipContentClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const tooltipContent = (
        <div onClick={handleTooltipContentClick}>
            <strong className="seller-language-tooltip-label">
                <I18n k="gig_listings.gig_cards.seller_language_tooltip" />
            </strong>
            <div className="seller-language-tooltip-list">
                {sellerLanguagesTranslated.map(({ name, level }) => (
                    <div key={name + level}>
                        {name} ({level})
                    </div>
                ))}
            </div>
        </div>
    );

    return (
        <div className={classNames('seller-language', className)}>
            {showIcon && (
                <Icon size={14} className="icon">
                    {r_comment}
                </Icon>
            )}
            <TooltipWrapper
                className="seller-language-tooltip"
                shouldRenderTooltip={showTooltip}
                appendToBody={false}
                content={tooltipContent}
            >
                <span className="label" onClick={onLabelClick}>
                    <I18n
                        k={sellerSpeaksKey}
                        params={{ language: commonLanguageName, count: numberOfSellerLanguages - 1 }}
                        templates={{
                            speak: (text) => (!isMobile ? <span className="i-speak">{text}</span> : null), // eslint-disable-line react/display-name
                            count: (text) => (numberOfSellerLanguages > 1 ? text : ''),
                        }}
                    />
                </span>
            </TooltipWrapper>
        </div>
    );
};

SellerLanguage.propTypes = {
    sellerLanguages: PropTypes.array.isRequired,
    sellerType: SellerRepresentative,
    showIcon: PropTypes.bool,
    showTooltip: PropTypes.bool,
    className: PropTypes.string,
    onLabelClick: PropTypes.func,
};

export default SellerLanguage;
