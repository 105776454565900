import { isEqual } from 'lodash';
import { FILTER } from '../constants';

const SELECTED_DEFAULTS = {
    [FILTER.DELIVERY_TIME.ID]: '',
    [FILTER.GIG_PRICE_RANGE.ID]: [FILTER.GIG_PRICE_RANGE.DEFAULTS.MIN, FILTER.GIG_PRICE_RANGE.DEFAULTS.MAX],
    [FILTER.LANGUAGES_PAIR.ID]: FILTER.LANGUAGES_PAIR.INITIAL,
};

export const isDefaultOption = (id, value) =>
    SELECTED_DEFAULTS[id] !== undefined && isEqual(SELECTED_DEFAULTS[id], value);
