import React from 'react';
import classnames from 'classnames';
import { Section } from '@fiverr-private/fit';
import { ROLLOUTS } from '@fiverr-private/listing_experiments';
import AssetsVerifier from '../../AssetsVerifier';
import { ProOrientedSearchModal } from '../../ProOrientedSearchModal';
import Header from '../Header';
import { useBusinessListingsContext } from '../Context';

import styles from './index.module.scss';

const BusinessLayout = ({ children }) => {
    const { rollouts } = useBusinessListingsContext();
    const inMustangsVerifyAssetsRollout = rollouts[ROLLOUTS.MUSTANGS_VERIFY_ASSETS];

    return (
        <Section className={classnames(styles.main, 'listings-perseus')}>
            <Header />
            <div className="flex flex-items-start">{children}</div>
            {inMustangsVerifyAssetsRollout && <AssetsVerifier />}
            <ProOrientedSearchModal />
        </Section>
    );
};

export default BusinessLayout;
