import React from 'react';
import PropTypes from 'prop-types';
import { Select, Option } from '@fiverr-private/fit';
import { I18nProvider } from '@fiverr-private/fiverr_context';
import { FILTER } from '@fiverr-private/listing_lib';

import './index.scss';

const SelectContainer = ({ placeholder, options, id, value, optionSort, onChange }) => {
    const currentValue = typeof value === 'string' ? value : FILTER.LANGUAGES_PAIR.DEFAULT;
    const onChangeHandler = ({ target }) => {
        onChange(id, target.value);
    };

    const optionsToRender = options.sort(optionSort).map(({ id, alias }) => (
        <Option key={id} value={id}>
            {alias}
        </Option>
    ));

    if (placeholder) {
        return (
            <I18nProvider>
                {({ i18n }) => (
                    <Select className="select-container" value={currentValue} onChange={onChangeHandler}>
                        <Option value={FILTER.LANGUAGES_PAIR.DEFAULT}>{i18n.t(placeholder)}</Option>
                        {optionsToRender}
                    </Select>
                )}
            </I18nProvider>
        );
    }

    return (
        <Select className="select-container" value={currentValue} onChange={onChangeHandler}>
            {optionsToRender}
        </Select>
    );
};

SelectContainer.defaultProps = {
    options: [],
    value: '',
    onChange: (id, value) => ({ id, value }),
};

SelectContainer.propTypes = {
    id: PropTypes.string,
    value: PropTypes.string,
    options: PropTypes.array,
    placeholder: PropTypes.string,
    optionSort: PropTypes.func,
    onChange: PropTypes.func,
};

export default SelectContainer;
