import { stats } from '@fiverr-private/obs';

type IntegrationType = 'sdk' | 'https';

const SEARCH_PERSUES_PREFIX = 'services.search_perseus';

export const reportRequestTime = (integrationType: IntegrationType, metricRouteKey: string, responseTime: number) =>
    stats.time(SEARCH_PERSUES_PREFIX, `${integrationType}.${metricRouteKey}.total_request_time`, responseTime);

export const reportRequestCount = (integrationType: IntegrationType, metricRouteKey: string, status?: number) => {
    stats.count(SEARCH_PERSUES_PREFIX, `${integrationType}.${metricRouteKey}.request`);

    if (status) {
        stats.count(SEARCH_PERSUES_PREFIX, `${integrationType}.${metricRouteKey}.result.status.${status}`, 1);
    }
};
