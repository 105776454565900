import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { I18n } from '@fiverr-private/i18n-react';
import { Checkbox } from '@fiverr-private/fit';
import { commaSeparateNumber } from '@fiverr-private/futile';
import { groupByFilterGroup, shouldRenderFilterOption, sortByDescLength } from '../../../filter/utils';

const CheckboxGroup = ({ value, id, options, visibleCheckBoxes, onChange }) => {
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [groupedOptions, setGroupedOptions] = useState([]);

    useEffect(() => {
        const groupedBy = groupByFilterGroup(options.filter(({ count }) => shouldRenderFilterOption(count)));

        setGroupedOptions(
            Object.keys(groupedBy)
                .sort(sortByDescLength)
                .map((key) => ({
                    id: key,
                    items: groupedBy[key],
                }))
        );
    }, [options]);

    const onChangeHandler = (selectedValue, isChecked) => {
        const newSelected = isChecked ? [...value, selectedValue] : value.filter((v) => v !== selectedValue);
        onChange(id, newSelected.sort());
    };

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    const shouldCollapse = options.length > visibleCheckBoxes;
    const hasGroups = Object.keys(groupedOptions).length > 1;

    return (
        <>
            {groupedOptions.map((group, groupIndex) => {
                const shouldHideGroup = isCollapsed && groupIndex > 0;

                return (
                    <Fragment key={group.id}>
                        {hasGroups && (
                            <div
                                className={classNames('checkbox-group-title tbody-8 text-bold co-text-light', {
                                    hidden: shouldHideGroup,
                                })}
                            >
                                <I18n k={`search_perseus.filter.${group.id}`} />
                            </div>
                        )}
                        <ul className={'checkbox-group'}>
                            {group.items.map(({ id, name, alias, count }, filterIndex) => {
                                const isChecked = value && value.includes(id);

                                return (
                                    <li
                                        data-testid={`checkbox-filter-${id}`}
                                        key={id}
                                        className={classNames('box-shadow-z1', {
                                            hidden:
                                                shouldHideGroup || (isCollapsed && filterIndex + 1 > visibleCheckBoxes),
                                        })}
                                    >
                                        <Checkbox
                                            key={id}
                                            id={`checkbox-${id}`}
                                            value={id}
                                            name={name}
                                            onChange={() => onChangeHandler(id, !isChecked)}
                                            checked={isChecked}
                                        >
                                            <label htmlFor={`checkbox-${id}`}>{alias}</label>
                                            {count && <span>({commaSeparateNumber(count)})</span>}
                                        </Checkbox>
                                    </li>
                                );
                            })}
                        </ul>
                    </Fragment>
                );
            })}
            {shouldCollapse && (
                <button className="toggle-list" onClick={toggleCollapse}>
                    <I18n k={`search_perseus.filter.${isCollapsed ? 'show_more' : 'show_less'}`} />
                </button>
            )}
        </>
    );
};

CheckboxGroup.propTypes = {
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    options: PropTypes.array,
    id: PropTypes.string,
    visibleCheckBoxes: PropTypes.number,
    onChange: PropTypes.func,
};

CheckboxGroup.defaultProps = {
    value: [],
    options: [],
    visibleCheckBoxes: 3,
    onChange: () => true,
};

export default CheckboxGroup;
