import { capitalize } from 'lodash';
import { translate } from '@fiverr-private/i18n-react';

export const getTitle = ({ hidePrefix, showTranslatedUGC, localizedTitle, translatedTitle, title, gigTitleKey }) => {
    if (showTranslatedUGC && localizedTitle) {
        return localizedTitle;
    }

    if (showTranslatedUGC && translatedTitle) {
        return translatedTitle;
    }

    if (hidePrefix) {
        return capitalize(title);
    }

    return translate(gigTitleKey, { params: { title } });
};
