import { OrchestratorStateMachineAction, OrchestratorMachineState, OrchestratorStateMachineEvent } from '../../types';
import { handleTypeAction } from './handleTypeAction';
import { handleEventAction } from './handleEventAction';

export const orchestratorMachineReducer = (
    state: OrchestratorMachineState,
    action: OrchestratorStateMachineAction | OrchestratorStateMachineEvent
): OrchestratorMachineState => {
    if ('event' in action) {
        return handleEventAction(state, action);
    }

    if ('type' in action) {
        return handleTypeAction(state, action);
    }

    return state;
};
