import { getAllocationUtils } from './utils';
import sortByPrice from './sortByPrice';
import promotedVideoStrip from './promotedVideoStrip';
import { responsiveCarousels } from './responsiveCarousels/responsiveCarousels';
import { agenciesInListingsGD } from './agenciesInListingsGD';
import { starlingsBoostingNewGigs } from './starlingsBoostingNewGigs';
import { hourlyBannerGuest, hourlyBannerUser } from './hourlyBannerUserAndGuest';

const experiments = {
    sortByPrice,
    responsiveCarousels,
    promotedVideoStrip,
    agenciesInListingsGD,
    starlingsBoostingNewGigs,
    hourlyBannerGuest,
    hourlyBannerUser,
};

export const clientExperiments = getAllocationUtils(experiments);
