import React from 'react';
import { Wrap } from '@fiverr-private/layout_components';
import { SelectedFilter } from '@fiverr-private/listing_types';
import { ActiveFilter } from './ActiveFilter/ActiveFilter';
import { ClearActiveFiltersButton } from './ClearActiveFiltersButton/ClearActiveFiltersButton';

interface ActiveFiltersSectionProps {
    activeFilters;
    selectedFilters: SelectedFilter[];
    onFilterClick: () => void;
}

// TODO
export const ActiveFiltersSection = ({
    selectedFilters = [],
    onFilterClick,
    activeFilters,
}: ActiveFiltersSectionProps) => {
    if (selectedFilters.length < 1) {
        return null;
    }

    return (
        <Wrap alignItems="center" gap="3" as="section" marginBottom="4" marginTop="2">
            {selectedFilters.map((filter) => (
                <ActiveFilter key={filter.id} onFilterClick={onFilterClick} {...filter} activeFilters={activeFilters} />
            ))}
            <ClearActiveFiltersButton selectedFilters={selectedFilters} />
        </Wrap>
    );
};
