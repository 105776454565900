import { ActiveFilters } from '@fiverr-private/listing_types';

// TODO: figure out where should we configure this filter object and import it from there
const FILTER = {
    PRO: {
        ID: 'pro',
        SELECTED_VALUE: 'any',
        SELECTED_VALUE_ONLY: 'true',
    },
};

export const isProFilterActive = (activeFilters: ActiveFilters | undefined): boolean =>
    [FILTER.PRO.SELECTED_VALUE, FILTER.PRO.SELECTED_VALUE_ONLY].includes(
        activeFilters?.[FILTER.PRO.ID]?.toString() || ''
    );
