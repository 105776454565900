import React from 'react';
import classnames from 'classnames';
import { I18n } from '@fiverr-private/i18n-react';
import { Base } from '@fiverr-private/modal/src';
import ScListModal from '../../../../../../common/tiles/view_all_tile/modals/sc_list_modal';
import { SUBCATEGORY_FILTER_VISUAL_URL_PARAM } from '../../utils';

export const ViewAllSubcategoriesModal = ({ triggerClassName, items, initialValue }) => {
    const categoryVisualUrlParam = {
        key: SUBCATEGORY_FILTER_VISUAL_URL_PARAM,
        value: true,
    };

    return (
        <Base
            name="search_perseus listings-perseus list-modal"
            trigger={({ open }) => (
                <div onClick={open} className={classnames('tbody-6', triggerClassName)}>
                    <I18n k={'search_perseus.filter_tiles.view_all'} />
                </div>
            )}
        >
            {({ close }) => (
                <ScListModal
                    close={close}
                    items={items}
                    initialValue={initialValue}
                    urlParams={[categoryVisualUrlParam]}
                />
            )}
        </Base>
    );
};
