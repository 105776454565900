import { ACTIVE_FILTER_SEPARATORS, FILTER } from '@fiverr-private/listing_lib';
import { USD_CURRENCY } from './constants';

export { replaceAll } from './string';

const priceRangeStringFormat = (rangeValue) =>
    `${FILTER.GIG_PRICE_RANGE.ID}${ACTIVE_FILTER_SEPARATORS.PARAM_VALUE}${rangeValue.join(
        ACTIVE_FILTER_SEPARATORS.ARRAY_VALUE
    )}`;

export const normalizeActiveFilter = (activeFilters, currency) => {
    const priceRangeId = FILTER.GIG_PRICE_RANGE.ID,
        priceRangeFilter = activeFilters[priceRangeId];

    let convertedPriceRange;

    if (!priceRangeFilter || currency.name === USD_CURRENCY) {
        return { ...activeFilters };
    }

    if (priceRangeFilter) {
        const [min, max] = priceRangeFilter;
        convertedPriceRange = [
            convertToUSD(min, currency.rate),
            max === FILTER.GIG_PRICE_RANGE.DEFAULTS.MAX ? max : convertToUSD(max, currency.rate),
        ];
    }

    return {
        ...activeFilters,
        ...(convertedPriceRange && { [priceRangeId]: convertedPriceRange }),
    };
};

export const convertToUSD = (amount, rate = 1) => (amount / (rate !== 0 ? rate : 1)).toFixed(2);

export const convertStringToConstValue = (string) =>
    string
        .split(/(?=[A-Z])/)
        .join('_')
        .toUpperCase();

export const clearObjectValues = (object) =>
    Object.keys(object).reduce((acc, filterKey) => {
        acc[filterKey] = undefined;
        return acc;
    }, {});

export const formatPriceBuckets = (activeFilters, priceBucketOptions = []) => {
    const { [FILTER.PRICE_BUCKETS.ID]: activePriceBucket } = activeFilters;

    const priceBucketIdMapper = {
        0: 'low',
        1: 'medium',
        2: 'high',
    };

    if (activePriceBucket && priceBucketOptions.length) {
        const { min, max } = priceBucketOptions.find(({ id }) => id === activePriceBucket) || {};
        const modifiedPriceBucket = `${priceBucketIdMapper[activePriceBucket]},${min},${max}`;

        return { [FILTER.PRICE_BUCKETS.ID]: modifiedPriceBucket };
    }

    return {};
};

export const formatPricingFactor = (selectedPricingFactor) => {
    if (!selectedPricingFactor) {
        return {};
    }

    return { [FILTER.PRICING_FACTOR.ID]: selectedPricingFactor.value };
};

export const getPairSelected = ({ options }) => {
    const { fromOptions, toOptions } = options;
    const [fromValue] = fromOptions.filter((opt) => opt.selected);
    const [toValue] = toOptions.filter((opt) => opt.selected);
    const pairDefaultValue = FILTER.LANGUAGES_PAIR.DEFAULT;

    return {
        fromValue: fromValue ? fromValue.id : pairDefaultValue,
        toValue: toValue ? toValue.id : pairDefaultValue,
    };
};

export const splitArray = (arr, splitBy) => {
    const filtered = arr.filter(splitBy);
    return [filtered, arr.filter((item) => !splitBy(item))];
};

export const objectValues = (obj) => Object.keys(obj).map((key) => obj[key]);

export const maybeAdjustRefCurrency = (ref, activeFilters, currency) => {
    const priceRangeId = FILTER.GIG_PRICE_RANGE.ID;
    const priceRangeFilter = activeFilters[priceRangeId];
    let updatedRef = ref;

    if (!priceRangeFilter || currency.name === USD_CURRENCY) {
        return ref;
    }

    const activeFilterUSDCopy = normalizeActiveFilter(activeFilters, currency);

    if (priceRangeFilter) {
        const usdPriceRange = activeFilterUSDCopy[priceRangeId];

        updatedRef = updatedRef.replace(
            `${priceRangeStringFormat(priceRangeFilter)}`,
            `${priceRangeStringFormat(usdPriceRange)}`
        );
    }

    return updatedRef;
};
