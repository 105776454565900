const { logger, stats } = require('@fiverr-private/obs');
const { GIG_SCHEMA } = require('../config/schemas');

const STATS_PREFIX = 'packages.gig_listings_package';
const GIG_STATUS_APPROVED = 'APPROVED';

const SCHEME_VALIDATION_ERROR = (e, gig) =>
    `Phoenix response scheme validation - Gig - ${gig.gig_id} was found invalid and was filtered from results. Error: ${e}`;
const PERSONALIZED_PRICING_ERROR = (gig) =>
    `Phoenix Personalized pricing failed for Gig - ${gig.gig_id}, this gig was filtered from results`;

module.exports = {
    validatePhoenixResponse({ gigs = [] }, onError) {
        return gigs.filter((gig) => {
            try {
                if (gig.personalized_pricing_fail) {
                    onError({
                        message: PERSONALIZED_PRICING_ERROR(gig),
                    });

                    return false;
                }

                if (gig.status !== GIG_STATUS_APPROVED) {
                    stats.count(STATS_PREFIX, 'gig_schema_validation.status');
                    logger.warn('Requested non-approved gig', {
                        gig_id: gig.gig_id,
                        seller_id: gig.seller_id,
                        status: gig.status,
                    });
                }

                return GIG_SCHEMA.validateSync(gig);
            } catch (e) {
                onError({
                    message: SCHEME_VALIDATION_ERROR(e, gig),
                });

                return false;
            }
        });
    },
};
