import { useEffect } from 'react';
import { getContext } from '@fiverr-private/fiverr_context';
import { metricsService } from '@fiverr-private/listing_lib';

const TIMEOUTS_TO_CHECK = [5, 10];
const ERRORS_THRESHOLD = 0.5;
const TEST_IMAGE_URL =
    'https://fiverr-res.cloudinary.com/image/upload/t_profile_original,q_auto,f_auto/v1/attachments/profile/photo/38b7ebd600615b8c78e053ccc3f4fc2e-1692778916332/bceab9af-e868-454f-be22-a81e3a0c2751.png';

const getMetricPathWithPrefix = (metricPath) => `assets_verifier.${metricPath}`;

const reportTooManyPageImagesLoadingErrors = () => {
    const images = document.querySelectorAll('img');

    let errors = 0;

    images.forEach((image) => {
        image.addEventListener('error', () => {
            errors++;
        });
    });

    const all = images.length;

    TIMEOUTS_TO_CHECK.forEach((seconds) => {
        setTimeout(() => {
            const errorsPercentage = errors / all;

            if (errorsPercentage > ERRORS_THRESHOLD) {
                metricsService.count(getMetricPathWithPrefix(`error_threshold.${seconds}`));
            }
        }, seconds * 1000);
    });
};

const reportTestImageLoadingFailure = () => {
    const testImage = new Image();

    testImage.onload = () => {
        metricsService.count(getMetricPathWithPrefix('test_image.success'));
    };

    testImage.onerror = () => {
        metricsService.count(getMetricPathWithPrefix('test_image.error'));
    };

    testImage.src = `${TEST_IMAGE_URL}?${Date.now()}`;
};

const AssetsVerifier = () => {
    useEffect(() => {
        const { knownCrawler } = getContext();

        if (!knownCrawler) {
            reportTooManyPageImagesLoadingErrors();
            reportTestImageLoadingFailure();
        }
    }, []);

    return null;
};

export default AssetsVerifier;
