import React from 'react';
import { isEmpty } from 'lodash';
import { logger } from '@fiverr-private/obs';
import { Image } from '@fiverr-private/media';
import { getContext } from '@fiverr-private/fiverr_context';
import { I18n } from '@fiverr-private/i18n-react';
import { Arrows, Carousel, Slide, Track } from '@fiverr-private/carousel';
import { Container, LinkCard, Stack } from '@fiverr-private/layout_components';
import { Heading, Text } from '@fiverr-private/typography';
import { ArrowRightIcon } from '@fiverr-private/visuals';
import { ProfessionFiltersProps } from '../side_filters/ExposedFilters/types';
import { useAppContext } from '../../context/listingsContext';
import {
    ProfessionFilterCarouselItem,
    useProfessionFilterCarouselItems,
} from './utils/useProfessionFilterCarouselItems';
import { FALLBACK_IMAGES } from './constants';

export const ProfessionFiltersCarousel = ({ professionFilter }: ProfessionFiltersProps) => {
    const { professionFilterCarouselImages, listingAttributes, flowName, query } = useAppContext();
    const { isTouch } = getContext();

    const carouselItems = useProfessionFilterCarouselItems({
        professionFilterCarouselImages,
        professionFilter,
        listingAttributes,
        flowName,
        query,
    });

    const onImageLoadError =
        (carouselItem: ProfessionFilterCarouselItem, carouselItemIndex: number) =>
        (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
            logger.warn('ProfessionFiltersCarousel: Failed to load image', {
                imageUrl: carouselItem.imageUrl,
                itemTitle: carouselItem.title,
            });

            const target = event.target as HTMLImageElement;
            target.onerror = null; // Prevent infinite loop if default image also fails
            target.src = FALLBACK_IMAGES[carouselItemIndex % FALLBACK_IMAGES.length]; // Set default image
        };

    if (isEmpty(carouselItems)) {
        return null;
    }

    return (
        <Carousel paddingLeft="2" paddingY="1" scrollType="row" breakpoints={{ default: 2, sm: 3, lg: 4 }}>
            <Stack alignItems="center" justifyContent="spaceBetween" marginBottom="3">
                <Heading as="h6">
                    <I18n k="search_perseus.filter.profession_filter.title" />
                </Heading>
                {!isTouch && <Arrows position="topRight" />}
            </Stack>
            <Track autoSlidesWidth={isTouch}>
                {carouselItems.map((item, index) => (
                    <Slide key={index}>
                        <LinkCard
                            href={item.linkUrl}
                            borderColor={{ default: 'grey_400', active: 'grey_1200', hover: 'grey_1200' }}
                            borderRadius="2xl"
                            display="flex"
                            overflow="hidden"
                            marginBottom="0"
                        >
                            <Stack
                                width={{ default: '310px', md: '100%' }}
                                height="150px"
                                gap="0"
                                justifyContent="spaceBetween"
                                backgroundColor="grey_200"
                                paddingLeft="5"
                            >
                                <Stack
                                    height="100%"
                                    flex="1"
                                    direction="column"
                                    alignItems="flexStart"
                                    justifyContent="spaceBetween"
                                    paddingY="4"
                                    paddingRight="1"
                                >
                                    <Text fontWeight="semibold">{item.title}</Text>
                                    <ArrowRightIcon />
                                </Stack>
                                <Container height="100%" width="145px">
                                    {item.imageUrl && (
                                        <Image
                                            width="100%"
                                            height="100%"
                                            src={item.imageUrl}
                                            alt={item.title}
                                            onError={onImageLoadError(item, index)}
                                        />
                                    )}
                                </Container>
                            </Stack>
                        </LinkCard>
                    </Slide>
                ))}
            </Track>
        </Carousel>
    );
};
