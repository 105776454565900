import { MachineState, OnEnterStateEffect, OrchestratorMachineState } from '../../types';

export const setOnEnterState = (
    state: OrchestratorMachineState,
    machineState: MachineState,
    callback: OnEnterStateEffect
): OrchestratorMachineState => ({
    ...state,
    onEnterStateMap: {
        ...state.onEnterStateMap,
        [machineState]: callback,
    },
});
