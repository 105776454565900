import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { noop } from 'lodash';
import { assetType, attachmentType } from '../../shared/types';
import { AssetThumbnail } from '../AssetThumbnail';

export const FORWARD_ITEMS_TO_PRELOAD = 2;

export const AssetCarouselItem = ({
    className,
    isParentHovered,
    currentPosition,
    index,
    asset,
    attachment,
    onClick = noop,
    lazyLoad,
}) => {
    const [loadItem, setLoadItem] = useState(index - currentPosition <= FORWARD_ITEMS_TO_PRELOAD);

    useEffect(() => {
        if (index - currentPosition <= FORWARD_ITEMS_TO_PRELOAD) {
            setLoadItem(true);
        }
    }, [currentPosition, index]);

    if (!loadItem) {
        return null;
    }

    return (
        <div className={classnames(className, 'asset-carousel-item')} onClick={onClick}>
            <AssetThumbnail
                asset={asset}
                attachment={attachment}
                isParentHovered={isParentHovered}
                isActive={currentPosition === index}
                lazyLoad={lazyLoad}
            />
        </div>
    );
};

AssetCarouselItem.propTypes = {
    className: PropTypes.string,
    isParentHovered: PropTypes.bool,
    currentPosition: PropTypes.number,
    index: PropTypes.number,
    asset: assetType.isRequired,
    attachment: attachmentType,
    onClick: PropTypes.func,
    lazyLoad: PropTypes.bool,
};
