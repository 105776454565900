import React, { useContext } from 'react';
import { useImpressionItem } from '@fiverr-private/impressionable';
import { I18n } from '@fiverr-private/i18n-react';
import { AppContext } from '../../../context/listingsContext';
import { buildEventEnrichmentObject } from '../../semantic_search/util';
import { TRANSLATION_KEYS_MAPPER } from './constants';

const ModifiedResult = ({ searchInsteadUrl, modifiedQueryString, reasonForModification, phraseStart }) => {
    const { query } = useContext(AppContext);
    const eventEnrichment = buildEventEnrichmentObject(query, reasonForModification);

    const [ref] = useImpressionItem({ enrichment: eventEnrichment });

    return (
        <React.Fragment>
            <I18n k="search_perseus.search_results_counter.showing_results" params={{ phraseStart }} />
            &nbsp;
            <span ref={ref}>{modifiedQueryString}</span>.
            <div className="redirect-link m-t-12">
                <I18n k={TRANSLATION_KEYS_MAPPER[reasonForModification]} />
                &nbsp;
                <a href={searchInsteadUrl}>{query}</a>
            </div>
        </React.Fragment>
    );
};

export default ModifiedResult;
