import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import { numberFormat } from '@fiverr-private/localization';
import { Input, Radio } from '@fiverr-private/fit';

import './index.scss';

interface RadioItem {
    id: string;
    alias: string;
    count?: number;
    label?: string;
    inputPlaceholder?: string;
}
interface RadioListProps {
    id?: string;
    items: RadioItem[];
    selected: string;
    customOptionValue: string;
    onSelect: (value: string) => void;
    onClear: () => void;
    onChangeHandler: (value: string, callback: (value: string) => void) => void;
    onKeyPress: (e: React.KeyboardEvent<HTMLInputElement>, value: string) => void;
    radioClassname?: string;
}

interface RadioListState {
    customOptionValue: string;
}

const CUSTOM_FILTER_OPTION = 'custom';

export class RadioList extends Component<RadioListProps, RadioListState> {
    constructor(props) {
        super(props);

        this.state = {
            customOptionValue: props.customOptionValue,
        };

        this.getValues = this.getValues.bind(this);
        this.setCustomOptionValue = this.setCustomOptionValue.bind(this);
    }

    setCustomOptionValue(customOptionValue) {
        this.setState({
            customOptionValue,
        });
    }

    getValues() {
        const { customOptionValue } = this.state;
        const { selected, id } = this.props;

        const value = selected === CUSTOM_FILTER_OPTION ? customOptionValue : selected;
        return id ? { [id]: value } : {};
    }

    syncValues() {
        return this.props.onClear();
    }

    override render() {
        const { id = '', items, onChangeHandler, onKeyPress, selected, onSelect, radioClassname } = this.props;
        const { customOptionValue } = this.state;

        const radioBtnName = `ftb-radio-${id}`;

        return (
            <div className="radio-list">
                {items.map((item) => (
                    <Fragment key={item.id}>
                        <div className="radio-item-wrapper" key={item.id}>
                            <Radio
                                value={item.id}
                                name={radioBtnName}
                                checked={selected === item.id}
                                onChange={(e) => onSelect(e.target.value)}
                                className={classNames('radio-item', { selected: item.id === selected }, radioClassname)}
                            >
                                <div className="inner-radio">
                                    <span>{item.alias}</span>
                                    {!!item.count && (
                                        <span className="count">
                                            {numberFormat({
                                                value: item.count,
                                                options: { fallbackValue: item.count.toLocaleString() },
                                            })}
                                        </span>
                                    )}
                                </div>
                            </Radio>
                            {item.label && <label className="co-text-lighter fs-14 text-normal">{item.label}</label>}
                        </div>
                        {item.id === CUSTOM_FILTER_OPTION && selected === item.id && (
                            <Input
                                value={customOptionValue}
                                autoFocus
                                className="radio-list-input text-normal fs-16"
                                onKeyPress={(e) => onKeyPress(e, customOptionValue)}
                                onChange={(e) => onChangeHandler(e.target.value, this.setCustomOptionValue)}
                                placeholder={item.inputPlaceholder}
                            />
                        )}
                    </Fragment>
                ))}
            </div>
        );
    }
}
