import { OrchestratorMachineState, OrchestratorStateMachineAction } from '../../types';
import { setOnEnterState } from './setOnEnterState';
import { setOnLeaveState } from './setOnLeaveState';
import { setListingAttributesPayload } from './setListingAttributesPayload';

export const handleTypeAction = (
    state: OrchestratorMachineState,
    action: OrchestratorStateMachineAction
): OrchestratorMachineState => {
    switch (action.type) {
        case 'setOnEnterState': {
            return setOnEnterState(state, action.payload.state, action.payload.callback);
        }
        case 'setOnLeaveState': {
            return setOnLeaveState(state, action.payload.state, action.payload.callback);
        }
        case 'setListingAttributesPayload': {
            return setListingAttributesPayload(state, action.payload);
        }
        default: {
            return state;
        }
    }
};
