import { difference, isArray, isNil, lowerCase } from 'lodash';
import { bigQuery, logger, mixpanel } from '@fiverr-private/obs';
import { removeEmptyEntries } from '../../util';
import {
    FILTERS_LOCATION,
    SELECTORS,
    BI_CLICK_DATA,
    BI_CONSTS,
    EVENTS_MAPPING,
    EXPERT_BANNER_TYPE,
} from '../../constants';
import { localStorageService } from '../../localStorageService/localStorageService';

const basePayload = ({ pageName, categoryIds, categoryName, subCategoryName, nestedSubCategoryName }) => ({
    'Page Name': pageName,
    'Category Name': categoryName,
    'Sub Category Name': subCategoryName,
    'Nested Sub Category Name': nestedSubCategoryName,
    ...categoryIds,
});

const prepareMixpanelTrackPayload = ({
    pageName,
    categoryIds,
    categoryName,
    subCategoryName,
    nestedSubCategoryName,
    payload = {},
}) =>
    removeEmptyEntries({
        ...basePayload({ pageName, categoryIds, categoryName, subCategoryName, nestedSubCategoryName }),
        ...payload,
    });

const prepareMixpanelViewPayload = ({
    pageName,
    subCategoryName,
    categoryName,
    nestedSubCategoryName,
    categoryIds,
    page,
    sort,
    filters,
    query,
    isNonExperiential,
    numberOfResults,
    hasFiverrChoiceGigs,
    fiverrChoiceGigPosition,
    promotedGigsCount,
    hasPromotedGigs,
    significantLeafCategories,
    dominantLeafCategoryParent,
    error,
    pro_only = false,
    searchAutoComplete,
}) =>
    removeEmptyEntries({
        'Number Of Results': numberOfResults,
        Query: query,
        Sort: sort,
        'Listings Type': isNonExperiential ? 'Non-Experiential' : 'Experiential',
        'Applied Filters': filters,
        'Seen Fiverr Choice Badge': hasFiverrChoiceGigs,
        'Fiverr Choice Gig Position': fiverrChoiceGigPosition,
        'Number of Promoted Gigs': promotedGigsCount,
        'Seen Promoted Gigs Badge': hasPromotedGigs,
        ...(isNil(dominantLeafCategoryParent) && { 'Significant Leaf Categories': significantLeafCategories }),
        'Dominant Leaf Sub Category': dominantLeafCategoryParent,
        'Page Number': page,
        'Is Pro Experience': pro_only,
        'V4 Perseus': true,
        'Error Type': error,
        ...(searchAutoComplete &&
            searchAutoComplete.autocomplete_type && { 'Autocomplete Type': searchAutoComplete.autocomplete_type }),
        ...basePayload({ pageName, categoryIds, categoryName, subCategoryName, nestedSubCategoryName }),
    });

const prepareMixpanelFilterPayload = ({
    pageName,
    categoryIds,
    categoryName,
    subCategoryName,
    nestedSubCategoryName,
    id,
    value,
    location,
    filters,
    stepNum,
}) =>
    removeEmptyEntries({
        ...basePayload({ pageName, categoryIds, categoryName, subCategoryName, nestedSubCategoryName }),
        'Filter Name': id,
        'Filter Value': value,
        'Filter Location': location,
        'Applied filters': filters,
        'Step Number': stepNum,
    });

const prepareBiQueryFilterPayload = (reportData, type, id, value, location) => {
    const filter = {
        element: {
            metadata: id,
            text: value && value.toString(),
            position: location,
        },
    };

    return removeEmptyEntries({
        type,
        group: 'listings_filters',
        listings: {
            ...reportData.listings,
        },
        page: filter,
    });
};

const baseTrackResults = (newValue, oldValue) => ({
    value: newValue ? newValue : oldValue,
    type: _findFilterType(newValue),
});

const seoBaseTrackResults = (newValue, oldValue) => [
    {
        value: newValue ? newValue : oldValue,
        type: _findFilterType(newValue),
    },
];

export const filterTrackResults = {
    [SELECTORS.types.SINGLE]: baseTrackResults,
    [SELECTORS.types.PAIR]: baseTrackResults,
    [SELECTORS.types.MULTI]: (newValue = [], oldValue = []) => {
        const added = newValue.length > oldValue.length;

        // Concat values and get unique ( We actually want the delta between the two )
        const values = [...newValue, ...oldValue];

        return {
            value: values.find((v) => values.filter((val) => val === v).length === 1),
            type: _findFilterType(added),
        };
    },
};

const seoFilterTrackResults = {
    [SELECTORS.types.SINGLE]: seoBaseTrackResults,
    [SELECTORS.types.PAIR]: seoBaseTrackResults,
    [SELECTORS.types.MULTI]: (newValue = [], oldValue = []) => {
        const added = difference(newValue, oldValue);
        const removed = difference(oldValue, newValue);

        return added
            .map((value) => ({ value, type: EVENTS_MAPPING.FILTERS_APPLY }))
            .concat(removed.map((value) => ({ value, type: EVENTS_MAPPING.FILTER_REMOVE })));
    },
};

// TODO remove usages. ~50 usages. inline events directly.
/** @deprecated - avoid usage. report BQ / MP events directly. */
class Tracker {
    initialize(context) {
        this.context = context;
    }

    /** @deprecated */
    track(type, payload, location) {
        const eventPayload = prepareMixpanelTrackPayload({ ...this.context, payload });
        const { reportData } = this.context;
        const { mixpanel: mixpanelType, biquery } = type;
        const [id] = Object.keys(payload);

        mixpanelType && mixpanel.track(mixpanelType, eventPayload);

        biquery && bigQuery.send(prepareBiQueryFilterPayload(reportData, biquery, id, payload[id], location));

        return eventPayload;
    }

    /** @deprecated */
    trackMobileFilterOpen() {
        const element = {
            name: FILTERS_LOCATION.FILTER_RESULTS,
            type: BI_CONSTS.BUTTON,
        };

        this.trackBiClick(element);
    }

    /** @deprecated */
    trackDrawerGroupOpen(componentName, elementName) {
        const element = {
            name: elementName,
            type: BI_CONSTS.BUTTON,
        };

        const component = {
            name: componentName,
        };

        this.trackBiClick(element, BI_CONSTS.CLICKED_ON_DRAWER_GROUP, component);
    }

    trackFilterGroupOpen(name, type = BI_CONSTS.BUTTON) {
        const element = {
            name,
            type,
        };

        this.trackBiClick(element);
    }

    trackBiClick(element, type, component) {
        const {
            reportData: {
                listings: { page_ctx_id },
            },
        } = this.context;

        bigQuery.send({
            type,
            ...BI_CLICK_DATA,
            page: {
                ctx_id: page_ctx_id,
                element,
                component,
            },
        });
    }

    trackFilter(type, { id, value: rawValue, location, stepNum } = {}) {
        const value = isArray(rawValue) ? rawValue.join(',') : rawValue;
        const eventPayload = prepareMixpanelFilterPayload({ ...this.context, id, value, location, stepNum });
        const { reportData } = this.context;
        const { mixpanel: mixpanelType, biquery } = type;

        mixpanelType && mixpanel.track(mixpanelType, eventPayload);

        biquery && bigQuery.send(prepareBiQueryFilterPayload(reportData, biquery, id, value, location));

        return eventPayload;
    }

    trackLeafCategoryFilter(type, { id, alias, location }) {
        mixpanel.track(type.mixpanel, {
            ...prepareMixpanelTrackPayload({ ...this.context }),
            'Filter Name': 'Leaf Categories Filter',
            'Filter Value': alias,
            'Filter Location': location,
            ID: id,
        });
    }

    trackTileFilter(type, filterId, stepNum) {
        mixpanel.track(
            type.mixpanel,
            prepareMixpanelTrackPayload({
                Target: 'Top Filters Flow',
                'Sub Category Name': this.context?.subCategoryName,
                'Step Number': stepNum,
                Context: filterId,
                ...this.context,
            })
        );
    }

    /** @deprecated */ // Update the bigQuery params
    filterTrackingUpdate = (currentFilter, filterChange, location, id) => {
        const filterType = SELECTORS.getType(id);
        const { type, value } = filterTrackResults[filterType](filterChange[id], currentFilter[id]);

        this.trackFilter(type, { id, value, location });
    };

    seoFilterTrackingUpdate = (currentFilter, filterChange, location, id) => {
        const filterType = SELECTORS.getType(id);
        const toTrack = seoFilterTrackResults[filterType](filterChange[id], currentFilter[id]);

        toTrack.forEach(({ type, value }) => {
            this.trackFilter(type, { id, value, location });
        });
    };

    syncAndTrack({ filters, page, sort, reportData }, initialTime) {
        const load_time = new Date() - initialTime;
        const data = removeEmptyEntries({ ...reportData, page: { load_time } });

        this.context = {
            ...this.context,
            filters,
            page,
            sort,
            reportData,
        };

        bigQuery.send(data);
    }

    view() {
        const eventPayload = prepareMixpanelViewPayload(this.context);
        const { reportData, localStorageData } = this.context;
        const { shouldStore, entrySuffix } = localStorageData;
        const loadTime = new Date() - new Date(reportData.page.timestamp);
        mixpanel.track(EVENTS_MAPPING.PAGE_VIEWED.mixpanel, eventPayload);

        shouldStore && localStorageService.storePageView(entrySuffix);

        const data = removeEmptyEntries({ ...reportData, page: { load_time: loadTime } });
        bigQuery.send(data);
    }

    click(target, additionalArgs = {}) {
        const payload = { ...additionalArgs, target };
        this.track(EVENTS_MAPPING.CLICK, payload);
    }

    error() {
        const eventPayload = prepareMixpanelViewPayload(this.context);

        mixpanel.track(EVENTS_MAPPING.PAGE_VIEWED.mixpanel, eventPayload);
    }

    trackEducationTooltipGotIt(tooltipName) {
        mixpanel.track(EVENTS_MAPPING.CLICKED_GOT_IT.mixpanel, { 'Education tooltip': tooltipName });
    }

    trackHowFiverrWorksVideo(payload) {
        mixpanel.track(
            EVENTS_MAPPING.CLICK.TARGETS.HOW_FIVERR_WORKS_VIDEO,
            prepareMixpanelTrackPayload({ ...this.context, payload })
        );
    }

    trackWebsiteMatchVideo(payload) {
        mixpanel.track(
            EVENTS_MAPPING.CLICK.TARGETS.WEBSITE_MATCH_VIDEO,
            prepareMixpanelTrackPayload({ ...this.context, payload })
        );
    }

    trackWebsiteMatchCta(payload) {
        mixpanel.track(
            EVENTS_MAPPING.CLICK.TARGETS.WEBSITE_MATCH_CTA,
            prepareMixpanelTrackPayload({ ...this.context, payload })
        );
    }

    trackGotoMarketPlace() {
        const {
            reportData: {
                listings: { page_ctx_id },
            },
        } = this.context;

        const data = {
            group: 'pro_navigation',
            type: EVENTS_MAPPING.MARKETPLACE_CLICK,
            listings: {
                is_pro: true,
                page_ctx_id,
            },
        };

        bigQuery.send(data);
    }

    viewChoiceModalities() {
        const eventPayload = prepareMixpanelViewPayload(this.context);
        mixpanel.track(EVENTS_MAPPING.VIEWED_CHOICE_MODALITIES.mixpanel, eventPayload);
    }

    /** @deprecated */
    clickChoiceModality({ bucketNumber, bucketData, gig }) {
        try {
            const eventPayload = {
                ...prepareMixpanelViewPayload(this.context),
                ...bucketData,
                'Bucket Number': bucketNumber,
                'Gig Id': gig.id,
                Price: gig.price,
            };

            mixpanel.track(EVENTS_MAPPING.CLICKED_CHOICE_MODALITY.mixpanel, eventPayload);
        } catch (e) {
            logger.warn(e, { message: 'Failed to report Clicked Fiverr Choice Modality to MixPanel' });
        }
    }

    /** @deprecated */
    viewHighlightRecommendation() {
        try {
            const eventPayload = prepareMixpanelViewPayload(this.context);
            mixpanel.track(EVENTS_MAPPING.VIEW_HIGHLIGHTED_RECOMMENDATIONS.mixpanel, eventPayload);
        } catch (e) {
            logger.warn(e, {
                message: `Failed to report ${EVENTS_MAPPING.VIEW_HIGHLIGHTED_RECOMMENDATIONS.mixpanel} event to MixPanel`,
            });
        }
    }

    promotedAdsTooltipView() {
        try {
            const payload = prepareMixpanelViewPayload(this.context);
            mixpanel.track(EVENTS_MAPPING.HOVER_PROMOTED_ADS_TOOLTIP.mixpanel, payload);
        } catch (e) {
            logger.warn(e, {
                message: `Failed to report ${EVENTS_MAPPING.HOVER_PROMOTED_ADS_TOOLTIP.mixpanel} event to MixPanel`,
            });
        }
    }

    trackNoRecommendationsSupply(componentName, reason = '') {
        const { pageName, reportData } = this.context;
        const { page_ctx_id: pageCtxId } = reportData.listings;

        const data = {
            group: 'technical_events',
            sub_group: 'feature_events',
            type: EVENTS_MAPPING.NO_RECOMMENDATIONS_SUPPLY,
            listings: {
                page_ctx_id: pageCtxId,
            },
            page: {
                ctx_id: pageCtxId,
                name: lowerCase(pageName),
                component: {
                    type: 'recommendations_carousel',
                    name: 'express_recommendations',
                },
            },
            element: {
                name: componentName,
            },
            reason,
        };

        bigQuery.send(data);
    }

    /** @deprecated */
    trackCreateBriefClick() {
        this.trackCreateBriefClickBigQuery();
        this.trackCreateBriefClickMixpanel();
    }

    trackCreateBriefClickBigQuery() {
        const { pageName, reportData } = this.context;
        const { page_ctx_id: pageCtxId } = reportData.listings;

        const eventPayload = {
            type: EVENTS_MAPPING.CLICKED_ON_CREATE_A_BRIEF.biquery,
            group: 'buyer_request',
            entity: 'client',
            event_source: lowerCase(pageName),
            feature: {
                name: 'brief_and_match',
            },
            page: {
                name: lowerCase(pageName),
                ctx_id: pageCtxId,
                element: {
                    type: 'button',
                },
            },
            action: {
                type: 'click',
            },
            out_of_session: false,
        };

        bigQuery.send(eventPayload);
    }

    trackCreateBriefClickMixpanel(hasResults = '', isMainCTA = '') {
        const eventPayload = prepareMixpanelTrackPayload({
            ...this.context,
            payload: {
                isMainCTA: String(isMainCTA),
                hasResults: String(hasResults),
                'Source Component': 'Empty search result',
            },
        });

        mixpanel.track(EVENTS_MAPPING.CLICKED_ON_CREATE_A_BRIEF.mixpanel, eventPayload);
    }

    /** @deprecated */
    trackFilterValidationError(filterName, value, pageName, errorType) {
        const eventPayload = {
            'Filter Name': filterName,
            'Filter Value': value,
            'Page name': pageName,
            'Validation error type': errorType,
        };
        mixpanel.track(EVENTS_MAPPING.FILTER_VALIDATION_ERROR.mixpanel, eventPayload);
    }

    /** @deprecated */
    trackNoResultImpression(hasActiveFilters, isExpertListings) {
        mixpanel.track('View no results page', {
            'is filtered': hasActiveFilters,
            source: isExpertListings ? 'pro listing' : 'full catalog',
        });
    }

    /** @deprecated */
    trackNoResultChatCTA(pageName, hasResults, isMainCTA) {
        mixpanel.track('clicked contact BSM', {
            pageName,
            isMainCTA: String(isMainCTA),
            hasResults: String(hasResults),
            'Source Component': 'Empty search result',
        });
    }

    trackBriefBannerImpression(payload) {
        mixpanel.track('view B&M fib banner', payload);
    }

    /** @deprecated */
    trackBriefBannerClick(payload) {
        mixpanel.track('clicked B&M fib banner', payload);
    }

    trackProjectPartnersImpression(payload) {
        mixpanel.track('view PJP fib banner', payload);
    }

    /** @deprecated */
    trackProjectPartnersClick(payload) {
        mixpanel.track('clicked PJP fib banner', payload);
    }

    trackAIConsultationBannerImpression(payload) {
        mixpanel.track('view AI fib banner', payload);
    }

    /** @deprecated */
    trackAIConsultationBannerClick(payload) {
        mixpanel.track('clicked AI fib banner', payload);
    }

    trackBannerImpression(bannerType, payload) {
        switch (bannerType) {
            case EXPERT_BANNER_TYPE.BRIEF:
                this.trackBriefBannerImpression(payload);
                break;
            case EXPERT_BANNER_TYPE.PROJECT_PARTNERS:
                this.trackProjectPartnersImpression(payload);
                break;
            case EXPERT_BANNER_TYPE.AI_CONSULTATION:
                this.trackAIConsultationBannerImpression(payload);
                break;
            default:
                break;
        }
    }

    log(error, level = 'warn') {
        logger[level](error);
    }
}

const _findFilterType = (value) => (value ? EVENTS_MAPPING.FILTERS_APPLY : EVENTS_MAPPING.FILTER_REMOVE);

export const tracker = new Tracker();
