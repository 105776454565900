import React from 'react';
import { array, bool, string } from 'prop-types';
import classNames from 'classnames';
import SellerLanguage from '../../../Components/SellerLanguage';

import './index.scss';

const SellerSpeaks = ({ showISpeak, sellerLanguages, sellerType = 'freelancer' }) => {
    if (!showISpeak) {
        return null;
    }

    return (
        <div className={classNames('m-t-8', 'basic-gig-card-seller-speaks')}>
            <SellerLanguage
                sellerLanguages={sellerLanguages}
                sellerType={sellerType}
                showIcon={false}
                showTooltip={false}
            />
        </div>
    );
};

SellerSpeaks.propTypes = {
    showISpeak: bool,
    sellerLanguages: array,
    sellerType: string,
};

export default SellerSpeaks;
