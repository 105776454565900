import { ASSET_TYPES } from '../../../shared/constants';

/**
 * @typedef {Object.<string, import('@fiverr-private/overlay').LightboxSourceType>} SOURCE_TYPES
 */
export const SOURCE_TYPES = {
    [ASSET_TYPES.IMAGE]: 'image',
    [ASSET_TYPES.VIDEO]: 'video',
    [ASSET_TYPES.AUDIO]: 'audio',
};

export const ASSET_IMG_TRANSFORMATION = 't_main1,';
export const ATTACHMENT_IMG_TRANSFORMATION = 't_gig_cards_web,';
