export const MAX_GIG_PRICE_RANGE = 50000;
export const MIN_GIG_PRICE_RANGE = 0;
export const CUSTOM_BUDGET_VALUE = 'custom';

export const RANGE_KEYS = {
    MIN: 'min',
    MAX: 'max',
};
export const DOMAttributes = {
    [RANGE_KEYS.MIN]: MIN_GIG_PRICE_RANGE,
    [RANGE_KEYS.MAX]: MAX_GIG_PRICE_RANGE,
};
export const MIN_GIG_PRICE = 5;
