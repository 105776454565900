import React, { useCallback } from 'react';
import classNames from 'classnames';
import { get } from 'lodash';
import { Icon } from '@fiverr-private/fit';
import { CloseIcon } from '@fiverr-private/icons';
import { Stack, WrapItem } from '@fiverr-private/layout_components';
import { Button } from '@fiverr-private/button';
import { Typography } from '@fiverr-private/typography';
import { FILTER, FILTER_HASH_PREFIX } from '@fiverr-private/listing_lib';
import { SelectedFilterOption as SelectedFilterOptionType } from '@fiverr-private/listing_types';
import { filterNavigation } from '../../../../../service/clientNavigation';
import { ActiveFilterAlias } from './ActiveFilterAlias/ActiveFilterAlias';
import { ActiveFilterOptionCount } from './ActiveFilterOptionCount/ActiveFilterOptionCount';
import { ID_TO_CHANGE_OBJECT } from './ActiveFilter.config';
import {
    containerStyles,
    leftSideFirstOptionTextStyle,
    leftSideStyles,
    rightSideIconStyles,
    rightSideStyles,
    sideStyles,
} from './ActiveFilter.ve.css';

interface ActiveFilterProps {
    id: string;
    activeFilters;
    alias: string;
    values: SelectedFilterOptionType[];
    onFilterClick: (selectedFilter: string) => void;
}

export const ActiveFilter = ({ id, alias = '', values, onFilterClick, activeFilters }: ActiveFilterProps) => {
    const firstOption = values[0];
    const { alias: firstOptionAlias, activeFilterAlias: firstOptionActiveFilterAlias } = firstOption || {};
    const defaultChangeGenerator = useCallback(() => ({ [id]: [] }), [id]);

    const onLeftSideClick = () => {
        onFilterClick(id);
        window.location.hash = `${FILTER_HASH_PREFIX}${id}`;
    };

    const onRightSideClick = () => {
        const change = get(ID_TO_CHANGE_OBJECT, id, defaultChangeGenerator)(activeFilters);
        const params = id === FILTER.SUB_CATEGORIES.ID ? {} : null;

        filterNavigation(change, activeFilters, null, null, null, params);
    };

    return (
        <WrapItem>
            <Stack className={containerStyles} overflow="hidden" alignItems="stretch" borderRadius="circle" gap="0">
                <Button className={classNames(sideStyles, leftSideStyles)} onClick={onLeftSideClick}>
                    <Stack color="grey_1100" gap="1">
                        <ActiveFilterAlias alias={alias} />
                        <Typography
                            className={leftSideFirstOptionTextStyle}
                            as="span"
                            fontSize="b_md"
                            fontWeight="semibold"
                        >
                            {firstOptionActiveFilterAlias || firstOptionAlias}
                        </Typography>
                    </Stack>
                    <ActiveFilterOptionCount options={values} />
                </Button>
                <Button className={classNames(sideStyles, rightSideStyles)} onClick={onRightSideClick}>
                    <Icon className={rightSideIconStyles} size={10}>
                        <CloseIcon />
                    </Icon>
                </Button>
            </Stack>
        </WrapItem>
    );
};
