import { isEmpty } from 'lodash';

export const T_PREFIX = 'search_perseus.business_listings.no_results';

export const shouldShowIcon = (resultsCount) => resultsCount === 0;

export const getNoResultsTitleKey = (hasActiveFilters, hasResults, isExpertListings) => {
    if (!isExpertListings) {
        return `${T_PREFIX}.title.services`;
    }

    let key;

    if (hasResults && hasActiveFilters) {
        key = 'experts_with_results_with_filter';
    } else if (hasResults && !hasActiveFilters) {
        key = 'experts_with_results_no_filter';
    } else if (!hasResults && hasActiveFilters) {
        key = 'experts_no_result_with_filter';
    } else if (!hasResults && !hasActiveFilters) {
        key = 'experts_no_result_no_filter';
    }

    return `${T_PREFIX}.title.${key}`;
};

export const getDetailsSectionContentKey = (hasActiveFilters, hasResult, isBsm, isExpertListings) => {
    if (!isExpertListings) {
        return hasActiveFilters ? `${T_PREFIX}.details.services_with_filter` : `${T_PREFIX}.details.services_no_filter`;
    }

    let key;

    if (hasResult && hasActiveFilters && isBsm) {
        key = 'experts_with_result_with_filter_with_bsm';
    } else if (hasResult && hasActiveFilters && !isBsm) {
        key = 'experts_with_result_with_filter_no_bsm';
    } else if (hasResult && !hasActiveFilters && isBsm) {
        key = 'experts_with_result_no_filter_with_bsm';
    } else if (hasResult && !hasActiveFilters && !isBsm) {
        key = 'experts_with_result_no_filter_no_bsm';
    } else if (!hasResult && hasActiveFilters && isBsm) {
        key = 'experts_no_result_with_filter_with_bsm';
    } else if (!hasResult && hasActiveFilters && !isBsm) {
        key = 'experts_no_result_with_filter_no_bsm';
    } else if (!hasResult && !hasActiveFilters && isBsm) {
        key = 'experts_no_result_no_filter_with_bsm';
    } else if (!hasResult && !hasActiveFilters && !isBsm) {
        key = 'experts_no_result_no_filter_no_bsm';
    }

    return `${T_PREFIX}.details.${key}`;
};

export const hasBsm = (managedAccount) =>
    !!managedAccount &&
    ((!isEmpty(managedAccount.bsm) && managedAccount.type === 'strategic') || managedAccount.type === 'classic');
