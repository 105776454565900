import React from 'react';
import Badge from '../../../Components/Badge';

const BadgeWrapper = (props) => (
    <div className="flex-start p-t-8">
        <Badge {...props} />
    </div>
);

export default BadgeWrapper;
