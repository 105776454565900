import React, { Component, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { omit, isEmpty, isEqual, isUndefined } from 'lodash';
import classNames from 'classnames';
import { Base } from '@fiverr-private/modal/src';
import { Button } from '@fiverr-private/fit';
import { I18n } from '@fiverr-private/fiverr_context';
import { FILTERS_LOCATION, FILTER, removeEmptyEntries, tracker, EVENTS_MAPPING } from '@fiverr-private/listing_lib';
import { AppContext } from '../../context/listingsContext';
import { getFiltersSelectedValues, getSelectedOptionValue } from '../../../utils/filter_builder';
import { clearObjectValues, convertStringToConstValue } from '../../../utils';
import { filterNavigation, sortNavigation } from '../../../service/clientNavigation';
import { modifyActiveFilters } from '../../../utils/filter_builder/_utils';
import { SORT_BY } from '../../../utils/constants';
import LanguageSellerToggle from '../floating_topbar/menus/content/toggleFilters/language_seller_toggle';
import { SC_IDS_WITH_SUBSCRIPTION_TOGGLE } from '../floating_topbar/menus/content/toggleFilters/subscription_toggle/constants';
import LocalSellerToggle from '../floating_topbar/menus/content/toggleFilters/local_seller_toggle';
import { decideOnSellerToggleFilters } from '../seller_toggle_filter';
import TopRatedSellerToggle from '../floating_topbar/menus/content/toggleFilters/top_rated_sellers_toggle';
import SortContainer from './content/SortContainer';
import { MAX_GIG_PRICE_RANGE, MIN_GIG_PRICE_RANGE } from './Filter/consts';
import Filter from './Filter';
import { getLocalSellerFilter, getTopRatedSellerFilter } from './getSellerFilters';
import '../filter/AdvancedSearch/index.scss';
import './index.scss';

const FilterWrapper = lazy(() =>
    import(/* webpackChunkName: 'listings/FilterWrapper/FilterWrapper' */ './FilterWrapper')
);

class MobileFilters extends Component {
    constructor(props, context) {
        super(props, context);
        const { filters, sorts } = props;
        this.initialFiltersValues = { ...getFiltersSelectedValues(filters) };
        const { assumedLanguage, categoryIds: { subCategoryId } = {}, isExpertListings } = context;
        this.isSubCategoryForSubscriptionFilter = SC_IDS_WITH_SUBSCRIPTION_TOGGLE.includes(Number(subCategoryId));
        this.sellerToggleFilter = !isExpertListings && decideOnSellerToggleFilters(filters, { assumedLanguage });
        this.localSellerFilter = !isExpertListings && getLocalSellerFilter(filters);
        this.topRatedSellerFilter = getTopRatedSellerFilter(filters);
        this.state = {
            filters: {},
            sort: sorts && getSelectedOptionValue(sorts),
            isValidFilter: true,
        };
        this.applyModalFilters = this.applyModalFilters.bind(this);
        this.applyFilters = this.applyFilters.bind(this);
        this.clearAllFilters = this.clearAllFilters.bind(this);
        this.onChangeFilter = this.onChangeFilter.bind(this);
        this.toggleExportedFilter = this.toggleExportedFilter.bind(this);
        this.onClearFilter = this.onClearFilter.bind(this);
        this.generateFiltersToUrlValue = this.generateFiltersToUrlValue.bind(this);
        this.validatePriceRange = this.validatePriceRange.bind(this);
        this.validatePriceBuckets = this.validatePriceBuckets.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        this.applySort = this.applySort.bind(this);
        this.sortFiltersPosition = this.sortFiltersPosition.bind(this);
    }

    sortFiltersPosition() {
        const filters = [...this.props.filters];
        const { exportedFilterId } = this.props;
        const customFilters = [];

        let sortedFilters = filters.reduce((acc, item) => {
            const filter = FILTER[item.id.toUpperCase()] || FILTER[convertStringToConstValue(item.id)];
            if (!filter || isUndefined(filter.SORT)) {
                customFilters.push(item);
                return acc;
            }

            acc[filter.SORT] = item;
            return acc;
        }, []);

        sortedFilters.splice(FILTER.GIG_PRICE_RANGE.SORT + 1, 0, ...customFilters);

        if (this.context.isBusiness) {
            sortedFilters = this.filterBusinessFilters(sortedFilters);
        }

        return sortedFilters.filter(({ id }) => id !== exportedFilterId);
    }

    filterBusinessFilters = (filters) =>
        filters.filter(({ id }) => {
            const filterConfig = Object.values(FILTER).find((filter) => filter.ID === id);
            const hideFilter = filterConfig?.HIDE_IN_BUSINESS_MOBILE;
            return !hideFilter;
        });

    generateFiltersToUrlValue(filters) {
        if (filters.gig_price_range) {
            filters.gig_price_range = this.validatePriceRange(filters.gig_price_range);
        }

        if (filters[FILTER.PRICE_BUCKETS.ID]) {
            filters[FILTER.PRICE_BUCKETS.ID] = this.validatePriceBuckets(filters[FILTER.PRICE_BUCKETS.ID]);
        }

        return Object.keys(filters).reduce((acc, filterId) => {
            if (!filters[filterId]) {
                return acc;
            }
            const id = filterId.toUpperCase();
            if (FILTER[id] && FILTER[id].SELECTED_VALUE) {
                acc[filterId] = FILTER[id].SELECTED_VALUE;
            }
            return acc;
        }, filters);
    }

    validatePriceRange(value) {
        let [min, max] = value;

        if (max && min > max) {
            max = value[0];
            min = value[1];
        }

        return [min || MIN_GIG_PRICE_RANGE, max || MAX_GIG_PRICE_RANGE];
    }

    validatePriceBuckets(value) {
        if (Number(value) >= 0) {
            return value;
        }

        return null;
    }

    applyModalFilters(close) {
        this.applyFilters();
        close();
    }

    applyFilters() {
        const { activeFilters } = this.props;

        const currentFilters = this.state.filters;
        const modifiedActiveFilters = modifyActiveFilters(activeFilters, currentFilters);

        const filters = this.generateFiltersToUrlValue({ ...currentFilters });
        if (!isEmpty(filters)) {
            !isEqual(removeEmptyEntries({ ...filters }), removeEmptyEntries({ ...modifiedActiveFilters })) &&
                filterNavigation(filters, modifiedActiveFilters);
        }
    }

    applySort(close) {
        sortNavigation({ [SORT_BY]: this.state.sort });
        close();
    }

    clearAllFilters() {
        let currentFilters = { ...this.state.filters };
        currentFilters = { ...clearObjectValues(this.initialFiltersValues), ...clearObjectValues(currentFilters) };

        delete currentFilters[this.props.exportedFilterId];

        this.setState({ filters: currentFilters });
    }

    onFilterOpen(open) {
        tracker.trackMobileFilterOpen();
        tracker.click(FILTERS_LOCATION.MOBILE_FILTER);
        open();
    }

    onSortOpen(open) {
        tracker.click(`${EVENTS_MAPPING.CLICK.TARGETS.TOP_FILTER}${FILTERS_LOCATION.SORT_BY}`);
        open();
    }

    onChangeFilter(id, value) {
        let filtersValues = { ...this.state.filters };
        filtersValues[id] = value;

        if (id === FILTER.GIG_PRICE_RANGE.ID) {
            filtersValues = omit(filtersValues, FILTER.PRICE_BUCKETS.ID);
            this.initialFiltersValues = omit(this.initialFiltersValues, FILTER.PRICE_BUCKETS.ID);
        }

        if (id === FILTER.PRICE_BUCKETS.ID) {
            filtersValues = omit(filtersValues, FILTER.GIG_PRICE_RANGE.ID);
            this.initialFiltersValues = omit(this.initialFiltersValues, FILTER.GIG_PRICE_RANGE.ID);
        }

        this.setState({ filters: filtersValues });
    }

    onSortChange({ target }) {
        this.setState({ sort: target.value });
    }

    onToggleChange = (filter) => {
        filterNavigation(
            this.generateFiltersToUrlValue(filter),
            this.props.activeFilters,
            FILTERS_LOCATION.FLOATING_TOP_RIGHT
        );
    };

    onClearFilter(id, value) {
        const currentValues = { ...this.state.filters };
        currentValues[id] = value;
        this.setState({ filters: currentValues });
    }

    toggleExportedFilter(id, value) {
        const { exportedFilterId, activeFilters } = this.props;
        this.onChangeFilter(id, value);

        this.setState(
            (prevState) => ({ filters: { ...prevState.filters, [exportedFilterId]: value } }),
            () => {
                filterNavigation(
                    this.generateFiltersToUrlValue({ ...this.state.filters, [exportedFilterId]: value }),
                    activeFilters,
                    FILTERS_LOCATION.TOP
                );
            }
        );
    }

    render() {
        const { filters: currentFilters, sort } = this.state;
        const { activeFilters, filters, sorts, exportedFilterId, CustomFilterButton } = this.props;
        const activeCount = Object.keys(activeFilters).length;
        const displayFilters = this.sortFiltersPosition();

        if (!filters.length) {
            return null;
        }
        const SellerToggleFilter =
            this.sellerToggleFilter && this.sellerToggleFilter.id === FILTER.SELLER_LOCATION.ID
                ? LocalSellerToggle
                : LanguageSellerToggle;

        const renderFallback = !this.topRatedSellerFilter && !this.localSellerFilter;
        const exportedFilter = filters.find(({ id }) => id === exportedFilterId);
        const filterValues = { ...this.initialFiltersValues, ...currentFilters };

        const filteredSorts = sorts?.filter(({ isPriceRelated }) => !isPriceRelated);
        const shouldShowSortBy = !isEmpty(sorts);

        return (
            <AppContext.Consumer>
                {({ currency, appFilters: { showSortByPrice } = {} }) =>
                    CustomFilterButton ? (
                        <Base
                            name="search_perseus listings-perseus filter-button-wrapper filter-modal"
                            trigger={({ open }) => (
                                <CustomFilterButton
                                    onClick={() => this.onFilterOpen(open)}
                                    activeFiltersCount={activeCount}
                                />
                            )}
                        >
                            {({ close }) => (
                                <Suspense fallback={<div />}>
                                    <FilterWrapper
                                        filters={displayFilters}
                                        values={filterValues}
                                        currency={currency}
                                        close={close}
                                        clearAll={this.clearAllFilters}
                                        applyFilters={this.applyModalFilters}
                                        onChange={this.onChangeFilter}
                                        onClear={this.onClearFilter}
                                        isValidFilter={this.state.isValidFilter}
                                        setIsValidFilter={(isValidFilter) => this.setState({ isValidFilter })}
                                    />
                                </Suspense>
                            )}
                        </Base>
                    ) : (
                        <div className="filter-button-wrapper p-b-8">
                            <div className="layout-row filters-container">
                                <Base
                                    name="search_perseus listings-perseus filter-modal"
                                    trigger={({ open }) => (
                                        <Button
                                            className={classNames('touch-filter', {
                                                'full-width': !shouldShowSortBy,
                                            })}
                                            onClick={() => this.onFilterOpen(open)}
                                        >
                                            <I18n k={'search_perseus.filter.filter'} />
                                            {activeCount > 0 && <span>{`(${activeCount})`}</span>}
                                        </Button>
                                    )}
                                >
                                    {({ close }) => (
                                        <Suspense fallback={<div />}>
                                            <FilterWrapper
                                                filters={displayFilters}
                                                values={filterValues}
                                                currency={currency}
                                                close={close}
                                                clearAll={this.clearAllFilters}
                                                applyFilters={this.applyModalFilters}
                                                onChange={this.onChangeFilter}
                                                onClear={this.onClearFilter}
                                                isValidFilter={this.state.isValidFilter}
                                                setIsValidFilter={(isValidFilter) => this.setState({ isValidFilter })}
                                            />
                                        </Suspense>
                                    )}
                                </Base>
                                {shouldShowSortBy && (
                                    <Base
                                        name="search_perseus listings-perseus filter-modal"
                                        trigger={({ open }) => (
                                            <Button className="sort-button" onClick={() => this.onSortOpen(open)}>
                                                <I18n k="search_perseus.filter.sort" />
                                            </Button>
                                        )}
                                    >
                                        {({ close }) => (
                                            <Suspense fallback={<div />}>
                                                <SortContainer
                                                    onChange={this.onSortChange}
                                                    applyFilters={this.applySort}
                                                    values={sort}
                                                    close={close}
                                                    sorts={showSortByPrice ? sorts : filteredSorts}
                                                />
                                            </Suspense>
                                        )}
                                    </Base>
                                )}
                            </div>
                            {this.topRatedSellerFilter && (
                                <TopRatedSellerToggle
                                    className={classNames('layout-row', 'switch-container')}
                                    filter={this.topRatedSellerFilter}
                                    onCustomChange={this.onToggleChange}
                                    tooltip
                                />
                            )}
                            {!this.topRatedSellerFilter && this.localSellerFilter && (
                                <LocalSellerToggle
                                    className={classNames('layout-row', 'switch-container')}
                                    filter={this.localSellerFilter}
                                    onCustomChange={this.onToggleChange}
                                    tooltip
                                />
                            )}
                            {renderFallback && [
                                exportedFilter && (
                                    <div className="exported-filter-toggle">
                                        <Filter
                                            filter={exportedFilter}
                                            value={filterValues[exportedFilter.id]}
                                            onChange={this.toggleExportedFilter}
                                            onClear={this.onClearFilter}
                                        />
                                    </div>
                                ),
                                !exportedFilter && SellerToggleFilter && (
                                    <SellerToggleFilter
                                        className={classNames('layout-row', 'switch-container')}
                                        onCustomChange={this.onToggleChange}
                                        filter={this.sellerToggleFilter}
                                    />
                                ),
                            ]}
                        </div>
                    )
                }
            </AppContext.Consumer>
        );
    }
}

MobileFilters.contextType = AppContext;

MobileFilters.propTypes = {
    activeFilters: PropTypes.object,
    filters: PropTypes.array.isRequired,
    sorts: PropTypes.array,
    exportedFilterId: PropTypes.string,
};

export default MobileFilters;
