import { getLeafCategoryTilesData } from '../../../LeafCategoriesCarousel/utils';
import { LeafCategoryFilter } from '../../types';
import { ExposedFilterPropsMapper } from '../types';

export const leafCategoriesPropsMapper: ExposedFilterPropsMapper = (context) => {
    const { significantLeafCategories = [], appFilters: { exposedFilters } = {} } = context;
    const leafCategoryFilter: LeafCategoryFilter = exposedFilters?.leaf_category_filters?.[0] || {};
    const leafCategories = leafCategoryFilter.children;
    const leafCategoryTiles = getLeafCategoryTilesData(significantLeafCategories, leafCategories);

    return { leafCategoryTiles };
};
