import { get } from 'lodash';
import { translate, hasTranslation } from '@fiverr-private/i18n-react';
import {
    ActiveFilters,
    FilterKey,
    Gig,
    GigAttributes,
    GigGroupType,
    RawGig,
    SortBy,
} from '@fiverr-private/listing_types';
import { activeFiltersToQueryString } from '../../../url';

type EnrichGig = {
    gigId: number;
    pos: number;
    is_fiverr_choice: boolean;
    impressionId: string;
    sellerId: string;
    gigQueryParams: Gig['gigQueryParams'];
    numericPricingFactor?: {
        scope: number;
        alias: string;
    };
    groupType?: GigGroupType;
    gigAttributes?: GigAttributes;
} & Pick<RawGig, 'type' | 'auction' | 'additional_types' | 'packages'>;

const SEARCH_FILTER: Partial<Record<FilterKey, string>> = {
    delivery_time: 'filtered_duration',
    package_includes: 'filtered_factors',
    gig_price_range: 'filtered_price',
};

const isFiverrChoice = ({ type, additional_types }: RawGig): boolean =>
    type === 'fiverr_choice' || !!(additional_types && additional_types.fiverr_choice);

const translateSilentFail = ({ key, locale }: { key: string; locale: string }): string => {
    const hasTranslationKey = hasTranslation(key, { locale });

    if (!hasTranslationKey) {
        return '';
    }

    return translate(key, { locale });
};

const getPricingFactorTranslationKey = ({
    pricingFactorKey,
    dominantLeafCategoryParent,
    locale,
}: {
    pricingFactorKey: string;
    locale: string;
    dominantLeafCategoryParent?: string;
}): string => {
    const keyPrefix = `pricing_factors.${pricingFactorKey}`;

    // TODO: Change this key to be from pricing_factors (Cheetahs) copies
    const key = `search_perseus.filters.global.pricing_factor_skeleton.${keyPrefix}.sub_category_${dominantLeafCategoryParent}.unit`;

    const subCategoryUnit = translateSilentFail({ key, locale });

    if (subCategoryUnit) {
        return key;
    }

    return `${keyPrefix}.unit`;
};

const getNumericPricingFactor = ({
    gig,
    locale,
    pricingFactorKey,
    dominantLeafCategoryParent,
}: {
    gig: RawGig;
    locale: string;
    pricingFactorKey?: string;
    dominantLeafCategoryParent?: string;
}): EnrichGig['numericPricingFactor'] | undefined => {
    const scope = get(gig, 'packages.recommended.numeric_pricing_factor.scope');
    if (!scope || !pricingFactorKey) {
        return;
    }

    const translationKey = getPricingFactorTranslationKey({ pricingFactorKey, dominantLeafCategoryParent, locale });
    return {
        scope,
        alias: translate(translationKey, { locale }),
    };
};

export const enrichGigs = ({
    gigs,
    sortBy,
    funnel,
    dominantLeafCategoryParent,
    activeFilters,
    locale,
    pricingFactorKey,
}: {
    gigs: RawGig[];
    sortBy: SortBy;
    funnel: string;
    activeFilters: ActiveFilters;
    locale: string;
    pricingFactorKey?: string;
    dominantLeafCategoryParent?: string;
}): EnrichGig[] => {
    const relevantActiveFilters = Object.keys(SEARCH_FILTER)
        .filter((key) => activeFilters[key])
        .reduce((total, key) => {
            total[key] = activeFilters[key];
            return total;
        }, {});

    const activeFiltersGigQueryParams = Object.keys(relevantActiveFilters).reduce((map, key) => {
        map[SEARCH_FILTER[key]] = relevantActiveFilters[key];
        return map;
    }, {});

    return gigs.map((gig) => {
        const {
            position,
            type,
            auction,
            packages,
            additional_types,
            gig_attributes: gigAttributes,
            seller_id,
            pk_i: gigId,
            group_type: groupType,
            uuid,
        } = gig;

        const numericPricingFactor = getNumericPricingFactor({
            gig,
            locale,
            pricingFactorKey,
            dominantLeafCategoryParent,
        });

        const result: EnrichGig = {
            gigId,
            pos: position,
            type,
            auction,
            is_fiverr_choice: isFiverrChoice(gig),
            additional_types,
            groupType,
            packages,
            sellerId: seller_id,
            gigAttributes,
            impressionId: uuid,
            gigQueryParams: {
                ...activeFiltersGigQueryParams,
                context_type: sortBy,
                funnel,
                ref: activeFiltersToQueryString(activeFilters),
            },
            ...(numericPricingFactor && { numericPricingFactor }),
        };

        return result;
    });
};
