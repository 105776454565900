import React, { useState } from 'react';
import classnames from 'classnames';
import { isEmpty, isArray } from 'lodash';
import PropTypes from 'prop-types';
import { I18n } from '@fiverr-private/i18n-react';
import { useImpressionItem } from '@fiverr-private/impressionable';
import { FilterIcon } from '@fiverr-private/icons';
import { grey_1200 } from '@fiverr-private/sass/helpers';
import {
    BI_ELEMENTS_NAMES,
    BI_EVENTS_TYPES,
    ELEMENT_TYPE,
} from '../../../../../../generic_listings/TagFilters/constants';
import { buildEventEnrichment } from '../../../../../../generic_listings/TagFilters/utils';
import { BI_EVENT_GROUPS } from '../../../../../../../../../shared/services/BIEvents/constants';
import { transformLeafCategoryItems } from '../../../../../../common/tiles/leafCategoryTile/utils';
import Types from '../../../types';
import { navigateLeafCategory, removeSubcategoryFilter } from '../../utils';
import { ViewAllSubcategoriesModal } from './ViewAllSubcategoriesModal';
import { ClearFilter } from './ClearFilter';

import styles from './index.module.scss';

const FILTER_ID = 'leaf_category';
const MAX_CHILDREN_TO_DISPLAY = 4;

const getScToDisplay = (subCategory, significantLeafCategories) => {
    const { children } = subCategory;
    const significantLeafSc = children.filter((scChildren) => {
        const nestedSc = scChildren.params?.nested_sub_category;
        const sc = scChildren.params?.sub_category;

        return (
            (!!nestedSc && significantLeafCategories.includes(nestedSc)) ||
            (!!sc && significantLeafCategories.includes(sc))
        );
    });

    if (significantLeafSc.length > 1) {
        return significantLeafSc;
    } else {
        return children.slice(0, MAX_CHILDREN_TO_DISPLAY);
    }
};

const VisualScFilter = ({ subCategory, activeFilters, significantLeafCategories = [], disableClicks }) => {
    const { children } = subCategory;
    const elementName = BI_ELEMENTS_NAMES.LEAF_CATEGORY;
    const [selected] = useState(activeFilters[FILTER_ID] || undefined);
    const enrichment = buildEventEnrichment({
        type: BI_EVENTS_TYPES.IMPRESSION,
        group: BI_EVENT_GROUPS.USER_IMPRESSION,
        elementName,
        elementType: ELEMENT_TYPE.TAGS,
    });
    const [ref] = useImpressionItem({ enrichment });

    if (isEmpty(children) || !isArray(children)) {
        return null;
    }

    const items = selected
        ? children.filter((category) => category.selected)
        : getScToDisplay(subCategory, significantLeafCategories);
    const leafCategories = transformLeafCategoryItems(children);

    const subCategoryChanged = (params, selected) => {
        navigateLeafCategory(params, selected, true);
        disableClicks();
    };

    const clearFilterClick = () => {
        removeSubcategoryFilter();
        disableClicks();
    };

    return (
        <div ref={ref} className={styles.container}>
            <div className={styles.titleContainer}>
                <FilterIcon size={16} color={grey_1200} />
                <span className="m-l-8">
                    <I18n k="search_perseus.filter_builder.sub_category.category" />
                </span>
            </div>
            <div className={classnames(styles.tagsContainer, 'm-t-16')}>
                {items.map(({ alias, params, selected: isItemSelected }) => (
                    <span
                        key={params.sub_category}
                        onClick={() => subCategoryChanged(params, selected)}
                        className={classnames(styles.filterTag, 'tbody-6', { [styles.selected]: isItemSelected })}
                    >
                        {alias}
                    </span>
                ))}
            </div>
            {selected ? (
                <ClearFilter onClick={clearFilterClick} className={classnames('m-t-12', styles.viewAllTrigger)} />
            ) : (
                <ViewAllSubcategoriesModal
                    items={leafCategories}
                    initialValue={selected}
                    triggerClassName={classnames('m-t-12', styles.viewAllTrigger)}
                />
            )}
        </div>
    );
};

VisualScFilter.propTypes = {
    subCategory: Types.subCategory.isRequired,
    activeFilters: Types.activeFilters.isRequired,
    significantLeafCategories: Types.significantLeafCategories,
    disableClicks: PropTypes.func.isRequired,
};

export default VisualScFilter;
