import { MIXPANEL_EVENT_NAMES } from '../../constants';
import { sendClickEventToMixPanel } from '../../Mixpanel';

const getPayloadForImageEvents = ({ target, sourceStr, position, userId, isSeller, sellerId, source }) => {
    const defaultPayload = {
        'user id': userId?.toString(),
        'is guest': !userId,
        'is seller': isSeller,
        'Seller Id': sellerId,
    };
    const additionalPayload = {
        Page: source,
        source: sourceStr,
    };
    switch (target) {
        case 'scroll':
            return { ...defaultPayload, ...additionalPayload, 'card position': position || null };
        case 'click':
            return { ...defaultPayload, ...additionalPayload, 'image position': position };
        case 'hover':
            return { ...defaultPayload, 'card position': position };
        default:
            return { ...defaultPayload, ...additionalPayload };
    }
};

export const hoverOnGigServiceCardImageEvent = ({
    gigId,
    target,
    sourceStr,
    position,
    userId,
    isSeller,
    sellerId,
    source,
}) => {
    sendClickEventToMixPanel({
        eventName: MIXPANEL_EVENT_NAMES.HOVERED_ON_SERVICE_CARD_IMAGE,
        'Gig Id': gigId,
        ...getPayloadForImageEvents({ target, sourceStr, position, userId, isSeller, sellerId, source }),
    });
};

export const clickOnGigServiceCardImageEvent = ({
    gigId,
    target,
    sourceStr,
    position,
    userId,
    isSeller,
    sellerId,
    source,
}) => {
    sendClickEventToMixPanel({
        eventName: MIXPANEL_EVENT_NAMES.CLICKED_ON_SERVICE_CARD_IMAGE,
        'Gig Id': gigId,
        ...getPayloadForImageEvents({ target, sourceStr, position, userId, isSeller, sellerId, source }),
    });
};

export const scrollOnGigServiceCardImageEvent = ({
    gigId,
    target,
    sourceStr,
    position,
    userId,
    isSeller,
    sellerId,
    source,
}) => {
    sendClickEventToMixPanel({
        eventName: MIXPANEL_EVENT_NAMES.SCROLLED_SERVICE_CARD_IMAGES,
        'Gig Id': gigId,
        ...getPayloadForImageEvents({ target, sourceStr, position, userId, isSeller, sellerId, source }),
    });
};
