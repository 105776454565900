import React, { useContext } from 'react';
import { isRolesListings } from '@fiverr-private/listing_lib';
import { filterType } from '../../../../../../../types';
import Toggle, { TogglePropTypes } from '../toggle';
import { AppContext } from '../../../../../../context/listingsContext';

const SellerOnlineToggle = ({ filter, ...rest }) => {
    const { isExpertListings, flowName } = useContext(AppContext);

    if (!filter) {
        return null;
    }

    const keyPrefix = isExpertListings || isRolesListings(flowName) ? 'experts' : 'global';

    return (
        <Toggle
            className="local-filter"
            selectedValue={true}
            title={`search_perseus.filters.${keyPrefix}.is_seller_online.title`}
            text="search_perseus.filters.global.is_seller_online.text"
            {...filter}
            {...rest}
        />
    );
};

SellerOnlineToggle.propTypes = {
    filter: filterType,
    ...TogglePropTypes,
};

export default SellerOnlineToggle;
