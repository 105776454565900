import { bigQuery } from '@fiverr-private/obs';
import { getContext } from '@fiverr-private/fiverr_context';
import { getParentImpId } from '@fiverr-private/impressionable';
import { PLATFORMS } from '../../../constants';
import { BI_EVENTS } from '../../../components/Bricks/Item/events/constants';

export const sendClickEventToBQ = (eventParams) => {
    const payload = getInteractionEventPayload(eventParams);

    bigQuery.send(payload);
};

const getInteractionEventPayload = ({
    type,
    seller = null,
    impressionId,
    action = 'click',
    group = 'user_action',
    entity = 'client',
    featureName = null,
    source = null,
    params = {},
    domain = null,
    gigId = null,
    ...pageParams
} = {}) => {
    const {
        userId,
        userGuid,
        organizationId,
        pageCtxId,
        isTouch,
        queryParameters: { ref_ctx_id: refCtxId } = {},
    } = getContext();
    const parentImpressionId = impressionId ? getParentImpId(impressionId) : null;

    return {
        entity,
        group,
        sub_group: 'website_actions',
        domain,
        type,
        action: { type: action },
        uid: userGuid,
        ...(gigId && { gig: { id: gigId } }),
        ...(userId && { user: { id: userId } }),
        ...getPageEnrichment(pageParams, { pageCtxId, refCtxId }),
        ...(organizationId && { organization: { id: organizationId } }),
        ...(seller && { seller: { id: seller.id, username: seller.username } }),
        platform: isTouch ? PLATFORMS.MOBILE : PLATFORMS.WEB,
        created_at: new Date(),
        ...(impressionId && {
            imp: {
                id: impressionId,
                ...(parentImpressionId && { parent_component_id: parentImpressionId }),
            },
        }),
        ...(featureName && { feature: { name: featureName } }),
        ...(source && { event_source: source }),
        ...params,
    };
};

export const getImpressionEventPayload = ({
    type,
    seller = null,
    impressionId,
    group = BI_EVENTS.IMPRESSION.GROUP,
    action = BI_EVENTS.IMPRESSION.ACTION_TYPE,
    entity = 'client',
    featureName = null,
    source = null,
    params = {},
    ...pageParams
} = {}) => {
    const {
        userId,
        userGuid,
        organizationId,
        pageCtxId,
        isTouch,
        queryParameters: { ref_ctx_id: refCtxId } = {},
    } = getContext();
    const parentImpressionId = impressionId ? getParentImpId(impressionId) : null;

    return {
        entity,
        type,
        group,
        action: {
            type: action,
        },
        uid: userGuid,
        ...(userId && { user: { id: userId } }),
        ...getPageEnrichment(pageParams, { pageCtxId, refCtxId }),
        ...(organizationId && { organization: { id: organizationId } }),
        ...(seller && { seller: { id: seller.id, username: seller.username } }),
        platform: isTouch ? PLATFORMS.MOBILE : PLATFORMS.WEB,
        created_at: new Date(),
        ...(impressionId && {
            imp: {
                id: impressionId,
                ...(parentImpressionId && { parent_component_id: parentImpressionId }),
            },
        }),
        ...(featureName && { feature: { name: featureName } }),
        ...(source && { event_source: source }),
        ...params,
    };
};

const getPageEnrichment = (
    {
        pageName,
        componentName = null,
        elementName = null,
        elementType = null,
        elementText = null,
        elementPosition = null,
        elementId = null,
        elementMetaData = null,
    },
    { pageCtxId, refCtxId }
) => ({
    page: {
        ctx_id: pageCtxId,
        name: pageName,
        ...(refCtxId && { referrer: { ctx_id: refCtxId } }),
        ...(componentName && { component: { name: componentName } }),
        element: {
            ...(elementName && { name: elementName }),
            ...(elementType && { type: elementType }),
            ...(elementPosition && { position: elementPosition }),
            ...(elementText && { text: elementText }),
            ...(elementId && { id: elementId }),
            ...(elementMetaData && { metadata: elementMetaData }),
        },
    },
});
