import React, { useContext } from 'react';
import { string } from 'prop-types';
import { AppContext } from '../../../context/listingsContext';
import { VIEW_ALL_COMPONENT } from '../../top_filters_carousel/constants';
import ViewAllTile from '../view_all_tile/viewAllTile';
import view_all from '../filter_tile/images/view_all.svg';
import { LIST_TYPES } from '../view_all_tile/constants';
import Ellipsis from '../../../../../../shared/components/Ellipsis';
import { TILE_TYPES } from '../../tiles_carousel/constants';
import { handleClick, sortByMostSignificant, transformLeafCategoryItems } from './utils';

import '../index.scss';

const LeafCategoryTile = ({ id, alias, url, src }) => {
    const {
        appFilters: { subCategory },
        significantLeafCategories = [],
    } = useContext(AppContext);

    if (id === VIEW_ALL_COMPONENT) {
        const leafCategories = transformLeafCategoryItems(subCategory.children);
        const sortedScItems = sortByMostSignificant(leafCategories, significantLeafCategories);

        return (
            <ViewAllTile
                items={sortedScItems}
                image={view_all}
                alias={alias}
                listType={LIST_TYPES.SUBCATEGORIES}
                tileType={TILE_TYPES.CHIP_TILE}
            />
        );
    }

    return (
        <a href={url} className={TILE_TYPES.CHIP_TILE} onClick={() => handleClick({ subCategory, selected: id })}>
            <div className="chip-tile-icon-wrapper">
                <img src={src} alt={alias} loading="lazy" />
            </div>
            <Ellipsis text={alias} textClassName="chip-tile-text tbody-5" />
        </a>
    );
};

LeafCategoryTile.propTypes = {
    id: string,
    alias: string,
    url: string,
    src: string,
};

export default LeafCategoryTile;
