import { stats, statsFunction } from '@fiverr-private/obs';
import { ACTIVE_FILTER_SEPARATORS, FILTER } from '../constants';
import { isBrowser } from '../util';
import { SOURCE_PARAMS } from './sourceParams.constants';

const UNKNOWN = 'unknown';
const AUTOCOMPLETE_TYPES = ['popular', 'recent-gigs-suggest', 'suggest', 'users-lookup', 'gigs-lookup'];

const PERSEUS_PREFIX = 'services.search_perseus';
const CLIENT_PERSEUS_PREFIX = `${PERSEUS_PREFIX}.client`;

const getPerseusPrefix = () => (isBrowser() ? CLIENT_PERSEUS_PREFIX : PERSEUS_PREFIX);
const getValidSourceParam = (source) => (SOURCE_PARAMS.includes(source) ? source : UNKNOWN);

export const metricsService = {
    count(metric, value = 1) {
        // todo: add 'rate' once its fixed in obs
        return stats.count(getPerseusPrefix(), metric, value);
    },

    time(metric, value) {
        return stats.time(getPerseusPrefix(), metric, value);
    },

    countHttpStatusCode(metric, status) {
        this.count(`http.${metric}.result.status.${status}`);
    },

    countSdkStatusCode(metric, status) {
        this.count(`sdk.${metric}.result.status.${status}`);
    },

    measureHttpRequest(metric, status, responseTime) {
        this.time(`http.${metric}.total_request_time`, responseTime);
        this.count(`http.${metric}.request`);
        this.countHttpStatusCode(metric, status);
    },

    measureSdkRequest(metric, responseTime) {
        this.time(`sdk.${metric}.total_request_time`, responseTime);
        this.count(`sdk.${metric}.request`);
    },

    countFlowView(metric) {
        this.count(`flow_view.${metric}`);
    },

    countSourcePage(flow, source) {
        const sourceParam = getValidSourceParam(source);
        source && this.count(`flow.${flow}.source.${sourceParam}`);
    },

    countKnownCrawler(metric, flow) {
        this.count(`known_crawler.${metric}`);
        flow && this.count(`flow.${flow}.known_crawler.${metric}`);
    },

    countSearchInstead(modifiedQueryString, nonTranslatedQuery) {
        modifiedQueryString && this.count('search_flow.render.search_instead');
        nonTranslatedQuery && this.count('search_flow.render.search_translated');
    },

    countActiveRefFilters(ref) {
        const proFilter = `${FILTER.PRO.ID}${ACTIVE_FILTER_SEPARATORS.PARAM_VALUE}${FILTER.PRO.SELECTED_VALUE}`;
        const isProFilterActive = ref?.includes(proFilter);
        isProFilterActive && this.count('filters.active_ref.pro');
    },

    countAutocompleteType(type) {
        const autocompleteType = AUTOCOMPLETE_TYPES.includes(type) ? type : UNKNOWN;

        type && this.count(`search_flow.autocomplete.type.${autocompleteType}`);
    },

    countVertical(metric) {
        this.count(`vertical.${metric}`);
    },

    statsFunction(func, metric) {
        return statsFunction(func, `${getPerseusPrefix()}.${metric}`);
    },
};
