import { noop } from 'lodash';
import { PLAYER_TOGGLE_PLAYING_EVENT, TOGGLE_PLAYING_TYPE } from './constants';

export const playMedia = ({ playerRef, playerContainerClassName, isPlaying, setIsPlaying } = {}) => {
    const playerContainers = document.querySelectorAll(`.${playerContainerClassName}`);

    Array.from(playerContainers).forEach((playerContainer) => {
        const player = playerContainer.querySelector('video, audio');

        player?.pause();

        const togglePlayingEvent = new CustomEvent(PLAYER_TOGGLE_PLAYING_EVENT, { detail: TOGGLE_PLAYING_TYPE.PAUSE });
        playerContainer.dispatchEvent(togglePlayingEvent);
    });

    if (isPlaying) {
        playerRef.current.pause();
        setIsPlaying(false);
    } else {
        setIsPlaying(true);
        playerRef.current.play().catch(noop);
    }
};

export const calculateMediaProgress = (event, setProgress) => {
    const { currentTime, duration } = event.target;

    const totalPercentage = 2 * Math.PI.toFixed(2) * 14;
    const progress = (currentTime * totalPercentage) / duration;

    setProgress(progress);
};

export const onEnd = (event, setIsPlaying) => {
    event.target.currentTime = 0;
    setIsPlaying(false);
};
