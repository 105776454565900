const { isEmpty, find } = require('lodash');
const { SUPPORTED_FIVERR_LOCALES, getShortLanguageCode } = require('@fiverr-private/localization');
const { shouldShowVisualFilters } = require('../../../experiments/serviceTilesSC/utils');
const { MINIMUM_FILTER_COUNT, MINIMUM_FILTER_OPTIONS, QUERY_LIST, DLC_LIST } = require('./constants');

const isSupportedFiverrLanguage = (assumedLanguage) =>
    find(SUPPORTED_FIVERR_LOCALES, (locale) => getShortLanguageCode(locale) === assumedLanguage);

const isQueryInList = (query = '') => QUERY_LIST.includes(query.trim().toLowerCase());
const isDlcInList = (subCategory) => DLC_LIST.includes(subCategory);

const isInVisualFilterWhitelist = (displayQuery, dominateSubCategoryId) =>
    isQueryInList(displayQuery.toLowerCase()) && isDlcInList(dominateSubCategoryId);

const findStepIndex = (filters) => filters.findIndex(({ selected }) => !selected);

const filterOptions = (options, mainFilter, filterIndex) => {
    const filteredOptions = [];

    options.forEach((item) => {
        const mainFilterOption = mainFilter?.options.find(({ id }) => id === item);
        const countAboveMinimum = mainFilterOption?.count > MINIMUM_FILTER_COUNT;
        const hasCount = mainFilterOption?.count;
        const notFirstStep = filterIndex > 0;

        if (mainFilterOption && (countAboveMinimum || !hasCount || notFirstStep)) {
            filteredOptions.push({ id: item, alias: mainFilterOption.alias });
        }
    });

    return filteredOptions;
};

const checkIfMetadataIncludesQuery = (filteredOptions, query = '') =>
    filteredOptions.some(({ alias = '' }) => {
        const lowerCaseQuery = query.toLowerCase();
        const lowerCaseAlias = alias.toLowerCase();

        return lowerCaseQuery.split(' ').includes(lowerCaseAlias) || lowerCaseQuery.includes(lowerCaseAlias);
    });

const buildVisualFiltersList = ({ displayQuery, filters = [], mainFilters = [], activeFiltersKeys = [] }) => {
    const visualFiltersList = [];

    filters.forEach(({ id: visualFilterId, tileSize, options }, index) => {
        const mainFilter = mainFilters.find(({ id: mainFilterId }) => mainFilterId === visualFilterId);

        const filteredOptions = filterOptions(options, mainFilter, index);

        const isMetadataIncludesQuery = checkIfMetadataIncludesQuery(filteredOptions, displayQuery);

        if (filteredOptions.length >= MINIMUM_FILTER_OPTIONS) {
            visualFiltersList.push({
                id: visualFilterId,
                ...(tileSize && { tileSize }),
                options: filteredOptions,
                isMetadataIncluded: isMetadataIncludesQuery,
                selected: activeFiltersKeys.includes(visualFilterId),
            });
        }
    });

    return visualFiltersList;
};

const getVisualFiltersConditions = (context) => {
    let condition = null;
    const {
        isTouch,
        isSearch,
        query,
        displayData = {},
        listings = {},
        appData: { assumedLanguage } = {},
        categoryIds = {},
        subCategory: leafCategoryFilters = {},
        dominateSubCategoryId,
        isBusiness,
        isExpertListings,
        modifiedQueryString,
    } = context;
    const { subCategoryId, nestedSubCategoryId } = categoryIds;
    const { recommendations, sellersWhoSpeak, choiceModalities } = listings[0] || {};
    const { nestedSubCategoryName } = displayData;
    const { children = {} } = leafCategoryFilters;

    const displayQuery = modifiedQueryString || query;
    const hasRepeatedBuyers = !isEmpty(recommendations?.repeatedBuyers);
    const hasChoiceModalities = !isEmpty(choiceModalities?.buckets);
    const hasFtbFriendly = !isEmpty(recommendations?.ftbFriendly);
    const hasSellersWhoSpeak = !isEmpty(sellersWhoSpeak) && isSupportedFiverrLanguage(assumedLanguage);

    const hasModalities = hasSellersWhoSpeak || hasFtbFriendly || hasRepeatedBuyers;

    if (isExpertListings) {
        if (isSearch) {
            return isInVisualFilterWhitelist(displayQuery, dominateSubCategoryId);
        } else {
            return shouldShowVisualFilters({ isTouch, subCategoryId, nestedSubCategoryId });
        }
    }

    if (isSearch) {
        condition = isInVisualFilterWhitelist(displayQuery, dominateSubCategoryId) && !hasChoiceModalities;
    } else {
        const shouldDisplayNscTilesCarousel = !nestedSubCategoryName && children[0]?.children?.length > 0;
        condition =
            !shouldDisplayNscTilesCarousel && shouldShowVisualFilters({ isTouch, subCategoryId, nestedSubCategoryId });
    }

    if (!isBusiness) {
        condition = condition && !hasModalities;
    }

    return condition && !isTouch;
};

module.exports = {
    findStepIndex,
    filterOptions,
    buildVisualFiltersList,
    getVisualFiltersConditions,
    checkIfMetadataIncludesQuery,
};
